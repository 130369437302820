import styled from "@emotion/styled";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import React, { useContext, useEffect, useState } from "react";
import { useGridStyles } from "../../../../hooks/useGridStyles";
import UserContext from "../../../../services/UserContext";
import SectionHeader, { SubHeaders } from "../../SectionHeader";
import { OverlayPanel } from "primereact/overlaypanel";
import "./organizationInfo.css";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../../../configs/axios-export.custom";
import axios from "axios";
import GrowlContext from "../../../../services/growlContext";
import { css } from "glamor";
import { useSharedStyles } from "../../useSharedStyles";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import Swal from "sweetalert2";
import SimpleModal from "../../../Modals/SimpleModal";
import { Drawer, Typography } from "@mui/material";
const HeaderButton = styled.button({
  background: "#ECAC37 !important",
  borderRadius: "26.5px",
  margin: "10px",
  height: "40px",
  padding: "10px",
  border: "none",
  left: "0px",
  color: "#FFFFFF !important",
  ":focus": {
    background: "#ECAC37",
  },
});

const Panel = styled.div({
  zIndex: 10000,
  top: "1px !important",
  right: "0px",
});

interface IUsers {
  FirstName: string;
  LastName: string;
  email: string;
}

type UserProfileType = {
  publicUserId: string;
  firstName: string;
  lastName: string;
  email: string;
  userRole: string;
  password: string;
  message: string;
  isEnabled: Boolean;
  pipelines: [];
};
const defaultUserSetting: UserProfileType = {
  publicUserId: "",
  firstName: "",
  lastName: "",
  email: "",
  userRole: "Select Role",
  password: "",
  message: "",
  pipelines: [],
  isEnabled: false,
};
const Team = () => {
  const context = React.useContext(UserContext);
  const growl = useContext(GrowlContext);
  const header = createAuthenticatedRequest(context);
  const [first, setFirst] = React.useState(0);
  const [keyword, setKeyword] = React.useState("");
  const [totalRecords, setTotalrecords] = React.useState(0);
  const [toggleInviteBtn, setToggleButton] = React.useState(false);
  const [toggleViewMode, setToggleViewMode] = React.useState(false);
  const [toggleEditMode, setToggleEditMode] = React.useState(false);
  const [companyUsers, setCompanyUsers] = useState<UserProfileType[]>([]);
  const [headerText, setHeaderText] = React.useState("Add team member");
  const [isInviteLoading, setIsInviteLoading] = React.useState(false);
  const [userSettings, setUserSettings] =
    React.useState<UserProfileType>(defaultUserSetting);
  const [rowState, setRowState] =
    React.useState<UserProfileType>(defaultUserSetting);
  const sharedStyles = useSharedStyles();
  const [serverValues, setServerValues] = React.useState<UserProfileType>();
  // const pl = React.useRef<OverlayPanel>(null);
  const styles = useGridStyles();
  const [isLoading, setisloading] = React.useState(true);
  const [toggleMask, setToggleMask] = React.useState("password");
  const [showMask, setShowMask] = React.useState(true);
  const [outsideEmailMessageModalOpen, setOutsideEmailMessageModalOpen] =
    useState<boolean>(false);
  const [addMemberOpen, setAddMemberOpen] = useState<boolean>(false);
  const email = context.user.parsedIdToken?.email;
  const handleOpenAddMemberDrawer = (): void => {
    setAddMemberOpen(true);
  };
  const handleCloseAddMemberDrawer = (): void => {
    setAddMemberOpen(false);
    setTimeout(() => {
      setHeaderText("Add team member");
      setRowState(defaultUserSetting);
      setUserSettings(defaultUserSetting);
      setToggleViewMode(false);
      setToggleButton(false);
      setToggleEditMode(false);
    }, 150);
  };
  const updateUsers = (data: any) => {
    const newUser = [data, ...companyUsers];
    setCompanyUsers(newUser);
  };
  const onUserSettingsChange = <O extends keyof UserProfileType>(
    prop: O,
    value: UserProfileType[O],
  ) => {
    setUserSettings({ ...userSettings, [prop]: value });
  };
  const loadUsers = () => {
    axios
      .get(
        getFullUrl("/api/organization/user", { useDedicatedEnvironment: true }),
        header,
      )
      .then((response) => {
        setCompanyUsers(response.data);
        setServerValues(response.data);
        setTotalrecords(companyUsers.length);
        setisloading(false);
      })
      .catch((e) => {
        console.error(e);
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: "Unable to load users",
          });
        }
      });
  };

  useEffect(loadUsers, [context]);
  const deleteUser = (publicUserId: string) => {
    axios
      .delete(
        getFullUrl(`/api/organization/user/${publicUserId}`, {
          useDedicatedEnvironment: true,
        }),
        header,
      )
      .then(() => {
        const updateUserList = companyUsers.filter(
          (u: UserProfileType) => u.publicUserId !== publicUserId,
        );
        setCompanyUsers(updateUserList);
        growl.current.show({
          severity: "success",
          summary: "Record has been deleted",
        });
      })
      .catch((e) => {
        console.error(e);
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: "Error deleting data",
          });
        }
      });
  };
  const showSidePanel = (e: React.SyntheticEvent<Element, Event>) => {
    setToggleButton(true);
    // if (pl && pl.current) {
    //   pl.current.toggle(e);
    // }
    handleOpenAddMemberDrawer();
  };
  const canSaveUsr =
    userSettings.firstName !== rowState?.firstName ||
    userSettings.lastName !== rowState?.lastName ||
    userSettings.userRole !== rowState?.userRole;

  function validateEmail(email: string) {
    var re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return re.test(email);
  }

  //  save user
  const saveNewUser = async () => {
    setIsInviteLoading(true);
    const r =
      userSettings.userRole === "Organization Admin"
        ? "OrganizationAdmin"
        : "Member";
    //  TO DO : Covert to form validation
    if (userSettings.firstName == "") {
      growl.current.show({
        severity: "error",
        summary: "First name is required.",
      });
      setIsInviteLoading(false);
      return;
    } else if (userSettings.lastName == "") {
      growl.current.show({
        severity: "error",
        summary: "Last name is required.",
      });
      setIsInviteLoading(false);
      return;
    } else if (userSettings.email == "") {
      growl.current.show({
        severity: "error",
        summary: "Email  is required.",
      });
      setIsInviteLoading(false);
    } else if (!validateEmail(userSettings.email)) {
      growl.current.show({
        severity: "error",
        summary: "Invalid email address. e.g. example@email.com",
      });
      setIsInviteLoading(false);
      return;
    } else if (userSettings.userRole == "") {
      growl.current.show({
        severity: "error",
        summary: "Role is required",
      });
      setIsInviteLoading(false);
      return;
    }
    await axios
      .post(
        getFullUrl("/api/organization/usernew", {
          useDedicatedEnvironment: true,
        }),
        {
          FirstName: userSettings.firstName,
          LastName: userSettings.lastName,
          Email: userSettings.email,
          Pipelines: userSettings.pipelines,
          Password: "",
          userRole: r,
        },
        header,
      )
      .then((res) => {
        growl.current.show({
          severity: "success",
          summary: "The new member has been added successfully",
        });
        // update state
        updateUsers(res.data);
        // pl.current?.hide();
        handleCloseAddMemberDrawer();
        setOutsideEmailMessageModalOpen(false);
      })
      .catch((error) => {
        setOutsideEmailMessageModalOpen(false);
        if (error.response) {
        }
        if (growl.current) {
          if (error.response.status === 409) {
            // TO DO get actual error response from trace stack
            growl.current.show({
              severity: "error",
              summary: "User already exists",
            });
            return;
          }
          if (error.response.status === 500) {
            growl.current.show({
              severity: "error",
              summary: "Invalid email address",
            });
            return;
          }
        }
      })
      .finally(() => setIsInviteLoading(false));
  };
  const userRole = (str: string) => {
    return str === "Organization Admin"
      ? "OrganizationAdmin"
      : str === "OrganizationAdmin"
      ? "OrganizationAdmin"
      : str === "Member"
      ? "Member"
      : str;
  };
  //  edit user  details

  const editUser = () => {
    if (userSettings.firstName == "") {
      growl.current.show({
        severity: "error",
        summary: "First name is required.",
      });
      return;
    } else if (userSettings.lastName == "") {
      growl.current.show({
        severity: "error",
        summary: "Last name is required.",
      });
      return;
    } else if (userSettings.userRole == "" && rowState?.userRole == "") {
      growl.current.show({
        severity: "error",
        summary: "User role  is required.",
      });
      return;
    }
    const r = userRole(userSettings.userRole);
    axios
      .put(
        getFullUrl(`/api/organization/user/${rowState?.publicUserId}`, {
          useDedicatedEnvironment: true,
        }),
        {
          FirstName: userSettings.firstName || rowState?.firstName,
          LastName: userSettings.lastName || rowState?.lastName,
          UserRole: r || rowState?.userRole,
          IsEnabled: true,
        },
        header,
      )
      .then(() => {
        const users = companyUsers.map((u) => {
          if (u.publicUserId === rowState?.publicUserId) {
            return {
              ...u,
              firstName: userSettings.firstName || rowState.firstName,
              lastName: userSettings.lastName || rowState.lastName,
              userRole: r || rowState.userRole,
            };
          }
          return u as UserProfileType;
        });
        growl.current.show({
          severity: "success",
          summary: "User info  edited successfully",
        });

        // pl.current?.hide();
        handleCloseAddMemberDrawer();

        setCompanyUsers(users);
      })
      .catch(() => {
        // growl.current.show({
        //   severity: "error",
        //   summary: "Error saving edits",
        // });
        alert("Error saving edits");
      });
  };

  const handleOnhide = () => {
    // reset sidebar  state on hide
    setHeaderText("Add team member");
    setRowState(defaultUserSetting);
    setUserSettings(defaultUserSetting);
    setToggleViewMode(false);
    setToggleButton(false);
    setToggleEditMode(false);
  };

  const show = (r: UserProfileType) => {
    let privatePipelines = [];
    let sharedPipelines = [];

    const isPrivateOnly = () => {
      let p = r.pipelines.every((ai) => {
        //  check if any of this user pipeline is present in other users pipeline
        const si = companyUsers.map((sd) => sd.pipelines.includes(ai));
        if (!si) {
          privatePipelines.push(ai);
        }
      });

      return (
        !!privatePipelines.length &&
        privatePipelines.length !== r.pipelines.length
      );
    };

    // let numberOfPipelines= privatePipelines.length as number;
    const userHasPrivatePipelinesOnly = isPrivateOnly();

    if (r.pipelines.length === 0) {
      Swal.fire({
        html: `<p>Are you sure you want to remove "<strong>${
          r.firstName + " " + r.lastName
        }</strong>" from your team list? Once deleted you cannot retrieve their account information.</p>`,
        showCancelButton: true,
        confirmButtonColor: "#E02020",
        showCloseButton: true,
        cancelButtonColor: "#ECAC37",
        confirmButtonText: "Remove",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          deleteUser(r.publicUserId);
        }
      });
    } else if (userHasPrivatePipelinesOnly) {
      Swal.fire({
        html: `<p>Are you sure you want to remove ${r.firstName} ${r.lastName}
          from your team list?, their private pipeline/s will be lost and
          cannot be retrieved.</p>`,
        showCancelButton: true,
        confirmButtonColor: "#E02020",
        showCloseButton: true,
        cancelButtonColor: "#ECAC37",
        confirmButtonText: "Yes",
        reverseButtons: true,
      }).then((re) => {
        if (re.isConfirmed) {
          deleteUser(r.publicUserId);
        }
      });
    } else {
      Swal.fire({
        html: `<p>Are you sure you want to remove this user from your team's
          list? while this user's shared pipeline/s will still be accessible by relevant
          team members, this user's private pipelines will not be accessible.</p>`,
        showCancelButton: true,
        confirmButtonColor: "#E02020",
        showCloseButton: true,
        cancelButtonColor: "#ECAC37",
        confirmButtonText: "Yes",
        reverseButtons: true,
      }).then((re) => {
        if (re.isConfirmed) {
          deleteUser(r.publicUserId);
        }
      });
    }
  };

  const tableHeader = (
    <div className="p-grid p-justify-end p-align-center">
      <div
        className="p-sm-12 p-md-6 p-lg-4 p-xl-6 topLeftColumnHeader"
        style={{ textAlign: "left" }}
      >
        <div className="search-bar">
          <InputText
            type="search"
            style={{
              color: "#979797",
              fontStyle: "italic",
              borderRadius: "20px",
            }}
            onChange={(e: any) => setKeyword(e.target.value)}
            placeholder="Search..."
          />
        </div>
      </div>
      <div className="p-sm-12 p-md-12 p-lg-4 p-xl-6 p-justify-end p-grid p-align-center buttonsHeader">
        <HeaderButton onClick={showSidePanel}>+ Add Member</HeaderButton>
      </div>
    </div>
  );
  //  Get the current cell/row data
  const getFormattedCellFromBody = function (
    fieldNames: any,
    getValueFunc: any,
  ) {
    return function (row: UserProfileType) {
      return <div>{getValueFunc(row)}</div>;
    };
  };

  //  paginator
  const tableFooter = "Showing {first} to {last} of {totalRecords} entries";
  const paginatorTemplate =
    "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown";

  const StatusTemplate = (obj: any) => {
    // let isEnable = true;
    return (
      <div>
        {obj.isEnable ? (
          <button className="user-status-button-active">Active</button>
        ) : (
          <button className="user-status-button-pending">Pending</button>
        )}
      </div>
    );
  };
  const roleTemplate = (r: UserProfileType) => {
    const rolename =
      r.userRole?.substring(0, 12) +
      " " +
      r.userRole?.substring(12, r.userRole.length);
    return <p>{rolename}</p>;
  };
  const userRoles: Array<string> = ["Member", "Organization Admin"];
  const showMasks = (type: "showMask" | "HideMask") => {
    switch (type) {
      case "showMask":
        setShowMask(false);
        setToggleMask("");
        break;
      case "HideMask":
        setShowMask(true);
        setToggleMask("password");
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <SimpleModal
        open={outsideEmailMessageModalOpen}
        handleClose={() => setOutsideEmailMessageModalOpen(false)}
        title={"Invite a new member"}
        acceptLabel={"Confirm"}
        handleAccept={() => saveNewUser()}
        acceptButtonDisabled={isInviteLoading}
        acceptButtonLoading={isInviteLoading}
        loading={isInviteLoading}
      >
        <Typography
          variant={"h6"}
          color={"textPrimary"}
          fontWeight={500}
          sx={{ mb: 1 }}
        >
          Are you sure you want to add a user outside of your organization?
          Please click confirm to save, or cancel to disregard.
        </Typography>
        <Typography variant={"body2"} color={"textSecondary"}>
          Note: if you add them as member user, they can get the same access to
          the different data sources, can create their own pipelines and get
          access to view/manage pipelines shared with them only.
        </Typography>
      </SimpleModal>
      <div style={{ marginBottom: "10px" }}>
        {" "}
        <SectionHeader text={"Teams"} />
      </div>
      {/*<Panel*/}
      {/*  style={{*/}
      {/*    paddingLeft: "50px",*/}
      {/*    paddingRight: "50px",*/}
      {/*    background: "#FFFFFF",*/}
      {/*    height: "auto !important",*/}
      {/*    boxShadow: "-3px 0px 11px rgba(0, 0, 0, 0.14)",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <OverlayPanel*/}
      {/*    onHide={handleOnhide}*/}
      {/*    ref={pl}*/}
      {/*    className="side-panel"*/}
      {/*    style={{*/}
      {/*      left: "none",*/}
      {/*      right: "0px !important",*/}
      {/*      display: "flex !important",*/}
      {/*    }}*/}
      {/*    dismissable={!outsideEmailMessageModalOpen}*/}
      {/*  >*/}
      {/*    <div*/}
      {/*      style={{*/}
      {/*        height: "auto",*/}
      {/*        minHeight: "70v !important",*/}
      {/*      }}*/}
      {/*    >*/}
      <Drawer
        variant={"temporary"}
        open={addMemberOpen}
        onClose={handleCloseAddMemberDrawer}
        anchor={"right"}
        sx={{
          zIndex: 500,
        }}
        // PaperProps={{
        //   className: "side-panel",
        // }}
      >
        <div
          style={{
            width: "370px",
            height: "200vh",
          }}
        >
          <div className="side-panel-header">
            <SubHeaders text={headerText} />
          </div>
          <div className="p-grid">
            <div className="p-col-12" style={{ textAlign: "left" }}>
              <div className="p-col-12" style={{ marginTop: "40px" }}>
                <label className="user-label" htmlFor="firstName">
                  First Name
                </label>

                {toggleViewMode ? (
                  <>
                    <div
                      className={"email-inner"}
                      id="firstName"
                      {...css({
                        ...sharedStyles.text,
                        ...sharedStyles.radiusBordered,
                      })}
                    >
                      {userSettings.firstName || rowState?.firstName}
                    </div>
                  </>
                ) : (
                  <InputText
                    placeholder=" First Name"
                    className="user-input"
                    defaultValue={rowState?.firstName}
                    onChange={(e) => {
                      onUserSettingsChange("firstName", e.currentTarget.value);
                    }}
                  />
                )}
              </div>
              <div className="p-col-12">
                <label className="user-label" htmlFor="LastName">
                  Last Name
                </label>
                {toggleViewMode ? (
                  <>
                    <div
                      className="email-inner"
                      id="full-name"
                      {...css({
                        ...sharedStyles.text,
                        ...sharedStyles.radiusBordered,
                      })}
                    >
                      {userSettings.lastName?.toLocaleLowerCase() ||
                        rowState?.lastName?.toLocaleLowerCase()}
                    </div>
                  </>
                ) : (
                  <InputText
                    type="text"
                    placeholder="Last Name"
                    className="user-input"
                    defaultValue={rowState?.lastName}
                    onChange={(e) => {
                      onUserSettingsChange("lastName", e.currentTarget.value);
                    }}
                  />
                )}
              </div>
              <div className="p-col-12">
                <label className="user-label" htmlFor="email">
                  Email
                </label>
                {toggleViewMode || toggleEditMode ? (
                  <>
                    <div
                      className="email-inner"
                      {...css({
                        ...sharedStyles.text,
                        ...sharedStyles.radiusBordered,
                      })}
                    >
                      {userSettings.email || rowState?.email}
                    </div>
                  </>
                ) : (
                  <InputText
                    placeholder="email@Organization.com"
                    className="user-input"
                    defaultValue={rowState?.email}
                    onChange={(e) => {
                      onUserSettingsChange("email", e.currentTarget.value);
                    }}
                  />
                )}
              </div>
              <div className="p-col-12">
                <label className="user-label" htmlFor="role">
                  Role
                </label>
                {toggleViewMode ? (
                  <>
                    <div
                      className={"email-inner"}
                      id="userrole"
                      {...css({
                        ...sharedStyles.text,
                        ...sharedStyles.radiusBordered,
                      })}
                    >
                      {rowState?.userRole === "OrganizationAdmin"
                        ? "Organization Admin"
                        : rowState?.userRole}
                    </div>
                  </>
                ) : (
                  <Dropdown
                    className="user-input"
                    value={
                      userSettings.userRole === "OrganizationAdmin"
                        ? "Organization Admin"
                        : userSettings.userRole
                    }
                    options={userRoles}
                    onChange={(e) => {
                      onUserSettingsChange("userRole", e.target.value);
                    }}
                    placeholder={userSettings.userRole || rowState.userRole}
                  />
                )}
              </div>
              <div className="p-col-12" style={{ marginTop: "22%" }}>
                <div
                  className="button-elements"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <button
                    // onClick={() => pl.current?.hide()}
                    onClick={handleCloseAddMemberDrawer}
                    className="edit-user-invite"
                  >
                    Cancel
                  </button>
                  {toggleInviteBtn && (
                    <Button
                      style={{
                        color: "white",
                        borderRadius: "26px",
                        justifyContent: "center",
                        backgroundColor: "#ECAC37",
                        fontSize: "16px",
                      }}
                      disabled={!canSaveUsr || isInviteLoading}
                      onClick={
                        userSettings?.email?.split("@")[1] ===
                        email?.split("@")[1]
                          ? saveNewUser
                          : () => setOutsideEmailMessageModalOpen(true)
                      }
                      className="edit-user-invite"
                      label="Invite"
                    />
                  )}
                  {toggleEditMode && (
                    <Button
                      style={{
                        color: "white",
                        borderRadius: "26px",
                        justifyContent: "center",
                        backgroundColor: "#ECAC37",
                        fontSize: "13px",
                      }}
                      disabled={!canSaveUsr}
                      onClick={editUser}
                      className="edit-user-edit"
                      label="Save Edits"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      {/*    </div>*/}
      {/*  </OverlayPanel>*/}
      {/*</Panel>*/}

      <DataTable
        value={companyUsers}
        globalFilter={keyword}
        // lazy={true}
        loading={isLoading}
        scrollable={true}
        scrollHeight="400px"
        header={tableHeader}
        rows={10}
        resizableColumns
        columnResizeMode="expand"
        paginator={true}
        totalRecords={totalRecords}
        first={first}
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={tableFooter}
        rowsPerPageOptions={[5, 10, 20, 25, 30]}
      >
        <Column
          headerStyle={styles.headerStyle(70)}
          style={styles.rowStyle(30)}
          bodyClassName="source"
          header="First name"
          field="firstName"
          className="cell-data"
          exportable={true}
        />

        <Column
          headerStyle={styles.headerStyle(70)}
          style={styles.rowStyle(30)}
          bodyClassName="source"
          header="Last name"
          field="lastName"
          className="cell-data"
          exportable={true}
        />

        <Column
          headerStyle={styles.headerStyle(70)}
          style={styles.columnStyle(30)}
          // className='cell-data'
          header="Email"
          field="email"
          exportable={true}
        />
        <Column
          headerStyle={styles.headerStyle(70)}
          style={styles.columnStyle(30)}
          header="Roles"
          className="cell-data"
          exportable={true}
          field="userRole"
          body={roleTemplate}
        />

        <Column
          headerStyle={styles.headerStyle(70)}
          style={styles.columnStyle(30)}
          bodyClassName="source"
          header="Status"
          body={StatusTemplate}
          exportable={true}
        />

        <Column
          header="Actions"
          headerStyle={styles.headerStyle(70)}
          style={styles.columnStyle(30)}
          bodyClassName="source"
          body={getFormattedCellFromBody({}, (r: UserProfileType) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="admin-action-button"
                onClick={(e: React.SyntheticEvent<Element, Event>) => {
                  setRowState(r);
                  setToggleButton(false);
                  setToggleViewMode(true);
                  setHeaderText("View team member");
                  // pl.current?.toggle(e);
                  handleOpenAddMemberDrawer();
                }}
              >
                View
              </Button>

              <Button
                className="admin-action-button"
                onClick={(e: React.SyntheticEvent<Element, Event>) => {
                  setRowState(r);
                  setUserSettings(r);
                  setToggleButton(false);
                  setToggleViewMode(false);
                  setToggleEditMode(true);
                  setHeaderText("Edit member details");
                  // pl.current?.toggle(e);
                  handleOpenAddMemberDrawer();
                }}
              >
                Edit
              </Button>
              {email === r.email ? (
                <></>
              ) : (
                <Button className="admin-action-button" onClick={() => show(r)}>
                  Delete
                </Button>
              )}
            </div>
          ))}
        />
      </DataTable>
    </div>
  );
};

export default Team;
