import React from "react";
import UserContext, { ServerOrgRoles, User } from "../../services/UserContext";
import { useHistory } from "react-router-dom";
import { useStyles } from "../../hooks/useStyles";
import {
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import { onEnter } from "../../services/onEnterKey";
import axios from "axios";
import { parseToken, SignInResponseModel } from "./models";

type SignInParams = {
  updateErrorMessage: React.Dispatch<string | null>;
  errorMessage: string | null | undefined;
};

type State = { email: string; password: string };

function SignIn({ updateErrorMessage, errorMessage }: SignInParams) {
  const [state, setState] = React.useState<State>({ email: "", password: "" });
  const history = useHistory();
  const context = React.useContext(UserContext);
  const styles = useStyles();

  const onChange = (newState: State) => {
    updateErrorMessage(null);
    setState(newState);
  };

  const signIn = async () => {
    const { updateCurrentUser } = context;
    if (!state.email || !state.password) {
      updateErrorMessage("Please enter a email and password");
      return;
    }

    await axios
      .post(
        getFullUrl("/api/auth/shouldverify", { useDedicatedEnvironment: true }),
        { email: state.email },
        createRequestWithAuthHeaders(context)
      )
      .then(async (response) => {
        if (response.data) {
          history.push("/verify-email", { email: state.email });
        } else {
          // before signing in, reset refresh token in local storage. this should keep us from flickering
          // on sign in
          window.localStorage.setItem("refreshToken", "");

          await axios
            .post<any, { data: SignInResponseModel }>(
              getFullUrl("/api/auth/signin", { useDedicatedEnvironment: true }),
              { email: state.email, password: state.password },
              createRequestWithAuthHeaders(context)
            )
            .then((response) => {
              var { user, groups, konektidToken } = response.data;
              var parsedIdToken = parseToken(user.idToken);
              const result: User = {
                accessToken: user.accessToken,
                jwt: user.idToken,
                idToken: user.idToken,
                parsedIdToken: parsedIdToken ?? undefined,
                refreshToken: user.refreshToken,
                expiresIn: user.expiresIn,
                groups,
                konektidToken,
              };
              updateCurrentUser(result).then(() => history.push("/"));
            })
            .catch((e) => {
              if (e.response) {
                if (e.response.data.Message) {
                  updateErrorMessage(e.response.data.Message);
                }
                if (typeof e.response.data === 'string'
                  && e.response.data.includes("Having trouble signing in, " +
                  "please check with your organization account administrator")) {
                  updateErrorMessage("Having trouble signing in, please " +
                    "check with your organization account administrator");
                }
              } else {
                updateErrorMessage("Incorrect email or password.");
              }
            });
        }
      })
      .catch((err) => {
        updateErrorMessage("Error signing in.");
      });
  };

  return (
    <div style={styles.innerContainer}>
      <div style={styles.header}>
        <img
          src={styles.aidkonektIcon}
          style={styles.icon}
          alt="Aidkonekt"
          className="formIcon"
        ></img>
        Sign In
      </div>
      <div style={styles.errorDiv}>
        <span>{errorMessage}</span>
      </div>
      <>
        <label htmlFor="email" style={styles.inputLabel}>
          Email
        </label>
        <input
          id="email"
          onChange={(event) => {
            onChange({ ...state, email: event.target.value });
          }}
          style={styles.inputStyle}
        />

        <label htmlFor="password" style={styles.inputLabel}>
          Password
        </label>
        <input
          id="password"
          type="password"
          onKeyUp={onEnter(signIn)}
          onChange={(event) => {
            onChange({ ...state, password: event.target.value });
          }}
          style={styles.inputStyle}
        />
        <div style={styles.buttonStyle} className="auth-btn" onClick={signIn}>
          <p style={styles.buttonTextStyle}>Sign In</p>
        </div>
      </>
    </div>
  );
}

export default SignIn;
