import Input from "../../components/Widgets/Inputs/Input";
import { RootState, useDispatch, useSelector } from "../../store";
import React, { useContext, useEffect } from "react";
import UserContext from "../../services/UserContext";
import { getCountryDropdownOptions } from "../../slices/contracts";
import { getDropdownOptionsForReports } from "../../slices/reports";
import { getECDDropdownOptions } from "../../slices/ecd";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";

interface ECDFiltersProps {
  control: any;
}

export default function ECDFilters({ control }: ECDFiltersProps) {
  const {
    dropdownOptions: {
      fetchStatus,
      bureaus,
      documentTypes,
      sectorTypes,
      countries,
    },
  } = useSelector((state: RootState) => state.ecd);

  const commonInputStyles = {
    "& .MuiFormLabel-root": {
      fontSize: "0.875em",
      lineHeight: "1.875em",
    },
  };

  return (
    <>
      <Input
        type={"combo-box"}
        sx={commonInputStyles}
        control={control}
        name={"Bureaus"}
        label={"BUREAU"}
        options={bureaus}
        multiple
        withCheckboxes
        fullWidth
        loading={RequestStatus.isFetching(fetchStatus)}
      />
      <Input
        type={"combo-box"}
        sx={commonInputStyles}
        control={control}
        name={"Countries"}
        label={"COUNTRY / OPERATING UNIT"}
        options={countries}
        multiple
        withCheckboxes
        fullWidth
        loading={RequestStatus.isFetching(fetchStatus)}
      />
      <Input
        sx={commonInputStyles}
        type={"date"}
        name={"EtdSignatureFiscalYearFrom"}
        control={control}
        label={"FISCAL YEAR APPROVED FROM"}
        datePickerViews={["year"]}
      />
      <Input
        sx={commonInputStyles}
        type={"date"}
        name={"EtdSignatureFiscalYearTo"}
        control={control}
        label={"FISCAL YEAR APPROVED TO"}
        datePickerViews={["year"]}
      />
      <Input
        type={"combo-box"}
        sx={commonInputStyles}
        control={control}
        name={"Sectors"}
        label={"SECTOR TYPES"}
        options={sectorTypes}
        multiple
        withCheckboxes
        fullWidth
        loading={RequestStatus.isFetching(fetchStatus)}
      />
      <Input
        type={"text"}
        sx={commonInputStyles}
        name={"DocumentTitle"}
        control={control}
        label={"DOCUMENT TITLE"}
      />
      <Input
        type={"combo-box"}
        sx={commonInputStyles}
        control={control}
        name={"DocumentType"}
        label={"DOCUMENT TYPE"}
        options={documentTypes}
        // multiple
        // withCheckboxes
        fullWidth
        loading={RequestStatus.isFetching(fetchStatus)}
      />
      <Input
        type={"text"}
        sx={commonInputStyles}
        name={"FileNumber"}
        control={control}
        label={"FILE NUMBER"}
      />
      <Input
        sx={commonInputStyles}
        type={"date"}
        name={"ActivityDateFrom"}
        control={control}
        label={"ACTIVITY DATE FROM"}
      />
      <Input
        sx={commonInputStyles}
        type={"date"}
        name={"ActivityDateTo"}
        control={control}
        label={"ACTIVITY DATE TO"}
      />
      <Input
        sx={commonInputStyles}
        type={"date"}
        name={"ExpirationDateFrom"}
        control={control}
        label={"EXPIRATION DATE FROM"}
      />
      <Input
        sx={commonInputStyles}
        type={"date"}
        name={"ExpirationDateTo"}
        control={control}
        label={"EXPIRATION DATE TO"}
      />
      <Input
        sx={commonInputStyles}
        type={"date"}
        name={"EtdSignatureDateFrom"}
        control={control}
        label={"ETD SIGNATURE DATE FROM"}
      />
      <Input
        sx={commonInputStyles}
        type={"date"}
        name={"EtdSignatureDateTo"}
        control={control}
        label={"ETD SIGNATURE DATE TO"}
      />
      <Input
        type={"text"}
        sx={commonInputStyles}
        name={"DocumentText"}
        control={control}
        label={"SEARCH IN DOCUMENT"}
      />
    </>
  );
}
