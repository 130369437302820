export const qalist = [
  {
    title: "FY 2021 1st Quarter",
    description:
      "On November 19, 2020, USAID held their first quarter FY 2021 Business Forecast and Partner Update engagement. Provided below are the resources shared during the call",
    archives: [
      {
        title: "Webinar Recording",
        link: "https://www.usaid.gov/sites/default/files/FY-2021-1st-Quarter-Business-For.mp3",
        type: "MP3, 14MB",
      },
      {
        title: "Webinar Script",
        link: "https://www.usaid.gov/sites/default/files/documents/November_19_-_Business_Forecast_Script_-_Final.pdf",
        type: "PDF, 152KB",
      },
      {
        title: "General Questions",
        link: "https://www.usaid.gov/sites/default/files/documents/General_-_1st_Qtr_2021_Business_Forecast_QA.pdf",
        type: "PDF, 167KB",
      },
      {
        title: "Washington-Based Questions",
        link: "https://www.usaid.gov/sites/default/files/documents/Washington_-_1st_Qtr_2021_Business_Forecast_Questions_updated.pdf",
        type: "PDF, 355KB",
      },
      {
        title: "Mission-Based Questions",
        link: "https://www.usaid.gov/sites/default/files/documents/Mission_-_1st_Qtr_2021_Business_Forecast_QA_updated.pdf",
        type: "PDF, 1MG",
      },
      {
        title: "November 19, 2020 Business Forecast",
        link: "",
        type: "Excel, 78KB",
      },
    ],
  },
  {
    title: "FY 2020 3rd Quarter Business Forecast Conference Call",
    description:
      "On July 17, USAID hosted its FY2020 3rd Quarter Business Forecast conference call. Responses to the questions submitted, along with a recording and a transcript of the call, are available at these links:",
    archives: [
      {
        title: "Call transcript",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/July_FY2020_Business_Forecast_Transcript.pdf",
        type: "PDF, 121KB",
      },
      {
        title: "Questions & answers",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/July_FY_2020_Business_Forecast_-_updated-28-2020.pdf",
        type: "PDF, 1MB",
      },
    ],
  },
  {
    title: "FY 2020 2ND QUARTER BUSINESS FORECAST CONFERENCE CALL",
    description:
      "On March 13th, USAID hosted its FY 2020 2nd quarter Business Forecast conference call. Responses to questions submitted, as well as a recording and transcript of the call, are available at the links below.",
    archives: [
      {
        title: "Call Recording",
        link: "https://www.usaid.gov/sites/default/files/2020-03-13-BusinessForecastCall.mp3",
        type: "MP3, 14.5MB",
      },
      {
        title: "Call Transcript",
        link: "",
        type: "Word, 30K",
      },
      {
        title: "Business Forecast Q&A",
        link: "https://www.usaid.gov/sites/default/files/2ndQtr2020_BusinessForecastQA.pdf",
        type: "PDF, 862K",
      },
      {
        title: "Business Forecast as of March 13, 2020",
        link: "",
        type: "Excel, 59K",
      },
    ],
  },
  {
    title: "FY 2020 1ST QUARTER BUSINESS FORECAST CONFERENCE CALL",
    description:
      "On December 5, 2019, USAID hosted its FY 2020 1st quarter Business Forecast conference call. Responses to questions submitted, as well as a recording and transcript of the call, are available at the links below.",
    archives: [
      {
        title: "Call Recording",
        link: "https://www.usaid.gov/sites/default/files/Business_Forecast_Conference_call_2019-12-05.mp3",
        type: "MP3, 18MB",
      },
      {
        title: "Call Transcript",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/1st_Qtr_FY2020_Business_Forecast_Transcript.pdf",
        type: "PDF 112K",
      },
      {
        title: "Business Forecast Q&A",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/1st_Qtr_FY_2020_Business_Forecast_Call_QA_Updated_12.9.19-2.pdf",
        type: "PDF 525K",
      },
      {
        title: "Business Forecast as of December 5, 2019",
        link: "",
        type: "Excel 60K",
      },
    ],
  },
  {
    title: "FY 2019 3RD QUARTER BUSINESS FORECAST CONFERENCE CALL",
    description:
      "On July 12, 2019, USAID hosted its FY 2019 2nd quarter Business Forecast conference call. Responses to questions submitted, as well as a recording and transcript of the call, are available at the links below.",
    archives: [
      {
        title: "Call Recording",
        link: "https://www.usaid.gov/sites/default/files/Q3-2019-07-12.mp3",
        type: "MP3, 11.5MB",
      },
      {
        title: "Call Transcript",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/Q3_FY19_Business_Forecast_Script.pdf",
        type: "PDF, 99KB",
      },
      {
        title: "Business Forecast Q&A",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/Business_Forecast_Questions_Q3_FY19.pdf",
        type: "PDF, 519KB",
      },
    ],
  },
  {
    title: "FY 2019 2ND QUARTER BUSINESS FORECAST CONFERENCE CALL",
    description:
      "On March 21, 2019, USAID hosted its FY 2019 2nd quarter Business Forecast conference call. Responses to questions submitted, as well as a recording and transcript of the call, are available at the links below.",
    archives: [
      {
        title: "Call Recording",
        link: "https://www.usaid.gov/sites/default/files/USAID-FY19-Q2-Business-Forecast-Call.mp3",
        type: "MP3, 8.6MB",
      },
      {
        title: "Call Transcript",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/Q2_FY19_Business_Forecast_Transcript.pdf",
        type: "PDF, 132K",
      },
      {
        title: "Business Forecast Q&A",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/Business_Forecast_Questions_Q2_FY19_1.pdf",
        type: "PDF, 488K",
      },
    ],
  },
  {
    title: "FY 2019 1ST QUARTER BUSINESS FORECAST CONFERENCE CALL",
    description:
      "On November 29, 2018, USAID hosted its FY 2019 1st quarter Business Forecast conference call. Responses to questions submitted, as well as a recording and transcript of the call, are available at the links below.",
    archives: [
      {
        title: "Call Recording",
        link: "https://www.usaid.gov/sites/default/files/FY-2019-Q1-Business-Forecast-Call-Recording.mp3",
        type: "MP3, 12.2MB",
      },
      {
        title: "Call Transcript",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/Q1_FY19_Business_Forecast_Transcript.pdf",
        type: "PDF, 129K",
      },
      {
        title: "Business Forecast Q&A",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/Business_Forecast_Questions_Q1_FY19.pdf",
        type: "PDF, 474K",
      },
    ],
  },
  {
    title: "FY 2018 3RD QUARTER BUSINESS FORECAST CONFERENCE CALL",
    description:
      "On March 29, 2018, USAID hosted its FY 2018 2nd quarter Business Forecast conference call. Responses to questions submitted, as well as a recording and transcript of the call, are available at the links below.",
    archives: [
      {
        title: "Call Recording",
        link: "https://www.usaid.gov/sites/default/files/Q3-Biz-Forecast.mp3",
        type: "MP3, 12.2MB",
      },
      {
        title: "Call Transcript",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/Q3_FY2018_Business_Forecast_Transcript-3.pdf",
        type: "PDF, 94K",
      },
      {
        title: "Business Forecast Q&A",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/Business_Forecast_Questions_Q3_FY18_0.pdf",
        type: "PDF, 308K",
      },
    ],
  },
  {
    title: "FY 2018 2ND QUARTER BUSINESS FORECAST CONFERENCE CALL",
    description:
      "On March 29, 2018, USAID hosted its FY 2018 2nd quarter Business Forecast conference call. Responses to questions submitted, as well as a recording and transcript of the call, are available at the links below.",
    archives: [
      {
        title: "Call Recording",
        link: "https://www.usaid.gov/sites/default/files/2018-03-29-4.mp3",
        type: "MP3, 14MB",
      },
      {
        title: "Call Transcript",
        link: "https://www.usaid.gov/sites/default/files/Q2-FY2018-Business-Forecast-Script-2.pdf",
        type: "PDF, 104K",
      },
      {
        title: "Business Forecast Q&A",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/Business_Forecast_Questions_Q2_0.pdf",
        type: "PDF, 679K",
      },
    ],
  },
  {
    title: "1ST QUARTER BUSINESS FORECAST CONFERENCE CALL",
    description:
      "Responses to questions submitted, as well as a recording and transcript of the call, are available at the links below.",
    archives: [
      {
        title: "Call Recording",
        link: "https://www.usaid.gov/sites/default/files/FY_2018_1st_Qtr_Business_Forecast_Call_-_Audio.mp3",
        type: "MP3, 13.2MB",
      },
      {
        title: "Call Transcript",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/FY-2018-1st-Qtr-Busines-_Forecast-Call-Script.pdf",
        type: "PDF, 108K",
      },
      {
        title: "Business Forecast Q&A",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/FY-2018-1st-Quarter-Business-Forecast-QA.pdf",
        type: "PDF, 407K",
      },
    ],
  },
  {
    title: "1ST QUARTER FY 2018 FORECAST",
    description: "",
    archives: [
      {
        title: "Business Forecast",
        link: "",
        type: "Excel Document 62K",
      },
      {
        title: "Business Forecast",
        link: "https://www.usaid.gov/documents/1868/1st-quarter-fy-2018-forecast-november-pdf",
        type: "PDF Document 263K",
      },
    ],
  },
  {
    title: "4TH QUARTER FY 2017  FORECAST",
    description:
      "Responses to questions submitted related to USAID's FY17 4th quarter Business Forecast are available here:",
    archives: [
      {
        title: "Business Forecast Q&A",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/FY2017-Qtr-4-Business-Forecast-QA.pdf",
        type: "PDF 482 KB",
      },
      {
        title: "Business Forecast",
        link: "",
        type: "Excel Document 62K",
      },
      {
        title: "Business Forecast",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/Business-Forecast-4Quarter-July18-2017.pdf",
        type: "PDF Document 269K",
      },
    ],
  },
  {
    title: "3RD QUARTER FY 2017  FORECAST",
    description:
      "Responses to questions submitted related to USAID's FY17 4th quarter Business Forecast are available here:",
    archives: [
      {
        title: "Business Forecast",
        link: "",
        type: "Excel Document 187K",
      },
      {
        title: "Business Forecast",
        link: "https://www.usaid.gov/sites/default/files/FY_2017_Qtr_3_Business_Forecast-April21-2017.pdf",
        type: "PDF Document 403K",
      },
    ],
  },
  {
    title: "FY 2017 3RD QUARTER BUSINESS FORECAST CONFERENCE CALL",
    description:
      "On May 16th at 9:00am EST, USAID hosted its FY 2017 3rd quarter Business Forecast conference call. Responses to questions submitted, as well as a recording and transcript of the call are available at the links below.",
    archives: [
      {
        title: "Call Recording",
        link: "https://www.usaid.gov/sites/default/files/Q3-FY2017-Business-Forecast-Call-Recording.mp3",
        type: "MP3, 10MB",
      },
      {
        title: "Call Transcript",
        link: "https://www.usaid.gov/sites/default/files/Q3-FY2017-Business-Forecast-Script_1.pdf",
        type: "PDF, 108K",
      },
      {
        title: "Business Forecast Q&A",
        link: "https://www.usaid.gov/sites/default/files/Q3-FY2017-BusinessForecast-Q-A.pdf",
        type: "PDF, 450K",
      },
    ],
  },
  {
    title: "2ND QUARTER FY 2017  FORECAST",
    description: "",
    archives: [
      {
        title: "Business Forecast",
        link: "",
        type: "Excel Document 73K",
      },
      {
        title: "Business Forecast",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/FY_2017_Qtr_2_Business_Forecast-Feb02-2016.pdf",
        type: "PDF Document 302K",
      },
    ],
  },
  {
    title: "FY 2017 2ND QUARTER BUSINESS FORECAST CONFERENCE CALL",
    description:
      "On March 7th at 9:00am EST, USAID hosted its FY 2017 2nd quarter Business Forecast conference call. Responses to questions submitted, as well as a recording and transcript of the call, are available at the links below.",
    archives: [
      {
        title: "Call Recording",
        link: "https://www.usaid.gov/sites/default/files/USAIDFY17_Qtr2_ForecastCall.mp3",
        type: "MP3, 10.7MB",
      },
      {
        title: "Call Transcript",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/TranscriptFY2017Qtr2BusinessForecastCall.pdf",
        type: "PDF, 115K",
      },
      {
        title: "Business Forecast Q&A",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/FY2017Qtr2BusinessForecastConferenceQA.pdf",
        type: "PDF, 442K",
      },
    ],
  },
  {
    title: "1ST QUARTER FY 2017  FORECAST",
    description: "",
    archives: [
      {
        title: "Mission Forecast",
        link: "",
        type: "Excel Document 120K",
      },
      {
        title: "Mission Forecast",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/MissionForecast_November18_2016-01.pdf",
        type: "PDF Document 314K",
      },
      {
        title: "Washington Forecast",
        link: "",
        type: "Excel Document 17K",
      },
      {
        title: "Washington Forecast",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/Washington_December02_2016.pdf",
        type: "PDF Document 104K",
      },
    ],
  },
  {
    title: "FY2017 1ST QUARTER BUSINESS FORECAST CONFERENCE CALL",
    description:
      "USAID's Management Bureau Office of Acquisition and Assistance hosted its FY2017 First Quarter Business Forecast review conference call on Tuesday, December 13th at 8:00AM EDT. Provided below are the resources from the call.",
    archives: [
      {
        title: "Call Recording",
        link: "https://www.freeconferencecall.com/wall/recorded_audio?audioRecordingUrl=https%3A%2F%2Frs0000.freeconferencecall.com%2Fstorage%2FsgetFCC2%2FR2XpZ%2FaejPL&subscriptionId=4866166",
        type: "link is external",
      },
      {
        title: "Call Transcript",
        link: "https://www.usaid.gov/sites/default/files/FY2017_Qtr1_BusinessForecastCallScript.pdf",
        type: "PDF, 93K",
      },
      {
        title: "Business Forecast Q&A",
        link: "https://www.usaid.gov/sites/default/files/FY2017Qtr1BFReviewQuestions_12_22_16_Update.pdf",
        type: "PDF, 587K",
      },
    ],
  },
  {
    title: "4TH QUARTER FY 2016 FORECAST",
    description: "",
    archives: [
      {
        title: "Mission Forecast",
        link: "",
        type: "Excel Document 120K",
      },
      {
        title: "Mission Forecast",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/MissionForecast_July-15-2016-01.pdf",
        type: "PDF Document 394K",
      },
      {
        title: "Washington Forecast",
        link: "",
        type: "Excel Document 41K",
      },
      {
        title: "Washington Forecast",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/WashingtonForecast_August03_2016.pdf",
        type: "PDF Document 216K",
      },
    ],
  },
  {
    title: "FY2016 4TH QUARTER BUSINESS FORECAST CONFERENCE CALL",
    description:
      "USAID's Management Bureau Office of Acquisition and Assistance hosted its FY2016 Fourth Quarter Business Forecast review conference call on Thursday, August 4th at 9:30AM EDT. Provided below are the resources from the call.",
    archives: [
      {
        title: "Call Recording",
        link: "https://www.usaid.gov/sites/default/files/73498616-12987223.mp3",
        type: "MP3, 8.1MB",
      },
      {
        title: "Call Transcript",
        link: "https://www.usaid.gov/sites/default/files/FY2016Qtr4BusinessForecastConfCallTranscript.pdf",
        type: "PDF, 114K",
      },
      {
        title: "Business Forecast Q&A",
        link: "https://www.usaid.gov/sites/default/files/FY2016Qtr4BusinessForecastReviewConfCallQuestions2.pdf",
        type: "PDF, 422K",
      },
    ],
  },
  {
    title: "3RD QUARTER FY 2016 FORECAST",
    description: "Business Forecast Published on April 29, 2016",
    archives: [
      {
        title: "Mission Forecast",
        link: "",
        type: "Excel Document 160K",
      },
      {
        title: "Mission Forecast",
        link: "https://www.usaid.gov/sites/default/files/MissionForecast-Feb08-2016-01.pdf",
        type: "PDF Document 361K",
      },
      {
        title: "Washington Forecast",
        link: "",
        type: "Excel Document 104K",
      },
      {
        title: "Washington Forecast",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/WashingtonForecastFY16Qtr3_4_29_2016_01.pdf",
        type: "PDF Document 125K",
      },
    ],
  },
  {
    title: "FY2016 3RD QUARTER BUSINESS FORECAST CONFERENCE CALL",
    description:
      "On Thursday, May 19th at 8:30AM EDT. Provided below are the resources from the call.",
    archives: [
      {
        title: "Call Recording",
        link: "https://www.usaid.gov/sites/default/files/FY2016%20Q3BusinessForecastCall.mp3",
        type: "MP3, 8.7MB",
      },
      {
        title: "Call Transcript",
        link: "https://www.usaid.gov/sites/default/files/FY2016Qtr3BusinessForecastConfCallScript.pdf",
        type: "PDF, 116K",
      },
      {
        title: "Business Forecast Q&A",
        link: "https://www.usaid.gov/sites/default/files/FY2016Qtr3BusinessForecastReviewConfCallQuestions-updated.pdf",
        type: "PDF, 773K",
      },
    ],
  },
  {
    title: "2ND QUARTER FY 2016 FORECAST",
    description: "Business Forecast Published on February 8, 2016",
    archives: [
      {
        title: "Mission Forecast",
        link: "",
        type: "Excel Document 160K",
      },
      {
        title: "Mission Forecast",
        link: "https://www.usaid.gov/sites/default/files/MissionForecast-Feb08-2016-01.pdf",
        type: "PDF Document 362K",
      },
      {
        title: "Washington Forecast",
        link: "",
        type: "Excel Document 60K",
      },
      {
        title: "Washington Forecast",
        link: "https://www.usaid.gov/sites/default/files/WashingtonForecast-Feb08-2016-01.pdf",
        type: "PDF Document 131K",
      },
    ],
  },
  {
    title: "FY2016 2ND QUARTER BUSINESS FORECAST CONFERENCE CALL",
    description:
      "On March 3, 2016, the Management Bureau's Office of Acquisition and Assistance hosted its FY2016 Second Quarter Business Review conference call. You can access the information from the call below.",
    archives: [
      {
        title: "Business Forecast Call Recording",
        link: "https://www.fccdl.in/KAqehr976",
        type: "link is external",
      },
      {
        title: "Transcript from Business Forecast Call",
        link: "https://www.usaid.gov/sites/default/files/Transcript_FY2016Qtr2BusinessForecastConfCall.pdf",
        type: "PDF, 119KB",
      },
      {
        title: "Questions and Answers Submitted for Call",
        link: "https://www.usaid.gov/sites/default/files/FY2016Qtr2BusinessForecastReviewConfCallQuestionsv2.pdf",
        type: "PDF 697KB",
      },
    ],
  },
  {
    title: "FY2016 1ST QUARTER BUSINESS FORECAST CONFERENCE CALL",
    description:
      "USAID's Management Bureau Office of Acquisition and Assistance hosted its FY2016 First Quarter Business Forecast review conference call on Friday, November 20th at 8:30AM EST.",
    archives: [],
  },
  {
    title: "BUSINESS FORECAST CONFERENCE CALL NOVEMBER 20TH, 2015",
    description: "",
    archives: [
      {
        title: "Business Forecast Call Recording",
        link: "https://www.usaid.gov/sites/default/files/73498616-538098361.mp3",
        type: "MP3, 10.4MB",
      },
      {
        title: "Transcript from Business Forecast Call",
        link: "https://www.usaid.gov/sites/default/files/FY2016-Qtr1-Business-Forecast-Review-Conf-Call-Questions.pdf",
        type: "PDF, 331K",
      },
    ],
  },
  {
    title: "Business Forecast Published on November 30, 2015",
    description: "",
    archives: [
      {
        title: "Mission Forecast",
        link: "",
        type: "Excel Document 136K",
      },
      {
        title: "Mission Forecast",
        link: "https://www.usaid.gov/sites/default/files/Missions-November-30-2015-Missions.pdf",
        type: "PDF Document 262K",
      },
    ],
  },
  {
    title: "Business Forecast Published on November 02, 2015",
    description: "",
    archives: [
      {
        title: "Washington Forecast ",
        link: "",
        type: "Excel Document 22K",
      },
      {
        title: "Washington Forecast ",
        link: "https://www.usaid.gov/sites/default/files/WashingtonForecastNovember02_2015.pdf",
        type: "PDF Document 214K",
      },
      {
        title: "Mission Forecast",
        link: "",
        type: "Excel Document 270K",
      },
      {
        title: "Mission Forecast",
        link: "https://www.usaid.gov/sites/default/files/MissionForecastNovember02_2015.pdf",
        type: "PDF Document 50K",
      },
    ],
  },
  {
    main: "4TH QUARTER FY 2015 FORECAST",
    title: "Business Forecast Published on August 2015",
    description: "",
    archives: [
      {
        title: "Washington Forecast",
        link: "",
        type: "Excel Document 21K",
      },
      {
        title: "Washington Forecast ",
        link: "https://www.usaid.gov/sites/default/files/Washington_Qtr4August28_2015.pdf",
        type: "PDF Document 115K",
      },
      {
        title: "Mission Forecast",
        link: "",
        type: "Excel Document 53K",
      },
      {
        title: "Mission Forecast",
        link: "https://www.usaid.gov/sites/default/files/Missions_Qtr4August28_2015.pdf",
        type: "PDF Document 275K",
      },
    ],
  },
  {
    title: "Business Forecast Published on June 2015",
    description: "",
    archives: [
      {
        title: "Washington Forecast",
        link: "",
        type: "Excel Document 21K",
      },
      {
        title: "Washington Forecast ",
        link: "https://www.usaid.gov/sites/default/files/WashingtonJune25_2015.pdf",
        type: "PDF Document 115K",
      },
      {
        title: "Mission Forecast",
        link: "",
        type: "Excel Document 53K",
      },
      {
        title: "Mission Forecast",
        link: "https://www.usaid.gov/sites/default/files/MissionsJune25_2015.pdf",
        type: "PDF Document 275K",
      },
    ],
  },
  {
    main: "3RD QUARTER FY 2015 FORECAST",
    title: "Business Forecast Published on May 2015",
    description: "",
    archives: [
      {
        title: "Washington Forecast",
        link: "",
        type: "Excel Document 22K",
      },
      {
        title: "Washington Forecast ",
        link: "https://www.usaid.gov/sites/default/files/WashingtonForecast.pdf",
        type: "PDF Document 243K",
      },
      {
        title: "Mission Forecast",
        link: "",
        type: "Excel Document 61K",
      },
      {
        title: "Mission Forecast",
        link: "https://www.usaid.gov/sites/default/files/MissionsForecast.pdf",
        type: "PDF Document 397K",
      },
    ],
  },
  {
    title: "Business Forecast Conference Call hosted on June 03, 2015",
    description: "",
    archives: [
      {
        title: "Business Forecast Review Call Recording",
        link: "https://www.usaid.gov/sites/default/files/2015-06-04_BF-Edited.MP3",
        type: "MP3, 27MB",
      },
      {
        title: "Transcript from Business Forecast Review Call",
        link: "https://www.usaid.gov/sites/default/files/ScriptJune3BusinessForecastCall.pdf",
        type: "PDF, 480K",
      },
      {
        title: "Questions Submitted in Advance of Call",
        link: "https://www.usaid.gov/sites/default/files/BusinessForecastQuarter3Review-Questions.pdf",
        type: "PDF, 365K",
      },
      {
        title: "Questions Received during Business Forecast Call",
        link: "https://www.usaid.gov/sites/default/files/BusinessForecastQuestionsReceivedDuringtheCall.pdf",
        type: "PDF, 180K",
      },
    ],
  },
  {
    main: "2ND QUARTER FY 2015 FORECAST",
    title: "Business Forecast Published January 2015",
    description: "",
    archives: [
      {
        title: "Washington Forecast",
        link: "",
        type: "Excel Document 18K",
      },
      {
        title: "Washington Forecast",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/WashingtonForecast2QFY15.pdf",
        type: "PDF Document 100K",
      },
      {
        title: "Mission Forecast",
        link: "",
        type: "Excel Document 68K",
      },
      {
        title: "Mission Forecast",
        link: "https://www.usaid.gov/sites/default/files/documents/1868/MissionsForecast2QFY15.pdf",
        type: "PDF Document 314K",
      },
    ],
  },
  {
    main: "1ND QUARTER FY 2015 FORECAST",
    title: "",
    description: "",
    archives: [
      {
        title: "Washington Forecast",
        link: "",
        type: "Excel Document 18K",
      },
      {
        title: "Washington Forecast",
        link: "https://www.usaid.gov/sites/default/files/WashingtonForecast1QFY2015.pdf",
        type: "PDF Document 98K",
      },
      {
        title: "Mission Forecast",
        link: "",
        type: "Excel Document 48K",
      },
      {
        title: "Mission Forecast",
        link: "https://www.usaid.gov/sites/default/files/MissionsForecast1QFY2015.pdf",
        type: "PDF Document 268K",
      },
    ],
  },
];
