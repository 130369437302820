import { Server } from "http";
import React from "react";

export enum ServerOrgRoles {
  default = "Default",
  free = "Free",
  consultant = "Consultant",
  plus = "Plus",
  pro = "Pro",
  semiAdmin = "SemiAdmin",
  partialAdmin = "PartialAdmin",
  organizationMember = "OrganizationMember",
  organizationAdmin = "OrganizationAdmin",
  // semi admin roles
  EditPrimesAndQuarterlyCallInfo = "EditPrimesAndQuarterlyCallInfo",
  EditUserNotifications = "EditUserNotifications",
  EditCalendarEvents = "EditCalendarEvents",
  EditResourcesPage = "EditResourcesPage",
  // end of semi admin roles

  admin = "SuperAdmin",
}

export type IdToken = {
  aud: string;
  auth_time: number;
  "cognito:username": string;
  //   "custom:export-forecast": string;
  email: string;
  email_verified: boolean;
  event_id: string;
  exp: number;
  iat: number;
  iss: string;
  sub: string;
  token_use: string;
};

export type User = {
  accessToken: string;
  jwt: string;
  idToken?: string;
  parsedIdToken?: IdToken;
  refreshToken: string;
  expiresIn: number;
  groups: ServerOrgRoles[];
  konektidToken?: string;
};

export type UserContextType = {
  isLoaded: boolean;
  updateCurrentUser: (user: User | null) => Promise<void>;
  user: User;
  refreshUserToken: (reloadPage: boolean) => Promise<void>;
};

const defaultUserContext: UserContextType = {
  isLoaded: false,
  updateCurrentUser: (user: User | null) => Promise.resolve(),
  user: {
    accessToken: "",
    jwt: "",
    refreshToken: "",
    expiresIn: 0,
    groups: [],
    konektidToken: "",
  },
  refreshUserToken: (reloadPage: boolean) => Promise.resolve(),
};

export function isUserLoggedIn(context: UserContextType) {
  return !!context?.user?.idToken;
}

export const exportMaxAmounts = {
  forecast: {
    [ServerOrgRoles.default]: 0,
    [ServerOrgRoles.plus]: 500,
    [ServerOrgRoles.pro]: 3500,
    [ServerOrgRoles.admin]: 9999999,
  },
};

export const UserRoleGroups = {
  plusOrHigher: [
    ServerOrgRoles.plus,
    ServerOrgRoles.pro,
    ServerOrgRoles.semiAdmin,
    ServerOrgRoles.admin,
  ],
  proOrHigher: [
    ServerOrgRoles.pro,
    ServerOrgRoles.semiAdmin,
    ServerOrgRoles.admin,
  ],
  semiAdminOrHigher: [ServerOrgRoles.semiAdmin, ServerOrgRoles.admin],
  adminOrHigher: [ServerOrgRoles.admin],
  organizationAdmins: [
    ServerOrgRoles.organizationAdmin,
    ServerOrgRoles.plus,
    ServerOrgRoles.pro,
    ServerOrgRoles.semiAdmin,
    ServerOrgRoles.admin,
  ],
};

export const isUserInRoles = (
  context: UserContextType,
  roles: ServerOrgRoles[]
) => {
  const inRole = !!roles.find((r) => doesUserHaveRole(context, r));
  return inRole;
};

export const isUserAdminOrSemiAdminWith = (
  context: UserContextType,
  role: ServerOrgRoles
) => {
  if (doesUserHaveRole(context, ServerOrgRoles.admin)) return true;
  if (doesUserHaveRole(context, ServerOrgRoles.semiAdmin)) {
    return doesUserHaveRole(context, role);
  }

  return false;
};

export const userRoleInOrg = (
  context: UserContextType,
  role: ServerOrgRoles
) => {
  if (doesUserHaveRole(context, role)) return true;
  return false;
};

export enum FriendlyUserRoleNames {
  default = "Free",
  consultant = "Consultant",
  plus = "Plus",
  pro = "Pro",
  semiAdmin = "Semi-Admin",
  admin = "Admin",
  organizationMember = "OrganizationMember",
  organizationAdmin = "OrganizationAdmin",
}

const roleLookups = [
  { enum: ServerOrgRoles.default, role: "Free" },
  { enum: ServerOrgRoles.free, role: "Free" },
  { enum: ServerOrgRoles.consultant, role: "Consultant" },
  { enum: ServerOrgRoles.plus, role: "Plus" },
  { enum: ServerOrgRoles.pro, role: "Pro" },
  { enum: ServerOrgRoles.semiAdmin, role: "Partial Admin" },
  { enum: ServerOrgRoles.semiAdmin, role: "Partial Admin" },
  { enum: ServerOrgRoles.organizationAdmin, role: "OrganizationAdmin" },
  { enum: ServerOrgRoles.organizationMember, role: "OrganizationMember" },
  {
    enum: ServerOrgRoles.EditPrimesAndQuarterlyCallInfo,
    role: "Edit F+ Prime and Quarterly Call Info",
  },
  {
    enum: ServerOrgRoles.EditUserNotifications,
    role: "Edit Dashboard New Feed",
  },
  {
    enum: ServerOrgRoles.EditCalendarEvents,
    role: "Edit Dashboard Calendar Events",
  },
  {
    enum: ServerOrgRoles.EditResourcesPage,
    role: "Edit Resources Page",
  },
  { enum: ServerOrgRoles.admin, role: "Full Admin" },
];

export const convertRoleToFriendlyName = (en: ServerOrgRoles): string => {
  const match = roleLookups.find((r) => r.enum === en);
  return match ? match.role : "Free";
};

export function doesUserHaveRole(
  context: UserContextType,
  role: ServerOrgRoles
) {
  return !!(
    context &&
    context.user &&
    context.user.groups &&
    context.user.groups.find((r) => r === role)
  );
}

export function getMaxUserRole(context: UserContextType) {
  const admin = doesUserHaveRole(context, ServerOrgRoles.admin);
  const semiAdmin = doesUserHaveRole(context, ServerOrgRoles.semiAdmin);
  const partialAdmin = doesUserHaveRole(context, ServerOrgRoles.partialAdmin);
  const pro = doesUserHaveRole(context, ServerOrgRoles.pro);
  const plus = doesUserHaveRole(context, ServerOrgRoles.plus);
  const consultant = doesUserHaveRole(context, ServerOrgRoles.consultant);

  return admin
    ? ServerOrgRoles.admin
    : semiAdmin
    ? ServerOrgRoles.semiAdmin
    : partialAdmin
    ? ServerOrgRoles.partialAdmin
    : pro
    ? ServerOrgRoles.pro
    : plus
    ? ServerOrgRoles.plus
    : consultant
    ? ServerOrgRoles.consultant
    : ServerOrgRoles.default;
}

const AuthContext = React.createContext<UserContextType>(defaultUserContext);
export default AuthContext;
