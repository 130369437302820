import React, { FunctionComponent } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import DashboardSection from "../DashboardSection";
import EmailNotifications from "./EmailNotifications/EmailNotifications";
import AccountInformation from "./AccountInformation/AccountInformation";
import SectionHeader from "../SectionHeader";

interface ImageString {
  updateBase64String: ({}) => void;
}

const DashboardManagementBeta: FunctionComponent<ImageString> = ({
  updateBase64String,
}) => (
  <DashboardSection>
    <Accordion
      activeIndex={0}
      collapseIcon="pi pi-chevron-up"
      expandIcon="pi pi-chevron-down"
    >
      <AccordionTab header={<SectionHeader text={"Account Information"} />}>
        <AccountInformation updateBase64String={updateBase64String} />
      </AccordionTab>
      <AccordionTab
        header={
          <SectionHeader
            text={"Email Notifications"}
            icon={true}
            icontext={
              "Plus tier users can schedule weekly emails. Pro tier users can also schedule daily emails and notifications for new opportunities added to the Business Forecast."
            }
          />
        }
      >
        <EmailNotifications />
      </AccordionTab>
    </Accordion>
  </DashboardSection>
);

export default DashboardManagementBeta;
