import React, { ChangeEvent, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { useStyles } from "../../hooks/useStyles";
import { useHistory } from "react-router-dom";
import { onEnter } from "../../services/onEnterKey";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../configs/axios-export.custom";
import axios from "axios";

type CustomError = {
  response: { status: number; data: { message: string; stackTrace: string } };
};

function isCustomError(error: CustomError | Error): error is CustomError {
  return !!(error as CustomError)?.response?.data?.message;
}

type SignUpParams = {
  updateErrorMessage: React.Dispatch<string | null>;
  switchState: React.Dispatch<string>;
  errorMessage: string | null | undefined;
};

function SignUp({ updateErrorMessage, errorMessage }: SignUpParams) {
  const [state, setState] = useState({
    password: "",
    email: "",
    authCode: "",
    message: "",
  });
  const [checked, setChecked] = useState(false);
  const styles = useStyles();
  const history = useHistory();

  React.useEffect(() => {}, [checked]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const key = event.currentTarget.id;
    const value = event.currentTarget.value;
    setState((state) => ({ ...state, [key]: value }));
  };

  const signUp = () => {
    if (!checked) {
      updateErrorMessage("Please agree to the terms and conditions");
      return;
    }

    if (!state.password) {
      updateErrorMessage("Please enter a password");
      return;
    }

    if (!state.email) {
      updateErrorMessage("Please enter an email address");
      return;
    }

    const request = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    axios
      .post(
        getFullUrl("/api/auth/signup", { useDedicatedEnvironment: true }),
        {
          email: state.email,
          password: state.password,
        },
        request,
      )
      .then(() => {
        history.push("/verify-email", { email: state.email.toLowerCase() });
        updateErrorMessage(null);
      })
      .catch((err: CustomError | Error | any) => {
        if (err.response?.data?.Message === "Invalid email address format.") {
          updateErrorMessage(err.response.data.Message);
          return;
        }
        if (
          err.response?.data?.Message ===
            "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$" ||
          err.response?.data?.Message ===
            "Password did not conform with policy: Password not long enough"
        ) {
          updateErrorMessage("Password must be at least 8 characters long");
          return;
        }
        if (err.response.status === 409) {
          updateErrorMessage(
            `Email already exists. Please try with different email address, or click on "Sign in" link below to login to your existing account`,
          );
          return;
        }
        if (isCustomError(err)) {
          console.error("error signing up", {
            response: err.response,
            error: err,
          });
          updateErrorMessage(err.response.data.message);
        } else {
          updateErrorMessage(err.message);
        }
      });
  };

  return (
    <div style={styles.innerContainer}>
      <div style={styles.header}>
        <img
          src={styles.aidkonektIcon}
          style={styles.icon}
          alt="Aidkonekt"
          className="formIcon"
        ></img>
        Sign Up
      </div>
      <div style={styles.errorDiv}>
        <p>{errorMessage}</p>
      </div>
      <>
        <label htmlFor="email" style={styles.inputLabel}>
          Email
        </label>
        <input style={styles.inputStyle} id="email" onChange={onChange} />

        <label htmlFor="password" style={styles.inputLabel}>
          Password
        </label>
        <input
          style={styles.inputStyle}
          id="password"
          type="password"
          onKeyUp={onEnter(signUp)}
          onChange={onChange}
        />

        <div className="p-col-12">
          <Checkbox
            inputId="toc"
            checked={checked}
            onChange={(e) => setChecked(e.checked)}
          ></Checkbox>
          <label htmlFor="toc" className="p-checkbox-label">
            I agree to these
            <a
              // href="https://konektid-resources.s3.amazonaws.com/AidKonekt Terms and Conditions User Agreement_Mar 2021.pdf"
              href="https://konektid-resources.s3.amazonaws.com/AidKonekt+Data+-+Terms+and+Conditions+Policy.pdf"
              target="blank"
            >
              {" "}
              Terms and Conditions
            </a>{" "}
            and this
            <a
              href="https://konektid-resources.s3.amazonaws.com/AidKonekt Privacy Policy_2021.pdf"
              target="blank"
            >
              {" "}
              Privacy Policy
            </a>
          </label>
        </div>

        <div style={styles.buttonStyle} className="auth-btn" onClick={signUp}>
          <p style={styles.buttonTextStyle}>Sign Up</p>
        </div>
      </>
    </div>
  );
}

export default SignUp;
