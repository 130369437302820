import {
  DataColumnConfigProps,
  StickyColumnConfigProps,
} from "../../components/MaterialTable/Table";
import { format } from "date-fns";
import { compact } from "lodash";
import { getNewDateNoTimezoneAdjustment } from "../../utils/conversion/date-converters";

export const getGrantsStickyColumnsConfig = (
  canShare: boolean,
  onlyLink: boolean = false,
): Array<StickyColumnConfigProps> => [
  {
    propertyName: "actions",
    label: "Actions",
    width: onlyLink ? 80 : canShare ? 210 : 170,
    isSticky: true,
    cellAlign: "center",
    disableModalWithDetails: true,
  },
];

export const getGrantsColumnsConfig = (
  getSortableColumnPropertyName: (_propertyName: string) => string,
  isDetails: boolean = false,
): Array<DataColumnConfigProps> =>
  compact([
    {
      propertyName: isDetails ? "agencyCode" : "topLevelAgency",
      label: "Agency",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 250,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "agencyName",
      label: "Mission / Bureau",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 250,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    ...(isDetails
      ? [null]
      : [
          {
            propertyName: "opportunityTitle",
            label: "Opportunity Title",
            order: 0,
            isVisible: true,
            sortable: true,
            width: 300,
            getSortableColumnPropertyName: getSortableColumnPropertyName,
            maxNumberOfLines: 3,
          },
        ]),
    {
      propertyName: "description",
      label: "Description",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 350,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
      maxNumberOfLines: 3,
    },
    ...(isDetails
      ? [null]
      : [
          {
            propertyName: "status",
            label: "Opportunity Status",
            order: 0,
            isVisible: true,
            sortable: true,
            width: 200,
            getSortableColumnPropertyName: getSortableColumnPropertyName,
          },
        ]),

    ...(isDetails
      ? [null]
      : [
          {
            propertyName: "manualLastUpdatedDate",
            label: "Last Updated Date",
            order: 0,
            isVisible: true,
            sortable: true,
            width: 200,
            cellFormatter: (value) =>
              new Date(value) && new Date(value).toISOString().startsWith("0")
                ? "N/A"
                : value
                ? format(getNewDateNoTimezoneAdjustment(value), "dd MMMM yyyy")
                : null,
            getSortableColumnPropertyName: getSortableColumnPropertyName,
          },
        ]),

    ...(isDetails
      ? [null]
      : [
          {
            propertyName: "opportunityNumber",
            label: "Opportunity Number",
            order: 0,
            isVisible: true,
            sortable: true,
            width: 250,
            getSortableColumnPropertyName: getSortableColumnPropertyName,
          },
        ]),

    ...(isDetails
      ? [null]
      : [
          {
            propertyName: "fundingInstrumentDescription",
            label: "Funding Instrument Description",
            order: 0,
            isVisible: true,
            sortable: true,
            width: 200,
            getSortableColumnPropertyName: getSortableColumnPropertyName,
          },
        ]),

    ...(isDetails
      ? [null]
      : [
          {
            propertyName: "opportunityCategoryDescription",
            label: "Opportunity Category",
            order: 0,
            isVisible: true,
            sortable: true,
            width: 200,
            getSortableColumnPropertyName: getSortableColumnPropertyName,
          },
        ]),
    ...(isDetails
      ? [null]
      : [
          {
            propertyName: "fundingActivityDescription",
            label: "Category of Funding Activity",
            order: 0,
            isVisible: true,
            sortable: true,
            width: 200,
            getSortableColumnPropertyName: getSortableColumnPropertyName,
            maxNumberOfLines: 2,
          },
        ]),
    {
      propertyName: "numberOfAwards",
      label: "Expected Number of Awards",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 200,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    ...(isDetails
      ? [null]
      : [
          {
            propertyName: "cfdas",
            label: "CFDA Number(s)",
            order: 0,
            isVisible: true,
            sortable: false,
            width: 250,
            getSortableColumnPropertyName: getSortableColumnPropertyName,
          },
        ]),

    {
      propertyName: "costSharing",
      label: "Cost Sharing or Matching Requirement",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 250,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
      cellAlign: "center",
      headerAlign: "center",
    },
    {
      propertyName: "version",
      label: "Version",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 150,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
      disableModalWithDetails: true,
      cellAlign: "center",
      headerAlign: "center",
    },
    ...(isDetails
      ? [null]
      : [
          {
            propertyName: "originalDueDate",
            label: "Original Closing Date for Applications",
            order: 0,
            isVisible: true,
            sortable: true,
            width: 250,
            getSortableColumnPropertyName: getSortableColumnPropertyName,
            cellFormatter: (value) =>
              new Date(value) && new Date(value).toISOString().startsWith("0")
                ? "N/A"
                : value
                ? format(getNewDateNoTimezoneAdjustment(value), "dd MMMM yyyy")
                : null,
            disableModalWithDetails: true,
          },
        ]),
    {
      propertyName: "responseDate",
      label: isDetails
        ? "Response Date"
        : "Current Closing Date for Applications",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 250,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
      cellFormatter: (value) =>
        new Date(value) && new Date(value).toISOString().startsWith("0")
          ? "N/A"
          : value
          ? format(getNewDateNoTimezoneAdjustment(value), "dd MMMM yyyy")
          : null,
      disableModalWithDetails: true,
    },
    {
      propertyName: "postingDate",
      label: "Posting Date",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 250,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
      cellFormatter: (value) =>
        new Date(value) && new Date(value).toISOString().startsWith("0")
          ? "N/A"
          : value
          ? format(getNewDateNoTimezoneAdjustment(value), "dd MMMM yyyy")
          : null,
      disableModalWithDetails: true,
    },
    {
      propertyName: "archiveDate",
      label: "Archive Date",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 250,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
      cellFormatter: (value) =>
        new Date(value) && new Date(value).toISOString().startsWith("0")
          ? "N/A"
          : value
          ? format(getNewDateNoTimezoneAdjustment(value), "dd MMMM yyyy")
          : null,
      disableModalWithDetails: true,
    },
    {
      propertyName: "estimatedFundingFormatted",
      label: "Estimated Total Program Funding",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 200,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "awardCeilingFormatted",
      label: "Award Ceiling",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 200,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "awardFloorFormatted",
      label: "Award Floor",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 200,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    ...(isDetails
      ? [
          {
            propertyName: "modComments",
            label: "Modification Comments",
            order: 0,
            isVisible: true,
            sortable: false,
            width: 400,
            getSortableColumnPropertyName: getSortableColumnPropertyName,
          },
        ]
      : [
          {
            propertyName: "applicantTypeDescription",
            label: "Eligible Applicants",
            order: 0,
            isVisible: true,
            sortable: false,
            width: 400,
            getSortableColumnPropertyName: getSortableColumnPropertyName,
          },
        ]),
    {
      propertyName: "applicantEligibilityDescription",
      label: "Additional Information on Eligibility",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 400,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "agencyContactName",
      label: "Contact Name",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 200,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "agencyContactDescription",
      label: "Contact Description",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 300,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "agencyContactEmail",
      label: "Contact Email",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 200,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "agencyContactPhone",
      label: "Contact Phone",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 200,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    ...(isDetails
      ? [
          {
            propertyName: "sendEmail",
            label: "Send Email",
            order: 0,
            isVisible: true,
            sortable: true,
            width: 200,
            getSortableColumnPropertyName: getSortableColumnPropertyName,
          },
        ]
      : [null]),
    ...(!isDetails
      ? [
          {
            propertyName: "adminActions",
            label: "Admin Actions",
            order: 0,
            isVisible: true,
            disableModalWithDetails: true,
            cellAlign: "center",
            headerAlign: "center",
            hiddenBasedOnRole: true,
            width: 80,
          },
        ]
      : [null]),
  ] as (DataColumnConfigProps | null)[]) as DataColumnConfigProps[];
