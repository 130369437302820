import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import UserContext, {
  doesUserHaveRole,
  isUserInRoles,
  ServerOrgRoles,
  UserRoleGroups,
} from "../../services/UserContext";
import axios from "axios";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import { DataTable } from "primereact/datatable";
import SectionHeader, {
  SubHeaders,
} from "../../components/Dashboard/SectionHeader";
import { Column } from "primereact/column";
import { useGridStyles } from "../../hooks/useGridStyles";
import { InputText } from "primereact/inputtext";
import "./Pipeline.css";
import { OverlayPanel } from "primereact/overlaypanel";
import GrowlContext from "../../services/growlContext";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import MarketPreferencesBeta from "../../components/Dashboard/MarketPreferences/MarketPreferencesBeta";
import DebouncedButton from "./PipelineFragments/DebouncedButton";
import Chip from "../../components/Chip";
import { RadioButton } from "primereact/radiobutton";
import { Capitalize } from "../../utils/Helpers/Capitalize";
import { Popover } from "../../components/Popover/Popover";
// import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { PreferenceFilterTypes } from "../../services/enums";
import {
  excelFileRequest,
  generateExcelFromBlob,
} from "../../services/exporter";
import { UserProfile } from "../../components/Dashboard/DashboardManagement/Users/UserMenu";
import { Button as PButton } from "primereact/components/button/Button";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Box, Dialog, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const HeaderButton = styled.button({
  background: "#FAEACD",
  borderRadius: "26.5px",
  margin: "10px",
  height: "40px",
  padding: "10px",
  border: "none",
  left: "0px",
  cursor: "pointer",
  ":focus": {
    background: "#ECAC37",
  },
});
const CustomLabel = styled.label({
  fontSize: "15px",
  paddingLeft: 0,
});
const Divider = styled.hr({
  width: "100%",
  border: "1px solid #D9D9D9",
});
const OverlayerWindow = styled.div({
  zIndex: 10000,
  top: "1px !important",
  left: "0px",
});
const Div = styled.div({
  border: "5px solid #D9D9D9",
  borderRadius: "35px",
  height: "74px",
  boxSizing: "border-box",
  display: "flex",
  "& > button": {
    background: "#FAEACD",
    borderRadius: "26.5px",
    margin: "10px",
    height: "40px",
    padding: "8px 24px",
    border: "none",
    left: "0px",
    cursor: "pointer",
    ":focus": {
      background: "#ECAC37",
      border: "none",
      color: "#FFFFFF",
    },
  },
});
const customStyle = {
  multiSelect: {
    width: "100%",
    borderRadius: 10,
  },
  chipsContainer: {
    marginTop: "5px",
    display: "flex",
    flexWrap: "wrap" as "wrap",
  },
};

interface Item {
  name: string;
  code: string;
}
interface MarketPrefsItem {
  name: string;
  code: string;
}

type PipelineDto = {
  id: number;
  name: string;
  description: string;
  createdByCognitoId: string;
  businessForecastCustomColumnName: string;
  contractCustomColumnName: string;
  grantCustomColumnName: string;
  pipelineUserRole: string;
  createdOnUtc: string;
  users: [];
  marketPreferences: [];
  grantCount: number;
  contractCount: number;
  forecastCount: number;
  totalOpportunities: number;
  preferenceFilterType: PreferenceFilterTypes;
  keywords: [];
};

export interface Props {
  pipelines: PipelineDto[];
  headerTitle: string;
  deletePipeline(id: number): void;
  restorePipeline(id: number): void;
  totalRecords: number;
  toggledColumns: boolean;
  currentTab: boolean;
}
enum PreferenceTypes {
  country = "Country",
  technicalSector = "TechnicalSector",
  keyword = "Keyword",
}

enum PipelineRole {
  OWNER = "OWNER",
  CAN_MANGE = "CAN_MANGE",
  VIEW_ONLY = "VIEW_ONLY",
}

type MarketPreference = {
  preferenceType: PreferenceTypes;
  name: string;
};

type User = {
  publicUserId: string;
  firstName: string;
  lastName: string;
  userRole: string;
  pipelineUserRole: string;
  email: string;
  label: string;
};
const Pipeline = () => {
  const context = React.useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [pipeline, setPipelines] = useState<PipelineDto[]>(
    Array<PipelineDto>(),
  );
  const [first, setFirst] = React.useState(0);
  const [totalRecords, setTotalrecords] = React.useState(0);
  const [headerTitle, setSubHeader] = React.useState("All Pipelines");
  const [showAllpipelines, setshowAllpipelines] = React.useState(true);
  const [showMyPipelines, setshowMyPipelines] = React.useState(false);
  const [showShared, setShowShared] = React.useState(false);
  const [showDeletedPipelines, setShowDeletedPipelines] = React.useState(false);
  const growl = React.useContext(GrowlContext);
  const [controlRecord, setControlRecord] = useState<PipelineDto[]>(
    Array<PipelineDto>(),
  );
  const isFree = !isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const [toggleColunms, setToggleColumns] = React.useState(false);

  const setSelectedTab = (
    type:
      | "allPipelines"
      | "myPipelines"
      | "sharedPipelines"
      | "deletedPipelines",
  ) => {
    switch (type) {
      case "allPipelines":
        setToggleColumns(false);
        setshowAllpipelines(true);
        setSubHeader("All Pipelines");
        setPipelines(controlRecord);
        setShowDeletedPipelines(false);
        setshowMyPipelines(false);
        setShowShared(false);
        setTotalrecords(controlRecord.length);
        break;
      case "myPipelines":
        setToggleColumns(true);
        setSubHeader("My Pipelines");
        setTotalrecords(myPipelines.length);
        setshowMyPipelines(true);
        setShowDeletedPipelines(false);
        setshowAllpipelines(false);
        setShowShared(false);
        setPipelines(myPipelines);
        break;
      case "sharedPipelines":
        setSubHeader("Shared with me");
        setTotalrecords(sharedPipeline.length);
        setPipelines(sharedPipeline);
        setShowDeletedPipelines(false);
        setShowShared(true);
        setshowAllpipelines(false);
        setshowMyPipelines(false);
        setToggleColumns(true);
        break;
      case "deletedPipelines":
        setSubHeader("Deleted pipelines");
        setTotalrecords(deletedPipelines.length);
        setPipelines(deletedPipelines);
        setShowDeletedPipelines(true);
        setShowShared(false);
        setshowAllpipelines(false);
        setshowMyPipelines(false);
        setToggleColumns(true);
        break;
    }
  };

  const [admins, setAdmins] = React.useState<
    {
      email: string;
      firstName: string;
      lastName: string;
      publicUserId: string;
    }[]
  >([]);

  const getAdmin = () => {
    axios
      .get(
        getFullUrl("/api/organization/admin", {
          useDedicatedEnvironment: true,
        }),
        createRequestWithAuthHeaders(context),
      )
      .then(({ data }) => {
        setAdmins(data);
      });
  };

  React.useEffect(() => {
    getAdmin();
  }, []);

  const [myPipelines, setMyPipelines] = React.useState<PipelineDto[]>(
    Array<PipelineDto>(),
  );
  const [sharedPipeline, setSharedPipeline] = React.useState<PipelineDto[]>(
    Array<PipelineDto>(),
  );
  const [deletedPipelines, setDeletedPipelines] = React.useState<PipelineDto[]>(
    Array<PipelineDto>(),
  );

  const toLowerCase = (str: string | undefined | null) => {
    return !!str ? str.toLocaleLowerCase() : str;
  };

  const loadPipelines = () => {
    axios
      .get(
        getFullUrl("/api/pipeline", { useDedicatedEnvironment: true }),
        createRequestWithAuthHeaders(context),
      )
      .then((response) => {
        //  Reconstruct the pipeline data
        const pipelines: PipelineDto[] = (response.data as PipelineDto[]).map(
          (r) => ({
            id: r.id,
            name: r.name,
            users: r.users,
            description: r.description,
            createdOnUtc: r.createdOnUtc,
            pipelineUserRole: r.pipelineUserRole,
            marketPreferences: r.marketPreferences,
            businessForecastCustomColumnName: "",
            contractCustomColumnName: "",
            grantCustomColumnName: "",
            grantCount: r.grantCount,
            contractCount: r.grantCount,
            forecastCount: r.forecastCount,
            totalOpportunities:
              r.grantCount + r.forecastCount + r.contractCount,
            createdByCognitoId: toLowerCase(r.createdByCognitoId) ?? "",
            preferenceFilterType: r.preferenceFilterType,
            keywords: r.keywords,
          }),
        );

        setPipelines(pipelines);
        setControlRecord(pipelines);
        const mypipelines = pipelines.filter(
          (pipeline) =>
            toLowerCase(context.user.parsedIdToken?.sub) ===
            pipeline.createdByCognitoId,
        );
        const sharedWithMe = pipelines.filter(
          (pipeline) =>
            toLowerCase(context.user.parsedIdToken?.sub) !==
            pipeline.createdByCognitoId,
        );
        setMyPipelines(mypipelines);
        setSharedPipeline(sharedWithMe);
        setTotalrecords(pipelines.length);

        axios
          .get(
            getFullUrl("/api/pipeline?isDeleted=true", {
              useDedicatedEnvironment: true,
            }),
            createRequestWithAuthHeaders(context),
          )
          .then((response) => {
            //  Reconstruct the pipeline data
            const deletedPipelines: PipelineDto[] = (
              response.data as PipelineDto[]
            ).map((r) => ({
              id: r.id,
              name: r.name,
              users: r.users,
              description: r.description,
              createdOnUtc: r.createdOnUtc,
              pipelineUserRole: r.pipelineUserRole,
              marketPreferences: r.marketPreferences,
              businessForecastCustomColumnName: "",
              contractCustomColumnName: "",
              grantCustomColumnName: "",
              grantCount: r.grantCount,
              contractCount: r.grantCount,
              forecastCount: r.forecastCount,
              totalOpportunities:
                r.grantCount + r.forecastCount + r.contractCount,
              createdByCognitoId: toLowerCase(r.createdByCognitoId) ?? "",
              preferenceFilterType: r.preferenceFilterType,
              keywords: r.keywords,
            }));

            setDeletedPipelines(deletedPipelines);
          });

        setIsLoading(false);
      })
      .catch((message) => {
        console.error(message);
        setIsLoading(false);
        // growl.current.show({
        //   severity: "error",
        //   summary: "Error loading pipeline data",
        // });
      });
  };
  React.useEffect(() => {
    //  load pipeline data
    loadPipelines();
  }, []);

  const softDeletePipeline = (id: number) => {
    axios
      .delete(
        getFullUrl(`/api/pipeline/soft/${id}`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      )
      .then(() => {
        const deletedPipeline = pipeline.find((pipeline) => pipeline.id === id);

        const updatedPipelineList = pipeline.filter(
          (pipeline) => pipeline.id !== id,
        );

        const updatedDeletedPipelineList = [
          ...deletedPipelines,
          deletedPipeline!,
        ];

        var mypipelines = updatedPipelineList.filter(
            (x) =>
              toLowerCase(context.user.parsedIdToken?.sub) ===
              x.createdByCognitoId,
          ),
          sharedWithMe = updatedPipelineList.filter(
            (pipeline) =>
              toLowerCase(context.user.parsedIdToken?.sub) !==
              pipeline.createdByCognitoId,
          );

        setMyPipelines(mypipelines);
        setSharedPipeline(sharedWithMe);
        setDeletedPipelines(updatedDeletedPipelineList);
        setPipelines(updatedPipelineList);
        setControlRecord(updatedPipelineList);
        setTotalrecords(updatedPipelineList.length);

        growl.current.show({
          severity: "success",
          summary: "Pipeline has been deleted",
        });
      })
      .catch((message) => {
        console.error(message);
        growl.current.show({
          severity: "error",
          summary: "Error loading market preferences",
        });
      });
  };

  const restorePipeline = (id: number) => {
    axios
      .put(
        getFullUrl(`/api/pipeline/restore/${id}`, {
          useDedicatedEnvironment: true,
        }),
        { id },
        createAuthenticatedRequest(context),
      )
      .then(() => {
        const restoredPipeline = deletedPipelines.find(
          (pipeline) => pipeline.id === id,
        );

        const updatedPipelineList = restoredPipeline
          ? [...controlRecord, restoredPipeline]
          : controlRecord;

        const updatedDeletedPipelineList = deletedPipelines.filter(
          (p) => p.id !== id,
        );

        var mypipelines = updatedPipelineList.filter(
            (x) =>
              toLowerCase(context.user.parsedIdToken?.sub) ===
              x.createdByCognitoId,
          ),
          sharedWithMe = updatedPipelineList.filter(
            (pipeline) =>
              toLowerCase(context.user.parsedIdToken?.email) !==
              pipeline.createdByCognitoId,
          );

        setMyPipelines(mypipelines);
        setSharedPipeline(sharedWithMe);
        setDeletedPipelines(updatedDeletedPipelineList);
        setPipelines(updatedPipelineList);
        setControlRecord(updatedPipelineList);
        setTotalrecords(updatedPipelineList.length);

        growl.current.show({
          severity: "success",
          summary: "Pipeline has been restored",
        });
      })
      .catch((message) => {
        console.error(message);
        growl.current.show({
          severity: "error",
          summary: "Error loading market preferences",
        });
      });
  };

  const deletePipeline = (id: number) => {
    axios
      .delete(
        getFullUrl(`/api/pipeline/${id}`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      )
      .then(() => {
        const updatedPipelineList = controlRecord.filter(
          (pipeline) => pipeline.id !== id,
        );

        var mypipelines = updatedPipelineList.filter(
            (x) =>
              toLowerCase(context.user.parsedIdToken?.sub) ===
              x.createdByCognitoId,
          ),
          sharedWithMe = updatedPipelineList.filter(
            (pipeline) =>
              toLowerCase(context.user.parsedIdToken?.sub) !==
              pipeline.createdByCognitoId,
          );
        const updatedDeletedPipelineList = deletedPipelines.filter(
          (p) => p.id !== id,
        );

        setMyPipelines(mypipelines);
        setSharedPipeline(sharedWithMe);

        setPipelines(updatedPipelineList);
        setControlRecord(updatedPipelineList);
        setTotalrecords(updatedPipelineList.length);
        setDeletedPipelines(updatedDeletedPipelineList);

        growl.current.show({
          severity: "success",
          summary: "Pipeline has been deleted",
        });
      })
      .catch((message) => {
        console.error(message);
        growl.current.show({
          severity: "error",
          summary: "Error loading market preferences",
        });
      });
  };

  const defaultButton = useRef<HTMLButtonElement>(null);
  const myButton = useRef<HTMLButtonElement>(null);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const isMy = urlParams.get("my");

  useEffect(() => {
    if (defaultButton && defaultButton.current && !isMy) {
      defaultButton.current.focus();
    }
  }, [defaultButton]);

  useEffect(() => {
    if (isMy) {
      setTimeout(() => {
        setSelectedTab("myPipelines");
        if (myButton.current) {
          myButton.current.focus();
        }
      }, 500);
    }
  }, []);

  const handleSelectDeletedPipelines = () => {
    setSelectedTab("deletedPipelines");
  };

  return (
    <>
      <div className="p-col p-grid p-justify-center content-block">
        <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
          <Div>
            <button
              ref={defaultButton}
              onClick={() => setSelectedTab("allPipelines")}
            >
              <strong>All Pipelines</strong>
            </button>
            {isFree ? null : (
              <>
                <button
                  ref={myButton}
                  onClick={() => setSelectedTab("myPipelines")}
                >
                  <strong>My Pipelines</strong>
                </button>
                <button onClick={() => setSelectedTab("sharedPipelines")}>
                  <strong> Shared With Me</strong>
                </button>
              </>
            )}
            <button onClick={handleSelectDeletedPipelines}>
              <strong> Deleted Pipelines</strong>
            </button>
          </Div>
        </div>
        <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
          <Div
            style={{
              display: "block",
              alignItems: "center",
              padding: "20px",
              minHeight: "80vh",
              height: "auto",
            }}
          >
            <div>
              {showAllpipelines ? (
                <PipelineTable
                  currentTab={true}
                  toggledColumns={toggleColunms}
                  totalRecords={totalRecords}
                  deletePipeline={softDeletePipeline}
                  restorePipeline={restorePipeline}
                  headerTitle={headerTitle}
                  pipelines={pipeline}
                />
              ) : null}
              {isFree ? null : (
                <>
                  {showMyPipelines ? (
                    <PipelineTable
                      toggledColumns={toggleColunms}
                      totalRecords={totalRecords}
                      deletePipeline={softDeletePipeline}
                      restorePipeline={restorePipeline}
                      currentTab={true}
                      headerTitle={headerTitle}
                      pipelines={myPipelines}
                    />
                  ) : null}
                  {showShared ? (
                    <PipelineTable
                      toggledColumns={toggleColunms}
                      totalRecords={totalRecords}
                      deletePipeline={softDeletePipeline}
                      restorePipeline={restorePipeline}
                      currentTab={false}
                      headerTitle={headerTitle}
                      pipelines={sharedPipeline}
                    />
                  ) : null}
                </>
              )}
              {showDeletedPipelines ? (
                <PipelineTable
                  currentTab={true}
                  toggledColumns={toggleColunms}
                  totalRecords={totalRecords}
                  deletePipeline={deletePipeline}
                  restorePipeline={restorePipeline}
                  headerTitle={headerTitle}
                  pipelines={deletedPipelines}
                />
              ) : null}
            </div>
          </Div>
        </div>
      </div>
    </>
  );
};

export default Pipeline;

const PipelineTable: FC<Props> = ({
  pipelines,
  headerTitle,
  deletePipeline,
  restorePipeline,
  totalRecords,
  toggledColumns,
  currentTab,
}) => {
  const context = React.useContext(UserContext);
  const isMember = doesUserHaveRole(context, ServerOrgRoles.organizationMember);
  const [keyword, setKeyword] = React.useState("");
  const header = createAuthenticatedRequest(context);
  const growl = React.useContext(GrowlContext);
  const [togglePrivacyTab, setTogglePrivacyTab] = React.useState(false);
  const [togglesidebarBtn, settogglesidebarBtn] = React.useState(false);
  const [siderBarHeader, setSidebarHeader] = React.useState("Add Pipeline");
  const styles = useGridStyles();
  const [first, setFirst] = React.useState(0);
  const history = useHistory();
  const { user } = context;

  const setUserLabel = (userList) => {
    userList.map((user) => {
      if (user) {
        user.label =
          Capitalize(
            !user.firstName && !user.lastName ? user.email : user.firstName,
          ) +
          " " +
          Capitalize(user.lastName ?? "");
      }
    });
  };

  const goTopipeline = (pipeline: PipelineDto) => {
    if (isFree) {
      growl.current.show({
        severity: "error",
        summary: (
          <p>
            You don't have access to this feature with your current subscription
            plan,{" "}
            {!isMember ? (
              <a href="/tiers"> Upgrade</a>
            ) : (
              "contact your organizations' administrator to upgrade"
            )}{" "}
            to view pipeline details
          </p>
        ),
        life: 10000,
        closable: true,
      });
    } else {
      history.push(`pipeline/${pipeline.id}`, pipeline);
    }
  };

  const initialize = {} as PipelineDto;
  initialize.name = "";
  initialize.id = 0;
  initialize.users = [];
  initialize.businessForecastCustomColumnName = "";
  initialize.contractCustomColumnName = "";
  initialize.contractCustomColumnName = "";
  initialize.description = "";
  initialize.createdOnUtc = "";
  initialize.pipelineUserRole = "";
  initialize.marketPreferences = [];
  initialize.totalOpportunities = 0;
  initialize.grantCount = 0;
  initialize.contractCount = 0;
  initialize.forecastCount = 0;
  initialize.keywords = [];

  const [currentRowData, setCurrentRowData] =
    React.useState<PipelineDto>(initialize);

  //  sidebar
  const [title, setTitle] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [companyUsers, setCompanyUsers] = React.useState<User[]>([]);
  const [selected, setSelected] = React.useState({});
  const popupMenu = useRef<OverlayPanel>(null);
  const isFree = !isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const setFields = (field, value) => {
    setSelected({
      ...selected,
      [field]: value,
    });
  };
  // const {users} = currentRowData
  const [pipeusers, setPipelineUser] = React.useState<User[]>([]);
  const [selectedCountries, setSelectedCountires] = React.useState<
    MarketPrefsItem[]
  >(Array<MarketPrefsItem>());
  const [selectedSectors, setSelectedSectors] = React.useState<
    MarketPrefsItem[]
  >(Array<MarketPrefsItem>());
  const [preferenceFilterType, setPreferenceFilterType] =
    React.useState<PreferenceFilterTypes>(PreferenceFilterTypes.And);
  const [activeTab, setActiveTab] = React.useState(0);
  const [selectedKeywords, setSelectedKeywords] = React.useState<any[]>([]);
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState("");

  const GetSelectedCountries = (data: any) => {
    setSelectedCountires(data);
  };
  const GetSelectedSectors = (data: any) => {
    setSelectedSectors(data);
  };
  const GetSelectedKeywords = (data: any) => {
    setSelectedKeywords(data);
  };
  const GetPreferenecFilterType = (data: any) => {
    setPreferenceFilterType(data);
  };

  const keywordHasChanged = (oldKeyword: any, newKeyword: any) => {
    if (oldKeyword.length !== newKeyword.length) return true;

    let changed = false;

    oldKeyword.forEach((element) => {
      const found = newKeyword.find((obj) => {
        return obj.name === element.keyword;
      });
      if (!found) changed = true;
    });
    return changed;
  };

  const preferenceHasChanged = (oldPref: any, newPref: any) => {
    if (oldPref.length !== newPref.length) return true;

    let changed = false;

    oldPref.forEach((element) => {
      const found = newPref.find((obj) => {
        return (
          obj.preferenceType == element.preferenceType &&
          obj.name === element.name
        );
      });
      if (!found) changed = true;
    });
    return changed;
  };
  React.useEffect(() => {
    // setPipelineUser(currentRowData.users)

    axios
      .get(
        getFullUrl("/api/organization/user", { useDedicatedEnvironment: true }),
        createAuthenticatedRequest(context),
      )
      .then((response) => {
        const { data } = response;
        const emailId = context.user.parsedIdToken?.email;
        setUserLabel(data);
        if (emailId) {
          // var id = email[0]+ "@" + email[1]
          const orgUsers = data.filter(
            (r: User) => r.email.toLocaleLowerCase() !== emailId,
          );

          setCompanyUsers(orgUsers);
        } else {
          setCompanyUsers(data);
        }
      })
      .catch((e) => {
        console.error(e);
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: "Unable to load users",
          });
        }
      });
  }, []);

  const togglePrivacy = () => {
    setActiveTab(1);
    setTogglePrivacyTab(true);
  };

  const saveNewPipeline = async () => {
    // Convert UserID to uppercase
    const pipelineUsers = pipeusers.map((sa) => ({
      publicUserId: sa.publicUserId.toUpperCase(),
      role:
        sa.userRole === "View only" ||
        sa.userRole === "Member" ||
        sa.userRole === null ||
        sa.userRole === undefined
          ? "Member"
          : "Admin",
    }));

    if (title === "") {
      growl.current.show({
        severity: "error",
        summary: "Title required",
      });
      return;
    }

    await axios
      .post(
        getFullUrl("/api/pipeline", { useDedicatedEnvironment: true }),
        {
          Name: title,
          Description: desc,
          BusinessForecastCustomColumnName: "Internal ID",
          ContractCustomColumnName: "Internal ID",
          GrantCustomColumnName: "Internal ID",
          Users: pipelineUsers,
          PreferenceFilterType: preferenceFilterType,
        },
        header,
      )
      .then(async (res) => {
        const { id } = res.data;
        //  add  related market preferences
        await addSelectedMarketPref(id);
        growl.current.show({
          severity: "success",
          summary: "Pipeline has been created successfully",
        });
        await context.refreshUserToken(true);
      })
      .catch(() => {
        growl.current.show({
          severity: "error",
          summary: "Error saving the pipeline",
        });
      });
  };

  const editPipeline = async () => {
    const pipelineUsers = pipeusers.map((sa) => {
      return {
        publicUserId: sa.publicUserId.toUpperCase(),
        role:
          sa.userRole === "View only" || sa.userRole === "Member"
            ? "Member"
            : sa.userRole === "Can manage"
            ? "Admin"
            : sa.pipelineUserRole,
      };
    });

    if (title === "") {
      growl.current.show({
        severity: "error",
        summary: "The title is required",
      });
      return;
    }

    await axios
      .put(
        getFullUrl(`/api/pipeline/${currentRowData.id}`, {
          useDedicatedEnvironment: true,
        }),
        {
          Name: title,
          Description: desc,
          BusinessForecastCustomColumnName: "Internal ID",
          ContractCustomColumnName: "Internal ID",
          GrantCustomColumnName: "Internal ID",
          Users: pipelineUsers,
          PreferenceFilterType: preferenceFilterType,
        },
        header,
      )
      .then(async () => {
        //  cascade and edit market preference as well
        await addSelectedMarketPref(currentRowData.id);
        growl.current.show({
          severity: "success",
          summary: "Pipeline has been edited",
        });
        await context.refreshUserToken(true);
      })

      .catch((message) => {
        console.error(message);
        growl.current.show({
          severity: "error",
          summary: "Error saving info",
        });
      });
  };

  const toLowerCase = (str: string | undefined | null) => {
    return !!str ? str.toLocaleLowerCase() : str;
  };

  const selectUsers = useCallback(async (usersToAdd: User[]) => {
    setPipelineUser(usersToAdd);
  }, []);
  const userTemplate = (option) => {
    return (
      <div className="inner-list" key={option.publicUserId}>
        {Capitalize(
          !option.firstName && !option.lastName
            ? option.email
            : option.firstName,
        )}{" "}
        {Capitalize(option.lastName ?? "")}
      </div>
    );
  };

  const RemoveUser = (userToRemove: User, Users: User[]) => {
    const updatedUsers = Users.filter(
      (user) => user.publicUserId !== userToRemove.publicUserId,
    );
    setPipelineUser(updatedUsers);
    if (companyUsers.includes(userToRemove)) {
      return;
    } else {
      setCompanyUsers([userToRemove, ...companyUsers]);
    }
  };

  const addSelectedMarketPref = async (id: number) => {
    let countries = selectedCountries.map((r) => {
      return {
        preferenceType: PreferenceTypes.country.toString(),
        name: r.code,
      };
    });
    let sectors = selectedSectors.map((r) => ({
      preferenceType: PreferenceTypes.technicalSector.toString(),
      name: r.name,
    }));

    let keywords = selectedKeywords.map((r) => {
      return {
        name: r,
        preferenceType: PreferenceTypes.keyword.toString(),
      };
    });

    const data = [...countries, ...sectors, ...keywords];

    if (
      currentRowData &&
      !preferenceHasChanged(currentRowData.marketPreferences, data) &&
      currentRowData.preferenceFilterType === preferenceFilterType &&
      !keywordHasChanged(currentRowData.keywords, keywords)
    ) {
      return;
    }

    await axios
      .put(
        getFullUrl(`/api/pipeline/${id}/marketpreference`, {
          useDedicatedEnvironment: true,
        }),
        data,
        header,
      )
      .then((res) => {})
      .catch((message) => {
        console.error(message.response.data);
        growl.current.show({
          severity: "error",
          summary: "Error saving info",
        });
      });
  };

  // row templates
  const tableHeader = (
    <div className="p-grid p-justify-end p-align-center">
      <div
        className="p-sm-12 p-md-6 p-lg-4 p-xl-6 topLeftColumnHeader"
        style={{ textAlign: "left" }}
      >
        <InputText
          type="search"
          style={{
            color: "#979797",
            fontStyle: "italic",
            borderRadius: "20px",
          }}
          onChange={(e: any) => setKeyword(e.target.value)}
          placeholder="Search..."
        />
      </div>
      <div className="p-sm-12 p-md-12 p-lg-4 p-xl-6 p-justify-end p-grid p-align-center buttonsHeader">
        <PButton
          type="button"
          icon="pi pi-file-excel"
          iconPos="left"
          onClick={(e) => {
            if (isFree) {
              growl.current.show({
                severity: "error",
                summary: (
                  <p>
                    You don't have access to this feature with your current
                    subscription plan,{" "}
                    {!isMember ? (
                      <a href="/tiers"> Upgrade</a>
                    ) : (
                      "contact your organizations' administrator to upgrade"
                    )}{" "}
                    to view pipeline details
                  </p>
                ),
                life: 10000,
                closable: true,
              });
            } else {
              setToDate(new Date());
              setFromDate(new Date());
              downloadPopup.current?.toggle(e);
            }
          }}
          tooltip="Excel"
          tooltipOptions={{ position: "top" }}
          style={{
            marginLeft: 15,
            marginRight: 15,
            ...styles.tableHeaderButton,
          }}
        />
        <HeaderButton
          onClick={(e: React.SyntheticEvent<Element, Event>) => {
            if (isFree) {
              growl.current.show({
                severity: "error",
                summary: (
                  <p>
                    You don't have access to this feature with your current
                    subscription plan,{" "}
                    {!isMember ? (
                      <a href="/tiers"> Upgrade</a>
                    ) : (
                      "contact your organizations' administrator to upgrade"
                    )}{" "}
                    to view pipeline details
                  </p>
                ),
                life: 10000,
                closable: true,
              });
            } else {
              setTogglePrivacyTab(false);
              setActiveTab(0);
              popupMenu.current?.toggle(e);
            }
          }}
        >
          + Add Pipeline
        </HeaderButton>
      </div>
    </div>
  );

  // Download opportunities
  const downloadPopup = useRef<OverlayPanel>(null);
  const [fromDate, setFromDate] = React.useState<Date | null>(new Date());
  const [toDate, setToDate] = React.useState<Date | null>(new Date());
  const [isDownloading, setIsDownloading] = React.useState(false);

  const handleDownloadPipelines = async () => {
    setIsDownloading(true);

    const ids = pipelines.map((pipeline) => pipeline.id);

    if (!ids.length) {
      setIsDownloading(false);
      growl.current.show({
        severity: "error",
        summary: "Unable to download pipelines",
      });
      return;
    }

    try {
      const {
        data: { firstName, lastName },
      } = await axios.get(
        getFullUrl("/api/user/profile", { useDedicatedEnvironment: true }),
        createAuthenticatedRequest(context),
      );
      await excelFileRequest(
        context,
        toDate ?? new Date(),
        fromDate ?? new Date(),
        ids,
        `${firstName} ${lastName}`,
      );
    } catch (e) {
      console.error(e);
      growl.current.show({
        severity: "error",
        summary: "Unable to download pipelines",
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const dateStringTemplate = (rowdata) => {
    let strDate = rowdata.createdOnUtc.split("T")[0];
    return <div style={{ padding: 10 }}>{strDate}</div>;
  };

  const privacyTemplate = (rowdata: PipelineDto) => {
    const p = rowdata.users.filter((r: User) => r.pipelineUserRole !== "Admin");
    let privacy: string =
      rowdata.users.length > 1
        ? "Shared with " + (rowdata.users.length - 1) + " Member(s)"
        : "Private";
    return (
      <div
        className="center-cell-content"
        style={{
          padding: 10,
        }}
      >
        <p> {privacy}</p>
      </div>
    );
  };

  const ownerTemplate = (rowdata) => {
    let owner = rowdata.users.filter(
      (sa) =>
        toLowerCase(sa.publicUserId) ===
        toLowerCase(rowdata.createdByCognitoId),
    );
    return (
      <div
        style={{
          padding: 10,
        }}
      >
        {owner.map((r, i) => (
          <p key={i} id={r.publicUserId}>
            {r.firstName}
            {""}
            {r.lastName}
          </p>
        ))}
      </div>
    );
  };

  const OpportunitiesTemplate = (r: PipelineDto) => {
    return (
      <div className="center-cell-content">
        <p
          style={{
            textAlign: headerTitle === "My Pipelines" ? "left" : "center",
            color: "#197ab5",
            textDecoration: "underline",
          }}
        >
          {r.totalOpportunities}
        </p>
      </div>
    );
  };
  const countriesTemplate = (rowdata: PipelineDto) => {
    const selectedCount = rowdata.marketPreferences.filter(
      (refs: MarketPreference) =>
        refs.preferenceType == PreferenceTypes.country.toString(),
    );

    return (
      <div
        className="countries"
        style={{
          fontWeight: 600,
          cursor: "pointer",
          justifyContent: selectedCount.length > 3 ? "center" : "left",
        }}
        onClick={() => {
          if (selectedCount.length > 3) {
            setDialogHeader("Countries");
            setDialogText(
              selectedCount.map((i: Item) => " " + i.name).toString(),
            );
          }
        }}
      >
        {selectedCount.length > 3
          ? selectedCount.length + " Selected.."
          : selectedCount.map((sa: Item, i) => (
              <p
                style={{ margin: "0px" }}
                className="selected-countries"
                key={i}
              >
                {sa.name === "USAID" ||
                sa.name.toLocaleLowerCase() ===
                  "agency for international development"
                  ? "Agency for International Development"
                  : sa.name}
              </p>
            ))}
      </div>
    );
  };
  const sectorsTemplate = (rowdata: PipelineDto) => {
    const selectedSect = rowdata.marketPreferences.filter(
      (refs: MarketPreference) =>
        refs.preferenceType == PreferenceTypes.technicalSector.toString(),
    );

    return (
      <div
        className="countries"
        style={{
          fontWeight: 600,
          cursor: "pointer",
          justifyContent: selectedSect.length > 3 ? "center" : "left",
        }}
        onClick={() => {
          if (selectedSect.length > 3) {
            setDialogHeader("Sectors");
            setDialogText(
              `<ul style='text-align:left' >${selectedSect
                .map((i: Item) => "<li>" + i.name + "</li>")
                .join("")}</ul>`,
            );
          }
        }}
      >
        {selectedSect.length > 3
          ? selectedSect.length + " Selected.."
          : selectedSect.map((sa: Item, i) => (
              <p
                style={{ margin: "4px" }}
                className="selected-countries"
                key={i}
              >
                {sa.name}
              </p>
            ))}
      </div>
    );
  };
  const tableFooter = "Showing {first} to {last} of {totalRecords} entries";
  const paginatorTemplate =
    "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown";
  function searchTable(pipelineRecords: PipelineDto[]) {
    return pipelineRecords.filter(
      (pipeline) =>
        // TO DO  convert  to debounce search.
        pipeline.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
        pipeline.description.toLowerCase().indexOf(keyword.toLowerCase()) >
          -1 ||
        pipeline.createdOnUtc.toLowerCase().indexOf(keyword.toLowerCase()) > -1,
    );
  }

  const getFormattedCellFromBody = function (
    fieldNames: any,
    getValueFunc: any,
  ) {
    return function (row: PipelineDto) {
      return <div>{getValueFunc(row)}</div>;
    };
  };
  function getDifference(c1: User[], c2: User[]) {
    return c1.filter((sa) => {
      return !c2.some((r) => {
        return sa.publicUserId === toLowerCase(r.publicUserId);
      });
    });
  }
  const selectedRow = (
    record: PipelineDto,
    e: React.SyntheticEvent<Element, Event>,
  ) => {
    // set row data
    setCurrentRowData(record);
    setSelectedCountires(
      record.marketPreferences.filter((item: MarketPreference) => {
        if (item.preferenceType === PreferenceTypes.country.toString()) {
          return item;
        }
      }),
    );

    setSelectedSectors(
      record.marketPreferences.filter((item: MarketPreference) => {
        if (
          item.preferenceType === PreferenceTypes.technicalSector.toString()
        ) {
          return item;
        }
      }),
    );

    // setSelectedCountires
    setUserLabel(record.users);
    setPipelineUser(record.users);
    setSidebarHeader(record.name);
    setTitle(record.name);
    setDesc(record.description);
    setTogglePrivacyTab(true);
    settogglesidebarBtn(true);
    setPreferenceFilterType(record.preferenceFilterType);
    //  remove already added users from options
    setCompanyUsers(companyUsers);
    // toggle overlay sidebar
    if (popupMenu && popupMenu.current) {
      popupMenu.current.toggle(e);
    }
  };

  const adminActionsTemplate = (r: PipelineDto) => {
    return (
      <>
        {
          <div className="admin-action-buttons">
            {headerTitle !== "Deleted pipelines" && (
              <Button
                style={{ cursor: "pointer" }}
                className="admin-action-button"
                onClick={(e: React.SyntheticEvent<Element, Event>) => {
                  // e.preventDefault()
                  selectedRow(r, e);
                  setTogglePrivacyTab(false);
                  setActiveTab(0);
                }}
              >
                <span className="btn-lab">Edit</span>
              </Button>
            )}
            {r.pipelineUserRole === "Admin" && (
              <>
                <p
                  onClick={() => {
                    setSoftDeleteDialog(true);
                    setSoftDeleteDialogName(r.name);
                    setSoftDeleteDialogId(r.id);
                  }}
                >
                  Delete
                </p>
                {headerTitle === "Deleted pipelines" && (
                  <p onClick={() => restorePipeline(r.id)}>Restore</p>
                )}
              </>
            )}
          </div>
        }
      </>
    );
  };

  const handleOnhide = () => {
    setSidebarHeader("Add new pipeline");
    setCurrentRowData(initialize);
    setPipelineUser(initialize.users);
    setTitle("");
    setDesc("");
    settogglesidebarBtn(false);
    setTogglePrivacyTab(false);
  };

  const [softDeleteDialog, setSoftDeleteDialog] = React.useState(false);
  const [softDeleteDialogName, setSoftDeleteDialogName] = React.useState("");
  const [softDeleteDialogId, setSoftDeleteDialogId] = React.useState(-1);

  function toggle(event) {
    var popup = document.getElementById("overlay_panel");
    if (popup?.style.display === "none") {
      popup.style.setProperty("display", "flex", "important");
    } else {
      popup?.style.setProperty("display", "none", "important");
    }
    event.stopPropagation();
  }

  const roles = ["View only", "Can manage"];

  const handleRoleChanged = (userId: string, role: string) => {
    const updatedUsers = pipeusers.map((user) => {
      if (userId === user.publicUserId) {
        return { ...user, userRole: role };
      } else {
        return user;
      }
    });

    setPipelineUser(updatedUsers);
  };

  const [admins, setAdmins] = React.useState<
    {
      email: string;
      firstName: string;
      lastName: string;
      publicUserId: string;
    }[]
  >([]);

  const getAdmin = () => {
    axios
      .get(
        getFullUrl("/api/organization/admin", {
          useDedicatedEnvironment: true,
        }),
        createRequestWithAuthHeaders(context),
      )
      .then(({ data }) => {
        setAdmins(data);
      });
  };

  React.useEffect(() => {
    getAdmin();
  }, []);

  const formattedRole = (role: string) => {
    switch (role) {
      case "Admin":
        return "Can manage";
      default:
        return "View only";
    }
  };

  const owner = pipeusers.find(
    (p) => toLowerCase(p.publicUserId) === currentRowData.createdByCognitoId,
  );

  return (
    <div>
      <OverlayPanel
        onHide={handleOnhide}
        ref={popupMenu}
        showCloseIcon
        id="overlay_panel"
        style={{
          left: "none",
          right: "0px !important",
          display: "flex !important",
        }}
      >
        <div className="side-panel-container">
          <div style={{ width: "535px", height: "107vh" }}>
            <div className="side-panel-header">
              <SubHeaders text={siderBarHeader} />
            </div>
            <div className="tabview">
              <div>
                <TabView
                  activeIndex={activeTab}
                  onTabChange={(e) => {
                    setTogglePrivacyTab(e.index === 1);
                    setActiveTab(e.index);
                  }}
                >
                  <TabPanel header="Details">
                    <div className="p-grid text-labels">
                      <div className="p-col-12" style={{ textAlign: "left" }}>
                        <div className="p-col-12">
                          <CustomLabel htmlFor="Title">Title*</CustomLabel>
                          <InputText
                            placeholder="Title"
                            className="field-inputs"
                            disabled={
                              togglesidebarBtn &&
                              currentRowData.pipelineUserRole !== "Admin"
                            }
                            defaultValue={title}
                            style={{
                              marginTop: 8,
                            }}
                            // value={}
                            onChange={(e) => {
                              e.preventDefault();
                              setTitle((e.target as HTMLInputElement).value);
                            }}
                          />
                        </div>
                        <div className="p-col-12">
                          <CustomLabel
                            className="field-titles"
                            htmlFor="Message"
                          >
                            Description
                          </CustomLabel>
                          <InputTextarea
                            value={desc || currentRowData.description}
                            placeholder="Pipeline Description"
                            disabled={
                              togglesidebarBtn &&
                              currentRowData.pipelineUserRole !== "Admin"
                            }
                            style={{
                              width: "100%",
                              marginTop: 8,
                            }}
                            rows={5}
                            cols={40}
                            onChange={(e) =>
                              setDesc((e.target as HTMLInputElement).value)
                            }
                          />
                        </div>
                        {isFree ? null : (
                          <div className="p-col-12">
                            <CustomLabel
                              className="field-titles"
                              htmlFor="users"
                            >
                              Share With
                            </CustomLabel>
                            <MultiSelect
                              optionLabel="label"
                              filter={true}
                              disabled={
                                togglesidebarBtn &&
                                currentRowData.pipelineUserRole !== "Admin"
                              }
                              style={{
                                ...customStyle.multiSelect,
                                marginTop: 8,
                              }}
                              placeholder="share with"
                              fixedPlaceholder
                              value={
                                pipeusers.map((user) => ({
                                  firstName:
                                    !user.firstName && !user.lastName
                                      ? user.email
                                      : user.firstName,
                                  lastName: user.lastName,
                                  publicUserId: toLowerCase(user.publicUserId),
                                  pipelineUserRole: user.pipelineUserRole,
                                  email: user.email,
                                  label: user.label,
                                })) as Pick<
                                  User,
                                  | "firstName"
                                  | "lastName"
                                  | "email"
                                  | "publicUserId"
                                  | "pipelineUserRole"
                                  | "label"
                                >[]
                              }
                              options={
                                companyUsers.map((user) => {
                                  const currentUser = pipeusers.find(
                                    (p) =>
                                      toLowerCase(user.publicUserId) ===
                                      toLowerCase(p.publicUserId),
                                  );
                                  return {
                                    firstName:
                                      !user.firstName && !user.lastName
                                        ? user.email
                                        : user.firstName,
                                    lastName: user.lastName,
                                    email: user.email,
                                    label: user.label,
                                    publicUserId: toLowerCase(
                                      user.publicUserId,
                                    ),
                                    pipelineUserRole: currentUser
                                      ? user.pipelineUserRole ??
                                        currentUser.pipelineUserRole
                                      : "Member",
                                  };
                                }) as Pick<
                                  User,
                                  | "firstName"
                                  | "lastName"
                                  | "email"
                                  | "publicUserId"
                                  | "pipelineUserRole"
                                  | "label"
                                >[]
                              }
                              itemTemplate={userTemplate}
                              onChange={(e) => selectUsers(e.value)}
                            />
                            <div style={customStyle.chipsContainer}>
                              {pipeusers
                                .filter(
                                  (user) =>
                                    toLowerCase(user.publicUserId) !==
                                    currentRowData.createdByCognitoId,
                                )
                                .map((sa, i) => (
                                  <Chip
                                    key={i}
                                    label={
                                      Capitalize(
                                        sa.firstName && sa.lastName
                                          ? sa.firstName
                                          : sa.email,
                                      ) +
                                      " " +
                                      Capitalize(sa.lastName ?? " ")
                                    }
                                    onIconClick={() =>
                                      togglesidebarBtn &&
                                      currentRowData.pipelineUserRole !==
                                        "Admin"
                                        ? null
                                        : RemoveUser(sa, pipeusers)
                                    }
                                  />
                                ))}
                            </div>
                          </div>
                        )}

                        <div className="p-col-12">
                          <label>Preferences</label>
                          <MarketPreferencesBeta
                            pipelineId={currentRowData.id}
                            GetSelectedCountries={GetSelectedCountries}
                            GetSelectedSectors={GetSelectedSectors}
                            GetPreferenecFilterType={GetPreferenecFilterType}
                            GetSelectedKeywords={GetSelectedKeywords}
                            keywords={currentRowData.keywords}
                            preferenceFType={
                              currentRowData.preferenceFilterType
                            }
                            marketPreferences={currentRowData.marketPreferences}
                            disabled={
                              togglesidebarBtn &&
                              currentRowData.pipelineUserRole !== "Admin"
                            }
                          />
                        </div>
                        <div className="p-col-12">
                          <div className="pipeline-save-button">
                            <DebouncedButton
                              as={Button}
                              delay={5000}
                              className="modal-button"
                              label="Continue"
                              onClick={togglePrivacy}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel
                    header="Privacy"
                    contentStyle={{ textAlign: "left" }}
                  >
                    <div
                      style={{
                        paddingLeft: "1em",
                        paddingTop: "1em",
                      }}
                    >
                      <div className="field-radiobutton text-labels">
                        <RadioButton
                          inputId="private"
                          name="private"
                          checked={
                            pipeusers.filter((user) =>
                              toLowerCase(context.user.parsedIdToken?.sub) ===
                              currentRowData.createdByCognitoId
                                ? toLowerCase(user.publicUserId) !==
                                  currentRowData.createdByCognitoId
                                : true,
                            ).length < 1
                          }
                          value="private"
                        />
                        <label
                          style={{ paddingLeft: "5px", fontWeight: 600 }}
                          htmlFor="private"
                        >
                          Private
                        </label>
                        <p className="radio-button-info">
                          Used for personal pipeline opportunities tracking
                        </p>
                      </div>
                      <div className="field-radiobutton text-labels">
                        <RadioButton
                          inputId="public"
                          name="public"
                          checked={
                            pipeusers.filter((user) =>
                              toLowerCase(context.user.parsedIdToken?.sub) ===
                              currentRowData.createdByCognitoId
                                ? toLowerCase(user.publicUserId) !==
                                  currentRowData.createdByCognitoId
                                : true,
                            ).length >= 1
                          }
                          value="public"
                        />
                        <label
                          style={{
                            fontSize: "16px",
                            paddingLeft: "5px",
                            fontWeight: 600,
                          }}
                          htmlFor="private"
                        >
                          Shared
                        </label>
                        <p className="radio-button-info">
                          {" "}
                          Multiple users can manage pipeline opportunities
                        </p>
                      </div>
                      <div className="pipeline-user-label">
                        <label
                          style={{ fontSize: "16px", marginTop: "10px" }}
                          className="field-titles"
                          htmlFor="pipeline members"
                        >
                          Pipeline Members
                        </label>
                        <div className="shared-with-total">
                          {"Shared with " +
                            pipeusers.filter(
                              (user) =>
                                toLowerCase(user.publicUserId) !==
                                currentRowData.createdByCognitoId,
                            ).length +
                            " Member" +
                            (pipeusers.filter((user) =>
                              toLowerCase(context.user.parsedIdToken?.sub) ===
                              currentRowData.createdByCognitoId
                                ? toLowerCase(user.publicUserId) !==
                                  currentRowData.createdByCognitoId
                                : true,
                            ).length !== 2
                              ? "s"
                              : "")}
                        </div>
                      </div>
                      <div className="p-grid text-labels scroll">
                        {toLowerCase(context.user.parsedIdToken?.sub) ===
                          currentRowData.createdByCognitoId &&
                          owner && (
                            <>
                              <div
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div className="pipeline-members">
                                  <div>
                                    <p style={{ width: "200px" }}>
                                      <strong>
                                        {toLowerCase(owner.firstName)}{" "}
                                        {toLowerCase(owner.lastName)}
                                      </strong>
                                    </p>
                                  </div>
                                </div>
                                <div className="pipeline-members privacy">
                                  <span className="owner">Owner</span>
                                </div>
                              </div>
                              <Divider />
                            </>
                          )}
                        {React.Children.toArray(
                          pipeusers
                            .filter((user) =>
                              toLowerCase(context.user.parsedIdToken?.sub) ===
                              currentRowData.createdByCognitoId
                                ? toLowerCase(user.publicUserId) !==
                                  currentRowData.createdByCognitoId
                                : true,
                            )
                            .map((user: User) => (
                              <>
                                <div
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <div className="pipeline-members">
                                    {user.pipelineUserRole === "Admin" && (
                                      <div>
                                        <p style={{ width: "200px" }}>
                                          <strong>
                                            {toLowerCase(user.firstName)}{" "}
                                            {toLowerCase(user.lastName)}
                                          </strong>
                                        </p>
                                        <span
                                          style={{
                                            width: 200,
                                          }}
                                        >
                                          {user.email}
                                        </span>
                                      </div>
                                    )}
                                    {user.pipelineUserRole !== "Admin" && (
                                      <div>
                                        <p>
                                          <strong>
                                            {toLowerCase(user.firstName)}{" "}
                                            {toLowerCase(user.lastName)}
                                          </strong>
                                        </p>
                                        <span
                                          style={{
                                            width: 200,
                                            lineBreak: "anywhere",
                                          }}
                                        >
                                          {user.email}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                  {toLowerCase(user.publicUserId) ===
                                  currentRowData.createdByCognitoId ? (
                                    <div className="pipeline-members privacy">
                                      <span className="owner">Owner</span>
                                    </div>
                                  ) : (
                                    <div className="pipeline-members privacy">
                                      <Dropdown
                                        className="role-dropdown"
                                        optionLabel="role"
                                        placeholder={formattedRole(
                                          user.pipelineUserRole,
                                        )}
                                        disabled={
                                          togglesidebarBtn &&
                                          currentRowData.pipelineUserRole !==
                                            "Admin"
                                        }
                                        value={user.userRole}
                                        options={roles}
                                        valueTemplate={(v) => {
                                          if (v === null) {
                                            return formattedRole(
                                              user.pipelineUserRole,
                                            );
                                          } else {
                                            return v;
                                          }
                                        }}
                                        itemTemplate={(i) => i}
                                        onChange={(e) =>
                                          handleRoleChanged(
                                            user.publicUserId,
                                            e.value,
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                  {togglesidebarBtn &&
                                  currentRowData.pipelineUserRole !==
                                    "Admin" ? null : (
                                    <div
                                      onClick={() =>
                                        RemoveUser(user, pipeusers)
                                      }
                                      className="member-close-button"
                                    >
                                      X
                                    </div>
                                  )}
                                </div>
                                <Divider />
                              </>
                            )),
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: 20,
                        }}
                      >
                        <Button
                          style={{ marginRight: "10px" }}
                          className="modal-button"
                          label="Cancel"
                          onClick={() => popupMenu.current?.hide()}
                        ></Button>
                        <DebouncedButton
                          disabled={
                            togglesidebarBtn &&
                            currentRowData.pipelineUserRole !== "Admin"
                          }
                          as={Button}
                          delay={5000}
                          className="modal-button"
                          label="Save"
                          onClick={async () =>
                            togglesidebarBtn
                              ? await editPipeline()
                              : await saveNewPipeline()
                          }
                        ></DebouncedButton>
                      </div>
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </div>
          </div>
        </div>
      </OverlayPanel>
      {/*<Dialog*/}
      {/*  closeOnEscape={true}*/}
      {/*  dismissableMask={true}*/}
      {/*  className="soft-delete-pipeline"*/}
      {/*  maskClassName="soft-delete-pipeline-mask"*/}
      {/*  header={<></>}*/}
      {/*  style={{*/}
      {/*    width: "30vw",*/}
      {/*  }}*/}
      {/*  onHide={() => {*/}
      {/*    setSoftDeleteDialog(false);*/}
      {/*    setSoftDeleteDialogName("");*/}
      {/*    setSoftDeleteDialogId(-1);*/}
      {/*  }}*/}
      {/*  visible={softDeleteDialog}*/}
      {/*>*/}
      <Dialog
        open={softDeleteDialog}
        onClose={() => {
          setSoftDeleteDialog(false);
          setTimeout(() => {
            setSoftDeleteDialogName("");
            setSoftDeleteDialogId(-1);
          }, 150);
        }}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            p: 2,
          },
        }}
      >
        <Box className={"soft-delete-pipeline"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              onClick={() => {
                setSoftDeleteDialog(false);
                setTimeout(() => {
                  setSoftDeleteDialogName("");
                  setSoftDeleteDialogId(-1);
                }, 150);
              }}
            >
              <ClearIcon />
            </IconButton>
          </Box>
          <p className="soft-main-text">
            {headerTitle === "Deleted pipelines" ? (
              <>
                Are you sure you want to delete the{" "}
                <b>“{softDeleteDialogName}”</b> pipeline permanently?
              </>
            ) : (
              <>
                Are you sure you want to delete the{" "}
                <b>“{softDeleteDialogName}”</b> pipeline?
              </>
            )}
          </p>
          {headerTitle !== "Deleted pipelines" && (
            <p className="soft-sub-text">
              Pipeline will be moved to <span>deleted pipelines</span> tab, you
              can restore it, or delete it permanently.
            </p>
          )}
          {headerTitle === "Deleted pipelines" && (
            <p className="soft-main-text error">This action can't be undone.</p>
          )}
          <div className="soft-delete-btns">
            <button
              onClick={() => {
                setSoftDeleteDialog(false);
                setSoftDeleteDialogName("");
                setSoftDeleteDialogId(-1);
              }}
            >
              Cancel
            </button>
            <button
              className="delete-btn"
              style={{ width: headerTitle === "Deleted pipelines" ? 236 : 120 }}
              onClick={() => {
                deletePipeline(softDeleteDialogId);
                setSoftDeleteDialog(false);
                setSoftDeleteDialogName("");
                setSoftDeleteDialogId(-1);
              }}
            >
              {headerTitle === "Deleted pipelines"
                ? "Delete Permanently"
                : "Delete"}
            </button>
          </div>
        </Box>
      </Dialog>
      {/*</Dialog>*/}
      <div style={{ marginBottom: "10px" }}>
        {" "}
        <SectionHeader text={headerTitle} />
      </div>

      <Popover
        dialogHeader={dialogHeader}
        isVisible={!!dialogText}
        dialogText={dialogText}
        setDialogVisible={(x) => {
          if (!x) {
            setDialogHeader("");
            setDialogText("");
          }
        }}
      />

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <OverlayerWindow>
          <OverlayPanel
            ref={downloadPopup}
            style={{ width: "390px", display: "flex !important" }}
            className="side-panel-downloadopportunities"
          >
            <div>
              <p>From:</p>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                value={fromDate ?? new Date()}
                onError={(e) => null}
                onChange={(date) => setFromDate(date)}
                style={{ marginLeft: 10 }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
            <div>
              <p>To:</p>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                value={toDate ?? new Date()}
                onError={(e) => null}
                onChange={(date) => setToDate(date)}
                style={{ marginLeft: 10 }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
            <Button
              disabled={isDownloading}
              onClick={handleDownloadPipelines}
              className="button-downloadopportunities"
              label="Download"
            />
          </OverlayPanel>
        </OverlayerWindow>
      </MuiPickersUtilsProvider>

      <DataTable
        value={searchTable(pipelines)}
        globalFilter={keyword}
        // lazy={true}
        first={first}
        scrollable={true}
        scrollHeight="400px"
        totalRecords={pipelines.length}
        header={tableHeader}
        // onPage={(e)=> setFirst(e.first)}
        rows={10}
        resizableColumns
        columnResizeMode="expand"
        paginator={true}
        // loading={isLoading}
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={tableFooter}
        rowsPerPageOptions={[5, 10, 20, 25, 30]}
        className={
          headerTitle === "My Pipelines"
            ? "format-table-cells my-pipelines"
            : "format-table-cells"
        }
      >
        <Column
          headerStyle={styles.headerStyle(250)}
          style={styles.columnStyle(70)}
          bodyClassName="source"
          header="Title"
          field="name"
          className="justify-left-columns"
          exportable={true}
          body={getFormattedCellFromBody({}, (r: PipelineDto) => (
            <div style={{ display: "flex" }}>
              <p
                style={{
                  padding: "10px",
                  color: "#197AB5",
                  textDecoration: "underline",
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCurrentRowData(r);
                  goTopipeline(r);
                }}
              >
                {r.name}
              </p>
            </div>
          ))}
        />

        {toggledColumns && (
          <Column
            headerStyle={styles.headerStyle(350)}
            style={styles.columnStyle(30)}
            bodyClassName="source"
            header="Description"
            field="description"
            className="cell-data"
            exportable={true}
          />
        )}

        <Column
          headerStyle={styles.headerStyle(200)}
          style={styles.columnStyle(200)}
          bodyClassName="source"
          header="Country"
          field="country"
          body={countriesTemplate}
          exportable={true}
        />
        <Column
          headerStyle={styles.headerStyle(200)}
          style={styles.columnStyle(300)}
          bodyClassName="source"
          header="Sector"
          field="sector"
          body={sectorsTemplate}
          exportable={true}
        />
        <Column
          headerStyle={styles.headerStyle(150)}
          style={styles.columnStyle(150)}
          bodyClassName="source"
          header="Opportunities"
          field="totalOpportunities"
          body={OpportunitiesTemplate}
          exportable={true}
        />
        {toggledColumns && (
          <Column
            headerStyle={styles.headerStyle(150)}
            style={styles.columnStyle(30)}
            bodyClassName="source"
            header="Owner"
            field="source"
            body={ownerTemplate}
            exportable={true}
          />
        )}
        {toggledColumns && (
          <Column
            headerStyle={styles.headerStyle(150)}
            style={styles.columnStyle(30)}
            bodyClassName="source"
            header="Privacy"
            body={privacyTemplate}
            exportable={true}
          />
        )}

        {toggledColumns && (
          <Column
            headerStyle={styles.headerStyle(150)}
            style={styles.columnStyle(30)}
            className="center-cell-content"
            bodyClassName="source"
            header="Created at"
            field="source"
            exportable={true}
            body={dateStringTemplate}
          />
        )}

        {!isFree && (
          <Column
            headerStyle={styles.headerStyle(150)}
            header="Actions"
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
            body={adminActionsTemplate}
          />
        )}
      </DataTable>
    </div>
  );
};
