import type { TypedUseSelectorHook } from "react-redux";
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import type { ThunkAction } from "redux-thunk";
import type { AnyAction } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { reducerProxy } from "./rootReducer";

const isReduxDebug =
  process.env.NEXT_PUBLIC_ENABLE_REDUX_DEV_TOOLS === "true" ||
  window?.location?.href?.includes("localhost:3000");
export const store = configureStore({
  reducer: reducerProxy,
  devTools: isReduxDebug,
  middleware: (getDefaultMiddleware) =>
    isReduxDebug
      ? getDefaultMiddleware().prepend().concat(logger)
      : getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, AnyAction>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();
