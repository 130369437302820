import { createSvgIcon } from "@mui/material/utils";

export const ReplyReversedIcon = createSvgIcon(
  <svg
    width="32"
    height="32"
    viewBox="0 0 24 24"
    version="1.1"
    id="svg1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs id="defs1" />
    <path
      fill="currentColor"
      d="M 14,9 V 5 l 7,7 -7,7 V 14.9 C 9,14.9 5.5,16.5 3,20 4,15 7,10 14,9"
      id="path1"
    />
  </svg>,
  "ReplyReversed",
);
