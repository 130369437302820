import * as React from "react";
import { HTMLAttributes } from "react";
import ReactSelect from "react-select";
import ThemeContext from "../../configs/theme";
import "./CustomSearchSelect.css";

export const AddAllOptionText = "Add all matching results";

const CustomSearchSelect = (params: any) => {
  const {
    setSelectedOption,
    allOptions,
    placeholder,
    disabled,
    getOptionLabel,
    getOptionValue,
    getOptionsFromArray,
    filterOptionsArray,
    addAllFilteredResults,
    useContainsFilter,
  } = params;
  const maxResults = 50;
  const theme = React.useContext(ThemeContext);

  const getOptionsFromStringArray = (arr: string[]) => {
    const results = arr
      .map((m: string, i: number) => ({
        label: m,
        value: m,
        isFixed: true,
        index: i,
      }))
      .filter((r) => r.index < maxResults);

    // if add all is in group and more than one record showing, add option to front
    const hasAddAll =
      allOptions.findIndex((x) => x.label === AddAllOptionText) > -1 &&
      addAllFilteredResults;
    if (
      hasAddAll > -1 &&
      results.length > 1 &&
      !results.find((x) => x.label === AddAllOptionText)
    ) {
      results.splice(0, 0, {
        label: AddAllOptionText,
        value: AddAllOptionText,
        isFixed: true,
        index: 0,
      });
    }
    return results;
  };

  const getAllOptions = getOptionsFromArray || getOptionsFromStringArray;

  const filterOptionsStringArray = (arr: string[]) => (text: string) => {
    const results = allOptions.filter((m: string) =>
      m.toLowerCase().match(text.toLowerCase())
    );
    return results;
  };

  const filterAllOptions = filterOptionsArray
    ? filterOptionsArray(allOptions)
    : filterOptionsStringArray(allOptions);

  const [options, setOptions] = React.useState([...getAllOptions(allOptions)]);
  const [value, setValue] = React.useState(Array<string>());

  const StartsWithFilter = (
    option: { data: { label: string } },
    searchText: string
  ) => {
    let isMatch = option.data.label
      .toLowerCase()
      .startsWith(searchText.toLowerCase());

    // Override if add all
    if (addAllFilteredResults && option.data.label === AddAllOptionText) {
      isMatch = true;
    }

    return isMatch;
  };

  const ContainsFilter = (
    option: { data: { label: string } },
    searchText: string
  ) => {
    let isMatch = option.data.label
      .toLowerCase()
      .includes(searchText.toLowerCase());

    // Override if add all
    if (addAllFilteredResults && option.data.label === AddAllOptionText) {
      isMatch = true;
    }

    return isMatch;
  };

  const selectStyles = {
    menu: (styles: any) => ({ ...styles, zIndex: 999 }),
    option: (provided: any, state: any) => ({
      ...provided,
      font: theme.v2Fonts.input,
    }),
  };
  return (
    <ReactSelect
      isDisabled={disabled}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      placeholder={placeholder || "Select..."}
      styles={selectStyles}
      isMulti={true}
      onInputChange={(text) =>
        setOptions([...getAllOptions(filterAllOptions(text))])
      }
      value={value}
      onChange={(x) => {
        let localX = x ? [...x] : [];
        if (
          x &&
          x.find((r: { label: string }) => r.label === AddAllOptionText)
        ) {
          // Need to set x to all options minus "add all" then call default behavior
          localX = [
            ...value,
            ...options.filter((r) => r.label !== AddAllOptionText),
          ];
        }

        setValue(localX);
        setSelectedOption(
          localX
            ? localX.map((m: { label: string; value: string }) => m.value)
            : []
        );
      }}
      options={options}
      filterOption={useContainsFilter ? ContainsFilter : StartsWithFilter}
      formatOptionLabel={formatOptionLabel}
    />
  );
};
const s1: React.CSSProperties | undefined = {};
const s2: React.CSSProperties | undefined = { fontStyle: "italic" };
const formatOptionLabel = (o: any) => (
  <div style={{ display: "flex", font: "16px Montserrat" }}>
    <div style={o.label === AddAllOptionText ? s2 : s1}>{o.label}</div>
  </div>
);
export default CustomSearchSelect;
