import React, { FunctionComponent, useState } from "react";

interface ShowMoreTextProps {
  text: string;
  textLength: number;
  styles?: React.CSSProperties
}
const ShowMoreTextComp: FunctionComponent<ShowMoreTextProps> = ({
  text,
  textLength,
  styles
}) => {
  const [showMore, setShowMore] = useState(false);
  const showMoreText = (text: string) => {
    if (text?.length < textLength) return text;
    else {
      return `${text?.substring(0, textLength)} ...`;
    }
  };
  return (
    <>
      {showMore ? (
        <p
          style={{ margin: "0px",...styles }}
          className={text?.length > textLength ? "show-more-txt" : ""}
          onClick={() => {
            setShowMore(false);
          }}
        >
          {text}
        </p>
      ) : (
        <p
          style={{ margin: "0px",...styles }}
          className={text?.length > textLength ? "show-more-txt" : ""}
          onClick={() => {
            setShowMore(true);
          }}
        >
          {showMoreText(text)}
        </p>
      )}
    </>
  );
};

export default ShowMoreTextComp;
