import React, { FunctionComponent, useContext, useMemo } from "react";
import { css } from "glamor";
import PillButton from "../../../../PillButton";
import Pill from "../../../../Pill";
import DashboardSection from "../../../DashboardSection";
import UserContext, {
  convertRoleToFriendlyName,
  getMaxUserRole,
  isUserInRoles,
  userRoleInOrg,
  ServerOrgRoles,
  UserRoleGroups,
} from "../../../../../services/UserContext";
import ThemeContext from "../../../../../configs/theme";
import { AccountInformationContext } from "../../AccountInformation/reducer";
import SectionHeader from "../../../SectionHeader";
import {
  AskAQuestionUrl,
  goToUrlInNewTab,
} from "../../../../../configs/resources";
import "../UserMenu.css";
import axios from "axios";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../../../../configs/axios-export.custom";

const useStyles = () => {
  const theme = React.useContext(ThemeContext);

  return {
    overviewSection: {
      margin: "25px 0px",
    },
    formalName: {
      font: theme.v2Fonts.mediumHeader,
    },
    companyName: {
      margin: "10px 0px",
      font: theme.v2Fonts.smallHeader,
    },
    overflowText: {
      overflowX: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  };
};
type ProfilePicture = {
  logo: string;
};
type ProfileOverviewProps = {
  organization: string;
  profile: string;
};

//
const ProfileOverview: FunctionComponent<ProfileOverviewProps> = ({
  organization,
  profile,
}) => {
  const userContext = useContext(UserContext);
  const { accountInformationState } = useContext(AccountInformationContext);
  // const[profilePic,setProfilePic] = React.useState(profile);
  const styles = useStyles();
  const isFree = !isUserInRoles(userContext, UserRoleGroups.plusOrHigher);
  const role = useMemo(() => {
    return convertRoleToFriendlyName(getMaxUserRole(userContext));
  }, [userContext]);
  const isOrgMember = userRoleInOrg(
    userContext,
    ServerOrgRoles.organizationMember
  );
  const isOrgAdmin = userRoleInOrg(
    userContext,
    ServerOrgRoles.organizationAdmin
  );

  return (
    <DashboardSection>
      {/* <SectionHeader text={"User Information"} /> */}

      <div className="preview">
        <div className="preview-details">
          <div className="preview-details-group">
            <div className="preview-details-group-img">
              {profile && (
                <img
                  onLoad={(e) => (e.currentTarget.style.display = "")}
                  style={{
                    borderRadius: "50%",
                    margin: "2px",
                    display: "none",
                  }}
                  className="img-centered-clipped"
                  height={75}
                  width={75}
                  src={"data:image/jpeg;base64," + profile}
                  alt=""
                />
              )}
            </div>
          </div>
          <div className="preview-user-details">
            <div className="preview-user-details-name ">
              <p>
                {" "}
                {accountInformationState.firstName.serverValue}{" "}
                {accountInformationState.lastName.serverValue}
              </p>
            </div>
            <p className="preview-user-details-title">
              <span>
                {role} {isOrgMember ? <span>member</span> : <span>admin</span>}
                {""} at{" "}
              </span>
              <span>
                <strong>{organization}</strong>
              </span>
            </p>
            <hr />
            <div className="preview-user-details-email-label  ">
              <div className="lable-font">Email</div>
              <div className="time-zone-value lable-font ">
                <strong>{accountInformationState.email}</strong>
              </div>
            </div>
            <div className="time-zone-group">
              <div className="lable-font">Timezone</div>
              <div className="time-zone-value lable-font">
                <strong>
                  {accountInformationState.timeZone["currentValue"]}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        {...css(styles.overviewSection)}
        className="p-d-flex p-flex-column"
        style={{ margin: "auto", width: "170px" }}
      >
        {isOrgAdmin && (
          <PillButton
            label="Upgrade Plan"
            onClick={goToUrlInNewTab("tiers")}
            style={{ margin: "5px 0 25px" }}
          />
        )}
        <PillButton
          label="Contact Support"
          onClick={goToUrlInNewTab(AskAQuestionUrl)}
          style={{ margin: "5px 0" }}
        />
      </div>
    </DashboardSection>
  );
};

export default ProfileOverview;
