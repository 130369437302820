import { createSvgIcon } from "@mui/material/utils";

export const ThumbsUpIcon = createSvgIcon(
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.2002 5.72001C13.0128 5.49511 12.7784 5.31406 12.5135 5.18966C12.2485 5.06525 11.9595 5.00051 11.6668 5.00001H8.62683L9.00016 4.04668C9.15545 3.62926 9.20717 3.18041 9.15087 2.73862C9.09458 2.29683 8.93196 1.87529 8.67695 1.51016C8.42195 1.14503 8.08218 0.847207 7.68679 0.642238C7.2914 0.437269 6.85219 0.331273 6.40683 0.333343C6.27859 0.33361 6.15315 0.37086 6.04554 0.440625C5.93794 0.51039 5.85274 0.609709 5.80016 0.726676L3.90016 5.00001H2.3335C1.80306 5.00001 1.29436 5.21072 0.919283 5.5858C0.54421 5.96087 0.333496 6.46958 0.333496 7.00001V11.6667C0.333496 12.1971 0.54421 12.7058 0.919283 13.0809C1.29436 13.456 1.80306 13.6667 2.3335 13.6667H10.8202C11.288 13.6665 11.741 13.5023 12.1004 13.2027C12.4597 12.903 12.7026 12.4869 12.7868 12.0267L13.6335 7.36001C13.6859 7.0716 13.6742 6.7752 13.5993 6.49179C13.5244 6.20839 13.3882 5.94491 13.2002 5.72001ZM3.66683 12.3333H2.3335C2.15669 12.3333 1.98712 12.2631 1.86209 12.1381C1.73707 12.0131 1.66683 11.8435 1.66683 11.6667V7.00001C1.66683 6.8232 1.73707 6.65363 1.86209 6.52861C1.98712 6.40358 2.15669 6.33334 2.3335 6.33334H3.66683V12.3333ZM12.3335 7.12001L11.4868 11.7867C11.4584 11.942 11.3758 12.0822 11.2538 12.1823C11.1317 12.2824 10.978 12.3359 10.8202 12.3333H5.00016V5.80668L6.8135 1.72668C7.00015 1.78109 7.1735 1.87361 7.3226 1.9984C7.4717 2.12318 7.59331 2.27752 7.67976 2.45167C7.76621 2.62582 7.81562 2.816 7.82487 3.01021C7.83412 3.20442 7.803 3.39843 7.7335 3.58001L7.38016 4.53334C7.30487 4.73485 7.27946 4.95157 7.30608 5.16503C7.3327 5.37849 7.41058 5.58233 7.53305 5.75917C7.65553 5.93601 7.81897 6.08059 8.00943 6.18057C8.1999 6.28055 8.41172 6.33297 8.62683 6.33334H11.6668C11.7648 6.33318 11.8615 6.35461 11.9503 6.39609C12.039 6.43757 12.1175 6.49809 12.1802 6.57334C12.2444 6.64756 12.2914 6.73504 12.3179 6.82954C12.3444 6.92404 12.3498 7.02322 12.3335 7.12001Z" />
  </svg>,
  "ThumbsUp",
);
