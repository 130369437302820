import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";
import withReact from "sweetalert2-react-content";
const MySwal = withReact(Swal);

const RichTextEditorComponent = ({
  names,
  state,
  setState,
}: {
  names: string[];
  state: string[];
  setState: (p: string, i: number) => void;
}) => {
  return (
    <div>
      {names.map((n, i) => (
        <div style={{ height: 250 }} key={n}>
          <label>{n}</label>
          <ReactQuill
            style={{ height: 150 }}
            theme="snow"
            value={state[i]}
            onChange={(c) => setState(c, i)}
          />
        </div>
      ))}
    </div>
  );
};

type ShowRichTextComponentProps = {
  name: string;
  components: {
    name: string;
    startingText: string;
  }[];
};

export const showRichTextEditorComponent = async (
  props: ShowRichTextComponentProps
) => {
  let state = [...props.components.map((r) => r.startingText)];
  let setState = (p: string, i: number) => {
    state[i] = p;
  };

  return await MySwal.fire({
    title: props.name,
    width: "800px",
    html: (
      <RichTextEditorComponent
        names={props.components.map((r) => r.name)}
        state={state}
        setState={setState}
      />
    ),
    showCancelButton: true,
    showLoaderOnConfirm: true,
    allowOutsideClick: () => !MySwal.isLoading(),
  }).then((r) => {
    return { isConfirmed: r.isConfirmed, components: state };
  });
};
