import React, { useContext, useEffect, useState } from "react";
import "./authentication.css";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import { useStyles } from "../../hooks/useStyles";
import UserConfirmation from "./UserConfirmation";
import UserContext, { User } from "../../services/UserContext";
import { callRefresh } from "../../App";

function Authenticator() {
  const searchParams = new URLSearchParams(document.location.search);

  const [errorMessage, setErrorMessage] = React.useState<string | null>();
  const [currentState, setCurrentState] = React.useState(
    searchParams.get("email") ? "setNewPassword" : "showSignIn",
  );

  const [currentUser, setCurrentUser] = useState<User>();
  useEffect(() => {}, [currentUser]);
  const [currentRefreshToken, setCurrentRefreshToken] = useState(
    window.localStorage.getItem("refreshToken"),
  );
  const [isLoaded, setIsLoaded] = useState(false);

  const styles = useStyles();
  const context = useContext(UserContext);

  React.useEffect(() => {
    setErrorMessage(null);
  }, [currentState]);

  useEffect(() => {
    if (
      typeof currentRefreshToken === "string" &&
      currentRefreshToken?.length > 0
    )
      callRefresh(
        currentRefreshToken,
        currentUser!,
        setCurrentRefreshToken,
        setCurrentUser,
        setIsLoaded,
      );
  }, []);

  useEffect(() => {
    if (currentUser) context.updateCurrentUser(currentUser);
  }, [currentUser]);

  return (
    <div
      style={{
        ...styles.formContainerStyle,
        maxHeight: errorMessage ? 560 : 520,
        backgroundColor: "white",
      }}
    >
      {currentState === "showSignIn" && (
        <SignIn
          updateErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
        />
      )}
      {currentState === "showSignUp" && (
        <SignUp
          updateErrorMessage={setErrorMessage}
          switchState={setCurrentState}
          errorMessage={errorMessage}
        />
      )}
      {currentState === "showForgotPassword" && (
        <ForgotPassword
          switchState={setCurrentState}
          updateErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
        />
      )}
      {currentState === "setNewPassword" && (
        <UserConfirmation
          switchState={setCurrentState}
          updateErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
        />
      )}
      <div style={styles.buttonContainer}>
        {currentState === "showSignIn" ? (
          <div style={styles.linkContainer}>
            <p
              onClick={() => setCurrentState("showSignUp")}
              style={styles.toggle}
              className="auth-link"
            >
              Need an account? Sign Up
            </p>
            <p
              onClick={() => setCurrentState("showForgotPassword")}
              style={styles.toggle}
              className="auth-link"
            >
              Forgot your password?
            </p>
          </div>
        ) : (
          <div style={styles.linkContainer}>
            <p
              style={styles.toggle}
              className="auth-link"
              onClick={() => setCurrentState("showSignIn")}
            >
              Already have an account? Sign In
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Authenticator;
