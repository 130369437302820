import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DropdownOption } from "../components/Widgets/Inputs/Input";
import { UserContextType } from "../services/UserContext";
import axios from "axios";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../configs/axios-export.custom";
import { buildQuery } from "../utils/Helpers/queryBuilder";
import { RequestStatus } from "../utils/Helpers/fetchStatus";
import { shareOpportunityToUser } from "../utils/Helpers/asyncFunctions";

interface FundingInstrument {
  id: number;
  description: string;
}
interface ApplicantType {
  id: number;
  description: string;
}
interface FundingActivityCategory {
  id: number;
  description: string;
}

interface GrantRecord {
  awardFloor: number | null;
  awardFloorFormatted: string;
  applicantEligibilityDescription: string;
  sendEmail: string;
  createTimestamp: Date | null;
  modComments: string;
  createdDateIn: Date | null;
  responseDateString: string;
  postingDateString: string;
  archiveDateString: string;
  createTimeStampString: string;
  createdOn: Date;
  awardCeilingFormatted: string;
  awardCeiling: number | null;
  estimatedFundingFormatted: string;
  estimatedFunding: number | null;
  rowId: number;
  version: number;
  agencyCode: string;
  agencyName: string;
  agencyPhone: string;
  agencyAddressDescription: string;
  agencyContactPhone: string;
  agencyContactName: string;
  agencyContactEmail: string;
  agencyContactEmailDescription: string;
  description: string;
  responseDate: Date | null;
  postingDate: Date | null;
  archiveDate: Date | null;
  costSharing: boolean;
  numberOfAwards: number;
  agencyContactDescription: string;
  fundingInstrumentLookups: { fundingInstrument: FundingInstrument }[];
  applicantTypeLookups: { applicantType: ApplicantType }[];
  fundingActivityLookups: {
    fundingActivityCategory: FundingActivityCategory;
  }[];
}

export interface SummaryGrant {
  id: number;
  isMyList: boolean;
  customColumn: string;
  notes: string;
  topLevelAgency: string;
  opportunityNumber: string;
  opportunityTitle: string;
  originalDueDate: string | null;
  opportunityCategoryDescription: string;
  lastUpdatedDateIn: string | null;
  manualLastUpdatedDate?: string | null;
  cfdas: string[];
  record: SummaryGrantRecord; // have to set this
  primeOrIncumbent: string;
}

interface SummaryGrantRecord {
  awardFloorFormatted: string;
  applicantEligibilityDescription: string;
  awardCeilingFormatted: string;
  estimatedFundingFormatted: string;
  version: number;
  agencyName: string;
  agencyPhone: string;
  agencyAddressDescription: string;
  agencyContactPhone: string;
  agencyContactName: string;
  agencyContactEmail: string;
  description: string;
  responseDate: string | null;
  postingDate: string | null;
  archiveDate: string | null;
  costSharing: boolean;
  numberOfAwards: number;
  fundingInstrumentDescription: string;
  applicantTypeDescription: string;
  fundingActivityDescription: string;
  agencyContactDescription: string;
  status: string | null;
}

export interface GrantsFileRecord {
  fileName: string;
  fileDescription: string;
  createdOn: string | null;
}

export interface GrantsFiltersType {
  filter?: string;
  country?: Array<DropdownOption>;
  category?: Array<DropdownOption>;
  opportunityNumber?: string | null;
  sortField?: string;
  sortOrder?: number;
  onlyMyList?: boolean;
  includeUsaid?: boolean;
  includeMcc?: boolean;
  includeStateDept?: boolean;
  includeCdc?: boolean;
  fileName?: string;
  fileKeyword?: string;
  updatedBy?: Date | null;
  status?: string;
  onlyUntrackedInPipeline?: boolean;
  eligibility?: string | null;
}

export interface GrantsPagination {
  pageIndex: number;
  pageSize: number;
}

interface GrantsState {
  items: Array<any>;
  fetchStatus: string;
  total: number;
  lastUpdatedUtc: string | null;
  attributes: {
    [key: string]: any;
  };
  details: {
    fetchStatus: string;
    files: Array<GrantsFileRecord>;
    grantDetails: {
      cfdas: {
        cfdaNumber: string;
        programTitle: string;
      }[];
      description: string;
      opportunityNumber: string;
      opportunityTitle: string;
      originalDueDate: string;
      topLevelAgency: string;
    } | null;
    matchingFiles: Array<string>;
    records: Array<SummaryGrantRecord>;
    pipelineDetails: Array<{
      pipelineId: string;
      pipelineName: string;
    }>;
  };
  shareOpportunities: {
    postFetchStatus: string;
  };
  dropdownOptions: {
    country: {
      items: Array<DropdownOption>;
      fetchStatus: string;
    };
    categories: {
      items: Array<DropdownOption>;
      fetchStatus: string;
    };
  };
  updateGrantsRecord: {
    fetchStatus: string;
    errors: any;
  };
}

const initialState: GrantsState = Object.freeze({
  items: [],
  fetchStatus: RequestStatus.statuses.NULL,
  total: 0,
  lastUpdatedUtc: null,
  attributes: {},
  details: {
    fetchStatus: RequestStatus.statuses.NULL,
    files: [],
    grantDetails: null,
    matchingFiles: [],
    records: [],
    pipelineDetails: [],
  },
  shareOpportunities: {
    postFetchStatus: RequestStatus.statuses.NULL,
  },
  dropdownOptions: {
    country: {
      items: [],
      fetchStatus: RequestStatus.statuses.NULL,
    },
    categories: {
      items: [],
      fetchStatus: RequestStatus.statuses.NULL,
    },
  },
  updateGrantsRecord: {
    fetchStatus: RequestStatus.statuses.NULL,
    errors: {},
  },
});

export const getGrants: any = createAsyncThunk(
  "grants/getGrants",
  async (
    data: {
      context: UserContextType;
      params: GrantsFiltersType & GrantsPagination;
    },
    thunkAPI,
  ) => {
    const { context, params } = data;
    const response = await axios.get(
      getFullUrl(`/api/Grant${buildQuery({ ...params }, "|")}`, {
        useDedicatedEnvironment: true,
      }),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

export const getCountryDropdownOptions: any = createAsyncThunk(
  "grants/getCountryDropdownOptions",
  async (data: { context: UserContextType }, thunkAPI) => {
    const { context } = data;
    const response = await axios.get(
      getFullUrl(`/api/Grant/dropdownoptions/country`, {
        useDedicatedEnvironment: true,
      }),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

export const getCategoriesDropdownOptions: any = createAsyncThunk(
  "grants/getSetAsideDropdownOptions",
  async (data: { context: UserContextType }, thunkAPI) => {
    const { context } = data;
    const response = await axios.get(
      getFullUrl(`/api/Grant/dropdownoptions/category`, {
        useDedicatedEnvironment: true,
      }),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

export const shareOpportunities: any = createAsyncThunk(
  "grants/shareOpportunities",
  async (
    data: {
      context: UserContextType;
      message: string;
      opportunityId: number | Array<number>;
      addresses: Array<string>;
    },
    thunkAPI,
  ) => {
    const { context, message, opportunityId, addresses } = data;
    return Array.isArray(opportunityId)
      ? await Promise.all(
          opportunityId.map(
            async (id) =>
              await Promise.all(
                addresses.map(async (address) => {
                  await shareOpportunityToUser({
                    context,
                    message,
                    opportunityId: id,
                    recordType: "G",
                    toAdderess: address,
                  });
                }),
              ),
          ),
        )
      : await Promise.all(
          addresses.map(async (address) => {
            await shareOpportunityToUser({
              context,
              message,
              opportunityId,
              recordType: "G",
              toAdderess: address,
            });
          }),
        );
  },
);

export const getGrantDetails: any = createAsyncThunk(
  "grants/getGrantDetails",
  async (
    data: {
      context: UserContextType;
      params: {
        id: number;
        fileKeyword: string;
        fileName: string;
      };
    },
    thunkAPI,
  ) => {
    const {
      context,
      params: { id, fileKeyword = "", fileName = "" },
    } = data;
    const response = await axios.get(
      getFullUrl(
        `/api/grant/details/${id}${buildQuery({ fileKeyword, fileName })}`,
        {
          useDedicatedEnvironment: true,
        },
      ),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

export const updateGrantsRecord: any = createAsyncThunk(
  "grants/updateGrantsRecord",
  async (
    data: {
      context: UserContextType;
      id: number;
      values: { manualLastUpdatedDate?: string | null };
    },
    thunkAPI,
  ) => {
    const { context, id, values } = data;
    const response = await axios.put(
      getFullUrl(`/api/grant/${id}`, {
        useDedicatedEnvironment: true,
      }),
      values,
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

const slice = createSlice({
  name: "grants",
  initialState,
  reducers: {
    resetShareOpportunitiesStatus(state: GrantsState) {
      state.shareOpportunities = initialState.shareOpportunities;
    },
    clearDetails(state: GrantsState) {
      state.details = initialState.details;
    },
    resetUpdateGrantsRecordState(state: GrantsState) {
      state.updateGrantsRecord = initialState.updateGrantsRecord;
    },
  },
  extraReducers: {
    [getGrants.pending]: (state: GrantsState, action: PayloadAction<any>) => {
      state.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getGrants.fulfilled]: (state: GrantsState, action: PayloadAction<any>) => {
      const { data, attributes, lastUpdatedUtc, totalItems } = action.payload;
      state.items = data.map((item: SummaryGrant) => ({
        ...item,
        ...item.record,
      }));
      state.attributes = attributes;
      state.total = totalItems;
      state.lastUpdatedUtc = lastUpdatedUtc;
      state.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getGrants.rejected]: (state: GrantsState, action: PayloadAction<any>) => {
      state.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [shareOpportunities.pending]: (state, action) => {
      state.shareOpportunities.postFetchStatus =
        RequestStatus.statuses.FETCHING;
    },
    [shareOpportunities.fulfilled]: (state, action: PayloadAction<any>) => {
      state.shareOpportunities.postFetchStatus = RequestStatus.statuses.DONE;
    },
    [shareOpportunities.rejected]: (state, action) => {
      state.shareOpportunities.postFetchStatus = RequestStatus.statuses.ERROR;
    },

    [getCountryDropdownOptions.pending]: (
      state: GrantsState,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions.country.fetchStatus =
        RequestStatus.statuses.FETCHING;
    },
    [getCountryDropdownOptions.fulfilled]: (
      state: GrantsState,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions.country.items = action.payload.map((item) => ({
        value: item,
        label: item,
      }));
      state.dropdownOptions.country.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getCountryDropdownOptions.rejected]: (
      state: GrantsState,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions.country.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [getCategoriesDropdownOptions.pending]: (
      state: GrantsState,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions.categories.fetchStatus =
        RequestStatus.statuses.FETCHING;
    },
    [getCategoriesDropdownOptions.fulfilled]: (
      state: GrantsState,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions.categories.items = action.payload.map((item) => ({
        value: item,
        label: item,
      }));
      state.dropdownOptions.categories.fetchStatus =
        RequestStatus.statuses.DONE;
    },
    [getCategoriesDropdownOptions.rejected]: (
      state: GrantsState,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions.categories.fetchStatus =
        RequestStatus.statuses.ERROR;
    },

    [getGrantDetails.pending]: (
      state: GrantsState,
      action: PayloadAction<any>,
    ) => {
      state.details.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getGrantDetails.fulfilled]: (
      state: GrantsState,
      action: PayloadAction<any>,
    ) => {
      const files = action.payload.files.sort((a, b) =>
        new Date(a.createdOn || "9999-01-01") <
        new Date(b.createdOn || "9999-01-01")
          ? 1
          : -1,
      );
      const records = action.payload.records
        .map((record) => ({
          ...record.grantRecord,
          opportunityCategoryDescription:
            action.payload.grantDetails?.description,
          applicantTypeDescription: record.applicantTypes,
          fundingInstrumentDescription: record.fundingInstruments,
          fundingActivityDescription: record.fundingActivities,
          id: record.grantRecord.opportunityId,
        }))
        .sort((a, b) => {
          if (a.createdOn === null) return -1;
          if (b.createdOn === null) return 1;
          return (
            new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime()
          );
        });
      const matchingFiles = action.payload.matchingFiles;

      state.details = {
        files,
        records,
        matchingFiles,
        grantDetails: action.payload.grantDetails,
        pipelineDetails: action.payload.pipelineDetails,
        fetchStatus: RequestStatus.statuses.DONE,
      };
    },
    [getGrantDetails.rejected]: (
      state: GrantsState,
      action: PayloadAction<any>,
    ) => {
      state.details.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [updateGrantsRecord.pending]: (state, action) => {
      state.updateGrantsRecord.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [updateGrantsRecord.fulfilled]: (state, action: PayloadAction<any>) => {
      state.updateGrantsRecord.fetchStatus = RequestStatus.statuses.DONE;
    },
    [updateGrantsRecord.rejected]: (state, action) => {
      state.updateGrantsRecord.fetchStatus = RequestStatus.statuses.ERROR;
    },
  },
});

export const { reducer } = slice;

export const {
  resetShareOpportunitiesStatus,
  clearDetails,
  resetUpdateGrantsRecordState,
} = slice.actions;
export default slice;
