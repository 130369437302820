import React, { FunctionComponent } from "react";
import ThemeContext from "../configs/theme";

const useStyle = () => {
  const theme = React.useContext(ThemeContext);

  return {
    chip: {
      display: "flex",
      alignItems: "center",
      margin: "2px",
      marginRight: "5px",
      padding: "7px",
      paddingLeft: "12px",
      fontSize: "14px",
      fontWeight: 600,
      textAlign: "left" as "left",
      backgroundColor: theme.v2.lightBlue,
      borderRadius: "1em",
    },
    icon: {
      fontSize: ".9em",
      cursor: "pointer",
    },
  };
};

interface ChipProps {
  label: string;
  onIconClick?: React.Dispatch<any>;
  className?: string
}

const Chip: FunctionComponent<ChipProps> = ({ label, onIconClick, className }) => {
  const style = useStyle();

  return (
    <div className={className} style={style.chip}>
      <span>{label}</span> &nbsp;{" "}
      {!!onIconClick && <i style={style.icon} className="pi pi-times" onClick={onIconClick} />}
    </div>
  );
};

export default Chip;
