import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { rowsPerPageOptionsStandard } from "../../utils/constants/gridsOptions";
import { useGridStyles } from "../../hooks/useGridStyles";
import UserContext from "../../services/UserContext";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../configs/axios-export.custom";
import DateFnsUtils from "@date-io/date-fns";
import { Panel } from "primereact/panel";
import { PanelHeader } from "../../components/DataTable/PanelHeader";
import { DataTable } from "primereact/datatable";
import { GetCustomColumnBody } from "../../components/DataTable/Columns";
import { buildQuery } from "../../utils/Helpers/queryBuilder";
import { useDebounce } from "../../services/functions";
import IDIQFilters from "./IDIQFilters";
import { useHistory, useLocation } from "react-router-dom";
import IDIQTableCell from "./IDIQTableCell";
import { Popover } from "../../components/Popover/Popover";
import { TableProperties } from "exceljs";
import {
  buildExcelTable,
  genEmptyRow,
  generateExcel,
} from "../../services/exporter";
import { Button as PButton } from "primereact/components/button/Button";
import {
  MaximizeGrid,
  MinimizeGrid,
} from "../../components/DataTable/ActionButtons";

export interface IDIQItemType {
  id: number;
  idiq_type: string;
  project_number: string;
  project_title: string;
  project_description: string;
  vendor_name: string;
  project_start_date: string;
  project_end_date: string;
  is_deleted: boolean;
  is_manually_added: boolean;
  created_on_utc: string;
}

interface IDIQDataType {
  attributes: {
    [key: string]: string;
  };
  lastUpdatedUtc: string;
  pageIndex: number;
  pageSize: number;
  totalItems: number;
  data: Array<IDIQItemType>;
}

export interface IDIQPaginationType {
  idiq_types: "Idiq" | "Lwa" | "BPA" | "";
  page_index: number;
  rows_per_page: number;
  first: number;
  vendor_name: string;
  project_number: string;
  project_start_before: number | null;
  project_start_after: number | null;
  project_end_before: number | null;
  project_end_after: number | null;
  sort_field: string;
  sort_order: number;
}

export interface IDIQModalProps {
  title: string;
  content: string;
  isOpen: boolean;
}

const initialData: IDIQDataType = {
  attributes: {},
  lastUpdatedUtc: "",
  pageIndex: 0,
  pageSize: 0,
  totalItems: 0,
  data: [],
};

const initialPagination: IDIQPaginationType = {
  idiq_types: "",
  page_index: 0,
  rows_per_page: rowsPerPageOptionsStandard[0],
  first: 0,
  vendor_name: "",
  project_number: "",
  project_start_before: Date.now(),
  project_start_after: null,
  project_end_before: null,
  project_end_after: Date.now(),
  sort_field: "",
  sort_order: 1,
};

const initialModal: IDIQModalProps = {
  title: "",
  content: "",
  isOpen: false,
};

const IDIQTable: React.FC = (): JSX.Element => {
  const styles = useGridStyles();
  const context = useContext(UserContext);

  const [loading, setLoading] = useState<boolean>(false);

  const [idiqData, setIdiqData] = useState<IDIQDataType>(initialData);
  const [pagination, setPagination] =
    useState<IDIQPaginationType>(initialPagination);
  const [modal, setModal] = useState<IDIQModalProps>(initialModal);

  const [vendorName, setVendorName] = useState<string>("");
  const debouncedVendorName = useDebounce(vendorName, 0);

  const [projectNumber, setProjectNumber] = useState<string>("");
  const debouncedProjectNumber = useDebounce(projectNumber, 0);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const [disableExcel, setDisableExcel] = useState<boolean>(false);

  const handleVendorNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVendorName(event.target.value);
  };

  const handleProjectNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProjectNumber(event.target.value);
  };

  const convertDateFormat = (date: number | null): string | null => {
    let _date: string | null = null;
    if (date !== null) {
      let d = new Date(date);
      _date = `${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`;
    }
    return _date;
  };

  const getQueryParams = (
    forcedQueryParams: { [key: string]: any } = {},
  ): string => {
    const {
      first,
      page_index,
      rows_per_page,
      project_start_before,
      project_start_after,
      project_end_before,
      project_end_after,
      vendor_name,
      idiq_types,
      project_number,
      sort_field,
      sort_order,
    } = pagination;

    const _project_start_before = convertDateFormat(project_start_before);
    const _project_start_after = convertDateFormat(project_start_after);
    const _project_end_before = convertDateFormat(project_end_before);
    const _project_end_after = convertDateFormat(project_end_after);

    const queryParamsObj = {
      idiq_types,
      page_index,
      rows_per_page,
      project_start_before: _project_start_before,
      project_start_after: _project_start_after,
      project_end_before: _project_end_before,
      project_end_after: _project_end_after,
      vendor_name,
      project_number,
      sort_field,
      sort_order,
    };

    return buildQuery({
      ...queryParamsObj,
      project_start_before: _project_start_before ?? "",
      project_start_after: _project_start_after ?? "",
      project_end_before: _project_end_before ?? "",
      project_end_after: _project_end_after ?? "",
      ...forcedQueryParams,
    });
  };

  const getIDIQData = (): void => {
    const queryParamsString = getQueryParams();

    setLoading(true);
    axios
      .get(
        getFullUrl(`/api/Utility/idiq${queryParamsString}`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      )
      .then((response) => {
        setIdiqData({
          ...response.data,
          pageIndex: pagination.page_index,
          pageSize: pagination.rows_per_page,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getExcelData = async (): Promise<Array<IDIQItemType>> => {
    const queryParamsString = getQueryParams({
      rows_per_page: 1000,
    });

    let result: Array<IDIQItemType> = [];
    await axios
      .get(
        getFullUrl(`/api/Utility/idiq${queryParamsString}`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      )
      .then((response) => {
        result = response.data.data;
      })
      .catch((error) => {});

    return result;
  };

  const getExportableDataTable = (data: IDIQItemType[]): TableProperties => {
    const columns = [
      { name: "Contract" },
      { name: "Project Title" },
      { name: "Project Description" },
      { name: "Period of Perf. Start Date" },
      { name: "Period of Perf. End Date" },
      { name: "Prime holder / vendor name" },
    ];

    let rows: any[][] = [];

    if (data.length == 0) {
      rows.push(genEmptyRow(columns.length));
    } else {
      for (let i = 0; i < data.length; i++) {
        const record = data[i];

        rows.push([
          record.project_number,
          record.project_title ?? "",
          record.project_description ?? "",
          record.project_start_date
            ? record.project_start_date.substring(0, 10)
            : "",
          record.project_end_date
            ? record.project_end_date.substring(0, 10)
            : "",
          record.vendor_name,
        ]);
      }
    }

    return buildExcelTable("DataExport", columns, rows);
  };

  useEffect(() => {
    pagination.vendor_name !== debouncedVendorName &&
      setPagination((prev) => ({
        ...prev,
        vendor_name: debouncedVendorName,
        page_index: 0,
        first: 0,
      }));
  }, [debouncedVendorName]);

  useEffect(() => {
    pagination.project_number !== debouncedProjectNumber &&
      setPagination((prev) => ({
        ...prev,
        project_number: debouncedProjectNumber,
        page_index: 0,
        first: 0,
      }));
  }, [debouncedProjectNumber]);

  useEffect(() => {
    getIDIQData();
  }, [
    pagination.sort_order,
    pagination.sort_field,
    pagination.rows_per_page,
    pagination.page_index,
  ]);

  const IDIQTableHeader = (
    <div className="p-grid p-justify-end p-align-center">
      <div className="p-col-12 p-md-4 p-lg-4 p-justify-end buttonsHeader">
        <div className="button-export">
          <PButton
            type="button"
            icon="pi pi-file-excel"
            iconPos="left"
            onClick={async () => {
              setDisableExcel(true);
              generateExcel(
                "Data+",
                `Aidkonekt_data_plus_${new Date().getFullYear()}`,
                getExportableDataTable(
                  getExcelData ? await getExcelData() : idiqData?.data,
                ),
              ).then(() => {
                setDisableExcel(false);
              });
            }}
            disabled={disableExcel}
            tooltip="Excel"
            tooltipOptions={{ position: "top" }}
            style={{
              marginLeft: 15,
              marginRight: 15,
              ...styles.tableHeaderButton,
            }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Popover
        dialogHeader={modal?.title}
        isVisible={modal?.isOpen}
        dialogText={modal?.content}
        setDialogVisible={() => setModal(initialModal)}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Panel
          style={styles.filters.filterBox}
          collapsed={isCollapsed}
          onToggle={(e) => setIsCollapsed(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <PanelHeader
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
              title={"Filters"}
            />
          }
        >
          <IDIQFilters
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
            vendorName={vendorName}
            handleVendorNameChange={handleVendorNameChange}
            projectNumber={projectNumber}
            handleProjectNumberChange={handleProjectNumberChange}
            getIDIQData={getIDIQData}
          />
        </Panel>
      </MuiPickersUtilsProvider>
      <DataTable
        value={idiqData.data}
        rowsPerPageOptions={rowsPerPageOptionsStandard}
        paginator
        rows={pagination.rows_per_page}
        totalRecords={idiqData.totalItems}
        first={pagination.first}
        lazy
        onSort={(e) => {
          setPagination((prev) => ({
            ...prev,
            sort_order: e.sortOrder,
            sort_field: e.sortField,
          }));
        }}
        sortField={pagination.sort_field}
        sortOrder={pagination.sort_order}
        onPage={(e) => {
          setPagination((prev) => ({
            ...prev,
            page_index: e.first / e.rows,
            first: e.first,
            rows_per_page: e.rows,
          }));
        }}
        header={
          // <div className="p-grid p-justify-end p-align-center">
          //   <div
          //     className="p-col-12 p-md-8 p-lg-8 topLeftColumnHeader"
          //     style={{ textAlign: "left" }}
          //   ></div>
          // </div>
          IDIQTableHeader
        }
        style={{
          margin: "16px 0",
        }}
        loading={loading}
      >
        {GetCustomColumnBody<IDIQItemType>(
          styles,
          125,
          (x) => (
            <IDIQTableCell item={x} contentParameterName={"project_number"} />
          ),
          "Contract",
          "project_number",
          {
            sortable: true,
          },
        )}
        {GetCustomColumnBody<IDIQItemType>(
          styles,
          170,
          (x) => (
            // <IDIQTableCell item={x} contentParameterName={"project_title"} />
            <IDIQTableCell
              item={x}
              contentParameterName={"project_title"}
              setModal={setModal}
              columnTitle={"Project Title"}
              textLength={100}
            />
          ),
          "Project Title",
          "project_title",
          {
            sortable: false,
          },
        )}
        {GetCustomColumnBody<IDIQItemType>(
          styles,
          170,
          (x) => (
            <IDIQTableCell
              item={x}
              contentParameterName={"project_description"}
              setModal={setModal}
              columnTitle={"Project Description"}
              textLength={100}
            />
          ),
          "Project Description",
          "project_description",
          {
            sortable: false,
          },
        )}
        {GetCustomColumnBody<IDIQItemType>(
          styles,
          75,
          (x) => (
            <IDIQTableCell
              item={x}
              contentParameterName={"project_start_date"}
              isDate
            />
          ),
          "Period of Perf. Start Date",
          "project_start_date",
          {
            sortable: true,
          },
        )}
        {GetCustomColumnBody<IDIQItemType>(
          styles,
          75,
          (x) => (
            <IDIQTableCell
              item={x}
              contentParameterName={"project_end_date"}
              isDate
            />
          ),
          "Period of Perf. End Date",
          "project_end_date",
          {
            sortable: true,
          },
        )}
        {GetCustomColumnBody<IDIQItemType>(
          styles,
          170,
          (x) => (
            <IDIQTableCell item={x} contentParameterName={"vendor_name"} />
          ),
          "Prime holder / vendor name",
          "vendor_name",
          {
            sortable: true,
          },
        )}
      </DataTable>
    </>
  );
};

export default IDIQTable;
