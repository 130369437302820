import React from "react";
import { useGridStyles } from "../../../hooks/useGridStyles";
import { getSortIfDescendingFirst } from "../../../services/functions";
import { DataTable } from "primereact/datatable";
import { GetCustomColumnBody } from "../../../components/DataTable/Columns";
import {
  DateFormat,
  formatDate,
} from "../../../utils/conversion/date-converters";
import * as ActionButtons from "../../../components/DataTable/ActionButtons";

export interface TableRecord {
  id: number;
  file: string;
  country: string;
  opportunityTitle: string;
  attachmentDate: Date;
  keyword: string;
  linkUrl: string;
}

type LCPSuggestionsTableProps = {
  isLoading: boolean;

  // Filtering/sorting/querying
  rowsPerPage: number;
  sortField: string;
  setSortField: React.Dispatch<string>;
  sortOrder: number;
  setSortOrder: React.Dispatch<number>;
  first: number;
  totalRecords: number;

  // Grid state
  data: Array<TableRecord>;
  setData: React.Dispatch<Array<TableRecord>>;
  lastUpdated: string;
  gridWidth: number;

  // Notes info
  originalNotes: Array<{ id: number; note: string }>;
  setOriginalNotes: React.Dispatch<Array<{ id: number; note: string }>>;

  isMyContracts?: boolean;
  decrement?: () => void;
  addRecord: (r: {
    id: number;
    attachmentDate: Date;
    country: string;
  }) => Promise<void>;
  removeRecord: (r: { id: number }) => Promise<void>;
  queryData: (event: {
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
  }) => Promise<void>;
};

const LCPSuggestionsTable: React.FC<LCPSuggestionsTableProps> = ({
  isLoading,
  rowsPerPage,
  sortField,
  setSortField,
  sortOrder,
  setSortOrder,
  first,
  totalRecords,
  data,
  gridWidth,
  addRecord,
  removeRecord,
  queryData,
}): JSX.Element => {
  const styles = useGridStyles();

  const [expandedRows, setExpandedRows] = React.useState(Array<TableRecord>());

  const sort = async (event: { sortField: string; sortOrder: number }) => {
    const newSortOrder = getSortIfDescendingFirst(event, sortField);
    setSortField(event.sortField);
    setSortOrder(newSortOrder);
    await queryData({
      first,
      rows: rowsPerPage,
      sortField: event.sortField,
      sortOrder: newSortOrder,
    });
  };

  const ActionsButtonColumn = ({ record }: { record: TableRecord }) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ActionButtons.NavigateToButton
          openPage={() => {
            window.open(record.linkUrl, "_blank");
          }}
        />
        <span style={{ marginRight: 20 }} />
        <ActionButtons.AddToMyListButton add={() => addRecord(record)} />
        <span style={{ marginRight: 20 }} />
        <ActionButtons.RemoveFromMyListButton
          remove={() => removeRecord(record)}
        />
      </div>
    );
  };

  return (
    <DataTable
      value={data}
      style={{ marginTop: 20, width: `${gridWidth + 20}px` }}
      paginator={true}
      rows={rowsPerPage}
      totalRecords={totalRecords}
      lazy={true}
      first={first}
      onPage={queryData}
      loading={isLoading}
      sortField={sortField}
      sortOrder={sortOrder}
      onSort={sort}
      header={<div />}
      scrollable={true}
      scrollHeight="400px"
      expandedRows={expandedRows}
      onRowToggle={(e: any) => setExpandedRows(e.data)}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    >
      {GetCustomColumnBody<TableRecord>(
        styles,
        100,
        (x) => (
          <ActionsButtonColumn record={x} />
        ),
        "Add/Ignore",
        "id",
        { sortable: false }
      )}
      {GetCustomColumnBody<TableRecord>(
        styles,
        250,
        (x) => x.file,
        "File",
        "file",
        { sortable: false }
      )}
      {GetCustomColumnBody<TableRecord>(
        styles,
        250,
        (x) => x.country,
        "Country",
        "country",
        { sortable: false }
      )}
      {GetCustomColumnBody<TableRecord>(
        styles,
        250,
        (x) => x.opportunityTitle,
        "Associated Procurement Opportunity Title",
        "opportunityTitle",
        { sortable: false }
      )}
      {GetCustomColumnBody<TableRecord>(
        styles,
        150,
        (x) => formatDate(x.attachmentDate, DateFormat.dd_MMMM_yyyy),
        "Date Attachment was Posted",
        "attachmentDate"
      )}
      {GetCustomColumnBody<TableRecord>(
        styles,
        150,
        (x) => x.keyword,
        "Keywords",
        "keywords",
        { sortable: false }
      )}
    </DataTable>
  );
};

export default LCPSuggestionsTable;
