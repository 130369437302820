import { UserContextType } from "../services/UserContext";

const configs = {
  API: {
    endpoints: [
      {
        name: "BackendWebAPI",
        endpoint: "https://wduqw5xnr2.execute-api.us-east-1.amazonaws.com/Prod", // "https://j4m7f4lve5.execute-api.us-west-2.amazonaws.com/Prod"
      },
      {
        name: "LocalTest",
        endpoint: "https://localhost:44336",
      },
      {
        name: "BackendWebAPIDev",
        endpoint: "https://uesu3rp516.execute-api.us-east-1.amazonaws.com/Prod", //"https://wduqw5xnr2.execute-api.us-east-1.amazonaws.com/Dev" // "https://j4m7f4lve5.execute-api.us-west-2.amazonaws.com/Prod"
      },
      {
        name: "EmailerDev",
        endpoint: "https://e6vbqzhtb2.execute-api.us-east-1.amazonaws.com/dev/",
      },
      {
        name: "EmailerProd",
        endpoint:
          "https://qq1foqfw8c.execute-api.us-east-1.amazonaws.com/prod/",
      },
    ],
    dedicatedEndpoints: [
      {
        name: "LocalTest",
        endpoint: "https://localhost:44327",
      },
      {
        name: "BackendWebAPI",
        endpoint: "https://api.aidkonekt.com",
      },
      {
        name: "BackendWebAPIDev",
        endpoint: "https://devapi.aidkonekt.com",
      },
    ],
  },
};

const ApiName = process.env.REACT_APP_LOCAL_API
  ? "LocalTest"
  : process.env.REACT_APP_STAGE === "dev"
  ? "BackendWebAPIDev"
  : "BackendWebAPI";
const EmailerAPIName = process.env.REACT_APP_LOCAL_API
  ? "EmailerDev"
  : process.env.REACT_APP_STAGE === "dev"
  ? "EmailerDev"
  : "EmailerProd";

const createAuthenticatedRequest = (
  context: UserContextType,
  params?: { additionalHeaders?: any; body?: any }
) => {
  let otherHeaders = {};
  if (params && params.additionalHeaders) {
    otherHeaders = { ...params.additionalHeaders };
  }

  let body: string | null = null;
  if (params && params.body) {
    body = JSON.stringify(params.body);
  }

  const jwt = context?.user?.jwt ?? null;
  const idToken = context?.user?.idToken ?? null;
  const konektidToken = context?.user?.konektidToken ?? null;

  return jwt
    ? {
        headers: {
          Authorization: jwt,
          AccessToken: idToken,
          X_Konektid_Authorization: konektidToken,
          ...otherHeaders,
        },
        body,
      }
    : undefined;
};

const createRequestWithAuthHeaders = (context: UserContextType) => {
  const jwt = context?.user?.jwt ?? null;
  const accessToken = context?.user?.accessToken ?? null;
  const konektidToken = context?.user?.konektidToken ?? null;

  return jwt
    ? {
        headers: {
          Authorization: jwt,
          AccessToken: accessToken,
          X_Konektid_Authorization: konektidToken,
        },
      }
    : undefined;
};

const getApiEndpoint = (useDedicatedEnvironment: boolean) => {
  const endpoints = useDedicatedEnvironment
    ? configs.API.dedicatedEndpoints
    : configs.API.endpoints;
  const endpoint = endpoints.find((r) => r.name === ApiName);
  if (!endpoint) throw new Error("unable to find API for " + ApiName);
  return endpoint.endpoint; //dedicatedEndpoints
};

const getEmailerApiEndpoint = () => {
  const endpoint = configs.API.endpoints.find((r) => r.name === EmailerAPIName);
  if (!endpoint) throw new Error("unable to find API for " + EmailerAPIName);

  return endpoint.endpoint;
};

const getFullUrl = (
  url: string,
  config?: { useDedicatedEnvironment: boolean }
) => {
  const baseUrl = getApiEndpoint(config?.useDedicatedEnvironment ?? false);
  return `${baseUrl}${url}`;
};

const getEmailerFullUrl = (url) => {
  const baseUrl = getEmailerApiEndpoint();
  return `${baseUrl}${url}`;
};

export {
  ApiName,
  EmailerAPIName,
  createAuthenticatedRequest,
  getApiEndpoint,
  getFullUrl,
  getEmailerFullUrl,
  createRequestWithAuthHeaders,
};

export default configs;
