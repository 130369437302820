import { ClipboardIcon } from "../../assets/icons/material-icons/clipboard";
import { format } from "date-fns";
import { getNewDateNoTimezoneAdjustment } from "../../utils/conversion/date-converters";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import React from "react";
import { Theme } from "@mui/material/styles";
import { BoltIcon } from "../../assets/icons/material-icons/bolt";
import { DocumentApprovedIcon } from "../../assets/icons/material-icons/document-approved";
import { OfficeBagIcon } from "../../assets/icons/material-icons/office-bag";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { AwardIcon } from "../../assets/icons/material-icons/award";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { LocationInfoIcon } from "../../assets/icons/material-icons/location-info";
import { OfficeBuildingIcon } from "../../assets/icons/material-icons/office-building";
import PortraitOutlinedIcon from "@mui/icons-material/PortraitOutlined";
import InfoRow from "./InfoRow";
import { getEmptyStringWithCurrencyIfNull } from "../../screens/Grants/grantsUtils";
import Button from "@mui/material/Button";

export type SourceType = "forecast" | "contracts" | "grants";

export type Tab =
  | "primes"
  | "q&a"
  | "bid"
  | "notes"
  | "award-details"
  | "contact-details"
  | "related-documents"
  | "eligibility";

const icons = {
  clipboard: <ClipboardIcon />,
  locationOn: <LocationOnOutlinedIcon />,
  locationInfo: <LocationInfoIcon />,
  localOffer: <LocalOfferOutlinedIcon />,
  calendar: <CalendarTodayOutlinedIcon />,
  award: <AwardIcon />,
  requestQuote: <RequestQuoteOutlinedIcon />,
  checkbox: <CheckBoxOutlinedIcon />,
  officeBuilding: <OfficeBuildingIcon />,
  portrait: <PortraitOutlinedIcon />,
  bolt: <BoltIcon />,
  documentApproved: <DocumentApprovedIcon />,
  officeBag: (
    <OfficeBagIcon htmlColor={"white"} sx={{ fill: "white !important" }} />
  ),
};

const tabsTitles: Record<Tab, string> = {
  primes: "Suggested Primes/Partners",
  "q&a": "Quarterly Forecast Q&A",
  bid: "Bid Documents",
  notes: "Pipelines Notes",
  "award-details": "Award Details",
  "contact-details": "Contact Details",
  "related-documents": "Related Documents",
  eligibility: "Eligibility",
};

export const getDateDisplay = (
  value: any,
  timezoneAdjustment: boolean = true,
  _format: string = "dd MMMM yyyy, hh:mm",
): string =>
  value
    ? new Date(value) && new Date(value).toISOString().startsWith("0")
      ? "N/A"
      : format(
          timezoneAdjustment
            ? getNewDateNoTimezoneAdjustment(value)
            : new Date(value),
          _format,
        )
    : "N/A";

export const getTopBarData = (
  theme: Theme,
  data: any,
  source: SourceType,
): Record<
  string,
  {
    label: string;
    value: string;
    icon: JSX.Element;
  }
> =>
  source === "forecast"
    ? {
        id: {
          label: "ID:",
          value: data?.aaPlanId ?? "N/A",
          icon: icons.clipboard,
        },
        date: {
          label: "Date Record Created / Changed:",
          value: getDateDisplay(data?.createdOn, false),
          icon: icons.calendar,
        },
      }
    : source === "contracts"
    ? {
        id: {
          label: "Contract Award:",
          value: data?.awardNumber ?? "N/A",
          icon: icons.clipboard,
        },
        originalPublish: {
          label: "Original Publish:",
          value: getDateDisplay(data?.postedDateIn, true, "dd MMMM yyyy"),
          icon: icons.calendar,
        },
        lastUpdated: {
          label: "Last Updated:",
          value: getDateDisplay(data?.lastUpdated, true, "dd MMMM yyyy"),
          icon: icons.calendar,
        },
      }
    : {
        id: {
          label: "Opportunity Number:",
          value: data?.opportunityNumber ?? "N/A",
          icon: (
            <ClipboardIcon
              sx={{
                height: "18px !important",
                width: "18px !important",
                color: theme.palette.text.secondary,
              }}
            />
          ),
        },
        posting: {
          label: "Posting:",
          value: getDateDisplay(data?.postingDate, true, "dd MMMM yyyy"),
          icon: icons.calendar,
        },
        lastUpdated: {
          label: "Last Updated:",
          value: getDateDisplay(data?.lastUpdatedDate, true, "dd MMMM yyyy"),
          icon: icons.calendar,
        },
      };

export const labelSettings = (
  theme: Theme,
  source: "forecast" | "contracts" | "grants",
) => {
  const options = {
    forecast: {
      label: "Forecast+",
      backgroundColor: theme.palette.secondary.main,
      boxShadowColor: theme.palette.secondary.dark,
      icon: icons.bolt,
    },
    contracts: {
      label: "Contracts+",
      backgroundColor: theme.palette.success.main,
      boxShadowColor: theme.palette.success.dark,
      icon: icons.documentApproved,
    },
    grants: {
      label: "Grants+",
      backgroundColor: theme.palette.primary.main,
      boxShadowColor: theme.palette.primary.dark,
      icon: icons.officeBag,
    },
  };

  return options[source];
};

export const urlSettings = (
  source: "forecast" | "contracts" | "grants",
  id: string | number,
) => {
  const options = {
    forecast: {
      label: "usaid.gov",
      url: `https://www.usaid.gov/node/${id}`,
    },
    contracts: {
      label: "sam.gov",
      url: `https://sam.gov/opp/${id}/view`,
    },
    grants: {
      label: "grants.gov",
      url: `https://grants.gov/search-results-detail/${id}`,
    },
  };

  return options[source];
};

export const getTabButtons = (
  source: SourceType,
  selectedTab: Tab,
  handleChangeTab: (tab: Tab) => void,
): JSX.Element => {
  const _tabs: Tab[] =
    source === "forecast"
      ? [
          "primes",
          "q&a",
          "bid",
          // , "notes"
        ]
      : source === "contracts"
      ? [
          "award-details",
          "contact-details",
          "related-documents",
          // , "notes"
        ]
      : [
          "eligibility",
          "contact-details",
          "related-documents",
          // , "notes"
        ];
  return (
    <>
      {_tabs.map((_tab) => (
        <Button
          size={"small"}
          onClick={() => handleChangeTab(_tab)}
          color={"secondary"}
          variant={selectedTab === _tab ? "contained" : "secondaryContained"}
        >
          {tabsTitles[_tab]}
        </Button>
      ))}
    </>
  );
};

export const getInfoPanelContent = (
  theme: Theme,
  data: any,
  source: SourceType,
): JSX.Element =>
  source === "forecast" ? (
    <>
      {Object.values(getForecastInfoPanelData(theme, data)).map(
        (item, idx, array) => (
          <InfoRow {...item} bottomDivider={idx < array.length - 1} />
        ),
      )}
    </>
  ) : source === "contracts" ? (
    <>
      {Object.values(getContractsInfoPanelData(theme, data)).map(
        (item, idx, array) => (
          <InfoRow {...item} bottomDivider={idx < array.length - 1} />
        ),
      )}
    </>
  ) : (
    <>
      {Object.values(getGrantsInfoPanelData(theme, data)).map(
        (item, idx, array) => (
          <InfoRow {...item} bottomDivider={idx < array.length - 1} />
        ),
      )}
    </>
  );

const getForecastInfoPanelData = (
  theme: Theme,
  data: any,
): Record<string, { label: string; value: string; icon: JSX.Element }> => ({
  country: {
    label: "Country/Office",
    value: data?.country ?? "N/A",
    icon: icons.locationOn,
  },
  sector: {
    label: "Sector",
    icon: icons.localOffer,
    value: data?.sector ?? "N/A",
  },
  anticipatedReleaseDate: {
    label: "Anticipated Release Date",
    icon: icons.calendar,
    value: getDateDisplay(
      data?.anticipatedSolicitationReleaseDate,
      false,
      "dd MMMM yyyy",
    ),
  },
  awardActionType: {
    label: "Award/Action Type",
    icon: icons.award,
    value: data?.awardActionType ?? "N/A",
  },
  anticipatedAwardDate: {
    label: "Anticipated Award Date",
    icon: icons.award,
    value: getDateDisplay(data?.anticipatedAwardDate, false, "dd MMMM yyyy"),
  },
  awardLength: {
    label: "Award Length",
    icon: icons.calendar,
    value: data?.awardLength ?? "N/A",
  },
  cost: {
    label: "Cost",
    icon: icons.requestQuote,
    value: data?.totalEstimatedCost ?? "N/A",
  },
  eligibilityCriteria: {
    label: "Eligibility Criteria",
    icon: icons.checkbox,
    value: data?.eligibilityCriteria ?? "N/A",
  },
  fiscalYearOfAction: {
    label: "Fiscal Year Of Action",
    icon: icons.calendar,
    value: data?.fiscalYearofAction ?? "N/A",
  },
  solicitationNumber: {
    label: "Solicitation Number",
    icon: icons.calendar,
    value: data?.solicitationNumber ?? "N/A",
  },
  geoCodes: {
    label: "Geographical Codes",
    icon: icons.locationInfo,
    value: data?.geographicalCodes ?? "N/A",
  },
  location: {
    label: "Location",
    icon: icons.locationOn,
    value: data?.location ?? "N/A",
  },
  setAside: {
    label: "Small Business Set-Aside",
    icon: icons.officeBuilding,
    value: data?.smallBusinessSetAside ?? "N/A",
  },
  aaSpecialist: {
    label: "A&A Specialist",
    icon: icons.portrait,
    value: data?.aaSpecialist ?? "N/A",
  },
});

const getContractsInfoPanelData = (
  theme: Theme,
  data: any,
): Record<string, { label: string; value: string; icon: JSX.Element }> => ({
  country: {
    label: "Country/Office",
    value: data?.country ?? "N/A",
    icon: icons.locationOn,
  },
  agencyName: {
    label: "Department/Agency",
    icon: icons.localOffer,
    value: data?.agencyName ?? "N/A",
  },
  solicitationDeadlineResponseIn: {
    label: "Offer Due Date",
    icon: icons.calendar,
    value: getDateDisplay(
      data?.solicitationDeadlineResponseIn,
      true,
      "dd MMMM yyyy",
    ),
  },
  solicitationNumber: {
    label: "Solicitation Number",
    icon: icons.calendar,
    value: data?.solicitationNumber ?? "N/A",
  },
  classificationCode: {
    label: "Product Service Code",
    icon: icons.checkbox,
    value: data?.classificationCode ?? "N/A",
  },
  naicsCodes: {
    label: "NAICS",
    icon: icons.locationInfo,
    value: data?.naicsCodes ?? "N/A",
  },
  placeOfPerformance: {
    label: "Place Of Performance",
    icon: icons.locationInfo,
    value:
      data?.placeOfPerformance && data?.placeOfPerformance.length > 0
        ? data?.placeOfPerformance
        : "N/A",
  },
  setAside: {
    label: "Set-Aside",
    icon: icons.officeBuilding,
    value: data?.setAside ?? "N/A",
  },
});

const getGrantsInfoPanelData = (
  theme: Theme,
  data: any,
): Record<string, { label: string; value: string; icon: JSX.Element }> => ({
  topLevelAgency: {
    label: "Agency",
    value: data?.topLevelAgency ?? "N/A",
    icon: icons.localOffer,
  },
  agencyName: {
    label: "Misson / Bureau",
    icon: icons.locationOn,
    value: data?.agencyName ?? "N/A",
  },
  fundingInstrumentDescription: {
    label: "Funding Instrument Type",
    icon: icons.calendar,
    value:
      Array.isArray(data?.fundingInstrumentDescription) &&
      data?.fundingInstrumentDescription?.length > 0
        ? data.fundingInstrumentDescription
            .map((instrument) => instrument.description)
            .join(", ")
        : "N/A",
  },
  opportunityCategoryDescription: {
    label: "Opportunity Category",
    icon: icons.calendar,
    value: data?.opportunityCategoryDescription ?? "N/A",
  },
  fundingActivityDescription: {
    label: "Category of Funding Activity",
    icon: icons.checkbox,
    value:
      Array.isArray(data?.fundingActivityDescription) &&
      data?.fundingActivityDescription?.length > 0
        ? data.fundingActivityDescription
            .map((activity) => activity.description)
            .join(", ")
        : "N/A",
  },
  originalDueDate: {
    label: "Original Closing Date for Applications",
    icon: icons.calendar,
    value: getDateDisplay(data?.originalDueDate, true, "dd MMMM yyyy"),
  },
  responseDate: {
    label: "Current Closing Date for Applications",
    icon: icons.calendar,
    value: getDateDisplay(data?.responseDate, true, "dd MMMM yyyy"),
  },
  awardCeilingFormatted: {
    label: "Award Ceiling",
    icon: icons.locationInfo,
    value: getEmptyStringWithCurrencyIfNull(data?.awardCeilingFormatted, "N/A"),
  },
  awardFloorFormatted: {
    label: "Award Floor",
    icon: icons.locationInfo,
    value: getEmptyStringWithCurrencyIfNull(data?.awardFloorFormatted, "N/A"),
  },
  cfdas: {
    label: "CFDA Number(s)",
    icon: icons.locationInfo,
    value:
      Array.isArray(data?.cfdas) && data?.cfdas?.length > 0
        ? data.cfdas
            .map((cfda) => `${cfda.cfdaNumber}: ${cfda.programTitle}`)
            .join("\n")
        : "N/A",
  },
});
