import { Theme } from "@mui/material/styles";
import React, { Dispatch, ReactNode, SetStateAction } from "react";
import {
  GrantsFiltersType,
  GrantsPagination,
  SummaryGrant,
} from "../../slices/grants";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import {
  Avatar,
  Box,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { FiltersIcon } from "../../components/Icons/FiltersIcon";
import { SourceIcon } from "../../components/Icons/SourceIcon";
import { LastUpdatedIcon } from "../../components/Icons/LastUpdatedIcon";
import { TableProperties } from "exceljs";
import {
  buildExcelTable,
  genEmptyRow,
  getChunksFromString,
  MaxCharsAllowed,
} from "../../services/exporter";
import { removeHtmlTags } from "../../services/dataParser";
import { DateFormat, formatDate } from "../../utils/conversion/date-converters";
import { ForecastRecord } from "../../utils/types/Forecast";
import axios from "axios";
import {
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import { buildQuery } from "../../utils/Helpers/queryBuilder";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

export const getSortableColumnPropertyName = (
  _propertyName: string,
): string => {
  return _propertyName === "lastUpdatedDateIn"
    ? "lastUpdatedDate"
    : _propertyName === "secondaryContactInfo"
    ? "pointOfContactTwo"
    : _propertyName;
};

const isNumeric = (str: any) => {
  return !isNaN(str) && !isNaN(parseFloat(str));
};

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const getTableHeader = (
  theme: Theme,
  props: {
    handleOpenFilters: () => void;
    setFilters: Dispatch<SetStateAction<any>>;
    lastUpdated: string | null;
    fetchStatus: string;
    inputValue: string;
    onChange: (event) => void;
    // extendedFilters: ForecastExtendedFiltersProps;
    allFilters: {
      pagination: GrantsPagination;
      filters: GrantsFiltersType;
      sortOption: {
        sortField: string;
        sortOrder: number;
      };
    };
  },
): Array<ReactNode> => {
  const {
    handleOpenFilters,
    setFilters,
    lastUpdated,
    fetchStatus,
    inputValue,
    onChange,
    // extendedFilters,
    allFilters: { pagination, filters, sortOption },
  } = props;

  const isDataFetching = RequestStatus.isFetching(fetchStatus);

  const extendedFiltersLength = Object.entries(filters)
    ?.filter(
      ([key, value]) =>
        !key.includes("include") &&
        !key.includes("sort") &&
        key !== "onlyUntrackedInPipeline",
    )
    ?.filter(([key, value]) =>
      Array.isArray(value) ? value.length > 0 : !!value,
    )
    ?.map(([key, value]) => key)?.length;

  const topLine = (
    <Box
      sx={{
        pt: 1,
        display: "flex",
        gap: "16px",
        alignItems: "center",
      }}
    >
      {/* todo: temporarily hidden, restore and adjust when possible */}
      {/*<TextField*/}
      {/*  value={inputValue}*/}
      {/*  onChange={onChange}*/}
      {/*  sx={{*/}
      {/*    width: "300px",*/}
      {/*  }}*/}
      {/*  InputProps={{*/}
      {/*    startAdornment: (*/}
      {/*      <SearchIcon sx={{ color: theme.palette.action.active }} />*/}
      {/*    ),*/}
      {/*    endAdornment: (*/}
      {/*      <>*/}
      {/*        {inputValue?.length > 0 && (*/}
      {/*          <IconButton*/}
      {/*            onClick={() => onChange({ target: { value: "" } })}*/}
      {/*            sx={{ mr: 0.5 }}*/}
      {/*            disabled={isDataFetching}*/}
      {/*          >*/}
      {/*            <ClearIcon />*/}
      {/*          </IconButton>*/}
      {/*        )}*/}
      {/*        <Box*/}
      {/*          sx={{*/}
      {/*            borderLeft: `solid 1px ${theme.palette.divider}`,*/}
      {/*            pl: 0.5,*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <Tooltip title={"Filters"}>*/}
      {/*            <span>*/}
      {/*              <IconButton onClick={handleOpenFilters}>*/}
      {/*                <FiltersIcon sx={{ width: "24px", height: "24px" }} />*/}
      {/*              </IconButton>*/}
      {/*              {extendedFiltersLength > 0 && (*/}
      {/*                <Avatar*/}
      {/*                  sx={{*/}
      {/*                    position: "absolute",*/}
      {/*                    height: "16px",*/}
      {/*                    width: "16px",*/}
      {/*                    top: "2px",*/}
      {/*                    right: "2px",*/}
      {/*                    fontSize: "12px",*/}
      {/*                    backgroundColor: theme.palette.secondary.main,*/}
      {/*                    // border: `solid 1px ${theme.palette.secondary.main}`,*/}
      {/*                    pointerEvents: "none",*/}
      {/*                  }}*/}
      {/*                >*/}
      {/*                  {extendedFiltersLength}*/}
      {/*                </Avatar>*/}
      {/*              )}*/}
      {/*            </span>*/}
      {/*          </Tooltip>*/}
      {/*        </Box>*/}
      {/*      </>*/}
      {/*    ),*/}
      {/*    sx: {*/}
      {/*      px: 0.5,*/}
      {/*    },*/}
      {/*  }}*/}
      {/*  // inputProps={{*/}
      {/*  //   style: {*/}
      {/*  //     padding: "8px",*/}
      {/*  //   },*/}
      {/*  // }}*/}
      {/*  size={"small"}*/}
      {/*  placeholder={"Search"}*/}
      {/*  // disabled={isDataFetching}*/}
      {/*/>*/}
      <Tooltip title={"Filters"}>
        <span>
          <Button
            variant={"outlined"}
            endIcon={<FiltersIcon sx={{ width: "24px", height: "24px" }} />}
            size={"small"}
            onClick={handleOpenFilters}
          >
            Filters
            {extendedFiltersLength > 0 && (
              <Avatar
                sx={{
                  position: "absolute",
                  height: "16px",
                  width: "16px",
                  top: "2px",
                  right: "6px",
                  fontSize: "12px",
                  backgroundColor: theme.palette.secondary.main,
                  // border: `solid 1px ${theme.palette.secondary.main}`,
                  pointerEvents: "none",
                }}
              >
                {extendedFiltersLength}
              </Avatar>
            )}
          </Button>
        </span>
      </Tooltip>
      <FormControlLabel
        onChange={(e, checked) =>
          setFilters((prev) => ({
            ...prev,
            onlyUntrackedInPipeline: checked,
          }))
        }
        control={<Switch checked={filters.onlyUntrackedInPipeline} />}
        label={"Exclude Tracked Opportunities"}
        disabled={isDataFetching}
      />
    </Box>
  );

  const bottomLine = (
    <Box
      sx={{
        display: "flex",
        gap: "16px",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <FormControlLabel
          onChange={(e, checked) =>
            setFilters((prev) => ({ ...prev, includeUsaid: checked }))
          }
          control={<Switch checked={filters.includeUsaid} />}
          label={"USAID"}
          disabled={isDataFetching}
        />
        <FormControlLabel
          onChange={(e, checked) =>
            setFilters((prev) => ({ ...prev, includeMcc: checked }))
          }
          control={<Switch checked={filters.includeMcc} />}
          label={"MCC"}
          disabled={isDataFetching}
        />
        <FormControlLabel
          onChange={(e, checked) =>
            setFilters((prev) => ({ ...prev, includeStateDept: checked }))
          }
          control={<Switch checked={filters.includeStateDept} />}
          label={"State Dept"}
          disabled={isDataFetching}
        />
        <FormControlLabel
          onChange={(e, checked) =>
            setFilters((prev) => ({ ...prev, includeCdc: checked }))
          }
          control={<Switch checked={filters.includeCdc} />}
          label={"CDC"}
          disabled={isDataFetching}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "24px",
          "& > *": { display: "flex", alignItems: "center" },
        }}
      >
        <Typography variant={"subtitle2"} color={"textSecondary"}>
          <SourceIcon
            color={"secondary"}
            sx={{
              height: "16px",
              width: "16px",
              mr: 0.5,
              mt: "-2px",
            }}
          />
          Source: grants.gov
        </Typography>
        <Typography variant={"subtitle2"} color={"textSecondary"}>
          <LastUpdatedIcon
            sx={{
              height: "16px",
              width: "16px",
              mr: 0.5,
              mt: "-2px",
            }}
          />
          Last updated: {lastUpdated ?? "N/A"}
        </Typography>
      </Box>
    </Box>
  );

  return [topLine, bottomLine];
};

export const getExportableDataTable = (
  data: SummaryGrant[],
): TableProperties => {
  const columns = [
    { name: "Grants.gov link" },
    { name: "Agency" },
    { name: "Mission / Bureau" },
    { name: "Opportunity Title" },
    { name: "Description" },
    { name: "Opportunity Status" },
    { name: "Last Updated Date" },
    { name: "Opportunity Number" },
    { name: "Funding Instrument Type" },
    { name: "Opportunity Category" },
    { name: "Category of Funding Activity" },
    { name: "Expected Number of Awards" },
    { name: "CFDA Number(s)" },
    { name: "Cost Sharing or Matching Requirement" },
    { name: "Version" },
    { name: "Original Closing Date for Applications" },
    { name: "Current Closing Date for Applications" },
    { name: "Posting Date" },
    { name: "Archive Date" },
    { name: "Estimated Total Program Funding" },
    { name: "Award Ceiling" },
    { name: "Award Floor" },
    { name: "Eligible Applicants" },
    { name: "Additional Information on Eligibility" },
    { name: "Contact Name" },
    { name: "Contact Description" },
    { name: "Contact Email" },
    { name: "Contact Phone" },
  ];

  let rows: any[][] = [];

  if (data.length === 0) {
    rows.push(genEmptyRow(columns.length));
  } else {
    for (let i = 0; i < data.length; i++) {
      const record = data[i];
      let row = [
        record?.id
          ? `https://www.grants.gov/web/grants/view-opportunity.html?oppId=${record?.id}`
          : "",
        record.topLevelAgency,
        record.record.agencyName,
        removeHtmlTags(record.opportunityTitle),
        removeHtmlTags(record.record.description),
        record.record.status,
        record.manualLastUpdatedDate
          ? formatDate(record.manualLastUpdatedDate, DateFormat.dd_MMMM_yyyy)
          : "",
        record.opportunityNumber,
        record.record.fundingInstrumentDescription,
        record.opportunityCategoryDescription,
        record.record.fundingActivityDescription,
        record.record.numberOfAwards,
        record.cfdas.join(", "),
        record.record.costSharing ? "True" : "False",
        record.record.version,
        formatDate(record.originalDueDate, DateFormat.dd_MMMM_yyyy),
        formatDate(record.record.responseDate, DateFormat.dd_MMMM_yyyy),
        formatDate(record.record.postingDate, DateFormat.dd_MMMM_yyyy),
        formatDate(record.record.archiveDate, DateFormat.dd_MMMM_yyyy),
        `$${record.record.estimatedFundingFormatted}`,
        `$${record.record.awardCeilingFormatted}`,
        record.record.awardFloorFormatted,
        record.record.applicantTypeDescription,
        removeHtmlTags(record.record.applicantEligibilityDescription),
        removeHtmlTags(record.record.agencyContactName),
        removeHtmlTags(record.record.agencyContactDescription),
        record.record.agencyContactEmail,
        record.record.agencyContactPhone,
      ];

      rows.push(row);
    }
  }

  return buildExcelTable("GrantsExport", columns, rows);
};

export const getExcelData = async (
  context,
  queryDto,
  totalItems: number = 1000,
): Promise<ForecastRecord[]> => {
  const q = queryDto;
  if (q) {
    q.pageSize = totalItems;
    q.pageIndex = 0;
  }
  let result = Array<any>();
  await axios
    .get(
      getFullUrl(`/api/grant${buildQuery(q, "|")}`, {
        useDedicatedEnvironment: true,
      }),
      createRequestWithAuthHeaders(context),
    )
    .then((response) => {
      result = response.data.data;
    })
    .catch((error) => {
      alert("error fetching data");
    });
  return result;
};

export const getEmptyStringWithCurrencyIfNull = (
  d: string | null,
  defaultReturn?: string,
) => (d === null ? defaultReturn ?? "" : `$${d}`);
