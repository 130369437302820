import "datejs";

export function getFullTimeString(date: Date) {
  let [hours] = date.toLocaleTimeString().split(":");
  if (hours === "Invalid Date") {
    return "";
  }

  return date.toString("H:mm");
}

export function getFullDateString(date: Date) {
  if (date.toString() === "Invalid Date") {
    return "";
  }

  return date.toString("dd MMMM yyyy");
}

export function getDateString(date: Date) {
  return `${getFullDateString(date)}, ${getFullTimeString(date)}`;
}
