import React, { FC } from "react";
import {
  Box,
  Divider,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";

interface InfoRowProps {
  label: string;
  icon: JSX.Element;
  value: string;
  bottomDivider?: boolean;
  secondValue?: string;
  secondValueProps?: TypographyProps;
  extraValues?: Array<string>;
  extraValuesProps?: TypographyProps;
}

const InfoRow: FC<InfoRowProps> = (props) => {
  const {
    label,
    icon,
    value,
    bottomDivider = false,
    secondValue = null,
    secondValueProps = {},
    extraValues = [],
    extraValuesProps = {},
  } = props;

  const theme = useTheme();

  return (
    <>
      <Box sx={{ my: 2 }}>
        <Box
          sx={{
            display: "flex",
            gap: "4px",
            alignItems: "center",
            justifyContent: "flex-start",
            mb: 0.5,
            "& > svg": {
              fill: theme.palette.text.secondary,
              height: "18px !important",
              width: "18px !important",
            },
          }}
        >
          {icon}
          <Typography variant={"body2"} color={"textSecondary"} align={"left"}>
            {label}
          </Typography>
        </Box>
        <Typography
          variant={"subtitle2"}
          fontWeight={"600"}
          color={"textPrimary"}
          align={"left"}
        >
          {value}
        </Typography>
        {secondValue && (
          <Typography
            fontWeight={"600"}
            align={"left"}
            variant={"subtitle2"}
            color={"textPrimary"}
            {...secondValueProps}
          >
            {secondValue}
          </Typography>
        )}
        {extraValues.length > 0 &&
          extraValues.map((extraValue) => (
            <Typography
              fontWeight={"600"}
              align={"left"}
              variant={"subtitle2"}
              color={"textPrimary"}
              {...extraValuesProps}
            >
              {extraValue}
            </Typography>
          ))}
      </Box>
      {bottomDivider && <Divider sx={{ mx: -2, width: "calc(100% + 64px)" }} />}
    </>
  );
};

export default InfoRow;
