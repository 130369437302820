import { getISODateWithAdjustedTimezone } from "../conversion/date-converters";

export const buildQuery = (
  params: { [key: string]: unknown },
  joinMultipleValuesWith: string | null = null,
  removeEmptyFields: boolean = false,
): string => {
  let queryParams: Array<string> = [];

  Object.entries(params)?.forEach(([key, value]) => {
    if (
      value === undefined ||
      (removeEmptyFields &&
        ((!value && value !== 0) || (Array.isArray(value) && !value?.length)))
    )
      return;
    if (Array.isArray(value)) {
      if (joinMultipleValuesWith)
        queryParams.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(
            value.map((_v) => _v).join(joinMultipleValuesWith),
          )}`,
        );
      else
        value.forEach((_v) =>
          queryParams.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(_v)}`,
          ),
        );
    } else {
      let queryParam: string = `${encodeURIComponent(key)}=${encodeURIComponent(
        typeof (value as any)?.getMonth === "function"
          ? getISODateWithAdjustedTimezone(value as Date)
          : (value as any),
      )}`;
      queryParams.push(queryParam.replaceAll("%26", "&"));
    }
  });

  return queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
};
