import React, { FC, useContext } from "react";
import SimpleModal from "./SimpleModal";
import UserContext, {
  doesUserHaveRole,
  ServerOrgRoles,
} from "../../services/UserContext";

interface UpgradePlanModalProps {
  upgradePlanModalOpen: boolean;
  setUpgradePlanModalOpen: (value: boolean) => any;
}

const UpgradePlanModal: FC<UpgradePlanModalProps> = (props) => {
  const { upgradePlanModalOpen, setUpgradePlanModalOpen } = props;

  const context = useContext(UserContext);
  const isMember = doesUserHaveRole(context, ServerOrgRoles.organizationMember);

  return (
    <SimpleModal
      title={"Upgrade"}
      open={upgradePlanModalOpen}
      handleClose={() => setUpgradePlanModalOpen(false)}
      disableCancelButton
      handleAccept={() => {
        window.open("tiers", "_blank");
        setTimeout(() => {
          setUpgradePlanModalOpen(false);
        }, 150);
      }}
      acceptLabel={"Upgrade"}
      acceptButtonDisabled={isMember}
      contentSx={{
        overflow: "auto",
      }}
    >
      {isMember
        ? "Contact your account admin to renew the plan"
        : "Upgrade your plan for access."}
    </SimpleModal>
  );
};

export default UpgradePlanModal;
