import React, { useState } from "react";
import { css } from "glamor";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { DateFormat, formatDate } from "../utils/conversion/date-converters";
import { useSharedStyles } from "./Dashboard/useSharedStyles";
import { ShowMoreText } from "./ShowMoreText";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./update.css";
import { Calendar } from "primereact/calendar";

const styles = {
  update: {
    textAlign: "left",

    margin: "10px 0",
    padding: "15px",
    borderRadius: "30px",
    whiteSpace: "pre-wrap",
  },
  buttonContainer: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "flex-end",
  },
};

export type UpdateType = {
  id: number;
  message: string;
  createdOn: Date | null;
  title: string;
  source: string;
};
export const convertToUtc = (date) => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      10, // if hour remains zero with gmt it will be posted -2 hour then it will be in the past ( yesterday)
      0,
      0,
    ),
  );
};

const options = [
  { label: "", value: "" },
  { label: "USAID Press Release", value: "USAID Press Release" },
];
type UpdateProps = {
  update: UpdateType;
  canEditUpdates: boolean;
  editUpdate: (
    updateId: number,
    editedUpdateText: string,
    editedUpdateTitle: string,
    editedUpdateDate: null | Date,
    editedUpdateSource: string,
  ) => void;
  deleteUpdate: (updateId: number) => void;
};

const Update = ({
  update,
  canEditUpdates,
  editUpdate,
  deleteUpdate,
}: UpdateProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedUpdateText, setEditedUpdateText] = useState<string>(
    update.message,
  );
  const [editedUpdateTitle, setEditedUpdateTitle] = useState<string>(
    update.title,
  );
  const [editedUpdateDate, setEditedUpdateDate] = useState<Date>(
    update.createdOn ? new Date(update.createdOn) : new Date(),
  );
  const [editedUpdateSource, setEditedUpdateSource] = useState<string>(
    update.source,
  );
  const sharedStyles = useSharedStyles();

  const handleSave = () => {
    setIsEditing(false);
    editUpdate(
      update.id,
      editedUpdateText,
      editedUpdateTitle,
      editedUpdateDate,
      editedUpdateSource,
    );
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
    setEditedUpdateText(update.message);
  };

  const getDate = React.useCallback(() => {
    if (!!update.createdOn) {
      const date = new Date(update.createdOn);
      return new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0),
      );
    }

    return new Date();
  }, [update.createdOn]);

  return (
    <React.Fragment>
      <div
        className={isEditing ? "update" : "update update-back-hover"}
        {...css(styles.update)}
      >
        {isEditing ? (
          <>
            <div className="p-inputgroup">
              <InputText
                placeholder="title"
                value={editedUpdateTitle}
                style={{
                  margin: "10px 0",
                  borderRadius: "30px",
                  width: "100%",
                  background: "inherit",
                }}
                onChange={(e) => setEditedUpdateTitle((e.target as any).value)}
              />
            </div>
            <div className="p-inputgroup calendar-input">
              <Calendar
                appendTo={document.body}
                style={{
                  margin: "10px 0",
                  borderRadius: "30px",
                  width: "40%",
                  background: "inherit",
                }}
                placeholder="mm/dd/yyyy"
                dateFormat="mm/dd/yy"
                value={convertToUtc(editedUpdateDate)}
                onChange={(c) => {
                  setEditedUpdateDate(convertToUtc((c.target as any).value));
                }}
              />

              <select
                value={editedUpdateSource}
                style={{
                  margin: "10px 0",
                  borderRadius: "8px",
                  width: "50%",
                  height: "35px",
                  padding: "9px",
                  background: "inherit",
                  marginLeft: "10px",
                }}
                onChange={(s) => setEditedUpdateSource(s.target.value)}
              >
                {options.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            <div className="p-inputgroup">
              <ReactQuill
                style={{ width: "100%", height: "100%" }}
                theme="snow"
                value={editedUpdateText}
                onChange={(e) => setEditedUpdateText(e)}
              />
            </div>
          </>
        ) : (
          <div style={sharedStyles.potentiallyBlurred(false)}>
            {update.createdOn ? (
              <div style={{ display: "flex" }}>
                <div
                  className="update-date"
                  style={{
                    fontWeight: "bold",
                    marginBottom: "15px",
                    color: "#ecac37",
                  }}
                >
                  {formatDate(getDate().toString(), DateFormat.dd_MMMM_yyyy)}
                </div>
                {update.source ? (
                  <div
                    style={sharedStyles.potentiallyBlurred(false)}
                    className="update-source"
                  >
                    {update.source}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <></>
            )}
            <p>
              <strong> {update.title}</strong>
            </p>
            <ShowMoreText text={update.message} isFree={sharedStyles.isFree} />
          </div>
        )}
        {canEditUpdates && (
          <div {...css(styles.buttonContainer)} className="p-inputgroup">
            {isEditing && (
              <Button
                icon="pi pi-save"
                label="Save"
                className="aid-blu-btn"
                style={{ marginRight: "5px" }}
                disabled={!isEditing}
                onClick={() => handleSave()}
              />
            )}
            <Button
              icon={isEditing ? "pi pi-times" : "pi pi-pencil"}
              label={isEditing ? "Cancel" : "Edit"}
              className="aid-blu-btn"
              style={{ marginRight: "5px" }}
              onClick={() => toggleEditing()}
            />
            <Button
              icon="pi pi-trash"
              label="Delete"
              className="p-button-danger"
              disabled={isEditing}
              onClick={() => deleteUpdate(update.id)}
            />
          </div>
        )}
      </div>
      <div className="update-seperator"></div>
    </React.Fragment>
  );
};

export default Update;
