import React from "react";
import "./ProjectDetails.css";
import aidkonektIcon from "../../assets/AidKonectLogo.png";
import { useGridStyles } from "../../hooks/useGridStyles";
import { Button as PButton } from "primereact/components/button/Button";
import ProjectInfoTable, {
  ProjectTableRecord,
} from "./TableComponents/ProjectInfoTable";
import ProjectTransactionsTable, {
  ProjectTransactionRecord,
} from "./TableComponents/ProjectTransactionsTable";
import { PanelHeader } from "../../components/DataTable/PanelHeader";
import { Panel } from "primereact/panel";

const mockProjectData = {
  startDate: new Date(2019, 3, 28),
  endDate: new Date(2023, 0, 28),
  startPrice: 100,
  currentPrice: 126,
  endPrice: 210,
  projectTableRecords: [
    {
      id: "0",
      status: "Pending",
      notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      country: "Ukraine",
      name: "Name",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    },
    {
      id: "1",
      status: "Pending",
      notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      country: "Ukraine",
      name: "Name",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    },
    {
      id: "2",
      status: "Pending",
      notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      country: "Ukraine",
      name: "Name",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    },
    {
      id: "3",
      status: "Pending",
      notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      country: "Ukraine",
      name: "Name",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    },
    {
      id: "4",
      status: "Pending",
      notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      country: "Ukraine",
      name: "Name",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    },
  ],
  projectTransactionRecords: [
    {
      id: "00000",
      name: "Name",
      date: new Date(),
      amount: "200",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    },
    {
      id: "00001",
      name: "Name",
      date: new Date(),
      amount: "200",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    },
    {
      id: "00002",
      name: "Name",
      date: new Date(),
      amount: "200",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    },
    {
      id: "00003",
      name: "Name",
      date: new Date(),
      amount: "200",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    },
    {
      id: "00004",
      name: "Name",
      date: new Date(),
      amount: "200",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    },
  ],
};

const ProjectDetails = () => {
  const maxWidth = 85;
  const styles = useGridStyles();

  const headerRef = React.useRef<HTMLElement>(null);
  const projectInfoRef = React.useRef<HTMLElement>(null);
  const projectReportsRef = React.useRef<HTMLElement>(null);
  const projectTransactionRef = React.useRef<HTMLElement>(null);
  const projectPanelsRef = React.useRef<HTMLElement>(null);

  const [reportsData, setReportsData] = React.useState<ProjectTableRecord[]>();
  const [documentsData, setDocumentsData] =
    React.useState<ProjectTableRecord[]>();
  const [transactionsData, setTransactionsData] =
    React.useState<ProjectTransactionRecord[]>();
  const [transactionTab, setTransactionTab] =
    React.useState("TransactionHistory");

  const [isCollapsedCompilation, setIsCollapsedCompilation] =
    React.useState(true);
  const [isCollapsedAdditional, setIsCollapsedAdditional] =
    React.useState(true);
  const [isCollapsedExecutive, setIsCollapsedExecutive] = React.useState(true);
  const [isCollapsedAwardKey, setIsCollapsedAwardKey] = React.useState(true);
  const [isCollapsedAgency, setIsCollapsedAgency] = React.useState(true);
  const [isCollapsedParentAward, setIsCollapsedParentAward] =
    React.useState(true);

  const scrollToSection = (section: string) => {
    if (
      headerRef.current &&
      projectInfoRef.current &&
      projectReportsRef.current &&
      projectTransactionRef.current
    ) {
      if (section === "reports") {
        window.scrollTo({
          top:
            headerRef.current.clientHeight +
            projectInfoRef.current.clientHeight,
          behavior: "smooth",
        });
      }
      if (section === "subAwardees") {
        window.scrollTo({
          top:
            headerRef.current.clientHeight +
            projectInfoRef.current.clientHeight +
            projectReportsRef.current.clientHeight * 2,
          behavior: "smooth",
        });
      }
      if (section === "details") {
        window.scrollTo({
          top:
            headerRef.current.clientHeight +
            projectInfoRef.current.clientHeight +
            projectReportsRef.current.clientHeight * 2 +
            projectTransactionRef.current.clientHeight,
          behavior: "smooth",
        });
      }
    }
  };

  const handleTransactionTabChange = (tab: string) => {
    setTransactionTab(tab);
    setTransactionsData(mockProjectData.projectTransactionRecords);
  };

  const renderDate = (date) => {
    const day =
      date.getDate().toString().length === 1
        ? `0${date.getDate().toString()}`
        : date.getDate().toString();
    const month =
      date.getMonth().toString().length === 1
        ? `0${(date.getMonth() + 1).toString()}`
        : (date.getMonth() + 1).toString();
    const year = date.getFullYear().toString();

    return day + "/" + month + "/" + year;
  };

  const renderDateRemaining = (startDate, endDate) => {
    const months = endDate.getMonth() - startDate.getMonth();
    const years = endDate.getFullYear() - startDate.getFullYear();

    return months > 0
      ? `${years} years, ${months} months remaining`
      : `${years - 1} years, ${12 + months} months remaining`;
  };

  const renderProgressBar = (startIndex, endIndex, currentIndex, date?) => {
    let progress = 0;

    if (date) {
      const now = currentIndex.getTime() / (1000 * 3600);
      const start = startIndex.getTime() / (1000 * 3600);
      const end = endIndex.getTime() / (1000 * 3600);
      const total = Math.floor(end - start);
      const elapsed = Math.floor(now - start);
      progress = Math.floor((elapsed / total) * 100);
    } else {
      progress = Math.floor(
        ((currentIndex - startIndex) * 100) / (endIndex - startIndex)
      );
    }

    return (
      <>
        <div
          className="start-line"
          style={{ minWidth: `${(progress * maxWidth) / 100}%` }}
        />
        {date ? (
          <span>Today {progress}%</span>
        ) : (
          <span>
            {currentIndex}$ {progress}%
          </span>
        )}
        <div className="end-line" />
      </>
    );
  };

  React.useEffect(() => {
    setReportsData(mockProjectData.projectTableRecords);
    setDocumentsData(mockProjectData.projectTableRecords);
    setTransactionsData(mockProjectData.projectTransactionRecords);
  }, []);

  return (
    <div className="project-details-screen">
      <section className="header-section" ref={headerRef}>
        <img className="" src={aidkonektIcon} alt="logo" />
        <div className="header-data">
          <div className="header-data-content">
            <div className="data">
              <span>
                Implementer: <b>XX</b>
              </span>
            </div>
            <div className="data">
              <span>
                Country: <b>XX</b>
              </span>
            </div>
          </div>
          <div className="header-data-content">
            <span>Recipient Business Type</span>
            <span>1234 Street Address City, State 00000</span>
          </div>
        </div>
        <button className="feedback-btn">Submit Feedback</button>
        <button className="download-btn aid-blu-btn">Download PDF</button>
      </section>

      <section className="project-info-section" ref={projectInfoRef}>
        <div className="nav-bar">
          <span
            className="nav-item"
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }
          >
            Project Description
          </span>
          <span className="nav-item" onClick={() => scrollToSection("reports")}>
            Project Reports
          </span>
          <span
            className="nav-item"
            onClick={() => scrollToSection("subAwardees")}
          >
            Sub-Awardees
          </span>
          <span className="nav-item" onClick={() => scrollToSection("details")}>
            Details
          </span>
        </div>
        <div className="project-info">
          <span className="project-section-title">
            Project Name | Project Number: AIDOAATO1700027
          </span>
          <span className="project-section-description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore
          </span>
        </div>
        <div className="project-info-data">
          <div className="list-data">
            <span>Award Mechanism</span>
            <div className="address">
              <span>Awarding Agency Name</span>
              <span>1234 Street Address City, State 00000</span>
            </div>
            <span>Technical Sector: XXXXX</span>
            <span>Parent IDV Agency Name: XXXXX</span>
            <span>Set-Aside Type: XXX</span>
            <span>Product or Service Code (PSC): XXX</span>
            <span>
              North American Industry Classification System (NAICS) Code: XXX
            </span>
          </div>
          <div className="box-data">
            <span className="project-section-title">
              In Progress (
              {renderDateRemaining(
                mockProjectData.startDate,
                mockProjectData.endDate
              )}
              )
            </span>
            <div className="progress-block">
              <div className="progress-bar-container">
                <div className="progress-bar-points">
                  <span>{renderDate(mockProjectData.startDate)}</span>
                  <span>{renderDate(mockProjectData.endDate)}</span>
                </div>
                <div className="progress-bar">
                  {renderProgressBar(
                    mockProjectData.startDate,
                    mockProjectData.endDate,
                    new Date(),
                    true
                  )}
                </div>
                <div className="progress-bar-data-container">
                  <div className="progress-bar-data">
                    <span>Start Date</span>
                    <span>{renderDate(mockProjectData.startDate)}</span>
                  </div>
                  <div className="progress-bar-data">
                    <span>Current End Date</span>
                    <span>{renderDate(new Date())}</span>
                  </div>
                  <div className="progress-bar-data">
                    <span>Potential End Date</span>
                    <span>{renderDate(mockProjectData.endDate)}</span>
                  </div>
                </div>
              </div>
            </div>
            <span className="project-section-title">Award Amount</span>
            <div className="progress-block">
              <div className="progress-bar-container">
                <div className="progress-bar-points">
                  <span>{mockProjectData.startPrice}$</span>
                  <span>{mockProjectData.endPrice}$</span>
                </div>
                <div className="progress-bar">
                  {renderProgressBar(
                    mockProjectData.startPrice,
                    mockProjectData.endPrice,
                    mockProjectData.currentPrice
                  )}
                </div>
                <div className="progress-bar-data-container">
                  <div className="progress-bar-data">
                    <span>Obligated Amount</span>
                    <span>{mockProjectData.startPrice}$</span>
                  </div>
                  <div className="progress-bar-data">
                    <span>Current Award Ceiling</span>
                    <span>{mockProjectData.currentPrice}$</span>
                  </div>
                  <div className="progress-bar-data">
                    <span>Potential End Date</span>
                    <span>{mockProjectData.endPrice}$</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="project-reports-section" ref={projectReportsRef}>
        <span className="project-section-title">Project Reports</span>
        <span className="project-section-description">
          from Development Experience Clearinghouse
        </span>
        <ProjectInfoTable tableData={reportsData} />
      </section>

      <section className="project-reports-section">
        <span className="project-section-title">Solicitation Documents</span>
        <span className="project-section-description">
          only available for select Contracts (not Grants)
        </span>
        <ProjectInfoTable tableData={documentsData} />
      </section>

      <section
        className="project-transaction-section"
        ref={projectTransactionRef}
      >
        <div className="project-transaction-tabs">
          <PButton
            style={{
              ...styles.mySuggestionsOptions(
                transactionTab === "TransactionHistory",
                "yellow"
              ),
              marginRight: 20,
            }}
            onClick={() => handleTransactionTabChange("TransactionHistory")}
          >
            Transaction History
          </PButton>
          <PButton
            style={{
              ...styles.mySuggestionsOptions(
                transactionTab === "SubAwardees",
                "yellow"
              ),
            }}
            onClick={() => handleTransactionTabChange("SubAwardees")}
          >
            Sub Awardees
          </PButton>
          <PButton
            style={{
              ...styles.mySuggestionsOptions(
                transactionTab === "FederalAccount",
                "yellow"
              ),
            }}
            onClick={() => handleTransactionTabChange("FederalAccount")}
          >
            Federal Account Funding
          </PButton>
        </div>
        <ProjectTransactionsTable tableData={transactionsData} />
      </section>

      <section className="project-panels-section" ref={projectPanelsRef}>
        <div className="project-panels-section-column">
          <Panel
            style={{
              ...styles.filters.filterBox,
              marginBottom: !isCollapsedCompilation ? 20 : 0,
            }}
            collapsed={isCollapsedCompilation}
            onToggle={(e) => setIsCollapsedCompilation(e.value)}
            toggleable
            expandIcon="pi pi-chevron-down"
            collapseIcon="pi pi-chevron-up"
            header={
              <PanelHeader
                title="Completion Details"
                isCollapsed={isCollapsedCompilation}
                setIsCollapsed={setIsCollapsedCompilation}
              />
            }
          >
            <div className="panel-item">
              <span className="panel-item-title">Solicitation ID</span>
              <span className="panel-item-value">—</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Solicitation Procedures</span>
              <span className="panel-item-value">
                NP: NEGOTIATED PROPOSAL/QUOTE
              </span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">
                Number of Offers Received
              </span>
              <span className="panel-item-value">—</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Extent Competed</span>
              <span className="panel-item-value">
                A: FULL AND OPEN COMPETITION
              </span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">
                Other Than Full and Open,Competition
              </span>
              <span className="panel-item-value">—</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Set-Aside Type</span>
              <span className="panel-item-value">—</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">
                Commercial Item Acquisition,Procedures
              </span>
              <span className="panel-item-value">
                D: COMMERCIAL ITEM PROCEDURES,USED
              </span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">
                Simplified Procedures for Certain,Commercial Items
              </span>
              <span className="panel-item-value">NO</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Evaluated Preference</span>
              <span className="panel-item-value">NO PREFERENCE USED</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Fed Biz Opps</span>
              <span className="panel-item-value">YES</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">
                Small Business Competitiveness,Demonstration Program
              </span>
              <span className="panel-item-value">FALSE</span>
            </div>
          </Panel>
          <Panel
            style={{
              ...styles.filters.filterBox,
              marginBottom: !isCollapsedExecutive ? 20 : 0,
            }}
            collapsed={isCollapsedExecutive}
            onToggle={(e) => setIsCollapsedExecutive(e.value)}
            toggleable
            expandIcon="pi pi-chevron-down"
            collapseIcon="pi pi-chevron-up"
            header={
              <PanelHeader
                title="Executive Compensation"
                isCollapsed={isCollapsedExecutive}
                setIsCollapsed={setIsCollapsedExecutive}
              />
            }
          >
            <div className="panel-item">
              <span className="panel-item-title">Officer 1</span>
              <span className="panel-item-value">JAMES BUTCHER - $868,926</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Officer 2</span>
              <span className="panel-item-value">ANNA SLOTHER - $466,002</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Officer 3</span>
              <span className="panel-item-value">RHETT GURIAN - $454,307</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Officer 4</span>
              <span className="panel-item-value">
                WILLIAM KELLER - $429,653
              </span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Officer 5</span>
              <span className="panel-item-value">
                JEFFREY WUORINEN - $406,812
              </span>
            </div>
          </Panel>
          <Panel
            style={{
              ...styles.filters.filterBox,
              marginBottom: !isCollapsedAgency ? 20 : 0,
            }}
            collapsed={isCollapsedAgency}
            onToggle={(e) => setIsCollapsedAgency(e.value)}
            toggleable
            expandIcon="pi pi-chevron-down"
            collapseIcon="pi pi-chevron-up"
            header={
              <PanelHeader
                title="Agency Details"
                isCollapsed={isCollapsedAgency}
                setIsCollapsed={setIsCollapsedAgency}
              />
            }
          >
            <div className="panel-item">
              <span className="panel-item-title">Awarding Agency</span>
              <span className="panel-item-value">
                Agency of International Development,(USAID)
              </span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Awarding Sub-Agency</span>
              <span className="panel-item-value">
                Agency for International Development
              </span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Awarding Office</span>
              <span className="panel-item-value">USAID M/OAA</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Funding Agency</span>
              <span className="panel-item-value">
                Agency for International Development,(USAID)
              </span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Funding Sub-Agency</span>
              <span className="panel-item-value">
                Agency for International Development
              </span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Funding Office</span>
              <span className="panel-item-value">USAID/MALI</span>
            </div>
          </Panel>
        </div>
        <div className="project-panels-section-column">
          <Panel
            style={{
              ...styles.filters.filterBox,
              marginBottom: !isCollapsedAdditional ? 20 : 0,
            }}
            collapsed={isCollapsedAdditional}
            onToggle={(e) => setIsCollapsedAdditional(e.value)}
            toggleable
            expandIcon="pi pi-chevron-down"
            collapseIcon="pi pi-chevron-up"
            header={
              <PanelHeader
                title="Additional Details"
                isCollapsed={isCollapsedAdditional}
                setIsCollapsed={setIsCollapsedAdditional}
              />
            }
          >
            <div className="panel-item">
              <span className="panel-item-title">Contract Type</span>
              <span className="panel-item-value">DELIVERY ORDER</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">National Interest Action</span>
              <span className="panel-item-value">NONE</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Cost or Pricing Data</span>
              <span className="panel-item-value">YES</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">
                Domestic or Foreign Entity
              </span>
              <span className="panel-item-value">U.S. OWNED BUSINESS</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">
                Fair Opportunity Limited Sources
              </span>
              <span className="panel-item-value">—</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Foreign Funding</span>
              <span className="panel-item-value">NOT APPLICABLE</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">
                Interagency Contracting,Authority
              </span>
              <span className="panel-item-value">NOT APPLICABLE</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Major Problem</span>
              <span className="panel-item-value">—</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Subcontracting Plan</span>
              <span className="panel-item-value">—</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Multi Year Contract</span>
              <span className="panel-item-value">Multi Year Contract</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Consolidated Contract</span>
              <span className="panel-item-value">NO</span>
            </div>
          </Panel>
          <Panel
            style={{
              ...styles.filters.filterBox,
              marginBottom: !isCollapsedAwardKey ? 20 : 0,
            }}
            collapsed={isCollapsedAwardKey}
            onToggle={(e) => setIsCollapsedAwardKey(e.value)}
            toggleable
            expandIcon="pi pi-chevron-down"
            collapseIcon="pi pi-chevron-up"
            header={
              <PanelHeader
                title="Unique Award Key"
                isCollapsed={isCollapsedAwardKey}
                setIsCollapsed={setIsCollapsedAwardKey}
              />
            }
          >
            <div className="panel-item">
              <span className="panel-item-title">Unique Award Key</span>
              <span className="panel-item-value">DELIVERY ORDER</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Award or IDV Flag</span>
              <span className="panel-item-value">NONE</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">
                Procurement Instrument,Identifier (PIID){" "}
              </span>
              <span className="panel-item-value">YES</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">
                Submitting Agency Identifier,Code
              </span>
              <span className="panel-item-value">U.S. OWNED BUSINESS</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">
                Parent Award ID (Parent PIID)
              </span>
              <span className="panel-item-value">-</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">
                Parent Agency Identifier Code
              </span>
              <span className="panel-item-value">NOT APPLICABLE</span>
            </div>
          </Panel>
          <Panel
            style={{
              ...styles.filters.filterBox,
              marginBottom: !isCollapsedParentAward ? 20 : 0,
            }}
            collapsed={isCollapsedParentAward}
            onToggle={(e) => setIsCollapsedParentAward(e.value)}
            toggleable
            expandIcon="pi pi-chevron-down"
            collapseIcon="pi pi-chevron-up"
            header={
              <PanelHeader
                title="Parent Award Details"
                isCollapsed={isCollapsedParentAward}
                setIsCollapsed={setIsCollapsedParentAward}
              />
            }
          >
            <div className="panel-item">
              <span className="panel-item-title">Parent Award Unique Key</span>
              <span className="panel-item-value">
                CONT_IDV_AIDOAAI1500004_7200
              </span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">
                Parent Award ID (Parent PIID)
              </span>
              <span className="panel-item-value">AIDOAAI1500004</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Parent IDV Type</span>
              <span className="panel-item-value">IDC</span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">Parent IDV Agency Name</span>
              <span className="panel-item-value">
                Agency for International Development
              </span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">
                Parent IDV Sub-Agency Name
              </span>
              <span className="panel-item-value">
                Agency for International Development
              </span>
            </div>
            <div className="panel-item">
              <span className="panel-item-title">
                Mulitiple Or Single Parent Award IDV
              </span>
              <span className="panel-item-value">SINGLE AWARD</span>
            </div>
          </Panel>
        </div>
      </section>

      <section className="project-footer-section">
        <span>
          This report and data is based upon public information and data
          obtained from various public sources, the accuracy of which cannot be
          guaranteed. There may be inherent contradictions among various public
          data sets. AidKonekt makes no representation or warranty as to the
          accuracy of information provided. This is only intended as an
          information resource and not an advisory document. As with any
          information resource, it is your responsibility to determine the
          quality and credibility of information obtained, as well as the
          reasonableness of relying on such information for a particular use.
        </span>
      </section>
    </div>
  );
};

export default ProjectDetails;
