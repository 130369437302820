import React, { useState } from "react";
import "./IEEArchives.css";
import { useStyles } from "../../hooks/useStyles";
import { createRequestWithAuthHeaders, getFullUrl } from "../../configs/axios-export.custom";
import UserContext, { doesUserHaveRole, ServerOrgRoles } from "../../services/UserContext";
import GrowlContext from "../../services/growlContext";
import { DateFormat, formatDate } from "../../utils/conversion/date-converters";
import { getData } from "../LCPArchives/getresources";
import axios, { AxiosRequestConfig } from "axios";

interface IEEArchivesPlans {
  date: string;
  link: string;
  id: number;
}

interface IEEArchiveRecords {
  country: string;
  plans: IEEArchivesPlans[];
}

const IEEArchives: React.FC = (): JSX.Element => {
  const user = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);

  const styles = useStyles();
  const [archives, setArchives] = useState<Array<IEEArchiveRecords>>([]);
  const [searchValue, setSearchValue] = useState("");

  React.useEffect(() => {
    getData(growl.current, user, "iee").then((r) => {
      if (!r) return;

      const data = Object.keys(r)
        .sort((a, b) => (a > b ? 1 : -1))
        .map((k) => {
          const collection = r[k];
          const record: IEEArchiveRecords = {
            country: k,
            plans: collection
              .sort((a, b) => {
                if (!a && !b) return 0;
                else if (a && !b) return -1;
                else if (!a && b) return 1;
                else return a > b ? 1 : -1;
              })
              .map((x) => ({
                id: x.id,
                date: formatDate(x.date, DateFormat.dd_MMMM_yyyy) || "",
                link: x.link,
              })),
          };
          return record;
        });
      setArchives(data);
    });
  }, []);

  const removePlan = async (
    archive: IEEArchiveRecords,
    item: IEEArchivesPlans
  ) => {
    let request: AxiosRequestConfig = {
      ...createRequestWithAuthHeaders(user),
      data: {
        id: item.id,
      },
    };

    await axios
      .delete(
        getFullUrl("/api/admin/resource", { useDedicatedEnvironment: true }),
        request
      )
      .then((r) => {
        const newArchives = [...archives];
        const match = newArchives.find((x) => x.country === archive.country);
        if (!match) return;
        match.plans = [...match.plans.filter((r) => r.id !== item.id)];
        setArchives(newArchives);
      });
  };

  const isAdmin = doesUserHaveRole(user, ServerOrgRoles.admin)
  || doesUserHaveRole(user, ServerOrgRoles.partialAdmin);

  return (
    <div className="resources-screen">
      <section className="header">
        <div className="header-title">
          <img
            src={styles.aidkonektIcon}
            style={styles.icon}
            alt="Aidkonekt"
            className="formIcon"
          />
          <span>IEE Archives</span>
        </div>
        <span className="header-subtitle">
          USAID Environmental Compliance Documents
        </span>
        <span className="header-description"></span>
      </section>

      <section className="iee-list-section">
        <div className="iee-search-block">
          <input
            type="text"
            placeholder="Country"
            onChange={(e) => setSearchValue(e.currentTarget.value)}
          />
          <button type="button" className="card-btn aid-blu-btn">
            Search
          </button>
        </div>
        <div className="country-list">
          {archives.map(
            (item, index) =>
              item.country.toLowerCase().indexOf(searchValue.toLowerCase()) !==
                -1 && (
                <React.Fragment key={item.country}>
                  <h3 className="list-title">{item.country}</h3>
                  <div className="list-content">
                    <ul>
                      {item.plans
                        .sort((a, b) => {
                          const aDate = new Date(a.date);
                          const bDate = new Date(b.date);
                          return aDate > bDate ? 1 : -1;
                        })
                        .map((plan, index) =>
                          isAdmin ? (
                            <li
                              key={plan.id}
                              className="list-item"
                              onClick={() => window.open(plan.link, "_blank")}
                            >
                              <button
                                type="button"
                                className="removeIcon"
                                onClick={() => removePlan(item, plan)}
                              >
                                X
                              </button>
                              {plan.date}
                              {index !== item.plans.length - 1 ? "," : ""}
                            </li>
                          ) : (
                            <li
                              key={plan.id}
                              className="list-item"
                              onClick={() => window.open(plan.link, "_blank")}
                            >
                              {plan.date}
                              {index !== item.plans.length - 1 ? "," : ""}
                            </li>
                          )
                        )}
                    </ul>
                  </div>
                </React.Fragment>
              )
          )}
        </div>
      </section>
    </div>
  );
};

export default IEEArchives;
