import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ThemeContext from "../../../configs/theme";
import { DateFormat } from "../../../utils/conversion/date-converters";
import { Button } from "primereact/button";
import { EventType, sharedStyles } from "./EventsCalendar";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import ShowMoreTextComp from "../../ShowMoreTextComp";
import { format } from "date-fns";
import CalendarPopup from "./CalendarPopup";
import ReactDOM from "react-dom";
import AddEditEventModal from "./AddEditEventModal";

const useStyles = () => {
  const theme = useContext(ThemeContext);

  return {
    header: {
      font: theme.v2Fonts.smallHeader,
      whiteSpace: "nowrap" as "nowrap",
    },
  };
};

export const useHeaderRow = () => {
  const styles = useStyles();

  return (
    <tr style={styles.header}>
      <th style={{ width: "20%" }}>Date</th>
      <th style={{ width: "60%" }}>Event</th>
      <th style={{ width: "20%" }}>Organization</th>
    </tr>
  );
};

interface CalendarRowProps {
  event: EventType;
  canEditEvents: boolean;
  editEvent: (eventId: number, editedEvent: EventType) => void;
  deleteEvent: (eventId: number) => void;
  setEventToShow: (data: any, show: boolean, offset: any) => void;
  eventOffset: any;
  loadDataAsync: any;
}
const CalendarRow: FunctionComponent<CalendarRowProps> = ({
  event,
  canEditEvents,
  editEvent,
  deleteEvent,
  setEventToShow,
  eventOffset,
  loadDataAsync,
}) => {
  const [editedEvent, setEditedEvent] = useState<EventType>(event);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const toggleEditing = () => {
    setIsEditing(!isEditing);
    setEditedEvent(event);
  };
  const eventNameRef = useRef(null);

  const handleSave = () => {
    setIsEditing(false);
    editEvent(event.id, editedEvent);
  };
  const isNewEvent = (dateStr: string) => {
    var date = new Date(dateStr);
    var nowDate = new Date();
    nowDate.addDays(-7);
    return date >= nowDate;
  };
  const [offset, setOffset] = useState(-1);
  const prepareEventToShow = (event: any) => {
    var element = ReactDOM.findDOMNode(eventNameRef.current) as Element;
    if (element) {
      const elementRect = element.getBoundingClientRect();
      setOffset(elementRect.top);
      setEventToShow(event, true, elementRect.top);
    }
  };

  useEffect(() => {
    setEditedEvent(event);
  }, [event]);

  return (
    <tr>
      {/*{isEditing ? (*/}
      {/*  <>*/}
      {/*    <td>*/}
      {/*      <Calendar*/}
      {/*        appendTo={document.body}*/}
      {/*        placeholder="mm/dd/yyyy"*/}
      {/*        dateFormat="mm/dd/yy"*/}
      {/*        value={new Date(event.date)}*/}
      {/*        onChange={(e) =>*/}
      {/*          setEditedEvent({*/}
      {/*            ...editedEvent,*/}
      {/*            dateAsString: (e.value as Date).toISOString(),*/}
      {/*          })*/}
      {/*        }*/}
      {/*      />*/}
      {/*    </td>*/}
      {/*    <td>*/}
      {/*      <InputText*/}
      {/*        placeholder="Event Name"*/}
      {/*        style={{ width: "49%", marginRight: "5px", marginBottom: "5px" }}*/}
      {/*        value={editedEvent.name}*/}
      {/*        onChange={(e) =>*/}
      {/*          setEditedEvent({*/}
      {/*            ...editedEvent,*/}
      {/*            name: e.currentTarget.value,*/}
      {/*          })*/}
      {/*        }*/}
      {/*      />*/}

      {/*      <InputText*/}
      {/*        placeholder="Location"*/}
      {/*        style={{ width: "48%", marginRight: "5px", marginBottom: "5px" }}*/}
      {/*        value={editedEvent.location}*/}
      {/*        onChange={(e) =>*/}
      {/*          setEditedEvent({*/}
      {/*            ...editedEvent,*/}
      {/*            location: e.currentTarget.value,*/}
      {/*          })*/}
      {/*        }*/}
      {/*      />*/}
      {/*      <InputText*/}
      {/*        placeholder="RSVP Link"*/}
      {/*        style={sharedStyles.inlineInput}*/}
      {/*        value={editedEvent.url}*/}
      {/*        onChange={(e) =>*/}
      {/*          setEditedEvent({*/}
      {/*            ...editedEvent,*/}
      {/*            url: e.currentTarget.value,*/}
      {/*          })*/}
      {/*        }*/}
      {/*      />*/}
      {/*    </td>*/}
      {/*    <td>*/}
      {/*      <InputText*/}
      {/*        placeholder="Organization"*/}
      {/*        style={sharedStyles.inlineInput}*/}
      {/*        value={editedEvent.organization}*/}
      {/*        onChange={(e) =>*/}
      {/*          setEditedEvent({*/}
      {/*            ...editedEvent,*/}
      {/*            organization: e.currentTarget.value,*/}
      {/*          })*/}
      {/*        }*/}
      {/*      />*/}
      {/*    </td>*/}
      {/*  </>*/}
      {/*) : (*/}
      <>
        <td style={sharedStyles.cell} className="event-date-view">
          {format(new Date(event.date), "MMM dd yyyy")}
        </td>
        <td style={sharedStyles.cell}>
          <span style={{ display: "flex" }}>
            <a
              id={editedEvent.id + "lnk"}
              ref={eventNameRef}
              href={editedEvent.url}
              className="event-location-view"
              target="_blank"
              onMouseOver={() => prepareEventToShow(editedEvent)}
              onMouseLeave={() => setEventToShow(null, false, offset)}
            >
              {" "}
              <ShowMoreTextComp text={editedEvent.name} textLength={35} />{" "}
            </a>

            {/* <CalendarPopup
                eventName={editedEvent.name}
                location={editedEvent.location}
                organization={editedEvent.organization}
                url={editedEvent.url}
                styles={{ width: "85%" }}
                className="event-location-view"
              ></CalendarPopup> */}
            {/* <a
                style={{ width: "85%" }}
                href={editedEvent.url}
                className="event-location-view"
                target="_blank"
              >
                {" "}
                <ShowMoreTextComp
                  text={editedEvent.name}
                  textLength={40}
                />{" "}
              </a> */}
            {isNewEvent(editedEvent.createdOn) ? (
              <span style={{ marginLeft: "6px" }} className="new-label">
                New
              </span>
            ) : (
              ""
            )}
          </span>
          <span style={{ marginTop: "7px" }} className="event-loc">
            <svg
              style={{ marginRight: "2px" }}
              width="11"
              height="13"
              viewBox="0 0 11 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.5 0.25C4.17392 0.25 2.90215 0.776784 1.96447 1.71447C1.02678 2.65215 0.5 3.92392 0.5 5.25C0.5 8.625 4.90625 12.4375 5.09375 12.6C5.20696 12.6968 5.35103 12.75 5.5 12.75C5.64897 12.75 5.79304 12.6968 5.90625 12.6C6.125 12.4375 10.5 8.625 10.5 5.25C10.5 3.92392 9.97322 2.65215 9.03553 1.71447C8.09785 0.776784 6.82608 0.25 5.5 0.25ZM5.5 11.2812C4.16875 10.0312 1.75 7.3375 1.75 5.25C1.75 4.25544 2.14509 3.30161 2.84835 2.59835C3.55161 1.89509 4.50544 1.5 5.5 1.5C6.49456 1.5 7.44839 1.89509 8.15165 2.59835C8.85491 3.30161 9.25 4.25544 9.25 5.25C9.25 7.3375 6.83125 10.0375 5.5 11.2812ZM5.5 2.75C5.00555 2.75 4.5222 2.89662 4.11107 3.17133C3.69995 3.44603 3.37952 3.83648 3.1903 4.29329C3.00108 4.75011 2.95157 5.25277 3.04804 5.73773C3.1445 6.22268 3.3826 6.66814 3.73223 7.01777C4.08186 7.3674 4.52732 7.6055 5.01227 7.70196C5.49723 7.79843 5.99989 7.74892 6.45671 7.5597C6.91352 7.37048 7.30397 7.05005 7.57867 6.63893C7.85338 6.2278 8 5.74445 8 5.25C8 4.58696 7.73661 3.95107 7.26777 3.48223C6.79893 3.01339 6.16304 2.75 5.5 2.75ZM5.5 6.5C5.25277 6.5 5.0111 6.42669 4.80554 6.28934C4.59998 6.15199 4.43976 5.95676 4.34515 5.72835C4.25054 5.49995 4.22579 5.24861 4.27402 5.00614C4.32225 4.76366 4.4413 4.54093 4.61612 4.36612C4.79093 4.1913 5.01366 4.07225 5.25614 4.02402C5.49861 3.97579 5.74995 4.00054 5.97835 4.09515C6.20676 4.18976 6.40199 4.34998 6.53934 4.55554C6.67669 4.7611 6.75 5.00277 6.75 5.25C6.75 5.58152 6.6183 5.89946 6.38388 6.13388C6.14946 6.3683 5.83152 6.5 5.5 6.5Z"
                fill="#666666"
              />
            </svg>
            <ShowMoreTextComp text={editedEvent.location} textLength={40} />{" "}
          </span>
        </td>
        <td style={sharedStyles.cell} className="event-location-view">
          <ShowMoreTextComp text={editedEvent.organization} textLength={25} />{" "}
        </td>
      </>
      {/*)}*/}
      {canEditEvents && (
        <td style={{ ...sharedStyles.cell, ...sharedStyles.controls }}>
          {isEditing ? (
            <>
              <Button
                icon={"pi pi-save"}
                className="p-button-rounded"
                style={sharedStyles.iconButton}
                tooltip={"Save Event"}
                tooltipOptions={{ position: "top" }}
                onClick={() => handleSave()}
              />
              <Button
                icon={"pi pi-times"}
                className="p-button-rounded"
                style={sharedStyles.iconButton}
                tooltip={"Cancel"}
                tooltipOptions={{ position: "top" }}
                onClick={() => toggleEditing()}
              />
            </>
          ) : (
            <>
              {/*<Button*/}
              {/*  icon={"pi pi-pencil"}*/}
              {/*  className="p-button-rounded"*/}
              {/*  style={sharedStyles.iconButton}*/}
              {/*  tooltip={"Edit Event"}*/}
              {/*  tooltipOptions={{ position: "top" }}*/}
              {/*  onClick={() => toggleEditing()}*/}
              {/*/>*/}
              <AddEditEventModal
                canEditEvents={canEditEvents}
                loadDataAsync={loadDataAsync}
                edit={{
                  event,
                }}
              />
              <Button
                icon="pi pi-trash"
                className="p-button-rounded p-button-danger"
                style={sharedStyles.iconButton}
                tooltip="Delete Event"
                tooltipOptions={{ position: "top" }}
                onClick={() => deleteEvent(event.id)}
              />
            </>
          )}
        </td>
      )}
    </tr>
  );
};

export default CalendarRow;
