import React, { FunctionComponent, useState } from "react";
import { css } from "glamor";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { hours, Weekday } from "../../../../utils/constants/time-constants";
import { NotificationRecipient } from "../../../../utils/constants/notification-recipients";
import { useSharedStyles } from "../../useSharedStyles";
import { Button } from "primereact/button";
import { AccountInformationContext } from "../AccountInformation/reducer";
import { EmailNotificationsContext } from "./reducer";
import {
  saveDailyEmailEnabled,
  saveDailyEmailRecipient,
  saveDailyEmailTime,
  saveForecastAlertEnabled,
  saveForecastAlertRecipient,
  saveWeeklyEmailDay,
  saveWeeklyEmailEnabled,
  saveWeeklyEmailRecipient,
  saveWeeklyEmailTime,
  setDailyEmailEnabled,
  setDailyEmailRecipient,
  setDailyEmailTime,
  setForecastAlertEnabled,
  setForecastAlertRecipient,
  setWeeklyEmailDay,
  setWeeklyEmailEnabled,
  setWeeklyEmailRecipient,
  setWeeklyEmailTime,
} from "./actions";
import { NotificationTypeEnum } from "../../../../services/enums";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../../../configs/axios-export.custom";
import { tryCatchServerError } from "../../../../services/functions";
import GrowlContext from "../../../../services/growlContext";
import UserContext from "../../../../services/UserContext";
import ThemeContext from "../../../../configs/theme";
import IconTooltip from "../../../../components/IconTooltip";
import axios from "axios";
import Chip from "../../../Chip";

const useStyles = (): { [key: string]: React.CSSProperties } => {
  const theme = React.useContext(ThemeContext);

  return {
    checkboxLabel: {
      marginLeft: "8px",
      font: theme.v2Fonts.smallHeader,
    },
    dropdown: {
      width: "100%",
    },
    error: {
      display: "block",
      color: "red",
    },
    timePopup: {
      position: "absolute",
      top: -8,
      left: 55,
      padding: "0px 5px",
      fontSize: "12px",
      whiteSpace: "nowrap",
      backgroundColor: "rgb(203, 227, 242)",
      borderRadius: 4,
    },
    save: {
      backgroundColor: "#ecac37",
      borderRadius: "26.5px",
      height: "47px",
      minWidth: "177px",
      textAlign: "center",
      fontSize: "1em",
      justifyContent: "center",
      fontWeight: 600,
    },
  };
};

const EmailNotifications: FunctionComponent = () => {
  const { accountInformationState } = React.useContext(
    AccountInformationContext,
  );
  const growl = React.useContext(GrowlContext);
  const userContext = React.useContext(UserContext);
  const { emailNotificationsState, emailNotificationsDispatch } =
    React.useContext(EmailNotificationsContext);
  const styles = useStyles();
  const sharedStyles = useSharedStyles();
  const theme = React.useContext(ThemeContext);
  const [companyEmails, setCompanyEmails] = React.useState<
    { emailAddress: string }[]
  >([]);
  const [showTimePopUp, setShowTimePopUp] = useState(false);
  const [showTimePopUpWeekly, setShowTimePopUpWeekly] = useState(false);
  const request = createAuthenticatedRequest(userContext);

  React.useEffect(() => {
    axios
      .get(
        getFullUrl("/api/user/profile", { useDedicatedEnvironment: true }),
        request,
      )
      .then(({ data }) => {
        setCompanyEmails(data.companyEmails);
      });
  }, []);

  const weeklyEmailDayShouldBeSelected =
    emailNotificationsState.weekly.enabled.currentValue &&
    emailNotificationsState.weekly.weekday.currentValue === undefined;
  const weeklyEmailTimeShouldBeSelected =
    emailNotificationsState.weekly.enabled.currentValue &&
    emailNotificationsState.weekly.time.currentValue === undefined;
  const weeklyEmailRecipientShouldBeSelected =
    emailNotificationsState.weekly.enabled.currentValue &&
    emailNotificationsState.weekly.recipient.currentValue === undefined;

  const dailyEmailTimeShouldBeSelected =
    emailNotificationsState.daily.enabled.currentValue &&
    emailNotificationsState.daily.time.currentValue === undefined;
  const dailyEmailRecipientShouldBeSelected =
    emailNotificationsState.daily.enabled.currentValue &&
    emailNotificationsState.daily.recipient.currentValue === undefined;

  const businessForecastLiveUpdateRecipientShouldBeSelected =
    emailNotificationsState.forecastAlert.enabled.currentValue &&
    emailNotificationsState.forecastAlert.recipient.currentValue === undefined;

  const notificationSettingsHaveChanged =
    emailNotificationsState.weekly.enabled.currentValue !==
      emailNotificationsState.weekly.enabled.serverValue ||
    emailNotificationsState.weekly.weekday.currentValue !==
      emailNotificationsState.weekly.weekday.serverValue ||
    emailNotificationsState.weekly.time.currentValue !==
      emailNotificationsState.weekly.time.serverValue ||
    emailNotificationsState.weekly.recipient.currentValue !==
      emailNotificationsState.weekly.recipient.serverValue ||
    emailNotificationsState.daily.enabled.currentValue !==
      emailNotificationsState.daily.enabled.serverValue ||
    emailNotificationsState.daily.time.currentValue !==
      emailNotificationsState.daily.time.serverValue ||
    emailNotificationsState.daily.recipient.currentValue !==
      emailNotificationsState.daily.recipient.serverValue ||
    emailNotificationsState.forecastAlert.enabled.currentValue !==
      emailNotificationsState.forecastAlert.enabled.serverValue ||
    emailNotificationsState.forecastAlert.recipient.currentValue !==
      emailNotificationsState.forecastAlert.recipient.serverValue;

  const formIsProperlyFilledOut =
    notificationSettingsHaveChanged &&
    !(
      weeklyEmailDayShouldBeSelected ||
      weeklyEmailTimeShouldBeSelected ||
      weeklyEmailRecipientShouldBeSelected ||
      dailyEmailTimeShouldBeSelected ||
      dailyEmailRecipientShouldBeSelected ||
      businessForecastLiveUpdateRecipientShouldBeSelected
    );

  const saveNotificationSettings = () => {
    const updateState = () => {
      const dailyDto = {
        notificationType: NotificationTypeEnum.Daily,
        notificationDay: null,
        notificationTime: emailNotificationsState.daily.time.currentValue,
        recipient: emailNotificationsState.daily.recipient.currentValue,
      };
      const weeklyDto = {
        notificationType: NotificationTypeEnum.Weekly,
        notificationDay: emailNotificationsState.weekly.weekday.currentValue,
        notificationTime: emailNotificationsState.weekly.time.currentValue,
        recipient: emailNotificationsState.weekly.recipient.currentValue,
      };
      const bfUpdate = {
        notificationType: NotificationTypeEnum.BusinessForecastLiveUpdate,
        recipient: emailNotificationsState.forecastAlert.recipient.currentValue,
      };
      const dto = [
        emailNotificationsState.daily.enabled.currentValue ? dailyDto : null,
        emailNotificationsState.weekly.enabled.currentValue ? weeklyDto : null,
        emailNotificationsState.forecastAlert.enabled.currentValue
          ? bfUpdate
          : null,
      ].filter((r) => r);

      axios
        .post(
          getFullUrl("/api/user/notification", {
            useDedicatedEnvironment: true,
          }),
          [...dto],
          createRequestWithAuthHeaders(userContext),
        )
        .then(() => {
          // After successful save, we synchronize the server state to match the UI state
          emailNotificationsDispatch(saveWeeklyEmailEnabled());
          emailNotificationsDispatch(saveWeeklyEmailDay());
          emailNotificationsDispatch(saveWeeklyEmailTime());
          emailNotificationsDispatch(saveWeeklyEmailRecipient());
          emailNotificationsDispatch(saveDailyEmailEnabled());
          emailNotificationsDispatch(saveDailyEmailTime());
          emailNotificationsDispatch(saveDailyEmailRecipient());
          emailNotificationsDispatch(saveForecastAlertEnabled());
          emailNotificationsDispatch(saveForecastAlertRecipient());
          growl.current.show({
            severity: "success",
            summary: "Saved notification settings",
          });
        })
        .catch(
          tryCatchServerError(
            () =>
              growl.current.show({
                severity: "error",
                summary: "Error saving notification settings",
              }),
            (e) => console.error(e),
          ),
        );
    };

    updateState();
  };

  const newForecastAddAlert = (
    <>
      <span>New Forecast Add Alert </span>
      <span style={{ font: theme.v2Fonts.context }}>
        <IconTooltip
          tooltipText={
            "Receive an email whenever a new opportunity is added to the USAID Business Forecast"
          }
        />
        &nbsp;
      </span>
    </>
  );

  return (
    <div className="p-grid">
      {/*<div className="p-col-12">*/}
      {/*  <Checkbox*/}
      {/*    inputId="businessForecastLiveUpdate"*/}
      {/*    disabled={!sharedStyles.isPro}*/}
      {/*    checked={emailNotificationsState.forecastAlert.enabled.currentValue}*/}
      {/*    onChange={(e) =>*/}
      {/*      emailNotificationsDispatch(setForecastAlertEnabled(e.checked))*/}
      {/*    }*/}
      {/*  />*/}
      {/*  <label*/}
      {/*    style={{*/}
      {/*      ...styles.checkboxLabel,*/}
      {/*      ...sharedStyles.potentiallyBlurred(!sharedStyles.isPro),*/}
      {/*    }}*/}
      {/*    htmlFor="businessForecastLiveUpdate"*/}
      {/*  >*/}
      {/*    {newForecastAddAlert}*/}
      {/*  </label>*/}
      {/*</div>*/}

      {/*<div className="p-col-6">*/}
      {/*  <label*/}
      {/*    style={{*/}
      {/*      ...sharedStyles.floatingLabel,*/}
      {/*      ...sharedStyles.potentiallyBlurred(!sharedStyles.isPro),*/}
      {/*    }}*/}
      {/*    htmlFor="bfRecipients"*/}
      {/*  >*/}
      {/*    Recipients*/}
      {/*  </label>*/}
      {/*  <Dropdown*/}
      {/*    inputId="bfRecipients"*/}
      {/*    placeholder="Select recipient(s)"*/}
      {/*    className={sharedStyles.dropdownBlurClassName(!sharedStyles.isPro)}*/}
      {/*    disabled={!sharedStyles.isPro}*/}
      {/*    options={Object.values(NotificationRecipient).filter((value) => {*/}
      {/*      if (companyEmails.length === 0) {*/}
      {/*        return value === "Only Me"*/}
      {/*      }*/}
      {/*      return true*/}
      {/*    })}*/}
      {/*    value={emailNotificationsState.forecastAlert.recipient.currentValue}*/}
      {/*    onChange={(e) =>*/}
      {/*      emailNotificationsDispatch(setForecastAlertRecipient(e.value))*/}
      {/*    }*/}
      {/*    style={{ ...styles.dropdown }}*/}
      {/*  />*/}
      {/*  {businessForecastLiveUpdateRecipientShouldBeSelected && (*/}
      {/*    <small {...css(styles.error)}>Please select the recipient(s).</small>*/}
      {/*  )}*/}
      {/*</div>*/}
      {/*{(emailNotificationsState.forecastAlert.recipient.currentValue === NotificationRecipient.ALL_TEAM*/}
      {/*    && companyEmails.length > 0) &&*/}
      {/*  <div className="email-notifications-company-users">*/}
      {/*    {companyEmails.map((user, index) => (*/}
      {/*      <Chip*/}
      {/*        key={index}*/}
      {/*        label={user.emailAddress}*/}
      {/*      />*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*}*/}
      <div className="p-col-12">
        <Checkbox
          inputId="weeklyEmail"
          checked={emailNotificationsState.weekly.enabled.currentValue}
          disabled={!sharedStyles.isPlus}
          onChange={(e) =>
            emailNotificationsDispatch(setWeeklyEmailEnabled(e.checked))
          }
        />
        <label
          style={{
            ...styles.checkboxLabel,
            ...sharedStyles.potentiallyBlurred(!sharedStyles.isPlus),
          }}
          htmlFor="weeklyEmail"
        >
          Weekly Email
        </label>
      </div>

      <div className="p-col-6">
        <label
          style={{
            ...sharedStyles.floatingLabel,
            ...sharedStyles.potentiallyBlurred(!sharedStyles.isPlus),
          }}
          htmlFor="weekday"
        >
          Weekday
        </label>
        <Dropdown
          placeholder="Select a day"
          className={sharedStyles.dropdownBlurClassName(!sharedStyles.isPlus)}
          disabled={!sharedStyles.isPlus}
          options={Object.values(Weekday)}
          value={emailNotificationsState.weekly.weekday.currentValue}
          onChange={(e) =>
            emailNotificationsDispatch(setWeeklyEmailDay(e.value))
          }
          style={{ ...styles.dropdown }}
        />
        {weeklyEmailDayShouldBeSelected && (
          <small {...css(styles.error)}>Please select a day.</small>
        )}
      </div>
      <div className="p-col-6" style={{ position: "relative" }}>
        {showTimePopUpWeekly && (
          <div style={{ ...styles.timePopup }}>
            {accountInformationState.timeZone.serverValue}
          </div>
        )}
        <label
          style={{
            ...sharedStyles.floatingLabel,
            ...sharedStyles.potentiallyBlurred(!sharedStyles.isPlus),
          }}
          htmlFor="weekdayTime"
          onMouseEnter={() => setShowTimePopUpWeekly(true)}
          onMouseLeave={() => setShowTimePopUpWeekly(false)}
        >
          Time ({accountInformationState.timeZone.serverValue})
        </label>
        <Dropdown
          placeholder="Select a time"
          className={sharedStyles.dropdownBlurClassName(!sharedStyles.isPlus)}
          disabled={!sharedStyles.isPlus}
          options={Object.values(hours)}
          value={emailNotificationsState.weekly.time.currentValue}
          onChange={(e) =>
            emailNotificationsDispatch(setWeeklyEmailTime(e.value))
          }
          style={{ ...styles.dropdown }}
        />
        {weeklyEmailTimeShouldBeSelected && (
          <small {...css(styles.error)}>Please select a time.</small>
        )}
      </div>
      <div className="p-col-6">
        <label
          style={{
            ...sharedStyles.floatingLabel,
            ...sharedStyles.potentiallyBlurred(!sharedStyles.isPlus),
          }}
          htmlFor="weeklyRecipients"
        >
          Recipients
        </label>
        <Dropdown
          inputId="weeklyRecipients"
          className={sharedStyles.dropdownBlurClassName(!sharedStyles.isPlus)}
          disabled={!sharedStyles.isPlus}
          placeholder="Select recipient(s)"
          options={Object.values(NotificationRecipient).filter((value) => {
            if (companyEmails.length === 0) {
              return value === "Only Me";
            }
            return true;
          })}
          value={emailNotificationsState.weekly.recipient.currentValue}
          onChange={(e) =>
            emailNotificationsDispatch(setWeeklyEmailRecipient(e.value))
          }
          style={{ ...styles.dropdown }}
        />
        {weeklyEmailRecipientShouldBeSelected && (
          <small {...css(styles.error)}>Please select the recipient(s).</small>
        )}
      </div>
      {emailNotificationsState.weekly.recipient.currentValue ===
        NotificationRecipient.ALL_TEAM &&
        companyEmails.length > 0 && (
          <div className="email-notifications-company-users">
            {companyEmails.map((user, index) => (
              <Chip key={index} label={user.emailAddress} />
            ))}
          </div>
        )}
      <div className="p-col-12">
        <Checkbox
          inputId="dailyEmail"
          disabled={!sharedStyles.isPro}
          checked={emailNotificationsState.daily.enabled.currentValue}
          onChange={(e) =>
            emailNotificationsDispatch(setDailyEmailEnabled(e.checked))
          }
        />
        <label
          style={{
            ...styles.checkboxLabel,
            ...sharedStyles.potentiallyBlurred(!sharedStyles.isPro),
          }}
          htmlFor="dailyEmail"
        >
          Daily Email
        </label>
      </div>
      <div className="p-col-6" style={{ position: "relative" }}>
        {showTimePopUp && (
          <div style={{ ...styles.timePopup }}>
            {accountInformationState.timeZone.serverValue}
          </div>
        )}
        <label
          style={{
            ...sharedStyles.floatingLabel,
            ...sharedStyles.potentiallyBlurred(!sharedStyles.isPro),
          }}
          htmlFor="dailyTime"
          onMouseEnter={() => setShowTimePopUp(true)}
          onMouseLeave={() => setShowTimePopUp(false)}
        >
          Time ({accountInformationState.timeZone.serverValue})
        </label>
        <Dropdown
          placeholder="Select a time"
          className={sharedStyles.dropdownBlurClassName(!sharedStyles.isPro)}
          disabled={!sharedStyles.isPro}
          options={Object.values(hours)}
          value={emailNotificationsState.daily.time.currentValue}
          onChange={(e) =>
            emailNotificationsDispatch(setDailyEmailTime(e.value))
          }
          style={{ ...styles.dropdown }}
        />
        {dailyEmailTimeShouldBeSelected && (
          <small {...css(styles.error)}>Please select a time.</small>
        )}
      </div>
      <div className="p-col-6">
        <label
          style={{
            ...sharedStyles.floatingLabel,
            ...sharedStyles.potentiallyBlurred(!sharedStyles.isPro),
          }}
          htmlFor="dailyRecipients"
        >
          Recipients
        </label>
        <Dropdown
          inputId="dailyRecipients"
          className={sharedStyles.dropdownBlurClassName(!sharedStyles.isPro)}
          disabled={!sharedStyles.isPro}
          placeholder="Select recipient(s)"
          options={Object.values(NotificationRecipient).filter((value) => {
            if (companyEmails.length === 0) {
              return value === "Only Me";
            }
            return true;
          })}
          value={emailNotificationsState.daily.recipient.currentValue}
          onChange={(e) =>
            emailNotificationsDispatch(setDailyEmailRecipient(e.value))
          }
          style={{ ...styles.dropdown }}
        />
        {dailyEmailRecipientShouldBeSelected && (
          <small {...css(styles.error)}>Please select the recipient(s).</small>
        )}
      </div>
      {emailNotificationsState.daily.recipient.currentValue ===
        NotificationRecipient.ALL_TEAM &&
        companyEmails.length > 0 && (
          <div className="email-notifications-company-users">
            {companyEmails.map((user, index) => (
              <Chip key={index} label={user.emailAddress} />
            ))}
          </div>
        )}
      <div className="p-col-12" style={{ textAlign: "center" }}>
        <Button
          label="Save Notification Settings"
          // className="aid-blu-btn"
          style={{ ...styles.save }}
          // style={{ display: "block", margin: "auto" }}
          disabled={!formIsProperlyFilledOut || !sharedStyles.isPlus}
          onClick={() => saveNotificationSettings()}
        />
      </div>
    </div>
  );
};

export default EmailNotifications;
