/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef } from "react";
import "./GrantsTableScreen.css";
import UserContext, {
  isUserInRoles,
  UserContextType,
  UserRoleGroups,
} from "../../services/UserContext";
import plusButton from "../../assets/icons/v2/icon-11.png";
import Button from "@mui/material/Button";
import "date-fns";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import { goToUrlInNewTab, GrantsResourceServer } from "../../configs/resources";
import getWindowDimensions from "../../services/dimensions";
import { TableProperties } from "exceljs";
import Chip from "../../components/Chip";
import CustomSearchSelect, {
  AddAllOptionText,
} from "../../components/CustomSearchSelect/CustomSearchSelect";
import "../DataTable.css";
import { Button as PButton } from "primereact/button";
import "datejs";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Panel } from "primereact/panel";

import { getDateString } from "../../services/formatter";
import GrowlContext from "../../services/growlContext";
import {
  tryCatchServerError,
  getSortIfDescendingFirst,
  useDebounce,
} from "../../services/functions";
import { ProgressSpinner } from "primereact/progressspinner";
import { parse } from "query-string";
import {
  DateFormat,
  formatDate,
  getISODateWithAdjustedTimezone,
  getNewDateNoTimezoneAdjustment,
} from "../../utils/conversion/date-converters";
import { Popover } from "../../components/Popover/Popover";
import {
  buildExcelTable,
  generateExcel,
  genEmptyRow,
  getChunksFromString,
  MaxCharsAllowed,
} from "../../services/exporter";
import { useGridStyles } from "../../hooks/useGridStyles";

import {
  GetCustomColumnShortened,
  GetCustomColumnBody,
} from "../../components/DataTable/Columns";
import { PanelHeader } from "../../components/DataTable/PanelHeader";
import * as ActionButtons from "../../components/DataTable/ActionButtons";
import {
  MaximizeGrid,
  MinimizeGrid,
  NavigateToButton,
} from "../../components/DataTable/ActionButtons";

import { useEnterListener } from "../../hooks/useEnterListener";

import IconTooltip from "../../components/IconTooltip";
import Swal from "sweetalert2";

import { rowsPerPageOptionsStandard } from "../../utils/constants/gridsOptions";
import { saveAs } from "file-saver";
import axios from "axios";
import { fetchDataNewEnvironment } from "../../services/dropdownOptionListProvider";
import { removeHtmlTags } from "../../services/dataParser";
import { RemoveTags } from "../../utils/Helpers/fieldParser";
import CustomFilterSearchSelect from "../../components/CustomSearchSelect/CustomFilterSearchSelect";
import { OverlayPanel } from "primereact/overlaypanel";
import styled from "@emotion/styled";
import { MultiSelect } from "primereact/multiselect";
import { useHistory, useLocation } from "react-router-dom";
import { arrayEquals } from "../../utils/Helpers/arrayEqual";
import { ShareUser } from "../Forecast/Forecast";
import { Capitalize } from "../../utils/Helpers/Capitalize";
import { validateEmailSimple } from "../../utils/validation/input-validation";
import ReactDOM from "react-dom";
import ShowMoreWhenHover from "../../components/ShowMoreWhenHover";
import { format } from "date-fns";

const icons = {
  location: require("../../assets/icons/v2/icon_location.png")?.default,
  helmet: require("../../assets/icons/v2/icon_helmet.png")?.default,
  number: require("../../assets/icons/v2/icon_number.png")?.default,
  file: require("../../assets/icons/v2/icon_file.png")?.default,
  eligible: require("../../assets/icons/v2/icon_checklist.png")?.default,
  keyword: require("../../assets/icons/v2/icon-06.png")?.default,
  calendar: require("../../assets/icons/v2/icon_calendar.png")?.default,
  zip: require("../../assets/icons/v2/icon_zip.png")?.default,
};

interface FundingInstrument {
  id: number;
  description: string;
}
interface ApplicantType {
  id: number;
  description: string;
}
interface FundingActivityCategory {
  id: number;
  description: string;
}
type PipelineDto = {
  id: number;
  name: string;
};
type PipelineForecasts = {
  id: number;
  name: string;
  businessForcast: [];
};
interface GrantRecord {
  awardFloor: number | null;
  awardFloorFormatted: string;
  applicantEligibilityDescription: string;
  sendEmail: string;
  createTimestamp: Date | null;
  modComments: string;
  createdDateIn: Date | null;
  responseDateString: string;
  postingDateString: string;
  archiveDateString: string;
  createTimeStampString: string;
  createdOn: Date;
  awardCeilingFormatted: string;
  awardCeiling: number | null;
  estimatedFundingFormatted: string;
  estimatedFunding: number | null;
  rowId: number;
  version: number;
  agencyCode: string;
  agencyName: string;
  agencyPhone: string;
  agencyAddressDescription: string;
  agencyContactPhone: string;
  agencyContactName: string;
  agencyContactEmail: string;
  agencyContactEmailDescription: string;
  description: string;
  responseDate: Date | null;
  postingDate: Date | null;
  archiveDate: Date | null;
  costSharing: boolean;
  numberOfAwards: number;
  agencyContactDescription: string;
  fundingInstrumentLookups: { fundingInstrument: FundingInstrument }[];
  applicantTypeLookups: { applicantType: ApplicantType }[];
  fundingActivityLookups: {
    fundingActivityCategory: FundingActivityCategory;
  }[];
}

interface SummaryGrant {
  id: number;
  isMyList: boolean;
  customColumn: string;
  notes: string;
  topLevelAgency: string;
  opportunityNumber: string;
  opportunityTitle: string;
  originalDueDate: string | null;
  opportunityCategoryDescription: string;
  lastUpdatedDateIn: string | null;
  manualLastUpdatedDate?: string | null;
  cfdas: string[];
  record: SummaryGrantRecord; // have to set this
  primeOrIncumbent: string;
}

interface SummaryGrantRecord {
  awardFloorFormatted: string;
  applicantEligibilityDescription: string;
  awardCeilingFormatted: string;
  estimatedFundingFormatted: string;
  version: number;
  agencyName: string;
  agencyPhone: string;
  agencyAddressDescription: string;
  agencyContactPhone: string;
  agencyContactName: string;
  agencyContactEmail: string;
  description: string;
  responseDate: string | null;
  postingDate: string | null;
  archiveDate: string | null;
  costSharing: boolean;
  numberOfAwards: number;
  fundingInstrumentDescription: string;
  applicantTypeDescription: string;
  fundingActivityDescription: string;
  agencyContactDescription: string;
  status: string | null;
}

interface FileRecord {
  fileName: string;
  fileDescription: string;
  createdOn: string | null;
}

const OverlayerWindow = styled.div({
  zIndex: 10000,
  top: "1px !important",
  right: "0px",
});
const customStyle = {
  multiSelect: {
    width: "100%",
    borderRadius: 10,
  },
  chipsContainer: {
    marginTop: "5px",
    display: "flex",
    flexWrap: "wrap" as "wrap",
  },
};
const RecordExpandedView = ({
  record,
  fileKeyword,
}: {
  record: SummaryGrant;
  fileKeyword?: string;
}) => {
  const context = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);
  const [data, setData] = React.useState(Array<GrantRecord>());
  const [loading, setLoading] = React.useState(false);
  const [files, setFiles] = React.useState(Array<FileRecord>());
  const [matchingFiles, setMatchingFiles] = React.useState(Array<string>());
  const styles = useGridStyles();

  React.useEffect(() => {
    setLoading(true);
    const request = createAuthenticatedRequest(context);

    axios
      .get(
        getFullUrl(
          `/api/grant/details/${record.id}${
            fileKeyword ? `?fileKeyword=${fileKeyword}` : ""
          }`,
          { useDedicatedEnvironment: true },
        ),
        request,
      )
      .then((r) => {
        const {
          files,
          records,
          matchingFiles,
        }: {
          files: FileRecord[];
          records: GrantRecord[];
          matchingFiles: string[];
        } = r.data;

        const f = files.sort((a, b) =>
          new Date(a.createdOn || "9999-01-01") <
          new Date(b.createdOn || "9999-01-01")
            ? 1
            : -1,
        );

        setData(records);
        setFiles(f);
        setLoading(false);
        setMatchingFiles(matchingFiles);
      })
      .catch(
        tryCatchServerError(() => {
          growl.current.show({
            severity: "error",
            summary: "Error getting details",
            detail: `Error getting Grant+ details for ${record.id}`,
          });
          setLoading(false);
        }),
      );
  }, []);

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState("");
  const [dialogVisible, setDialogVisible] = React.useState(false);

  const suggestionsDescriptionColumnBody = (r: GrantRecord) => (
    <p
      className="forecast_description"
      style={{
        padding: 10,
      }}
      data-title={r.agencyName}
      data-full-abstract={r.description}
      dangerouslySetInnerHTML={{
        __html:
          r.description && r.description.length > 200
            ? `${removeHtmlTags(r.description).substring(0, 200)}...`
            : r.description,
      }}
      onClick={() => {
        setDialogHeader(r.agencyName);
        setDialogText(r.description);
        setDialogVisible(true);
      }}
    ></p>
  );

  const suggestionEligibilityDescriptionColumnBody = (r: GrantRecord) => (
    <p
      className="forecast_description"
      style={{
        padding: 10,
      }}
      data-title={r.agencyName}
      data-full-abstract={r.applicantEligibilityDescription}
      dangerouslySetInnerHTML={{
        __html:
          r.applicantEligibilityDescription &&
          r.applicantEligibilityDescription.length > 200
            ? `${removeHtmlTags(r.applicantEligibilityDescription).substring(
                0,
                200,
              )}...`
            : r.applicantEligibilityDescription,
      }}
      onClick={() => {
        setDialogHeader(r.agencyName);
        setDialogText(r.applicantEligibilityDescription);
        setDialogVisible(true);
      }}
    ></p>
  );

  if (loading) {
    return <ProgressSpinner></ProgressSpinner>;
  }

  return (
    <div
      className="p-grid p-dir-col"
      style={{
        display: "flex",
        justifyContent: "flex-start",
        textAlign: "left",
        margin: 15,
      }}
    >
      <Popover
        dialogHeader={dialogHeader}
        isVisible={dialogVisible}
        dialogText={dialogText}
        setDialogVisible={setDialogVisible}
      />
      <h4>
        Files
        {files.length > 0 ? (
          <>
            <a
              style={{ cursor: "pointer" }}
              // href=""
              onClick={() => {
                axios
                  .post(
                    getFullUrl(`/api/grant/details/${record.id}/getzip`, {
                      useDedicatedEnvironment: true,
                    }),
                    {},
                    {
                      ...createAuthenticatedRequest(context),
                      responseType: "blob",
                    },
                  )
                  .then(async (response) => {
                    let fileName = `export-${new Date().valueOf()}.zip`;
                    try {
                      fileName = response.headers["content-disposition"]
                        .split(";")[1]
                        .replace("filename=", "")
                        .replaceAll('"', "")
                        .trim();
                    } catch {
                      /* NO-OP */
                    }

                    // in deployed version the response is still getting converted back
                    // to b64 so need to un-load that. if running web server locally the
                    // response is already decoded so can directly save response.data instead
                    // of this BS
                    // const decoded = atob(await response.data.text());
                    // const array = new Uint8Array(decoded.length);
                    // for (let i = 0; i < decoded.length; i++) {
                    //   array[i] = decoded.charCodeAt(i);
                    // }

                    // saveAs(
                    //   new Blob([array], { type: "application/zip" }),
                    //   fileName
                    // );

                    saveAs(new Blob([response.data]), fileName);
                  })
                  .catch((error) => {
                    alert("error fetching data");
                  });
              }}
            >
              <img
                src={icons.zip}
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  ...styles.addRemoveButtonStyle,
                }}
              />
              Download all attachments
            </a>
          </>
        ) : (
          <span className="no-attachments">
            No attachments have been added to this opportunity
          </span>
        )}
      </h4>
      <div style={{ width: 1024 }}>
        <DataTable value={files}>
          {GetCustomColumnBody<FileRecord>(
            styles,
            300,
            (x) =>
              removeHtmlTags(x.fileDescription)
                .replace(/\&nbsp;/g, "")
                .replace(/&amp;/g, "&"),
            "Description",
            "fileDescription",
          )}
          {GetCustomColumnBody<FileRecord>(
            styles,
            300,
            (x) => (
              <a
                href="#"
                style={{ paddingLeft: 15 }}
                onClick={(e: any) => {
                  e.preventDefault();
                  window.open(
                    `${GrantsResourceServer}${encodeURIComponent(x.fileName)}`,
                  );
                }}
              >
                <span style={{ fontWeight: "bold", fontSize: 20 }}>
                  {matchingFiles.find((r) => r === x.fileName) ? "* " : ""}
                </span>
                {removeHtmlTags(x.fileName)
                  .replace(/\&nbsp;/g, "")
                  .replace(/&amp;/g, "&")}
              </a>
            ),
            "Name",
            "fileName",
          )}
          {GetCustomColumnBody<FileRecord>(
            styles,
            300,
            (x) =>
              typeof x.createdOn === "string"
                ? format(
                    getNewDateNoTimezoneAdjustment(x.createdOn),
                    "dd MMMM yyyy hh:mm aaaa",
                  )
                : null,
            "Added Date/Time at UTC",
            "createdOn",
          )}
        </DataTable>
      </div>

      <h4>Record History</h4>

      <DataTable
        value={data}
        scrollHeight="600px"
        scrollable={true}
        className="grants-history-table"
      >
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) => x.version,
          "Version",
          "version",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          100,
          (x) => x.agencyCode,
          "Agency",
          "agencyCode",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          250,
          (x) => x.agencyName,
          "Country or Bureau",
          "agencyName",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) => getEmptyStringWithCurrencyIfNull(x.awardCeilingFormatted),
          "Award Ceiling",
          "awardCeilingFormatted",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) => getEmptyStringWithCurrencyIfNull(x.awardFloorFormatted),
          "Award Floor",
          "awardFloorFormatted",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) => getEmptyStringWithCurrencyIfNull(x.estimatedFundingFormatted),
          "Estimated Funding",
          "estimatedFundingFormatted",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) => x.agencyPhone,
          "Agency Phone",
          "agencyPhone",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) => "x.agencyAddressDescription",
          "Agency Description",
          "agencyAddressDescription",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) => x.agencyContactPhone,
          "Agency Contact Phone",
          "agencyContactPhone",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) => x.agencyContactName,
          "Agency Contact Name",
          "agencyContactName",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) => x.agencyContactEmail,
          "Agency Contact Email",
          "agencyContactEmail",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) => x.agencyContactEmailDescription,
          "Agency Contact Email Description",
          "agencyContactEmailDescription",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) => x.agencyContactDescription,
          "Agency Contact Description",
          "agencyContactDescription",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) => x.sendEmail,
          "Send Email",
          "sendEmail",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          550,
          (x) => x.modComments,
          "Modification Comments",
          "modComments",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) =>
            typeof x.createdDateIn === "string"
              ? format(
                  getNewDateNoTimezoneAdjustment(x.createdDateIn),
                  "dd MMMM yyyy hh:mm aaaa",
                )
              : null,
          "Created On",
          "createdDateIn",
        )}
        {GetCustomColumnShortened<GrantRecord>(
          styles,
          550,
          suggestionEligibilityDescriptionColumnBody,
          (x) => x.agencyName,
          "Eligibility",
          200,
          false,
          "applicantEligibilityDescription",
        )}
        {GetCustomColumnShortened<GrantRecord>(
          styles,
          550,
          suggestionsDescriptionColumnBody,
          (x) => x.agencyName,
          "Description",
          200,
          true,
          "description",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) =>
            typeof x.responseDate === "string"
              ? format(
                  getNewDateNoTimezoneAdjustment(x.responseDate),
                  "dd MMMM yyyy hh:mm aaaa",
                )
              : null,
          "Response Date",
          "responseDate",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) =>
            typeof x.postingDate === "string"
              ? format(
                  getNewDateNoTimezoneAdjustment(x.postingDate),
                  "dd MMMM yyyy hh:mm aaaa",
                )
              : null,
          "Posting Date",
          "postingDate",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) =>
            typeof x.archiveDate === "string"
              ? format(
                  getNewDateNoTimezoneAdjustment(x.archiveDate),
                  "dd MMMM yyyy hh:mm aaaa",
                )
              : null,
          "Archive Date",
          "archiveDate",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) => (x.costSharing ? "YES" : "NO"),
          "Cost Sharing",
          "costSharing",
        )}
        {GetCustomColumnBody<GrantRecord>(
          styles,
          150,
          (x) => x.numberOfAwards,
          "Number of Awards",
          "numberOfAwards",
        )}
      </DataTable>
    </div>
  );
};

function addClickListener(
  setHeader: (x: string) => void,
  setText: (x: string) => void,
  setVisible: (x: boolean) => void,
) {
  return () => {
    const onDescriptionClick = (event: any) => {
      if (
        !event.target.matches(".abstract") ||
        event.target.matches(".p-dialog-header-close-icon")
      ) {
        return;
      }

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      setHeader(container.attributes["data-title"].value);
      setText(dialogText);
      setVisible(true);
    };

    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
    };
  };
}

function GrantsTableScreen() {
  const context = React.useContext(UserContext);
  const styles = useGridStyles();

  // Stylings
  const [gridWidth, setGridWidth] = React.useState(0);
  React.useEffect(() => {
    function handleResize() {
      const { width, headerWidth } = getWindowDimensions();
      if (width !== null && headerWidth != null) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Initial prarams
  let onlyMyRecordsArgument = false;
  if (location && location.search) {
    onlyMyRecordsArgument =
      (parse(location.search).myGrants as string) === "true" || false;
  }

  // Initialize date to today-3 months
  let todayMinusMonths = Date.today().addMonths(-6);

  // Grid required properties
  const [data, setData] = React.useState(Array<SummaryGrant>());
  const [isLoading, setIsLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalRecords, setTotalRecords] = React.useState(0); // todo
  const [first, setFirst] = React.useState(0);
  const [sortField, setSortField] = React.useState("postingDate");
  const [sortOrder, setSortOrder] = React.useState(-1);
  const [lastUpdated, setLastUpdated] = React.useState("");
  const [expandedRows, setExpandedRows] = React.useState(Array<SummaryGrant>());

  //Available Options
  const [countryNamesOptions, setCountryNamesOptions] = React.useState(
    Array<string>(),
  );
  const [categoryNamesOptions, setCategoryNamesOptions] = React.useState(
    Array<string>(),
  );

  React.useEffect(() => {
    let apiUrl = "/api/grant/dropdownoptions/";
    let countryNamesUrl = apiUrl + "country";
    let categoryNamesUrl = apiUrl + "category";

    let baseMessage = "error fetching available ";
    let countryNamesMessage = baseMessage + "country names";
    let categoryNamesMessage = baseMessage + "category names";

    fetchDataNewEnvironment(
      countryNamesUrl,
      setCountryNamesOptions,
      context,
      countryNamesMessage,
      true,
    );
    fetchDataNewEnvironment(
      categoryNamesUrl,
      setCategoryNamesOptions,
      context,
      categoryNamesMessage,
      true,
    );
  }, []);

  // Filters
  const [selectedCountryFilter, setSelectedCountryFilter] = React.useState(
    Array<string>(),
  );
  const [selectedCategoryFilter, setSelectedCategoryFilter] = React.useState(
    Array<string>(),
  );
  const [opportunityNumber, setOpportunityNumber] = React.useState("");
  const [eligibility, setEligibility] = React.useState("");
  const [keyword, setKeyword] = React.useState("");
  const [fileKeyword, setFileKeyword] = React.useState("");
  const [onlyMyList, setOnlyMyList] = React.useState(onlyMyRecordsArgument);
  const [includeUsaid, setIncludeUsaid] = React.useState(true);
  const [includeMcc, setIncludeMcc] = React.useState(false);
  const [includeStateDept, setIncludeStateDept] = React.useState(false);
  const [includeCdc, setIncludeCdc] = React.useState(false);
  const [trackedOpportunities, setTrackedOpportunities] = React.useState(false);
  const [fileName, setFileName] = React.useState("");
  const [updatedDateFilterError, setUpdatedDateFilterError] =
    React.useState(false);
  const [updatedDateFilter, setUpdatedDateFilter] = React.useState<Date | null>(
    todayMinusMonths,
  );

  const [missionFilter, setMissionFilter] = React.useState("");

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState<string | JSX.Element>("");
  const [dialogVisible, setDialogVisible] = React.useState(false);

  const [originalNotes, setOriginalNotes] = React.useState(
    Array<{ id: number; note: string }>(),
  );
  const [originalColumns, setOriginalColumns] = React.useState(
    Array<{ id: number; customColumn: string }>(),
  );

  const [customColumnName, setCustomColumnName] = React.useState("");
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  const [query, setQuery] = React.useState<URLSearchParams>();

  const queryCallback = () =>
    queryData({
      first,
      rows: rowsPerPage,
      sortField,
      sortOrder,
    });
  useEnterListener(queryCallback);

  React.useEffect(() => {
    const onDescriptionClick = (event: any) => {
      if (!event.target.matches(".abstract")) {
        return;
      }

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      setDialogHeader(container.attributes["data-title"].value);
      setDialogText(dialogText);
      setDialogVisible(true);
    };
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
    };
  }, []);

  // Load data on page load
  React.useEffect(() => {
    queryData({ first, rows: rowsPerPage, sortField, sortOrder });
  }, []);

  const debouncedSearch = useDebounce(keyword, 750);

  function queryData(event: {
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
  }) {
    setRowsPerPage(event.rows);

    setIsLoading(true);
    const url = "/api/grant";
    const queryString = new URLSearchParams();

    if (selectedCountryFilter.length) {
      queryString.set("country", selectedCountryFilter.join("|"));
    }

    if (selectedCategoryFilter.length) {
      queryString.set("category", selectedCategoryFilter.join("|"));
    }

    if (eligibility) {
      queryString.set("eligibility", eligibility);
    }

    if (opportunityNumber) {
      queryString.set("opportunityNumber", opportunityNumber);
    }

    if (onlyMyList) {
      queryString.set("onlyMyList", onlyMyList.toString());
    }

    if (includeUsaid) {
      queryString.set("includeUsaid", includeUsaid.toString());
    }
    if (includeMcc) {
      queryString.set("includeMcc", includeMcc.toString());
    }
    if (includeStateDept) {
      queryString.set("includeStateDept", includeStateDept.toString());
    }
    if (includeCdc) {
      queryString.set("includeCdc", includeCdc.toString());
    }
    if (trackedOpportunities) {
      queryString.set(
        "OnlyUntrackedInPipeline",
        trackedOpportunities.toString(),
      );
    }

    if (keyword) {
      queryString.set("filter", keyword);
    }

    if (fileKeyword) {
      queryString.set("fileKeyword", fileKeyword);
    }

    if (fileName) {
      queryString.set("fileName", fileName);
    }

    if (updatedDateFilter && !updatedDateFilterError) {
      queryString.set(
        "updatedBy",
        // updatedDateFilter.toISOString().substring(0, 10),
        getISODateWithAdjustedTimezone(updatedDateFilter),
      );
    }

    setFirst(event.first);
    const pageIndex = event.first / rowsPerPage;
    queryString.set("pageIndex", pageIndex.toString());
    queryString.set("pageSize", event.rows.toString());

    queryString.set("sortField", event.sortField || sortField);
    queryString.set("sortOrder", (event.sortOrder || sortOrder).toString());

    setQuery(queryString);

    axios
      .get(
        getFullUrl(`${url}?${queryString.toString()}`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      )
      .then((response) => {
        const { totalItems, data, lastUpdatedUtc, attributes } = response.data;
        setTotalRecords(totalItems);

        // Set top records
        setCustomColumnName(attributes ? attributes.CustomColumnName : "");
        const d = data as Array<SummaryGrant>;
        d.forEach((r) => {
          if (!r.notes) {
            r.notes = "";
          }
        });
        setData(d);
        setOriginalNotes(d.map((r) => ({ id: r.id, note: r.notes })));
        setOriginalColumns(
          d.map((r) => ({
            id: r.id,
            customColumn: r.customColumn,
          })),
        );

        setLastUpdated(
          lastUpdatedUtc
            ? `Last updated ${getDateString(new Date(lastUpdatedUtc + "Z"))}`
            : "",
        );
        setIsLoading(false);
      })
      .catch((error) => {
        alert("error fetching data");
        setIsLoading(false);
      });
  }

  var renderSearch = () => {
    return (
      <div>
        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.location} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!countryNamesOptions.length ? (
              <CustomFilterSearchSelect
                placeholder="Mission/Bureau"
                setFilter={setMissionFilter}
                setSelectedOption={setSelectedCountryFilter}
                allOptions={countryNamesOptions}
                disabled={isLoading || !countryNamesOptions}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.helmet} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!categoryNamesOptions.length ? (
              <CustomSearchSelect
                placeholder="Technical Sector"
                setSelectedOption={setSelectedCategoryFilter}
                allOptions={categoryNamesOptions}
                disabled={isLoading || !categoryNamesOptions}
                addAllFilteredResults={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.number} alt="search" style={styles.icon} />
          </div>
          <div
            className="p-col"
            style={{ textAlign: "left", display: "block" }}
          >
            <InputText
              style={styles.filters.inputBox}
              id="opportunityNumber"
              type="search"
              value={opportunityNumber}
              onChange={(e: any) =>
                setOpportunityNumber((e.target as any).value)
              }
              placeholder="Opportunity Number"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.file} alt="search" style={styles.iconFile} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            <InputText
              style={styles.filters.inputBox}
              id="fileName"
              type="search"
              value={fileName}
              onChange={(e: any) => setFileName((e.target as any).value)}
              placeholder="File Name"
              tooltip={
                "Search file names of historical attachments. " +
                "Clear the date filter below to search all records in the database. " +
                'Try "IEE" or "EMMP" for environmental reports. ' +
                'Or, "LCP" or "Compensation" for local compensation plans. ' +
                "You can also filter to only search by select Missions."
              }
              tooltipOptions={{ position: "left" }}
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.eligible} alt="search" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            <InputText
              style={styles.filters.inputBox}
              id="eligibility"
              type="search"
              value={eligibility}
              onChange={(e: any) => setEligibility((e.target as any).value)}
              placeholder="Eligibility"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.keyword} alt="search" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            <InputText
              style={styles.filters.inputBox}
              id="keyword"
              type="search"
              value={keyword}
              onChange={(e: any) => setKeyword((e.target as any).value)}
              placeholder="Keyword Search Grants+ Procurements Data"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.keyword} alt="search" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            <InputText
              style={styles.filters.inputBox}
              id="fileKeyword"
              type="search"
              value={fileKeyword}
              onChange={(e: any) => setFileKeyword((e.target as any).value)}
              placeholder="Keyword Search In-Document Text"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.calendar} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left", display: "flex" }}>
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              Show grants updated since{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              onError={(e) => setUpdatedDateFilterError(!!e)}
              value={updatedDateFilter}
              onChange={(date) => setUpdatedDateFilter(date)}
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading}
            />
          </div>
        </div>

        <div className="p-grid" style={styles.filters.notTopFilterRow}>
          <div className="p-col" style={styles.filters.searchButtonContainer}>
            <Button
              style={styles.filters.searchButton}
              onClick={() =>
                queryData({ first, rows: rowsPerPage, sortField, sortOrder })
              }
            >
              <div style={styles.filters.searchButtonText}>Search</div>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  var getExcelData = async (): Promise<SummaryGrant[]> => {
    var base = "/api/grant";
    var q = query;
    if (q) q.set("pageSize", "1000");
    var qu = q ? base + "?" + q.toString() : base;
    var result = Array<SummaryGrant>();
    await axios
      .get(
        getFullUrl(qu, { useDedicatedEnvironment: true }),
        createAuthenticatedRequest(context),
      )
      .then((response) => {
        result = response.data.data;
      })
      .catch((error) => {
        alert("error fetching data");
      });
    return result;
  };

  return (
    <div className="p-grid p-dir-col grants">
      <div className="p-grid" style={styles.pageHeader}>
        <div className="p-col-12" style={{ ...styles.pageHeader }}>
          <img
            src={styles.aidkonektIcon}
            style={styles.icon}
            alt="Aidkonekt"
            className="formIcon"
          ></img>
          Grants+
        </div>
      </div>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Panel
          style={styles.filters.filterBox}
          collapsed={isCollapsed}
          onToggle={(e) => setIsCollapsed(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <PanelHeader
              search={() =>
                queryData({ first, rows: rowsPerPage, sortField, sortOrder })
              }
              isCollapsed={isCollapsed}
              expandedTitle={
                <div
                  className="p-grid"
                  style={{ ...styles.filters.fontHeader }}
                >
                  <div
                    className="p-col-12 p-md-6 p-lg-2"
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      inputId="cb1"
                      value="USAID"
                      onChange={(e) => setIncludeUsaid(e.checked)}
                      checked={includeUsaid}
                    />
                    <label
                      htmlFor="cb1"
                      className="p-checkbox-label"
                      style={styles.filters.inputTextLabel}
                    >
                      USAID
                    </label>
                  </div>
                  <div
                    className="p-col-12 p-md-6 p-lg-2"
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      inputId="cb2"
                      value="MCC"
                      onChange={(e) => setIncludeMcc(e.checked)}
                      checked={includeMcc}
                    />
                    <label
                      htmlFor="cb2"
                      className="p-checkbox-label"
                      style={styles.filters.inputTextLabel}
                    >
                      MCC
                    </label>
                  </div>
                  <div
                    className="p-col-12 p-md-6 p-lg-2"
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      inputId="cb3"
                      value="State Dept"
                      onChange={(e) => setIncludeStateDept(e.checked)}
                      checked={includeStateDept}
                    />
                    <label
                      htmlFor="cb3"
                      className="p-checkbox-label"
                      style={styles.filters.inputTextLabel}
                    >
                      State Dept
                    </label>
                  </div>
                  <div
                    className="p-col-12 p-md-6 p-lg-2"
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      inputId="cb4"
                      value="CDC"
                      onChange={(e) => setIncludeCdc(e.checked)}
                      checked={includeCdc}
                    />
                    <label
                      htmlFor="cb4"
                      className="p-checkbox-label"
                      style={styles.filters.inputTextLabel}
                    >
                      CDC
                    </label>
                  </div>
                  <div
                    className="p-col-12 p-md-6 p-lg-4"
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      inputId="cb4"
                      value="CDC"
                      onChange={(e) => setTrackedOpportunities(e.checked)}
                      checked={trackedOpportunities}
                    />
                    <label
                      htmlFor="cb4"
                      className="p-checkbox-label"
                      style={styles.filters.inputTextLabel}
                    >
                      Exclude tracked opportunities
                    </label>
                  </div>
                </div>
              }
              setIsCollapsed={setIsCollapsed}
            />
          }
        >
          {renderSearch()}
        </Panel>
      </MuiPickersUtilsProvider>

      <Popover
        dialogHeader={dialogHeader}
        isVisible={dialogVisible}
        dialogText={dialogText}
        setDialogVisible={setDialogVisible}
      />

      <GrantsDataTable
        gridWidth={gridWidth}
        isLoading={isLoading}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        sortField={sortField}
        setSortField={setSortField}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        first={first}
        totalRecords={totalRecords}
        setTotalRecords={setTotalRecords}
        data={data}
        setData={setData}
        lastUpdated={lastUpdated}
        originalNotes={originalNotes}
        setOriginalNotes={setOriginalNotes}
        originalColumns={originalColumns}
        setOriginalColumns={setOriginalColumns}
        customColumnName={customColumnName}
        setCustomColumnName={setCustomColumnName}
        queryData={queryData}
        hideNotes={true}
        fileKeyword={fileKeyword}
        getExcelData={getExcelData}
      />
    </div>
  );
}

type GrantTableProps = {
  isLoading: boolean;
  isPipelineScreen?: boolean;
  isPipelineOwner?: boolean;

  // Filtering/sorting/querying
  id?: number;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<number>;

  sortField: string;
  setSortField: React.Dispatch<string>;
  sortOrder: number;
  setSortOrder: React.Dispatch<number>;
  first: number;
  totalRecords: number;
  setTotalRecords: React.Dispatch<number>;

  // Grid state
  data: Array<SummaryGrant>;
  setData: React.Dispatch<Array<SummaryGrant>>;
  lastUpdated: string;
  gridWidth: number;

  // Notes info
  originalNotes: Array<{ id: number; note: string }>;
  setOriginalNotes: React.Dispatch<Array<{ id: number; note: string }>>;
  hideNotes?: boolean;

  // Columns info
  originalColumns: Array<{ id: number; customColumn: string }>;
  setOriginalColumns: React.Dispatch<
    Array<{ id: number; customColumn: string }>
  >;

  // Column name
  customColumnName: string;
  setCustomColumnName: React.Dispatch<string>;

  isMyGrants?: boolean;
  decrement?: () => void;
  queryData: (event: {
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
  }) => void;

  // needed for global keyword search
  keyword?: string;
  setKeyword?: React.Dispatch<string>;

  // needed for file keyword search to show in expanded view
  fileKeyword?: string;

  getExcelData?: () => Promise<SummaryGrant[]>;
  isRemove?: boolean;
  isMySuggestions?: boolean;
  setRefreshGrantsData?: (value: boolean) => void;
};

const GrantsDataTable: React.FunctionComponent<GrantTableProps> = ({
  isLoading,
  id,

  rowsPerPage,
  setRowsPerPage,

  sortField,
  setSortField,
  sortOrder,
  setSortOrder,
  first,
  totalRecords,
  setTotalRecords,

  data,
  setData,
  lastUpdated,
  gridWidth,

  originalNotes,
  setOriginalNotes,
  hideNotes,

  originalColumns,
  setOriginalColumns,

  customColumnName,
  setCustomColumnName,

  isMyGrants,
  decrement,

  // needed for global keyword search
  keyword,
  setKeyword,

  queryData,

  // needed for file keyword search to show in expanded view
  fileKeyword,

  getExcelData,
  isRemove,
  isPipelineScreen,
  isPipelineOwner,
  isMySuggestions,
  setRefreshGrantsData = null,
}) => {
  // Contexts
  const context = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);
  const history = useHistory();
  const path = "/grants";
  const styles = useGridStyles();

  // Grid state
  const [expandedRows, setExpandedRows] = React.useState(Array<SummaryGrant>());
  const [isGridFullScreen, setIsGridFullScreen] = React.useState(false);

  const [notesCollapsed, setNotesCollapsed] = React.useState(false);
  const [customColumnCollapsed, setCustomColumnCollapsed] =
    React.useState(false);

  const [visibleTooltip, setVisibleTooltip] = React.useState<boolean>(false);
  const [bottomOffset, setBottomOffset] = React.useState<string>();
  const [leftOffset, setLeftOffset] = React.useState<string>();
  const popupRef = useRef(null);
  const [selectedTextToShow, setSelectedTextToShow] = React.useState<
    string | null
  >(null);
  const displayMoreTextPopup = (
    text: string,
    show: boolean,
    top: any,
    left: any,
  ) => {
    if (show) {
      setSelectedTextToShow(text);
      var element = ReactDOM.findDOMNode(popupRef.current) as Element;
      if (element) {
        const elementRect = element.getBoundingClientRect();
        if (text.length < 60) elementRect.height = 70;
        else elementRect.height = 160;
        setBottomOffset(top - elementRect.height + "px");
        setLeftOffset(left + "px");
      }
    }
    setVisibleTooltip(show);
  };

  const [pipeline, setPipelines] = React.useState<PipelineDto[]>(
    Array<PipelineDto>(),
  );
  const [pipelineForcasts, setPipelineforcasts] = React.useState<
    PipelineForecasts[]
  >(Array<PipelineForecasts>());
  const popupMenu = useRef<OverlayPanel>(null);
  const [rowData, setRowData] = React.useState<SummaryGrant>();
  const [selectedPipelines, setSelectedPipelines] = React.useState<
    PipelineDto[]
  >([]);
  const [serverRecord, setServerRecord] = React.useState<PipelineDto[]>([]);
  const [rerender, setRerender] = React.useState(false);
  const [toggleAddPipeline, setToggle] = React.useState(false);
  // Handlers
  function sort(event: { sortField: string; sortOrder: number }) {
    const newSortOrder = getSortIfDescendingFirst(event, sortField);
    setSortField(event.sortField);
    setSortOrder(newSortOrder);
    queryData({
      first,
      rows: rowsPerPage,
      sortField: event.sortField,
      sortOrder: newSortOrder,
    });
  }

  const [disableExcel, setDisableExcel] = React.useState(false);

  React.useEffect(() => {
    //  load pipeline data
    axios
      .get(
        getFullUrl("/api/pipeline", { useDedicatedEnvironment: true }),
        createRequestWithAuthHeaders(context),
      )
      .then(async (res) => {
        const mypipelines = res.data.filter(
          (pipeline) => pipeline.pipelineUserRole === "Admin",
        );

        const path = history.location.pathname;
        let id: number | null = null;

        if (path.includes("/pipeline/")) {
          const pathParts = path.split("/");
          id = +pathParts[pathParts.length - 1];
        }

        const records = await Promise.all(
          mypipelines.map(async (x: PipelineDto) => {
            if (id !== null && x.id !== id) return null;
            const sa = await axios.get(
              getFullUrl(`/api/pipeline/${x.id}/grant`, {
                useDedicatedEnvironment: true,
              }),
              createAuthenticatedRequest(context),
            );
            const data = sa.data.data;
            return {
              id: x.id,
              name: x.name,
              businessForcast: data,
            };
          }),
        ).then((results) => results.filter((record) => !!record));

        const r: PipelineForecasts[] = (records as PipelineForecasts[]).map(
          (x) => ({
            id: x.id,
            name: x.name,
            businessForcast: x.businessForcast,
          }),
        );
        setPipelines(r);
        setPipelineforcasts(r);
      })
      .catch((error) => {});
  }, [rerender]);

  const addToMyPipeline = (
    record: SummaryGrant,
    e: React.SyntheticEvent<Element, Event>,
  ) => {
    const p = pipelineForcasts.filter((a) =>
      a.businessForcast.some((u: SummaryGrant) =>
        u.id.toString().includes(record.id.toString()),
      ),
    );
    setSelectedPipelines(p);
    setServerRecord(p);
    // set row data
    setRowData(record);

    if (popupMenu && popupMenu.current) {
      popupMenu.current.toggle(e);
    }
  };
  const selectPipeline = useCallback(
    async (pipelinesTobeAdded: PipelineDto[]) => {
      let row = pipelinesTobeAdded.filter(
        (sa) => !serverRecord.some((r) => sa.id === r.id),
      );
      if (row.length > 0) {
        // shows save button when there's a new record
        setToggle(true);
      }
      setSelectedPipelines(pipelinesTobeAdded);
    },
    [],
  );

  const closeModal = () => {
    setSelectedPipelines([]);
    popupMenu.current?.hide();
  };

  const unTrackOpportunity = async (
    pipelineToRemove: PipelineDto,
    pipelines: PipelineDto[],
  ) => {
    const request = createAuthenticatedRequest(context);

    if (!rowData) {
      return;
    }

    const updatedPipelines = pipelines.filter(
      (r) => r.id !== pipelineToRemove.id,
    );
    setSelectedPipelines(updatedPipelines);

    const sendToServer = async () => {
      return axios
        .delete(
          getFullUrl(
            `/api/pipeline/${pipelineToRemove.id}/grant/${rowData.id}`,
            { useDedicatedEnvironment: true },
          ),
          request,
        )
        .then(() => {
          setToggle(false);
          growl.current.show({
            severity: "success",
            summary: `Pipeline removed`,
          });
          setRerender(!rerender);
        })
        .catch((message) => {
          console.error(message);
          growl.current.show({
            severity: "error",
            summary: "Error removing pipeline from the opportunity",
          });
        });
    };
    if (!serverRecord.includes(pipelineToRemove)) {
      setSelectedPipelines(updatedPipelines);
      growl.current.show({
        severity: "success",
        summary: `Pipeline removed from the opportunity`,
      });
      if (arrayEquals(updatedPipelines, serverRecord)) setToggle(false);
      return;
    }
    await sendToServer();
  };

  const TrackOpportunity = () => {
    const request = createAuthenticatedRequest(context);

    if (!rowData) {
      return;
    }
    const updatedSelectedPipelines = selectedPipelines.filter(
      (sa) => !serverRecord.some((r) => sa.id === r.id),
    );
    updatedSelectedPipelines.map(async (sa) => {
      await axios
        .post(
          getFullUrl(`/api/pipeline/${sa.id}/grant/${rowData.id}`, {
            useDedicatedEnvironment: true,
          }),
          null,
          request,
        )
        .then(() => {
          growl.current.show({
            severity: "success",
            summary: `Opportunity updated, with ${sa.name} pipeline`,
          });
          setToggle(false);
          popupMenu.current?.hide();
          setRerender(!rerender);
        })
        .catch((error) => {});
    });
  };
  const tableHeader = (
    <div className="p-grid p-justify-end p-align-center">
      <div
        className="p-col-8 topLeftColumnHeader"
        style={{ textAlign: "left" }}
      >
        <div>Source: grants.gov</div>
        <div>{lastUpdated}</div>
      </div>

      <div
        className="p-col-12 p-md-4 p-lg-4 p-justify-end p-grid p-align-center buttonsHeader"
        style={{ display: "flex" }}
      >
        {!isMyGrants && setKeyword && (
          <div className="search-bar">
            <InputText
              type="search"
              style={{ ...styles.keywordSearch }}
              value={keyword}
              onChange={(e) => setKeyword!((e.target as any).value)}
              placeholder="Global Search"
            />
          </div>
        )}
        <div style={{ font: "14px Open Sans", verticalAlign: "middle" }}>
          <IconTooltip
            tooltipText={
              "Opportunities are automatically sorted by “Posting Date,” so you can quickly identify newest opportunities."
            }
            className="tooltip-fixed"
          />
        </div>
        <div className="button-export">
          <PButton
            type="button"
            icon="pi pi-file-excel"
            iconPos="left"
            onClick={async () => {
              setDisableExcel(true);
              generateExcel(
                "Grants+",
                `Aidkonekt_grants_plus_${new Date().getFullYear()}`,
                getExportableDataTable(
                  getExcelData ? await getExcelData() : data,
                ),
              ).then(() => {
                setDisableExcel(false);
              });
            }}
            tooltip="Excel"
            tooltipOptions={{ position: "top" }}
            disabled={disableExcel}
            style={{
              marginLeft: 15,
              marginRight: 15,
              ...styles.tableHeaderButton,
            }}
          />
        </div>
        <div className="button-export">
          <PButton
            type="button"
            icon={
              isGridFullScreen
                ? "pi pi-window-minimize"
                : "pi pi-window-maximize"
            }
            iconPos="left"
            onClick={(e) => {
              if (isGridFullScreen) {
                MinimizeGrid(e.target as HTMLElement);
              } else {
                MaximizeGrid(e.target as HTMLElement, () =>
                  setIsGridFullScreen(false),
                );
              }

              setIsGridFullScreen(!isGridFullScreen);
            }}
            tooltip={isGridFullScreen ? "Minimize" : "Maximize"}
            tooltipOptions={{ position: "top" }}
            style={{
              marginLeft: 15,
              marginRight: 15,
              ...styles.tableHeaderButton,
            }}
          />
        </div>
      </div>
    </div>
  );

  const getExportableDataTable = (data: SummaryGrant[]): TableProperties => {
    const columns = [
      { name: "Grants.gov link" },
      { name: "Agency" },
      { name: "Mission / Bureau" },
      { name: "Opportunity Title" },
      { name: "Description" },
      { name: "Opportunity Status" },
      { name: "Last Updated Date" },
      { name: "Opportunity Number" },
      { name: "Funding Instrument Type" },
      { name: "Opportunity Category" },
      { name: "Category of Funding Activity" },
      { name: "Expected Number of Awards" },
      { name: "CFDA Number(s)" },
      { name: "Cost Sharing or Matching Requirement" },
      { name: "Version" },
      { name: "Original Closing Date for Applications" },
      { name: "Current Closing Date for Applications" },
      { name: "Posting Date" },
      { name: "Archive Date" },
      { name: "Estimated Total Program Funding" },
      { name: "Award Ceiling" },
      { name: "Award Floor" },
      { name: "Eligible Applicants" },
      { name: "Additional Information on Eligibility" },
      { name: "Contact Name" },
      { name: "Contact Description" },
      { name: "Contact Email" },
      { name: "Contact Phone" },
    ];

    if (isPipelineScreen && !isMySuggestions) {
      columns.unshift({ name: "Notes" }, { name: customColumnName });
    }

    let rows: any[][] = [];

    if (data.length === 0) {
      rows.push(genEmptyRow(columns.length));
    } else {
      for (let i = 0; i < data.length; i++) {
        const record = data[i];
        let row = [
          getDownloadUrlFromId(record.id),
          record.topLevelAgency,
          record.record.agencyName,
          removeHtmlTags(record.opportunityTitle),
          removeHtmlTags(record.record.description),
          record.record.status,
          record.manualLastUpdatedDate
            ? formatDate(record.manualLastUpdatedDate, DateFormat.dd_MMMM_yyyy)
            : "",
          record.opportunityNumber,
          record.record.fundingInstrumentDescription,
          record.opportunityCategoryDescription,
          record.record.fundingActivityDescription,
          record.record.numberOfAwards,
          record.cfdas.join(", "),
          record.record.costSharing ? "True" : "False",
          record.record.version,
          formatDate(record.originalDueDate, DateFormat.dd_MMMM_yyyy),
          formatDate(record.record.responseDate, DateFormat.dd_MMMM_yyyy),
          formatDate(record.record.postingDate, DateFormat.dd_MMMM_yyyy),
          formatDate(record.record.archiveDate, DateFormat.dd_MMMM_yyyy),
          `$${record.record.estimatedFundingFormatted}`,
          `$${record.record.awardCeilingFormatted}`,
          record.record.awardFloorFormatted,
          record.record.applicantTypeDescription,
          removeHtmlTags(record.record.applicantEligibilityDescription),
          removeHtmlTags(record.record.agencyContactName),
          removeHtmlTags(record.record.agencyContactDescription),
          record.record.agencyContactEmail,
          record.record.agencyContactPhone,
          ,
        ];

        if (isPipelineScreen && !isMySuggestions) {
          row.unshift(
            removeHtmlTags(record.notes ?? ""),
            removeHtmlTags(record.customColumn ?? ""),
          );
        }

        rows.push(row);
      }
    }

    return buildExcelTable("GrantsExport", columns, rows);
  };

  let updatedNotes = [...data];

  const NotesEditor = (props: any) => {
    const { rowIndex } = props;
    function onEditorValueChange(value: string) {
      let updatedData = [...data];
      updatedData[rowIndex].notes = value;
      updatedNotes[rowIndex].notes = value;
      setData(updatedData);
    }

    return (
      <textarea
        disabled={!isPipelineOwner}
        autoFocus
        value={data[rowIndex].notes || updatedNotes[rowIndex].notes}
        className="notesTextarea"
        onChange={(e) => onEditorValueChange((e.target as any).value)}
      />
    );
  };

  const ColumnEditor = (props: any) => {
    const { rowIndex } = props;
    function onEditorValueChange(value: string) {
      let updatedData = [...data];
      updatedData[rowIndex].customColumn = value;
      updatedNotes[rowIndex].customColumn = value;
      setData(updatedData);
    }

    return (
      <InputText
        disabled={!isPipelineOwner}
        type="text"
        className="customColumn"
        value={
          data[rowIndex].customColumn || updatedNotes[rowIndex].customColumn
        }
        onChange={(e) => onEditorValueChange((e.target as any).value)}
      />
    );
  };

  const cancelNote = (o: any) => {
    const { rowIndex, rowData }: { rowIndex: number; rowData: SummaryGrant } =
      o;
    const baseNote =
      (originalNotes.find((r) => r.id === rowData.id) || {}).note || "";

    // Revert note back in grid
    const updatedData = [...data];
    updatedData[rowIndex].notes = baseNote;
    setData(updatedData);
  };

  const cancelCustomColumn = (o: any) => {
    const { rowIndex, rowData }: { rowIndex: number; rowData: SummaryGrant } =
      o;
    const baseColumn =
      (originalColumns.find((r) => r.id === rowData.id) || {}).customColumn ||
      "";

    // Revert note back in grid
    const updatedData = [...data];
    updatedData[rowIndex].customColumn = baseColumn;
    setData(updatedData);
  };

  const submitNote = (o: any) => {
    const { rowData, rowIndex }: { rowIndex: number; rowData: SummaryGrant } =
      o.columnProps;
    const currentNote = updatedNotes[rowIndex].notes;
    // If the note has not changed, don't re-save
    const baseNote = originalNotes.find((r) => r.id === rowData.id);
    const isNoteUnmodified = baseNote && baseNote.note === currentNote;
    if (isNoteUnmodified || currentNote === null) {
      return;
    }

    if (!isPipelineOwner) {
      return;
    }

    // Send update
    axios
      .post(
        getFullUrl(`/api/pipeline/${id}/grant/note`, {
          useDedicatedEnvironment: true,
        }),
        { grantId: rowData.id, value: currentNote },
        createRequestWithAuthHeaders(context),
      )
      .then(() => {
        // Update in-memory notes to new note
        if (baseNote) {
          const updatedNotes = [...originalNotes];
          const updatedNote = updatedNotes.find((r) => r.id === baseNote.id);
          if (updatedNote) updatedNote.note = rowData.notes;
          setOriginalNotes(updatedNotes);
        }

        if (growl && growl.current)
          growl.current.show({
            severity: "success",
            summary: "Note Updated",
            detail: `Finished updating notes for '${rowData.opportunityNumber}'`,
          });
      })
      .catch(
        tryCatchServerError((message) => {
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          });
          cancelNote(o);
        }),
      );
  };

  const cancelColumn = (o: any) => {
    const { rowIndex, rowData }: { rowIndex: number; rowData: SummaryGrant } =
      o;
    const baseColumn =
      (originalColumns.find((r) => r.id === rowData.id) || {}).customColumn ||
      "";

    // Revert note back in grid
    const updatedData = [...data];
    updatedData[rowIndex].customColumn = baseColumn;
    setData(updatedData);
  };

  const submitColumn = (o: any) => {
    const { rowData, rowIndex }: { rowIndex: number; rowData: SummaryGrant } =
      o.columnProps;

    // If the note has not changed, don't re-save
    const baseColumn = originalColumns.find((r) => r.id === rowData.id);

    const currentCustomValue = updatedNotes[rowIndex].customColumn;

    const isColumnUnmodified =
      baseColumn && baseColumn.customColumn === currentCustomValue;
    if (isColumnUnmodified || currentCustomValue === null) {
      return;
    }

    if (!isPipelineOwner) {
      return;
    }

    // Send update
    axios
      .post(
        getFullUrl(`/api/pipeline/${id}/grant/customcolumn`, {
          useDedicatedEnvironment: true,
        }),
        { grantId: rowData.id, value: currentCustomValue },
        createRequestWithAuthHeaders(context),
      )
      .then(() => {
        // Update in-memory notes to new note
        if (baseColumn) {
          const updatedColumns = [...originalColumns];
          const updatedColumn = updatedColumns.find(
            (r) => r.id === baseColumn.id,
          );
          if (updatedColumn) updatedColumn.customColumn = rowData.customColumn;
          setOriginalColumns(updatedColumns);
        }

        if (growl && growl.current)
          growl.current.show({
            severity: "success",
            summary: "Custom Column Updated",
            detail: `Finished updating custom column for '${rowData.opportunityNumber}'`,
          });
      })
      .catch(
        tryCatchServerError((message) => {
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          });
          cancelCustomColumn(o);
        }),
      );
  };

  const addRecordToMyList = (record: SummaryGrant) => {
    let request = createRequestWithAuthHeaders(context);

    axios
      .post(
        getFullUrl(`/api/grant/mylist/${record.id}`, {
          useDedicatedEnvironment: true,
        }),
        null,
        request,
      )
      .then(() => {
        // re-set my list status for all items with this id
        const newData = [...data];
        newData
          .filter((r: any) => r.id === record.id)
          .forEach((r: SummaryGrant) => (r.isMyList = true));
        setData(newData);
        growl.current.show({
          severity: "success",
          summary: "Added",
          detail: `Added grant '${record.opportunityNumber}' to my list`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          }),
        ),
      );
  };

  const removeRecordFromMyList = (record: SummaryGrant) => {
    let request = createAuthenticatedRequest(context);

    axios
      .delete(
        getFullUrl(`/api/pipeline/${id}/grant/${record.id}`, {
          useDedicatedEnvironment: true,
        }),
        request,
      )
      .then(() => {
        // re-set my list status for all items with this id
        // const newData = [...data];
        const newData = [...data.filter((r) => r.id !== record.id)];
        // update table pagination total
        setTotalRecords(newData.length);
        // newData
        //   .filter((r: any) => r.id === record.id)
        //   .forEach((r: SummaryGrant) => (r.isMyList = false));
        setData(newData);
        growl.current.show({
          severity: "success",
          summary: "Removed",
          detail: `Removed '${record.opportunityNumber}' from my list`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          }),
        ),
      );
  };

  const addMySuggestion = (record: SummaryGrant) => {
    axios
      .post(
        // add suggestion to pipeline
        // getFullUrl(`/api/grant/mylist/${record.id}`, {
        getFullUrl(`/api/pipeline/${id}/grant/${record.id}`, {
          useDedicatedEnvironment: true,
        }),
        null,
        createRequestWithAuthHeaders(context),
      )
      .then(() => {
        // remove this item from current list
        const newData = [...data].filter((x) => x.id !== record.id);
        setData(newData);
        if (decrement) decrement();
        if (setRefreshGrantsData) {
          setRefreshGrantsData(true);
          queryData({ first, rows: rowsPerPage, sortField, sortOrder });
        }
        growl.current.show({
          severity: "success",
          summary: "Added",
          detail: `Added grant '${record.opportunityNumber}' to my list`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          }),
        ),
      );
  };

  React.useEffect(() => {
    if (isRemove) {
      removeAllMySuggestions();
    }
  }, [isRemove]);

  const removeAllMySuggestions = () => {
    axios
      .delete(
        getFullUrl(
          id
            ? `/api/pipeline/${id}/grant/suggestion`
            : `/api/grant/mysuggestions`,
          {
            useDedicatedEnvironment: true,
          },
        ),
        createAuthenticatedRequest(context),
      )
      .then(() => {
        // remove this item from current list
        setTotalRecords(0);
        setRowsPerPage(0);
        setData([]);

        if (decrement) decrement();
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          }),
        ),
      );
  };

  const removeMySuggestion = (record: SummaryGrant) => {
    axios
      .delete(
        getFullUrl(`/api/pipeline/${id}/grant/suggestion/${record.id}`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      )
      .then(() => {
        // remove this item from current list
        const newData = [...data].filter((x) => x.id !== record.id);
        setTotalRecords(totalRecords - 1);
        setRowsPerPage(rowsPerPage - 1);
        setData(newData);

        if (decrement) decrement();
        growl.current.show({
          severity: "success",
          summary: "Ignored",
          detail: `Ignored grant '${record.opportunityNumber}'`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          }),
        ),
      );
  };

  const getDownloadUrlFromId = (id: number) =>
    `https://www.grants.gov/web/grants/view-opportunity.html?oppId=${id}`;
  const ActionsButtonColumn = ({ record }: { record: SummaryGrant }) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <NavigateToButton
          openPage={() => {
            window.open(getDownloadUrlFromId(record.id), "_blank");
          }}
        />
      </div>
    );
  };

  const ActionsButtonColumnPipeline = ({
    record,
  }: {
    record: SummaryGrant;
  }) => {
    if (!isMyGrants) {
      const remove = (
        <ActionButtons.RemoveFromMyListButton
          remove={() => removeRecordFromMyList(record)}
        />
      );
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <NavigateToButton
            openPage={() => {
              window.open(getDownloadUrlFromId(record.id), "_blank");
            }}
          />

          {remove}
        </div>
      );
    } else {
      // MySuggestion page
      const add = (
        <ActionButtons.AddToMyListButton add={() => addMySuggestion(record)} />
      );
      const remove = (
        <ActionButtons.RemoveFromMyListButton
          remove={() => removeMySuggestion(record)}
        />
      );
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <NavigateToButton
            openPage={() => {
              window.open(getDownloadUrlFromId(record.id), "_blank");
            }}
          />
          {add}
          {remove}
        </div>
      );
    }
  };

  const showEditCustomColumnName = async () => {
    const { value: formValues } = await Swal.fire({
      title: `Edit Column Name`,
      html: `
          <input id="columnName" class="swal2-input" value="${customColumnName}"></input>`,
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [(document.getElementById("columnName") as any).value];
      },
    });

    if (!formValues || !formValues.length) return;

    const [newColumnName] = formValues;
    await axios
      .put(
        getFullUrl(`/api/pipeline/${id}/columnName`, {
          useDedicatedEnvironment: true,
        }),
        { columnName: newColumnName, type: "grant" },
        createAuthenticatedRequest(context),
      )
      .then(() => {
        setCustomColumnName(newColumnName);
      })
      .catch((e) => {
        console.error("error setting contract custom column name", e);
        growl.current.show({
          severity: "error",
          summary: "Error setting column name",
        });
      });
  };

  const notesHeader = (
    <>
      <span style={{ font: "14px Open Sans", verticalAlign: "middle" }}>
        <IconTooltip
          tooltipText={
            "Use your MyBids as a collaborative workspace with automatically saved Notes"
          }
        />
      </span>
      &nbsp;
      <span>Notes (*)</span>
    </>
  );

  const versionHeader = (
    <>
      <span style={{ font: "14px Open Sans", verticalAlign: "middle" }}>
        <IconTooltip
          tooltipText={
            "Version indicates how many changes have been made to the initial solicitation"
          }
        />
      </span>
      &nbsp;
      <span>Version</span>
    </>
  );

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState<string | JSX.Element>("");
  const [dialogVisible, setDialogVisible] = React.useState("");
  const [colunmType, setColumnType] = React.useState("");

  React.useEffect(() => {
    const onDescriptionClick = (event: any) => {
      if (!event.target.matches(".grants_description")) {
        return;
      }

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      setDialogHeader(container.attributes["data-title"].value);
      setDialogText(dialogText);
      setDialogVisible("Grants");
      setColumnType(container.id);
    };
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
      setColumnType("");
    };
  }, []);

  const recordDesriptionBody = (r: SummaryGrant) => (
    <p
      className="grants_description"
      data-title={r.opportunityTitle}
      data-full-abstract={r.record.description}
      dangerouslySetInnerHTML={{
        __html:
          r.record.description && r.record.description.length > 100
            ? `${removeHtmlTags(r.record.description).substring(0, 100)}...`
            : r.record.description,
      }}
      onClick={() => {
        setDialogHeader(removeHtmlTags(r.opportunityTitle));
        setDialogText(removeHtmlTags(r.record.description));
        setDialogVisible("Grants");
      }}
    ></p>
  );

  const recordApplicantTypeDescriptionBody = (r: SummaryGrant) => (
    <p
      className="grants_description"
      data-title={r.opportunityTitle}
      data-full-abstract={r.record.applicantTypeDescription}
      dangerouslySetInnerHTML={{
        __html:
          r.record.applicantTypeDescription &&
          r.record.applicantTypeDescription.length > 100
            ? `${removeHtmlTags(r.record.applicantTypeDescription).substring(
                0,
                100,
              )}...`
            : r.record.applicantTypeDescription,
      }}
      onClick={() => {
        setDialogHeader(removeHtmlTags(r.opportunityTitle));
        setDialogText(removeHtmlTags(r.record.applicantTypeDescription));
        setDialogVisible("Grants");
      }}
    ></p>
  );

  const pipelineRowTemplate = (r: any) => {
    const p = pipelineForcasts.filter((a) =>
      a.businessForcast.some((u: SummaryGrant) =>
        u.id.toString().includes(r.id),
      ),
    );
    const pipelines: any = [];
    for (let i = 0; i < p.length; i++) {
      pipelines.push(p[i].name);
    }
    const title = `Added to ${p.length} Pipelines`;
    return (
      <>
        <div>
          <span
            className="pipeline-actions"
            onClick={(e: React.SyntheticEvent<Element, Event>) =>
              addToMyPipeline(r, e)
            }
          >
            <img className="pipeline-button" src={plusButton} alt="" />
            <p
              className="grants_description"
              data-full-abstract={pipelines}
              data-title={title}
              id="pipeline-popover"
              style={{
                textDecoration: "underline",
                paddingLeft: "4px",
                fontWeight: 400,
              }}
              // style={{textDecoration:"underline"}}
              onClick={(e) => {
                e.stopPropagation(); // stop propagation during the bubbling event.
                setDialogHeader(title);
                setDialogText(pipelines);
                setDialogVisible("Grants");
                setColumnType("pipeline-popover");
              }}
            >
              {p.length == 0 ? `Add to Pipeline` : `${p.length} Pipelines`}
            </p>
          </span>
        </div>
      </>
    );
  };

  // Share opportunity

  const isPlus = isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const isPro = isUserInRoles(context, UserRoleGroups.proOrHigher);
  const canShare = isPlus || isPro;

  const shareColumnHeader = (
    <>
      <span style={{ verticalAlign: "middle" }}>
        <IconTooltip tooltipText={"You can share opportunity via email"} />
      </span>
      &nbsp;
    </>
  );

  const actionColumnHeader = (
    <>
      <span style={{ verticalAlign: "middle" }}>
        <IconTooltip
          tooltipText={"Click the arrow to download available attachments"}
        />
      </span>
      &nbsp;
    </>
  );

  const [shareId, setShareId] = React.useState(0);
  const [shareUsers, setShareUsers] = React.useState<ShareUser[]>([]);
  const [shareExternalUser, setShareExternalUser] = React.useState<string>("");
  const [shareExternalUsers, setShareExternalUsers] = React.useState<string[]>(
    [],
  );
  const [shareMessageText, setShareMessageText] = React.useState("");

  const handleAddExternalUser = () => {
    if (!!shareExternalUser) {
      const isValid = validateEmailSimple(shareExternalUser);
      if (isValid) {
        setShareExternalUsers([...shareExternalUsers, shareExternalUser]);
        setShareExternalUser("");
      } else {
        growl.current.show({
          severity: "error",
          summary: "Invalid email address",
        });
      }
    }
  };

  const removeExternalShareUser = (user: string) => {
    setShareExternalUsers(
      shareExternalUsers.filter((externalUser) => user !== externalUser),
    );
  };
  const removeShareUser = (userId: string) => {
    setShareUsers(shareUsers.filter((user) => user.publicUserId !== userId));
  };

  const usersEmails = React.useMemo(() => {
    if (!!shareUsers) {
      return shareUsers.map((user) => user.email).concat(shareExternalUsers);
    }

    return shareExternalUsers;
  }, [shareUsers, shareExternalUsers]);

  const handleShare = () => {
    if (shareUsers.length === 0 && shareExternalUsers.length === 0) {
      growl.current.show({
        severity: "error",
        summary: "Please specify at least one recipient",
      });
      return;
    }

    if (shareMessageText.length === 0) {
      growl.current.show({
        severity: "error",
        summary: "Message field is empty",
      });
      return;
    }

    if (!!shareUsers || !!shareExternalUsers) {
      const sharePromises = usersEmails.map((email) => {
        const body = {
          toAdderess: email,
          opportunityId: shareId,
          recordType: "G",
          message: `${shareMessageText.replaceAll("\n", "<br>")}`,
        };

        return new Promise((resolve, reject) =>
          axios
            .post(
              getFullUrl("/api/pipeline/share", {
                useDedicatedEnvironment: true,
              }),
              body,
              createRequestWithAuthHeaders(context),
            )
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              reject();
            }),
        );
      });

      Promise.all(sharePromises)
        .then(() => {
          if (growl.current) {
            growl.current.show({
              severity: "success",
              summary: "Opportunity shared successfully",
            });
          }
        })
        .catch(() => {
          if (growl.current) {
            growl.current.show({
              severity: "error",
              summary: "Unable to share opportunity",
            });
          }
        });
    }

    sharePopup.current?.hide();
  };
  const [companyUsers, setCompanyUsers] = React.useState<ShareUser[]>([]);

  React.useEffect(() => {
    axios
      .get(
        getFullUrl("/api/organization/user", { useDedicatedEnvironment: true }),
        createAuthenticatedRequest(context),
      )
      .then((response) => {
        const { data } = response;
        const emailId = context.user.parsedIdToken?.email;
        if (emailId) {
          // var id = email[0]+ "@" + email[1]
          const orgUsers = data.filter(
            (r: ShareUser) => r.email?.toLocaleLowerCase() !== emailId,
          );
          setCompanyUsers(orgUsers);
        } else {
          setCompanyUsers(data);
        }
      })
      .catch((e) => {
        console.error(e);
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: "Unable to load users",
          });
        }
      });
  }, []);

  const sharePopup = useRef<OverlayPanel>(null);
  const ShareButton = (e: SummaryGrant) => {
    return (
      <div>
        <button
          className="share-btn"
          onClick={() => {
            setShareId(e.id);
            setShareUsers([]);
            setShareExternalUser("");
            setShareExternalUsers([]);
            setShareMessageText("");
            sharePopup.current?.toggle(
              e as unknown as React.SyntheticEvent<Element, Event>,
            );
          }}
        >
          <svg
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 4.60063L9.23147 0V2.20024C7.21594 2.38887 0.0446832 3.64493 0 12C0 12 1.73858 6.73656 9.23147 6.54326V9.20148L15 4.60063Z"
              fill="white"
            />
          </svg>
          <span>Share</span>
        </button>
      </div>
    );
  };

  return (
    <div className="p-grid p-dir-col">
      <Popover
        dialogHeader={removeHtmlTags(dialogHeader)}
        isVisible={dialogVisible === "Grants"}
        dialogText={dialogText}
        setMultiDialogVisible={setDialogVisible}
        multi={true}
        colunmType={colunmType}
      />

      <Popover
        dialogHeader={removeHtmlTags(dialogHeader)}
        isVisible={dialogVisible === "fundingActivityDescription"}
        dialogText={dialogText}
        setMultiDialogVisible={setDialogVisible}
        multi={true}
      />

      <OverlayerWindow>
        <OverlayPanel
          ref={sharePopup}
          id="pipeline-overlay"
          style={{ width: "500px", height: "100%", display: "flex !important" }}
          className="contract-panel share"
        >
          <div
            style={{ width: "500px", height: "100%", overflowY: "auto" }}
            className="share-overlay-height"
          >
            <div className="share-modal-header">
              Share opportunity
              <i
                onClick={() => sharePopup.current?.hide()}
                className="pi pi-times mr-2"
                style={{ fontSize: 20, cursor: "pointer" }}
              ></i>
            </div>
            <div className="share-modal-body">
              <div>
                <p>Recipients</p>
                <p className="email-option">External users</p>
                <div className="external-users-input">
                  <InputText
                    value={shareExternalUser}
                    onChange={(e) => {
                      setShareExternalUser(
                        (e.target as HTMLInputElement).value,
                      );
                    }}
                  />
                  <button
                    type="button"
                    className="add-btn aid-blu-btn"
                    onClick={handleAddExternalUser}
                  >
                    +
                  </button>
                </div>
                <div
                  className="share-users-list"
                  style={{
                    marginBottom: !!shareExternalUsers.length ? 10 : 0,
                  }}
                >
                  {!!shareExternalUsers.length &&
                    shareExternalUsers.map((user, i) => (
                      <Chip
                        key={i}
                        className="share-chip"
                        label={user}
                        onIconClick={() => removeExternalShareUser(user)}
                      />
                    ))}
                </div>
                <p className="email-option">Company users</p>
                <MultiSelect
                  optionLabel="email"
                  filter={true}
                  className="share-multiselect"
                  style={customStyle.multiSelect}
                  placeholder={shareUsers.length + " User(s) selected"}
                  fixedPlaceholder
                  value={shareUsers}
                  itemTemplate={(item: ShareUser) => (
                    <div className="share-multiselect-item">
                      {(item.firstName || item.lastName) && (
                        <p>{item.firstName + " " + item.lastName}</p>
                      )}
                      <p>{item.email}</p>
                    </div>
                  )}
                  options={companyUsers}
                  onChange={(e) => setShareUsers(e.value)}
                />
                <div className="share-users-list">
                  {!!shareUsers.length &&
                    shareUsers.map((user, i) => (
                      <Chip
                        key={i}
                        className="share-chip"
                        label={
                          user.firstName || user.lastName
                            ? Capitalize(user.firstName ?? "") +
                              " " +
                              Capitalize(user.lastName ?? "")
                            : user.email
                        }
                        onIconClick={() => removeShareUser(user.publicUserId)}
                      />
                    ))}
                </div>
              </div>
              <div>
                <p style={{ marginTop: 10 }}>Message</p>
                <textarea
                  className="share-message-text"
                  value={shareMessageText}
                  onChange={(e) => setShareMessageText(e.currentTarget.value)}
                />
              </div>
              <div className="share-footer">
                <button onClick={() => sharePopup.current?.hide()}>
                  Cancel
                </button>
                <button onClick={handleShare}>Share</button>
              </div>
            </div>
          </div>
        </OverlayPanel>
      </OverlayerWindow>

      <OverlayerWindow>
        <OverlayPanel
          ref={popupMenu}
          id="pipeline-overlay"
          style={{ width: "390px", display: "flex !important" }}
          className="side-panel-forecast"
        >
          <div style={{ width: "335px", height: "auto" }}>
            <div className="side-panel-header-forecast">
              Add opportunity to Pipeline
              <i
                onClick={closeModal}
                className="pi pi-times mr-2"
                style={{ fontSize: 20, cursor: "pointer" }}
              ></i>
            </div>
            <div className="side-panel-content">
              <div className="p-grid">
                <div
                  className="p-col-12"
                  style={{ display: "grid" }}
                  id="long-text"
                >
                  <label
                    className="field-titles-forecast"
                    htmlFor=" Opportunity"
                  >
                    Opportunity Title
                  </label>
                  <InputText
                    style={{
                      border: "none",
                      fontWeight: 700,
                      color: "#000",
                      opacity: 1,
                    }}
                    disabled={true}
                    placeholder="Opportunity"
                    className="field-titles-forecast"
                    defaultValue={rowData ? rowData.opportunityTitle : ""}
                  />
                </div>
                <div
                  className="p-col-7"
                  style={{ marginTop: "-16px" }}
                  id="long-text"
                >
                  <label className="field-titles-forecast" htmlFor="country">
                    Contry/Office
                  </label>
                  <InputText
                    style={{
                      border: "none",
                      fontWeight: 700,
                      width: "22rem",
                      color: "#000",
                      opacity: 1,
                    }}
                    disabled={true}
                    type="text"
                    placeholder="country"
                    className="field-titles-forecast"
                    defaultValue={rowData ? rowData.record.agencyName : ""}
                  />
                </div>
                <div className="p-col-7" style={{ marginTop: "-16px" }}>
                  <label
                    className="field-titles-forecast"
                    htmlFor="Created date"
                  >
                    Created at
                  </label>
                  <InputText
                    style={{
                      border: "none",
                      fontWeight: 700,
                      color: "#000",
                      opacity: 1,
                    }}
                    disabled={true}
                    type="text"
                    placeholder="Created date"
                    className="field-titles-forecast "
                    defaultValue={formatDate(
                      rowData?.lastUpdatedDateIn as string,
                      DateFormat.dd_MMMM_yyyy,
                    )?.toString()}
                  />
                </div>
                <div className="p-col-12" style={{ marginTop: "-16px" }}>
                  <label className="field-titles-forecast" htmlFor="email">
                    Pipelines
                  </label>
                  <MultiSelect
                    optionLabel="name"
                    filter={true}
                    style={customStyle.multiSelect}
                    placeholder={
                      selectedPipelines.length + " Pipeline(s) Selected"
                    }
                    fixedPlaceholder
                    value={selectedPipelines}
                    options={pipeline}
                    // itemTemplate={userTemplate}
                    onChange={(e) => selectPipeline(e.value)}
                  />

                  <div style={customStyle.chipsContainer}>
                    {selectedPipelines.map((sa) => (
                      <Chip
                        key={sa.id}
                        label={sa.name}
                        onIconClick={() =>
                          unTrackOpportunity(sa, selectedPipelines)
                        }
                      />
                    ))}
                  </div>
                  {/* <Dropdown optionLabel="name" options={pipeline} className='field-inputs'  placeholder="3 Pipelines Selected " /> */}
                </div>
                <div
                  className="p-col-12"
                  style={{ display: "flex", justifyContent: "right" }}
                >
                  <button
                    onClick={closeModal}
                    style={{ marginRight: "6px" }}
                    className="modal-button"
                  >
                    Close
                  </button>
                  {toggleAddPipeline && (
                    <button onClick={TrackOpportunity} className="modal-button">
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </OverlayPanel>
      </OverlayerWindow>
      <DataTable
        value={data}
        style={{ marginTop: 20, width: `${gridWidth - 60}px` }}
        paginator={true}
        rows={rowsPerPage}
        totalRecords={totalRecords}
        lazy={true}
        first={first}
        onPage={queryData}
        loading={isLoading}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={sort}
        scrollable={true}
        scrollHeight="400px"
        header={tableHeader}
        expandedRows={expandedRows}
        onRowToggle={(e: any) => setExpandedRows(e.data)}
        rowExpansionTemplate={(d: SummaryGrant) => (
          <RecordExpandedView record={d} fileKeyword={fileKeyword} />
        )}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rowsPerPageOptions={rowsPerPageOptionsStandard}
      >
        {!isMyGrants && (
          <Column
            headerStyle={styles.headerStyle(50)}
            header={actionColumnHeader}
            style={styles.columnStyle(50)}
            expander
          />
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          120,
          (x) =>
            isPipelineScreen && !isPipelineOwner ? null : isPipelineScreen ? (
              <ActionsButtonColumnPipeline record={x} />
            ) : (
              <ActionsButtonColumn record={x} />
            ),
          // (x)=> <ActionsButtonColumnPipeline record={x} />,
          isPipelineScreen && !isPipelineOwner
            ? null
            : isPipelineScreen
            ? "Actions"
            : "Grants.gov link",
          "id",
          { sortable: false },
        )}
        {canShare && (
          <Column
            headerStyle={styles.headerStyle(150)}
            style={styles.columnStyle(150)}
            bodyClassName="share"
            header={shareColumnHeader}
            field="share"
            body={ShareButton}
          />
        )}
        {!hideNotes && (
          <Column
            field={notesCollapsed ? "" : "notes"}
            header={
              <div>
                {notesCollapsed && (
                  <div>
                    <PButton
                      icon="pi pi-chevron-right"
                      onClick={() => setNotesCollapsed(false)}
                    />
                  </div>
                )}
                {!notesCollapsed && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ alignSelf: "center", marginRight: 10 }}>
                      Notes
                    </div>
                    <PButton
                      icon="pi pi-chevron-left"
                      onClick={() => setNotesCollapsed(true)}
                    />
                  </div>
                )}
              </div>
            }
            headerStyle={{
              ...styles.headerStyle(notesCollapsed ? 50 : 350),
              ...styles.primeColumnStyle,
            }}
            body={(r: any) => (
              <ShowMoreWhenHover
                text={r.notes ?? ""}
                showMoreInPopup={displayMoreTextPopup}
              />
            )}
            bodyClassName="highlightedcolumn"
            style={styles.columnStyle(notesCollapsed ? 50 : 350)}
            editor={notesCollapsed ? undefined : NotesEditor}
            sortable={true}
            onEditorSubmit={submitNote}
            onEditorCancel={cancelNote}
            onEditorInit={() => {
              setVisibleTooltip(false);
            }}
            editorValidator={(e) => {
              const element = e.originalEvent.srcElement as EventTarget & {
                className: string;
              };
              e.originalEvent.stopImmediatePropagation();
              return element ? element.className !== `notesTextarea` : true;
            }}
          />
        )}
        {!hideNotes && (
          <Column
            field={customColumnCollapsed ? "" : "customColumn"}
            header={
              <div>
                {customColumnCollapsed && (
                  <div>
                    <PButton
                      icon="pi pi-chevron-right"
                      onClick={() => setCustomColumnCollapsed(false)}
                    />
                  </div>
                )}
                {!customColumnCollapsed && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ alignSelf: "center", marginRight: 10 }}>
                      {customColumnName}
                    </div>
                    <PButton
                      icon="pi pi-chevron-left"
                      style={{ marginRight: 10 }}
                      onClick={() => setCustomColumnCollapsed(true)}
                    />
                    <PButton
                      icon="pi pi-pencil"
                      onClick={showEditCustomColumnName}
                    />
                  </div>
                )}
              </div>
            }
            headerStyle={{
              ...styles.headerStyle(customColumnCollapsed ? 50 : 350),
              ...styles.primeColumnStyle,
            }}
            body={(r: any) => (
              <ShowMoreWhenHover
                text={r.customColumn ?? ""}
                showMoreInPopup={displayMoreTextPopup}
              />
            )}
            style={styles.columnStyle(customColumnCollapsed ? 50 : 350)}
            editor={customColumnCollapsed ? undefined : ColumnEditor}
            sortable={true}
            onEditorSubmit={submitColumn}
            onEditorCancel={cancelColumn}
            onEditorInit={() => {
              setVisibleTooltip(false);
            }}
            editorValidator={(e) => {
              const element = e.originalEvent.srcElement as EventTarget & {
                className: string;
              };
              e.originalEvent.stopImmediatePropagation();
              return element
                ? element.className !==
                    "p-inputtext p-component customColumn p-filled"
                : true;
            }}
          />
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          150,
          (x) => x.topLevelAgency,
          "Agency",
          "topLevelAgency",
        )}

        {history.location.pathname === path ? (
          <Column
            header="Pipeline"
            field="topLevelAgency"
            body={pipelineRowTemplate}
            headerStyle={styles.headerStyle(150)}
            style={styles.columnStyle(40)}
          />
        ) : null}

        {/* {GetCustomColumnShortened<SummaryGrant>(
          styles,
          180,
          pipelineRowTemplate,
          (x) => x.record.agencyName,
          "Pipelines",
          100,
          true,
          "agencyName"
        )} */}

        {GetCustomColumnBody<SummaryGrant>(
          styles,
          250,
          (x) => x.record.agencyName,
          "Mission / Bureau",
          "agencyName",
        )}
        {GetCustomColumnShortened<SummaryGrant>(
          styles,
          250,
          (x) => removeHtmlTags(x.opportunityTitle),
          () => "Opportunity Title",
          "Opportunity Title",
          250,
          true,
          "opportunityTitle",
        )}
        {GetCustomColumnShortened<SummaryGrant>(
          styles,
          550,
          recordDesriptionBody,
          (x) => removeHtmlTags(x.opportunityTitle).replaceAll("&ndash;", "–"),
          "Description",
          100,
          true,
          "id",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          150,
          (x) => x.record.status,
          "Opportunity Status",
          "status",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          200,
          (x) =>
            typeof x.manualLastUpdatedDate === "string"
              ? format(
                  getNewDateNoTimezoneAdjustment(x.manualLastUpdatedDate),
                  "dd MMMM yyyy",
                )
              : null,
          // {
          //   if (x.lastUpdatedDateIn) {
          //     const date = new Date(x.lastUpdatedDateIn);
          //     return new Date(
          //       Date.UTC(
          //         date.getUTCFullYear(),
          //         date.getUTCMonth(),
          //         date.getUTCDate(),
          //         0,
          //         0,
          //         0,
          //       ),
          //     ).toString(DateFormat.dd_MMMM_yyyy);
          //   } else {
          //     return "";
          //   }
          // },
          "Last Updated Date",
          "manualLastUpdatedDate",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          250,
          (x) => x.opportunityNumber,
          "Opportunity Number",
          "opportunityNumber",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          150,
          (x) => x.record.fundingInstrumentDescription,
          "Funding Instrument Type",
          "fundingInstrumentDescription",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          150,
          (x) => x.opportunityCategoryDescription,
          "Opportunity Category",
          "opportunityCategoryDescription",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          350,
          (x) =>
            x.record.fundingActivityDescription.length > 100 ? (
              <p
                data-title={x.opportunityTitle}
                data-full-abstract={x.record.fundingActivityDescription}
                onClick={() => {
                  setDialogHeader(removeHtmlTags(x.opportunityTitle));
                  setDialogText(
                    removeHtmlTags(x.record.fundingActivityDescription),
                  );
                  setDialogVisible("fundingActivityDescription");
                }}
              >
                {`${removeHtmlTags(
                  x.record.fundingActivityDescription,
                ).substring(0, 100)}...`}
              </p>
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: x.record.fundingActivityDescription,
                }}
              ></span>
            ),
          "Category of Funding Activity",
          "fundingActivityDescription",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          200,
          (x) => x.record.numberOfAwards,
          "Expected Number of Awards",
          "numberOfAwards",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          350,
          (x) => x.cfdas.map((r) => <p key={r}>{r}</p>),
          "CFDA Number(s)",
          "cfdas",
          { sortable: false },
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          250,
          (x) => (x.record.costSharing ? "YES" : "NO"),
          "Cost Sharing or Matching Requirement",
          "costSharing",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          100,
          (x) => x.record.version,
          versionHeader,
          "version",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          250,
          (x) =>
            typeof x.originalDueDate === "string"
              ? format(
                  getNewDateNoTimezoneAdjustment(x.originalDueDate),
                  "dd MMMM yyyy",
                )
              : null, //formatDate(x.originalDueDate, DateFormat.dd_MMMM_yyyy),
          "Original Closing Date for Applications",
          "originalDueDate",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          250,
          (x) =>
            typeof x.record.responseDate === "string"
              ? format(
                  getNewDateNoTimezoneAdjustment(x.record.responseDate),
                  "dd MMMM yyyy",
                )
              : null, //formatDate(x.record.responseDate, DateFormat.dd_MMMM_yyyy),
          "Current Closing Date for Applications",
          "responseDate",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          250,
          (x) =>
            typeof x.record.postingDate === "string"
              ? format(
                  getNewDateNoTimezoneAdjustment(x.record.postingDate),
                  "dd MMMM yyyy",
                )
              : null, //formatDate(x.record.postingDate, DateFormat.dd_MMMM_yyyy),
          "Posting Date",
          "postingDate",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          200,
          (x) =>
            typeof x.record.archiveDate === "string"
              ? format(
                  getNewDateNoTimezoneAdjustment(x.record.archiveDate),
                  "dd MMMM yyyy",
                )
              : null, //formatDate(x.record.archiveDate, DateFormat.dd_MMMM_yyyy),
          "Archive Date",
          "archiveDate",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          250,
          (x) =>
            getEmptyStringWithCurrencyIfNull(
              x.record.estimatedFundingFormatted,
            ),
          "Estimated Total Program Funding",
          "estimatedFundingFormatted",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          150,
          (x) =>
            getEmptyStringWithCurrencyIfNull(x.record.awardCeilingFormatted),
          "Award Ceiling",
          "awardCeilingFormatted",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          150,
          (x) => getEmptyStringWithCurrencyIfNull(x.record.awardFloorFormatted),
          "Award Floor",
          "awardFloorFormatted",
        )}
        {GetCustomColumnShortened<SummaryGrant>(
          styles,
          650,
          recordApplicantTypeDescriptionBody,
          (x) =>
            removeHtmlTags(x.record.applicantTypeDescription).replace(
              "&ndash;",
              "–",
            ),
          "Eligible Applicants",
          100,
          false,
          "applicantTypeDescription",
        )}
        {GetCustomColumnShortened<SummaryGrant>(
          styles,
          550,
          (x) => x.record.applicantEligibilityDescription,
          (x) => x.opportunityTitle,
          "Additional Information on Eligibility",
          100,
          true,
          "applicantEligibilityDescription",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          250,
          (x) =>
            x.record.agencyContactName && (
              <p
                className="quarterlyCallNotes"
                data-full-abstract={x.record.agencyContactName}
              >
                {x.record.agencyContactName &&
                x.record.agencyContactName.length > 100
                  ? `${x.record.agencyContactName.substring(0, 100)}...`
                  : x.record.agencyContactName}
              </p>
            ),
          "Contact Name",
          "agencyContactName",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          450,
          (x) =>
            x.record.agencyContactDescription
              ? x.record.agencyContactDescription
                  .replace(/\&nbsp;/g, "")
                  .replace(/&amp;/g, "&")
                  .replace(/&ntilde;/g, "ñ")
              : "",
          "Contact Description",
          "agencyContactDescription",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          200,
          (x) => x.record.agencyContactEmail,
          "Contact Email",
          "agencyContactEmail",
        )}
        {GetCustomColumnBody<SummaryGrant>(
          styles,
          200,
          (x) => x.record.agencyContactPhone,
          "Contact Phone",
          "agencyContactPhone",
        )}
      </DataTable>

      <div
        ref={popupRef}
        onMouseOver={() => {
          setVisibleTooltip(true);
        }}
        style={{ top: bottomOffset ?? "500px", left: leftOffset ?? "400px" }}
        onMouseLeave={() => {
          setVisibleTooltip(false);
        }}
        className={
          visibleTooltip ? "element-tooltip visible" : "element-tooltip hidden"
        }
      >
        <div>
          <p className="tooltip-body">{selectedTextToShow}</p>
        </div>
      </div>
    </div>
  );
};

export const GrantsTableOnly: React.FunctionComponent<{
  id: number;
  isPipelineScreen?: boolean;
  isPipelineOwner?: boolean;
  refreshGrantsData: boolean;
  setRefreshGrantsData: (value: boolean) => void;
}> = ({
  id,
  isPipelineScreen,
  isPipelineOwner,
  refreshGrantsData,
  setRefreshGrantsData,
}) => {
  const context = React.useContext(UserContext);
  // temporary
  const history = useHistory();
  const path = history.location.pathname;
  const [baseUrl, setBaseUrl] = React.useState("");

  // Page info
  const [gridWidth, setGridWidth] = React.useState(0);
  React.useEffect(() => {
    function handleResize() {
      const { nestedWidth: width, headerWidth } = getWindowDimensions();
      if (width && headerWidth != null) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Grid required properties
  const [isLoading, setIsLoading] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [sortField, setSortField] = React.useState("postingDate");
  const [sortOrder, setSortOrder] = React.useState(-1);
  const [first, setFirst] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [keyword, setKeyword] = React.useState("");

  const [lastUpdated, setLastUpdated] = React.useState("");
  const [data, setData] = React.useState(Array<SummaryGrant>());

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState<string | JSX.Element>("");
  const [dialogVisible, setDialogVisible] = React.useState(false);

  const [originalNotes, setOriginalNotes] = React.useState(
    Array<{ id: number; note: string }>(),
  );
  const [originalColumns, setOriginalColumns] = React.useState(
    Array<{ id: number; customColumn: string }>(),
  );

  const [customColumnName, setCustomColumnName] = React.useState("");
  const [query, setQuery] = React.useState<URLSearchParams>();

  React.useEffect(() => {
    const onDescriptionClick = (event: any) => {
      if (!event.target.matches(".abstract")) {
        return;
      }

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      setDialogHeader(container.attributes["data-title"].value);
      setDialogText(dialogText);
      setDialogVisible(true);
    };
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
    };
  }, []);

  // Load data on page load
  React.useEffect(() => {
    queryData({ first, rows: rowsPerPage, sortField, sortOrder });
  }, []);

  useEffect(() => {
    if (refreshGrantsData) {
      setRefreshGrantsData(false);
      queryData({ first, rows: rowsPerPage, sortField, sortOrder });
    }
  }, [refreshGrantsData]);

  const debouncedSearch = useDebounce(keyword, 750);
  React.useEffect(() => {
    queryData({
      first,
      rows: rowsPerPage,
      sortField,
      sortOrder,
      keyword,
    });
  }, [debouncedSearch]);

  function queryData(event: {
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
    keyword?: string;
  }) {
    setRowsPerPage(event.rows);

    setIsLoading(true);

    let url = "/api/grant";
    switch (path) {
      case `/pipeline/${id}`:
        url = `/api/pipeline/${id}/grant`;
        isPipelineScreen = true;
        setBaseUrl(url);
        break;
      default:
        setBaseUrl(url);
        break;
    }

    const queryString = new URLSearchParams();
    queryString.set("onlyMyList", true.toString());
    queryString.set("includeUsaid", true.toString());
    queryString.set("includeMcc", true.toString());
    queryString.set("includeStateDept", true.toString());

    setFirst(event.first);
    const pageIndex = event.first / rowsPerPage;
    queryString.set("pageIndex", pageIndex.toString());
    queryString.set("pageSize", event.rows.toString());

    queryString.set("sortField", event.sortField || sortField);
    queryString.set("sortOrder", (event.sortOrder || sortOrder).toString());

    const filterToUse = event.keyword !== undefined ? event.keyword : keyword;
    if (filterToUse) {
      queryString.set("filter", filterToUse);
    }

    setQuery(queryString);

    axios
      .get(
        getFullUrl(`${url}?${queryString.toString()}`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      )
      .then((response) => {
        const { totalItems, data, lastUpdatedUtc, attributes } = response.data;
        setTotalRecords(totalItems);
        setCustomColumnName(attributes ? attributes.CustomColumnName : "");

        // Set top records
        const d = data as Array<SummaryGrant>;
        d.forEach((r) => {
          if (!r.notes) {
            r.notes = "";
          }
        });
        setData(d);
        setOriginalNotes(d.map((r) => ({ id: r.id, note: r.notes })));
        setOriginalColumns(
          d.map((r) => ({
            id: r.id,
            customColumn: r.customColumn,
          })),
        );

        setLastUpdated(
          lastUpdatedUtc
            ? `Last updated ${getDateString(new Date(lastUpdatedUtc + "Z"))}`
            : "",
        );
        setIsLoading(false);
      })
      .catch((error) => {
        alert("error fetching data");
        setIsLoading(false);
      });
  }

  var getExcelData = async (): Promise<SummaryGrant[]> => {
    var base = baseUrl;
    var q = query;
    if (q) q.set("pageSize", "1000");
    var qu = q ? base + "?" + q.toString() : base;
    var result = Array<SummaryGrant>();
    await axios
      .get(
        getFullUrl(qu, { useDedicatedEnvironment: true }),
        createAuthenticatedRequest(context),
      )
      .then((response) => {
        result = response.data.data;
      })
      .catch((error) => {
        alert("error fetching data");
      });
    return result;
  };

  return (
    <div className="p-grid p-dir-col">
      <GrantsDataTable
        id={id}
        gridWidth={gridWidth}
        isLoading={isLoading}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        sortField={sortField}
        setSortField={setSortField}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        first={first}
        totalRecords={totalRecords}
        setTotalRecords={setTotalRecords}
        data={data}
        setData={setData}
        lastUpdated={lastUpdated}
        originalNotes={originalNotes}
        setOriginalNotes={setOriginalNotes}
        originalColumns={originalColumns}
        setOriginalColumns={setOriginalColumns}
        customColumnName={customColumnName}
        setCustomColumnName={setCustomColumnName}
        queryData={queryData}
        keyword={keyword}
        setKeyword={setKeyword}
        getExcelData={getExcelData}
        isPipelineScreen={isPipelineScreen}
        isPipelineOwner={isPipelineOwner}
      />
    </div>
  );
};

export const MySuggestionsGrants: React.FunctionComponent<{
  isPipelineOwner?: boolean;
  isMySuggestions?: boolean;
  decrement: () => void;
  isRemove: boolean;
  id: number;
  setRefreshGrantsData?: (value: boolean) => void;
}> = ({
  isPipelineOwner,
  isMySuggestions,
  decrement,
  isRemove,
  id,
  setRefreshGrantsData,
}) => {
  const context = React.useContext(UserContext);
  const history = useHistory();
  // Page info
  const [gridWidth, setGridWidth] = React.useState(0);
  React.useEffect(() => {
    function handleResize() {
      const { nestedWidth: width, headerWidth } = getWindowDimensions();
      if (width && headerWidth != null) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Grid required properties
  const [isLoading, setIsLoading] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [sortField, setSortField] = React.useState("lastUpdatedDate");
  const [sortOrder, setSortOrder] = React.useState(-1);
  const [first, setFirst] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);

  const [data, setData] = React.useState(Array<SummaryGrant>());
  const [baseUrl, setBaseUrl] = React.useState("");
  const [isPipelineScreen, setisPipelineScreen] = React.useState(false);

  const path = history.location.pathname;

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState<string | JSX.Element>("");
  const [dialogVisible, setDialogVisible] = React.useState(false);

  const [query, setQuery] = React.useState<URLSearchParams>();

  React.useEffect(() => {
    const onDescriptionClick = (event: any) => {
      if (!event.target.matches(".abstract")) {
        return;
      }

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      setDialogHeader(container.attributes["data-title"].value);
      setDialogText(dialogText);
      setDialogVisible(true);
    };
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
    };
  }, []);

  // Load data on page load
  React.useEffect(() => {
    queryData({ first, rows: rowsPerPage, sortField, sortOrder });
  }, []);

  function queryData(event: {
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
  }) {
    setRowsPerPage(event.rows);

    setIsLoading(true);
    let url = "/api/grant/mysuggestions";
    switch (path) {
      case `/pipeline/${id}`:
        url = `/api/pipeline/${id}/grant/suggestion`;
        setBaseUrl(url);
        setisPipelineScreen(true);
        break;
      case `/mybids`:
        setBaseUrl(url);
        setisPipelineScreen(false);
        break;
      default:
        break;
    }
    const queryString = new URLSearchParams();
    setFirst(event.first);
    const pageIndex = event.first / rowsPerPage;
    queryString.set("pageIndex", pageIndex.toString());
    queryString.set("pageSize", event.rows.toString());
    queryString.set("sortField", event.sortField || sortField);
    queryString.set("sortOrder", (event.sortOrder || sortOrder).toString());

    setQuery(queryString);

    axios
      .get(
        getFullUrl(`${url}?${queryString.toString()}`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      )
      .then((response) => {
        const { totalItems, data } = response.data;
        setTotalRecords(totalItems);

        // Set top records
        const d = data as Array<SummaryGrant>;
        setData(d);
        setIsLoading(false);
      })
      .catch((error) => {
        alert("Error fetching data");
        setIsLoading(false);
      });
  }

  var getExcelData = async (): Promise<SummaryGrant[]> => {
    var base = baseUrl;
    var q = query;
    if (q) q.set("pageSize", "1000");
    var qu = q ? base + "?" + q.toString() : base;
    var result = Array<SummaryGrant>();
    await axios
      .get(
        getFullUrl(qu, { useDedicatedEnvironment: true }),
        createAuthenticatedRequest(context),
      )
      .then((response) => {
        result = response.data.data;
      })
      .catch((error) => {
        alert("error fetching data");
      });
    return result;
  };

  return (
    <div className="p-grid p-dir-col">
      <GrantsDataTable
        id={id}
        gridWidth={gridWidth}
        isLoading={isLoading}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        sortField={sortField}
        setSortField={setSortField}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        first={first}
        totalRecords={totalRecords}
        setTotalRecords={setTotalRecords}
        data={data}
        setData={setData}
        lastUpdated={""}
        originalNotes={[]}
        setOriginalNotes={(r) => {}}
        originalColumns={[]}
        setOriginalColumns={(r) => {}}
        customColumnName={""}
        setCustomColumnName={(r) => {}}
        queryData={queryData}
        isMyGrants={true}
        decrement={decrement}
        hideNotes={true}
        getExcelData={getExcelData}
        isRemove={isRemove}
        isPipelineScreen={isPipelineScreen}
        isPipelineOwner={isPipelineOwner}
        isMySuggestions={isMySuggestions}
        setRefreshGrantsData={setRefreshGrantsData}
      />
    </div>
  );
};

const getEmptyStringWithCurrencyIfNull = (d: string | null) =>
  d === null ? "" : `$${d}`;

export default GrantsTableScreen;
