import React, { FC, useContext } from "react";
import Input, { DropdownOption } from "../../components/Widgets/Inputs/Input";
import { RootState, useDispatch, useSelector } from "../../store";
import {
  getCountryDropdownOptions,
  getOpportunityTypeDropdownOptions,
  getSetAsideDropdownOptions,
} from "../../slices/contracts";
import UserContext from "../../services/UserContext";

interface ContractsFiltersProps {
  control: any;
}

const ContractsFilters: FC<ContractsFiltersProps> = (props) => {
  const { control } = props;

  const dispatch = useDispatch();
  const context = useContext(UserContext);

  const {
    dropdownOptions: {
      country: { items: countryOptions, fetchStatus: countryFetchStatus },
      statuses: { items: statusOptions },
      setAsides: { items: setAsideOptions, fetchStatus: setAsidesFetchStatus },
      opportunityTypes: {
        items: opportunityTypesOptions,
        fetchStatus: opportunityTypesFetchStatus,
      },
    },
  } = useSelector((state: RootState) => state.contracts);

  return (
    <>
      <Input
        type={"text"}
        fullWidth
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"fileKeyword"}
        control={control}
        // defaultValue={null}
        label={"KEYWORD SEARCH IN-DOCUMENT TEXT"}
      />
      <Input
        type={"combo-box"}
        withCheckboxes
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        control={control}
        name={"country"}
        label={"COUNTRY / OFFICE"}
        options={countryOptions}
        // loading={loading}
        fullWidth
        multiple
        fetchStatus={countryFetchStatus}
        asyncCallback={(value: string) => {
          dispatch(
            getCountryDropdownOptions({
              context,
              filter: value,
            }),
          );
        }}
      />
      <Input
        type={"text"}
        fullWidth
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"fileName"}
        control={control}
        label={"FILE NAME"}
      />
      <Input
        type={"text"}
        fullWidth
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"solicitationNumber"}
        control={control}
        // defaultValue={null}
        label={"SOLICITATION NUMBER"}
      />
      <Input
        type={"combo-box"}
        withCheckboxes
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        control={control}
        // defaultValue={""}
        name={"setAsides"}
        label={"SET ASIDES"}
        options={setAsideOptions}
        // loading={loading}
        fullWidth
        multiple
        fetchStatus={setAsidesFetchStatus}
        asyncCallback={(value: string) => {
          dispatch(
            getSetAsideDropdownOptions({
              context,
              filter: value,
            }),
          );
        }}
      />
      <Input
        type={"combo-box"}
        // withCheckboxes
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        control={control}
        // defaultValue={""}
        name={"status"}
        label={"STATUS"}
        options={statusOptions}
        // loading={loading}
        fullWidth
        // multiple
      />
      <Input
        type={"combo-box"}
        withCheckboxes
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        control={control}
        // defaultValue={""}
        name={"opportunityType"}
        label={"OPPORTUNITY TYPES"}
        options={opportunityTypesOptions}
        // loading={loading}
        fullWidth
        multiple
        fetchStatus={opportunityTypesFetchStatus}
        asyncCallback={(value: string) => {
          dispatch(
            getOpportunityTypeDropdownOptions({
              context,
              filter: value,
            }),
          );
        }}
      />
      <Input
        type={"date"}
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"updatedBy"}
        control={control}
        // defaultValue={null}
        label={"SEARCH CONTRACTS+ UPDATES SINCE"}
      />
    </>
  );
};

export default ContractsFilters;
