import React, { FunctionComponent, useState } from "react";
import "react-quill/dist/quill.snow.css";
import "./ResourcesWidget.css";
import DashboardSection from "../DashboardSection";
import SectionHeader from "../SectionHeader";
import { Link } from "react-router-dom";
import UserContext, {
  UserRoleGroups,
  isUserInRoles,
  doesUserHaveRole,
  ServerOrgRoles,
} from "../../../services/UserContext";
import Button from "@mui/material/Button";
import UpgradePlanModal from "../../Modals/UpgradePlanModal";

const ResourcesWidget: FunctionComponent = () => {
  const context = React.useContext(UserContext);
  const isProUser = isUserInRoles(context, UserRoleGroups.proOrHigher);
  const isMember = doesUserHaveRole(context, ServerOrgRoles.organizationMember);
  const [upgradePlanModalOpen, setUpgradePlanModalOpen] =
    useState<boolean>(false);

  const blurLink = (text: string, route: string) => {
    if (isProUser) {
      return (
        <li>
          <Link to={route}>{text}</Link>
        </li>
      );
    } else {
      return (
        <li className="upgrade-link">
          <div className="blurred-text">{text}</div>

          <Link
            style={{ position: "absolute", alignSelf: "center" }}
            to="/tiers"
            className="upgrade-button"
          >
            Upgrade
          </Link>
        </li>
      );
    }
  };

  return (
    <DashboardSection>
      <SectionHeader text={"USAID Resources"} icon={false} />
      <UpgradePlanModal
        upgradePlanModalOpen={upgradePlanModalOpen}
        setUpgradePlanModalOpen={(val: boolean) => setUpgradePlanModalOpen(val)}
      />
      <div className="resource-list">
        {isProUser ? (
          <ul style={{ textAlign: "left" }}>
            {blurLink(
              "USAID Best Practices and General Information",
              "/resources",
            )}
            {blurLink("USG Local Compensation Plan Archive", "/lcp-archives")}
            {blurLink(
              "Initial Environmental Examination Archive",
              "/iee-archives",
            )}
          </ul>
        ) : (
          <Button
            variant={"secondaryContained"}
            sx={{
              my: 2,
            }}
            {...(isMember
              ? { onClick: () => setUpgradePlanModalOpen(true) }
              : {
                  component: "a",
                  href: "/tiers",
                  target: "_blank",
                })}
          >
            Upgrade your plan to see resources
          </Button>
        )}
      </div>
    </DashboardSection>
  );
};

export default ResourcesWidget;
