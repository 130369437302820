import { formatISO } from "date-fns";

export enum DateFormat {
  dd_MMMM_yyyy = "dd MMMM yyyy",
  dd_MMMM_yyyy_HH_mm = "dd MMMM yyyy HH:mm:ss",
  dd_MMMM_yyyy_hh_mm = "dd MMMM yyyy HH:mm",
}

export function safeParseDateWithTimezone(input: string | null): string | null {
  if (!input) {
    return input;
  }

  const idx = input.lastIndexOf(" ");
  if (idx === -1) {
    // already good - just return
    return input;
  }

  // there's a ' ' so date will look like "2024-09-29T00:00:00 00:00"
  return input.substring(0, idx);
}

export function formatDate(
  dateString: Date | string | null,
  format: string,
): string | null {
  if (dateString) {
    if ((dateString as any).toISOString) {
      const date = dateString as Date;
      return new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0),
      ).toString(format);
    } else {
      const str = safeParseDateWithTimezone(dateString.toString())!;
      const date = new Date(str);
      // sanity check - if min date "0001-01-01T00:00:00" then return N/A
      if (date && date.toISOString().startsWith("0")) {
        return "N/A";
      } else if (date) {
        return new Date(
          Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0,
            0,
            0,
          ),
        ).toString(format);
      }
    }
  }

  return dateString ? dateString.toString() : "";
}

export const getEpochDateWithoutTimezone = (
  date: Date | null,
): number | null => {
  let dateValue: number | null = 0;
  if (date !== null) {
    let _date: Date = new Date(0);
    const offset = _date.getTimezoneOffset() * 60000;
    const epoch = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0,
    );
    dateValue = epoch + offset;
  } else {
    dateValue = null;
  }

  return dateValue;
};

export const getISODateWithAdjustedTimezone = (
  date: Date,
  addHours: boolean = false,
  zeroOffset: boolean = true,
  timeOffset: string = "T00:00:00+00:00",
): string => {
  const str = formatISO(
    new Date(
      (getEpochDateWithoutTimezone(date) as number) + (addHours ? 36000000 : 0),
    ),
  );
  return zeroOffset ? str.split("T")[0] + timeOffset : str;
};

export const getNewDateNoTimezoneAdjustment = (date: string | number): Date => {
  return new Date(new Date(date).toISOString().slice(0, -1));
};
