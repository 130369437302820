import {
  initializeSettingValue,
  saveSettingValue,
  ServerSetting,
  updateSettingValue,
} from "../../reducers/DashboardReducer";
import { Weekday } from "../../../../utils/constants/time-constants";
import { NotificationRecipient } from "../../../../utils/constants/notification-recipients";
import { Type, Action } from "./actions";
import React from "react";

interface WeeklyEmailNotificationState {
  enabled: ServerSetting<boolean>;
  time: ServerSetting<string | undefined>;
  weekday: ServerSetting<Weekday | undefined>;
  recipient: ServerSetting<NotificationRecipient | undefined>;
}

interface DailyEmailNotificationState {
  enabled: ServerSetting<boolean>;
  time: ServerSetting<string | undefined>;
  recipient: ServerSetting<NotificationRecipient | undefined>;
}

interface ForecastAlertNotificationState {
  enabled: ServerSetting<boolean | undefined>;
  recipient: ServerSetting<NotificationRecipient | undefined>;
}

export interface EmailNotificationsState {
  weekly: WeeklyEmailNotificationState;
  daily: DailyEmailNotificationState;
  forecastAlert: ForecastAlertNotificationState;
}

export const createInitialEmailNotificationState = (
  weeklyEmailEnabled: boolean,
  weeklyEmailTime: string | undefined,
  weeklyEmailDay: Weekday | undefined,
  weeklyEmailRecipient: NotificationRecipient | undefined,
  dailyEmailEnabled: boolean,
  dailyEmailTime: string | undefined,
  dailyEmailRecipient: NotificationRecipient | undefined,
  forecastAlertEnabled: boolean,
  forecastAlertRecipient: NotificationRecipient | undefined
): EmailNotificationsState => ({
  weekly: {
    enabled: initializeSettingValue(weeklyEmailEnabled),
    time: initializeSettingValue(weeklyEmailTime),
    weekday: initializeSettingValue(weeklyEmailDay),
    recipient: initializeSettingValue(weeklyEmailRecipient),
  },
  daily: {
    enabled: initializeSettingValue(dailyEmailEnabled),
    time: initializeSettingValue(dailyEmailTime),
    recipient: initializeSettingValue(dailyEmailRecipient),
  },
  forecastAlert: {
    enabled: initializeSettingValue(forecastAlertEnabled),
    recipient: initializeSettingValue(forecastAlertRecipient),
  },
});

const reducer = (
  state: EmailNotificationsState,
  action: Action
): EmailNotificationsState => {
  switch (action.type) {
    case Type.SET_WEEKLY_EMAIL_ENABLED:
      return {
        ...state,
        weekly: {
          ...state.weekly,
          enabled: updateSettingValue(state.weekly.enabled, action.value),
        },
      };
    case Type.SAVE_WEEKLY_EMAIL_ENABLED:
      return {
        ...state,
        weekly: {
          ...state.weekly,
          enabled: saveSettingValue(state.weekly.enabled),
        },
      };
    case Type.SET_WEEKLY_EMAIL_TIME:
      return {
        ...state,
        weekly: {
          ...state.weekly,
          time: updateSettingValue(state.weekly.time, action.value),
        },
      };
    case Type.SAVE_WEEKLY_EMAIL_TIME:
      return {
        ...state,
        weekly: {
          ...state.weekly,
          time: saveSettingValue(state.weekly.time),
        },
      };
    case Type.SET_WEEKLY_EMAIL_DAY:
      return {
        ...state,
        weekly: {
          ...state.weekly,
          weekday: updateSettingValue(state.weekly.weekday, action.value),
        },
      };
    case Type.SAVE_WEEKLY_EMAIL_DAY:
      return {
        ...state,
        weekly: {
          ...state.weekly,
          weekday: saveSettingValue(state.weekly.weekday),
        },
      };
    case Type.SET_WEEKLY_EMAIL_RECIPIENT:
      return {
        ...state,
        weekly: {
          ...state.weekly,
          recipient: updateSettingValue(state.weekly.recipient, action.value),
        },
      };
    case Type.SAVE_WEEKLY_EMAIL_RECIPIENT:
      return {
        ...state,
        weekly: {
          ...state.weekly,
          recipient: saveSettingValue(state.weekly.recipient),
        },
      };
    case Type.SET_DAILY_EMAIL_ENABLED:
      return {
        ...state,
        daily: {
          ...state.daily,
          enabled: updateSettingValue(state.daily.enabled, action.value),
        },
      };
    case Type.SAVE_DAILY_EMAIL_ENABLED:
      return {
        ...state,
        daily: {
          ...state.daily,
          enabled: saveSettingValue(state.daily.enabled),
        },
      };
    case Type.SET_DAILY_EMAIL_TIME:
      return {
        ...state,
        daily: {
          ...state.daily,
          time: updateSettingValue(state.daily.time, action.value),
        },
      };
    case Type.SAVE_DAILY_EMAIL_TIME:
      return {
        ...state,
        daily: {
          ...state.daily,
          time: saveSettingValue(state.daily.time),
        },
      };
    case Type.SET_DAILY_EMAIL_RECIPIENT:
      return {
        ...state,
        daily: {
          ...state.daily,
          recipient: updateSettingValue(state.daily.recipient, action.value),
        },
      };
    case Type.SAVE_DAILY_EMAIL_RECIPIENT:
      return {
        ...state,
        daily: {
          ...state.daily,
          recipient: saveSettingValue(state.daily.recipient),
        },
      };
    case Type.SET_FORECAST_ALERT_ENABLED:
      return {
        ...state,
        forecastAlert: {
          ...state.forecastAlert,
          enabled: updateSettingValue(
            state.forecastAlert.enabled,
            action.value
          ),
        },
      };
    case Type.SAVE_FORECAST_ALERT_ENABLED:
      return {
        ...state,
        forecastAlert: {
          ...state.forecastAlert,
          enabled: saveSettingValue(state.forecastAlert.enabled),
        },
      };
    case Type.SET_FORECAST_ALERT_RECIPIENT:
      return {
        ...state,
        forecastAlert: {
          ...state.forecastAlert,
          recipient: updateSettingValue(
            state.forecastAlert.recipient,
            action.value
          ),
        },
      };
    case Type.SAVE_FORECAST_ALERT_RECIPIENT:
      return {
        ...state,
        forecastAlert: {
          ...state.forecastAlert,
          recipient: saveSettingValue(state.forecastAlert.recipient),
        },
      };
    default:
      return state; // TODO: Or throw error?
  }
};

type EmailNotificationsContextType = {
  emailNotificationsState: EmailNotificationsState;
  emailNotificationsDispatch: React.Dispatch<Action>;
};

export const EmailNotificationsContext =
  React.createContext<EmailNotificationsContextType>(
    {} as EmailNotificationsContextType
  );

export default reducer;
