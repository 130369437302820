import React, { FC, ReactNode } from "react";
import {
  Box,
  CardContent,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  LinearProgress,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";

interface SimpleModalProps {
  open: boolean;
  handleClose: () => void;
  acceptButtonType?: "button" | "submit" | "reset";
  form?: string;
  handleAccept?: any;
  title?: string;
  actions?: "default" | ReactNode;
  cancelLabel?: string;
  acceptLabel?: string;
  minWidth?: string;
  maxWidth?: string;
  disableCancelButton?: boolean;
  loading?: boolean;
  acceptButtonLoading?: boolean;
  acceptButtonDisabled?: boolean;
  contentSx?: SxProps;
  acceptButtonColor?:
    | "secondary"
    | "inherit"
    | "primary"
    | "success"
    | "error"
    | "info"
    | "warning";
  children: ReactNode;
}

const SimpleModal: FC<SimpleModalProps> = (props) => {
  const {
    open,
    handleClose = () => {},
    handleAccept,
    acceptButtonType = "button",
    form = null,
    title = null,
    actions = "default",
    cancelLabel = "Cancel",
    acceptLabel = "Ok",
    minWidth = "300px",
    maxWidth,
    disableCancelButton = false,
    loading = false,
    acceptButtonLoading = false,
    acceptButtonDisabled = false,
    acceptButtonColor = "secondary",
    contentSx = {},
    children,
  } = props;

  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth,
          ...(maxWidth && { maxWidth }),
        },
      }}
    >
      <Box sx={{ height: "4px" }}>{loading && <LinearProgress />}</Box>
      <CardContent sx={{ p: 4, pt: 2, minWidth }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Typography
            variant={"h5"}
            dangerouslySetInnerHTML={{ __html: title ?? "" }}
          />
          <IconButton onClick={handleClose}>
            <ClearIcon
              sx={{
                color: theme.palette.text.primary,
              }}
            />
          </IconButton>
        </Box>
        <Divider sx={{ ml: -4, width: `calc(100% + 64px)`, mt: 1 }} />
        <Box
          sx={{
            maxHeight: "calc(80vh - 150px)",
            overflowY: "scroll",
            mt: 2,
            ...contentSx,
          }}
        >
          {children}
        </Box>
        <Divider sx={{ ml: -4, width: `calc(100% + 64px)`, mt: 2 }} />
        {actions === "default" ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "24px",
              mt: 2,
              "& > button": {
                minWidth: "160px",
              },
            }}
          >
            {!disableCancelButton && (
              <Button
                variant={"secondaryContained"}
                color={"secondary"}
                onClick={handleClose}
              >
                {cancelLabel}
              </Button>
            )}
            <Button
              variant={"contained"}
              color={acceptButtonColor}
              type={acceptButtonType}
              onClick={acceptButtonType !== "button" ? () => {} : handleAccept}
              disabled={acceptButtonLoading || acceptButtonDisabled}
              {...(form && { form })}
              endIcon={
                acceptButtonLoading ? (
                  <CircularProgress
                    size={16}
                    sx={{
                      color: theme.palette.primary.contrastText,
                    }}
                  />
                ) : (
                  <></>
                )
              }
            >
              {acceptLabel}
            </Button>
          </Box>
        ) : (
          actions
        )}
      </CardContent>
    </Dialog>
  );
};

export default SimpleModal;
