import { Button } from "primereact/button";
import React from "react";
import { goToUrlInNewTab } from "../../../../configs/resources";
import PillButton from "../../../PillButton";
import SectionHeader from "../../SectionHeader";
import addphoto from "../../../../assets/icons/addphoto.svg";
import UserContext, {
  convertRoleToFriendlyName,
  getMaxUserRole,
} from "../../../../services/UserContext";

export default function Subscription() {
  const context = React.useContext(UserContext);
  const userRole = React.useMemo(() => {
    return convertRoleToFriendlyName(getMaxUserRole(context));
  }, [context]);

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        {" "}
        <SectionHeader text={"Subscription Details"} />
      </div>

      <div className="p-col-12 p-md-12 p-lg-12 p-xl-4">
        <div className="subscription-container">
          <p className="subscription-header-text">Subscription plan</p>
          <div className="plus-section">
            <div style={{ margin: "auto", padding: "10px" }}>
              <img style={{ paddingTop: "0px" }} src={addphoto} alt=""></img>
            </div>
            <div
              style={{ textAlign: "left", marginLeft: "5px", padding: "10px" }}
            >
              <div>
                <strong>{userRole}</strong>
              </div>
              <div>Automate monitoring of your USAID pipeline.</div>
            </div>
          </div>
          <hr style={{ marginTop: "50px" }} />
          <div className="subscription-footer">
            <div>
              Get access to wider range of USAID projects, data and resources.
              In addition, you will be able to setup and customize the daily
              email notifications.
            </div>
            <PillButton
              label="Upgrade Plan"
              onClick={goToUrlInNewTab("tiers")}
              style={{ margin: "5px 0 25px" }}
              className="sub-button"
            />
          </div>
        </div>
      </div>
      <div className="p-col-12 p-md-12 p-lg-12 p-xl-8"></div>
    </div>
  );
}
