export const getRandomIntArray = (
  maxAmount: number,
  count: number,
): number[] => {
  if (count > maxAmount) return [] as number[];
  const randomIndexes: number[] = [];
  while (randomIndexes.length < count) {
    const r = Math.floor(Math.random() * maxAmount);
    if (randomIndexes.indexOf(r) === -1) randomIndexes.push(r);
  }

  return randomIndexes;
};

export const getRandomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
