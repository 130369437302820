import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import { Breadcrumbs as MUIBreadcrumbs, capitalize, Link } from "@mui/material";
import { pageTitles } from "../Navigation/pageTitles.config";

interface BreadcrumbsProps {
  replaceLastWith?: string;
}

const Breadcrumbs: FC<BreadcrumbsProps> = (props) => {
  const { replaceLastWith = null } = props;
  const location = useLocation();

  return location.pathname === "/" ? (
    <></>
  ) : (
    <MUIBreadcrumbs
      maxItems={3}
      sx={{
        "& > *": {
          fontSize: "14px",
        },
      }}
    >
      {location.pathname.split("/").map((segment, idx, array) =>
        idx === 0 ? (
          <Link href={"/"} underline={"none"} key={"home-bradcrumb"}>
            Home
          </Link>
        ) : (
          <Link
            href={array.slice(0, idx + 1).join("/")}
            underline={"none"}
            key={`breadcrumb-${idx}`}
          >
            {idx === array.length - 1 && replaceLastWith
              ? replaceLastWith
              : pageTitles.find(
                  (title) =>
                    title.pathname === array.slice(0, idx + 1).join("/"),
                )
              ? pageTitles.find(
                  (title) =>
                    title.pathname === array.slice(0, idx + 1).join("/"),
                )?.title ?? capitalize(segment)
              : capitalize(segment)}
          </Link>
        ),
      )}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
