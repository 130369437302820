import toast from "react-hot-toast";

export const handleInvalidDatesToast = (
  values: {
    [key: string]: any;
  },
  callback: () => void,
): void => {
  const invalidDateFields = Object.entries(values)
    .filter(
      ([key, value]: [key: string, value: any]) =>
        typeof value?.getMonth === "function" &&
        Number.isNaN(new Date(value)?.getMonth()),
    )
    .map(([key, value]) => key);
  if (invalidDateFields.length > 0) toast.error("Date you set is invalid");
  else callback();
};
