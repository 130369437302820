import { Button } from "primereact/button";
import React, { FC, useContext, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { SubHeaders } from "../../../components/Dashboard/SectionHeader";
import "../Pipeline.css";
import { PipelineItems } from "./PipelineItems";
import AidkonektIcon from "../../../assets/icons/v2/icon_Globe.png";
import { OverlayPanel } from "primereact/overlaypanel";
import Details from "./SideBar";
import axios from "axios";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../../configs/axios-export.custom";
import UserContext from "../../../services/UserContext";
import GrowlContext from "../../../services/growlContext";
import { goToUrlInNewTab } from "../../../configs/resources";
import { PreferenceFilterTypes } from "../../../services/enums";
import { Button as PButton } from "primereact/components/button/Button";
import { useGridStyles } from "../../../hooks/useGridStyles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import styled from "@emotion/styled";
import {
  excelFileRequest,
  generateExcelFromBlob,
} from "../../../services/exporter";
import { UserProfile } from "../../../components/Dashboard/DashboardManagement/Users/UserMenu";
type PipelineDto = {
  id: number;
  name: string;
  description: string;
  createdByCognitoId: string;
  businessForecastCustomColumnName: string;
  contractCustomColumnName: string;
  grantCustomColumnName: string;
  pipelineUserRole: string;
  createdOnUtc: string;
  users: [];
  marketPreferences: [];
  keywords: [];
  grantCount: number;
  contractCount: number;
  forecastCount: number;
  totalOpportunities: number;
  preferenceFilterType: PreferenceFilterTypes;
};
const OverlayerWindow = styled.div({
  zIndex: 10000,
  top: "1px !important",
  left: "0px",
});
const PipelineDetail: React.FunctionComponent = () => {
  const context = React.useContext(UserContext);
  const growl = useContext(GrowlContext);
  const location = useLocation();
  const navigation = useHistory();
  const styles = useGridStyles();
  const [pipeline, setPipeline] = React.useState(location.state as PipelineDto);
  const [isPipelineOwner, setIspipelineOwner] = React.useState(false);
  const sp = useRef<OverlayPanel>(null);
  const [togglePrivacyTab, setTogglePrivacyTab] = React.useState(false);
  const [toggleView, setToggleViewMode] = React.useState(false);
  // extract pipeline id from path.
  const path = window.location.pathname.split("/");
  const pipelineId = parseInt(path[path.length - 1]);

  const loadPipeline = (pipelineId: number) => {
    axios
      .get(
        getFullUrl(`/api/pipeline/${pipelineId}`, {
          useDedicatedEnvironment: true,
        }),
        createRequestWithAuthHeaders(context),
      )
      .then((res) => {
        const data: PipelineDto = res.data as PipelineDto;
        let pipelineOwner = data.pipelineUserRole === "Admin";
        setIspipelineOwner(pipelineOwner);
        setPipeline(data);
      })
      .catch((e) => {
        if (e && growl.current) {
          growl.current.show({
            severity: "error",
            summary:
              "You don't have access to this pipeline, contact your AidKonekt organization admin for details",
          });
          navigation.replace("/pipeline");
        }
      });
  };

  React.useEffect(() => {
    loadPipeline(pipelineId);
  }, []);

  // Download opportunities
  const downloadPopup = useRef<OverlayPanel>(null);
  const [fromDate, setFromDate] = React.useState<Date | null>(new Date());
  const [toDate, setToDate] = React.useState<Date | null>(new Date());
  const [isDownloading, setIsDownloading] = React.useState(false);

  const handleDownloadPipelines = async () => {
    setIsDownloading(true);
    try {
      const {
        data: { firstName, lastName },
      } = await axios.get(
        getFullUrl("/api/user/profile", { useDedicatedEnvironment: true }),
        createAuthenticatedRequest(context),
      );
      await excelFileRequest(
        context,
        toDate ?? new Date(),
        fromDate ?? new Date(),
        [pipelineId],
        `${firstName} ${lastName}`,
      );
    } catch (e) {
      console.error(e);
      growl.current.show({
        severity: "error",
        summary: "Unable to download pipelines",
      });
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="pipeline-details-main">
      <div className="pipeline-details-pipelines">
        <Link to={"/pipeline"}>
          <i className="pi pi-arrow-left"></i>View All Pipelines
        </Link>
      </div>
      <div className="pipeline-detail-container">
        <div className="pipeline-header">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={AidkonektIcon}
              alt="Aidkonekt Icon"
              style={{ height: "25px", width: "auto" }}
            />
            &nbsp;
            <span className="pipeline-title">{pipeline?.name}</span>
            <span>
              {/* <Dropdown optionLabel="name" value={city} options={pipeline} onChange={(e) => setCity(e.value)} placeholder="Select a City"/> */}
            </span>
          </div>
          <p className="header-pipeline">{pipeline?.description}</p>
        </div>
        <div className="pipeline-buttons">
          <PButton
            type="button"
            icon="pi pi-file-excel"
            iconPos="left"
            onClick={(e) => {
              setToDate(new Date());
              setFromDate(new Date());
              downloadPopup.current?.toggle(e);
            }}
            tooltip="Excel"
            tooltipOptions={{ position: "top" }}
            style={{
              marginLeft: 15,
              marginRight: 15,
              ...styles.tableHeaderButton,
            }}
          />
          {isPipelineOwner && (
            <>
              <Button
                onClick={(e: React.SyntheticEvent<Element, Event>) => {
                  sp.current?.toggle(e);
                }}
                style={{ marginRight: "6px", fontSize: "13px" }}
                className="modal-button"
                label="Share Pipeline"
              />
              {/* <Button style={{fontSize:"13px"}} onClick={(e:React.SyntheticEvent<Element,Event>)=>{sp.current?.toggle(e)}}  className="modal-button" label="Edit Pipeline"/> */}
            </>
          )}
        </div>
      </div>
      <PipelineItems id={pipelineId} isPipelineOwner={isPipelineOwner} />

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <OverlayerWindow>
          <OverlayPanel
            ref={downloadPopup}
            style={{ width: "390px", display: "flex !important" }}
            className="side-panel-downloadopportunities details"
          >
            <div>
              <p>From:</p>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                value={fromDate ?? new Date()}
                onError={(e) => null}
                onChange={(date) => setFromDate(date)}
                style={{ marginLeft: 10 }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
            <div>
              <p>To:</p>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                value={toDate ?? new Date()}
                onError={(e) => null}
                onChange={(date) => setToDate(date)}
                style={{ marginLeft: 10 }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
            <Button
              disabled={isDownloading}
              onClick={handleDownloadPipelines}
              className="button-downloadopportunities"
              label="Download"
            />
          </OverlayPanel>
        </OverlayerWindow>
      </MuiPickersUtilsProvider>
      <OverlayPanel
        // onHide={handleOnhide }
        ref={sp}
        showCloseIcon
        id="overlay_panel"
        style={{ left: "none", right: "0px !important" }}
      >
        <div className="side-panel-container">
          <div style={{ width: "535px", height: "107vh" }}>
            <div className="side-panel-header">
              <SubHeaders text={pipeline?.name} />
            </div>
            <div className="tabview">
              <div>
                <Details
                  divRef={sp}
                  pipelines={pipeline}
                  toggleEdits={togglePrivacyTab}
                  toggleViewMode={toggleView}
                />
              </div>
            </div>
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default PipelineDetail;
