import React, { useEffect, useState } from "react";

import "./App.css";
import Router from "./components/Navigation/Router";
import UserContext, { User } from "./services/UserContext";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.min.css";
import "./screens/DataTable.css";
import "./assets/themes/nova-light/theme.css";

import { Toast as Growl } from "primereact/toast";
import GrowlContext from "./services/growlContext";

import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { getFullUrl } from "./configs/axios-export.custom";
import axios from "axios";
import {
  parseToken,
  SignInResponseModel,
} from "./components/Authentication/models";
import { Userpilot } from "userpilot";
import { addSeconds, isBefore } from "date-fns";
import { MUITheme } from "./theme/MUITheme";
import { Toaster } from "react-hot-toast";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store";
import {
  DynamicPageTitleConsumer,
  DynamicPageTitleProvider,
} from "./services/dynamicPageTitleContext";
import { CssBaseline } from "@mui/material";

export const callRefresh = async (
  currentRefreshToken: string | null,
  currentUser: User,
  setCurrentRefreshToken: React.Dispatch<React.SetStateAction<string | null>>,
  setCurrentUser: React.Dispatch<React.SetStateAction<User | undefined>>,
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  let token = currentRefreshToken
    ? currentRefreshToken
    : currentUser.refreshToken
    ? currentUser.refreshToken
    : window.localStorage.getItem("refreshToken");

  const expDate = window.localStorage.getItem("expiredIn");

  let errorJson = false;
  let expiredInDate = "";

  try {
    if (!!expDate) expiredInDate = JSON.parse(expDate);
  } catch (err) {
    errorJson = true;
  }

  const isExpired = isBefore(
    !!expDate && !errorJson
      ? new Date(JSON.parse(expDate))
      : addSeconds(new Date(), 3500),
    new Date(),
  );

  // if (isExpired || errorJson || (!currentUser && token)) {
  await axios
    .post<SignInResponseModel>(
      getFullUrl("/api/auth/refreshToken", { useDedicatedEnvironment: true }),
      {
        Token: token,
      },
    )
    .then(async (resp) => {
      var { user, groups, konektidToken } = resp.data;
      var parsedIdToken = parseToken(user.idToken);
      var refreshToken = user.refreshToken ? user.refreshToken : token;
      setCurrentRefreshToken(refreshToken);
      setCurrentUser({
        accessToken: user.accessToken,
        jwt: user.idToken,
        idToken: user.idToken,
        parsedIdToken: parsedIdToken ?? undefined,
        refreshToken: refreshToken!,
        expiresIn: user.expiresIn,
        groups,
        konektidToken,
      });
      window.localStorage.setItem(
        "expiredIn",
        JSON.stringify(addSeconds(new Date(), 3500)),
      );
      setIsLoaded(true);
    });
  // }
};

const App = () => {
  Userpilot.initialize("NX-b3f5b1ba");
  const [currentUser, setCurrentUser] = useState<User>();
  useEffect(() => {}, [currentUser]);
  const [currentRefreshToken, setCurrentRefreshToken] = useState(
    window.localStorage.getItem("refreshToken"),
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const growl = React.useRef();

  const updateCurrentUser = async (user) => {
    if (user) {
      setCurrentRefreshToken(user.refreshToken);
      setCurrentUser(user);
      setIsLoaded(true);
      return;
    }

    try {
      await callRefresh(
        currentRefreshToken,
        currentUser!,
        setCurrentRefreshToken,
        setCurrentUser,
        setIsLoaded,
      );
    } catch (err) {
      setCurrentUser({
        accessToken: "",
        jwt: "",
        idToken: undefined,
        parsedIdToken: undefined,
        refreshToken: "",
        expiresIn: 0,
        groups: [],
        konektidToken: undefined,
      });
      setIsLoaded(true);
    }
  };

  const refreshUserToken = async (reloadPage) => {
    await callRefresh(
      currentRefreshToken,
      currentUser!,
      setCurrentRefreshToken,
      setCurrentUser,
      setIsLoaded,
    ).then(() => {
      if (reloadPage) {
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    window.localStorage.setItem("refreshToken", currentRefreshToken!);
  }, [currentRefreshToken]);

  return (
    <ReduxProvider store={store}>
      <GrowlContext.Provider value={growl as any}>
        <Growl ref={(el) => (growl.current = el as any)} />
        <UserContext.Provider
          value={{
            user: currentUser as any,
            updateCurrentUser: updateCurrentUser,
            isLoaded: isLoaded,
            refreshUserToken,
          }}
        >
          <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DynamicPageTitleProvider>
                <DynamicPageTitleConsumer>
                  {(pageTitle) => (
                    <ThemeProvider theme={MUITheme}>
                      <CssBaseline />
                      <div className="App">
                        <Toaster />
                        <Router currentUser={currentUser} />
                      </div>
                    </ThemeProvider>
                  )}
                </DynamicPageTitleConsumer>
              </DynamicPageTitleProvider>
            </LocalizationProvider>
          </StyledEngineProvider>
        </UserContext.Provider>
      </GrowlContext.Provider>
    </ReduxProvider>
  );
};

export default App;
