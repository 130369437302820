import React, { useContext } from "react";
import ThemeContext from "../../configs/theme";
import UserContext, {
  isUserInRoles,
  UserRoleGroups,
} from "../../services/UserContext";

export function useSharedStyles() {
  const theme = useContext(ThemeContext);

  const context = React.useContext(UserContext);
  const isFree = !isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const isPlus = isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const isPro = isUserInRoles(context, UserRoleGroups.proOrHigher);

  const potentiallyBlurred: (blur: boolean) => React.CSSProperties = (blur) =>
    blur
      ? {
          color: "transparent",
          textShadow: "0 0 10px rgba(0,0,0,0.5)",
          WebkitTouchCallout: "none",
          WebkitUserSelect: "none",
          KhtmlUserSelect: "none",
          MozUserSelect: "none",
          msUserSelect: "none",
          userSelect: "none",
        }
      : {};

  const floatingLabel: React.CSSProperties = {
    display: "block",
    color: theme.v2.darkGrey,
    fontWeight: 600,
    fontSize: 15,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  const radiusBordered: React.CSSProperties = {
    border: "2px solid",
    borderColor: theme.v2.lightGrey,
    borderRadius: "65px",
    paddingTop: 8,
    paddingBottom: 8,
  };

  const text: React.CSSProperties = {
    fontSize: "14px",
    padding: "7px 15px",
    margin: "5px 0px",
  };

  return {
    isFree,
    isPlus,
    isPro,
    dropdownBlurClassName: (blur: boolean) => (blur ? "tyd-blur-dropdown" : ""),
    potentiallyBlurred,
    floatingLabel,
    radiusBordered,
    text,
  };
}
