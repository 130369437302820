import axios from "axios";
import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../configs/axios-export.custom";
import { UserContextType } from "../services/UserContext";
import { RequestStatus } from "../utils/Helpers/fetchStatus";
import { buildQuery } from "../utils/Helpers/queryBuilder";
import { DropdownOption } from "../components/Widgets/Inputs/Input";

export interface ECDRecord {
  id: number;
  countries: string[];
  sectorTypes: string[];
  specificConditions: string[];
  thresholdDetermination: string[];
  projectNumber: string[];
  awardNumbers: string[];
  classification: string | null;
  climateRiskManagementAssessment: string | null;
  documentTitle: string;
  documentType: string;
  createdOnUtc: string;
  extECDRecord_Id: number;
  fileNumber: string;
  originatingBureauSubregionOffice: string;
  projectDateActivityDateRangeEndParsed: string | null;
  projectDateActivityDateRangeStartParsed: string | null;
  projectDateEnvironmentalDeterminationSignatureDateParsed: string;
  projectDateEnvironmentalDeterminationSignatureFyParsed: string;
  projectDateExpirationDateParsed: string | null;
  recordStatus: string;
  updatedOnUtc: string | null;
  parentDocumentUrl: string | null;
  relatedDocumentsUrls: string[];
}

interface ECDState {
  items: ECDRecord[];
  fetchStatus: string;
  total: number;
  lastUpdatedUtc: string | null;
  attributes: Record<string, unknown>;
  resetECDRecord: {
    fetchStatus: string;
    errors: Record<string, unknown>;
  };
  dropdownOptions: {
    fetchStatus: string;
    bureaus: DropdownOption[];
    sectorTypes: DropdownOption[];
    countries: DropdownOption[];
    documentTypes: DropdownOption[];
  };
}

export interface ECDFiltersProps {
  PageIndex: number;
  PageSize: number;
  SortField?: string;
  SortOrder?: number;
  Id?: number | null;
  Countries?: string[] | DropdownOption[];
  Bureaus?: string[] | DropdownOption[];
  Sectors?: string[] | DropdownOption[];
  Statuses?: string[] | DropdownOption[];
  DocumentTitle?: string;
  FileNumber?: string;
  DocumentText?: string;
  DocumentType?: string | DropdownOption | null;
  EtdSignatureDateFrom: Date | string | null;
  EtdSignatureDateTo: Date | string | null;
  EtdSignatureFiscalYearFrom: Date | string | null;
  EtdSignatureFiscalYearTo: Date | string | null;
  ActivityDateFrom?: Date | string | null;
  ActivityDateTo?: Date | string | null;
  ExpirationDateFrom?: Date | string | null;
  ExpirationDateTo?: Date | string | null;
}

const initialState: ECDState = Object.freeze({
  items: [],
  fetchStatus: RequestStatus.statuses.NULL,
  total: 0,
  lastUpdatedUtc: null,
  attributes: {},
  resetECDRecord: {
    fetchStatus: RequestStatus.statuses.NULL,
    errors: {},
  },
  dropdownOptions: {
    fetchStatus: RequestStatus.statuses.NULL,
    bureaus: [],
    sectorTypes: [],
    countries: [],
    documentTypes: [],
  },
});

export const getECDData: any = createAsyncThunk(
  "ecd/getECDData",
  async (
    data: {
      context: UserContextType;
      params: ECDFiltersProps;
    },
    thunkAPI,
  ) => {
    const { context, params } = data;
    const response = await axios.get(
      getFullUrl(`/api/ecd${buildQuery({ ...params })}`, {
        useDedicatedEnvironment: true,
      }),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

export const getECDDropdownOptions: any = createAsyncThunk(
  "ecd/getECDDropdownOptions",
  async (
    data: {
      context: UserContextType;
    },
    thunkAPI,
  ) => {
    const { context } = data;
    const response = await axios.get(
      getFullUrl(`/api/ecd/dropdowns`, {
        useDedicatedEnvironment: true,
      }),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? {};
  },
);

export const resetECDRecord: any = createAsyncThunk(
  "ecd/resetECDRecord",
  async (
    data: {
      context: UserContextType;
      id: number;
    },
    thunkAPI,
  ) => {
    const { context, id } = data;
    const response = await axios.put(
      getFullUrl(`/api/ecd/${id}/reset`, {
        useDedicatedEnvironment: true,
      }),
      {},
      createAuthenticatedRequest(context),
    );

    return response?.data ?? {};
  },
);

function transformDropdownOptionsArray(data: string[]): DropdownOption[] {
  return data.map((item) => ({
    label: item,
    value: item,
  }));
}

const slice = createSlice({
  name: "ecd",
  initialState,
  reducers: {
    resetReportsItems(state: ECDState) {
      state.items = initialState.items;
      state.total = initialState.total;
      state.fetchStatus = initialState.fetchStatus;
    },
    resetResetECDRecord(state: ECDState) {
      state.resetECDRecord = initialState.resetECDRecord;
    },
  },
  extraReducers: {
    [getECDData.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getECDData.fulfilled]: (state, action: PayloadAction<any>) => {
      const { attributes, data, lastUpdatedUtc, totalItems } = action.payload;
      state.items = data;
      state.total = totalItems;
      state.lastUpdatedUtc = lastUpdatedUtc;
      state.attributes = attributes;
      state.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getECDData.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [getECDDropdownOptions.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getECDDropdownOptions.fulfilled]: (state, action: PayloadAction<any>) => {
      state.dropdownOptions = {
        fetchStatus: RequestStatus.statuses.DONE,
        bureaus: transformDropdownOptionsArray(action.payload.bureaus),
        sectorTypes: transformDropdownOptionsArray(action.payload.sectorTypes),
        countries: transformDropdownOptionsArray(action.payload.countries),
        documentTypes: transformDropdownOptionsArray(
          action.payload.documentTypes,
        ),
      };
    },
    [getECDDropdownOptions.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [resetECDRecord.pending]: (state, action) => {
      state.resetECDRecord.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [resetECDRecord.fulfilled]: (state, action: PayloadAction<any>) => {
      state.resetECDRecord.fetchStatus = RequestStatus.statuses.DONE;
    },
    [resetECDRecord.rejected]: (state, action) => {
      state.resetECDRecord.fetchStatus = RequestStatus.statuses.ERROR;
      state.resetECDRecord.errors = action.payload;
    },
  },
});

export const { reducer } = slice;

export const { resetResetECDRecord, resetReportsItems } = slice.actions;
export default slice;
