import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../configs/axios-export.custom";
import axios from "axios";
import { AddAllOptionText } from "../components/CustomSearchSelect/CustomSearchSelect";
import { UserContextType } from "./UserContext";

export default async function fetchData(
  url: string,
  setData: (data: any) => void,
  context: UserContextType,
  message: string,
  addAllOptionsSelect: boolean = false,
  addAllOptionsAsKeyValue: boolean = false
) {
  const fetchRequest = createAuthenticatedRequest(context);

  axios
    .get(getFullUrl(url), fetchRequest)
    .then((response) => {
      let options = response.data;
      if (addAllOptionsSelect) {
        options.push(AddAllOptionText);
      }
      if (addAllOptionsAsKeyValue) {
        options.push({ key: AddAllOptionText, value: AddAllOptionText });
      }
      setData(options);
    })
    .catch((error) => {});
}

export async function fetchDataNewEnvironment(
  url: string,
  setData: (data: any) => void,
  context: UserContextType,
  message: string,
  addAllOptionsSelect: boolean = false,
  addAllOptionsAsKeyValue: boolean = false
) {
  const fetchRequest = createAuthenticatedRequest(context);

  axios
    .get(getFullUrl(url, { useDedicatedEnvironment: true }), fetchRequest)
    .then((response) => {
      let options = response.data;
      if (addAllOptionsSelect) {
        options.push(AddAllOptionText);
      }
      if (addAllOptionsAsKeyValue) {
        options.push({ key: AddAllOptionText, value: AddAllOptionText });
      }
      setData(options);
    })
    .catch((error) => {});
}
