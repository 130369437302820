import React, { useState, FunctionComponent, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import "./PipelineUpdates.css";
import DashboardSection from "../DashboardSection";
import SectionHeader from "../SectionHeader";
import { Link } from "react-router-dom";
import UserContext from "../../../services/UserContext";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../../configs/axios-export.custom";
import axios from "axios";
import GrowlContext from "../../../services/growlContext";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { getDateString } from "../../../services/formatter";
import { parseISO } from "date-fns";

enum periods {
  week = 1,
  month,
  year,
}
enum pipelineTypes {
  allPiplines = 1,
  myPipelines = 2,
  sharedWithMe = 3,
}

interface PipelineUpdate {
  title: string;
  updatedOn: string;
  changedColumn: string;
  pipelineName: string;
  pipelineId: number;
}

const peridsOptions = [
  { label: "Last Week", value: periods.week },
  { label: "Last Month", value: periods.month },
  { label: "Last Year", value: periods.year },
];
const pipelineTypesOptions = [
  { label: "All Pipelines", value: pipelineTypes.allPiplines },
  { label: "My Pipelines", value: pipelineTypes.myPipelines },
  { label: "Shared with me", value: pipelineTypes.sharedWithMe },
];

const PipelineUpdates: FunctionComponent = () => {
  const [pipeLinesUpdates, setPipeLinesUpdates] = useState<PipelineUpdate[]>(
    []
  );
  const userContext = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);
  const request = createAuthenticatedRequest(userContext);
  const [period, setPeriod] = useState(periods.week);
  const [type, setType] = useState(pipelineTypes.allPiplines);
  const [visibleTooltip, setVisibleTooltip] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  const getBidStatusTooltip = () => {
    let periodOption = peridsOptions.find((obj) => {
      return obj.value === period;
    });

    let typeOption = pipelineTypesOptions.find((obj) => {
      return obj.value === type;
    });

    if (pipeLinesUpdates.length === 0) {
      return (
        <>
          There are no updates <u>{periodOption?.label}</u> from{" "}
          <u>{typeOption?.label} </u>
        </>
      );
    } else if (pipeLinesUpdates.length === 1) {
      return (
        <>
          <u>${pipeLinesUpdates.length}</u> opportunity has been updated{" "}
          <u>{periodOption?.label}</u> from <u>{typeOption?.label}</u>
        </>
      );
    } else {
      return (
        <>
          <u>{pipeLinesUpdates.length}</u> opportunities have been updated{" "}
          <u>{periodOption?.label}</u> from <u>{typeOption?.label}</u>
        </>
      );
    }
  };

  const removeHtmlEntities = (str: string) => {
    if (str) {
      return str
        ?.replace(/&nbsp;/g, " ")
        ?.replace(/&amp;/g, "&")
        ?.replace(/&lt;/g, "<")
        ?.replace(/&gt;/g, ">")
        ?.replace(/&quot;/g, '"')
        ?.replace(/&apos;/g, "'")
        ?.replace(/&cent;/g, "¢")
        ?.replace(/&pound;/g, "£")
        ?.replace(/&yen;/g, "¥")
        ?.replace(/&euro;/g, "€")
        ?.replace(/&copy;/g, "©")
        ?.replace(/&reg;/g, "®");
    } else {
      return str;
    }
  };

  useEffect(() => {
    setLoading(true);
    let queryString = new URLSearchParams();

    queryString.set("period", period.toString());
    queryString.set("type", type.toString());

    axios
      .get(
        getFullUrl(
          `/api/user/recentPipeLineUpdates?${queryString.toString()}`,
          {
            useDedicatedEnvironment: true,
          }
        ),
        request
      )
      .then((response) => {
        setLoading(false);
        const updates: PipelineUpdate[] = response.data;
        setPipeLinesUpdates(updates);
      })
      .catch((e) => {
        console.error(e);
        growl.current.show({
          severity: "error",
          summary: "Unable to load pipelines updates",
        });
      });
  }, [period, type]);

  function renderUpdates() {
    return pipeLinesUpdates.map((update) => (
      <div className="pipeline-card">
        <div className="pipeline-date">
          {getDateString(parseISO(update.updatedOn + "Z"))}
        </div>
        <div className="pipeline-change">
          {`${update.changedColumn} ${
            update.changedColumn?.includes(",")
              ? " have been changed"
              : " has been changed"
          }`}
        </div>
        <div className="pipeline-title">{removeHtmlEntities(update.title)}</div>
        <div className="pipeline-name">
          <Link className="pipeline-link" to={`/pipeline/${update.pipelineId}`}>
            {removeHtmlEntities(update.pipelineName)}
          </Link>
        </div>
      </div>
    ));
  }
  return (
    <DashboardSection>
      <div className="pipeline-updates-header">
        <SectionHeader text={"Pipelines Updates"} icon={false} />

        <div className="bid-status">
          <div
            onMouseOver={() => setVisibleTooltip(true)}
            onMouseLeave={() => setVisibleTooltip(false)}
            className={
              visibleTooltip
                ? "bidstatus-tooltip visible"
                : "bidstatus-tooltip hidden"
            }
          >
            <div>
              <p className="bid-status-tooltip-body">{getBidStatusTooltip()}</p>
            </div>
          </div>
          <svg
            className="bid-status-icon"
            onMouseOver={() => setVisibleTooltip(true)}
            onMouseLeave={() => setVisibleTooltip(false)}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="17"
              cy="17"
              r="17"
              fill="url(#paint0_radial_381_5632)"
            />

            <circle cx="17" cy="17" r="13" fill="#F46E6E" />
            <text x="12" y="20" fontFamily="Verdana" fontSize="11" fill="white">
              {pipeLinesUpdates.length}
            </text>
            {/* <path
              d="M13.634 16.316L13.06 16.274L16.21 13.236V12.2H9.77L9.784 13.67H14.152L11.282 16.428V17.38H12.71C14.208 17.38 15.09 17.926 15.09 18.99C15.09 20.012 14.222 20.684 12.738 20.684C11.744 20.684 10.75 20.32 9.98 19.662L9.294 21.006C10.19 21.65 11.548 22.07 12.85 22.07C15.216 22.07 16.7 20.796 16.7 18.962C16.7 17.408 15.468 16.47 13.634 16.316ZM21.9666 12.144C19.5866 12.144 18.2706 13.908 18.2706 17.086C18.2706 20.278 19.5866 22.042 21.9666 22.042C24.3326 22.042 25.6486 20.278 25.6486 17.086C25.6486 13.908 24.3326 12.144 21.9666 12.144ZM21.9666 13.432C23.3246 13.432 24.0106 14.594 24.0106 17.086C24.0106 19.578 23.3246 20.754 21.9666 20.754C20.5946 20.754 19.9086 19.578 19.9086 17.086C19.9086 14.594 20.5946 13.432 21.9666 13.432Z"
              fill="white"
            /> */}
            <defs>
              <radialGradient
                id="paint0_radial_381_5632"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(17 17) rotate(90) scale(54.0909 66.1332)"
              >
                <stop stopColor="#F46E6E" stopOpacity="0" />
                <stop offset="1" stopColor="#F46E6E" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div className="search-box" style={{ width: "100%" }}>
        <Dropdown
          className="serach-dropdown-list type"
          options={pipelineTypesOptions}
          onChange={(e) => setType(e.value)}
          value={type}
          style={{ width: "55%" }}
          appendTo={document.body}
        />

        <Dropdown
          className="serach-dropdown-list"
          options={peridsOptions}
          onChange={(e) => setPeriod(e.value)}
          value={period}
          style={{ width: "50%" }}
          appendTo={document.body}
        />
      </div>
      <div className="search-box-sep"></div>
      <div
        style={{
          height: "70vh",
          overflowY: "scroll",
          overflowX: "hidden",
          marginRight: "-20px",
        }}
      >
        {loading ? (
          <ProgressSpinner strokeWidth="1" className="pipeline-loader" />
        ) : (
          renderUpdates()
        )}
      </div>
    </DashboardSection>
  );
};

export default PipelineUpdates;
