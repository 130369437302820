import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  createFilterOptions,
  LinearProgress,
  ListItemText,
  SxProps,
  TextField,
  useTheme,
} from "@mui/material";
import {
  cloneElement,
  ComponentType,
  FC,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RequestStatus } from "../../../utils/Helpers/fetchStatus";
import { StateSnapshot, Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { debounce, uniqBy } from "lodash";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { constants } from "../../../utils/constants/general";

interface InputComboBoxProps {
  options: Array<any>;
  onChange: any;
  noValueMessage: string;
  isTouched: boolean;
  defaultValue?: string;
  label?: string | null;
  placeholder?: string;
  fetchStatus?: string | null;
  disabled?: boolean;
  error: boolean;
  inputRef?: any;
  value?: any;
  requiredSign?: boolean;
  multiple?: boolean;
  limitTags?: number | null;
  sx?: SxProps;
  withCheckboxes?: boolean;
  useVirtualizedList?: boolean;
  asyncCallback: ((value: string) => void) | null;
  disableAddAllMatchingOption: boolean;
}

const ALL_MATCHING_OPTIONS = "internal-select-all-matching-options";

const InputComboBox: FC<InputComboBoxProps> = (props) => {
  const {
    options: _options,
    onChange,
    noValueMessage,
    isTouched,
    defaultValue = null,
    label = null,
    placeholder = null,
    fetchStatus = null,
    disabled = false,
    error,
    inputRef,
    value: valueToForceSet,
    requiredSign = false,
    multiple = false,
    limitTags = null,
    sx = {},
    withCheckboxes = false,
    useVirtualizedList = false,
    asyncCallback = null,
    disableAddAllMatchingOption,
    ...other
  } = props;

  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);

  const options = useMemo(
    () =>
      _options?.length > 0 &&
      !disableAddAllMatchingOption &&
      multiple &&
      !!asyncCallback
        ? [
            {
              label: "Add all matching options",
              value: ALL_MATCHING_OPTIONS,
            },
            ..._options,
          ]
        : _options,
    [_options],
  );

  const [value, setValue] = useState<
    | { value: string; label: string }
    | Array<{
        value: any;
        label: any;
      }>
    | null
  >(valueToForceSet ?? multiple ? [] : null);
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [shouldSetDefaultValue, setShoudSetDefaultValue] =
    useState<boolean>(true);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const virtuosoState = useRef<StateSnapshot | undefined>(undefined);

  const [query, setQuery] = useState<string>("");
  const [initialFetchDone, setInitialFetchDone] = useState<boolean>(false);

  const searchCallback = (value) => {
    setQuery(value);
  };

  const debouncedQuery = useCallback(debounce(searchCallback, 750), []);

  const handleOnValueChange = (e, value): void => {
    setValue(value);
    onChange({ ...e, target: { ...e.target, value: value?.value || value } });
    if (useVirtualizedList && virtuosoRef.current) {
      virtuosoRef.current?.getState((state) => (virtuosoState.current = state));
    }
  };

  const handleOnInputValueChange = (e, value, reason): void => {
    asyncCallback && debouncedQuery(value);
    setInputValue(value);
  };

  const _filterOptions = createFilterOptions();
  const filterOptions = (options, state, includeAddAll = true) => {
    const results = _filterOptions(options, state);

    if (multiple && includeAddAll) {
      if (
        !results.includes({
          label: "Add all matching options",
          value: ALL_MATCHING_OPTIONS,
        })
      ) {
        results.unshift({
          label: "Add all matching options",
          value: ALL_MATCHING_OPTIONS,
        });
      }
    }

    return results;
  };

  useEffect(() => {
    if (defaultValue && options && shouldSetDefaultValue) {
      setValue(options.find((option) => option.value === defaultValue));
      setShoudSetDefaultValue(false);
    }
  }, [defaultValue, options]);

  useEffect(() => {
    if (RequestStatus.isFetching(fetchStatus)) {
      // setShoudSetDefaultValue(true);
      // setValue(multiple ? [] : null);
      // setInputValue(null);
    }
    if (RequestStatus.isDone(fetchStatus) && !initialFetchDone) {
      // setShoudSetDefaultValue(false);
      setValue(valueToForceSet);
      setInitialFetchDone(true);
    }
  }, [fetchStatus, initialFetchDone]);

  useEffect(() => {
    asyncCallback !== null && asyncCallback(query);
  }, [query]);

  // useEffect(() => {
  //   if (options) {
  //     let newVal =
  //       options.find((option) => option.value === valueToForceSet) ||
  //       (multiple
  //         ? null
  //         : {
  //             value: null,
  //             label: "",
  //           });
  //     setValue(multiple ? [newVal] : newVal);
  //   }
  // }, [valueToForceSet]);

  useEffect(() => {
    //todo: cleanup how the values are set to work the same in the case of multiple or single choice input
    if (asyncCallback && !initialFetchDone) return;
    if (multiple) setValue(valueToForceSet);
    else setValue(options.find((option) => option.value === valueToForceSet));
  }, [valueToForceSet]);

  const OutlinedCard = (props) => {
    return (
      <Card
        {...props}
        sx={{
          mb: 2,
          // boxShadow: (theme: Theme) => theme.shadows[20],
          boxShadow: "0px 10px 15px rgba(100, 116, 139, 0.12)",
          // maxHeight: "400px",
        }}
        variant={"outlined"}
      />
    );
  };

  const ListboxComponent = forwardRef<HTMLUListElement>(
    ({ children, ...rest }, ref) => {
      const data = children as ReactElement[];

      return (
        <ul
          ref={(reference) => {
            if (typeof ref === "function") {
              ref(reference);
            }
          }}
          {...rest}
          style={{
            padding: "0",
          }}
        >
          <Virtuoso
            ref={virtuosoRef}
            style={{ height: "35vh" }}
            data={data}
            restoreStateFrom={virtuosoState.current}
            itemContent={(index, child) => {
              return cloneElement(child, { index });
            }}
          />
        </ul>
      );
    },
  ) as ComponentType<HTMLAttributes<HTMLElement>>;

  return (
    <>
      <Autocomplete
        {...other}
        open={open}
        onOpen={openPopper}
        onClose={closePopper}
        options={options}
        value={value}
        inputValue={inputValue ?? ""}
        defaultValue={defaultValue ?? multiple ? [] : null}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        getOptionLabel={(option) => option?.label ?? ""}
        onChange={handleOnValueChange}
        onInputChange={handleOnInputValueChange}
        loading={RequestStatus.isFetching(fetchStatus)}
        noOptionsText={noValueMessage}
        disabled={disabled}
        PaperComponent={OutlinedCard}
        multiple={multiple}
        disableClearable={typeof value === "undefined"}
        disableCloseOnSelect={multiple}
        {...(limitTags && { limitTags })}
        {...(asyncCallback
          ? { filterOptions: (options) => options }
          : {
              filterOptions,
            })}
        slotProps={{
          popper: {
            sx: {
              zIndex: constants.zIndexes.drawerComboboxList,
            },
          },
        }}
        {...(useVirtualizedList && {
          ListboxComponent,
        })}
        renderTags={(tagValue, getTagProps) => {
          // utilizing the fact that in this project (as far as i've seen) each dropdown options list is a list of strings
          return tagValue?.length > 0 ? (
            tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={option?.label ?? option}
                sx={{
                  borderRadius: "16px !important",
                  "& > svg": {
                    height: "24px",
                    width: "24px",
                  },
                }}
              />
            ))
          ) : (
            <></>
          );
        }}
        renderInput={(props) => {
          return (
            <>
              <TextField
                {...props}
                inputRef={inputRef}
                error={error}
                label={label && (requiredSign ? `${label} *` : label)}
                {...(!!placeholder && { placeholder: placeholder })}
                sx={sx}
                inputProps={{
                  ...props?.inputProps,
                  form: {
                    autocomplete: "off",
                  },
                }}
              />
              <Box
                sx={{
                  height: "4px",
                  mt: "-4px",
                  borderRadius: "0 0 4px 4px",
                  overflow: "hidden",
                }}
              >
                {RequestStatus.isFetching(fetchStatus) && <LinearProgress />}
              </Box>
            </>
          );
        }}
        renderOption={(props, option, { selected }) =>
          option.value === ALL_MATCHING_OPTIONS ? (
            <li
              {...props}
              style={{ padding: "6px 12px" }}
              key={option.value}
              onClick={(e) => {
                handleOnValueChange(
                  e,
                  uniqBy(
                    [
                      ...(Array.isArray(value) ? value : []),
                      ...filterOptions(
                        options,
                        {
                          inputValue: inputValue ?? "",
                          getOptionLabel: (option) => option.label,
                        },
                        false,
                      ),
                    ],
                    "value",
                  ),
                );
                closePopper();
              }}
            >
              {/*{multiple && withCheckboxes && (*/}
              {/*  <Checkbox style={{ marginRight: 8 }} checked={selected} />*/}
              {/*)}*/}
              <DoneAllIcon
                sx={{
                  height: "20px",
                  width: "20px",
                  mr: 2,
                  color: theme.palette.primary.main,
                }}
              />
              <ListItemText
                primary={option.label}
                {...(option?.secondaryLabel && {
                  secondary: option.secondaryLabel,
                })}
                primaryTypographyProps={{
                  sx: {
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                  },
                }}
              />
            </li>
          ) : (
            <li
              {...props}
              style={{ ...(withCheckboxes && multiple && { padding: 0 }) }}
              key={option.value}
            >
              {multiple && withCheckboxes && (
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
              )}
              <ListItemText
                primary={option.label}
                {...(option?.secondaryLabel && {
                  secondary: option.secondaryLabel,
                })}
              />
            </li>
          )
        }
        // renderOption={(props, option) => (
        //   <li {...props}>
        //     <ListItemText primary={option.label} />
        //   </li>
        // )}
      />
    </>
  );
};

export default InputComboBox;
