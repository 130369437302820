export const GrantsResourceServer =
  "https://konektid-grants-resources.s3.amazonaws.com/";
export const ContractsResourceServer =
  "https://konektid-contracts-resources.s3.amazonaws.com/";

export const AskAQuestionUrl =
  "https://airtable.com/appWIUzo8POY9mutx/shrXJJgGOiz4girPN";

export const goToUrlInNewTab = (url: string) => () =>
  window.open(url, "_blank");
