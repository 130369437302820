import React, { useState } from "react";
import UserContext from "../../services/UserContext";

import "date-fns";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import getWindowDimensions from "../../services/dimensions";

import CustomSearchSelect from "../../components/CustomSearchSelect/CustomSearchSelect";
import "../DataTable.css";
import "./USAid.css";

import {
  GetCustomColumnShortened,
  GetCustomColumnBody,
} from "../../components/DataTable/Columns";
import "datejs";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { DataTable } from "primereact/datatable";
import { Button as PButton } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { PanelHeader } from "../../components/DataTable/PanelHeader";
import * as ActionButtons from "../../components/DataTable/ActionButtons";

import { getDateString } from "../../services/formatter";
import GrowlContext from "../../services/growlContext";
import {
  tryCatchServerError,
  getSortIfDescendingFirst,
} from "../../services/functions";
import {
  DateFormat,
  formatDate,
  getEpochDateWithoutTimezone,
  getISODateWithAdjustedTimezone,
} from "../../utils/conversion/date-converters";
import { Popover } from "../../components/Popover/Popover";
import {
  buildExcelTable,
  generateExcel,
  genEmptyRow,
  getChunksFromString,
  MaxCharsAllowed,
} from "../../services/exporter";
import { TableProperties } from "exceljs";
import { useGridStyles } from "../../hooks/useGridStyles";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { useEnterListener } from "../../hooks/useEnterListener";
import {
  MaximizeGrid,
  MinimizeGrid,
} from "../../components/DataTable/ActionButtons";
import {
  rowsPerPageOptionsHighIncrement,
  rowsPerPageOptionsStandard,
} from "../../utils/constants/gridsOptions";
import axios, { AxiosRequestConfig } from "axios";
import { fetchDataNewEnvironment } from "../../services/dropdownOptionListProvider";
import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from "primereact/checkbox";
import { GetUsAidViewOld } from "../USAidOld/USAidOld";
import { Link } from "react-router-dom";
import { removeHtmlTags } from "../../services/dataParser";
import { format, formatISO } from "date-fns";
import { ProgressBar } from "primereact/progressbar";
import { Paginator } from "primereact/paginator";
import CardView from "./components/CardView";

const icons = {
  location: require("../../assets/icons/v2/icon_location.png")?.default,
  helmet: require("../../assets/icons/v2/icon_helmet.png")?.default,
  number: require("../../assets/icons/v2/icon_number.png")?.default,
  file: require("../../assets/icons/v2/icon_file.png")?.default,
  eligible: require("../../assets/icons/v2/icon_checklist.png")?.default,
  keyword: require("../../assets/icons/v2/icon-06.png")?.default,
  calendar: require("../../assets/icons/v2/icon_calendar.png")?.default,
  hands: require("../../assets/icons/Implementer_icon.png")?.default,
  arrowUp: require("../../assets/icons/v2/icon_up_arrow.png")?.default,
  arrowDown: require("../../assets/icons/v2/icon_down_arrow.png")?.default,
  building: require("../../assets/icons/v2/Funding_Agency_icon.png")?.default,
  bag: require("../../assets/icons/v2/Funding_Account_icon.png")?.default,
};

export interface UsAidRecord {
  isMyProject: boolean;
  activityEndDate: string;
  activityId: number;
  uniqueAwardId?: string;
  activityStartDate: string;
  country: string;
  disbursements: number;
  fundingAccount: string;
  fundingAgency: string;
  implementer: string;
  managingAgency: string;
  obligations: number;
  projectDescription: string;
  projectName: string;
  projectNumber: string;
  technicalSector: string;
  updatedOnUtc: string;
}

export interface UsaSpendingAwardDto {
  isMyProject: boolean;
  country: string;
  uniqueAwardId: string; // used in url
  projectNumber: string; // ID
  projectName: string;
  projectDescription: string;
  implementer: string;
  subawardRecipients: string;
  awardMechanism: string;
  awardingAgencyName: string;
  awardingOffice: string;
  parentIDVAgencyName: string;
  naicsCode: string;
  startDate: string | null;
  currentEndDate: string | null;
  potentialEndDate: string | null;
  obligatedAmount: number | null;
  currentAmount: number | null;
  potentialAmount: number | null;
  recipientAddress: string;
  createdOnUtc: string;
  setAside: string;
}

interface FilteredDate {
  start: {
    from: Date | null;
    to: Date | null;
  };
  close: {
    from: Date | null;
    to: Date | null;
  };
}

let todayDate = Date.today();

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const currencyFormatterNoDecimals = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const amountType = (amount: number) => {
  if (amount === 0) {
    return "";
  }
  if (amount.toString().length >= 10) {
    return "milliard";
  } else if (amount.toString().length >= 7) {
    return "million";
  } else if (amount.toString().length >= 4) {
    return "thousand";
  } else {
    return "";
  }
};

function USAidScreen() {
  const [projectSource, setProjectSource] = React.useState({
    fae: false,
    usaspending: true,
  });
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  React.useEffect(() => {
    setIsCollapsed(false); // expand on first load
  }, []);

  // Initial prarams
  let onlyMyRecordsArgument = false;
  const styles = useGridStyles();

  return (
    <div className="p-grid p-dir-col">
      <div className="p-grid p-justify-start">
        <div className="p-col-12" style={{ textAlign: "start" }}>
          <PButton
            style={{
              ...styles.mySuggestionsOptions(
                projectSource.usaspending,
                "yellow",
              ),
              marginRight: 20,
            }}
            onClick={() => setProjectSource({ fae: false, usaspending: true })}
          >
            USA Spending
          </PButton>
          <PButton
            style={{
              ...styles.mySuggestionsOptions(projectSource.fae, "yellow"),
            }}
            onClick={() => setProjectSource({ fae: true, usaspending: false })}
          >
            Foreign Assistance
          </PButton>
        </div>
      </div>

      {projectSource.fae && (
        <GetUsAidView
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          onlyMyRecordsArgument={onlyMyRecordsArgument}
        />
      )}
      {projectSource.usaspending && (
        <GetUsaSpendingView
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          onlyMyRecordsArgument={onlyMyRecordsArgument}
        />
      )}
    </div>
  );
}

export default USAidScreen;

const managingOptions = [
  "U.S. Agency for International Development",
  "Department of Agriculture",
  "Federal Trade Commission",
  "Department of State",
  "Department of Energy",
];

const GetUsAidView = ({
  setIsCollapsed,
  onlyMyRecordsArgument,
  isCollapsed,
}: {
  isCollapsed: boolean;
  setIsCollapsed: (x: boolean) => void;
  onlyMyRecordsArgument: boolean;
}) => {
  const context = React.useContext(UserContext);

  // Stylings
  const [gridWidth, setGridWidth] = React.useState(0);
  const [collapsed, setCollapsed] = React.useState(isCollapsed);

  const setCollapsedFunc = (value) => {
    setCollapsed(value);
    // setIsCollapsed(value);
  };
  React.useEffect(() => {
    function handleResize() {
      const { width, headerWidth } = getWindowDimensions();
      if (width !== null && headerWidth != null) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Grid required properties
  const [data, setData] = React.useState(Array<UsAidRecord>());
  const [isLoading, setIsLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalRecords, setTotalRecords] = React.useState(0); // todo
  const [first, setFirst] = React.useState(0);
  const [sortField, setSortField] = React.useState("UpdatedOnUtc");
  const [sortOrder, setSortOrder] = React.useState(-1);
  const [lastUpdated, setLastUpdated] = React.useState("");
  const [selectedCountryFilter, setSelectedCountryFilter] = React.useState(
    Array<string>(),
  );
  const [seletedFundingAgencyFilter, setSeletedFundingAgencyFilter] =
    React.useState(Array<string>());
  const [seletedFundingAccountFilter, setSeletedFundingAccountFilter] =
    React.useState(Array<string>());

  //Available Options
  const [aidCounryNamesOptions, setAidCountryNamesOptions] = React.useState(
    Array<string>(),
  );
  const [fundingAgencyOptions, setFundingAgencyOptions] = React.useState(
    Array<string>(),
  );
  const [aidImplementorsOptions, setAidImplementorsOptions] = React.useState(
    Array<string>(),
  );
  const [technicalSectorsOptions, setTechnicalSectorsOptions] = React.useState(
    Array<string>(),
  );
  const [fundingAccountOptions, setFundingAccountOptions] = React.useState(
    Array<string>(),
  );

  const [selectedImplementer, setSelectedImplementer] = React.useState(
    Array<string>(),
  );

  React.useEffect(() => {
    let apiUrl = "/api/ForeignAssistance/dropdownoptions/";
    let aidCounryNamesUrl = apiUrl + "country";
    let aidImplementorsUrl = apiUrl + "implementor";
    let technicalSectorsUrl = apiUrl + "technicalSector";
    let regionNamesUrl = apiUrl + "region";
    let subagencyNamesUrl = apiUrl + "subagency";
    let agencyUrl = apiUrl + "fundingAgency";
    let fundingAccountUrl = apiUrl + "fundingAccount";

    let baseMessage = "error fetching available ";
    let aidCounryNamesMessage = baseMessage + "aid country names";
    let aidImplementorsMessage = baseMessage + "aid implementors";
    let technicalSectorsMessage = baseMessage + "technical sectors";
    let regionNamesMessage = baseMessage + "region names";
    let subagencyNamesMessage = baseMessage + "subagency names";
    let agencyNamesMessage = baseMessage + "agnency names";
    let fundingAccountsMessage = baseMessage + "funding account names";

    fetchDataNewEnvironment(
      aidCounryNamesUrl,
      setAidCountryNamesOptions,
      context,
      aidCounryNamesMessage,
    );
    fetchDataNewEnvironment(
      aidImplementorsUrl,
      setAidImplementorsOptions,
      context,
      aidImplementorsMessage,
      true,
    );
    fetchDataNewEnvironment(
      technicalSectorsUrl,
      setTechnicalSectorsOptions,
      context,
      technicalSectorsMessage,
      true,
    );

    fetchDataNewEnvironment(
      agencyUrl,
      setFundingAgencyOptions,
      context,
      agencyNamesMessage,
      true,
    );

    fetchDataNewEnvironment(
      fundingAccountUrl,
      setFundingAccountOptions,
      context,
      fundingAccountsMessage,
      true,
    );
  }, []);

  // Filters
  const [closingStartDate, setClosingStartDate] = React.useState<Date | null>(
    todayDate,
  );
  const [closingEndDate, setClosingEndDate] = React.useState<Date | null>(null);
  const [updatedStartingDateFilterError, setUpdatedStartedDateFilterError] =
    React.useState(false);
  const [updatedClosingDateFilterError, setUpdatedClosingDateFilterError] =
    React.useState(false);
  const [recentlyAwardedProjects, setRecentlyAwardedProjects] =
    React.useState<boolean>(false);
  const [updatedDateFilter, setUpdatedDateFilter] =
    React.useState<FilteredDate>({
      start: {
        from: null,
        to: null,
      },
      close: {
        from: todayDate,
        to: null,
      },
    });

  const [withMinimumAmount, setWithMinimumAmount] = React.useState(true);
  const [minimumAmount, setMinimumAmount] = React.useState(500000);

  const [updatedStartFromDateFilterError, setUpdatedStartFromDateFilterError] =
    React.useState(false);
  const [updatedStartToDateFilterError, setUpdatedStartToDateFilterError] =
    React.useState(false);
  const [updatedCloseFromDateFilterError, setUpdatedCloseFromDateFilterError] =
    React.useState(false);
  const [updatedCloseToDateFilterError, setUpdatedCloseToDateFilterError] =
    React.useState(false);
  // const [selectedCountryFilter, setSelectedCountryFilter] = React.useState(
  //   Array<string>()
  // );
  const [selectedTechnicalSector, setTechnicalSector] = React.useState(
    Array<string>(),
  );
  // const [selectedImplementingPartner, setSelectedImplementingAgency] =
  //   React.useState(Array<string>());
  const [keyword, setKeyword] = React.useState("");
  const [managingAgency, setManagingAgency] = React.useState(Array<string>());
  const [name, setName] = React.useState("");
  const [onlyMyList, setOnlyMyList] = React.useState(onlyMyRecordsArgument);
  // const [regionName, setRegionName] = React.useState(Array<string>());
  // const [subagencyName, setSubagencyName] = React.useState(Array<string>());
  const [activityProjectNumber, setActivityProjectNumber] = React.useState("");

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState("");
  const [dialogVisible, setDialogVisible] = React.useState(false);

  const [query, setQuery] = React.useState<URLSearchParams>();

  const depList = [
    closingStartDate,
    closingEndDate,
    name,
    keyword,
    onlyMyList,
    activityProjectNumber,
    rowsPerPage,
    sortField,
    sortOrder,
    first,
    totalRecords,
  ];

  React.useEffect(() => {
    if (recentlyAwardedProjects) {
      setUpdatedDateFilter({
        start: {
          from: new Date(new Date().setDate(new Date().getDate() - 90)),
          to: todayDate,
        },
        close: {
          from: null,
          to: null,
        },
      });
    } else {
      setUpdatedDateFilter({
        start: {
          from: null,
          to: null,
        },
        close: {
          from: todayDate,
          to: null,
        },
      });
    }
  }, [recentlyAwardedProjects]);

  const queryCallback = React.useCallback(
    () =>
      queryData({
        first,
        rows: rowsPerPage,
        sortField,
        sortOrder,
      }),
    depList,
  );
  useEnterListener(queryCallback);

  const dt = React.useRef<DataTable>(null);
  const [isGridFullScreen, setIsGridFullScreen] = React.useState(false);
  const growl = React.useContext(GrowlContext);
  const styles = useGridStyles();

  React.useEffect(() => {
    const onDescriptionClick = (event: any) => {
      if (!event.target.matches(".abstract")) {
        return;
      }

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      setDialogHeader(container.attributes["data-title"].value);
      setDialogText(dialogText);
      setDialogVisible(true);
    };
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
    };
  }, []);

  function sort(event: { sortField: string; sortOrder: number }) {
    const newSortOrder = getSortIfDescendingFirst(event, sortField);
    setSortField(event.sortField);
    setSortOrder(newSortOrder);
    queryData({
      first,
      rows: rowsPerPage,
      sortField: event.sortField,
      sortOrder: newSortOrder,
    });
  }

  function queryData(event: {
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
  }) {
    setRowsPerPage(event.rows);

    setFirst(event.first);
    setRowsPerPage(event.rows);
    let request = createAuthenticatedRequest(context);
    setIsLoading(true);
    const url = "/api/ForeignAssistance";
    const queryString = new URLSearchParams();

    if (activityProjectNumber) {
      queryString.set("projectNumber", activityProjectNumber);
    }

    if (selectedCountryFilter.length) {
      queryString.set("country", selectedCountryFilter.join("|"));
    }

    if (selectedImplementer.length) {
      queryString.set("implementer", selectedImplementer.join("|"));
    }

    if (selectedTechnicalSector.length) {
      queryString.set("sector", selectedTechnicalSector.join("|"));
    }

    if (seletedFundingAgencyFilter.length) {
      queryString.set("fundingAgency", seletedFundingAgencyFilter.join("|"));
    }

    if (seletedFundingAccountFilter) {
      queryString.set("fundingAccount", seletedFundingAccountFilter.join("|"));
    }

    if (onlyMyList) {
      queryString.set("isMyList", onlyMyList.toString());
    }

    if (withMinimumAmount && minimumAmount > 0) {
      queryString.set("minimumAmount", minimumAmount.toString());
    }

    let agencyQueryString = "";
    if (managingAgency.length) {
      managingAgency.forEach((agency) => {
        agencyQueryString = `${agencyQueryString}&managingAgencyNames=${agency}`;
      });
    }
    //
    // if (closingStartDate && !updatedStartingDateFilterError) {
    //   queryString.set(
    //     "closingStartDate",
    //     closingStartDate.toISOString().substring(0, 10)
    //   );
    // }
    //
    // if (closingEndDate && !updatedClosingDateFilterError) {
    //   queryString.set(
    //     "closingEndDate",
    //     closingEndDate.toISOString().substring(0, 10)
    //   );
    // }
    //
    // if (regionName.length) {
    //   queryString.set("regionName", regionName.join("|"));
    // }
    //
    if (updatedDateFilter.close.from && !updatedCloseFromDateFilterError) {
      queryString.set(
        "dateCloseFrom",
        // updatedDateFilter.close.from.toISOString().substring(0, 10),
        getISODateWithAdjustedTimezone(updatedDateFilter.close.from),
      );
    }

    if (updatedDateFilter.close.to && !updatedCloseToDateFilterError) {
      queryString.set(
        "dateCloseTo",
        // updatedDateFilter.close.to.toISOString().substring(0, 10),
        getISODateWithAdjustedTimezone(updatedDateFilter.close.to),
      );
    }

    if (updatedDateFilter.start.from && !updatedStartFromDateFilterError) {
      queryString.set(
        "dateStartFrom",
        // updatedDateFilter.start.from.toISOString().substring(0, 10),
        getISODateWithAdjustedTimezone(updatedDateFilter.start.from),
      );
    }

    if (updatedDateFilter.start.to && !updatedStartToDateFilterError) {
      queryString.set(
        "dateStartTo",
        // updatedDateFilter.start.to.toISOString().substring(0, 10),
        getISODateWithAdjustedTimezone(updatedDateFilter.start.to),
      );
    }

    if (keyword) {
      queryString.set("Keyword", keyword);
    }

    if (name) {
      queryString.set("name", name);
    }

    setFirst(event.first);
    const pageIndex = event.first / rowsPerPage;
    queryString.set("pageIndex", pageIndex.toString());
    queryString.set("pageSize", event.rows.toString());

    queryString.set("sortField", event.sortField || sortField);
    queryString.set("sortOrder", (event.sortOrder || sortOrder).toString());

    setQuery(queryString);

    axios
      .get(
        getFullUrl(`${url}?${queryString.toString()}${agencyQueryString}`, {
          useDedicatedEnvironment: true,
        }),
        request,
      )
      .then((response) => {
        const { totalItems, data, lastUpdatedUtc } = response.data;
        setTotalRecords(totalItems);

        // Set top records
        const d = data as Array<UsAidRecord>;
        setData(d);
        setLastUpdated(
          lastUpdatedUtc
            ? `Last updated ${getDateString(new Date(lastUpdatedUtc + "Z"))}`
            : "",
        );
        setIsLoading(false);
      })
      .catch((error) => {
        alert("error fetching data");
        setIsLoading(false);
      });
  }

  const [disableExcel, setDisableExcel] = React.useState(false);

  const tableHeader = (
    <div className="p-grid p-justify-end p-align-center">
      <div
        className="p-col-12 p-md-8 p-lg-8 topLeftColumnHeader"
        style={{ textAlign: "left" }}
      >
        <div>Source: Foreign Assistance</div>
        <div>{lastUpdated}</div>
      </div>

      <div className="p-col-12 p-md-4 p-lg-4 p-justify-end buttonsHeader">
        <div className="button-export">
          <PButton
            type="button"
            icon="pi pi-file-excel"
            iconPos="left"
            onClick={async () => {
              setDisableExcel(true);
              generateExcel(
                "Projects+",
                `Aidkonekt_projects_plus_${new Date().getFullYear()}`,
                getExportableDataTable(
                  getAssistantExcelData ? await getAssistantExcelData() : data,
                ),
              ).then(() => {
                setDisableExcel(false);
              });
            }}
            tooltip="Excel"
            tooltipOptions={{ position: "top" }}
            disabled={disableExcel}
            style={{
              marginLeft: 15,
              marginRight: 15,
              ...styles.tableHeaderButton,
            }}
          />
        </div>
        <div className="button-export">
          <PButton
            type="button"
            icon={
              isGridFullScreen
                ? "pi pi-window-minimize"
                : "pi pi-window-maximize"
            }
            iconPos="left"
            onClick={(e) => {
              if (isGridFullScreen) {
                MinimizeGrid(e.target as HTMLElement);
              } else {
                MaximizeGrid(e.target as HTMLElement, () =>
                  setIsGridFullScreen(false),
                );
              }

              setIsGridFullScreen(!isGridFullScreen);
            }}
            tooltip={isGridFullScreen ? "Minimize" : "Maximize"}
            tooltipOptions={{ position: "top" }}
            style={{
              marginLeft: 15,
              marginRight: 15,
              ...styles.tableHeaderButton,
            }}
          />
        </div>
      </div>
    </div>
  );

  const getExportableDataTable = (data: UsAidRecord[]): TableProperties => {
    const columns = [
      { name: "Country" },
      { name: "Project Number" },
      { name: "Project Name" },
      { name: "Project Description" },
      { name: "Implementer" },
      { name: "Technical Sector" },
      { name: "Managing Agency" },
      { name: "Funding Agency" },
      { name: "Funding Account" },
      { name: "Activity Start Date" },
      { name: "Activity End Date" },
      { name: "Obligations" },
      { name: "Disbursements" },
    ];

    let rows: any[][] = [];

    if (data.length == 0) {
      rows.push(genEmptyRow(columns.length));
    } else {
      for (let i = 0; i < data.length; i++) {
        const record = data[i];

        rows.push([
          record.country,
          record.projectNumber?.toLowerCase() === "null"
            ? ""
            : record.projectNumber,
          record.projectName,
          record.projectDescription,
          record.implementer,
          record.technicalSector,
          record.managingAgency,
          record.fundingAgency,
          record.fundingAccount,
          formatDate(record.activityStartDate, DateFormat.dd_MMMM_yyyy),
          formatDate(record.activityEndDate, DateFormat.dd_MMMM_yyyy),
          `${currencyFormatter.format(record.obligations)}`,
          `${currencyFormatter.format(record.disbursements)}`,
        ]);
      }
    }

    return buildExcelTable("ProjectsExport", columns, rows);
  };

  var renderSearch = () => {
    return (
      <div>
        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.location} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!aidCounryNamesOptions.length ? (
              <CustomSearchSelect
                placeholder="Country"
                setSelectedOption={setSelectedCountryFilter}
                allOptions={aidCounryNamesOptions}
                disabled={isLoading || !aidCounryNamesOptions}
                addAllFilteredResults={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.helmet} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!technicalSectorsOptions.length ? (
              <CustomSearchSelect
                placeholder="Technical Sector"
                setSelectedOption={setTechnicalSector}
                allOptions={technicalSectorsOptions}
                disabled={isLoading || !technicalSectorsOptions}
                addAllFilteredResults={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.hands} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!aidImplementorsOptions.length ? (
              <CustomSearchSelect
                placeholder="Implementer"
                setSelectedOption={setSelectedImplementer}
                allOptions={aidImplementorsOptions}
                disabled={isLoading || !aidImplementorsOptions}
                addAllFilteredResults={true}
                useContainsFilter={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.building} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!fundingAgencyOptions.length ? (
              <CustomSearchSelect
                placeholder="Funding Agency"
                setSelectedOption={setSeletedFundingAgencyFilter}
                allOptions={fundingAgencyOptions}
                disabled={isLoading || !fundingAgencyOptions}
                addAllFilteredResults={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.bag} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!fundingAccountOptions.length ? (
              <CustomSearchSelect
                placeholder="Funding Account"
                setSelectedOption={setSeletedFundingAccountFilter}
                allOptions={fundingAccountOptions}
                disabled={isLoading || !fundingAccountOptions}
                addAllFilteredResults={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.number} alt="search" style={styles.icon} />
          </div>
          <div
            className="p-col"
            style={{ textAlign: "left", display: "block" }}
          >
            <InputText
              style={styles.filters.inputBox}
              id="activityProjectNumber"
              type="search"
              value={activityProjectNumber}
              onChange={(e: any) =>
                setActivityProjectNumber((e.target as any).value)
              }
              placeholder="Project Number"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.keyword} alt="search" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            <InputText
              style={styles.filters.inputBox}
              id="keyword"
              type="search"
              value={keyword}
              onChange={(e: any) => setKeyword((e.target as any).value)}
              placeholder="Keyword"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.keyword} alt="search" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            <InputText
              style={styles.filters.inputBox}
              id="name"
              type="search"
              value={name}
              onChange={(e: any) => setName((e.target as any).value)}
              placeholder="Name"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.calendar} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left", display: "flex" }}>
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              Show projects closing between{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              value={updatedDateFilter.close.from}
              onError={(e) => setUpdatedStartedDateFilterError(!!e)}
              onChange={(date) =>
                setUpdatedDateFilter({
                  start: updatedDateFilter.start,
                  close: {
                    from: date,
                    to: updatedDateFilter.close.to,
                  },
                })
              }
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading}
            />
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              {" "}
              and{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              value={updatedDateFilter.close.to}
              onError={(e) => setUpdatedClosingDateFilterError(!!e)}
              // onChange={(date) => setClosingEndDate(date)}
              onChange={(date) =>
                setUpdatedDateFilter({
                  start: updatedDateFilter.start,
                  close: {
                    from: updatedDateFilter.close.from,
                    to: date,
                  },
                })
              }
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading}
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <Checkbox
              inputId="withMimimumAmountCB"
              value="withMinimumAmount"
              onChange={(e) => setWithMinimumAmount(e.checked)}
              checked={withMinimumAmount}
            />
          </div>
          <div className="p-col" style={{ textAlign: "left", display: "flex" }}>
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              Only show Projects over{" "}
            </label>
            <InputNumber
              style={styles.filters.inputTextLabel}
              id="minimumAmount"
              mode="currency"
              currency="USD"
              locale="en-US"
              maxFractionDigits={0}
              value={minimumAmount}
              onChange={(e: any) => setMinimumAmount(e.value)}
              placeholder="Minimum Amount"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={{ ...styles.filters.notTopFilterRow, paddingTop: 7 }}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.calendar} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left", display: "flex" }}>
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              Show project starting between{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              value={updatedDateFilter.start.from}
              onError={(e) => setUpdatedStartFromDateFilterError(!!e)}
              onChange={(date) =>
                setUpdatedDateFilter({
                  start: {
                    from: date,
                    to: updatedDateFilter.start.to,
                  },
                  close: updatedDateFilter.close,
                })
              }
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading || recentlyAwardedProjects}
            />
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              {" "}
              and{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              value={updatedDateFilter.start.to}
              onError={(e) => setUpdatedStartToDateFilterError(!!e)}
              onChange={(date) =>
                setUpdatedDateFilter({
                  start: {
                    from: updatedDateFilter.start.from,
                    to: date,
                  },
                  close: updatedDateFilter.close,
                })
              }
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading || recentlyAwardedProjects}
            />
          </div>
        </div>

        <div
          className="p-col-12 p-md-6 p-lg-12"
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 7,
            marginBottom: 10,
            marginLeft: 5,
            textAlign: "left",
          }}
        >
          <Checkbox
            inputId="swa"
            value="showAwardsOnly"
            onChange={(e) => setRecentlyAwardedProjects(e.checked)}
            checked={recentlyAwardedProjects}
          />
          <label
            htmlFor="swa"
            className="p-checkbox-label"
            style={{
              ...styles.filters.inputTextLabel,
              marginLeft: 20,
              marginBottom: 3,
            }}
          >
            Show recently awarded projects (last 90 days)
          </label>
        </div>

        <div className="p-grid" style={styles.filters.notTopFilterRow}>
          <div className="p-col" style={styles.filters.searchButtonContainer}>
            <PButton
              style={styles.filters.searchButton}
              onClick={() =>
                queryData({
                  first,
                  rows: rowsPerPage,
                  sortField,
                  sortOrder,
                })
              }
            >
              <div style={styles.filters.searchButtonText}>Search</div>
            </PButton>
          </div>
        </div>
      </div>
    );
  };

  // var getExcelData = async (): Promise<UsAidRecord[]> => {
  //   var base = "/api/usaid";
  //   var q = query;
  //   if (q) q.set("pageSize", "1000");
  //   var qu = q ? base + "?" + q.toString() : base;
  //   let request = createAuthenticatedRequest(context);
  //   var result = Array<UsAidRecord>();
  //   await axios
  //     .get(getFullUrl(qu, { useDedicatedEnvironment: true }), request)
  //     .then((response) => {
  //       result = response.data.data;
  //     })
  //     .catch((error) => {
  //       alert("error fetching data");
  //     });
  //   return result;
  // };

  var getAssistantExcelData = async (): Promise<UsAidRecord[]> => {
    var base = "/api/ForeignAssistance";
    var q = query;
    if (q) q.set("pageSize", "1000");
    var qu = q ? base + "?" + q.toString() : base;
    let request = createAuthenticatedRequest(context);
    var result = Array<UsAidRecord>();
    await axios
      .get(getFullUrl(qu, { useDedicatedEnvironment: true }), request)
      .then((response) => {
        result = response.data.data;
      })
      .catch((error) => {
        alert("error fetching data");
      });
    return result;
  };

  const addRecordToMyList = async (record: UsAidRecord) => {
    const activityId =
      !isTableView && record.uniqueAwardId
        ? +record.uniqueAwardId
        : record.activityId;
    try {
      await axios.post(
        getFullUrl("/api/ForeignAssistance/mylist", {
          useDedicatedEnvironment: true,
        }),
        { uniqueId: activityId },
        createRequestWithAuthHeaders(context),
      );
      const newData = [...data];
      newData
        .filter((r: Partial<UsAidRecord>) => r.activityId === activityId)
        .forEach((r: UsAidRecord) => (r.isMyProject = true));
      setData(newData);
      growl.current.show({
        severity: "success",
        summary: "Added",
        detail: `Added USA Record '${record.projectNumber}' to my list`,
      });
    } catch (e) {
      growl.current.show({
        severity: "error",
        summary: "Error",
        detail: e,
        sticky: true,
      });
    }
  };

  const removeRecordFromMyList = (record: UsAidRecord) => {
    const activityId =
      !isTableView && record.uniqueAwardId
        ? +record.uniqueAwardId
        : record.activityId;
    let request: AxiosRequestConfig = {
      ...createRequestWithAuthHeaders(context),
      data: { uniqueId: activityId },
    };
    axios
      .delete(
        getFullUrl("/api/ForeignAssistance/mylist", {
          useDedicatedEnvironment: true,
        }),
        request,
      )
      .then(() => {
        // re-set my list status for all items with this id
        const newData = [...data];
        newData
          .filter((r: Partial<UsAidRecord>) => r.activityId === activityId)
          .forEach((r: UsAidRecord) => (r.isMyProject = false));
        setData(newData);
        growl.current.show({
          severity: "success",
          summary: "Removed",
          detail: `Removed '${record.projectNumber}' from my list`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          }),
        ),
      );
  };

  const ActionsButtonColumn = ({ record }: { record: UsAidRecord }) => {
    const add = (
      <ActionButtons.AddToMyListButton add={() => addRecordToMyList(record)} />
    );
    const remove = (
      <ActionButtons.RemoveFromMyListButton
        remove={() => removeRecordFromMyList(record)}
      />
    );
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {record.isMyProject ? remove : add}
      </div>
    );
  };

  const [isTableView, setIsTableView] = React.useState(true);

  const queryCardData = (event: {
    first: number;
    rows: number;
    page: number;
    pageCount: number;
  }) =>
    queryData({
      first: event.first,
      rows: event.rows,
      sortField,
      sortOrder,
    });

  const ViewColumnBody = ({ record }: { record: UsAidRecord }) => {
    record.projectName = record.projectName
      ? record.projectName.replace("&", "and")
      : "";

    return (
      <Link
        to={`/project-view/record?projectNumber=${
          record.projectNumber
        }&fa=${true}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <button className="view-column-btn">
          <div>
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.4402 5.7C13.9252 2.1825 11.0752 0 8.00017 0C4.92517 0 2.07517 2.1825 0.560172 5.7C0.518875 5.79462 0.497559 5.89676 0.497559 6C0.497559 6.10324 0.518875 6.20538 0.560172 6.3C2.07517 9.8175 4.92517 12 8.00017 12C11.0752 12 13.9252 9.8175 15.4402 6.3C15.4815 6.20538 15.5028 6.10324 15.5028 6C15.5028 5.89676 15.4815 5.79462 15.4402 5.7ZM8.00017 10.5C5.62267 10.5 3.37267 8.7825 2.07517 6C3.37267 3.2175 5.62267 1.5 8.00017 1.5C10.3777 1.5 12.6277 3.2175 13.9252 6C12.6277 8.7825 10.3777 10.5 8.00017 10.5ZM8.00017 3C7.40683 3 6.82681 3.17595 6.33346 3.50559C5.84011 3.83524 5.4556 4.30377 5.22853 4.85195C5.00147 5.40013 4.94206 6.00333 5.05782 6.58527C5.17357 7.16721 5.45929 7.70176 5.87885 8.12132C6.29841 8.54088 6.83296 8.8266 7.4149 8.94236C7.99684 9.05811 8.60004 8.9987 9.14822 8.77164C9.6964 8.54458 10.1649 8.16006 10.4946 7.66671C10.8242 7.17336 11.0002 6.59334 11.0002 6C11.0002 5.20435 10.6841 4.44129 10.1215 3.87868C9.55888 3.31607 8.79582 3 8.00017 3ZM8.00017 7.5C7.7035 7.5 7.41349 7.41203 7.16682 7.2472C6.92014 7.08238 6.72788 6.84811 6.61435 6.57403C6.50082 6.29994 6.47112 5.99834 6.52899 5.70736C6.58687 5.41639 6.72973 5.14912 6.93951 4.93934C7.14929 4.72956 7.41657 4.5867 7.70754 4.52882C7.99851 4.47094 8.30011 4.50065 8.5742 4.61418C8.84829 4.72771 9.08255 4.91997 9.24738 5.16665C9.4122 5.41332 9.50017 5.70333 9.50017 6C9.50017 6.39782 9.34214 6.77936 9.06083 7.06066C8.77953 7.34196 8.398 7.5 8.00017 7.5Z"
                fill="white"
              />
            </svg>
            <span>View</span>
          </div>
        </button>
      </Link>
    );
  };

  return (
    <>
      <div className="p-grid" style={{ alignItems: "center" }}>
        <div className="name-with-view">
          <div className="p-col-12" style={styles.pageHeader}>
            Projects Enhanced USAID Project Data
          </div>
          <div className="button-export">
            {isTableView ? (
              <div style={{ width: "60px" }}></div>
            ) : (
              <PButton
                type="button"
                icon="pi pi-file-excel"
                iconPos="left"
                onClick={async () => {
                  setDisableExcel(true);
                  try {
                    await generateExcel(
                      "Foreign Assistance",
                      `Aidkonekt_foreign_assistance_${new Date().getFullYear()}`,
                      getExportableDataTable(
                        getAssistantExcelData
                          ? await getAssistantExcelData()
                          : data,
                      ),
                    );
                  } catch (e) {
                    growl.current.show({
                      severity: "error",
                      summary: "Error",
                      detail: e,
                    });
                  } finally {
                    setDisableExcel(false);
                  }
                }}
                tooltip="Excel"
                tooltipOptions={{ position: "top" }}
                disabled={disableExcel}
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  ...styles.tableHeaderButton,
                }}
              />
            )}
          </div>
          <div className="view-switch-container">
            <div
              className="view-switch-table"
              style={{
                background: isTableView ? "#605F5F" : "#D9D9D9",
              }}
              onClick={() => setIsTableView(true)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V19C0 19.2652 0.105357 19.5196 0.292893 19.7071C0.48043 19.8946 0.734784 20 1 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0ZM6 18H2V2H6V18ZM12 18H8V2H12V18ZM18 18H14V2H18V18Z"
                  fill={isTableView ? "white" : "#605F5F"}
                />
              </svg>
            </div>
            <div
              style={{
                background: !isTableView ? "#605F5F" : "#D9D9D9",
              }}
              className="view-switch-card"
              onClick={() => setIsTableView(false)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 13H3C2.73478 13 2.48043 13.1054 2.29289 13.2929C2.10536 13.4804 2 13.7348 2 14V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H10C10.2652 22 10.5196 21.8946 10.7071 21.7071C10.8946 21.5196 11 21.2652 11 21V14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13ZM9 20H4V15H9V20ZM21 2H14C13.7348 2 13.4804 2.10536 13.2929 2.29289C13.1054 2.48043 13 2.73478 13 3V10C13 10.2652 13.1054 10.5196 13.2929 10.7071C13.4804 10.8946 13.7348 11 14 11H21C21.2652 11 21.5196 10.8946 21.7071 10.7071C21.8946 10.5196 22 10.2652 22 10V3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2ZM20 9H15V4H20V9ZM21 13H14C13.7348 13 13.4804 13.1054 13.2929 13.2929C13.1054 13.4804 13 13.7348 13 14V21C13 21.2652 13.1054 21.5196 13.2929 21.7071C13.4804 21.8946 13.7348 22 14 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21V14C22 13.7348 21.8946 13.4804 21.7071 13.2929C21.5196 13.1054 21.2652 13 21 13ZM20 20H15V15H20V20ZM10 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V10C2 10.2652 2.10536 10.5196 2.29289 10.7071C2.48043 10.8946 2.73478 11 3 11H10C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10V3C11 2.73478 10.8946 2.48043 10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2ZM9 9H4V4H9V9Z"
                  fill={!isTableView ? "white" : "#605F5F"}
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="p-col-10" style={{ ...styles.sectionSubheader }}>
          Search current and past USAID projects by country, sector, and closing
          date. This page allows you to quickly sort USAID project activity by
          closing date for your target countries, competitors, and technical
          sectors. This tool is designed to deliver rapid, landscape snapshots
          of USAID projects, and may not have every USAID project.
        </div>
      </div>

      <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Panel
            style={styles.filters.filterBox}
            collapsed={collapsed}
            onToggle={(e) => {
              setCollapsedFunc(e.value);
            }}
            toggleable
            expandIcon=""
            collapseIcon=""
            header={
              <PanelHeader
                search={() =>
                  queryData({ first, rows: rowsPerPage, sortField, sortOrder })
                }
                isCollapsed={collapsed}
                setIsCollapsed={setCollapsedFunc}
                expandedTitle={
                  <div
                    className="p-grid p-justify-start"
                    style={{ ...styles.filters.fontHeader }}
                  >
                    <div
                      className="p-col-12 p-md-6 p-lg-4"
                      style={{ textAlign: "left" }}
                    >
                      <RadioButton
                        id="allprojects"
                        value="0"
                        name="allprojects"
                        onChange={() => setOnlyMyList(false)}
                        checked={!onlyMyList}
                      />
                      <label style={{ marginLeft: 5 }} htmlFor="allprojects">
                        Show all Projects
                      </label>
                    </div>
                    <div
                      className="p-col-12 p-md-6 p-lg-4"
                      style={{ textAlign: "left" }}
                    >
                      <RadioButton
                        id="myprojects"
                        value="1"
                        name="myprojects"
                        onChange={() => setOnlyMyList(true)}
                        checked={onlyMyList}
                      />
                      <label style={{ marginLeft: 5 }} htmlFor="myprojects">
                        Show my Projects
                      </label>
                    </div>
                  </div>
                }
              />
            }
          >
            {renderSearch()}
          </Panel>
        </MuiPickersUtilsProvider>

        <Popover
          dialogHeader={dialogHeader}
          isVisible={dialogVisible}
          dialogText={dialogText}
          setDialogVisible={setDialogVisible}
        />

        {isTableView ? (
          <DataTable
            value={data}
            style={{ marginTop: 20, width: `${gridWidth - 20}px` }}
            paginator={true}
            rows={rowsPerPage}
            totalRecords={totalRecords}
            lazy={true}
            first={first}
            onPage={queryData}
            loading={isLoading}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={sort}
            scrollable={true}
            scrollHeight="400px"
            ref={dt}
            header={tableHeader}
            exportFilename={`Aidkonekt_projects_plus_${new Date().getFullYear()}`}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            rowsPerPageOptions={rowsPerPageOptionsStandard}
          >
            {GetCustomColumnBody<UsAidRecord>(
              styles,
              150,
              (x) => (
                <ViewColumnBody record={x} />
              ),
              "View",
              "view",
            )}
            {GetCustomColumnBody<UsAidRecord>(
              styles,
              150,
              (x) => (
                <div className="actions-column">
                  <ActionsButtonColumn record={x} />
                </div>
              ),
              "Add to My Projects",
              "activityProjectNumber",
              { sortable: false },
            )}
            {GetCustomColumnBody<UsAidRecord>(
              styles,
              150,
              (x) => x.country,
              "Country",
              "country",
            )}
            {GetCustomColumnBody<UsAidRecord>(
              styles,
              150,
              (x) =>
                x.projectNumber?.toLowerCase() === "null"
                  ? ""
                  : x.projectNumber,
              "Project Number",
              "projectNumber",
            )}
            {GetCustomColumnBody<UsAidRecord>(
              styles,
              350,
              (x) => x.projectName,
              "Project Name",
              "projectName",
            )}
            {GetCustomColumnShortened<UsAidRecord>(
              styles,
              450,
              (x) => x.projectDescription,
              (x) => x.projectName,
              "Project Description",
              150,
              true,
              "projectDescription",
            )}
            {GetCustomColumnBody<UsAidRecord>(
              styles,
              200,
              (x) => x.implementer,
              "Implementer",
              "implementer",
            )}
            {GetCustomColumnBody<UsAidRecord>(
              styles,
              200,
              (x) => x.technicalSector,
              "Technical Sector",
              "technicalSector",
            )}
            {GetCustomColumnBody<UsAidRecord>(
              styles,
              250,
              (x) => x.managingAgency,
              "Managing Agency",
              "managingAgency",
            )}
            {GetCustomColumnBody<UsAidRecord>(
              styles,
              250,
              (x) => x.fundingAgency,
              "Funding Agency",
              "fundingAgency",
            )}
            {GetCustomColumnBody<UsAidRecord>(
              styles,
              350,
              (x) => x.fundingAccount,
              "Funding Account",
              "fundingAccount",
            )}
            {GetCustomColumnBody<UsAidRecord>(
              styles,
              150,
              (x) => {
                if (!x.activityStartDate) {
                  return "";
                }

                const date = new Date(x.activityStartDate);
                const formattedDate = new Date(
                  Date.UTC(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    0,
                    0,
                    0,
                  ),
                );
                return formatDate(formattedDate, DateFormat.dd_MMMM_yyyy);
              },
              "Activity Start Date",
              "activityStartDate",
            )}
            {GetCustomColumnBody<UsAidRecord>(
              styles,
              150,
              (x) => {
                if (!x.activityEndDate) {
                  return "";
                }

                const date = new Date(x.activityEndDate);
                const formattedDate = new Date(
                  Date.UTC(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    0,
                    0,
                    0,
                  ),
                );
                return formatDate(formattedDate, DateFormat.dd_MMMM_yyyy);
              },
              "Activity End Date",
              "activityEndDate",
            )}
            {GetCustomColumnBody<UsAidRecord>(
              styles,
              150,
              (x) =>
                x.obligations ? currencyFormatter.format(x.obligations) : "",
              "Obligations",
              "obligations",
            )}
            {GetCustomColumnBody<UsAidRecord>(
              styles,
              150,
              (x) =>
                x.disbursements
                  ? currencyFormatter.format(x.disbursements)
                  : "",
              "Disbursements",
              "disbursements",
            )}
          </DataTable>
        ) : (
          <div className="card-view-with-export">
            {/*<div className="button-export">*/}
            {/*  <PButton*/}
            {/*    type="button"*/}
            {/*    icon="pi pi-file-excel"*/}
            {/*    iconPos="left"*/}
            {/*    onClick={async () => {*/}
            {/*      setDisableExcel(true);*/}
            {/*      try {*/}
            {/*        await generateExcel(*/}
            {/*          "USA Spending",*/}
            {/*          `Aidkonekt_usa_spending_${new Date().getFullYear()}`,*/}
            {/*          getExportableDataTable(*/}
            {/*            getAssistantExcelData*/}
            {/*              ? await getAssistantExcelData()*/}
            {/*              : data,*/}
            {/*          ),*/}
            {/*        );*/}
            {/*      } catch (e) {*/}
            {/*        growl.current.show({*/}
            {/*          severity: "error",*/}
            {/*          summary: "Error",*/}
            {/*          detail: e,*/}
            {/*        });*/}
            {/*      } finally {*/}
            {/*        setDisableExcel(false);*/}
            {/*      }*/}
            {/*    }}*/}
            {/*    tooltip="Excel"*/}
            {/*    tooltipOptions={{ position: "top" }}*/}
            {/*    disabled={disableExcel}*/}
            {/*    style={{*/}
            {/*      marginLeft: 15,*/}
            {/*      marginRight: 15,*/}
            {/*      ...styles.tableHeaderButton,*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            <CardView
              isLoading={isLoading}
              isForeignAssistance={true}
              data={data.map((item) => ({
                isMyProject: item.isMyProject,
                country: item.country,
                uniqueAwardId: item.activityId.toString(),
                projectNumber: item.projectNumber,
                projectName: item.projectName,
                projectDescription: item.projectDescription,
                implementer: item.implementer,
                subawardRecipients: "",
                awardMechanism: "",
                awardingAgencyName: item.technicalSector,
                awardingOffice: "",
                parentIDVAgencyName: "",
                naicsCode: "",
                startDate: item.activityStartDate,
                currentEndDate: item.activityEndDate,
                potentialEndDate: "",
                obligatedAmount: item.obligations,
                currentAmount: item.disbursements,
                potentialAmount: item.obligations,
                recipientAddress: "",
                createdOnUtc: item.updatedOnUtc,
                setAside: "",
              }))}
              mechanismsOptions={[]}
              first={first}
              rowsPerPage={rowsPerPage}
              totalRecords={totalRecords}
              queryCardData={queryCardData}
              addRecordToMyList={addRecordToMyList}
              removeRecordFromMyList={removeRecordFromMyList}
            />
          </div>
        )}
      </>
    </>
  );
};

const GetUsaSpendingView = ({
  setIsCollapsed,
  onlyMyRecordsArgument,
  isCollapsed,
}: {
  isCollapsed: boolean;
  setIsCollapsed: (x: boolean) => void;
  onlyMyRecordsArgument: boolean;
}) => {
  const context = React.useContext(UserContext);
  const [collapsed, setCollapsed] = React.useState(isCollapsed);

  const setCollapsedFunc = (value) => {
    setCollapsed(value);
    // setIsCollapsed(value);
  };
  // Stylings
  const [gridWidth, setGridWidth] = React.useState(0);
  React.useEffect(() => {
    function handleResize() {
      const { width, headerWidth } = getWindowDimensions();
      if (width !== null && headerWidth != null) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Grid required properties
  const [data, setData] = React.useState(Array<UsaSpendingAwardDto>());
  const [isLoading, setIsLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalRecords, setTotalRecords] = React.useState(0); // todo
  const [first, setFirst] = React.useState(0);
  const [sortField, setSortField] = React.useState("lastUpdatedDate");
  const [sortOrder, setSortOrder] = React.useState(-1);
  const [lastUpdated, setLastUpdated] = React.useState("");

  //Available Options
  const [usaspendingCountryNamesOptions, setUsaspendingCountryNamesOptions] =
    React.useState(Array<string>());
  const [usaspendingImplementorsOptions, setUsaspendingImplementorsOptions] =
    React.useState(Array<string>());
  const [usaSubawardeesOptions, setUsaSubawardeesOptions] = React.useState(
    Array<string>(),
  );
  const [mechanismsOptions, setMechanismsOptions] = React.useState(
    Array<{ key: string; value: string }>(),
  );
  const [naicsesOptions, setNaicsesOptions] = React.useState(Array<string>());
  const [setAsidesOptions, setSetAsidesOptions] = React.useState(
    Array<string>(),
  );

  React.useEffect(() => {
    let apiUrl = "/api/usaspending/dropdownoptions/";
    let usaspendingCountryNamesUrl = apiUrl + "country";
    let usaspendingImplementorsUrl = apiUrl + "implementor";
    let usaSubawardsUrl = apiUrl + "subawards";
    let mechanismsUrl = apiUrl + "mechanism";
    let naicsesUrl = apiUrl + "naics";
    let setAsidesUrl = apiUrl + "setAside";

    let baseMessage = "error fetching available ";
    let usaspendingCountryNamesMessage =
      baseMessage + "usaspending country names";
    let usaspendingImplementorsMessage =
      baseMessage + "usaspending implementors";
    let usaSubawardsUrlMessage = baseMessage + "usaspending subwards";
    let mechanismsMessage = baseMessage + "mechanisms";
    let naicsesMessage = baseMessage + "naicses";
    let setAsidesMessage = baseMessage + "set asides";

    fetchDataNewEnvironment(
      usaspendingCountryNamesUrl,
      setUsaspendingCountryNamesOptions,
      context,
      usaspendingCountryNamesMessage,
      true,
    );
    fetchDataNewEnvironment(
      usaspendingImplementorsUrl,
      setUsaspendingImplementorsOptions,
      context,
      usaspendingImplementorsMessage,
      true,
    );
    fetchDataNewEnvironment(
      usaSubawardsUrl,
      setUsaSubawardeesOptions,
      context,
      usaSubawardsUrlMessage,
      true,
    );
    fetchDataNewEnvironment(
      mechanismsUrl,
      setMechanismsOptions,
      context,
      mechanismsMessage,
      false,
      true,
    );
    fetchDataNewEnvironment(
      naicsesUrl,
      setNaicsesOptions,
      context,
      naicsesMessage,
      true,
    );
    fetchDataNewEnvironment(
      setAsidesUrl,
      setSetAsidesOptions,
      context,
      setAsidesMessage,
      true,
    );
  }, []);

  // Filters
  const [updatedDateFilter, setUpdatedDateFilter] =
    React.useState<FilteredDate>({
      start: {
        from: null,
        to: null,
      },
      close: {
        from: todayDate,
        to: null,
      },
    });
  const [updatedStartFromDateFilterError, setUpdatedStartFromDateFilterError] =
    React.useState(false);
  const [updatedStartToDateFilterError, setUpdatedStartToDateFilterError] =
    React.useState(false);
  const [updatedCloseFromDateFilterError, setUpdatedCloseFromDateFilterError] =
    React.useState(false);
  const [updatedCloseToDateFilterError, setUpdatedCloseToDateFilterError] =
    React.useState(false);
  const [recentlyAwardedProjects, setRecentlyAwardedProjects] =
    React.useState<boolean>(false);
  const [selectedCountryFilter, setSelectedCountryFilter] = React.useState(
    Array<string>(),
  );
  const [selectedImplementingPartner, setSelectedImplementingAgency] =
    React.useState(Array<string>());
  const [selectedSubawardees, setSelectedSubawardees] = React.useState(
    Array<string>(),
  );
  const [keyword, setKeyword] = React.useState("");
  const [projectNumberFilter, setProjectNumberFilter] = React.useState("");
  const [onlyMyList, setOnlyMyList] = React.useState(onlyMyRecordsArgument);
  const [mechanism, setMechanism] = React.useState(Array<string>());
  const [naics, setNaics] = React.useState(Array<string>());
  const [setAside, setSetAside] = React.useState(Array<string>());
  const [withMinimumAmount, setWithMinimumAmount] = React.useState(true);
  const [minimumAmount, setMinimumAmount] = React.useState(500000);

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState("");
  const [dialogVisible, setDialogVisible] = React.useState(false);

  const [query, setQuery] = React.useState<URLSearchParams>();

  React.useEffect(() => {
    if (recentlyAwardedProjects) {
      setUpdatedDateFilter({
        start: {
          from: new Date(new Date().setDate(new Date().getDate() - 90)),
          to: todayDate,
        },
        close: {
          from: null,
          to: null,
        },
      });
    } else {
      setUpdatedDateFilter({
        start: {
          from: null,
          to: null,
        },
        close: {
          from: todayDate,
          to: null,
        },
      });
    }
  }, [recentlyAwardedProjects]);

  const queryCallback = () =>
    queryData({
      first,
      rows: rowsPerPage,
      sortField,
      sortOrder,
    });
  useEnterListener(queryCallback);

  const dt = React.useRef<DataTable>(null);
  const [isGridFullScreen, setIsGridFullScreen] = React.useState(false);
  const growl = React.useContext(GrowlContext);
  const styles = useGridStyles();

  React.useEffect(() => {
    const onDescriptionClick = (event: any) => {
      if (!event.target.matches(".abstract")) {
        return;
      }

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      setDialogHeader(container.attributes["data-title"].value);
      setDialogText(dialogText);
      setDialogVisible(true);
    };
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
    };
  }, []);

  function sort(event: { sortField: string; sortOrder: number }) {
    const newSortOrder = getSortIfDescendingFirst(event, sortField);
    setSortField(event.sortField);
    setSortOrder(newSortOrder);
    queryData({
      first,
      rows: rowsPerPage,
      sortField: event.sortField,
      sortOrder: newSortOrder,
    });
  }

  const queryCardData = (event: {
    first: number;
    rows: number;
    page: number;
    pageCount: number;
  }) =>
    queryData({
      first: event.first,
      rows: event.rows,
      sortField,
      sortOrder,
    });

  function queryData(event: {
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
  }) {
    setRowsPerPage(event.rows);

    setFirst(event.first);
    setRowsPerPage(event.rows);
    let request = createAuthenticatedRequest(context);
    setIsLoading(true);
    const url = "/api/usaspending";
    const queryString = new URLSearchParams();

    if (updatedDateFilter.close.from && !updatedCloseFromDateFilterError) {
      queryString.set(
        "dateCloseFrom",
        // updatedDateFilter.close.from.toISOString().substring(0, 10),
        getISODateWithAdjustedTimezone(updatedDateFilter.close.from),
      );
    }

    if (updatedDateFilter.close.to && !updatedCloseToDateFilterError) {
      queryString.set(
        "dateCloseTo",
        // updatedDateFilter.close.to.toISOString().substring(0, 10),
        getISODateWithAdjustedTimezone(updatedDateFilter.close.to),
      );
    }

    if (updatedDateFilter.start.from && !updatedStartFromDateFilterError) {
      queryString.set(
        "dateStartFrom",
        getISODateWithAdjustedTimezone(updatedDateFilter.start.from),
      );
    }

    if (updatedDateFilter.start.to && !updatedStartToDateFilterError) {
      queryString.set(
        "dateStartTo",
        getISODateWithAdjustedTimezone(updatedDateFilter.start.to),
      );
    }

    if (selectedCountryFilter.length) {
      queryString.set("country", selectedCountryFilter.join("|"));
    }

    if (selectedImplementingPartner.length) {
      queryString.set("implementer", selectedImplementingPartner.join("|"));
    }

    if (selectedSubawardees.length) {
      queryString.set("subawardRecipient", selectedSubawardees.join("|"));
    }

    if (keyword) {
      queryString.set("keyword", keyword);
    }

    if (projectNumberFilter.length) {
      queryString.set("projectNumber", projectNumberFilter);
    }

    if (mechanism.length) {
      var keys: string[] = [];
      mechanism.forEach((m) => {
        var s = mechanismsOptions
          ? mechanismsOptions.find((x) => x.value == m)
          : null;
        if (s) {
          keys.push(s.key);
        }
      });
      queryString.set("mechanism", keys.join("|"));
    }

    if (naics.length) {
      queryString.set("naics", naics.join("|"));
    }

    if (setAside.length) {
      queryString.set("setAside", setAside.join("|"));
    }

    if (onlyMyList) {
      queryString.set("isMyList", onlyMyList.toString());
    }

    if (withMinimumAmount && minimumAmount > 0) {
      queryString.set("minimumAmount", minimumAmount.toString());
    }

    setFirst(event.first);
    const pageIndex = event.first / rowsPerPage;
    queryString.set("pageIndex", pageIndex.toString());
    queryString.set("pageSize", event.rows.toString());

    queryString.set("sortField", event.sortField || sortField);
    queryString.set("sortOrder", (event.sortOrder || sortOrder).toString());

    setQuery(queryString);

    axios
      .get(
        getFullUrl(`${url}?${queryString.toString()}`, {
          useDedicatedEnvironment: true,
        }),
        request,
      )
      .then((response) => {
        const { totalItems, data, lastUpdatedUtc } = response.data;
        setTotalRecords(totalItems);

        // Set top records
        const d = data as Array<UsaSpendingAwardDto>;
        setData(d);
        setLastUpdated(
          lastUpdatedUtc
            ? `Last updated ${getDateString(new Date(lastUpdatedUtc + "Z"))}`
            : "",
        );
        setIsLoading(false);
      })
      .catch((error) => {
        alert("error fetching data");
        setIsLoading(false);
      });
  }

  const [disableExcel, setDisableExcel] = React.useState(false);

  const tableHeader = (
    <div className="p-grid p-justify-end p-align-center">
      <div
        className="p-col-12 p-md-8 p-lg-8 topLeftColumnHeader"
        style={{ textAlign: "left" }}
      >
        <div>Source: USA Spending </div>
        <div>{lastUpdated}</div>
      </div>

      <div className="p-col-12 p-md-4 p-lg-4 p-justify-end buttonsHeader">
        <div className="button-export">
          <PButton
            type="button"
            icon="pi pi-file-excel"
            iconPos="left"
            onClick={async () => {
              setDisableExcel(true);
              generateExcel(
                "USA Spending",
                `Aidkonekt_usa_spending_${new Date().getFullYear()}`,
                getExportableDataTable(
                  getExcelData ? await getExcelData() : data,
                ),
              ).then(() => {
                setDisableExcel(false);
              });
            }}
            tooltip="Excel"
            tooltipOptions={{ position: "top" }}
            disabled={disableExcel}
            style={{
              marginLeft: 15,
              marginRight: 15,
              ...styles.tableHeaderButton,
            }}
          />
        </div>
        <div className="button-export">
          <PButton
            type="button"
            icon={
              isGridFullScreen
                ? "pi pi-window-minimize"
                : "pi pi-window-maximize"
            }
            iconPos="left"
            onClick={(e) => {
              if (isGridFullScreen) {
                MinimizeGrid(e.target as HTMLElement);
              } else {
                MaximizeGrid(e.target as HTMLElement, () =>
                  setIsGridFullScreen(false),
                );
              }

              setIsGridFullScreen(!isGridFullScreen);
            }}
            tooltip={isGridFullScreen ? "Minimize" : "Maximize"}
            tooltipOptions={{ position: "top" }}
            style={{
              marginLeft: 15,
              marginRight: 15,
              ...styles.tableHeaderButton,
            }}
          />
        </div>
      </div>
    </div>
  );

  const getExportableDataTable = (
    data: UsaSpendingAwardDto[],
  ): TableProperties => {
    const columns = [
      { name: "Country" },
      { name: "Project Number" },
      { name: "Project Name" },
      { name: "Project Description" },
      { name: "Implementer" },
      { name: "Subawards" },
      { name: "Award Mechanism" },
      { name: "Awarding Agency Name" },
      { name: "Awarding Office" },
      { name: "Parent IDV Agency Name" },
      { name: "Set-Aside Type" },
      { name: "NAICS Code" },
      { name: "Start Date" },
      { name: "Current End Date" },
      { name: "Potential End Date" },
      { name: "Obligated Amount" },
      { name: "Current Amount" },
      { name: "Potential Amount" },
      { name: "Recipient Address" },
    ];

    let rows: any[][] = [];

    if (data.length == 0) {
      rows.push(genEmptyRow(columns.length));
    } else {
      for (let i = 0; i < data.length; i++) {
        const record = data[i];
        const chunkDescription = getChunksFromString(
          record.projectDescription,
          MaxCharsAllowed,
        );

        rows.push([
          record.country,
          record.projectNumber?.toLowerCase() === "null"
            ? ""
            : record.projectNumber,
          record.projectName,
          chunkDescription[0] || "",
          record.implementer,
          record.subawardRecipients ?? "",
          getDispayForMechanism(record.awardMechanism),
          record.awardingAgencyName,
          record.awardingOffice,
          record.parentIDVAgencyName,
          record.setAside,
          record.naicsCode,
          formatDate(record.startDate, DateFormat.dd_MMMM_yyyy),
          formatDate(record.currentEndDate, DateFormat.dd_MMMM_yyyy),
          formatDate(record.potentialEndDate, DateFormat.dd_MMMM_yyyy),
          record.obligatedAmount !== null
            ? currencyFormatterNoDecimals.format(
                Math.round(record.obligatedAmount),
              )
            : "",
          record.currentAmount !== null
            ? currencyFormatterNoDecimals.format(
                Math.round(record.currentAmount),
              )
            : "",
          record.potentialAmount !== null
            ? currencyFormatterNoDecimals.format(
                Math.round(record.potentialAmount),
              )
            : "",
          record.recipientAddress,
        ]);

        for (let j = 1; j < chunkDescription.length; j++) {
          let emptyRow = genEmptyRow(columns.length);
          emptyRow[3] = chunkDescription[j];
          rows.push(emptyRow);
        }
      }
    }

    return buildExcelTable("USASpending", columns, rows);
  };

  const addRecordToMyList = (record: UsaSpendingAwardDto) => {
    axios
      .post(
        getFullUrl("/api/usaspending/mylist", {
          useDedicatedEnvironment: true,
        }),
        { uniqueAwardId: record.uniqueAwardId },
        createRequestWithAuthHeaders(context),
      )
      .then(() => {
        // re-set my list status for all items with this id
        const newData = [...data];
        newData
          .filter(
            (r: Partial<UsaSpendingAwardDto>) =>
              r.uniqueAwardId === record.uniqueAwardId,
          )
          .forEach((r: UsaSpendingAwardDto) => (r.isMyProject = true));
        setData(newData);
        growl.current.show({
          severity: "success",
          summary: "Added",
          detail: `Added USA Record '${record.projectNumber}' to my list`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          }),
        ),
      );
  };

  const removeRecordFromMyList = (record: UsaSpendingAwardDto) => {
    let request: AxiosRequestConfig = {
      ...createRequestWithAuthHeaders(context),
      data: { uniqueAwardId: record.uniqueAwardId },
    };
    axios
      .delete(
        getFullUrl("/api/usaspending/mylist", {
          useDedicatedEnvironment: true,
        }),
        request,
      )
      .then(() => {
        // re-set my list status for all items with this id
        const newData = [...data];
        newData
          .filter(
            (r: Partial<UsaSpendingAwardDto>) =>
              r.uniqueAwardId === record.uniqueAwardId,
          )
          .forEach((r: UsaSpendingAwardDto) => (r.isMyProject = false));
        setData(newData);
        growl.current.show({
          severity: "success",
          summary: "Removed",
          detail: `Removed '${record.projectNumber}' from my list`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          }),
        ),
      );
  };

  const ActionsButtonColumn = ({ record }: { record: UsaSpendingAwardDto }) => {
    const add = (
      <ActionButtons.AddToMyListButton add={() => addRecordToMyList(record)} />
    );
    const remove = (
      <ActionButtons.RemoveFromMyListButton
        remove={() => removeRecordFromMyList(record)}
      />
    );
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {record.isMyProject ? remove : add}
      </div>
    );
  };

  const ViewColumnBody = ({ record }: { record: UsaSpendingAwardDto }) => {
    record.recipientAddress = record.recipientAddress
      ? record.recipientAddress.replace("&", "and")
      : "";
    record.projectName = record.projectName
      ? record.projectName.replace("&", "and")
      : "";
    return (
      <Link
        to={`/project-view/record?projectNumber=${
          record.projectNumber
        }&fa=${false}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <button className="view-column-btn">
          <div>
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.4402 5.7C13.9252 2.1825 11.0752 0 8.00017 0C4.92517 0 2.07517 2.1825 0.560172 5.7C0.518875 5.79462 0.497559 5.89676 0.497559 6C0.497559 6.10324 0.518875 6.20538 0.560172 6.3C2.07517 9.8175 4.92517 12 8.00017 12C11.0752 12 13.9252 9.8175 15.4402 6.3C15.4815 6.20538 15.5028 6.10324 15.5028 6C15.5028 5.89676 15.4815 5.79462 15.4402 5.7ZM8.00017 10.5C5.62267 10.5 3.37267 8.7825 2.07517 6C3.37267 3.2175 5.62267 1.5 8.00017 1.5C10.3777 1.5 12.6277 3.2175 13.9252 6C12.6277 8.7825 10.3777 10.5 8.00017 10.5ZM8.00017 3C7.40683 3 6.82681 3.17595 6.33346 3.50559C5.84011 3.83524 5.4556 4.30377 5.22853 4.85195C5.00147 5.40013 4.94206 6.00333 5.05782 6.58527C5.17357 7.16721 5.45929 7.70176 5.87885 8.12132C6.29841 8.54088 6.83296 8.8266 7.4149 8.94236C7.99684 9.05811 8.60004 8.9987 9.14822 8.77164C9.6964 8.54458 10.1649 8.16006 10.4946 7.66671C10.8242 7.17336 11.0002 6.59334 11.0002 6C11.0002 5.20435 10.6841 4.44129 10.1215 3.87868C9.55888 3.31607 8.79582 3 8.00017 3ZM8.00017 7.5C7.7035 7.5 7.41349 7.41203 7.16682 7.2472C6.92014 7.08238 6.72788 6.84811 6.61435 6.57403C6.50082 6.29994 6.47112 5.99834 6.52899 5.70736C6.58687 5.41639 6.72973 5.14912 6.93951 4.93934C7.14929 4.72956 7.41657 4.5867 7.70754 4.52882C7.99851 4.47094 8.30011 4.50065 8.5742 4.61418C8.84829 4.72771 9.08255 4.91997 9.24738 5.16665C9.4122 5.41332 9.50017 5.70333 9.50017 6C9.50017 6.39782 9.34214 6.77936 9.06083 7.06066C8.77953 7.34196 8.398 7.5 8.00017 7.5Z"
                fill="white"
              />
            </svg>
            <span>View</span>
          </div>
        </button>
      </Link>
    );
  };

  function getDispayForMechanism(awardMechanism: string): any {
    var mech = mechanismsOptions
      ? mechanismsOptions.find((m) => m.key == awardMechanism)
      : null;
    return mech ? mech.value : "";
  }

  var renderSearch = () => {
    return (
      <div>
        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.location} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!usaspendingCountryNamesOptions.length ? (
              <CustomSearchSelect
                placeholder="Country"
                setSelectedOption={setSelectedCountryFilter}
                allOptions={usaspendingCountryNamesOptions}
                disabled={isLoading || !usaspendingCountryNamesOptions}
                addAllFilteredResults={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.hands} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!usaspendingImplementorsOptions.length ? (
              <CustomSearchSelect
                placeholder="Implementer"
                setSelectedOption={setSelectedImplementingAgency}
                allOptions={usaspendingImplementorsOptions}
                disabled={isLoading || !usaspendingImplementorsOptions}
                addAllFilteredResults={true}
                useContainsFilter={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.hands} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!usaSubawardeesOptions.length ? (
              <CustomSearchSelect
                placeholder="Subawardees"
                setSelectedOption={setSelectedSubawardees}
                allOptions={usaSubawardeesOptions}
                disabled={isLoading || !usaSubawardeesOptions}
                addAllFilteredResults={true}
                useContainsFilter={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.number} alt="search" style={styles.icon} />
          </div>
          <div
            className="p-col"
            style={{ textAlign: "left", display: "block" }}
          >
            <InputText
              style={styles.filters.inputBox}
              id="projectNumber"
              type="search"
              value={projectNumberFilter}
              onChange={(e: any) =>
                setProjectNumberFilter((e.target as any).value)
              }
              placeholder="Project Number"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.file} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!mechanismsOptions?.length ? (
              <CustomSearchSelect
                placeholder="Award Mechanism"
                setSelectedOption={setMechanism}
                allOptions={
                  mechanismsOptions
                    ? mechanismsOptions.map((x) => x.value)
                    : null
                }
                disabled={isLoading || !mechanismsOptions}
                addAllFilteredResults={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.helmet} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!naicsesOptions.length ? (
              <CustomSearchSelect
                placeholder="NAICS Code"
                setSelectedOption={setNaics}
                allOptions={naicsesOptions}
                disabled={isLoading || !naicsesOptions}
                addAllFilteredResults={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.eligible} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!setAsidesOptions.length ? (
              <CustomSearchSelect
                placeholder="Set-Aside Type"
                setSelectedOption={setSetAside}
                allOptions={setAsidesOptions}
                disabled={isLoading || !setAsidesOptions}
                addAllFilteredResults={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.keyword} alt="search" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            <InputText
              style={styles.filters.inputBox}
              id="keyword"
              type="search"
              value={keyword}
              onChange={(e: any) => setKeyword((e.target as any).value)}
              placeholder="Keyword"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.calendar} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left", display: "flex" }}>
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              Show projects closing between{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              value={updatedDateFilter.close.from}
              onError={(e) => setUpdatedCloseFromDateFilterError(!!e)}
              onChange={(date) =>
                setUpdatedDateFilter({
                  start: updatedDateFilter.start,
                  close: {
                    from: date,
                    to: updatedDateFilter.close.to,
                  },
                })
              }
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading}
            />
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              {" "}
              and{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              value={updatedDateFilter.close.to}
              onError={(e) => setUpdatedCloseToDateFilterError(!!e)}
              onChange={(date) =>
                setUpdatedDateFilter({
                  start: updatedDateFilter.start,
                  close: {
                    from: updatedDateFilter.close.from,
                    to: date,
                  },
                })
              }
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading}
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <Checkbox
              inputId="withMimimumAmountCB"
              value="withMinimumAmount"
              onChange={(e) => setWithMinimumAmount(e.checked)}
              checked={withMinimumAmount}
            />
          </div>
          <div className="p-col" style={{ textAlign: "left", display: "flex" }}>
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              Only show Projects over{" "}
            </label>
            <InputNumber
              style={styles.filters.inputTextLabel}
              id="minimumAmount"
              mode="currency"
              currency="USD"
              locale="en-US"
              maxFractionDigits={0}
              value={minimumAmount}
              onChange={(e: any) => setMinimumAmount(e.value)}
              placeholder="Minimum Amount"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={{ ...styles.filters.notTopFilterRow, paddingTop: 7 }}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.calendar} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left", display: "flex" }}>
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              Show project starting between{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              value={updatedDateFilter.start.from}
              onError={(e) => setUpdatedStartFromDateFilterError(!!e)}
              onChange={(date) =>
                setUpdatedDateFilter({
                  start: {
                    from: date,
                    to: updatedDateFilter.start.to,
                  },
                  close: updatedDateFilter.close,
                })
              }
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading || recentlyAwardedProjects}
            />
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              {" "}
              and{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              value={updatedDateFilter.start.to}
              onError={(e) => setUpdatedStartToDateFilterError(!!e)}
              onChange={(date) =>
                setUpdatedDateFilter({
                  start: {
                    from: updatedDateFilter.start.from,
                    to: date,
                  },
                  close: updatedDateFilter.close,
                })
              }
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading || recentlyAwardedProjects}
            />
          </div>
        </div>

        <div
          className="p-col-12 p-md-6 p-lg-12"
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 7,
            marginBottom: 10,
            marginLeft: 5,
            textAlign: "left",
          }}
        >
          <Checkbox
            inputId="swa"
            value="showAwardsOnly"
            onChange={(e) => setRecentlyAwardedProjects(e.checked)}
            checked={recentlyAwardedProjects}
          />
          <label
            htmlFor="swa"
            className="p-checkbox-label"
            style={{
              ...styles.filters.inputTextLabel,
              marginLeft: 20,
              marginBottom: 3,
            }}
          >
            Show recently awarded projects (last 90 days)
          </label>
        </div>

        <div className="p-grid" style={styles.filters.notTopFilterRow}>
          <div className="p-col" style={styles.filters.searchButtonContainer}>
            <PButton
              style={styles.filters.searchButton}
              onClick={() =>
                queryData({
                  first,
                  rows: rowsPerPage,
                  sortField,
                  sortOrder,
                })
              }
            >
              <div style={styles.filters.searchButtonText}>Search</div>
            </PButton>
          </div>
        </div>
      </div>
    );
  };

  var getExcelData = async (): Promise<UsaSpendingAwardDto[]> => {
    var base = "/api/usaspending";
    var q = query;
    if (q) q.set("pageSize", "1000");
    var qu = q ? base + "?" + q.toString() : base;
    let request = createAuthenticatedRequest(context);
    var result = Array<UsaSpendingAwardDto>();
    await axios
      .get(getFullUrl(qu, { useDedicatedEnvironment: true }), request)
      .then((response) => {
        result = response.data.data;
      })
      .catch((error) => {
        alert("error fetching data");
      });
    return result;
  };

  const [isTableView, setIsTableView] = React.useState(true);

  return (
    <>
      <div className="p-grid" style={{ alignItems: "center" }}>
        <div className="name-with-view">
          <div className="p-col-12" style={styles.pageHeader}>
            <img
              src={styles.aidkonektIcon}
              style={styles.icon}
              alt="Aidkonekt"
              className="formIcon"
            ></img>
            Projects Enhanced USA Spending Project Data
          </div>
          <div className="button-export">
            {isTableView ? (
              <div style={{ width: "60px" }}></div>
            ) : (
              <PButton
                type="button"
                icon="pi pi-file-excel"
                iconPos="left"
                onClick={async () => {
                  setDisableExcel(true);
                  try {
                    await generateExcel(
                      "USA Spending",
                      `Aidkonekt_usa_spending_${new Date().getFullYear()}`,
                      getExportableDataTable(
                        getExcelData ? await getExcelData() : data,
                      ),
                    );
                  } catch (e) {
                    growl.current.show({
                      severity: "error",
                      summary: "Error",
                      detail: e,
                    });
                  } finally {
                    setDisableExcel(false);
                  }
                }}
                tooltip="Excel"
                tooltipOptions={{ position: "top" }}
                disabled={disableExcel}
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  ...styles.tableHeaderButton,
                }}
              />
            )}
          </div>
          <div className="view-switch-container">
            <div
              className="view-switch-table"
              style={{
                background: isTableView ? "#605F5F" : "#D9D9D9",
              }}
              onClick={() => setIsTableView(true)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V19C0 19.2652 0.105357 19.5196 0.292893 19.7071C0.48043 19.8946 0.734784 20 1 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0ZM6 18H2V2H6V18ZM12 18H8V2H12V18ZM18 18H14V2H18V18Z"
                  fill={isTableView ? "white" : "#605F5F"}
                />
              </svg>
            </div>
            <div
              style={{
                background: !isTableView ? "#605F5F" : "#D9D9D9",
              }}
              className="view-switch-card"
              onClick={() => setIsTableView(false)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 13H3C2.73478 13 2.48043 13.1054 2.29289 13.2929C2.10536 13.4804 2 13.7348 2 14V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H10C10.2652 22 10.5196 21.8946 10.7071 21.7071C10.8946 21.5196 11 21.2652 11 21V14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13ZM9 20H4V15H9V20ZM21 2H14C13.7348 2 13.4804 2.10536 13.2929 2.29289C13.1054 2.48043 13 2.73478 13 3V10C13 10.2652 13.1054 10.5196 13.2929 10.7071C13.4804 10.8946 13.7348 11 14 11H21C21.2652 11 21.5196 10.8946 21.7071 10.7071C21.8946 10.5196 22 10.2652 22 10V3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2ZM20 9H15V4H20V9ZM21 13H14C13.7348 13 13.4804 13.1054 13.2929 13.2929C13.1054 13.4804 13 13.7348 13 14V21C13 21.2652 13.1054 21.5196 13.2929 21.7071C13.4804 21.8946 13.7348 22 14 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21V14C22 13.7348 21.8946 13.4804 21.7071 13.2929C21.5196 13.1054 21.2652 13 21 13ZM20 20H15V15H20V20ZM10 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V10C2 10.2652 2.10536 10.5196 2.29289 10.7071C2.48043 10.8946 2.73478 11 3 11H10C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10V3C11 2.73478 10.8946 2.48043 10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2ZM9 9H4V4H9V9Z"
                  fill={!isTableView ? "white" : "#605F5F"}
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="p-col-10" style={{ ...styles.sectionSubheader }}>
          A database of USAID projects that allows you to quickly sort USAID
          project activity by closing date for your target countries,
          competitors, and technical sectors. This tool is designed to deliver
          rapid, landscape snapshots of USAID projects, but is not meant to be
          comprehensive of every USAID project. It is based on USAID project
          data from USA Spending.
        </div>
      </div>

      <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Panel
            style={styles.filters.filterBox}
            collapsed={collapsed}
            onToggle={(e) => setCollapsedFunc(e.value)}
            toggleable
            expandIcon=""
            collapseIcon=""
            header={
              <PanelHeader
                search={() =>
                  queryData({ first, rows: rowsPerPage, sortField, sortOrder })
                }
                isCollapsed={collapsed}
                setIsCollapsed={setCollapsedFunc}
                expandedTitle={
                  <div
                    className="p-grid p-justify-start"
                    style={{ ...styles.filters.fontHeader }}
                  >
                    <div
                      className="p-col-12 p-md-6 p-lg-4"
                      style={{ textAlign: "left" }}
                    >
                      <RadioButton
                        id="allprojects"
                        value="0"
                        name="allprojects"
                        onChange={() => setOnlyMyList(false)}
                        checked={!onlyMyList}
                      />
                      <label style={{ marginLeft: 5 }} htmlFor="allprojects">
                        Show all Projects
                      </label>
                    </div>
                    <div
                      className="p-col-12 p-md-6 p-lg-4"
                      style={{ textAlign: "left" }}
                    >
                      <RadioButton
                        id="myprojects"
                        value="1"
                        name="myprojects"
                        onChange={() => setOnlyMyList(true)}
                        checked={onlyMyList}
                      />
                      <label style={{ marginLeft: 5 }} htmlFor="myprojects">
                        Show my Projects
                      </label>
                    </div>
                  </div>
                }
              />
            }
          >
            {renderSearch()}
          </Panel>
        </MuiPickersUtilsProvider>

        <Popover
          dialogHeader={dialogHeader}
          isVisible={dialogVisible}
          dialogText={dialogText}
          setDialogVisible={setDialogVisible}
        />

        {isTableView ? (
          <DataTable
            value={data}
            style={{ marginTop: 20, width: `${gridWidth - 20}px` }}
            paginator={true}
            rows={rowsPerPage}
            totalRecords={totalRecords}
            lazy={true}
            first={first}
            onPage={queryData}
            loading={isLoading}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={sort}
            scrollable={true}
            scrollHeight="400px"
            ref={dt}
            header={tableHeader}
            exportFilename={`Aidkonekt_projects_plus_${new Date().getFullYear()}`}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            rowsPerPageOptions={rowsPerPageOptionsHighIncrement}
          >
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => (
                <ViewColumnBody record={x} />
              ),
              "View",
              "view",
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => (
                <div className="actions-column">
                  {/* <NavigateToButton
                  openPage={() => window.open("/project-details")}
                /> */}
                  <ActionsButtonColumn record={x} />
                </div>
              ),
              "Add to My Projects",
              "activityProjectNumber",
              { sortable: false },
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => x.country,
              "Country",
              "country",
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => x.projectNumber,
              "Project Number",
              "projectNumber",
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => x.projectName,
              "Project Name",
              "projectName",
            )}
            {GetCustomColumnShortened<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => x.projectDescription,
              (x) => x.projectNumber,
              "Description",
              150,
              true,
              "projectDescription",
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => x.implementer,
              "Implementer",
              "implementer",
            )}
            {GetCustomColumnShortened<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => x.subawardRecipients ?? "",
              (x) => "Subawards",
              "Subawards",
              150,
              true,
              "subawards",
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => getDispayForMechanism(x.awardMechanism),
              "Award Mechanism",
              "awardMechanism",
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => x.awardingAgencyName,
              "Awarding Agency Name",
              "awardingAgencyName",
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => x.awardingOffice,
              "Awarding Office",
              "awardingOffice",
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => x.parentIDVAgencyName,
              "Parent IDV Agency Name",
              "parentIDVAgencyName",
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => x.setAside,
              "Set-Aside Type",
              "setAside",
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => x.naicsCode,
              "NAICS Code",
              "naicsCode",
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => formatDate(x.startDate, DateFormat.dd_MMMM_yyyy),
              "Start Date",
              "startDate",
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => formatDate(x.currentEndDate, DateFormat.dd_MMMM_yyyy),
              "Current End Date",
              "currentEndDate",
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => formatDate(x.potentialEndDate, DateFormat.dd_MMMM_yyyy),
              "Potential End Date",
              "potentialEndDate",
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) =>
                x.obligatedAmount !== null
                  ? currencyFormatterNoDecimals.format(
                      Math.round(x.obligatedAmount),
                    )
                  : "",
              "Obligated Amount",
              "obligatedAmount",
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) =>
                x.currentAmount !== null
                  ? currencyFormatterNoDecimals.format(
                      Math.round(x.currentAmount),
                    )
                  : "",
              "Current Amount",
              "currentAmount",
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) =>
                x.potentialAmount !== null
                  ? currencyFormatterNoDecimals.format(
                      Math.round(x.potentialAmount),
                    )
                  : "",
              "Potential Amount",
              "potentialAmount",
            )}
            {GetCustomColumnBody<UsaSpendingAwardDto>(
              styles,
              150,
              (x) => x.recipientAddress,
              "Recipient Address",
              "recipientAddress",
            )}
          </DataTable>
        ) : (
          <div className="card-view-with-export">
            {/*<div className="button-export">*/}
            {/*  <PButton*/}
            {/*    type="button"*/}
            {/*    icon="pi pi-file-excel"*/}
            {/*    iconPos="left"*/}
            {/*    onClick={async () => {*/}
            {/*      setDisableExcel(true);*/}
            {/*      try {*/}
            {/*        await generateExcel(*/}
            {/*          "USA Spending",*/}
            {/*          `Aidkonekt_usa_spending_${new Date().getFullYear()}`,*/}
            {/*          getExportableDataTable(*/}
            {/*            getExcelData ? await getExcelData() : data,*/}
            {/*          ),*/}
            {/*        );*/}
            {/*      } catch (e) {*/}
            {/*        growl.current.show({*/}
            {/*          severity: "error",*/}
            {/*          summary: "Error",*/}
            {/*          detail: e,*/}
            {/*        });*/}
            {/*      } finally {*/}
            {/*        setDisableExcel(false);*/}
            {/*      }*/}
            {/*    }}*/}
            {/*    tooltip="Excel"*/}
            {/*    tooltipOptions={{ position: "top" }}*/}
            {/*    disabled={disableExcel}*/}
            {/*    style={{*/}
            {/*      marginLeft: 15,*/}
            {/*      marginRight: 15,*/}
            {/*      ...styles.tableHeaderButton,*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            <CardView
              isLoading={isLoading}
              data={data}
              mechanismsOptions={mechanismsOptions}
              first={first}
              rowsPerPage={rowsPerPage}
              totalRecords={totalRecords}
              queryCardData={queryCardData}
              addRecordToMyList={addRecordToMyList}
              removeRecordFromMyList={removeRecordFromMyList}
              isForeignAssistance={false}
            />
          </div>
        )}
      </>
    </>
  );
};
