import { createSvgIcon } from "@mui/material/utils";

export const OfficeBagIcon = createSvgIcon(
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6667 2H9.66666V1.33333C9.66666 0.979711 9.52619 0.640573 9.27614 0.390524C9.02609 0.140476 8.68695 0 8.33333 0H5.66666C5.31304 0 4.9739 0.140476 4.72385 0.390524C4.4738 0.640573 4.33333 0.979711 4.33333 1.33333V2H2.33333C1.8029 2 1.29419 2.21071 0.919115 2.58579C0.544042 2.96086 0.333328 3.46957 0.333328 4V10C0.333328 10.5304 0.544042 11.0391 0.919115 11.4142C1.29419 11.7893 1.8029 12 2.33333 12H11.6667C12.1971 12 12.7058 11.7893 13.0809 11.4142C13.4559 11.0391 13.6667 10.5304 13.6667 10V4C13.6667 3.46957 13.4559 2.96086 13.0809 2.58579C12.7058 2.21071 12.1971 2 11.6667 2ZM5.66666 1.33333H8.33333V2H5.66666V1.33333ZM12.3333 10C12.3333 10.1768 12.2631 10.3464 12.1381 10.4714C12.013 10.5964 11.8435 10.6667 11.6667 10.6667H2.33333C2.15652 10.6667 1.98695 10.5964 1.86192 10.4714C1.7369 10.3464 1.66666 10.1768 1.66666 10V6.26L4.78666 7.33333C4.85745 7.34294 4.92921 7.34294 4.99999 7.33333H8.99999C9.07229 7.332 9.14407 7.32078 9.21333 7.3L12.3333 6.26V10ZM12.3333 4.85333L8.89333 6H5.10666L1.66666 4.85333V4C1.66666 3.82319 1.7369 3.65362 1.86192 3.5286C1.98695 3.40357 2.15652 3.33333 2.33333 3.33333H11.6667C11.8435 3.33333 12.013 3.40357 12.1381 3.5286C12.2631 3.65362 12.3333 3.82319 12.3333 4V4.85333Z"
      // fill="currentColor"
    />
  </svg>,
  "OfficeBag",
);
