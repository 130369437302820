import React, { useState } from "react";
import { Button } from "primereact/button";
import "./Landing.css";
import { useLocation } from "react-router-dom";
import ReactSimplyCarousel, { VisibleSlidesState } from "react-simply-carousel";
import { Avatar, Grid } from "@mui/material";

const useStyles = () => {
  return {
    footerButton: {
      width: "100%",
      maxWidth: 380,
      height: 100,
      borderRadius: 10,
      color: "#367FB6",
      padding: "18px 60px",
      backgroundColor: "#ECAC37",
    },
    logo: {
      height: "40px",
    },
  };
};

const icons = {
  logo: require("../../assets/AidkonektLogoLarge.png").default,
  yellowAirplane:
    require("../../assets/imgs/landing_yellow_papper_airplane.png").default,
  blueAirplane: require("../../assets/imgs/landing_blue_papper_airplane.png")
    .default,
  scottIcon: require("../../assets/imgs/landing-scott-icon.png").default,
  mikeIcon: require("../../assets/imgs/landing-mike-icon.png").default,
  logoIcon: require("../../assets/icons/v2/icon_Globe.png").default,
  clockIcon: require("../../assets/icons/v2/icon_clock.png").default,
  aidPreviewIcon: require("../../assets/imgs/landing-aid-preview-icon.png")
    .default,
  optionIcon1: require("../../assets/icons/v2/landing-option-icon1.png")
    .default,
  optionIcon2: require("../../assets/icons/v2/landing-option-icon2.png")
    .default,
  commasIcon: require("../../assets/icons/v2/icon_commas.png").default,
  aidWebsites: require("../../assets/imgs/aid_websites.png").default,
  envelope: require("../../assets/imgs/landing_envelope.png").default,
  firstPerson: require("../../assets/imgs/landing_person_1.png").default,
  secondPerson: require("../../assets/imgs/landing_person_2.png").default,
  thirdPerson: require("../../assets/imgs/landing_person_3.png").default,
  geopollIcon: require("../../assets/imgs/geopoll_icon.png").default,
  geopollPersonIcon: require("../../assets/imgs/geopoll_person_icon.png")
    .default,
  enviromentIcon: require("../../assets/imgs/enviroment_icon.png").default,
  enviromentPersonIcon: require("../../assets/imgs/enviroment_person_icon.png")
    .default,
  arizonaIcon: require("../../assets/imgs/arizona_icon.png").default,
  arizonaPersonIcon: require("../../assets/imgs/arizona_person_icon.png")
    .default,
  adamIcon: require("../../assets/imgs/adam_icon.png").default,
  adamPersonIcon: require("../../assets/imgs/adam_person_icon.png").default,
  ocdcIcon: require("../../assets/imgs/ocdc-icon.png").default,
  ocdcLuAnnAvatar: require("../../assets/imgs/luann-avatar-ocdc.png").default,
};

const logos = [
  {
    img: require("./assets/CERES_Environmental.png").default,
    href: "https://ceresenvironmental.com/",
  },
  {
    img: require("./assets/APEX_Consulting.png").default,
    href: "https://apexconsulting-me.com/",
  },
  {
    img: require("./assets/ECODIT.png").default,
    href: "https://www.ecodit.com/",
  },
  {
    img: require("./assets/EngenderHealth.png").default,
    href: "https://www.engenderhealth.org/",
  },
  {
    img: require("./assets/Global_Communities.png").default,
    href: "https://globalcommunities.org/",
  },
  {
    img: require("./assets/iDE.png").default,
    href: "https://www.ideglobal.org/",
  },
  {
    img: require("./assets/MarketShare.png").default,
    href: "https://marketshareassociates.com/",
  },
  { img: require("./assets/AIR.png").default, href: "https://air.org/" },
  { img: require("./assets/RIWI.png").default, href: null },
  { img: require("./assets/IDG.png").default, href: null },
  { img: require("./assets/Integral_Global.png").default, href: null },
  { img: require("./assets/Mortenson_Center.png").default, href: null },
  { img: require("./assets/OCDC.png").default, href: "https://ocdc.coop/" },
];

const comments = [
  {
    icon1: icons.geopollIcon,
    icon2: icons.geopollPersonIcon,
    comment:
      "“ AidKonekt's platform offers GeoPoll a real-time portal to " +
      "planned and active global solicitations from USAID, State and the " +
      "MCC where we can proactively reach out and engage prospective primes " +
      "and share what we can bring to their RFP/ RFA responses. “",
    personName: "Scott Lansell",
    personPosition: "VP for International Development and Relief",
  },
  {
    icon1: icons.enviromentIcon,
    icon2: icons.enviromentPersonIcon,
    comment:
      "“Aidkonekt keeps us ahead of the curve—it’s a one stop shop that integrates crucial resources for tracking USAID opportunities. By seamlessly integrating crucial resources from USAID forecasts to local compensation plans, AidKonekt saves us countless hours we previously spent navigating various websites and significantly enhances our strategic planning and team collaboration. It’s our go-to hub for everything USAID, streamlining our processes and enabling us to focus on crafting winning proposals“",
    personName: "Chetna Chandrasekaran",
    personPosition: "BD and Marketing Lead, EI",
  },
  {
    icon1: icons.arizonaIcon,
    icon2: icons.arizonaPersonIcon,
    comment:
      "“I use Aidkonekt first thing every day to check grants and " +
      "contracts solicitations and new updates to the business forecast. I " +
      "also use the project and data database to do research and where needed, " +
      "it’s handy to have the CDCS and FSN salary scale library.”",
    personName: "Eric Bergthold",
    personPosition:
      "Executive Director, Office of International Development " +
      "at University of Arizona",
  },
  {
    icon1: icons.adamIcon,
    icon2: icons.adamPersonIcon,
    comment:
      "“ We are able to partner better. AidKonekt's suggested primes " +
      "are very helpful to build our initial partner outreach list for prime " +
      "partnering. AidKonekt makes it really easy to find information on " +
      "which primes are implementing projects in a certain country. ”",
    personName: "Megan Vetter",
    personPosition: "Africa Manager, Adam Smith",
  },
  {
    icon1: icons.ocdcIcon,
    icon2: icons.ocdcLuAnnAvatar,
    comment:
      "“U.S. Overseas Cooperative Development Council (OCDC) is a small team," +
      " AidKonekt saves staff time by streamlining USAID market research" +
      " and helping us identify partners to cultivate.“",
    personName: "LuAnn Werner",
    personPosition:
      "Deputy Executive Director, U.S. Overseas Cooperative Development Council ",
  },
];

const OptionItem = ({ title, options }) => (
  <li>
    <div className="list-item">
      <span>{title}</span>
      <div>
        {options == 3 && (
          <div className="option-icon-container">
            <img src={icons.optionIcon1} alt="option-icon" />
          </div>
        )}
        {options >= 2 && (
          <div className="option-icon-container">
            <img src={icons.optionIcon1} alt="option-icon" />
          </div>
        )}
        <div className="option-icon-container">
          <img src={icons.optionIcon2} alt="option-icon" />
        </div>
      </div>
    </div>
  </li>
);

const LandingPage: React.FC = (): JSX.Element => {
  const styles = useStyles();

  const [activeMobilePlan, setActiveMobilePlan] = useState("");

  const [viewSlider, setViewSlider] = React.useState(true);
  const [activeSlide, setActiveSlide] = React.useState(0);

  const handleSlideChange = React.useCallback(
    (
      newActiveSlideIndex: number,
      newVisibleSlidesState: VisibleSlidesState,
    ) => {
      setActiveSlide(newActiveSlideIndex);
    },
    [],
  );

  React.useEffect(() => {
    const handleResize = () => {
      setViewSlider(false);
      setTimeout(() => setViewSlider(true), 100);
    };

    window.addEventListener("resize", handleResize);
  });

  return (
    <div className="landing">
      <section className="header">
        <div className="header-content">
          <div className="logo">
            <img style={styles.logo} src={icons.logo} alt="logo" />
          </div>
          <div className="header-button-container">
            <div className="header-button">
              <div>
                <button
                  onClick={() =>
                    window.open(
                      "https://calendly.com/aidkonekt-usaid-data/15-min-aidkonekt-intro-call",
                      "_blank",
                    )
                  }
                >
                  Schedule a Demo
                </button>
              </div>
            </div>
            <div className="header-button">
              <div>
                <button onClick={() => window.open("auth", "_blank")}>
                  Get Your Free Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="top-line-block build">
        <div className="top-line-inner">
          <div>
            <h1>
              Build your pipeline with
              <br />
              AidKonekt
            </h1>
            <div className="line" />
            <span>
              USAID Biz Dev Intel software. AidKonekt is the online platform
              that monitors and provides updates on USAID forecasted
              opportunities, and pipelines for grants and contracts. AidKonekt
              also allows you to search past procurements, reports and
              assessments, and project data, in addition to USAID country,
              sector, and competitor insight.AidKonekt was designed by USAID
              business development specialists who know how USAID partners need
              to see procurement and market intel data. We streamline pipeline
              monitoring and accelerate competitor and market research, so your
              team can focus on high value tasks like partnering, recruitment
              and proposal development, not refreshing government websites.
            </span>
          </div>
          <img src={icons.yellowAirplane} alt="yellow-airplane" />
        </div>
      </section>

      <section className="comments-section">
        <h2>Why our clients trust AidKonekt</h2>
        <div className="slider-container">
          {viewSlider && (
            <ReactSimplyCarousel
              containerProps={{
                style: {
                  userSelect: "none",
                },
                className: "slider-component",
              }}
              preventScrollOnSwipe
              disableSwipeByTouch
              disableSwipeByMouse
              centerMode
              // infinite
              autoplay={true}
              autoplayDelay={4500}
              swipeTreshold={10}
              activeSlideIndex={activeSlide}
              activeSlideProps={{
                className: "active-slide",
              }}
              onRequestChange={handleSlideChange}
              itemsToShow={screen.width < 800 ? 1 : 3}
              itemsToScroll={1}
              speed={1000}
            >
              {comments.map((comment) => (
                <div className="comments-card-container">
                  <div className="comments-card">
                    <div className={"comments-content-container"}>
                      <img src={comment.icon1} alt="" />
                      <span>{comment.comment}</span>
                    </div>
                    <div className="comments-person">
                      {comment.icon2 ? (
                        <img src={comment.icon2} alt="" />
                      ) : (
                        <Avatar
                          sx={{
                            height: "50px !important",
                            width: "50px !important",
                          }}
                        >
                          {comment.personName
                            .split(" ")
                            .map((part) => part[0].toUpperCase())}
                        </Avatar>
                      )}
                      <div className="comments-person-data">
                        <span>{comment.personName}</span>
                        <span>{comment.personPosition}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/*<div className="comments-card-container">*/}
              {/*  <div className="comments-card">*/}
              {/*    <img src={comments[0].icon1} alt="" />*/}
              {/*    <span>{comments[0].comment}</span>*/}
              {/*    <div className="comments-person">*/}
              {/*      <img src={comments[0].icon2} alt="" />*/}
              {/*      <div className="comments-person-data">*/}
              {/*        <span>{comments[0].personName}</span>*/}
              {/*        <span>{comments[0].personPosition}</span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="comments-card-container">*/}
              {/*  <div className="comments-card">*/}
              {/*    <img src={comments[1].icon1} alt="" />*/}
              {/*    <span>{comments[1].comment}</span>*/}
              {/*    <div className="comments-person">*/}
              {/*      <img src={comments[1].icon2} alt="" />*/}
              {/*      <div className="comments-person-data">*/}
              {/*        <span>{comments[1].personName}</span>*/}
              {/*        <span>{comments[1].personPosition}</span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="comments-card-container">*/}
              {/*  <div className="comments-card">*/}
              {/*    <img src={comments[2].icon1} alt="" />*/}
              {/*    <span>{comments[2].comment}</span>*/}
              {/*    <div className="comments-person">*/}
              {/*      <img src={comments[2].icon2} alt="" />*/}
              {/*      <div className="comments-person-data">*/}
              {/*        <span>{comments[2].personName}</span>*/}
              {/*        <span>{comments[2].personPosition}</span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="comments-card-container">*/}
              {/*  <div className="comments-card">*/}
              {/*    <img src={comments[3].icon1} alt="" />*/}
              {/*    <span>{comments[3].comment}</span>*/}
              {/*    <div className="comments-person">*/}
              {/*      <img src={comments[3].icon2} alt="" />*/}
              {/*      <div className="comments-person-data">*/}
              {/*        <span>{comments[3].personName}</span>*/}
              {/*        <span>{comments[3].personPosition}</span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="comments-card-container">*/}
              {/*  <div className="comments-card">*/}
              {/*    <img src={comments[4].icon1} alt="" />*/}
              {/*    <span>{comments[4].comment}</span>*/}
              {/*    <div className="comments-person">*/}
              {/*      <img src={comments[4].icon2} alt="" />*/}
              {/*      <div className="comments-person-data">*/}
              {/*        <span>{comments[4].personName}</span>*/}
              {/*        <span>{comments[4].personPosition}</span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="comments-card-container">*/}
              {/*  <div className="comments-card">*/}
              {/*    <img src={comments[0].icon1} alt="" />*/}
              {/*    <span>{comments[0].comment}</span>*/}
              {/*    <div className="comments-person">*/}
              {/*      <img src={comments[0].icon2} alt="" />*/}
              {/*      <div className="comments-person-data">*/}
              {/*        <span>{comments[0].personName}</span>*/}
              {/*        <span>{comments[0].personPosition}</span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="comments-card-container">*/}
              {/*  <div className="comments-card">*/}
              {/*    <img src={comments[1].icon1} alt="" />*/}
              {/*    <span>{comments[1].comment}</span>*/}
              {/*    <div className="comments-person">*/}
              {/*      <img src={comments[1].icon2} alt="" />*/}
              {/*      <div className="comments-person-data">*/}
              {/*        <span>{comments[1].personName}</span>*/}
              {/*        <span>{comments[1].personPosition}</span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="comments-card-container">*/}
              {/*  <div className="comments-card">*/}
              {/*    <img src={comments[2].icon1} alt="" />*/}
              {/*    <span>{comments[2].comment}</span>*/}
              {/*    <div className="comments-person">*/}
              {/*      <img src={comments[2].icon2} alt="" />*/}
              {/*      <div className="comments-person-data">*/}
              {/*        <span>{comments[2].personName}</span>*/}
              {/*        <span>{comments[2].personPosition}</span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="comments-card-container">*/}
              {/*  <div className="comments-card">*/}
              {/*    <img src={comments[3].icon1} alt="" />*/}
              {/*    <span>{comments[3].comment}</span>*/}
              {/*    <div className="comments-person">*/}
              {/*      <img src={comments[3].icon2} alt="" />*/}
              {/*      <div className="comments-person-data">*/}
              {/*        <span>{comments[3].personName}</span>*/}
              {/*        <span>{comments[3].personPosition}</span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </ReactSimplyCarousel>
          )}
        </div>
      </section>

      <section className="mike-section">
        <div className="mike-block">
          <img src={icons.mikeIcon} alt="scott-icon" />
          <div>
            <h3>Why AidKonekt?</h3>
            <p>
              AidKonekt was developed by Mike Shanley, Founder & CEO to meet a
              recognized need within the USAID Market. Throughout his
              development career, Mike observed the time and resource intensive
              nature of USAID Business Development and its limiting effects on
              organizations' goals for implementation and impact. AidKonekt
              allows organizations to streamline USAID procurement monitoring
              and market research, lower USAID business development costs, and
              create accessible pathways for new partners to enter the market.
            </p>
          </div>
        </div>
      </section>

      <section className="opportunities-section">
        <div className="opportunities-block">
          <div>
            <h3>Bid on more opportunities more efficiently.</h3>
            <p>
              AidKonekt is the only comprehensive platform targeted exclusively
              to USAID Business Development teams.
            </p>
          </div>
          <img src={icons.clockIcon} alt="logo-icon" />
        </div>
      </section>

      <section className="top-line-block partners">
        <h3>Join the growing list of USAID partners</h3>
        <span>
          streamlining their USAID business development work with AidKonekt!
        </span>
        <Grid container spacing={1} marginTop={2}>
          {logos.map((logo, index) => (
            <Grid
              item
              xl={2.4}
              lg={3}
              md={4}
              sm={6}
              xs={12}
              sx={{
                height: "180px",
                display: "flex",
              }}
            >
              <a
                {...(logo.href && {
                  href: logo.href,
                  target: "_blank",
                  rel: "noreferrer noopener",
                })}
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={logo.img}
                  alt={`logo-${index + 1}`}
                  style={{
                    maxHeight: "155px",
                    // maxWidth: "284px",
                    width: "inherit",
                  }}
                />
              </a>
            </Grid>
          ))}
        </Grid>
        {/*<div className="partners-logo-list">*/}
        {/*  {logos.map((logo, index) => (*/}
        {/*    <div>*/}
        {/*      <a*/}
        {/*        {...(logo.href && {*/}
        {/*          href: logo.href,*/}
        {/*          target: "_blank",*/}
        {/*          rel: "noreferrer noopener",*/}
        {/*        })}*/}
        {/*      >*/}
        {/*        <img src={logo.img} alt={`logo-${index + 1}`} />*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {/*  <div*/}
        {/*    style={*/}
        {/*      {*/}
        {/*        // flex: 1*/}
        {/*      }*/}
        {/*    }*/}
        {/*  ></div>*/}
        {/*</div>*/}
      </section>

      <section className="what-is-section">
        <h2>What is AidKonekt?</h2>
        <div className="what-is-block">
          <div className="what-is-list">
            <div className="what-is-item">
              <h5>Automated Tasks</h5>
              <span>Save time, bid more</span>
            </div>
            <div className="what-is-item">
              <h5>Better Data</h5>
              <span>Improving the resources you already use</span>
            </div>
            <div className="what-is-item">
              <h5>Earlier Intel</h5>
              <span>Stay ahead of the curve</span>
            </div>
          </div>
          <img src={icons.aidPreviewIcon} alt="aid-preview-icon" />
        </div>
      </section>

      <section className="about-how">
        <div className="title-container">
          <div className="title">
            <h2>How Can AidKonekt Work For You?</h2>
          </div>
        </div>
        <div className="levels-container">
          <div className="level-card">
            <div className="level-img">
              <img src={icons.firstPerson} alt="person-1" />
            </div>
            <div className="level-content">
              <div className="privilege-list">
                <h3>New USAID Partner</h3>
                <p>
                  Quickly learn how to monitor the USAID market, identify
                  partners and learn best practices in minutes every day.
                </p>
              </div>
            </div>
          </div>
          <div className="level-card">
            <div className="level-img adapt">
              <img src={icons.thirdPerson} alt="person-3" />
            </div>
            <div className="level-content">
              <div className="privilege-list">
                <h3>USAID Subrecipient</h3>
                <p>
                  Manage your pipeline in minutes daily, identify partners and
                  learn how to leverage new tools/resources.
                </p>
              </div>
            </div>
            <div className="level-img">
              <img src={icons.thirdPerson} alt="person-3" />
            </div>
          </div>
          <div className="level-card">
            <div className="level-img">
              <img src={icons.secondPerson} alt="person-2" />
            </div>
            <div className="level-content">
              <div className="privilege-list">
                <h3>USAID Prime</h3>
                <p>
                  Streamline time-consuming tasks and searches, train new
                  employees easily.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="aside-img blue-airplane">
          <img src={icons.blueAirplane} alt="blue-airplane" />
        </div>
      </section>

      <section className="tasks">
        <div className="title-container">
          <div className="title">
            <h2>
              Why AidKonekt is the right solution for USAID business development
            </h2>
          </div>
        </div>
        <div className="tasks-cards-container">
          <div className="tasks-cards-container-content">
            <div className="task-card">
              <span>
                Designed by and for USAID Business Development specialists.
              </span>
            </div>
            <div className="task-card">
              <span>
                User friendly interface helps teams streamline their partnering
                processes.
              </span>
            </div>
            <div className="task-card">
              <span>
                Delivers clear data on current, future, and historical USAID
                procurements.
              </span>
            </div>
            <div className="task-card">
              <span>Customizable pipeline and notification system.</span>
            </div>
            <div className="task-card">
              <span>
                Quickly search and share 100,000s of USAID procurement and
                project documents.
              </span>
            </div>
            <div className="task-card">
              <span>
                Partner and capture research in minutes, rather than days
              </span>
            </div>
          </div>
        </div>
        <div className="exclusive-block">
          <div className="exclusive">
            <p>
              Other platforms tell you what's happening today. AidKonekt does
              this and more by also preparing you for what may be coming up
              next. It's the difference between knowing the weather today, and
              knowing the weather a year from now.
            </p>
          </div>
        </div>
      </section>

      <section className="comment-section">
        <div className="comment-block">
          <p>
            “I save hours every week by using AidKonekt because it aggregates
            multiple websites small and large businesses use to track
            opportunities with USAID, MCC, and the State Dept. I'm able to work
            with my team more efficiently with a shared space to track our
            progress against our business development goals.”
          </p>
          <div>
            <b>Chetna Chandrasekaran</b>
            <span>
              Lead, Business Development and Marketing, Environmental Incentives
            </span>
          </div>
        </div>
      </section>

      <section className="plan-section">
        <div className="websites-images">
          <img src={icons.aidWebsites} alt="aid-websites" />
        </div>
        <div className="desktop-view">
          <div className="plan-block">
            <span>Find the right plan for you</span>
            <div className="plans">
              <div className="free-plan">
                <h5>Free</h5>
              </div>
              <div className="plus-plan">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19 11H13V5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H11V19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19V13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z"
                    fill="#197AB5"
                  />
                </svg>
                <h5>Plus</h5>
              </div>
              <div className="pro-plan">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19.89 9.5498C19.8069 9.38488 19.6797 9.2462 19.5226 9.14912C19.3656 9.05204 19.1847 9.00036 19 8.9998H14V2.9998C14.0107 2.78048 13.949 2.56373 13.8243 2.383C13.6996 2.20227 13.5188 2.06761 13.31 1.9998C13.1092 1.93374 12.8927 1.933 12.6914 1.99768C12.4902 2.06236 12.3147 2.18913 12.19 2.3598L4.18998 13.3598C4.08975 13.5047 4.02956 13.6734 4.01551 13.849C4.00147 14.0246 4.03406 14.2008 4.10998 14.3598C4.17991 14.5415 4.30137 14.6989 4.45947 14.8126C4.61757 14.9263 4.80543 14.9914 4.99998 14.9998H9.99998V20.9998C10.0001 21.2107 10.067 21.4161 10.1909 21.5867C10.3148 21.7574 10.4895 21.8844 10.69 21.9498C10.7905 21.9809 10.8948 21.9978 11 21.9998C11.1578 22.0002 11.3134 21.9633 11.4542 21.892C11.595 21.8208 11.7169 21.7172 11.81 21.5898L19.81 10.5898C19.9177 10.4406 19.9822 10.2646 19.9963 10.0811C20.0104 9.89756 19.9736 9.71373 19.89 9.5498ZM12 17.9198V13.9998C12 13.7346 11.8946 13.4802 11.7071 13.2927C11.5196 13.1052 11.2652 12.9998 11 12.9998H6.99998L12 6.0798V9.9998C12 10.265 12.1053 10.5194 12.2929 10.7069C12.4804 10.8944 12.7348 10.9998 13 10.9998H17L12 17.9198Z"
                    fill="#EEAD24"
                  />
                </svg>
                <h5>Pro</h5>
              </div>
            </div>
          </div>
          <div className="plan-description-block">
            <h5>USAID Market Pipeline</h5>
            <ul className="usaid-list">
              <OptionItem
                title="View recent USAID forecast changes"
                options={3}
              />
              <OptionItem
                title="View actual historical changes to each USAID Business Forecast opportunity"
                options={2}
              />
              <OptionItem
                title="View Suggested Primes and Partners on the Forecast+ feed"
                options={2}
              />
              <OptionItem
                title="Build, manage, and monitor pipeline updates for your USAID forecast, grants, and contracts opportunities"
                options={2}
              />
              <OptionItem
                title="Collaborate with your team to share, monitor and track new opportunities and changes on the forecast, sam.gov and grants.gov"
                options={2}
              />
              <OptionItem
                title="Add notes for your team on each forecasted opportunity"
                options={2}
              />
              <OptionItem
                title="Export your pipeline in Excel files"
                options={2}
              />
              <OptionItem
                title="Share USAID forecast, contracts, and grants opportunities with your AidKonekt team members via email"
                options={2}
              />
              <OptionItem
                title="Easily share attachments from Grants+ and Contracts+ Feeds"
                options={2}
              />
              <OptionItem
                title="View USAID Market Events Calendar with RSVP links"
                options={2}
              />
              <OptionItem
                title="Weekly USAID Pipeline Email Digest Updates (all pipeline changes and new opportunities in one email)"
                options={2}
              />
              <OptionItem
                title="Daily USAID Pipeline Email Digest Updates (all pipeline changes and new opportunities in one email)"
                options={1}
              />
            </ul>
          </div>
          <div className="plan-description-block">
            <h5>Capture/Market Intel</h5>
            <ul className="usaid-list">
              <OptionItem
                title="Create USAID project reports filtered by country, sector, or competitor."
                options={1}
              />
              <OptionItem
                title="Search for local partners and Key Personnel candidates"
                options={1}
              />
              <OptionItem
                title="Project reports feature to quickly export current projects and project closing reports."
                options={1}
              />
              <OptionItem
                title={`Export "Projects Closing" reports for your target USAID markets`}
                options={1}
              />
              <OptionItem
                title="See recently awarded USAID projects"
                options={1}
              />
              <OptionItem
                title="Quickly search and access 300,000+ USAID reports, evaluations and assessments from DEC"
                options={1}
              />
              <OptionItem
                title="Projects closing export for any USAID country (see which USAID projects close next)"
                options={1}
              />
              <OptionItem
                title="Export current and historical USAID project, Foreign Assistance and DEC report searches"
                options={1}
              />
              <OptionItem
                title="Access AidKonekt USAID Market Resources, Local Compensation Plan Archive, Initial Environmental Examination Archive, and Webinar Archive"
                options={1}
              />
            </ul>
          </div>
        </div>
        <div className="mobile-view">
          <div className="mobile-plan-card pro">
            <h4>Pro</h4>
            <p>Capture research in minutes, not days.</p>
            <ul>
              <li>USAID Pipelines</li>
              <li>News and Updates</li>
              <li>Capture Market Research</li>
            </ul>
            <button onClick={() => setActiveMobilePlan("pro")}>
              View Features
            </button>
          </div>
          <div className="mobile-plan-card plus">
            <h4>Plus</h4>
            <p>Automate monitoring of your USAID pipeline</p>
            <ul>
              <li>USAID Pipelines</li>
              <li>News and Updates</li>
            </ul>
            <button onClick={() => setActiveMobilePlan("plus")}>
              View Features
            </button>
          </div>
          <div className="mobile-plan-card free">
            <h4>Free</h4>
            <p>Check in daily to see Forecast changes</p>
            <ul>
              <li>USAID Pipelines</li>
            </ul>
            <button onClick={() => setActiveMobilePlan("free")}>
              View Features
            </button>
          </div>
        </div>
      </section>

      {activeMobilePlan === "pro" && (
        <div className="mobile-plan-view">
          <h4>Find The Right Plan For You!</h4>
          <div className="mobile-plan-view-card pro">
            <span className="back-btn" onClick={() => setActiveMobilePlan("")}>
              Back to Plans
            </span>
            <div>
              <h4>Pro</h4>
              <h5>Capture research in minutes, not days.</h5>
              <div className="mobile-plan-view-card-list">
                <p>USAID Pipelines</p>
                <ul>
                  <li>
                    <span>
                      Create and monitor your team's proprietary business
                      forecast activity/changes feed
                    </span>
                  </li>
                  <li>
                    <span>
                      View Suggested Primes and Partners on the Forecast+ feed
                    </span>
                  </li>
                  <li>
                    <span>
                      Easily share attachments f rom Grants+ and Contracts+
                      Feeds
                    </span>
                  </li>
                  <li>
                    <span>
                      Keyword search historical USAID grants and contracts
                      attachments by file names
                    </span>
                  </li>
                  <li>
                    <span>
                      Search, access, and collaborate on opportunities with your
                      team
                    </span>
                  </li>
                  <li>
                    <span>
                      Build, manage, and monitor pipeline updates for your USAID
                      forecast, grants, and contracts opportunities
                    </span>
                  </li>
                  <li>
                    <span>
                      View historical changes to all feed opportunities
                    </span>
                  </li>
                  <li>
                    <span>Export your pipeline as an Excel or CSV file</span>
                  </li>
                </ul>
              </div>
              <div className="mobile-plan-view-card-list">
                <p>News and Updates</p>
                <ul>
                  <li>
                    <span>USAID News and Updates Feed</span>
                  </li>
                  <li>
                    <span>
                      View USAID Market Events Calendar with RSVP links
                    </span>
                  </li>
                  <li>
                    <span>Receive one weekly notification email</span>
                  </li>
                  <li>
                    <span>
                      Set-up and send custom, daily email notifications to up
                      toten colleagues
                    </span>
                  </li>
                </ul>
              </div>
              <div className="mobile-plan-view-card-list">
                <p>Capture Market Research</p>
                <ul>
                  <li>
                    <span>
                      Search USAID projects by start and end date and other
                      filters
                    </span>
                  </li>
                  <li>
                    <span>
                      Quickly search and access more than 150,000 USAID reports,
                      evaluations and assessments f rom DEC
                    </span>
                  </li>
                  <li>
                    <span>
                      Export current and historical USAID project and DEC report
                      searches
                    </span>
                  </li>
                  <li>
                    <span>
                      Access AidKonekt USAID Market Resources, Local
                      Compensation Plan Archive, and Webinar Archive
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      {activeMobilePlan === "plus" && (
        <div className="mobile-plan-view">
          <h4>Find The Right Plan For You!</h4>
          <div className="mobile-plan-view-card plus">
            <span className="back-btn" onClick={() => setActiveMobilePlan("")}>
              Back to Plans
            </span>
            <div>
              <h4>Plus</h4>
              <h5>Automate monitoring of your USAID pipeline</h5>
              <div className="mobile-plan-view-card-list">
                <p>USAID Pipelines</p>
                <ul>
                  <li>
                    <span>
                      Create and monitor your team's proprietary business
                      forecast activity/changes feed
                    </span>
                  </li>
                  <li>
                    <span>
                      View Suggested Primes and Partners on the Forecast+ feed
                    </span>
                  </li>
                  <li>
                    <span>
                      Easily share attachments f rom Grants+ and Contracts+
                      Feeds
                    </span>
                  </li>
                  <li>
                    <span>
                      Keyword search historical USAID grants and contracts
                      attachments by file names
                    </span>
                  </li>
                  <li>
                    <span>
                      Search, access, and collaborate on opportunities with your
                      team
                    </span>
                  </li>
                  <li>
                    <span>
                      Build, manage, and monitor pipeline updates for your USAID
                      forecast, grants, and contracts opportunities
                    </span>
                  </li>
                  <li>
                    <span>
                      View historical changes to all feed opportunities
                    </span>
                  </li>
                  <li>
                    <span>Export your pipeline as an Excel or CSV file</span>
                  </li>
                </ul>
              </div>
              <div className="mobile-plan-view-card-list">
                <p>News and Updates</p>
                <ul>
                  <li>
                    <span>USAID News and Updates Feed</span>
                  </li>
                  <li>
                    <span>
                      View USAID Market Events Calendar with RSVP links
                    </span>
                  </li>
                  <li>
                    <span>Receive one weekly notification email</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      {activeMobilePlan === "free" && (
        <div className="mobile-plan-view">
          <h4>Find The Right Plan For You!</h4>
          <div className="mobile-plan-view-card free">
            <span className="back-btn" onClick={() => setActiveMobilePlan("")}>
              Back to Plans
            </span>
            <div>
              <h4>Free</h4>
              <h5>Check in daily to see Forecast changes</h5>
              <div className="mobile-plan-view-card-list">
                <p>USAID Pipelines</p>
                <ul>
                  <li>
                    <span>
                      Create and monitor your team's proprietary business
                      forecast activity/changes feed
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      <section className="footer-buttons">
        <div className="buttons-container">
          <Button
            label="Schedule a Demo"
            onClick={() =>
              window.open(
                "https://calendly.com/aidkonekt-usaid-data/15-min-aidkonekt-intro-call",
                "_blank",
              )
            }
            className="footer-button"
            style={styles.footerButton}
          />
          <Button
            label="Create a Free Account"
            onClick={() => window.open("auth", "_blank")}
            className="footer-button"
            style={styles.footerButton}
          />
        </div>
      </section>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Roboto:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
      </style>
    </div>
  );
};

export default LandingPage;
