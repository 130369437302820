import { combineReducers } from "@reduxjs/toolkit";
import { reducer as forecastReducer } from "../slices/forecast";
import { reducer as pipelinesReducer } from "../slices/pipelines";
import { reducer as organizationReducer } from "../slices/organization";
import { reducer as usaSpendingReducer } from "../slices/usa-spending";
import { reducer as foreignAssistanceReducer } from "../slices/foreign-assistance";
import { reducer as userSettingsReducer } from "../slices/user-settings";
import { reducer as contractsReducer } from "../slices/contracts";
import { reducer as grantsReducer } from "../slices/grants";
import { reducer as dashboardReducer } from "../slices/dashboard";
import { reducer as reportsReducer } from "../slices/reports";
import { reducer as ecdReducer } from "../slices/ecd";

export const reducerProxy = (state: any, action: any) => {
  if (action.type === "logout/LOGOUT") {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

export const rootReducer = combineReducers({
  forecast: forecastReducer,
  pipelines: pipelinesReducer,
  organization: organizationReducer,
  usaSpending: usaSpendingReducer,
  foreignAssistance: foreignAssistanceReducer,
  userSettings: userSettingsReducer,
  contracts: contractsReducer,
  grants: grantsReducer,
  dashboard: dashboardReducer,
  reports: reportsReducer,
  ecd: ecdReducer,
});
