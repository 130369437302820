import React, { useContext, useRef, useState } from "react";
import UserContext, {
  UserRoleGroups,
  isUserInRoles,
  ServerOrgRoles,
  isUserAdminOrSemiAdminWith,
} from "../../services/UserContext";

import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { parseISO } from "date-fns";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";

import GrowlContext from "../../services/growlContext";
import "../DataTable.css";
import "./Forecast.css";
import getWindowDimensions from "../../services/dimensions";
import { withRouter } from "react-router-dom";

import { getDateString, getFullDateString } from "../../services/formatter";
import { tryCatchServerError, useDebounce } from "../../services/functions";
import IconTooltip from "../../components/IconTooltip";

import { Popover } from "../../components/Popover/Popover";
import ThemeContext from "../../configs/theme";
import { useGridStyles } from "../../hooks/useGridStyles";

import {
  MaximizeGrid,
  MinimizeGrid,
} from "../../components/DataTable/ActionButtons";

import {
  buildExcelTable,
  generateExcel,
  genEmptyRow,
} from "../../services/exporter";
import { TableProperties } from "exceljs";
import Switch from "react-switch";

import { rowsPerPageOptionsStandard } from "../../utils/constants/gridsOptions";
import axios from "axios";
import { showRichTextEditorComponent } from "../../components/ShowRichTextEditor";
import {
  removeHtmlTags,
  removeHtmlTagsExceptLink,
} from "../../services/dataParser";
import plusButton from "../../assets/icons/v2/icon-11.png";
import styled from "@emotion/styled";
import { MultiSelect } from "primereact/multiselect";
import Chip from "../../components/Chip";
import { arrayEquals } from "../../utils/Helpers/arrayEqual";
import { Capitalize } from "../../utils/Helpers/Capitalize";
import { validateEmailSimple } from "../../utils/validation/input-validation";

export interface ShareUser {
  publicUserId: string;
  firstName: string;
  lastName: string;
  userRole: string;
  email: string;
}

const customStyle = {
  multiSelect: {
    width: "100%",
    borderRadius: 10,
  },
  chipsContainer: {
    marginTop: "5px",
    display: "flex",
    flexWrap: "wrap" as "wrap",
  },
};
const OverlayerWindow = styled.div({
  zIndex: 1000000,
});
enum myListStatus {
  default = 0,
  bid,
  noBid,
  undecided,
}

enum businessForecastRecordType {
  Default = "Default",
  NewRecord = "NewRecord",
  ModifiedRecord = "ModifiedRecord",
  FinalRecord = "FinalRecord",
}
type PipelineForecasts = {
  id: number;
  name: string;
  businessForcast: [];
};
interface ServerRecord {
  forecast: {
    id: string;
    rowId: number;
    country: string;
    sector: string;
    totalEstimatedCost: string;
    awardActionType: string;
    smallBusinessSetAside: string;
    anticipatedAwardDate: Date;
    anticipatedSolicitationReleaseDate: Date;
    createdOn: Date | null;
    status: businessForecastRecordType;
    changedColumns: string;
    awardTitle: string;
    awardDescription: string;

    aaPlanId: string;
    fiscalYearofAction: string;
    aaSpecialist: string;
    awardLength: string;
    eligibilityCriteria: string;
    geographicalCodes: string;
    categoryManagementContractVehicle: string;
    solicitationNumber: string;
    coCreation: string;
    location: string;
  };
  enhancements: {
    primeOrIncumbent: string;
    quarterlyCallNotes: string;
  };
  userEdits: {
    myListStatus: myListStatus;
    isMyList: boolean;
    email: string;
    notes: string;
  };
  pipelines: {
    numberIncludedPipelines: number;
  };
}

type ForecastRecord = {
  id: string;
  country: string;
  createdOnDate: Date | null;
  isMyList: boolean;
  title: string;
  description: string;
  sector: string;
  primeOrIncumbent: string;
  quarterlyCallNotes: string;
  estimatedCostRange: string;
  projectType: string;
  anticipatedReleaseDate: Date;
  anticipatedAwardDate: Date;
  smallBusinessSetAside: string;
  changedColumns: string;
  aaPlanId: string;
  fiscalYearofAction: string;
  aaSpecialist: string;
  awardLength: string;
  eligibilityCriteria: string;
  geographicalCodes: string;
  categoryManagementContractVehicle: string;
  solicitationNumber: string;
  coCreation: string;
  location: string;
  rawRecord: ServerRecord;
};

type ForecastSearch = {
  keyword: string;
  sortField: string;
  sortOrder: number;
  rowsPerPage: number;
  pageIndex: number;
  liveOnly: boolean;
  newOnly: boolean;
  OnlyUntrackedInPipeline: boolean;
};
type PipelineDto = {
  id: number;
  name: string;
};
function ForecastScreen({ history }: { history: any }) {
  const context = useContext(UserContext);
  const styles = useGridStyles();
  const theme = React.useContext(ThemeContext);
  const [initialPageLoaded, setInitialPageLoaded] = useState(false);
  const [data, setData] = useState<ForecastRecord[]>(Array<ForecastRecord>());
  const [rowData, setRowData] = useState<ForecastRecord>();
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRecords, setTotalRecords] = React.useState(0); // todo
  const [first, setFirst] = React.useState(0);
  const [sortField, setSortField] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState(0);
  const [newOnlyField, setNewOnlyField] = React.useState(false);
  const [liveOnlyField, setLiveOnlyField] = React.useState(false);
  const [trackedOpportunitiesField, setTrackedOpportunitiesField] =
    React.useState(false);
  const [lastUpdated, setLastUpdated] = React.useState("");
  const [keyword, setKeyword] = React.useState("");
  const [liveTally, setLiveTally] = React.useState(0);
  const [pipelineForcasts, setPipelineforcasts] = useState<PipelineForecasts[]>(
    Array<PipelineForecasts>()
  );

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState<string | JSX.Element>("");
  const [dialogVisible, setDialogVisible] = React.useState("");
  const [colunmType, setColumnType] = React.useState("");

  const dialogTableName = "Forecasts";

  const growl = React.useContext(GrowlContext);

  const canEditPrimes = isUserAdminOrSemiAdminWith(
    context,
    ServerOrgRoles.EditPrimesAndQuarterlyCallInfo
  );

  const [queryDto, setQueryDto] = React.useState<ForecastSearch>();

  const [gridWidth, setGridWidth] = React.useState(0);

  const debouncedSearch = useDebounce(keyword, 750);
  const popupMenu = useRef<OverlayPanel>(null);
  const [pipeline, setPipelines] = useState<PipelineDto[]>(
    Array<PipelineDto>()
  );

  const [selectedPipelines, setSelectedPipelines] = React.useState<
    PipelineDto[]
  >([]);
  const [serverRecord, setServerRecord] = React.useState<PipelineDto[]>([]);
  const [toggleAddPipeline, setToggle] = React.useState(false);

  React.useEffect(() => {
    if (!initialPageLoaded) {
      return;
    }
    queryData({
      first,
      rows: rowsPerPage,
      liveOnly: liveOnlyField,
      newOnly: newOnlyField,
      trackedOpportunities: trackedOpportunitiesField,
    });
  }, [debouncedSearch, initialPageLoaded]);

  React.useEffect(() => {
    if (!initialPageLoaded) {
      return;
    }
    queryData({
      first,
      rows: rowsPerPage,
      liveOnly: liveOnlyField,
      newOnly: newOnlyField,
      trackedOpportunities: trackedOpportunitiesField,
    });
  }, [
    liveOnlyField,
    newOnlyField,
    trackedOpportunitiesField,
    initialPageLoaded,
  ]);

  React.useEffect(() => {
    queryData({
      first: first,
      rows: rowsPerPage,
      liveOnly: liveOnlyField,
      trackedOpportunities: trackedOpportunitiesField,
    });

    function handleResize() {
      const { width, headerWidth } = getWindowDimensions();
      if (width && headerWidth) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    setInitialPageLoaded(true);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  React.useEffect(() => {
    const onDescriptionClick = (event: any) => {
      if (!event.target.matches(".show-popover")) {
        return;
      }
      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      const dialogText = container.attributes["data-full-abstract"].value;

      if (!dialogText) return;

      setDialogHeader(container.attributes["data-title"].value);
      setDialogText(dialogText);
      setDialogVisible(dialogTableName);

      setColumnType(container.id);
    };
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
      setColumnType("");
    };
  }, []);

  const openUpdatePopup = async (
    id: string,
    name: string,
    existingPrimes: string,
    existingQuarterlyText: string
  ) => {
    const result = await showRichTextEditorComponent({
      name: `Enter Info for ${name}`,
      components: [
        { name: "Likely Primes", startingText: existingPrimes },
        {
          name: "Quarterly Forecast Q&A and Notes",
          startingText: existingQuarterlyText,
        },
      ],
    });

    if (!result.isConfirmed) {
      return;
    }

    // have new primes - save them
    let request = createAuthenticatedRequest(context);

    await axios
      .post(
        getFullUrl("/api/BusinessForecast/EnhancedForecast", {
          useDedicatedEnvironment: true,
        }),
        {
          businessForecastId: id,
          subPrimes: result.components[0], // order depends on order entered initially
          quarterlyCallNotes: result.components[1],
        },
        request
      )
      .then(() => {
        const newData = [...data] as any;
        newData
          .filter((r: ForecastRecord) => r.rawRecord.forecast.id === id)
          .forEach((r: ForecastRecord) => {
            r.primeOrIncumbent = result.components[0];
            r.quarterlyCallNotes = result.components[1];
          });
        setData(newData);
      })
      .catch((error) => {
        console.error(error);
        alert("error updating prime data");
      });
  };

  function sort(event: { sortField: string; sortOrder: number }) {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);
    queryData({
      first,
      rows: rowsPerPage,
      sort: { ...event },
      liveOnly: liveOnlyField,
      newOnly: newOnlyField,
      trackedOpportunities: trackedOpportunitiesField,
    });
  }

  const queryData = (event: {
    first: number;
    rows: number;
    sort?: { sortField: string; sortOrder: number };
    liveOnly?: boolean;
    newOnly?: boolean;
    trackedOpportunities?: boolean;
  }) => {
    setIsLoading(true);
    setRowsPerPage(event.rows);
    setFirst(event.first);

    const dto: ForecastSearch = {
      keyword: debouncedSearch,
      sortField: event.sort ? event.sort.sortField : sortField,
      sortOrder: event.sort ? event.sort.sortOrder : sortOrder,
      rowsPerPage: event.rows, // need to send rows since rowsPerPage may not be updated yet
      pageIndex: event.first / event.rows,
      liveOnly: event.liveOnly ? event.liveOnly : liveOnlyField,
      newOnly: event.newOnly ? event.newOnly : newOnlyField,
      OnlyUntrackedInPipeline: event.trackedOpportunities
        ? event.trackedOpportunities
        : trackedOpportunitiesField,
    };
    setQueryDto(dto);

    axios
      .post(
        getFullUrl("/api/businessforecast/RecentForecasts", {
          useDedicatedEnvironment: true,
        }),
        dto,
        createRequestWithAuthHeaders(context)
      )
      .then((response) => {
        const { lastUpdatedUtc, data, totalItems, attributes } = response.data;
        setTotalRecords(totalItems);

        if (attributes && attributes.TotalLiveRecords) {
          setLiveTally(attributes.TotalLiveRecords);
        }

        const records: ForecastRecord[] = (data as ServerRecord[]).map((d) => ({
          id: d.forecast.id,
          country: d.forecast.country,
          createdOnDate: d.forecast.createdOn,
          isMyList: d.userEdits.isMyList,
          title: d.forecast.awardTitle,
          description: d.forecast.awardDescription,
          sector: d.forecast.sector,
          primeOrIncumbent: d.enhancements.primeOrIncumbent,
          quarterlyCallNotes: d.enhancements.quarterlyCallNotes,
          estimatedCostRange: d.forecast.totalEstimatedCost,
          projectType: d.forecast.awardActionType,
          anticipatedReleaseDate: d.forecast.anticipatedSolicitationReleaseDate,
          anticipatedAwardDate: d.forecast.anticipatedAwardDate,
          smallBusinessSetAside: d.forecast.smallBusinessSetAside,
          changedColumns: d.forecast.changedColumns,
          numberIncludedPipelines: d.pipelines.numberIncludedPipelines,
          aaPlanId: d.forecast.aaPlanId,
          fiscalYearofAction: d.forecast.fiscalYearofAction,
          aaSpecialist: d.forecast.aaSpecialist,
          awardLength: d.forecast.awardLength,
          eligibilityCriteria: d.forecast.eligibilityCriteria,
          geographicalCodes: d.forecast.geographicalCodes,
          categoryManagementContractVehicle:
            d.forecast.categoryManagementContractVehicle,
          solicitationNumber: d.forecast.solicitationNumber,
          coCreation: d.forecast.coCreation,
          location: d.forecast.location,
          rawRecord: d,
        }));

        setData(records);
        setLastUpdated(
          lastUpdatedUtc
            ? `Last updated ${getDateString(new Date(lastUpdatedUtc + "Z"))}`
            : ""
        );
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        alert("error fetching data");
      });
  };

  const getExportableDataTable = (data: ForecastRecord[]): TableProperties => {
    const columns = [
      { name: "usaid.gov link" },
      { name: "Opportunity Action" },
      { name: "Changed Columns" },
      { name: "Country/Office" },
      { name: "Date Record Created/Changed" },
      { name: "Name" },
      { name: "Description" },
      { name: "Sector" },
      { name: "Suggested Primes/Partners" },
      { name: "Quarterly Forecast Q&A and Notes" },
      { name: "ID" },
      { name: "Category Management Contract Vehicle" },
      { name: "Cost" },
      { name: "Award/Action Type" },
      { name: "Geographical Codes" },
      { name: "Co-creation" },
      { name: "Anticipated Release Date" },
      { name: "Anticipated Award Date" },
      { name: "Award Length" },
      { name: "Small Business Set-Aside" },
      { name: "Eligibility Criteria" },
      { name: "Solicitation Number" },
      { name: "A&A Specialist" },
      { name: "Fiscal Year of Action" },
      { name: "Location" },
    ];

    let rows: any[][] = [];

    if (data.length == 0) {
      rows.push(genEmptyRow(columns.length));
    } else {
      for (let i = 0; i < data.length; i++) {
        const record = data[i];

        rows.push([
          record.rawRecord.forecast.status !==
          businessForecastRecordType.FinalRecord
            ? `https://www.usaid.gov/node/${record.rawRecord.forecast.rowId}`
            : "",
          record.rawRecord.forecast.status ===
          businessForecastRecordType.NewRecord
            ? "New Opportunity Added"
            : record.rawRecord.forecast.status ===
              businessForecastRecordType.ModifiedRecord
            ? "Updated Opportunity"
            : "Opportunity Removed",
          record.changedColumns
            ? record.changedColumns
                .replace(/\&nbsp;/g, "")
                .replace(/&amp;/g, "&")
            : "",
          record.country
            ? record.country.replace(/\&nbsp;/g, "")?.replace(/&amp;/g, "&")
            : "",
          record.createdOnDate,
          record.title
            ? record.title.replace(/\&nbsp;/g, "")?.replace(/&amp;/g, "&")
            : "",
          removeHtmlTagsExceptLink(record.description) ?? "",
          record.sector ? removeHtmlTagsExceptLink(record.sector) : "",
          removeHtmlTagsExceptLink(record.primeOrIncumbent) ?? "",
          removeHtmlTagsExceptLink(record.quarterlyCallNotes) ?? "",
          record.aaPlanId ?? "",
          record.categoryManagementContractVehicle ?? "",
          record.estimatedCostRange ?? "",
          record.projectType ?? "",
          removeHtmlTagsExceptLink(record.geographicalCodes) ?? "",
          removeHtmlTagsExceptLink(record.coCreation) ?? "",
          record.anticipatedReleaseDate ?? "",
          record.anticipatedAwardDate ?? "",
          record.awardLength ?? "",
          record.smallBusinessSetAside ?? "",
          record.eligibilityCriteria ?? "",
          record.solicitationNumber ?? "",
          record.aaSpecialist ?? "",
          record.fiscalYearofAction ?? "",
          record.location ?? "",
        ]);
      }
    }

    return buildExcelTable("ForecastExport", columns, rows);
  };

  const exportExcel = async () => {
    setDisableExcel(true);
    generateExcel(
      "Forecasts",
      `Aidkonekt_forecast_plus_${new Date().getFullYear()}`,
      getExportableDataTable(getExcelData ? await getExcelData() : data)
    ).then(() => {
      setDisableExcel(false);
    });
  };

  const showUpgradeAlert = () => {
    setDialogHeader("You're almost there!");
    setDialogText(
      `<div>
        This feature is disabled for your role. Please
        <a href="/tiers">upgrade</a> to access this feature!
      </div>`
    );
    setDialogVisible(dialogTableName);
  };

  let dt = React.useRef<DataTable>(null);
  const [isGridFullScreen, setIsGridFullScreen] = React.useState(false);
  const [exportVisible, setExportVisible] = React.useState(false);

  // Role specific behaviors
  const isFree = !isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const tableFooter = isFree
    ? "Showing last 20 of {totalRecords} entries - upgrade for full access!"
    : "Showing {first} to {last} of {totalRecords} entries";
  const paginatorTemplate = isFree
    ? "CurrentPageReport"
    : "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown";
  const canSort = !isFree;
  const exportFunc = isFree ? showUpgradeAlert : exportExcel;
  const upgradeFunc = () => {
    history.push("/tiers");
  };
  const primeOrIncumbentColumnBody = isFree
    ? (r: ForecastRecord) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{ alignSelf: "center" }}
            className="blurred-text"
          >{`${r.title} ${r.country}`}</div>
          <Button
            style={{ position: "absolute", alignSelf: "center" }}
            className="p-button-warning"
            type="button"
            onClick={upgradeFunc}
            label="Upgrade"
          />
        </div>
      )
    : (r: ForecastRecord) => (
        <p
          className="show-popover"
          data-full-abstract={r.primeOrIncumbent}
          data-title="Suggested Primes/Partners"
          dangerouslySetInnerHTML={{
            __html:
              r.primeOrIncumbent && r.primeOrIncumbent.length > 90
                ? `${removeHtmlTags(r.primeOrIncumbent).substring(0, 90)}...`
                : r.primeOrIncumbent,
          }}
          onClick={() => {
            setDialogHeader("Suggested Primes/Partners");
            setDialogText(r.primeOrIncumbent);
            setDialogVisible(dialogTableName);
          }}
        ></p>
      );
  const quarterlyCallNotesColumnBody = isFree
    ? (r: ForecastRecord) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{ alignSelf: "center" }}
            className="blurred-text"
          >{`${r.title} ${r.country}`}</div>
          <Button
            style={{ position: "absolute", alignSelf: "center" }}
            className="p-button-warning"
            type="button"
            onClick={upgradeFunc}
            label="Upgrade"
          />
        </div>
      )
    : (r: ForecastRecord) => (
        <p
          className="show-popover"
          data-full-abstract={r.quarterlyCallNotes}
          data-title="Quarterly Forecast Q&A and Notes"
          dangerouslySetInnerHTML={{
            __html:
              r.quarterlyCallNotes && r.quarterlyCallNotes.length > 90
                ? `${removeHtmlTags(r.quarterlyCallNotes).substring(0, 90)}...`
                : r.quarterlyCallNotes,
          }}
          onClick={() => {
            setDialogHeader("Quarterly Forecast Q&A and Notes");
            setDialogText(r.quarterlyCallNotes);
            setDialogVisible(dialogTableName);
          }}
        ></p>
      );

  const titleColumnBody = (r: ForecastRecord) => (
    <p
      className="show-popover"
      data-title={"Name"}
      data-full-abstract={r.title}
      dangerouslySetInnerHTML={{ __html: r.title }}
      onClick={() => {
        setDialogHeader("Name");
        setDialogText(r.title);
        setDialogVisible(dialogTableName);
      }}
    ></p>
  );

  const descriptionColumnBody = (r: ForecastRecord) => (
    <p
      className="show-popover"
      data-title={r.title}
      data-full-abstract={r.description}
      dangerouslySetInnerHTML={{
        __html:
          r.description && r.description.length > 100
            ? `${removeHtmlTags(r.description).substring(0, 100)}...`
            : r.description,
      }}
      onClick={() => {
        setDialogHeader(r.title);
        setDialogText(r.description);
        setDialogVisible(dialogTableName);
      }}
    ></p>
  );

  const primeOrIncumbentColumnHeader = (
    <>
      <span style={{ font: theme.v2Fonts.context, verticalAlign: "middle" }}>
        <IconTooltip
          tooltipText={
            "AidKonekt algorithm determines potential primes based on publicly available data. The (P) means prime has " +
            "relevant USAID project past performance, and the (R) means the prime has posted a public recruitment ad " +
            "relevant to this bid."
          }
        />
      </span>
      &nbsp;
      <span>Suggested Primes/Partners</span>
    </>
  );
  const quarterlyCallNotesColumnHeader = (
    <>
      <span style={{ font: theme.v2Fonts.context, verticalAlign: "middle" }}>
        <IconTooltip
          tooltipText={
            "This column includes updates from USAID's Quarterly Business Forecast call and Q&A, as well as other " +
            "relevant notes and market context for individual procurements. If the column is empty, that likely means " +
            "there has not been a recent question on that forecasted opportunity, or it has been posted after the most " +
            "recent USAID Quarterly Business Forecast call"
          }
        />
      </span>
      &nbsp;
      <span>Quarterly Forecast Q&A and Notes</span>
    </>
  );

  const isMyListColumnHeader = (
    <>
      <span style={{ font: theme.v2Fonts.context, verticalAlign: "middle" }}>
        <IconTooltip
          tooltipText={
            "Click here to add or remove opportunities you want to monitor. " +
            "These will appear in your MyBids pipeline and in your email notifications."
          }
        />
      </span>
      &nbsp;
      <span>My Bids+</span>
    </>
  );
  const firstColumnHeader = (
    <>
      <span style={{ font: theme.v2Fonts.context, verticalAlign: "middle" }}>
        <IconTooltip
          tooltipText={
            "This column notes the record type corresponding to the color-coded list above. " +
            "Blue - New Opportunity; Orange - Change; Grey - Removed Opportunity."
          }
        />
      </span>
      &nbsp;
    </>
  );

  // End role specific behaviors

  const categoryManagementColumnHeader = (
    <>
      <span style={{ font: theme.v2Fonts.context, verticalAlign: "middle" }}>
        <IconTooltip
          tooltipText={
            "Note from the USAID Industry Liaison on this field:" +
            "\n\n" +
            "The intent of the Category Management Contract Vehicle field is not to show the Tiers, but to show the actual category management vehicle that will be used. USAID is sharing this note internally for Contracting Officers to update as needed." +
            "\n\n" +
            "See FAQ linked here for more information on the “Tiers” referenced above."
          }
          className="category-management-tooltip"
        />
      </span>
      &nbsp;
      <span>Category Management Contract Vehicle</span>
    </>
  );

  // Share opportunity

  const isPlus = isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const isPro = isUserInRoles(context, UserRoleGroups.proOrHigher);
  const canShare = isPlus || isPro;

  const shareColumnHeader = (
    <>
      <span style={{ font: theme.v2Fonts.context, verticalAlign: "middle" }}>
        <IconTooltip tooltipText={"You can share opportunity via email"} />
      </span>
      &nbsp;
    </>
  );

  const [shareId, setShareId] = React.useState(0);
  const [shareUsers, setShareUsers] = React.useState<ShareUser[]>([]);
  const [shareExternalUser, setShareExternalUser] = React.useState<string>("");
  const [shareExternalUsers, setShareExternalUsers] = React.useState<string[]>(
    []
  );
  const [shareMessageText, setShareMessageText] = React.useState("");

  const handleAddExternalUser = () => {
    if (!!shareExternalUser) {
      const isValid = validateEmailSimple(shareExternalUser);
      if (isValid) {
        setShareExternalUsers([...shareExternalUsers, shareExternalUser]);
        setShareExternalUser("");
      } else {
        growl.current.show({
          severity: "error",
          summary: "Invalid email address",
        });
      }
    }
  };
  const removeExternalShareUser = (user: string) => {
    setShareExternalUsers(
      shareExternalUsers.filter((externalUser) => user !== externalUser)
    );
  };
  const removeShareUser = (userId: string) => {
    setShareUsers(shareUsers.filter((user) => user.publicUserId !== userId));
  };

  const usersEmails = React.useMemo(() => {
    if (!!shareUsers) {
      return shareUsers.map((user) => user.email).concat(shareExternalUsers);
    }

    return shareExternalUsers;
  }, [shareUsers, shareExternalUsers]);

  const handleShare = () => {
    if (shareUsers.length === 0 && shareExternalUsers.length === 0) {
      growl.current.show({
        severity: "error",
        summary: "Please specify at least one recipient",
      });
      return;
    }

    if (shareMessageText.length === 0) {
      growl.current.show({
        severity: "error",
        summary: "Message field is empty",
      });
      return;
    }

    if (!!shareUsers || !!shareExternalUsers) {
      const sharePromises = usersEmails.map((email) => {
        const body = {
          toAdderess: email,
          opportunityId: shareId,
          recordType: "F",
          message: `${shareMessageText.replaceAll("\n", "<br>")}`,
        };

        return new Promise((resolve, reject) =>
          axios
            .post(
              getFullUrl("/api/pipeline/share", {
                useDedicatedEnvironment: true,
              }),
              body,
              createRequestWithAuthHeaders(context)
            )
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              reject();
            })
        );
      });

      Promise.all(sharePromises)
        .then(() => {
          if (growl.current) {
            growl.current.show({
              severity: "success",
              summary: "Opportunity shared successfully",
            });
          }
        })
        .catch(() => {
          if (growl.current) {
            growl.current.show({
              severity: "error",
              summary: "Unable to share opportunity",
            });
          }
        });
    }

    sharePopup.current?.hide();
  };
  const [companyUsers, setCompanyUsers] = React.useState<ShareUser[]>([]);

  React.useEffect(() => {
    axios
      .get(
        getFullUrl("/api/organization/user", { useDedicatedEnvironment: true }),
        createAuthenticatedRequest(context)
      )
      .then((response) => {
        const { data } = response;
        const emailId = context.user.parsedIdToken?.email;
        if (emailId) {
          // var id = email[0]+ "@" + email[1]
          const orgUsers = data.filter(
            (r: ShareUser) => r.email?.toLocaleLowerCase() !== emailId
          );
          setCompanyUsers(orgUsers);
        } else {
          setCompanyUsers(data);
        }
      })
      .catch((e) => {
        console.error(e);
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: "Unable to load users",
          });
        }
      });
  }, []);

  const sharePopup = useRef<OverlayPanel>(null);
  const ShareButton = (e: ForecastRecord) => {
    return (
      <div>
        <button
          className="share-btn"
          onClick={() => {
            setShareId(e.rawRecord.forecast.rowId);
            setShareUsers([]);
            setShareExternalUser("");
            setShareExternalUsers([]);
            setShareMessageText("");
            sharePopup.current?.toggle(
              e as unknown as React.SyntheticEvent<Element, Event>
            );
          }}
        >
          <svg
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 4.60063L9.23147 0V2.20024C7.21594 2.38887 0.0446832 3.64493 0 12C0 12 1.73858 6.73656 9.23147 6.54326V9.20148L15 4.60063Z"
              fill="white"
            />
          </svg>
          <span>Share</span>
        </button>
      </div>
    );
  };

  const LinkButton = (e: ForecastRecord) => (
    <div>
      {e.rawRecord.forecast.status !==
      businessForecastRecordType.FinalRecord ? (
        <a
          target="_blank"
          href={`https://www.usaid.gov/node/${e.rawRecord.forecast.id}`}
          rel="noreferrer"
        >
          link
        </a>
      ) : (
        <></>
      )}
    </div>
  );

  const [disableExcel, setDisableExcel] = React.useState(false);

  var getExcelData = async (): Promise<ForecastRecord[]> => {
    var q = queryDto;
    if (q) q.rowsPerPage = 1000;
    var result = Array<ForecastRecord>();
    await axios
      .post(
        getFullUrl("/api/businessforecast/RecentForecasts", {
          useDedicatedEnvironment: true,
        }),
        q,
        createRequestWithAuthHeaders(context)
      )
      .then((response) => {
        const data = response.data.data;
        const records: ForecastRecord[] = (data as ServerRecord[]).map((d) => ({
          id: d.forecast.id,
          country: d.forecast.country,
          createdOnDate: d.forecast.createdOn,
          isMyList: d.userEdits.isMyList,
          title: d.forecast.awardTitle,
          description: d.forecast.awardDescription,
          sector: d.forecast.sector,
          primeOrIncumbent: d.enhancements.primeOrIncumbent,
          quarterlyCallNotes: d.enhancements.quarterlyCallNotes,
          estimatedCostRange: d.forecast.totalEstimatedCost,
          projectType: d.forecast.awardActionType,
          anticipatedReleaseDate: d.forecast.anticipatedSolicitationReleaseDate,
          anticipatedAwardDate: d.forecast.anticipatedAwardDate,
          smallBusinessSetAside: d.forecast.smallBusinessSetAside,
          changedColumns: d.forecast.changedColumns,

          aaPlanId: d.forecast.aaPlanId,
          fiscalYearofAction: d.forecast.fiscalYearofAction,
          aaSpecialist: d.forecast.aaSpecialist,
          awardLength: d.forecast.awardLength,
          eligibilityCriteria: d.forecast.eligibilityCriteria,
          geographicalCodes: d.forecast.geographicalCodes,
          categoryManagementContractVehicle:
            d.forecast.categoryManagementContractVehicle,
          solicitationNumber: d.forecast.solicitationNumber,
          coCreation: d.forecast.coCreation,
          location: d.forecast.location,
          rawRecord: d,
        }));
        result = records;
      })
      .catch((error) => {
        alert("error fetching data");
      });
    return result;
  };

  const tableHeader = (
    <div className="p-grid p-justify-between p-align-center">
      <div
        className="p-sm-12 p-md-6 p-lg-4 p-xl-2 topLeftColumnHeader"
        style={{ textAlign: "left" }}
      >
        <div>Source: USAID Business Forecast</div>
        <div>{lastUpdated}</div>
      </div>
      <div
        className="p-sm-12 p-md-6 p-lg-4 p-xl-2"
        style={{ textAlign: "left", fontWeight: "bold" }}
      >
        {liveTally !== 0 ? (
          <span>
            Number of current opportunities on the Business Forecast:{" "}
            <span style={{ font: "14px Open Sans regular" }}>{liveTally}</span>
          </span>
        ) : (
          ""
        )}
      </div>
      <div className="p-sm-12 p-md-12 p-lg-4 p-xl-4 p-flex-wrap p-justify-end p-grid p-align-center buttonsHeader switch-button-list">
        <div
          className="button-select"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontWeight: "bold",
              color: "rgb(217, 217, 217)",
              marginRight: 5,
            }}
          >
            New only
          </span>
          <Switch
            checked={newOnlyField}
            onColor="#46b1f1"
            offColor="#a4a5a8"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={(value) => setNewOnlyField(value)}
          />
        </div>
        <div
          className="button-select"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontWeight: "bold",
              color: "rgb(217, 217, 217)",
              marginRight: 5,
            }}
          >
            Current only
          </span>
          <Switch
            checked={liveOnlyField}
            onColor="#46b1f1"
            offColor="#a4a5a8"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={(value) => setLiveOnlyField(value)}
          />
        </div>
        <div
          className="button-select"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: 14,
              fontWeight: "bold",
              color: "rgb(217, 217, 217)",
              marginRight: 5,
            }}
          >
            Exclude tracked opportunities
          </span>
          <Switch
            checked={trackedOpportunitiesField}
            onColor="#46b1f1"
            offColor="#a4a5a8"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={(value) => setTrackedOpportunitiesField(value)}
          />
        </div>
      </div>
      <div className="p-sm-12 p-md-12 p-lg-4 p-xl-3 p-justify-end p-align-center buttonsHeader">
        <div className="search-bar">
          <InputText
            type="search"
            style={{ ...styles.keywordSearch }}
            value={keyword}
            onInput={(e: any) =>
              isFree ? showUpgradeAlert() : setKeyword(e.target.value)
            }
            placeholder="Search"
          />
        </div>
        <div className="button-export">
          <Button
            type="button"
            icon="pi pi-file-excel"
            iconPos="left"
            onClick={exportFunc}
            disabled={disableExcel}
            style={{ ...styles.tableHeaderButton, height: "auto" }}
          />
        </div>
        <div className="button-export">
          <Button
            type="button"
            icon={
              isGridFullScreen
                ? "pi pi-window-minimize"
                : "pi pi-window-maximize"
            }
            iconPos="left"
            style={{ ...styles.tableHeaderButton, height: "auto" }}
            onClick={(e) => {
              if (isGridFullScreen) {
                MinimizeGrid(e.target as HTMLElement);
              } else {
                MaximizeGrid(e.target as HTMLElement, () =>
                  setIsGridFullScreen(false)
                );
              }

              setIsGridFullScreen(!isGridFullScreen);
            }}
          />
        </div>
      </div>
    </div>
  );

  const getFormattedCellFromBody = function (
    fieldNames: any,
    getValueFunc: any
  ) {
    return function (row: ForecastRecord) {
      const recordType = row.rawRecord.forecast.status;
      const updatedFields = row.rawRecord.forecast.changedColumns
        ? row.rawRecord.forecast.changedColumns
            .split(",")
            .map((m: any) => m.trim())
        : [];

      let className;

      if (recordType === businessForecastRecordType.NewRecord) {
        className = "new";
      } else if (recordType === businessForecastRecordType.FinalRecord) {
        className = "final";
      } else if (
        recordType !== businessForecastRecordType.ModifiedRecord ||
        !fieldNames.length
      ) {
        className = "";
      } else {
        // Only modified rows here - find if this field is a modified field - or if source field
        if (
          fieldNames.find((x: any) => x === "Source") ||
          updatedFields.find((m: any) => fieldNames.find((x: any) => x === m))
        ) {
          className = "modified";
        } else {
          className = "";
        }
      }

      return (
        <div className={className + "" + "show-popover"}>
          {getValueFunc(row)}
        </div>
      );
    };
  };

  const addRecordToMyList = (record: ForecastRecord) => {
    let request = createRequestWithAuthHeaders(context);

    axios
      .post(
        getFullUrl(
          `/api/businessforecast/mylist/${record.rawRecord.forecast.id}`,
          { useDedicatedEnvironment: true }
        ),
        null,
        request
      )
      .then(() => {
        // re-set my list status for all items with this id
        const newData = [...data];
        newData
          .filter(
            (r: any) => r.rawRecord.forecast.id === record.rawRecord.forecast.id
          )
          .forEach((r: any) => (r.isMyList = true));
        setData(newData);
        growl.current.show({
          severity: "success",
          summary: "Added",
          detail: `Added forecast '${record.title}' to my list`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          })
        )
      );
  };

  const [rerender, setRerender] = React.useState(false);

  React.useEffect(() => {
    //  load pipeline data
    axios
      .get(
        getFullUrl("/api/pipeline", { useDedicatedEnvironment: true }),
        createRequestWithAuthHeaders(context)
      )
      .then(async (res) => {
        const mypipelines = res.data.filter(
          (pipeline) => pipeline.pipelineUserRole === "Admin"
        );
        const records = await Promise.all(
          mypipelines.map(async (x: PipelineDto) => {
            const sa = await axios.get(
              getFullUrl(`/api/pipeline/${x.id}/forecast`, {
                useDedicatedEnvironment: true,
              }),
              createAuthenticatedRequest(context)
            );
            const data = sa.data.data;
            return {
              id: x.id,
              name: x.name,
              businessForcast: data,
            };
          })
        );

        const r: PipelineForecasts[] = (records as PipelineForecasts[]).map(
          (x) => ({
            id: x.id,
            name: x.name,
            businessForcast: x.businessForcast,
          })
        );

        setPipelines(r);
        setPipelineforcasts(r);
      })
      .catch((error) => {});
  }, [rerender]);

  const addRecordToMyPipeline = (
    record: ForecastRecord,
    e: React.SyntheticEvent<Element, Event>
  ) => {
    // set row data
    setRowData(record);

    // find pipelines tracking this opportunity
    const pipelinesTrackingThisOpportunity = pipelineForcasts.filter((a) =>
      a.businessForcast.some((u: ServerRecord) =>
        u.forecast.id.includes(record.id)
      )
    );

    setSelectedPipelines(pipelinesTrackingThisOpportunity);
    setServerRecord(pipelinesTrackingThisOpportunity);

    if (popupMenu && popupMenu.current) {
      popupMenu.current.toggle(e);
    }
  };
  const selectPipeline = (pipelinesTobeAdded: PipelineDto[]) => {
    let row = pipelinesTobeAdded.filter(
      (sa) => !serverRecord.some((r) => sa.id === r.id)
    );
    if (row.length > 0) {
      // shows save button when there's a new record
      setToggle(true);
    }
    setSelectedPipelines(pipelinesTobeAdded);
  };

  const TrackOpportunity = () => {
    const request = createAuthenticatedRequest(context);
    // pipelineId}/forecast/{forecastId}"
    if (!rowData) {
      return;
    }
    const updatedSelectedPipelines = selectedPipelines.filter(
      (sa) => !serverRecord.some((r) => sa.id === r.id)
    );

    updatedSelectedPipelines.map(async (sa) => {
      await axios
        .post(
          getFullUrl(`/api/pipeline/${sa.id}/forecast/${rowData.id}`, {
            useDedicatedEnvironment: true,
          }),
          null,
          request
        )
        .then(() => {
          growl.current.show({
            severity: "success",
            summary: `Opportunity updated, with ${sa.name} pipeline`,
          });
          setToggle(false);
          popupMenu.current?.hide();
          setRerender(!rerender);
        })
        .catch((error) => {});
    });
  };

  const unTrackOpportunity = async (
    pipelineToRemove: PipelineDto,
    pipelines: PipelineDto[]
  ) => {
    const request = createAuthenticatedRequest(context);

    if (!rowData) {
      return;
    }
    const updatedPipelines = pipelines.filter(
      (r) => r.id !== pipelineToRemove.id
    );
    const sendToServer = async () => {
      return axios
        .delete(
          getFullUrl(
            `/api/pipeline/${pipelineToRemove.id}/forecast/${rowData.id}`,
            { useDedicatedEnvironment: true }
          ),
          request
        )
        .then(() => {
          setToggle(false);
          setSelectedPipelines(updatedPipelines);
          growl.current.show({
            severity: "success",
            summary: `Pipeline removed from the opportunity`,
          });
          setRerender(!rerender);
        })
        .catch((message) => {
          console.error(message);
          growl.current.show({
            severity: "error",
            summary: "Error removing pipeline from the opportunity",
          });
        });
    };

    if (!serverRecord.includes(pipelineToRemove)) {
      setSelectedPipelines(updatedPipelines);
      growl.current.show({
        severity: "success",
        summary: `Pipeline removed from the opportunity`,
      });
      if (arrayEquals(updatedPipelines, serverRecord)) setToggle(false);
      return;
    }
    await sendToServer();
  };
  const closeModal = () => {
    setSelectedPipelines([]);
    popupMenu.current?.hide();
  };
  const removeRecordFromMyList = (record: ForecastRecord) => {
    let request = createAuthenticatedRequest(context);

    axios
      .delete(
        getFullUrl(
          `/api/businessforecast/mylist/${record.rawRecord.forecast.id}`,
          { useDedicatedEnvironment: true }
        ),
        request
      )
      .then(() => {
        // re-set my list status for all items with this id
        const newData = [...data];
        newData
          .filter(
            (r: any) => r.rawRecord.forecast.id === record.rawRecord.forecast.id
          )
          .forEach((r: any) => (r.isMyList = false));
        setData(newData);
        growl.current.show({
          severity: "error",
          summary: "Removed",
          detail: `Removed '${record.title}' from my list`,
        });
      })
      .catch((r) => {
        console.error(r);
        growl.current.show({
          severity: "error",
          summary: "Error",
          detail: `Error removing '${record.title}' from my list`,
        });
      });
  };

  const pipelineRowTemplate = (r: any) => {
    const p = pipelineForcasts.filter((a) =>
      a.businessForcast.some((u: ServerRecord) => u.forecast.id.includes(r.id))
    );
    const pipelines: any = [];
    //  extract pipelines'

    for (let i = 0; i < p.length; i++) {
      pipelines.push(p[i].name);
    }

    const title = `Added to ${p.length} Pipelines`;
    return (
      <>
        <div>
          <span className="pipeline-actions">
            <img
              className="pipeline-button"
              src={plusButton}
              alt=""
              onClick={(e: React.SyntheticEvent<Element, Event>) =>
                addRecordToMyPipeline(r, e)
              }
            />
            <p
              className="show-popover"
              data-full-abstract={pipelines}
              data-title={title}
              id="pipeline-popover"
              style={{
                textDecoration: "underline",
                paddingLeft: "4px",
                fontWeight: 400,
              }}
            >
              {p.length == 0 ? `Add to Pipeline` : `${p.length} Pipelines`}
            </p>
          </span>
        </div>
      </>
    );
  };

  const [longHeight, setLongHeight] = useState(false);

  return (
    <>
      <OverlayerWindow>
        <OverlayPanel
          ref={popupMenu}
          id="pipeline-overlay"
          style={{ width: "390px", display: "flex !important" }}
          dismissable
          className="side-panel-forecast"
        >
          <div style={{ width: "335px", height: "auto" }}>
            <div className="side-panel-header-forecast">
              Add opportunity to Pipeline
              <i
                onClick={closeModal}
                className="pi pi-times mr-2"
                style={{ fontSize: 20, cursor: "pointer" }}
              ></i>
            </div>
            <div className="side-panel-content">
              <div className="p-grid">
                <div
                  className="p-col-12"
                  style={{ display: "grid" }}
                  id="long-text"
                >
                  <label
                    className="field-titles-forecast"
                    htmlFor=" Opportunity"
                  >
                    Opportunity Title
                  </label>
                  <InputText
                    style={{
                      border: "none",
                      fontWeight: 700,
                      color: "#000",
                      opacity: 1,
                    }}
                    disabled={true}
                    placeholder="Opportunity"
                    className="field-titles-forecast"
                    defaultValue={rowData ? rowData.title : ""}
                  />
                </div>
                <div
                  className="p-col-7"
                  style={{ marginTop: "-16px" }}
                  id="long-text"
                >
                  <label className="field-titles-forecast" htmlFor="country">
                    Contry/Office
                  </label>
                  <InputText
                    style={{
                      border: "none",
                      fontWeight: 700,
                      width: "22rem",
                      color: "#000",
                      opacity: 1,
                    }}
                    disabled={true}
                    type="text"
                    placeholder="country"
                    className="field-titles-forecast"
                    defaultValue={rowData ? rowData.country : ""}
                  />
                </div>
                <div className="p-col-7" style={{ marginTop: "-16px" }}>
                  <label
                    className="field-titles-forecast"
                    htmlFor="Created date"
                  >
                    Created at
                  </label>
                  <InputText
                    style={{
                      border: "none",
                      fontWeight: 700,
                      color: "#000",
                      opacity: 1,
                    }}
                    disabled={true}
                    type="text"
                    placeholder="Created date"
                    className="field-titles-forecast "
                    // defaultValue={""}
                    defaultValue={getFullDateString(
                      parseISO(rowData?.anticipatedReleaseDate.toString()!)
                    )}
                  />
                </div>
                <div className="p-col-12" style={{ marginTop: "-16px" }}>
                  <label className="field-titles-forecast" htmlFor="email">
                    Pipelines
                  </label>
                  <MultiSelect
                    optionLabel="name"
                    filter={true}
                    style={customStyle.multiSelect}
                    placeholder={
                      selectedPipelines.length + " Pipeline(s) Selected"
                    }
                    fixedPlaceholder
                    value={selectedPipelines}
                    options={pipeline}
                    // itemTemplate={userTemplate}
                    onChange={(e) => selectPipeline(e.value)}
                  />

                  <div style={customStyle.chipsContainer}>
                    {selectedPipelines.map((sa) => (
                      <Chip
                        key={sa.id}
                        label={sa.name}
                        onIconClick={() =>
                          unTrackOpportunity(sa, selectedPipelines)
                        }
                      />
                    ))}
                  </div>
                </div>
                <div
                  className="p-col-12"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginTop: longHeight
                      ? pipeline.length > 6
                        ? 6 * 32
                        : pipeline.length * 32
                      : 0,
                  }}
                >
                  <Button
                    onClick={closeModal}
                    style={{ marginRight: "6px" }}
                    className="modal-button"
                    label="Close"
                  />
                  {toggleAddPipeline && (
                    <Button
                      onClick={TrackOpportunity}
                      className="modal-button"
                      label="Save"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </OverlayPanel>
      </OverlayerWindow>
      <div className="p-grid p-dir-col">
        <div className="p-grid" style={{ alignItems: "center" }}>
          <div className="p-col-6" style={{ ...styles.pageHeader }}>
            <img
              src={styles.aidkonektIcon}
              style={styles.icon}
              alt="Aidkonekt"
              className="formIcon"
            ></img>
            Business Forecast Changes Feed
          </div>

          <div
            className="p-col-10"
            style={{ ...styles.sectionSubheader, paddingBottom: 0 }}
          >
            This feed monitors changes made to the USAID Business Forcast,
            including new opportunities added, changes made to existing
            opportunities, and when posted opportunities are removed. These
            changes are color-coded for simplified reference: all new
            opportunities are highlighted in blue; removed opportunities are
            highlighted in gray; changes are highlighted in orange on the left
            column and only the cells that changed are also highlighted orange
            for quicker reference. See historical changes to any opportunity by
            added to "Pipelines" list.
          </div>

          <div className="p-col-10" style={{ paddingTop: 0, marginTop: -12 }}>
            <div className="p-grid p-col">
              <div className="p-col-4">
                <h5
                  className="new-record"
                  style={{ ...styles.centeredSectionSubheader }}
                >
                  New Opportunity
                </h5>
              </div>
              <div className="p-col-4">
                <h5
                  className="modified-record-bubble"
                  style={{ ...styles.centeredSectionSubheader }}
                >
                  Changes to Opportunity
                </h5>
              </div>
              <div className="p-col-4">
                <h5
                  className="final-record"
                  style={{ ...styles.centeredSectionSubheader }}
                >
                  Opportunity Removed
                </h5>
              </div>
            </div>
          </div>
        </div>

        <Popover
          dialogHeader={dialogHeader}
          isVisible={dialogVisible === dialogTableName}
          dialogText={dialogText}
          setMultiDialogVisible={setDialogVisible}
          multi={true}
          colunmType={colunmType} // differentiate pipeline columns with the rest of the columns
        />
        <OverlayerWindow>
          <OverlayPanel
            ref={sharePopup}
            id="pipeline-overlay"
            style={{
              width: "500px",
              height: "100%",
              display: "flex !important",
            }}
            className="contract-panel share"
          >
            <div
              style={{ width: "500px", height: "100%", overflowY: "auto" }}
              className="share-overlay-height"
            >
              <div className="share-modal-header">
                Share opportunity
                <i
                  onClick={() => sharePopup.current?.hide()}
                  className="pi pi-times mr-2"
                  style={{ fontSize: 20, cursor: "pointer" }}
                ></i>
              </div>
              <div className="share-modal-body">
                <div>
                  <p>Recipients</p>
                  <p className="email-option">External users</p>
                  <div className="external-users-input">
                    <InputText
                      value={shareExternalUser}
                      onChange={(e) => {
                        setShareExternalUser(
                          (e.target as HTMLInputElement).value
                        );
                      }}
                    />
                    <button
                      type="button"
                      className="add-btn aid-blu-btn"
                      onClick={handleAddExternalUser}
                    >
                      +
                    </button>
                  </div>
                  <div
                    className="share-users-list"
                    style={{
                      marginBottom: !!shareExternalUsers.length ? 10 : 0,
                    }}
                  >
                    {!!shareExternalUsers.length &&
                      shareExternalUsers.map((user, i) => (
                        <Chip
                          key={i}
                          className="share-chip"
                          label={user}
                          onIconClick={() => removeExternalShareUser(user)}
                        />
                      ))}
                  </div>
                  <p className="email-option">Company users</p>
                  <MultiSelect
                    optionLabel="email"
                    filter={true}
                    className="share-multiselect"
                    style={customStyle.multiSelect}
                    placeholder={shareUsers.length + " User(s) selected"}
                    fixedPlaceholder
                    value={shareUsers}
                    itemTemplate={(item: ShareUser) => (
                      <div className="share-multiselect-item">
                        {(item.firstName || item.lastName) && (
                          <p>{item.firstName + " " + item.lastName}</p>
                        )}
                        <p>{item.email}</p>
                      </div>
                    )}
                    options={companyUsers}
                    onChange={(e) => setShareUsers(e.value)}
                  />
                  <div className="share-users-list">
                    {!!shareUsers.length &&
                      shareUsers.map((user, i) => (
                        <Chip
                          key={i}
                          className="share-chip"
                          label={
                            user.firstName || user.lastName
                              ? Capitalize(user.firstName ?? "") +
                                " " +
                                Capitalize(user.lastName ?? "")
                              : user.email
                          }
                          onIconClick={() => removeShareUser(user.publicUserId)}
                        />
                      ))}
                  </div>
                </div>
                <div>
                  <p style={{ marginTop: 10 }}>Message</p>
                  <textarea
                    className="share-message-text"
                    value={shareMessageText}
                    onChange={(e) => setShareMessageText(e.currentTarget.value)}
                  />
                </div>
                <div className="share-footer">
                  <button onClick={() => sharePopup.current?.hide()}>
                    Cancel
                  </button>
                  <button onClick={handleShare}>Share</button>
                </div>
              </div>
            </div>
          </OverlayPanel>
        </OverlayerWindow>
        <DataTable
          value={data}
          globalFilter={keyword}
          style={{ marginTop: -9, width: `${gridWidth - 20}px` }}
          paginator={true}
          rows={rowsPerPage}
          totalRecords={totalRecords}
          lazy={true}
          first={first}
          onPage={queryData}
          loading={isLoading}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={sort}
          scrollable={true}
          scrollHeight="400px"
          ref={dt}
          header={tableHeader}
          rowClassName={(r: ForecastRecord) => {
            const baseclassName =
              r.rawRecord.forecast.status ===
              businessForecastRecordType.NewRecord
                ? "new-record"
                : r.rawRecord.forecast.status ===
                  businessForecastRecordType.ModifiedRecord
                ? "modified-record"
                : r.rawRecord.forecast.status ===
                  businessForecastRecordType.FinalRecord
                ? "final-record"
                : "new-record";
            const classNames = [baseclassName];

            if (baseclassName === "modified-record") {
              // add class for each modified column
              classNames.splice(
                1,
                0,
                ...r.rawRecord.forecast.changedColumns
                  .split(",")
                  .map((m) => "changed-column-" + m.trim().toLowerCase())
              );
            }

            return classNames.reduce(
              (prev, curr) => ({ ...prev, [curr]: true }),
              {}
            );
          }}
          paginatorTemplate={paginatorTemplate}
          currentPageReportTemplate={tableFooter}
          rowsPerPageOptions={rowsPerPageOptionsStandard}
        >
          <Column
            headerStyle={styles.headerStyle(80)}
            style={styles.columnStyle(80)}
            bodyClassName="share"
            header="usaid.gov link"
            field="link"
            body={LinkButton}
          />
          {canShare && (
            <Column
              headerStyle={styles.headerStyle(100)}
              style={styles.columnStyle(100)}
              bodyClassName="share"
              header={shareColumnHeader}
              field="share"
              body={ShareButton}
            />
          )}
          <Column
            headerStyle={styles.headerStyle(70)}
            style={styles.columnStyle(30)}
            bodyClassName="source"
            header={firstColumnHeader}
            field="source"
            body={getFormattedCellFromBody(["Source"], () => "")}
            exportable={true}
            sortable
          />
          <Column
            field="country"
            header="Country/Office"
            sortable={canSort}
            headerStyle={styles.headerStyle(150)}
            style={{ ...styles.columnStyle(150) }}
            bodyClassName="country"
            body={getFormattedCellFromBody(
              ["Location", "OperatingUnit"],
              (x: any) => x.country
            )}
            exportable={true}
          />
          <Column
            field="createdOnDate"
            header="Date Record Created/Changed"
            sortable={canSort}
            headerStyle={styles.headerStyle(150)}
            style={{ ...styles.columnStyle(150) }}
            body={getFormattedCellFromBody([], (r: any) =>
              getDateString(parseISO(r.createdOnDate + "Z"))
            )}
            exportable={true}
          />
          {isFree ? null : (
            <Column
              field="isMyList"
              header="Pipelines"
              headerStyle={styles.headerStyle(150)}
              style={styles.columnStyle(150)}
              body={getFormattedCellFromBody([], pipelineRowTemplate)}
              className="pipeline-rows"
            />
          )}

          <Column
            field="title"
            header="Name"
            sortable={canSort}
            headerStyle={styles.headerStyle(450)}
            style={{ ...styles.columnStyle(450) }}
            body={getFormattedCellFromBody(["AwardTitle"], titleColumnBody)}
            bodyClassName="awardtitle show-popover"
            exportable={true}
          />
          <Column
            field="description"
            header="Description"
            sortable={canSort}
            body={getFormattedCellFromBody(
              ["AwardDescription"],
              descriptionColumnBody
            )}
            headerStyle={styles.headerStyle(600)}
            style={{ ...styles.columnStyle(600) }}
            bodyClassName="awarddescription show-popover"
            exportable={true}
          />
          <Column
            field="primeOrIncumbent"
            header={primeOrIncumbentColumnHeader}
            sortable={canSort}
            body={getFormattedCellFromBody([], primeOrIncumbentColumnBody)}
            headerStyle={{
              ...styles.headerStyle(650),
              ...styles.primeColumnStyle,
            }}
            style={{ ...styles.columnStyle(650), position: "relative" }}
            bodyClassName="show-popover"
            exportable={true}
          />
          <Column
            field="quarterlyCallNotes"
            header={quarterlyCallNotesColumnHeader}
            sortable={canSort}
            body={getFormattedCellFromBody([], quarterlyCallNotesColumnBody)}
            headerStyle={{
              ...styles.headerStyle(800),
              ...styles.primeColumnStyle,
            }}
            style={{ ...styles.columnStyle(800), position: "relative" }}
            bodyClassName="show-popover"
            exportable={true}
          />
          <Column
            field="sector"
            header="Sector"
            sortable={canSort}
            body={getFormattedCellFromBody(["Sector"], (x: any) =>
              removeHtmlTags(x.sector)
            )}
            headerStyle={styles.headerStyle(250)}
            style={{ ...styles.columnStyle(250) }}
            bodyClassName="sector"
            exportable={true}
          />
          {/**AAPlanId */}
          <Column
            field="aaPlanId"
            header="ID"
            sortable={canSort}
            body={getFormattedCellFromBody(
              ["AAPlanId"],
              (x: ForecastRecord) => x.aaPlanId
            )}
            headerStyle={styles.headerStyle(130)}
            style={{ ...styles.columnStyle(130) }}
            bodyClassName="aaplanid"
            exportable={true}
          />

          {/**CategoryManagementContractVehicle */}
          <Column
            field="categoryManagementContractVehicle"
            header={categoryManagementColumnHeader}
            sortable={canSort}
            body={getFormattedCellFromBody(
              ["CategoryManagementContractVehicle"],
              (x: ForecastRecord) => x.categoryManagementContractVehicle
            )}
            headerStyle={styles.headerStyle(250)}
            style={{ ...styles.columnStyle(250) }}
            bodyClassName="categorymanagementcontractvehicle"
            exportable={true}
          />

          <Column
            field="estimatedCostRange"
            header="Cost"
            sortable={canSort}
            body={getFormattedCellFromBody(
              ["TotalEstimatedCost"],
              (x: any) => x.estimatedCostRange
            )}
            headerStyle={styles.headerStyle(100)}
            style={{ ...styles.columnStyle(100) }}
            bodyClassName="totalestimatedcost"
            exportable={true}
          />
          <Column
            field="projectType"
            header="Award/Action Type"
            sortable={canSort}
            body={getFormattedCellFromBody(
              ["AwardActionType"],
              (x: any) => x.projectType
            )}
            headerStyle={styles.headerStyle(200)}
            style={{ ...styles.columnStyle(200) }}
            bodyClassName="awardactiontype"
            exportable={true}
          />

          {/**Geographical Codes */}
          <Column
            field="geographicalCodes"
            header={geographicalCodesHeader()}
            sortable={canSort}
            body={getFormattedCellFromBody(
              ["GeographicalCodes"],
              (x: ForecastRecord) => (
                <p
                  dangerouslySetInnerHTML={{ __html: x.geographicalCodes }}
                ></p>
              )
            )}
            headerStyle={styles.headerStyle(300)}
            style={{ ...styles.columnStyle(300) }}
            bodyClassName="geographicalcodes"
            exportable={true}
          />

          {/**Co-creation */}
          <Column
            field="coCreation"
            header="Co-creation"
            sortable={canSort}
            body={getFormattedCellFromBody(
              ["CoCreation"],
              (x: ForecastRecord) => (
                <p dangerouslySetInnerHTML={{ __html: x.coCreation }}></p>
              )
            )}
            headerStyle={styles.headerStyle(250)}
            style={{ ...styles.columnStyle(250) }}
            bodyClassName="cocreationparsed"
            exportable={true}
          />

          <Column
            field="anticipatedReleaseDate"
            header="Anticipated Release Date"
            sortable={canSort}
            body={getFormattedCellFromBody(
              ["AnticipatedSolicitationReleaseDate"],
              (r: any) => getFullDateString(parseISO(r.anticipatedReleaseDate))
            )}
            headerStyle={styles.headerStyle(150)}
            style={{ ...styles.columnStyle(150) }}
            bodyClassName="anticipatedsolicitationreleasedate"
            exportable={true}
          />
          <Column
            field="anticipatedAwardDate"
            header="Anticipated Award Date"
            sortable={canSort}
            body={getFormattedCellFromBody(["AnticipatedAwardDate"], (r: any) =>
              getFullDateString(parseISO(r.anticipatedAwardDate))
            )}
            headerStyle={styles.headerStyle(150)}
            style={{ ...styles.columnStyle(150) }}
            bodyClassName="anticipatedawarddate"
            exportable={true}
          />

          {/**Award Length */}
          <Column
            field="awardLength"
            header="Award Length"
            sortable={canSort}
            body={getFormattedCellFromBody(
              ["AwardLength"],
              (x: ForecastRecord) => x.awardLength
            )}
            headerStyle={styles.headerStyle(160)}
            style={{ ...styles.columnStyle(160) }}
            bodyClassName="awardlength"
            exportable={true}
          />

          <Column
            field="smallBusinessSetAside"
            header="Small Business Set-Aside"
            sortable={canSort}
            body={getFormattedCellFromBody(
              ["SmallBusinessSetAside"],
              (x: any) => x.smallBusinessSetAside
            )}
            headerStyle={styles.headerStyle(150)}
            style={{ ...styles.columnStyle(150) }}
            bodyClassName="smallbusinesssetaside"
            exportable={true}
          />

          {/**Eligibility Criteria */}
          <Column
            field="eligibilityCriteria"
            header="Eligibility Criteria"
            sortable={canSort}
            body={getFormattedCellFromBody(
              ["EligibilityCriteria"],
              (x: ForecastRecord) => x.eligibilityCriteria
            )}
            headerStyle={styles.headerStyle(250)}
            style={{ ...styles.columnStyle(250) }}
            bodyClassName="eligibilitycriteria"
            exportable={true}
          />

          {/**Solicitation Number*/}
          <Column
            field="solicitationNumber"
            header="Solicitation Number"
            sortable={canSort}
            body={getFormattedCellFromBody(
              ["SolicitationNumber"],
              (x: ForecastRecord) => x.solicitationNumber
            )}
            headerStyle={styles.headerStyle(250)}
            style={{ ...styles.columnStyle(250) }}
            bodyClassName="solicitationnumber"
            exportable={true}
          />

          {/**A&A Specialist*/}
          <Column
            field="aaSpecialist"
            header="A&A Specialist"
            sortable={canSort}
            body={getFormattedCellFromBody(
              ["AASpecialist"],
              (x: ForecastRecord) => x.aaSpecialist
            )}
            headerStyle={styles.headerStyle(250)}
            style={{ ...styles.columnStyle(250) }}
            bodyClassName="aaspecialist"
            exportable={true}
          />

          {/**Fiscal Year of Action*/}
          <Column
            field="fiscalYearofAction"
            header="Fiscal Year of Action"
            sortable={canSort}
            body={getFormattedCellFromBody(
              ["FiscalYearofAction"],
              (x: ForecastRecord) => x.fiscalYearofAction
            )}
            headerStyle={styles.headerStyle(250)}
            style={{ ...styles.columnStyle(250) }}
            bodyClassName="fiscalyearofaction"
            exportable={true}
          />

          {/**Location*/}
          <Column
            field="location"
            header="Location"
            sortable={canSort}
            body={getFormattedCellFromBody(
              ["Location"],
              (x: ForecastRecord) => x.location
            )}
            headerStyle={styles.headerStyle(250)}
            style={{ ...styles.columnStyle(250) }}
            bodyClassName="location"
            exportable={true}
          />

          {canEditPrimes && (
            <Column
              header="Admin Actions"
              body={getFormattedCellFromBody([], (r: ForecastRecord) => (
                <Button
                  className="table-button"
                  onClick={async () =>
                    await openUpdatePopup(
                      r.rawRecord.forecast.id,
                      r.title,
                      r.primeOrIncumbent,
                      r.quarterlyCallNotes
                    )
                  }
                  label="Edit"
                />
              ))}
              headerStyle={styles.headerStyle(75)}
              style={{ ...styles.columnStyle(75) }}
            />
          )}
        </DataTable>
      </div>
    </>
  );
}

export const geographicalCodesHeader = () => {
  const theme = React.useContext(ThemeContext);

  return (
    <>
      <span style={{ font: theme.v2Fonts.context, verticalAlign: "middle" }}>
        <IconTooltip
          tooltipText={
            "What do these numbers mean? \n" +
            "USAID uses the Principal Geographic Code to share who is eligible to apply for funding. Definitions are outlined in ADS 310 \n" +
            "•937: The United States, the recipient country, and developing countries other than advanced developing countries \n" +
            "•110: The United States, the independent states of the former Soviet Union, or a developing country, but excluding any country that is a prohibited source \n" +
            "•935: Any area or country including the recipient country"
          }
        />
      </span>
      &nbsp;
      <span>Geographical Codes</span>
    </>
  );
};

export default withRouter(ForecastScreen);
