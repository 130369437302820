import axios from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../configs/axios-export.custom";
import { UserContextType } from "../services/UserContext";
import { RequestStatus } from "../utils/Helpers/fetchStatus";
import { ShareUser } from "../screens/Forecast/Forecast";
import { buildQuery } from "../utils/Helpers/queryBuilder";
import { getDropdownOptionsForUSASpending } from "./usa-spending";

interface ForeignAssistanceState {
  items: Array<any>;
  fetchStatus: string;
  total: number;
  lastUpdatedUtc: string | null;
  dropdownOptions: {
    country: Array<{ value: string; label: string }>;
    technicalSector: Array<{ value: string; label: string }>;
    implementor: Array<{ value: string; label: string }>;
    fundingAgency: Array<{ value: string; label: string }>;
    fundingAccount: Array<{ value: string; label: string }>;
    fetchStatus: string;
  };
  addToMyList: {
    fetchStatus: string;
  };
  removeFromMyList: {
    fetchStatus: string;
  };
}

export interface ForeignAssistanceFilters {
  Sort?: boolean;
  SortOrder?: number;
  SortField?: string;
  PageIndex: number;
  PageSize: number;
  ManagingAgencyNames?: Array<string>;
  ActivityId?: number | null;
  ProjectNumber?: string;
  Keyword?: string;
  Email?: string;
  Name?: string;
  IsMyList?: boolean;
  ClosingStartDate?: string;
  ClosingEndDate?: string;
  FundingAgency?: Array<string>;
  Implementer?: Array<string>;
  Country?: Array<string>;
  Sector?: Array<string>;
  FundingAccount?: Array<string>;
  DateStartFrom?: string;
  DateStartTo?: string;
  DateCloseFrom?: string;
  DateCloseTo?: string;
  MinimumAmount?: number;
}

const initialState: ForeignAssistanceState = Object.freeze({
  items: [],
  fetchStatus: RequestStatus.statuses.NULL,
  total: 0,
  lastUpdatedUtc: null,
  dropdownOptions: {
    country: [],
    technicalSector: [],
    implementor: [],
    fundingAgency: [],
    fundingAccount: [],
    fetchStatus: RequestStatus.statuses.NULL,
  },
  addToMyList: {
    fetchStatus: RequestStatus.statuses.NULL,
  },
  removeFromMyList: {
    fetchStatus: RequestStatus.statuses.NULL,
  },
});

export const getForeignAssistance: any = createAsyncThunk(
  "foreignAssistance/getForeignAssistance",
  async (
    data: {
      context: UserContextType;
      params: ForeignAssistanceFilters;
    },
    thunkAPI,
  ) => {
    const { context, params } = data;
    const response = await axios.get(
      getFullUrl(`/api/ForeignAssistance${buildQuery({ ...params }, "|")}`, {
        useDedicatedEnvironment: true,
      }),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

export const getDropdownOptionsForForeignAssistance: any = createAsyncThunk(
  "foreignAssistance/getDropdownOptionsForForeignAssistance",
  async (context: UserContextType, thunkAPI) => {
    const responses = await Promise.all([
      axios.get(
        getFullUrl(`/api/ForeignAssistance/dropdownoptions/country`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
      axios.get(
        getFullUrl(`/api/ForeignAssistance/dropdownoptions/technicalSector`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
      axios.get(
        getFullUrl(`/api/ForeignAssistance/dropdownoptions/implementor`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
      axios.get(
        getFullUrl(`/api/ForeignAssistance/dropdownoptions/fundingAgency`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
      axios.get(
        getFullUrl(`/api/ForeignAssistance/dropdownoptions/fundingAccount`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
    ]);

    return responses.map((response) => response.data);
  },
);

export const addToMyList: any = createAsyncThunk(
  "foreignAssistance/addToMyList",
  async (
    data: {
      context: UserContextType;
      uniqueId: number;
    },
    thunkAPI,
  ) => {
    const result = await axios.post(
      getFullUrl("/api/foreignAssistance/mylist", {
        useDedicatedEnvironment: true,
      }),
      { uniqueId: data.uniqueId },
      createRequestWithAuthHeaders(data.context),
    );

    return result.data;
  },
);

export const removeFromMyList: any = createAsyncThunk(
  "foreignAssistance/removeFromMyList",
  async (
    data: {
      context: UserContextType;
      uniqueId: number;
    },
    thunkAPI,
  ) => {
    const result = await axios.delete(
      getFullUrl("/api/foreignAssistance/mylist", {
        useDedicatedEnvironment: true,
      }),
      {
        ...createRequestWithAuthHeaders(data.context),
        data: { uniqueId: data.uniqueId },
      },
    );

    return result.data;
  },
);

const slice = createSlice({
  name: "foreignAssistance",
  initialState,
  reducers: {
    resetForeignAssistanceItems(state: ForeignAssistanceState) {
      state.items = initialState.items;
      state.total = initialState.total;
      state.fetchStatus = initialState.fetchStatus;
      state.lastUpdatedUtc = initialState.lastUpdatedUtc;
    },
    resetChangeListStatus(state: ForeignAssistanceState) {
      state.addToMyList = initialState.addToMyList;
      state.removeFromMyList = initialState.removeFromMyList;
    },
  },
  extraReducers: {
    [getForeignAssistance.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getForeignAssistance.fulfilled]: (state, action: PayloadAction<any>) => {
      const { attributes, data, lastUpdatedUtc, totalItems } = action.payload;
      state.items = data;
      state.total = totalItems;
      state.lastUpdatedUtc = lastUpdatedUtc;
      state.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getForeignAssistance.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [getDropdownOptionsForForeignAssistance.pending]: (state, action) => {
      state.dropdownOptions.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getDropdownOptionsForForeignAssistance.fulfilled]: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions = {
        country: action.payload[0]?.map((v) => ({ value: v, label: v })),
        technicalSector: action.payload[1]?.map((v) => ({
          value: v,
          label: v,
        })),
        implementor: action.payload[2]?.map((v) => ({
          value: v,
          label: v,
        })),
        fundingAgency: action.payload[3]?.map((v) => ({
          value: v,
          label: v,
        })),
        fundingAccount: action.payload[4]?.map((v) => ({
          value: v,
          label: v,
        })),
        fetchStatus: RequestStatus.statuses.DONE,
      };
    },
    [getDropdownOptionsForForeignAssistance.rejected]: (state, action) => {
      state.dropdownOptions.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [addToMyList.pending]: (state, action) => {
      state.addToMyList.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [addToMyList.fulfilled]: (state, action: PayloadAction<any>) => {
      state.addToMyList.fetchStatus = RequestStatus.statuses.DONE;
    },
    [addToMyList.rejected]: (state, action) => {
      state.addToMyList.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [removeFromMyList.pending]: (state, action) => {
      state.removeFromMyList.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [removeFromMyList.fulfilled]: (state, action: PayloadAction<any>) => {
      state.removeFromMyList.fetchStatus = RequestStatus.statuses.DONE;
    },
    [removeFromMyList.rejected]: (state, action) => {
      state.removeFromMyList.fetchStatus = RequestStatus.statuses.ERROR;
    },
  },
});

export const { reducer } = slice;

export const { resetForeignAssistanceItems, resetChangeListStatus } =
  slice.actions;
export default slice;
