import { SummaryGrant } from "../../slices/grants";
import { TableProperties } from "exceljs";
import { buildExcelTable, genEmptyRow } from "../../services/exporter";
import { removeHtmlTags } from "../../services/dataParser";
import { DateFormat, formatDate } from "../../utils/conversion/date-converters";
import { ForecastRecord } from "../../utils/types/Forecast";
import axios from "axios";
import {
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import { buildQuery } from "../../utils/Helpers/queryBuilder";
import { ECDRecord } from "../../slices/ecd";
import { format } from "date-fns";
import { useMemo } from "react";

export function parseRiskManagementConsentValue(
  value: string | null | undefined,
  options?: {
    parseForExport?: boolean;
  },
) {
  const { parseForExport } = options || {};
  if (parseForExport && value && !value.startsWith("RiskLOWMODERATEHIGH"))
    return value;
  if (!value || !value?.startsWith("RiskLOWMODERATEHIGH")) return null;
  const identified = value
    .split("Identified")[1]
    .split("Addressed")[0]
    .split("\n")
    .slice(0, 3);
  const addressed = value
    .split("Addressed")[1]
    .split("Note")[0]
    .split("\n")
    .slice(0, 3);
  const note = value.split("\r\n")[1];

  return parseForExport
    ? `Risks Identified: \n - Low ${identified[0] ?? "-"}\n - Moderate ${
        identified[1] ?? "-"
      }\n - High ${identified[2] ?? "-"}\nRisks Addressed: \n - Low ${
        addressed[0] ?? "-"
      }\n - Moderate ${addressed[1] ?? "-"}\n - High ${addressed[2] ?? "-"}${
        note ? `\nNote: ${note.trim()}` : ""
      }`
    : {
        identified,
        addressed,
        note: note ? note.trim() : null,
      };
}

export const getExportableDataTableECD = (
  data: ECDRecord[],
): TableProperties => {
  const columns = [
    { name: "ID" },
    { name: "External ID" },
    { name: "Country/Operating Unit" },
    { name: "Bureau" },
    { name: "FY Approved" },
    { name: "Sector Types" },
    { name: "Document Title" },
    { name: "Document Type" },
    { name: "File Number" },
    { name: "Activity Date Range" },
    { name: "Expiration Date" },
    { name: "ETD Signature Date" },
    { name: "Threshold Determination" },
    { name: "Climate Risk Management Assessment" },
    { name: "Specific Conditions" },
  ];

  let rows: any[][] = [];

  if (data.length === 0) {
    rows.push(genEmptyRow(columns.length));
  } else {
    for (let i = 0; i < data.length; i++) {
      const record = data[i];
      let row = [
        record.id,
        record.extECDRecord_Id,
        (record.countries ?? []).join(", "),
        record.originatingBureauSubregionOffice,
        record.projectDateEnvironmentalDeterminationSignatureFyParsed,
        (record.sectorTypes ?? []).join(", "),
        record.documentTitle,
        record.documentType,
        record.fileNumber,
        !record?.projectDateActivityDateRangeStartParsed &&
        !record?.projectDateActivityDateRangeEndParsed
          ? ""
          : `${
              record?.projectDateActivityDateRangeStartParsed
                ? format(
                    new Date(record.projectDateActivityDateRangeStartParsed),
                    "dd MMMM yyyy",
                  )
                : "N/A"
            } - ${
              record?.projectDateActivityDateRangeEndParsed
                ? format(
                    new Date(record.projectDateActivityDateRangeEndParsed),
                    "dd MMMM yyyy",
                  )
                : "N/A"
            }`,
        !record.projectDateExpirationDateParsed
          ? ""
          : format(
              new Date(record.projectDateExpirationDateParsed),
              "dd MMMM yyyy",
            ),
        !record.projectDateEnvironmentalDeterminationSignatureDateParsed
          ? ""
          : format(
              new Date(
                record.projectDateEnvironmentalDeterminationSignatureDateParsed,
              ),
              "dd MMMM yyyy",
            ),
        (record.thresholdDetermination ?? []).join(", "),
        parseRiskManagementConsentValue(
          record.climateRiskManagementAssessment,
          { parseForExport: true },
        ),
        (record.specificConditions ?? []).join(", "),
      ];

      rows.push(row);
    }
  }

  return buildExcelTable("GrantsExport", columns, rows);
};

export const getExcelDataECD = async (
  context,
  queryDto,
  totalItems: number = 1000,
): Promise<ECDRecord[]> => {
  const q = queryDto;
  if (q) {
    q.pageSize = totalItems;
    q.pageIndex = 0;
  }
  let result = Array<any>();
  await axios
    .get(
      getFullUrl(`/api/ecd${buildQuery(q)}`, {
        useDedicatedEnvironment: true,
      }),
      createRequestWithAuthHeaders(context),
    )
    .then((response) => {
      result = response.data.data;
    })
    .catch((error) => {
      alert("error fetching data");
    });
  return result;
};
