import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import OpportunityDetails from "../../components/OpportunityDetails/OpportunityDetails";
import { RootState, useDispatch, useSelector } from "../../store";
import { useHistory } from "react-router-dom";
import UserContext from "../../services/UserContext";
import { getContractDetails } from "../../slices/contracts";
import { getGrantDetails } from "../../slices/grants";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import {
  getPipelinesForRecord,
  resetAddOrRemoveFromPipelinesStatus,
  resetPipelinesForRecord,
  setPipelinesForRecordFor,
} from "../../slices/pipelines";
import toast from "react-hot-toast";

const GrantsProfilePage: FC = (props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const context = useContext(UserContext);

  const {
    details: {
      files,
      matchingFiles,
      fetchStatus,
      records,
      grantDetails,
      pipelineDetails,
    },
  } = useSelector((state: RootState) => state.grants);
  const {
    fetchStatus: pipelinesForRecordFetchStatus,
    itemsFor: pipelinesForRecordFor,
  } = useSelector((state: RootState) => state.pipelines.pipelinesForRecord);
  const { postFetchStatus } = useSelector(
    (state: RootState) => state.pipelines.addOrRemoveFromPipelines,
  );
  const { postFetchStatus: shareOpportunitiesFetchStatus } = useSelector(
    (state: RootState) => state.grants.shareOpportunities,
  );

  const [addToPipelinesOpen, setAddToPipelinesOpen] = useState<boolean>(false);

  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);

  const id = useMemo(() => {
    const path = history.location.pathname.split("/");
    return path[path.length - 1];
  }, [history]);

  useEffect(() => {
    if (!id) return;
    dispatch(
      getGrantDetails({
        context,
        params: {
          id,
          fileKeyword: "",
          fileName: "",
        },
      }),
    );
  }, [id]);

  const [data, changelog] = useMemo(() => {
    const changelog = ([...records] as any)?.sort(
      (r1, r2) =>
        new Date(r2.createTimestamp).getTime() -
        new Date(r1.createTimestamp).getTime(),
    );
    const data = changelog.shift();
    return [data, changelog];
  }, [records]);

  const handleOpenAddToPipelines = (): void => {
    if (!id) return;
    if (addToPipelinesOpen) {
      handleCloseAddToPipelines();
      setTimeout(() => {
        dispatch(setPipelinesForRecordFor(+id));
        dispatch(
          getPipelinesForRecord({
            context,
            table: "grants",
            recordId: id,
          }),
        );
      }, 100);
    } else {
      dispatch(setPipelinesForRecordFor(+id));
      dispatch(
        getPipelinesForRecord({
          context,
          table: "grants",
          recordId: id,
        }),
      );
    }
  };

  const handleCloseAddToPipelines = (): void => {
    setAddToPipelinesOpen(false);
    dispatch(setPipelinesForRecordFor(null));
    dispatch(resetPipelinesForRecord());
  };

  useEffect(() => {
    if (
      RequestStatus.isDone(pipelinesForRecordFetchStatus) &&
      pipelinesForRecordFor !== null &&
      !addToPipelinesOpen
    )
      setAddToPipelinesOpen(true);
    if (RequestStatus.isError(pipelinesForRecordFetchStatus)) {
      toast.error(
        "Pipeline data for this row couldn't be loaded. Try again later.",
      );
    }
  }, [pipelinesForRecordFetchStatus, pipelinesForRecordFor]);

  useEffect(() => {
    if (RequestStatus.isDone(postFetchStatus)) {
      dispatch(resetAddOrRemoveFromPipelinesStatus());
      handleCloseAddToPipelines();
      id &&
        dispatch(
          getGrantDetails({
            context,
            params: {
              id,
              fileKeyword: "",
              fileName: "",
            },
          }),
        );
    }
  }, [postFetchStatus]);

  useEffect(() => {
    if (RequestStatus.isDone(fetchStatus) && isFirstLoading)
      setIsFirstLoading(false);
  }, [fetchStatus, isFirstLoading]);

  return (
    <>
      <OpportunityDetails
        source={"grants"}
        data={{
          ...data,
          ...grantDetails,
          description: data?.description ?? "",
          numberIncludedPipelines: pipelineDetails.length,
        }}
        loading={RequestStatus.isFetching(fetchStatus) && isFirstLoading}
        files={files}
        addToPipelinesOpen={addToPipelinesOpen}
        setAddToPipelinesOpen={setAddToPipelinesOpen}
        handleCloseAddToPipelines={handleCloseAddToPipelines}
        handleOpenAddToPipelines={handleOpenAddToPipelines}
        pipelinesFetchStatus={pipelinesForRecordFetchStatus}
        shareOpportunitiesFetchStatus={shareOpportunitiesFetchStatus}
        onShareSubmit={undefined}
        handleResetShareOpportunitiesStatus={undefined}
      />
    </>
  );
};

export default GrantsProfilePage;
