import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  ElementType,
} from "react";
import {
  alpha,
  Box,
  ButtonGroup,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  LinearProgress,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { rowsPerPageOptionsStandard } from "../../utils/constants/gridsOptions";
import {
  TableComponents,
  TableVirtuoso,
  TableVirtuosoHandle,
  Virtuoso,
} from "react-virtuoso";
import SortButton from "../Widgets/SortButton";
import Button from "@mui/material/Button";
import { MaximizeGrid, MinimizeGrid } from "../DataTable/ActionButtons";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { FullscreenIcon } from "../Icons/FullscreenIcon";
import { ManageColumnsIcon } from "../Icons/ManageColumnsIcon";
import SimpleModal from "../Modals/SimpleModal";
import { DownloadDocumentIcon } from "../Icons/DownloadDocumentIcon";
import ClearIcon from "@mui/icons-material/Clear";
import { compact, uniq } from "lodash";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TableChartIcon from "@mui/icons-material/TableChart";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import {
  getSearchParamValue,
  setUrlParams,
} from "../../utils/Helpers/extractDataFromSearchParams";
import { useHistory } from "react-router-dom";
import TooltipWithScrollHide from "../Widgets/TooltipWithScrollHide";
import { constants } from "../../utils/constants/general";

interface BaseColumnConfigProps {
  propertyName: string;
  label: string;
  isSticky?: boolean;
  headerAlign?: "center" | "inherit" | "justify" | "left" | "right";
  cellAlign?: "center" | "inherit" | "justify" | "left" | "right";
  onRowSelect?: () => any;
  textSx?: SxProps;
  maxNumberOfLines?: number;
  maxWidth?: number;
  sortable?: boolean;
  getSortableColumnPropertyName?: (_propertyName: string) => string;
  cellFormatter?: (value: string | number) => string;
  headerFormatter?: (value: string) => string;
  disableModalWithDetails?: boolean;
  hiddenBasedOnRole?: boolean;
  headerTooltip?: string;
}

export interface StickyColumnConfigProps extends BaseColumnConfigProps {
  width: number;
}

export interface DataColumnConfigProps extends BaseColumnConfigProps {
  order: number;
  isVisible: boolean;
  width?: number;
  disableCellHighlight?: boolean;
}

export interface ColumnsSettingsData {
  propertyName: string;
  order: number;
  isVisible: boolean;
}

interface CustomTableProps {
  config: {
    data: Array<any>;
    stickyColumns?: Array<StickyColumnConfigProps>;
    columns: Array<DataColumnConfigProps>;
    selectedRowsBulkActions?: ReactNode;
    noDataMessage?: string;
    header?: ReactNode | Array<ReactNode>;
    minCellWidth?: number;
    tableLayout?: "auto" | "fixed";
    cellHighlight?: {
      highlightCellAttributeName: string;
      highlightColor: string;
      highlightUnderlineColor: string;
    };
    overscan?: number;
    totalRenderedRows?: number;
    columnsConfiguration?: {
      allowColumnsConfiguration: boolean;
      onSaveColumnsConfiguration: (data: {
        [key: string]: Array<ColumnsSettingsData>;
      }) => void;
      columnsSettingsKey: string;
    };
    exportExcelFunc?: () => Promise<void>;
    allowFullscreen?: boolean;
    rowHeight?: number;
    allowViewTypeSwitch?: boolean;
    renderCardRowFunction?: (index: number, row: unknown[]) => JSX.Element;
  };
  parametersToSelectBy?: Array<string>;
  loading: boolean;
  onPageChange: (event, page) => void;
  onRowsPerPageChange: (event) => void;
  pagination: {
    page: number;
    rowsPerPage: number;
    total: number;
    [key: string]: any;
  };
  tableContainerStyle?: SxProps;
  bulkActions?: {
    allowBulkActions: boolean;
    selectedRecords: Array<any>;
    setSelectedRecords: Dispatch<SetStateAction<any>>;
  };
  rowsPerPageOptions?: Array<number>;
  onSortClick?: (
    column: DataColumnConfigProps | StickyColumnConfigProps,
  ) => void;
  currentSort?: {
    sortField: string;
    sortOrder: number;
    [key: string]: any;
  } | null;
  TableContainerComponent?: ElementType;
  CardVariant?: any;
  paginationDisabled?: {
    disabled: boolean;
    disabledMessage: string;
  };
  tableContainerComponentSx?: SxProps;
  disableOpenCellDetailsModal?: boolean;
}

export interface ModalContent {
  propertyName: string;
  propertyLabel: string;
  content: string;
}

export type TableViewType = "table" | "cards";

export const constantDimensions = {
  loadingBar: {
    height: "4px",
  },
  pagination: {
    height: "52px",
  },
};

const SELECT_ROWS_NO_VALUE_FOR_PARAMETER = "noValuesForParameterSelect";

const CustomTable: FC<CustomTableProps> = ({
  config: {
    data,
    stickyColumns = [],
    columns: _columns,
    selectedRowsBulkActions = <></>,
    noDataMessage = "No data found",
    header = <></>,
    minCellWidth = null,
    tableLayout = "auto",
    cellHighlight = {
      highlightCellAttributeName: null,
      highlightColor: null,
      highlightUnderlineColor: null,
    },
    overscan = 500,
    totalRenderedRows = 5,
    columnsConfiguration = {
      allowColumnsConfiguration: false,
      onSaveColumnsConfiguration: () => {},
      columnsSettingsKey: "",
    },
    exportExcelFunc = null,
    allowFullscreen = true,
    rowHeight = null,
    allowViewTypeSwitch = false,
    renderCardRowFunction = undefined,
  },
  parametersToSelectBy = [],
  loading,
  onPageChange,
  onRowsPerPageChange,
  pagination,
  tableContainerStyle = {},
  bulkActions = {
    allowBulkActions: false,
    selectedRecords: [],
    setSelectedRecords: null,
  },
  rowsPerPageOptions = rowsPerPageOptionsStandard,
  onSortClick = null,
  currentSort = null,
  TableContainerComponent = Card,
  CardVariant,
  paginationDisabled = {
    disabled: false,
    disabledMessage: null,
  },
  tableContainerComponentSx = {},
  disableOpenCellDetailsModal = false,
}) => {
  const theme = useTheme();
  const history = useHistory();

  const tableRef = useRef<TableVirtuosoHandle>(null);
  const ref = useRef<HTMLDivElement>(null);

  const [showBulkActions, setShowBulkActions] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ModalContent | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [fullscreenModalOpen, setFullscreenModalOpen] =
    useState<boolean>(false);
  const [columns, setColumns] = useState<Array<DataColumnConfigProps>>(
    _columns
      ?.filter((column) => column.isVisible)
      ?.sort((a, b) => a.order - b.order) ?? [],
  );
  const [columnConfigDrawerOpen, setColumnConfigDrawerOpen] =
    useState<boolean>(false);

  const [internalColumnsConfig, setInternalColumnsConfig] = useState<
    Array<DataColumnConfigProps>
  >(_columns?.sort((a, b) => a.order - b.order) ?? []);

  const [isManageColumnsItemDragging, setIsManageColumnsItemDragging] =
    useState<boolean>(false);

  const [generateExcelEnabled, setGenerateExcelEnabled] =
    useState<boolean>(true);
  const [isExcelGenerating, setIsExcelGenerating] = useState<boolean>(false);

  const [viewType, setViewType] = useState<TableViewType>(
    allowViewTypeSwitch
      ? getSearchParamValue<TableViewType>(history, "tableViewType", "table")
      : "table",
  );

  const handleChangeViewType = (tableViewType: TableViewType): void => {
    setUrlParams(history, { tableViewType });
    setViewType(tableViewType);
  };

  const getSelectedRowValue = (row): string => {
    return parametersToSelectBy
      .map((parameter) => {
        return !!row?.[parameter]
          ? row[parameter].toString()
          : SELECT_ROWS_NO_VALUE_FOR_PARAMETER;
      })
      .join("-");
  };

  const someSelected =
    bulkActions?.selectedRecords.length > 0 &&
    bulkActions?.selectedRecords.filter((record) =>
      data.map((item) => getSelectedRowValue(item)).includes(record),
    ).length < data?.length;
  const allSelected =
    data?.length > 0 &&
    bulkActions?.selectedRecords.filter((record) =>
      data.map((item) => getSelectedRowValue(item)).includes(record),
    ).length === data?.length;

  const handleOpenModal = (params: ModalContent): void => {
    setModalContent(params);
    setModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setModalOpen(false);
    setTimeout(() => {
      setModalContent(null);
    }, 150);
  };

  const handleOpenColumnConfigDrawer = (): void => {
    setColumnConfigDrawerOpen(true);
  };

  const handleCloseColumnConfigDrawer = (
    preventInternalConfigOverwrite: boolean = false,
  ): void => {
    setColumnConfigDrawerOpen(false);
    !preventInternalConfigOverwrite &&
      setTimeout(() => {
        setInternalColumnsConfig(
          _columns?.sort((a, b) => a.order - b.order) ?? [],
        );
      }, 150);
  };

  const handleSelectRow = (row: any): void => {
    if (!bulkActions?.setSelectedRecords) return;
    if (bulkActions?.selectedRecords.includes(getSelectedRowValue(row)))
      bulkActions?.setSelectedRecords((prev) =>
        prev.filter((item) => item !== getSelectedRowValue(row)),
      );
    else
      bulkActions?.setSelectedRecords((prev) => [
        ...prev,
        getSelectedRowValue(row),
      ]);
  };

  const handleSelectAll = (): void => {
    if (!bulkActions?.setSelectedRecords) return;
    if (someSelected)
      bulkActions?.setSelectedRecords((prev) =>
        uniq([...prev, ...data.map((item) => getSelectedRowValue(item))]),
      );
    else if (allSelected)
      bulkActions?.setSelectedRecords((prev) =>
        prev.filter(
          (item) => !data.find((_item) => getSelectedRowValue(_item) === item),
        ),
      );
    // bulkActions?.setSelectedRecords([]);
    else
      bulkActions?.setSelectedRecords((prev) => [
        ...prev,
        ...data.map((item) => getSelectedRowValue(item)),
      ]);
  };

  const handleDeselectAll = (): void => {
    if (!bulkActions?.setSelectedRecords) return;
    bulkActions?.setSelectedRecords([]);
  };

  const onDragStart = (): void => {
    setIsManageColumnsItemDragging(true);
  };

  const onDragEnd = (
    result: DropResult,
    provided: ResponderProvided,
    columnsConfig: Array<DataColumnConfigProps>,
  ): void => {
    setIsManageColumnsItemDragging(false);
    if (!result.destination) return;
    const { draggableId, source, destination } = result;
    const item =
      columnsConfig.find((column) => column.propertyName === draggableId) ??
      null;
    if (!item) return;
    let newConfig = columnsConfig.filter(
      (column) => column.propertyName !== draggableId,
    );
    newConfig.splice(destination.index, 0, item);
    setInternalColumnsConfig(
      newConfig.map((column, idx) => ({ ...column, order: idx })),
    );
  };

  const handleColumnVisibilityChange = (
    columnsConfig: Array<DataColumnConfigProps>,
    index: number,
    newVisibility: boolean,
  ): void => {
    setInternalColumnsConfig((prev) => {
      let itemToChange = prev[index];

      return [
        ...prev.slice(0, index),
        { ...itemToChange, isVisible: newVisibility },
        ...prev.slice(index + 1, prev.length),
      ];
    });
  };

  const handleSaveColumnsConfig = (): void => {
    setColumns(internalColumnsConfig.filter((column) => column.isVisible));
    handleCloseColumnConfigDrawer(true);
    columnsConfiguration?.onSaveColumnsConfiguration?.({
      [columnsConfiguration?.columnsSettingsKey]: internalColumnsConfig.map(
        (item) => ({
          propertyName: item.propertyName,
          order: item.order,
          isVisible: item.isVisible,
        }),
      ),
    });
  };

  const exportExcel = async () => {
    if (!exportExcelFunc) return;
    setGenerateExcelEnabled(false);
    setIsExcelGenerating(true);
    exportExcelFunc().then(() => {
      setGenerateExcelEnabled(true);
      setIsExcelGenerating(false);
    });
  };

  useEffect(() => {
    if (bulkActions?.selectedRecords.length === 0 && showBulkActions)
      setShowBulkActions(false);
    else if (bulkActions?.selectedRecords.length > 0 && !showBulkActions)
      setShowBulkActions(true);
  }, [bulkActions?.selectedRecords, showBulkActions]);

  useEffect(() => {
    if (!loading && tableRef.current !== null) {
      tableRef.current.scrollToIndex(0);
    }
  }, [loading]);

  const fixedHeaderContent = () => {
    return (
      <>
        <TableRow key={"table-header"}>
          {bulkActions?.allowBulkActions && (
            <TableCell
              sx={{
                padding: `0 !important`,
                position: "sticky",
                left: 0,
                backgroundColor: theme.palette.background.paper,
                zIndex: 1000,
              }}
              width={40}
            >
              <Checkbox
                indeterminate={someSelected}
                checked={allSelected}
                disabled={data.length === 0}
                onChange={handleSelectAll}
              />
            </TableCell>
          )}
          {bulkActions?.selectedRecords.length > 0 ? (
            <>
              <TableCell
                colSpan={
                  [
                    ...stickyColumns,
                    ...columns?.filter((column) => column.isVisible),
                  ].length + (bulkActions?.allowBulkActions ? 1 : 0)
                }
                sx={{
                  position: "sticky",
                  left: 56,
                  padding: "0 !important",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "16px",
                    alignItems: "center",
                    "& > button": {
                      border: "solid 2px",
                      "&:hover": {
                        border: "solid 2px",
                      },
                      py: "2px",
                    },
                    position: "sticky",
                    left: 56,
                    width: "fit-content",
                  }}
                >
                  {bulkActions?.selectedRecords.length} row
                  {bulkActions?.selectedRecords.length === 1 ? "" : "s"}{" "}
                  selected
                  <Button
                    variant={"outlined"}
                    onClick={handleDeselectAll}
                    startIcon={
                      <ClearIcon sx={{ width: "28px", height: "28px" }} />
                    }
                    sx={{
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    }}
                  >
                    Deselect all
                  </Button>
                  {selectedRowsBulkActions}
                </Box>
              </TableCell>
            </>
          ) : (
            [...stickyColumns, ...columns].map((column, idx) => (
              <TableCell
                key={column.propertyName}
                variant="head"
                {...(column.headerAlign && { align: column.headerAlign })}
                {...(column.width && { width: column.width })}
                sx={{
                  backgroundColor: "background.paper",
                  ...(minCellWidth &&
                    !column?.width && {
                      minWidth: minCellWidth,
                    }),
                  ...(column?.isSticky && {
                    position: "sticky",
                    left:
                      [...stickyColumns, ...columns][idx - 1]?.isSticky &&
                      idx > 0
                        ? [...stickyColumns, ...columns].slice(0, idx).reduce(
                            //width is required for sticky columns
                            //@ts-ignore
                            (total, current) => (total += current.width),
                            0,
                          ) + (bulkActions?.allowBulkActions ? 44 : 0)
                        : bulkActions?.allowBulkActions
                        ? 44
                        : 0,
                    "&::after": {
                      boxShadow:
                        "15px 0 15px -10px rgba(212, 212, 212, 1) inset",
                      right: "-15px",
                      content: `""`,
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      width: "15px",
                    },
                    zIndex: 1000,
                    backgroundColor: theme.palette.background.paper,
                    ...(idx === stickyColumns.length - 1 && {
                      borderRight: `solid 2px ${theme.palette.divider}`,
                    }),
                  }),
                  ...(column?.width && {
                    minWidth: column.width,
                    maxWidth: column.width,
                  }),
                  ...(column?.maxWidth && {
                    maxWidth: column.maxWidth,
                  }),
                }}
                {...(idx === [...stickyColumns, ...columns].length - 1 && {
                  width: "100%",
                })}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                  >
                    {column?.headerTooltip && (
                      <Tooltip
                        title={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: column.headerTooltip,
                            }}
                          />
                        }
                        disableInteractive
                      >
                        <span>
                          <InfoOutlinedIcon
                            sx={{
                              height: "16px",
                              width: "16px",
                              "&:hover": {
                                color: theme.palette.primary.main,
                              },
                            }}
                          />
                        </span>
                      </Tooltip>
                    )}
                    {column?.headerFormatter
                      ? column.headerFormatter(column.label)
                      : column.label}
                  </Box>
                  {column?.sortable && onSortClick !== null && (
                    <SortButton
                      disabled={loading}
                      sortOrder={
                        currentSort?.sortField ===
                        (column?.getSortableColumnPropertyName
                          ? column?.getSortableColumnPropertyName(
                              column.propertyName,
                            )
                          : column.propertyName)
                          ? currentSort.sortOrder
                          : 0
                      }
                      onClick={() => {
                        onSortClick(column);
                      }}
                    />
                  )}
                </Box>
              </TableCell>
            ))
          )}
        </TableRow>
        <TableRow key={"table-loading-bar"}>
          <TableCell
            colSpan={
              [
                ...stickyColumns,
                ...columns?.filter((column) => column.isVisible),
              ].length + (bulkActions?.allowBulkActions ? 1 : 0)
            }
            sx={{
              border: "unset !important",
              padding: `0 !important`,
            }}
          >
            {loading && <LinearProgress sx={{ mt: "-4px", zIndex: 1500 }} />}
          </TableCell>
        </TableRow>
      </>
    );
  };

  const rowContent = (_index: number, row: any) => {
    return row?.noData ? (
      <TableCell
        colSpan={
          [...stickyColumns, ...columns?.filter((column) => column.isVisible)]
            .length + (bulkActions?.allowBulkActions ? 1 : 0)
        }
        sx={{
          height: "64px",
        }}
      >
        <Typography
          sx={{
            position: "fixed",
            mt: "-12px",
          }}
          variant={"h5"}
        >
          {noDataMessage}
        </Typography>
      </TableCell>
    ) : (
      <React.Fragment key={`table-row-${_index}`}>
        {bulkActions?.allowBulkActions && (
          <TableCell
            sx={{
              p: 0,
              position: "sticky",
              left: 0,
              backgroundColor: row?.rowBackground
                ? row.rowBackground
                : _index % 2
                ? theme.palette.background.default
                : theme.palette.background.paper,
              border: "unset",
            }}
            width={40}
          >
            <Checkbox
              onChange={() => handleSelectRow(row)}
              checked={bulkActions?.selectedRecords.includes(
                getSelectedRowValue(row),
              )}
            />
          </TableCell>
        )}
        {[...stickyColumns, ...columns].map((column, idx) => (
          <TableCell
            key={column.propertyName}
            {...(column.cellAlign && { align: column.cellAlign })}
            {...(column.width && { width: column.width })}
            onClick={() =>
              !column?.disableModalWithDetails &&
              !disableOpenCellDetailsModal &&
              row[column.propertyName] &&
              handleOpenModal({
                propertyName: column.propertyName,
                propertyLabel: column.label,
                content: column?.cellFormatter
                  ? column.cellFormatter(row[column.propertyName])
                  : row[column.propertyName],
              })
            }
            sx={{
              p: 1,
              borderBottom: "unset",
              backgroundColor: row?.rowBackground
                ? row.rowBackground
                : _index % 2
                ? theme.palette.background.default
                : theme.palette.background.paper,
              ...(column?.isSticky && {
                position: "sticky",
                left:
                  [...stickyColumns, ...columns][idx - 1]?.isSticky && idx > 0
                    ? [...stickyColumns, ...columns].slice(0, idx).reduce(
                        //width is required for sticky columns
                        //@ts-ignore
                        (total, current) => (total += current.width),
                        0,
                      ) + (bulkActions?.allowBulkActions ? 44 : 0)
                    : bulkActions?.allowBulkActions
                    ? 44
                    : 0,
                ...(idx === stickyColumns.length - 1 && {
                  "&::after": {
                    boxShadow: "15px 0 15px -10px rgba(212, 212, 212, 1) inset",
                    right: "-15px",
                    content: `""`,
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    width: "15px",
                  },
                  borderRight: `solid 2px ${theme.palette.divider}`,
                }),
              }),
              ...(column?.width && {
                minWidth: column.width,
                maxWidth: column.width,
              }),
              ...(column?.maxWidth && {
                maxWidth: column.maxWidth,
              }),
              ...(cellHighlight?.highlightCellAttributeName !== null &&
                cellHighlight?.highlightColor !== null &&
                cellHighlight?.highlightUnderlineColor !== null &&
                !row?.disableCellHighlight &&
                row[cellHighlight?.highlightCellAttributeName] !== null &&
                row[cellHighlight?.highlightCellAttributeName].includes(
                  column.propertyName.toLowerCase(),
                ) && {
                  backgroundColor: cellHighlight?.highlightColor,
                  borderBottom: `solid 3px ${cellHighlight?.highlightUnderlineColor}`,
                }),
              ...(!column?.disableModalWithDetails &&
                !disableOpenCellDetailsModal &&
                row[column.propertyName] && {
                  cursor: "pointer",
                }),
              ...(row?.rowTextColor && {
                "p, span": {
                  color: row.rowTextColor,
                },
              }),
              ...(idx === [...stickyColumns, ...columns].length - 1 && {
                width: "100%",
              }),
              ...(rowHeight && {
                height: `${rowHeight}px`,
              }),
            }}
          >
            {typeof row[column.propertyName] === "string" ||
            typeof row[column.propertyName] === "number" ? (
              <Typography
                variant={"body2"}
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: column?.maxNumberOfLines ?? 2,
                  ...column?.textSx,
                  "& > *": {
                    m: 0,
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: column?.cellFormatter
                    ? column.cellFormatter(row[column.propertyName])
                    : row[column.propertyName],
                }}
              />
            ) : (
              row[column.propertyName]
            )}
          </TableCell>
        ))}
      </React.Fragment>
    );
  };

  const isLessThanLg = useMediaQuery(theme.breakpoints.down("lg"));

  const cardRowContent = (_index: number, row: any[]) => {
    if (!renderCardRowFunction) {
      throw new Error("renderCardRowFunction required for card view");
    } else {
      return renderCardRowFunction(_index, row);
    }
  };

  const VirtuosoTableComponents: TableComponents<any> = {
    Table: (props) => (
      <Table
        {...props}
        sx={{
          borderCollapse: "separate",
          tableLayout: tableLayout,
        }}
      />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => (
      <TableRow {...props} key={_item?.propertyName} />
    ),
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };

  const TableActionButtons = (): JSX.Element => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: "8px",
        "> span": {
          "> button": {
            border: `solid 1px ${theme.palette.divider}`,
          },
        },
      }}
    >
      {allowViewTypeSwitch && (
        <ButtonGroup
          size={"small"}
          sx={{
            "& > button": {
              borderColor: theme.palette.divider,
              p: 0.5,
              "& > svg": {
                height: "20px !important",
                width: "20px !important",
              },
            },
          }}
        >
          <Tooltip
            title={"Table view"}
            disableFocusListener
            disableTouchListener
            disableInteractive
            arrow
          >
            <Button
              onClick={() =>
                viewType === "cards" && handleChangeViewType("table")
              }
              variant={viewType === "table" ? "contained" : "outlined"}
              sx={{
                borderRadius: "8px 0 0 8px",
              }}
            >
              <TableChartIcon
                sx={{
                  fill:
                    viewType === "table"
                      ? theme.palette.primary.contrastText
                      : theme.palette.text.secondary,
                }}
              />
            </Button>
          </Tooltip>
          <Tooltip
            title={"Card view"}
            disableFocusListener
            disableTouchListener
            disableInteractive
            arrow
          >
            <Button
              onClick={() =>
                viewType === "table" && handleChangeViewType("cards")
              }
              variant={viewType === "cards" ? "contained" : "outlined"}
              sx={{
                borderRadius: "0 8px 8px 0",
              }}
            >
              <ViewAgendaIcon
                sx={{
                  fill:
                    viewType === "cards"
                      ? theme.palette.primary.contrastText
                      : theme.palette.text.secondary,
                }}
              />
            </Button>
          </Tooltip>
        </ButtonGroup>
      )}
      {exportExcelFunc && (
        <Tooltip title={"Export Excel"} disableInteractive>
          <span>
            <IconButton disabled={!generateExcelEnabled} onClick={exportExcel}>
              <DownloadDocumentIcon />
              {isExcelGenerating && (
                <CircularProgress
                  size={16}
                  sx={{
                    position: "absolute",
                    left: "-6px",
                    top: "-6px",
                  }}
                />
              )}
            </IconButton>
          </span>
        </Tooltip>
      )}
      {allowFullscreen && viewType !== "cards" && (
        <Tooltip
          title={
            fullscreenModalOpen ? "Close Full Screen View" : "Full Screen View"
          }
          disableInteractive
        >
          <span>
            <IconButton
              onClick={(e) => {
                if (fullscreenModalOpen) {
                  MinimizeGrid(e.target as HTMLElement);
                } else {
                  MaximizeGrid(e.target as HTMLElement, () =>
                    setFullscreenModalOpen(false),
                  );
                }

                setFullscreenModalOpen(!fullscreenModalOpen);
              }}
            >
              <FullscreenIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {columnsConfiguration?.allowColumnsConfiguration && (
        <TooltipWithScrollHide
          querySelectors={"[data-virtuoso-scroller]"}
          title={"Manage Columns"}
        >
          <span>
            <IconButton
              onClick={
                columnConfigDrawerOpen
                  ? () => handleCloseColumnConfigDrawer()
                  : handleOpenColumnConfigDrawer
              }
              sx={{
                border: `solid 1px ${theme.palette.divider}`,
              }}
            >
              <ManageColumnsIcon />
            </IconButton>
          </span>
        </TooltipWithScrollHide>
      )}
    </Box>
  );

  const ManageColumnsContent = (): JSX.Element => (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          backgroundColor: theme.palette.secondary.light,
          borderBottom: `solid 3px ${theme.palette.secondary.main}`,
          py: 3,
        }}
      >
        <ManageColumnsIcon />
        <Typography variant={"h5"}>Manage Table Columns</Typography>
      </Box>
      <Box
        sx={{
          py: 1,
          overflowY: "scroll",
          flex: 1,
        }}
      >
        <DragDropContext
          onDragEnd={(result, provided) =>
            onDragEnd(result, provided, internalColumnsConfig)
          }
          onDragStart={onDragStart}
        >
          <Droppable droppableId={"forecast-column-order"}>
            {(provided, snapshot) => (
              <Box ref={provided.innerRef} {...provided.droppableProps}>
                {internalColumnsConfig.map((column, idx) => {
                  return (
                    <Draggable
                      key={column.propertyName}
                      draggableId={column.propertyName}
                      index={idx}
                    >
                      {(provided) => (
                        <Card
                          variant={"outlined"}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          key={column.propertyName}
                          sx={{
                            px: 2,
                            mb: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <FormControlLabel
                            control={<Checkbox checked={column.isVisible} />}
                            onChange={() =>
                              handleColumnVisibilityChange(
                                internalColumnsConfig,
                                idx,
                                !column.isVisible,
                              )
                            }
                            disabled={
                              internalColumnsConfig.filter(
                                (column) => column.isVisible,
                              ).length < 2 && column.isVisible
                            }
                            label={column.label.toUpperCase()}
                            slotProps={{
                              typography: {
                                fontSize: "14px",
                                fontWeight: "500",
                              },
                            }}
                          />
                          <Box
                            {...provided.dragHandleProps}
                            sx={{
                              "& > svg": { fontSize: "24px !important" },
                            }}
                          >
                            <DragIndicatorIcon />
                          </Box>
                        </Card>
                      )}
                    </Draggable>
                  );
                })}
              </Box>
            )}
          </Droppable>
          <Box
            sx={{
              height: isManageColumnsItemDragging ? "44px" : 0,
              // height: "44px",
            }}
          ></Box>
        </DragDropContext>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "16px",
          py: 2,
        }}
      >
        <Button
          variant={"secondaryContained"}
          color={"secondary"}
          onClick={() => handleCloseColumnConfigDrawer()}
        >
          Cancel
        </Button>
        <Button
          variant={"contained"}
          color={"secondary"}
          onClick={handleSaveColumnsConfig}
        >
          Save
        </Button>
      </Box>
    </>
  );

  return (
    <Box
      component={TableContainerComponent}
      {...(typeof CardVariant !== undefined && { variant: CardVariant })}
      className={"p-datatable-scrollable"}
      sx={{
        width: "100%",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        ...tableContainerComponentSx,
        ...(fullscreenModalOpen && {
          "& > *": {
            backgroundColor: theme.palette.background.paper,
          },
        }),
      }}
    >
      <Drawer
        open={columnConfigDrawerOpen}
        variant={"temporary"}
        onClose={() => handleCloseColumnConfigDrawer()}
        anchor={"right"}
        sx={{
          zIndex: constants.zIndexes.drawer, //to be sure that the drawer is over the FAQ side button
        }}
        PaperProps={{
          sx: {
            minWidth: "480px",
            "& > div": {
              px: 2,
            },
          },
        }}
      >
        {ManageColumnsContent()}
      </Drawer>

      <SimpleModal
        open={modalOpen}
        handleClose={handleCloseModal}
        handleAccept={handleCloseModal}
        disableCancelButton
        title={modalContent?.propertyLabel}
      >
        <Typography
          variant={"body1"}
          dangerouslySetInnerHTML={{ __html: modalContent?.content ?? "" }}
        />
      </SimpleModal>

      {Array.isArray(header) ? (
        <Box
          sx={{
            ...(fullscreenModalOpen && {
              borderRadius: "8px 8px 0 0 !important",
            }),
          }}
        >
          {header.map((node, idx) => {
            if (idx === 0)
              return (
                <Box
                  key={`node-${idx}`}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: 3,
                  }}
                >
                  {node}
                  <TableActionButtons />
                </Box>
              );
            else
              return (
                <Box
                  key={`node-${idx}`}
                  sx={{
                    px: 3,
                  }}
                >
                  {node}
                </Box>
              );
          })}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 3,
            pt: 2,
            pb: 1,
            ...(fullscreenModalOpen && {
              borderRadius: "8px 8px 0 0 !important",
            }),
          }}
        >
          {header}
          <TableActionButtons />
        </Box>
      )}

      <Box
        ref={ref}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          ...(fullscreenModalOpen && {
            borderRadius: "0 0 8px 8px",
          }),
          height: "-webkit-fill-available",
          flex: 1,
          ...tableContainerStyle,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
            }}
          >
            {viewType === "table" ? (
              <TableVirtuoso
                data={
                  data?.length === 0 && !loading ? [{ noData: true }] : data
                }
                components={VirtuosoTableComponents}
                fixedHeaderContent={fixedHeaderContent}
                itemContent={rowContent}
                overscan={overscan}
                totalCount={totalRenderedRows}
                style={{
                  width: "100%",
                  height: "-webkit-fill-available",
                }}
                ref={tableRef}
              />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "-webkit-fill-available",
                  borderTop: `solid 2px ${theme.palette.divider}`,
                  px: 3,
                  py: 2,
                }}
              >
                <Virtuoso
                  data={
                    data?.length === 0 && !loading
                      ? [[{ noData: true }]]
                      : isLessThanLg
                      ? data.map((row) => [row])
                      : compact(
                          data.map((row, idx) => {
                            if (idx % 2) return null;
                            return [
                              row,
                              ...(data.length > idx + 1 ? [data[idx + 1]] : []),
                            ];
                          }),
                        )
                  }
                  itemContent={cardRowContent}
                />
              </Box>
            )}
          </Box>
        </Box>
        {!paginationDisabled?.disabled ? (
          <TablePagination
            className={"p-paginator-bottom"}
            count={pagination.total}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            component={"div"}
            sx={{
              mx: 2,
              height: constantDimensions.pagination.height,
            }}
          />
        ) : paginationDisabled.disabledMessage?.length > 0 ? (
          <Typography
            variant={"subtitle1"}
            align={"center"}
            sx={{ py: 1 }}
            color={"textPrimary"}
          >
            {paginationDisabled.disabledMessage}
          </Typography>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default CustomTable;
