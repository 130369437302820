export const countries = [
  { name: "Afghanistan", code: "Afghanistan" },
  { name: "Africa", code: "Africa" },
  { name: "Albania", code: "Albania" },
  { name: "Algeria", code: "Algeria" },
  { name: "Angola", code: "Angola" },
  { name: "Anguilla", code: "Anguilla" },
  { name: "Antigua and Barbuda", code: "Antigua and Barbuda" },
  { name: "Argentina", code: "Argentina" },
  { name: "Armenia", code: "Armenia" },
  { name: "Aruba", code: "Aruba" },
  { name: "Asia", code: "Asia" },
  {
    name: "Asia, Middle East and North Africa Region",
    code: "Asia, Middle East and North Africa Region",
  },
  { name: "Australia", code: "Australia" },
  { name: "Austria", code: "Austria" },
  { name: "Azerbaijan", code: "Azerbaijan" },
  { name: "Bahamas", code: "Bahamas" },
  { name: "Bahrain", code: "Bahrain" },
  { name: "Bangladesh", code: "Bangladesh" },
  { name: "Barbados", code: "Barbados" },
  { name: "Belarus", code: "Belarus" },
  { name: "Belgium", code: "Belgium" },
  { name: "Belize", code: "Belize" },
  { name: "Benin", code: "Benin" },
  { name: "Bermuda", code: "Bermuda" },
  { name: "Bhutan", code: "Bhutan" },
  { name: "Bolivia", code: "Bolivia" },
  { name: "Bosnia/Herzegovina", code: "Bosnia/Herzegovina" },
  { name: "Botswana", code: "Botswana" },
  { name: "Brazil", code: "Brazil" },
  { name: "Brunei ", code: "Brunei " },
  {
    name: "British Indian Ocean Territory",
    code: "British Indian Ocean Territory",
  },
  { name: "Bulgaria", code: "Bulgaria" },
  { name: "Burkina Faso", code: "Burkina Faso" },
  {
    name: "Bureau for Conflict Prevention and Stabilization (CPS)",
    code: "Bureau for Conflict Prevention and Stabilization (CPS)",
  },
  {
    name: "Bureau for Democracy, Conflict and Humanitarian Assistance (DCHA)",
    code: "Bureau for Democracy, Conflict and Humanitarian Assistance (DCHA)",
  },
  {
    name: "Bureau for Economic Growth, Education and Environment (E3)",
    code: "Bureau for Economic Growth, Education and Environment (E3)",
  },
  {
    name: "Bureau for Food Security (FS)",
    code: "Bureau for Food Security (FS)",
  },
  {
    name: "Bureau for Global Health (GH)",
    code: "Bureau for Global Health (GH)",
  },
  { name: "Bureau for Management (M)", code: "Bureau for Management (M)" },
  {
    name: "Bureau for Policy, Planning and Learning (PPL)",
    code: "Bureau for Policy, Planning and Learning (PPL)",
  },
  {
    name: "Bureau for Resilience and Food Security (RFS)",
    code: "Bureau for Resilience and Food Security (RFS)",
  },
  { name: "Burma", code: "Burma" },
  { name: "Burundi", code: "Burundi" },
  { name: "Cabo Verde", code: "Cabo Verde" },
  { name: "Cambodia", code: "Cambodia" },
  { name: "Cameroon", code: "Cameroon" },
  { name: "Canada", code: "Canada" },
  { name: "Caribbean Region", code: "Caribbean Region" },
  { name: "Central African Republic", code: "Central African Republic" },
  {
    name: "Central America and Caribbean Region",
    code: "Central America and Caribbean Region",
  },
  { name: "Central America Region", code: "Central America Region" },
  {
    name: "Central and Eastern Europe Region",
    code: "Central and Eastern Europe Region",
  },
  { name: "Chad", code: "Chad" },
  { name: "Chile", code: "Chile" },
  { name: "China (P.R. Hong Kong)", code: "China (P.R. Hong Kong)" },
  { name: "China (P.R.C.)", code: "China (P.R.C.)" },
  { name: "China (Tibet)", code: "China (Tibet)" },
  { name: "Comoros", code: "Comoros" },
  { name: "Cook Islands", code: "Cook Islands" },
  { name: "Costa Rica", code: "Costa Rica" },
  { name: "Cote d'Ivoire", code: "Cote d'Ivoire" },
  { name: "Croatia", code: "Croatia" },
  { name: "Cuba", code: "Cuba" },
  { name: "Curacao", code: "Curacao" },
  { name: "Czechia", code: "Czechia" },
  {
    name: "Center for Accelerating Innovation and Impact (GH/CAII)",
    code: "Center for Accelerating Innovation and Impact (GH/CAII)",
  },
  {
    name: "Center for Faith-Based and Community Initiatives (CFBCI)",
    code: "Center for Faith-Based and Community Initiatives (CFBCI)",
  },
  { name: "Central Asia", code: "Central Asia" },
  { name: "Colombia", code: "Colombia" },
  { name: "Cyprus", code: "Cyprus" },
  { name: "Denmark", code: "Denmark" },
  {
    name: "Democratic Republic of the Congo",
    code: "Democratic Republic of the Congo",
  },
  { name: "Djibouti", code: "Djibouti" },
  { name: "Dominica", code: "Dominica" },
  { name: "Dominican Republic", code: "Dominican Republic" },
  {
    name: "East Asia and Oceania Region",
    code: "East Asia and Oceania Region",
  },
  { name: "East Africa", code: "East Africa" },
  { name: "Eastern Asia Region", code: "Eastern Asia Region" },
  { name: "Eastern Europe Region", code: "Eastern Europe Region" },
  { name: "East Timor", code: "East Timor" },
  { name: "Ecuador", code: "Ecuador" },
  { name: "Egypt", code: "Egypt" },
  { name: "El Salvador", code: "El Salvador" },
  { name: "Equatorial Guinea", code: "Equatorial Guinea" },
  { name: "Eritrea", code: "Eritrea" },
  { name: "Estonia", code: "Estonia" },
  { name: "Eswatini", code: "Eswatini" },
  { name: "Ethiopia", code: "Ethiopia" },
  { name: "Eurasia Region", code: "Eurasia Region" },
  { name: "Europe and Eurasia", code: "Europe and Eurasia" },
  { name: "Europe Region", code: "Europe Region" },
  { name: "Fiji", code: "Fiji" },
  { name: "France", code: "France" },
  { name: "French Polynesia", code: "French Polynesia" },
  { name: "Gabon", code: "Gabon" },
  { name: "Gambia", code: "Gambia" },
  { name: "Executive Secretariat (ES)", code: "Executive Secretariat (ES)" },
  { name: "Foreign Assistance (FA)", code: "Foreign Assistance (FA)" },
  { name: "Georgia", code: "Georgia" },
  { name: "Germany", code: "Germany" },
  { name: "Ghana", code: "Ghana" },
  { name: "Greece", code: "Greece" },
  { name: "Grenada", code: "Grenada" },
  { name: "Guatemala", code: "Guatemala" },
  { name: "Guinea", code: "Guinea" },
  { name: "Guinea-Bissau", code: "Guinea-Bissau" },
  { name: "Guyana", code: "Guyana" },
  { name: "Haiti", code: "Haiti" },
  { name: "Honduras", code: "Honduras" },
  { name: "Hungary", code: "Hungary" },
  { name: "India", code: "India" },
  { name: "Indonesia", code: "Indonesia" },
  { name: "Iran", code: "Iran" },
  { name: "Iraq", code: "Iraq" },
  { name: "Ireland", code: "Ireland" },
  { name: "Israel", code: "Israel" },
  { name: "Italy", code: "Italy" },
  { name: "Jamaica", code: "Jamaica" },
  { name: "Japan", code: "Japan" },
  { name: "Jordan", code: "Jordan" },
  { name: "Kazakhstan", code: "Kazakhstan" },
  { name: "Kenya", code: "Kenya" },
  { name: "Kiribati", code: "Kiribati" },
  { name: "Korea Republic", code: "Korea Republic" },
  { name: "Korea, Democratic Republic", code: "Korea, Democratic Republic" },
  { name: "Kosovo", code: "Kosovo" },
  { name: "Kuwait", code: "Kuwait" },
  { name: "Kyrgyz Republic", code: "Kyrgyz Republic" },
  { name: "Laos", code: "Laos" },
  {
    name: "Latin America and the Caribbean",
    code: "Latin America and the Caribbean",
  },
  { name: "Latin America Region", code: "Latin America Region" },
  { name: "Latvia", code: "Latvia" },
  { name: "Lebanon", code: "Lebanon" },
  { name: "Lesotho", code: "Lesotho" },
  {
    name: "Legislative and Public Affairs (LPA)",
    code: "Legislative and Public Affairs (LPA)",
  },
  { name: "Liberia", code: "Liberia" },
  { name: "Libya", code: "Libya" },
  { name: "Lithuania", code: "Lithuania" },
  { name: "Macedonia", code: "Macedonia" },
  { name: "Macau", code: "Macau" },
  { name: "Madagascar", code: "Madagascar" },
  { name: "Malawi", code: "Malawi" },
  { name: "Malaysia", code: "Malaysia" },
  { name: "Maldives", code: "Maldives" },
  { name: "Mali", code: "Mali" },
  { name: "Malta", code: "Malta" },
  { name: "Marshall Islands", code: "Marshall Islands" },
  { name: "Mauritania", code: "Mauritania" },
  { name: "Mauritius", code: "Mauritius" },
  {
    name: "MERP (MID EAST REG PRO FRANK)",
    code: "MERP (MID EAST REG PRO FRANK)",
  },
  { name: "Mexico", code: "Mexico" },
  {
    name: "Micronesia (Federated States)",
    code: "Micronesia (Federated States)",
  },
  {
    name: "Middle East and North Africa Region",
    code: "Middle East and North Africa Region",
  },
  { name: "Middle East", code: "Middle East" },
  { name: "Moldova", code: "Moldova" },
  { name: "Mongolia", code: "Mongolia" },
  { name: "Montenegro", code: "Montenegro" },
  { name: "Montserrat", code: "Montserrat" },
  { name: "Morocco", code: "Morocco" },
  { name: "Mozambique", code: "Mozambique" },
  { name: "Namibia", code: "Namibia" },
  { name: "Nepal", code: "Nepal" },
  { name: "Netherlands", code: "Netherlands" },
  { name: "New Caledonia", code: "New Caledonia" },
  { name: "New Zealand", code: "New Zealand" },
  { name: "Nicaragua", code: "Nicaragua" },
  { name: "Niger", code: "Niger" },
  { name: "Nigeria", code: "Nigeria" },
  { name: "North Africa Region", code: "North Africa Region" },
  { name: "North America Region", code: "North America Region" },
  {
    name: "North and Central America Region",
    code: "North and Central America Region",
  },
  { name: "North Macedonia", code: "North Macedonia" },
  { name: "Norway ", code: "Norway " },
  { name: "Oceania Region", code: "Oceania Region" },
  { name: "Oman", code: "Oman" },
  {
    name: "Office of Acquisition and Assistance (M/OAA)",
    code: "Office of Acquisition and Assistance (M/OAA)",
  },
  {
    name: "Office of Afghanistan and Pakistan Affairs (OAPA)",
    code: "Office of Afghanistan and Pakistan Affairs (OAPA)",
  },
  {
    name: "Office of Budget and Resource Management (BRM)",
    code: "Office of Budget and Resource Management (BRM)",
  },
  {
    name: "Office of Chief Operating Officer (COO)",
    code: "Office of Chief Operating Officer (COO)",
  },
  {
    name: "Office of Civil Rights and Diversity (OCRD)",
    code: "Office of Civil Rights and Diversity (OCRD)",
  },
  {
    name: "Office of Country Support (GH/OCS)",
    code: "Office of Country Support (GH/OCS)",
  },
  {
    name: "Office of Development Credit (E3/DC)",
    code: "Office of Development Credit (E3/DC)",
  },
  {
    name: "Office of Economic Policy (E3/EP)",
    code: "Office of Economic Policy (E3/EP)",
  },
  { name: "Office of Education (E3/ED)", code: "Office of Education (E3/ED)" },
  {
    name: "Office of Energy & Infrastructure (E3/EI)",
    code: "Office of Energy & Infrastructure (E3/EI)",
  },
  {
    name: "Office of Forestry & Biodiversity (E3/FAB)",
    code: "Office of Forestry & Biodiversity (E3/FAB)",
  },
  {
    name: "Office of Gender Equality & Women's Empowerment (E3/GEWE)",
    code: "Office of Gender Equality & Women's Empowerment (E3/GEWE)",
  },
  {
    name: "Office of General Counsel (GC)",
    code: "Office of General Counsel (GC)",
  },
  {
    name: "Office of Global Climate Change (E3/GCC)",
    code: "Office of Global Climate Change (E3/GCC)",
  },
  {
    name: "Office of Health Infectious Diseases & Nutrition (GH/HIDN)",
    code: "Office of Health Infectious Diseases & Nutrition (GH/HIDN)",
  },
  {
    name: "Office of Health Systems (GH/OHS)",
    code: "Office of Health Systems (GH/OHS)",
  },
  { name: "Office of HIV/AIDS (GH/OHA)", code: "Office of HIV/AIDS (GH/OHA)" },
  {
    name: "Office of Human Capital and Talent Management (HCTM)",
    code: "Office of Human Capital and Talent Management (HCTM)",
  },
  {
    name: "Office of Infectious Disease (GH/ID)",
    code: "Office of Infectious Disease (GH/ID)",
  },
  {
    name: "Office of Inspector General (OIG)",
    code: "Office of Inspector General (OIG)",
  },
  {
    name: "Office of Land and Urban (E3/LU)",
    code: "Office of Land and Urban (E3/LU)",
  },
  {
    name: "Office of Land Tenure & Resource Management (E3/LTRM)",
    code: "Office of Land Tenure & Resource Management (E3/LTRM)",
  },
  {
    name: "Office of Local Sustainability (E3/LS)",
    code: "Office of Local Sustainability (E3/LS)",
  },
  {
    name: "Office of Management Policy, Budget, and Performance (M/MPBP)",
    code: "Office of Management Policy, Budget, and Performance (M/MPBP)",
  },
  {
    name: "Office of Management Services (M/MS)",
    code: "Office of Management Services (M/MS)",
  },
  {
    name: "Office of Maternal and Child Health and Nutrition (GH/MCHN)",
    code: "Office of Maternal and Child Health and Nutrition (GH/MCHN)",
  },
  {
    name: "Office of Middle East Programs",
    code: "Office of Middle East Programs",
  },
  {
    name: "Office of Planning, Learning & Coordination (E3/PLC)",
    code: "Office of Planning, Learning & Coordination (E3/PLC)",
  },
  {
    name: "Office of Policy, Programs & Planning (GH/OPPP)",
    code: "Office of Policy, Programs & Planning (GH/OPPP)",
  },
  {
    name: "Office of Population & Reproductive Health (GH/PRH)",
    code: "Office of Population & Reproductive Health (GH/PRH)",
  },
  {
    name: "Office of Professional Development & Management Services (E3/PDMS)",
    code: "Office of Professional Development & Management Services (E3/PDMS)",
  },
  {
    name: "Office of Professional Development & Management Services (GH/PDMS)",
    code: "Office of Professional Development & Management Services (GH/PDMS)",
  },
  { name: "Office of Security (SEC)", code: "Office of Security (SEC)" },
  {
    name: "Office of Small and Disadvantaged Business Utilization (OSDBU)",
    code: "Office of Small and Disadvantaged Business Utilization (OSDBU)",
  },
  {
    name: "Office of the Administrator (AID/A)",
    code: "Office of the Administrator (AID/A)",
  },
  {
    name: "Office of the Assistant Administrator (GH/AA)",
    code: "Office of the Assistant Administrator (GH/AA)",
  },
  {
    name: "Office of the Chief Financial Officer (M/CFO)",
    code: "Office of the Chief Financial Officer (M/CFO)",
  },
  {
    name: "Office of the Chief Information Officer (M/CIO)",
    code: "Office of the Chief Information Officer (M/CIO)",
  },
  {
    name: "Office of Trade & Regulatory Reform (E3/TRR)",
    code: "Office of Trade & Regulatory Reform (E3/TRR)",
  },
  {
    name: "Office of U.S. Foreign Disaster Assistance (DCHA/OFDA)",
    code: "Office of U.S. Foreign Disaster Assistance (DCHA/OFDA)",
  },
  { name: "Office of Water (E3/W)", code: "Office of Water (E3/W)" },
  { name: "Pakistan", code: "Pakistan" },
  { name: "Palau", code: "Palau" },
  { name: "Panama", code: "Panama" },
  { name: "Papua New Guinea", code: "Papua New Guinea" },
  { name: "Paraguay", code: "Paraguay" },
  { name: "Peru", code: "Peru" },
  { name: "Philippines", code: "Philippines" },
  { name: "Poland", code: "Poland" },
  { name: "Portugal", code: "Portugal" },
  { name: "Qatar", code: "Qatar" },
  { name: "Romania", code: "Romania" },
  { name: "Russia", code: "Russia" },
  { name: "Power Africa", code: "Power Africa" },
  {
    name: "Private Capital and Microenterprise Office (E3/PCM)",
    code: "Private Capital and Microenterprise Office (E3/PCM)",
  },
  {
    name: "Regional Development Mission for Asia",
    code: "Regional Development Mission for Asia",
  },
  { name: "Rwanda", code: "Rwanda" },
  { name: "Samoa", code: "Samoa" },
  { name: "Sao Tome and Principe", code: "Sao Tome and Principe" },
  { name: "Saudi Arabia", code: "Saudi Arabia" },
  {
    name: "Senegal  (Includes Burkina Faso and Mali)",
    code: "Senegal  (Includes Burkina Faso and Mali)",
  },
  { name: "Serbia", code: "Serbia" },
  { name: "Sierra Leone", code: "Sierra Leone" },
  { name: "Singapore", code: "Singapore" },
  { name: "Slovak Republic", code: "Slovak Republic" },
  { name: "Slovenia", code: "Slovenia" },
  { name: "Solomon Islands", code: "Solomon Islands" },
  { name: "Somalia", code: "Somalia" },
  { name: "South Africa", code: "South Africa" },
  { name: "South America Region", code: "South America Region" },
  {
    name: "South and Central Asia Region",
    code: "South and Central Asia Region",
  },
  { name: "South East Asia Region", code: "South East Asia Region" },
  { name: "South Sudan", code: "South Sudan" },
  { name: "Southern Africa Region", code: "Southern Africa Region" },
  { name: "Southern Asia Region", code: "Southern Asia Region" },
  { name: "Spain", code: "Spain" },
  { name: "Sri Lanka", code: "Sri Lanka" },
  { name: "St. Kitts and Nevis", code: "St. Kitts and Nevis" },
  { name: "St. Lucia", code: "St. Lucia" },
  { name: "St. Vincent and Grenadines", code: "St. Vincent and Grenadines" },
  { name: "Sub-Saharan Africa Region", code: "Sub-Saharan Africa Region" },
  { name: "Sudan", code: "Sudan" },
  { name: "Suriname", code: "Suriname" },
  { name: "Switzerland", code: "Switzerland" },
  { name: "Syria", code: "Syria" },
  { name: "Taiwan", code: "Taiwan" },
  { name: "Tajikistan", code: "Tajikistan" },
  { name: "Tanzania", code: "Tanzania" },
  { name: "Thailand", code: "Thailand" },
  { name: "Timor-Leste", code: "Timor-Leste" },
  { name: "Togo", code: "Togo" },
  { name: "Tonga", code: "Tonga" },
  { name: "Trinidad and Tobago", code: "Trinidad and Tobago" },
  { name: "Tunisia", code: "Tunisia" },
  { name: "Turkey", code: "Turkey" },
  { name: "Turkmenistan", code: "Turkmenistan" },
  { name: "Turks and Caicos Islands", code: "Turks and Caicos Islands" },
  { name: "Tuvalu", code: "Tuvalu" },
  {
    name: "U.S. Global Development Lab (LAB)",
    code: "U.S. Global Development Lab (LAB)",
  },
  { name: "Uganda", code: "Uganda" },
  { name: "Ukraine", code: "Ukraine" },
  { name: "United Arab Emirates", code: "United Arab Emirates" },
  { name: "United Kingdom", code: "United Kingdom" },
  { name: "United States of America", code: "United States of America" },
  { name: "Uruguay", code: "Uruguay" },
  { name: "Uzbekistan", code: "Uzbekistan" },
  { name: "Vanuatu", code: "Vanuatu" },
  { name: "Venezuela", code: "Venezuela" },
  { name: "Vietnam", code: "Vietnam" },
  { name: "West Africa Region", code: "West Africa Region" },
  { name: "West Bank/Gaza", code: "West Bank/Gaza" },
  { name: "Western (Spanish) Sahara", code: "Western (Spanish) Sahara" },
  { name: "Western Hemisphere Region", code: "Western Hemisphere Region" },
  { name: "World", code: "World" },
  { name: "Yemen", code: "Yemen" },
  { name: "Zambia", code: "Zambia" },
  { name: "Zimbabwe", code: "Zimbabwe" },
  {
    name: "ACQUISITIONS -  DIPLOMATIC SECURITY",
    code: "ACQUISITIONS -  DIPLOMATIC SECURITY",
  },
  { name: "ACQUISITIONS - AQM MOMENTUM", code: "ACQUISITIONS - AQM MOMENTUM" },
  { name: "ACQUISITIONS - ELSO ANTWERP", code: "ACQUISITIONS - ELSO ANTWERP" },
  { name: "ACQUISITIONS - FSI", code: "ACQUISITIONS - FSI" },
  { name: "ACQUISITIONS - INL", code: "ACQUISITIONS - INL" },
  {
    name: "ACQUISITIONS - RPSO FRANKFURT",
    code: "ACQUISITIONS - RPSO FRANKFURT",
  },
  {
    name: "ACQUISITIONS - US MISSION TO THE UN",
    code: "ACQUISITIONS - US MISSION TO THE UN",
  },
  {
    name: "Agency for International Development",
    code: "USAID",
  },
  { name: "BUREAU OF AFRICAN AFFAIRS", code: "BUREAU OF AFRICAN AFFAIRS" },
  { name: "FOREIGN SERVICE INSTITUTE", code: "FOREIGN SERVICE INSTITUTE" },
  {
    name: "INTERNAT. BOUNDARY AND WATER COMM.",
    code: "INTERNAT. BOUNDARY AND WATER COMM.",
  },
  {
    name: "MILLENNIUM CHALLENGE CORPORATION",
    code: "MILLENNIUM CHALLENGE CORPORATION",
  },
  { name: "OFFICE OF LANGUAGE SERVICES", code: "OFFICE OF LANGUAGE SERVICES" },
  { name: "RALPH J BUNCHE LIBRARY", code: "RALPH J BUNCHE LIBRARY" },
  { name: "USAID A/AID", code: "USAID A/AID" },
  { name: "USAID EGEE", code: "USAID EGEE" },
];

export const technicalSectors = [
  {
    name: "Agriculture and Food Security",
    code: "Agriculture and Food Security",
  },
  {
    name: "Democracy, Human Rights, and Governance",
    code: "Democracy, Human Rights, and Governance",
  },
  { name: "Community Development", code: "Community Development" },
  { name: "Regional Revelopment", code: "Regional Revelopment" },
  {
    name: "Employment, Labor and Training",
    code: "Employment, Labor and Training",
  },
  {
    name: "Income Security and Social Services",
    code: "Income Security and Social Services",
  },
  { name: "Housing", code: "Housing" },
  {
    name: "Law, Justice and Legal Services ",
    code: "Law, Justice and Legal Services ",
  },
  { name: "Economic Growth and Trade", code: "Economic Growth and Trade" },
  { name: "Business and Commerce", code: "Business and Commerce" },
  { name: "Consumer Protection", code: "Consumer Protection" },
  { name: "Education", code: "Education" },
  { name: "Ending Extreme Poverty", code: "Ending Extreme Poverty" },
  {
    name: "Environment and Global Climate Change",
    code: "Environment and Global Climate Change",
  },
  { name: "Natural Resources", code: "Natural Resources" },
  { name: "Energy", code: "Energy" },
  {
    name: "Gender Equality and Women's Empowerment",
    code: "Gender Equality and Women's Empowerment",
  },
  { name: "Global Health", code: "Global Health" },
  {
    name: "HIV/AIDS (More than 50% funded by PEPFAR)",
    code: "HIV/AIDS (More than 50% funded by PEPFAR)",
  },
  { name: "Management", code: "Management" },
  { name: "U.S. Global Development Lab", code: "U.S. Global Development Lab" },
  { name: "Water and Sanitation", code: "Water and Sanitation" },
  {
    name: "Working in Crises and Conflict",
    code: "Working in Crises and Conflict",
  },
  {
    name: "Disaster Prevention and Relief",
    code: "Disaster Prevention and Relief",
  },
  { name: "Food and Nutrition", code: "Food and Nutrition" },
  { name: "Humanities", code: "Humanities" },
  { name: "Information and Statistics", code: "Information and Statistics" },
  {
    name: "Science and Technology and other Research and Development",
    code: "Science and Technology and other Research and Development",
  },
  { name: "Other", code: "Other" },
];
