export const washingtonCountryName = "America/Washington, D.C.";
export const newyorkCountryName = "America/New_York";
export const allTimeZones = [
  {
    country: "Africa/Abidjan",
    utc: "UTC+0",
  },
  {
    country: "Africa/Accra",
    utc: "UTC+0",
  },
  {
    country: "Africa/Algiers",
    utc: "UTC+1",
  },
  {
    country: "Africa/Bissau",
    utc: "UTC+0",
  },
  {
    country: "Africa/Cairo",
    utc: "UTC+2",
  },
  {
    country: "Africa/Casablanca",
    utc: "UTC+0",
  },
  {
    country: "Africa/Ceuta",
    utc: "UTC+1",
  },
  {
    country: "Africa/El_Aaiun",
    utc: "UTC+0",
  },
  {
    country: "Africa/Johannesburg",
    utc: "UTC+2",
  },
  {
    country: "Africa/Juba",
    utc: "UTC+2",
  },
  {
    country: "Africa/Khartoum",
    utc: "UTC+2",
  },
  {
    country: "Africa/Lagos",
    utc: "UTC+1",
  },
  {
    country: "Africa/Maputo",
    utc: "UTC+2",
  },
  {
    country: "Africa/Monrovia",
    utc: "UTC+0",
  },
  {
    country: "Africa/Nairobi",
    utc: "UTC+3",
  },
  {
    country: "Africa/Ndjamena",
    utc: "UTC+1",
  },
  {
    country: "Africa/Sao_Tome",
    utc: "UTC+0",
  },
  {
    country: "Africa/Tripoli",
    utc: "UTC+2",
  },
  {
    country: "Africa/Tunis",
    utc: "UTC+1",
  },
  {
    country: "Africa/Windhoek",
    utc: "UTC+1",
  },
  {
    country: "America/Adak",
    utc: "UTC-10",
  },
  {
    country: "America/Anchorage",
    utc: "UTC-9",
  },
  {
    country: "America/Araguaina",
    utc: "UTC-3",
  },
  {
    country: "America/Argentina/Buenos_Aires",
    utc: "UTC-3",
  },
  {
    country: "America/Argentina/Catamarca",
    utc: "UTC-3",
  },
  {
    country: "America/Argentina/Cordoba",
    utc: "UTC-3",
  },
  {
    country: "America/Argentina/Jujuy",
    utc: "UTC-3",
  },
  {
    country: "America/Argentina/La_Rioja",
    utc: "UTC-3",
  },
  {
    country: "America/Argentina/Mendoza",
    utc: "UTC-3",
  },
  {
    country: "America/Argentina/Rio_Gallegos",
    utc: "UTC-3",
  },
  {
    country: "America/Argentina/Salta",
    utc: "UTC-3",
  },
  {
    country: "America/Argentina/San_Juan",
    utc: "UTC-3",
  },
  {
    country: "America/Argentina/San_Luis",
    utc: "UTC-3",
  },
  {
    country: "America/Argentina/Tucuman",
    utc: "UTC-3",
  },
  {
    country: "America/Argentina/Ushuaia",
    utc: "UTC-3",
  },
  {
    country: "America/Asuncion",
    utc: "UTC-4",
  },
  {
    country: "America/Atikokan",
    utc: "UTC-5",
  },
  {
    country: "America/Bahia",
    utc: "UTC-3",
  },
  {
    country: "America/Bahia_Banderas",
    utc: "UTC-6",
  },
  {
    country: "America/Barbados",
    utc: "UTC-4",
  },
  {
    country: "America/Belem",
    utc: "UTC-3",
  },
  {
    country: "America/Belize",
    utc: "UTC-6",
  },
  {
    country: "America/Blanc-Sablon",
    utc: "UTC-4",
  },
  {
    country: "America/Boa_Vista",
    utc: "UTC-4",
  },
  {
    country: "America/Bogota",
    utc: "UTC-5",
  },
  {
    country: "America/Boise",
    utc: "UTC-7",
  },
  {
    country: "America/Cambridge_Bay",
    utc: "UTC-7",
  },
  {
    country: "America/Campo_Grande",
    utc: "UTC-4",
  },
  {
    country: "America/Cancun",
    utc: "UTC-5",
  },
  {
    country: "America/Caracas",
    utc: "UTC-4",
  },
  {
    country: "America/Cayenne",
    utc: "UTC-3",
  },
  {
    country: "America/Chicago",
    utc: "UTC-6",
  },
  {
    country: "America/Chihuahua",
    utc: "UTC-7",
  },
  {
    country: "America/Costa_Rica",
    utc: "UTC-6",
  },
  {
    country: "America/Creston",
    utc: "UTC-7",
  },
  {
    country: "America/Cuiaba",
    utc: "UTC-4",
  },
  {
    country: "America/Curacao",
    utc: "UTC-4",
  },
  {
    country: "America/Danmarkshavn",
    utc: "UTC+0",
  },
  {
    country: "America/Dawson",
    utc: "UTC-7",
  },
  {
    country: "America/Dawson_Creek",
    utc: "UTC-7",
  },
  {
    country: "America/Denver",
    utc: "UTC-7",
  },
  {
    country: "America/Detroit",
    utc: "UTC-5",
  },
  {
    country: "America/Edmonton",
    utc: "UTC-7",
  },
  {
    country: "America/Eirunepe",
    utc: "UTC-5",
  },
  {
    country: "America/El_Salvador",
    utc: "UTC-6",
  },
  {
    country: "America/Fort_Nelson",
    utc: "UTC-7",
  },
  {
    country: "America/Fortaleza",
    utc: "UTC-3",
  },
  {
    country: "America/Glace_Bay",
    utc: "UTC-3",
  },
  {
    country: "America/Godthab",
    utc: "UTC-3",
  },
  {
    country: "America/Goose_Bay",
    utc: "UTC-4",
  },
  {
    country: "America/Grand_Turk",
    utc: "UTC-5",
  },
  {
    country: "America/Guatemala",
    utc: "UTC-6",
  },
  {
    country: "America/Guayaquil",
    utc: "UTC-5",
  },
  {
    country: "America/Guyana",
    utc: "UTC-4",
  },
  {
    country: "America/Halifax",
    utc: "UTC-4",
  },
  {
    country: "America/Havana",
    utc: "UTC-5",
  },
  {
    country: "America/Hermosillo",
    utc: "UTC-7",
  },
  {
    country: "America/Indiana/Indianapolis",
    utc: "UTC-5",
  },
  {
    country: "America/Indiana/Knox",
    utc: "UTC-6",
  },
  {
    country: "America/Indiana/Marengo",
    utc: "UTC-5",
  },
  {
    country: "America/Indiana/Petersburg",
    utc: "UTC-5",
  },
  {
    country: "America/Indiana/Tell_City",
    utc: "UTC-6",
  },
  {
    country: "America/Indiana/Vevay",
    utc: "UTC-5",
  },
  {
    country: "America/Indiana/Vincennes",
    utc: "UTC-5",
  },
  {
    country: "America/Indiana/Winamac",
    utc: "UTC-5",
  },
  {
    country: "America/Inuvik",
    utc: "UTC-7",
  },
  {
    country: "America/Iqaluit",
    utc: "UTC-5",
  },
  {
    country: "America/Jamaica",
    utc: "UTC-5",
  },
  {
    country: "America/Juneau",
    utc: "UTC-9",
  },
  {
    country: "America/Kentucky/Louisville",
    utc: "UTC-5",
  },
  {
    country: "America/Kentucky/Monticello",
    utc: "UTC-5",
  },
  {
    country: "America/La_Paz",
    utc: "UTC-4",
  },
  {
    country: "America/Lima",
    utc: "UTC-5",
  },
  {
    country: "America/Los_Angeles",
    utc: "UTC-8",
  },
  {
    country: "America/Maceio",
    utc: "UTC-3",
  },
  {
    country: "America/Managua",
    utc: "UTC-6",
  },
  {
    country: "America/Manaus",
    utc: "UTC-4",
  },
  {
    country: "America/Martinique",
    utc: "UTC-4",
  },
  {
    country: "America/Matamoros",
    utc: "UTC-6",
  },
  {
    country: "America/Mazatlan",
    utc: "UTC-7",
  },
  {
    country: "America/Menominee",
    utc: "UTC-6",
  },
  {
    country: "America/Merida",
    utc: "UTC-6",
  },
  {
    country: "America/Metlakatla",
    utc: "UTC-9",
  },
  {
    country: "America/Mexico_City",
    utc: "UTC-6",
  },
  {
    country: "America/Miquelon",
    utc: "UTC-3",
  },
  {
    country: "America/Moncton",
    utc: "UTC-4",
  },
  {
    country: "America/Monterrey",
    utc: "UTC-6",
  },
  {
    country: "America/Montevideo",
    utc: "UTC-3",
  },
  {
    country: "America/Nassau",
    utc: "UTC-5",
  },
  {
    country: "America/Nipigon",
    utc: "UTC-5",
  },
  {
    country: "America/Nome",
    utc: "UTC-9",
  },
  {
    country: "America/Noronha",
    utc: "UTC-2",
  },
  {
    country: "America/North_Dakota/Beulah",
    utc: "UTC-6",
  },
  {
    country: "America/North_Dakota/Center",
    utc: "UTC-6",
  },
  {
    country: "America/North_Dakota/New_Salem",
    utc: "UTC-6",
  },
  {
    country: "America/Ojinaga",
    utc: "UTC-7",
  },
  {
    country: "America/Panama",
    utc: "UTC-5",
  },
  {
    country: "America/Pangnirtung",
    utc: "UTC-5",
  },
  {
    country: "America/Paramaribo",
    utc: "UTC-3",
  },
  {
    country: "America/Phoenix",
    utc: "UTC-7",
  },
  {
    country: "America/Port_of_Spain",
    utc: "UTC-4",
  },
  {
    country: "America/Port-au-Prince",
    utc: "UTC-5",
  },
  {
    country: "America/Porto_Velho",
    utc: "UTC-4",
  },
  {
    country: "America/Puerto_Rico",
    utc: "UTC-4",
  },
  {
    country: "America/Punta_Arenas",
    utc: "UTC-3",
  },
  {
    country: "America/Rainy_River",
    utc: "UTC-6",
  },
  {
    country: "America/Rankin_Inlet",
    utc: "UTC-5",
  },
  {
    country: "America/Recife",
    utc: "UTC-3",
  },
  {
    country: "America/Regina",
    utc: "UTC-6",
  },
  {
    country: "America/Resolute",
    utc: "UTC-6",
  },
  {
    country: "America/Rio_Branco",
    utc: "UTC-5",
  },
  {
    country: "America/Santarem",
    utc: "UTC-3",
  },
  {
    country: "America/Santiago",
    utc: "UTC-4",
  },
  {
    country: "America/Santo_Domingo",
    utc: "UTC-4",
  },
  {
    country: "America/Sao_Paulo",
    utc: "UTC-3",
  },
  {
    country: "America/Scoresbysund",
    utc: "UTC-1",
  },
  {
    country: "America/Sitka",
    utc: "UTC-9",
  },
  {
    country: "America/St_Johns",
    utc: "UTC-3:30",
  },
  {
    country: "America/Swift_Current",
    utc: "UTC-6",
  },
  {
    country: "America/Tegucigalpa",
    utc: "UTC-6",
  },
  {
    country: "America/Thule",
    utc: "UTC-4",
  },
  {
    country: "America/Thunder_Bay",
    utc: "UTC-5",
  },
  {
    country: "America/Tijuana",
    utc: "UTC-8",
  },
  {
    country: "America/Toronto",
    utc: "UTC-5",
  },
  {
    country: "America/Vancouver",
    utc: "UTC-8",
  },
  {
    country: washingtonCountryName,
    utc: "UTC-5",
  },
  {
    country: "America/Whitehorse",
    utc: "UTC-7",
  },
  {
    country: "America/Winnipeg",
    utc: "UTC-6",
  },
  {
    country: "America/Yakutat",
    utc: "UTC-9",
  },
  {
    country: "America/Yellowknife",
    utc: "UTC-7",
  },
  {
    country: "Antarctica/Casey",
    utc: "UTC+11",
  },
  {
    country: "Antarctica/Davis",
    utc: "UTC+7",
  },
  {
    country: "Antarctica/DumontDUrville",
    utc: "UTC+10",
  },
  {
    country: "Antarctica/Macquarie",
    utc: "UTC+10",
  },
  {
    country: "Antarctica/Mawson",
    utc: "UTC+5",
  },
  {
    country: "Antarctica/Palmer",
    utc: "UTC-3",
  },
  {
    country: "Antarctica/Rothera",
    utc: "UTC-3",
  },
  {
    country: "Antarctica/Syowa",
    utc: "UTC+3",
  },
  {
    country: "Antarctica/Troll",
    utc: "UTC+0",
  },
  {
    country: "Antarctica/Vostok",
    utc: "UTC+6",
  },
  {
    country: "Asia/Almaty",
    utc: "UTC+6",
  },
  {
    country: "Asia/Amman",
    utc: "UTC+2",
  },
  {
    country: "Asia/Anadyr",
    utc: "UTC+12",
  },
  {
    country: "Asia/Aqtau",
    utc: "UTC+5",
  },
  {
    country: "Asia/Aqtobe",
    utc: "UTC+5",
  },
  {
    country: "Asia/Ashgabat",
    utc: "UTC+5",
  },
  {
    country: "Asia/Atyrau",
    utc: "UTC+5",
  },
  {
    country: "Asia/Baghdad",
    utc: "UTC+3",
  },
  {
    country: "Asia/Baku",
    utc: "UTC+4",
  },
  {
    country: "Asia/Bangkok",
    utc: "UTC+7",
  },
  {
    country: "Asia/Barnaul",
    utc: "UTC+7",
  },
  {
    country: "Asia/Beirut",
    utc: "UTC+2",
  },
  {
    country: "Asia/Bishkek",
    utc: "UTC+6",
  },
  {
    country: "Asia/Brunei",
    utc: "UTC+8",
  },
  {
    country: "Asia/Chita",
    utc: "UTC+9",
  },
  {
    country: "Asia/Choibalsan",
    utc: "UTC+8",
  },
  {
    country: "Asia/Colombo",
    utc: "UTC+5:30",
  },
  {
    country: "Asia/Damascus",
    utc: "UTC+2",
  },
  {
    country: "Asia/Dhaka",
    utc: "UTC+6",
  },
  {
    country: "Asia/Dili",
    utc: "UTC+9",
  },
  {
    country: "Asia/Dubai",
    utc: "UTC+4",
  },
  {
    country: "Asia/Dushanbe",
    utc: "UTC+5",
  },
  {
    country: "Asia/Famagusta",
    utc: "UTC+2",
  },
  {
    country: "Asia/Gaza",
    utc: "UTC+2",
  },
  {
    country: "Asia/Hebron",
    utc: "UTC+2",
  },
  {
    country: "Asia/Ho_Chi_Minh",
    utc: "UTC+7",
  },
  {
    country: "Asia/Hong_Kong",
    utc: "UTC+8",
  },
  {
    country: "Asia/Hovd",
    utc: "UTC+7",
  },
  {
    country: "Asia/Irkutsk",
    utc: "UTC+8",
  },
  {
    country: "Asia/Jakarta",
    utc: "UTC+7",
  },
  {
    country: "Asia/Jayapura",
    utc: "UTC+9",
  },
  {
    country: "Asia/Jerusalem",
    utc: "UTC+2",
  },
  {
    country: "Asia/Kabul",
    utc: "UTC+4:30",
  },
  {
    country: "Asia/Kamchatka",
    utc: "UTC+12",
  },
  {
    country: "Asia/Karachi",
    utc: "UTC+5",
  },
  {
    country: "Asia/Kathmandu",
    utc: "UTC+5:45",
  },
  {
    country: "Asia/Khandyga",
    utc: "UTC+9",
  },
  {
    country: "Asia/Kolkata",
    utc: "UTC+5:30",
  },
  {
    country: "Asia/Krasnoyarsk",
    utc: "UTC+7",
  },
  {
    country: "Asia/Kuala_Lumpur",
    utc: "UTC+8",
  },
  {
    country: "Asia/Kuching",
    utc: "UTC+8",
  },
  {
    country: "Asia/Macau",
    utc: "UTC+8",
  },
  {
    country: "Asia/Magadan",
    utc: "UTC+11",
  },
  {
    country: "Asia/Makassar",
    utc: "UTC+8",
  },
  {
    country: "Asia/Manila",
    utc: "UTC+8",
  },
  {
    country: "Asia/Nicosia",
    utc: "UTC+2",
  },
  {
    country: "Asia/Novokuznetsk",
    utc: "UTC+7",
  },
  {
    country: "Asia/Novosibirsk",
    utc: "UTC+7",
  },
  {
    country: "Asia/Omsk",
    utc: "UTC+6",
  },
  {
    country: "Asia/Oral",
    utc: "UTC+5",
  },
  {
    country: "Asia/Pontianak",
    utc: "UTC+7",
  },
  {
    country: "Asia/Pyongyang",
    utc: "UTC+9",
  },
  {
    country: "Asia/Qatar",
    utc: "UTC+3",
  },
  {
    country: "Asia/Qostanay",
    utc: "UTC+6",
  },
  {
    country: "Asia/Qyzylorda",
    utc: "UTC+5",
  },
  {
    country: "Asia/Riyadh",
    utc: "UTC+3",
  },
  {
    country: "Asia/Sakhalin",
    utc: "UTC+11",
  },
  {
    country: "Asia/Samarkand",
    utc: "UTC+5",
  },
  {
    country: "Asia/Seoul",
    utc: "UTC+9",
  },
  {
    country: "Asia/Shanghai",
    utc: "UTC+8",
  },
  {
    country: "Asia/Singapore",
    utc: "UTC+8",
  },
  {
    country: "Asia/Srednekolymsk",
    utc: "UTC+11",
  },
  {
    country: "Asia/Taipei",
    utc: "UTC+8",
  },
  {
    country: "Asia/Tashkent",
    utc: "UTC+5",
  },
  {
    country: "Asia/Tbilisi",
    utc: "UTC+4",
  },
  {
    country: "Asia/Tehran",
    utc: "UTC+3:30",
  },
  {
    country: "Asia/Thimphu",
    utc: "UTC+6",
  },
  {
    country: "Asia/Tokyo",
    utc: "UTC+9",
  },
  {
    country: "Asia/Tomsk",
    utc: "UTC+7",
  },
  {
    country: "Asia/Ulaanbaatar",
    utc: "UTC+8",
  },
  {
    country: "Asia/Urumqi",
    utc: "UTC+6",
  },
  {
    country: "Asia/Ust-Nera",
    utc: "UTC+10",
  },
  {
    country: "Asia/Vladivostok",
    utc: "UTC+10",
  },
  {
    country: "Asia/Yakutsk",
    utc: "UTC+9",
  },
  {
    country: "Asia/Yangon",
    utc: "UTC+6:30",
  },
  {
    country: "Asia/Yekaterinburg",
    utc: "UTC+5",
  },
  {
    country: "Asia/Yerevan",
    utc: "UTC+4",
  },
  {
    country: "Atlantic/Azores",
    utc: "UTC-1",
  },
  {
    country: "Atlantic/Bermuda",
    utc: "UTC-4",
  },
  {
    country: "Atlantic/Canary",
    utc: "UTC+0",
  },
  {
    country: "Atlantic/Cape_Verde",
    utc: "UTC-1",
  },
  {
    country: "Atlantic/Faroe",
    utc: "UTC+0",
  },
  {
    country: "Atlantic/Madeira",
    utc: "UTC+0",
  },
  {
    country: "Atlantic/Reykjavik",
    utc: "UTC+0",
  },
  {
    country: "Atlantic/South_Georgia",
    utc: "UTC-2",
  },
  {
    country: "Atlantic/Stanley",
    utc: "UTC-3",
  },
  {
    country: "Australia/Adelaide",
    utc: "UTC+10:30",
  },
  {
    country: "Australia/Brisbane",
    utc: "UTC+10",
  },
  {
    country: "Australia/Broken_Hill",
    utc: "UTC+9:30",
  },
  {
    country: "Australia/Currie",
    utc: "UTC+10",
  },
  {
    country: "Australia/Darwin",
    utc: "UTC+9:30",
  },
  {
    country: "Australia/Eucla",
    utc: "UTC+8:45",
  },
  {
    country: "Australia/Hobart",
    utc: "UTC+10",
  },
  {
    country: "Australia/Lindeman",
    utc: "UTC+10",
  },
  {
    country: "Australia/Lord_Howe",
    utc: "UTC+11",
  },
  {
    country: "Australia/Melbourne",
    utc: "UTC+10",
  },
  {
    country: "Australia/Perth",
    utc: "UTC+8",
  },
  {
    country: "Australia/Sydney",
    utc: "UTC+11",
  },
  {
    country: "Europe/Amsterdam",
    utc: "UTC+1",
  },
  {
    country: "Europe/Andorra",
    utc: "UTC+1",
  },
  {
    country: "Europe/Astrakhan",
    utc: "UTC+4",
  },
  {
    country: "Europe/Athens",
    utc: "UTC+2",
  },
  {
    country: "Europe/Belgrade",
    utc: "UTC+1",
  },
  {
    country: "Europe/Berlin",
    utc: "UTC+1",
  },
  {
    country: "Europe/Brussels",
    utc: "UTC+1",
  },
  {
    country: "Europe/Bucharest",
    utc: "UTC+2",
  },
  {
    country: "Europe/Budapest",
    utc: "UTC+1",
  },
  {
    country: "Europe/Chisinau",
    utc: "UTC+2",
  },
  {
    country: "Europe/Copenhagen",
    utc: "UTC+1",
  },
  {
    country: "Europe/Dublin",
    utc: "UTC+0",
  },
  {
    country: "Europe/Gibraltar",
    utc: "UTC+1",
  },
  {
    country: "Europe/Helsinki",
    utc: "UTC+2",
  },
  {
    country: "Europe/Istanbul",
    utc: "UTC+3",
  },
  {
    country: "Europe/Kaliningrad",
    utc: "UTC+2",
  },
  {
    country: "Europe/Kiev",
    utc: "UTC+2",
  },
  {
    country: "Europe/Kirov",
    utc: "UTC+3",
  },
  {
    country: "Europe/Lisbon",
    utc: "UTC+0",
  },
  {
    country: "Europe/London",
    utc: "UTC+0",
  },
  {
    country: "Europe/Luxembourg",
    utc: "UTC+1",
  },
  {
    country: "Europe/Madrid",
    utc: "UTC+1",
  },
  {
    country: "Europe/Malta",
    utc: "UTC+1",
  },
  {
    country: "Europe/Minsk",
    utc: "UTC+3",
  },
  {
    country: "Europe/Monaco",
    utc: "UTC+1",
  },
  {
    country: "Europe/Moscow",
    utc: "UTC+3",
  },
  {
    country: "Europe/Oslo",
    utc: "UTC+1",
  },
  {
    country: "Europe/Paris",
    utc: "UTC+1",
  },
  {
    country: "Europe/Prague",
    utc: "UTC+1",
  },
  {
    country: "Europe/Riga",
    utc: "UTC+2",
  },
  {
    country: "Europe/Rome",
    utc: "UTC+1",
  },
  {
    country: "Europe/Samara",
    utc: "UTC+4",
  },
  {
    country: "Europe/Saratov",
    utc: "UTC+4",
  },
  {
    country: "Europe/Simferopol",
    utc: "UTC+3",
  },
  {
    country: "Europe/Sofia",
    utc: "UTC+2",
  },
  {
    country: "Europe/Stockholm",
    utc: "UTC+1",
  },
  {
    country: "Europe/Tallinn",
    utc: "UTC+2",
  },
  {
    country: "Europe/Tirane",
    utc: "UTC+1",
  },
  {
    country: "Europe/Ulyanovsk",
    utc: "UTC+4",
  },
  {
    country: "Europe/Uzhgorod",
    utc: "UTC+2",
  },
  {
    country: "Europe/Vienna",
    utc: "UTC+1",
  },
  {
    country: "Europe/Vilnius",
    utc: "UTC+2",
  },
  {
    country: "Europe/Volgograd",
    utc: "UTC+3",
  },
  {
    country: "Europe/Warsaw",
    utc: "UTC+1",
  },
  {
    country: "Europe/Zaporozhye",
    utc: "UTC+2",
  },
  {
    country: "Europe/Zurich",
    utc: "UTC+1",
  },
  {
    country: "Indian/Chagos",
    utc: "UTC+6",
  },
  {
    country: "Indian/Christmas",
    utc: "UTC+7",
  },
  {
    country: "Indian/Cocos",
    utc: "UTC+6:30",
  },
  {
    country: "Indian/Kerguelen",
    utc: "UTC+5",
  },
  {
    country: "Indian/Mahe",
    utc: "UTC+4",
  },
  {
    country: "Indian/Maldives",
    utc: "UTC+5",
  },
  {
    country: "Indian/Mauritius",
    utc: "UTC+4",
  },
  {
    country: "Indian/Reunion",
    utc: "UTC+4",
  },
  {
    country: "Pacific/Apia",
    utc: "UTC+13",
  },
  {
    country: "Pacific/Auckland",
    utc: "UTC+12",
  },
  {
    country: "Pacific/Bougainville",
    utc: "UTC+11",
  },
  {
    country: "Pacific/Chatham",
    utc: "UTC+12:45",
  },
  {
    country: "Pacific/Chuuk",
    utc: "UTC+10",
  },
  {
    country: "Pacific/Easter",
    utc: "UTC-6",
  },
  {
    country: "Pacific/Efate",
    utc: "UTC+11",
  },
  {
    country: "Pacific/Enderbury",
    utc: "UTC+13",
  },
  {
    country: "Pacific/Fakaofo",
    utc: "UTC+13",
  },
  {
    country: "Pacific/Fiji",
    utc: "UTC+12",
  },
  {
    country: "Pacific/Funafuti",
    utc: "UTC+12",
  },
  {
    country: "Pacific/Galapagos",
    utc: "UTC-6",
  },
  {
    country: "Pacific/Gambier",
    utc: "UTC-9",
  },
  {
    country: "Pacific/Guadalcanal",
    utc: "UTC+11",
  },
  {
    country: "Pacific/Guam",
    utc: "UTC+10",
  },
  {
    country: "Pacific/Honolulu",
    utc: "UTC-10",
  },
  {
    country: "Pacific/Kiritimati",
    utc: "UTC+14",
  },
  {
    country: "Pacific/Kosrae",
    utc: "UTC+11",
  },
  {
    country: "Pacific/Kwajalein",
    utc: "UTC+12",
  },
  {
    country: "Pacific/Majuro",
    utc: "UTC+12",
  },
  {
    country: "Pacific/Marquesas",
    utc: "UTC-9:30",
  },
  {
    country: "Pacific/Nauru",
    utc: "UTC+12",
  },
  {
    country: "Pacific/Niue",
    utc: "UTC-11",
  },
  {
    country: "Pacific/Norfolk",
    utc: "UTC+11",
  },
  {
    country: "Pacific/Noumea",
    utc: "UTC+11",
  },
  {
    country: "Pacific/Pago_Pago",
    utc: "UTC-11",
  },
  {
    country: "Pacific/Palau",
    utc: "UTC+9",
  },
  {
    country: "Pacific/Pitcairn",
    utc: "UTC-8",
  },
  {
    country: "Pacific/Pohnpei",
    utc: "UTC+11",
  },
  {
    country: "Pacific/Port_Moresby",
    utc: "UTC+10",
  },
  {
    country: "Pacific/Rarotonga",
    utc: "UTC-10",
  },
  {
    country: "Pacific/Tahiti",
    utc: "UTC-10",
  },
  {
    country: "Pacific/Tarawa",
    utc: "UTC+12",
  },
  {
    country: "Pacific/Tongatapu",
    utc: "UTC+13",
  },
  {
    country: "Pacific/Wake",
    utc: "UTC+12",
  },
  {
    country: "Pacific/Wallis",
    utc: "UTC+12",
  },
];

export const getTimeZoneDateAsString = (date: Date, tz: string) => {
  return date.toLocaleString("en-US", {
    timeZone: `${tz}`,
  });
};

export const getLocalTimeZone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;
