import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./USAidDetails.css";
import { currencyFormatterNoDecimals } from "../USAid";
import { useGridStyles } from "../../../hooks/useGridStyles";
import { addMonths, format, formatDistance, isBefore, isToday } from "date-fns";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { safeParseDateWithTimezone } from "../../../utils/conversion/date-converters";
import axios from "axios";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../../configs/axios-export.custom";
import UserContext from "../../../services/UserContext";
import { ProgressSpinner } from "primereact/progressspinner";
import { fetchDataNewEnvironment } from "../../../services/dropdownOptionListProvider";

export interface RecordDto {
  isMyProject?: boolean;
  isForeignAssistance?: boolean;
  country?: string;
  uniqueAwardId?: string; // used in url
  projectNumber?: string; // ID
  projectName?: string;
  projectDescription?: string;
  implementer?: string;
  subawardRecipients: string;
  awardMechanism?: string;
  awardingAgencyName?: string;
  awardingOffice?: string;
  parentIDVAgencyName?: string;
  naicsCode?: string;
  startDate: string | null;
  currentEndDate: string | null;
  potentialEndDate: string | null;
  obligatedAmount?: number | null;
  currentAmount?: number | null;
  potentialAmount?: number | null;
  recipientAddress?: string;
  createdOnUtc: string;
  setAside?: string;
  id?: number;
}

const USAidDetails: React.FC = () => {
  const styles = useGridStyles();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const context = React.useContext(UserContext);

  const [record, setRecord] = React.useState<RecordDto>({
    awardMechanism: "",
    awardingAgencyName: "",
    awardingOffice: "",
    country: "",
    createdOnUtc: "",
    currentAmount: undefined,
    currentEndDate: null,
    implementer: "",
    isForeignAssistance: false,
    isMyProject: false,
    naicsCode: "",
    obligatedAmount: undefined,
    parentIDVAgencyName: "",
    potentialAmount: undefined,
    potentialEndDate: null,
    projectDescription: "",
    projectName: "",
    projectNumber: "",
    recipientAddress: "",
    setAside: "",
    startDate: null,
    subawardRecipients: "",
    uniqueAwardId: "",
  });

  const [mechanismsOptions, setMechanismsOptions] = React.useState(
    Array<{ key: string; value: string }>(),
  );
  const documentRef = React.useRef<HTMLDivElement>(null);

  const id = params.get("id") ?? "";
  const projectNumber = params.get("projectNumber") ?? "";
  const isForeignAssistance = JSON.parse(params.get("fa") ?? "");

  let apiUrl = "/api/usaspending/dropdownoptions/";
  let mechanismsUrl = apiUrl + "mechanism";
  let baseMessage = "error fetching available ";
  let mechanismsMessage = baseMessage + "mechanisms";

  React.useEffect(() => {
    if ((id || projectNumber) && isForeignAssistance !== null) {
      const url = isForeignAssistance
        ? "/api/ForeignAssistance"
        : "/api/usaspending";
      const request = createAuthenticatedRequest(context);

      // ?projectNumber=SPMWRA09GR052&fundingAccount=&pageIndex=0&pageSize=20&sortField=UpdatedOnUtc&sortOrder=-1

      axios
        .get(
          getFullUrl(
            `${url}?${
              id ? `id=${id}` : `projectNumber=${projectNumber}`
            }&pageIndex=0&pageSize=20&sortField=${
              isForeignAssistance ? "UpdatedOnUtc" : "lastUpdatedDate"
            }&sortOrder=-1`,
            {
              useDedicatedEnvironment: true,
            },
          ),
          request,
        )
        .then(({ data }) => {
          if (isForeignAssistance) {
            const updatedData = {
              isMyProject: data.data[0].isMyProject,
              country: data.data[0].country,
              uniqueAwardId: data.data[0].activityId.toString(),
              projectNumber: data.data[0].projectNumber,
              projectName: data.data[0].projectName,
              projectDescription: data.data[0].projectDescription,
              implementer: data.data[0].implementer,
              subawardRecipients: "",
              awardMechanism: "",
              awardingAgencyName: data.data[0].technicalSector,
              awardingOffice: "",
              parentIDVAgencyName: "",
              naicsCode: "",
              startDate: data.data[0].activityStartDate,
              currentEndDate: data.data[0].activityEndDate,
              potentialEndDate: "",
              obligatedAmount: data.data[0].obligations,
              currentAmount: data.data[0].disbursements,
              potentialAmount: data.data[0].obligations,
              recipientAddress: "",
              createdOnUtc: data.data[0].updatedOnUtc,
              setAside: "",
            };
            setRecord(updatedData);
          } else {
            setRecord(data.data[0]);
          }
        });
      fetchDataNewEnvironment(
        mechanismsUrl,
        setMechanismsOptions,
        context,
        mechanismsMessage,
        false,
        true,
      );
    }
  }, []);

  // hotfix - dates coming in with " " b/w time and offset - convert back to +
  record.startDate = safeParseDateWithTimezone(record?.startDate);
  record.currentEndDate = safeParseDateWithTimezone(record?.currentEndDate);
  record.potentialEndDate = safeParseDateWithTimezone(record?.potentialEndDate);
  record.createdOnUtc = safeParseDateWithTimezone(record.createdOnUtc)!;

  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const [isDownloadView, setIsDownloadView] = React.useState(false);

  const todayString = format(new Date(), "MMMM dd yyyy");
  const isOutdated =
    record.currentEndDate &&
    isBefore(new Date(record.currentEndDate), new Date());
  const isLessThanMonth =
    record.currentEndDate &&
    isBefore(new Date(record.currentEndDate), addMonths(new Date(), 1));

  const handleDownloadPdf = () => {
    const document = documentRef.current;

    if (document) {
      html2canvas(document)
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("album", "px", [
            document.clientWidth,
            document.clientHeight,
          ]);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
          pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
          pdf.save(`${record.projectName ?? "project"}.pdf`);
        })
        .finally(() => setIsDownloadView(false));
    } else {
      setIsDownloadView(false);
    }
  };

  const handleTabChange = (index: number) => {
    if (activeTabIndex !== index) {
      setActiveTabIndex(index);
    }
  };

  const getSubawardsList = (subawards: string) => {
    return subawards.split("||");
  };

  const getRemainingTimeLabel = () => {
    if (!record.currentEndDate) {
      return "N/A";
    }

    if (isToday(new Date(`${record.currentEndDate}Z`))) {
      return "Today";
    }

    if (isOutdated) {
      return "N/A";
    }

    if (isLessThanMonth) {
      return "Less than a month";
    }

    return formatDistance(new Date(), new Date(record.currentEndDate));
  };

  const getDispayForMechanism = (awardMechanism: string) => {
    const mech = mechanismsOptions
      ? mechanismsOptions.find((m) => m.key == awardMechanism)
      : null;
    return mech ? mech.value : "";
  };

  if ((id && !record.id) || (projectNumber && !record.projectNumber)) {
    return (
      <ProgressSpinner
        strokeWidth="2"
        style={{
          color: "blue",
          width: 156,
        }}
      />
    );
  }

  return (
    <div ref={documentRef} className="usaid-details-container">
      <div className="usaid-details-header">
        <div className="usaid-details-header-back">
          {!isDownloadView && (
            <Link to="/projects">
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18px"
                  height="18px"
                >
                  <path d="M 12 2.0996094 L 1 12 L 4 12 L 4 21 L 11 21 L 11 15 L 13 15 L 13 21 L 20 21 L 20 12 L 23 12 L 12 2.0996094 z M 12 4.7910156 L 18 10.191406 L 18 11 L 18 19 L 15 19 L 15 13 L 9 13 L 9 19 L 6 19 L 6 10.191406 L 12 4.7910156 z" />
                </svg>
                <span>Projects</span>
              </button>
            </Link>
          )}
          <div className="usaid-details-logo-container">
            <img
              src={require("../../../assets/AidkonektLogoLarge.png").default}
              className="usaid-details-logo"
            />
          </div>
        </div>
        <div className="spacer" />
        {!isDownloadView && (
          <div className="usaid-details-download-container">
            <button
              onClick={() => {
                setIsDownloadView(true);
                setTimeout(handleDownloadPdf, 0);
              }}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.0002 8.3335C12.8234 8.3335 12.6538 8.40373 12.5288 8.52876C12.4037 8.65378 12.3335 8.82335 12.3335 9.00016V11.6668C12.3335 11.8436 12.2633 12.0132 12.1382 12.1382C12.0132 12.2633 11.8436 12.3335 11.6668 12.3335H2.3335C2.15669 12.3335 1.98712 12.2633 1.86209 12.1382C1.73707 12.0132 1.66683 11.8436 1.66683 11.6668V9.00016C1.66683 8.82335 1.59659 8.65378 1.47157 8.52876C1.34654 8.40373 1.17697 8.3335 1.00016 8.3335C0.823352 8.3335 0.653782 8.40373 0.528758 8.52876C0.403734 8.65378 0.333496 8.82335 0.333496 9.00016V11.6668C0.333496 12.1973 0.54421 12.706 0.919283 13.081C1.29436 13.4561 1.80306 13.6668 2.3335 13.6668H11.6668C12.1973 13.6668 12.706 13.4561 13.081 13.081C13.4561 12.706 13.6668 12.1973 13.6668 11.6668V9.00016C13.6668 8.82335 13.5966 8.65378 13.4716 8.52876C13.3465 8.40373 13.177 8.3335 13.0002 8.3335ZM6.52683 9.4735C6.59023 9.53419 6.665 9.58177 6.74683 9.6135C6.82663 9.64877 6.91292 9.66698 7.00016 9.66698C7.08741 9.66698 7.1737 9.64877 7.2535 9.6135C7.33533 9.58177 7.41009 9.53419 7.4735 9.4735L10.1402 6.80683C10.2657 6.68129 10.3362 6.51103 10.3362 6.3335C10.3362 6.15596 10.2657 5.9857 10.1402 5.86016C10.0146 5.73463 9.84436 5.6641 9.66683 5.6641C9.4893 5.6641 9.31903 5.73463 9.1935 5.86016L7.66683 7.3935V1.00016C7.66683 0.823352 7.59659 0.653782 7.47157 0.528758C7.34654 0.403734 7.17697 0.333496 7.00016 0.333496C6.82335 0.333496 6.65378 0.403734 6.52876 0.528758C6.40373 0.653782 6.3335 0.823352 6.3335 1.00016V7.3935L4.80683 5.86016C4.74467 5.798 4.67088 5.7487 4.58966 5.71506C4.50845 5.68142 4.4214 5.6641 4.3335 5.6641C4.24559 5.6641 4.15854 5.68142 4.07733 5.71506C3.99612 5.7487 3.92232 5.798 3.86016 5.86016C3.798 5.92232 3.7487 5.99612 3.71506 6.07733C3.68142 6.15854 3.6641 6.24559 3.6641 6.3335C3.6641 6.4214 3.68142 6.50845 3.71506 6.58966C3.7487 6.67088 3.798 6.74467 3.86016 6.80683L6.52683 9.4735Z"
                  fill="white"
                />
              </svg>
              <span>Download PDF</span>
            </button>
          </div>
        )}
        <div className="usaid-details-header-info">
          <span className="usaid-details-label">
            Project number: <b>{record.projectNumber ?? "N/A"}</b>
          </span>
          <div className="usaid-details-name-container">
            {isOutdated ? (
              <div className="usaid-details-status closed">
                <span>Closed</span>
              </div>
            ) : (
              <div className="usaid-details-status">
                <span>Active</span>
              </div>
            )}
            <span className="usaid-details-name">
              {record.projectName ?? "N/A"}
            </span>
          </div>
          <div className="usaid-details-header-data-container">
            <div className="usaid-details-header-data-container-left-side">
              <span className="usaid-details-label">
                Implementer: <b>{record.implementer ?? "N/A"}</b>
              </span>
              {!isForeignAssistance ? (
                <span className="usaid-details-label">
                  Awarding mechanism:{" "}
                  <b>
                    {record.awardMechanism
                      ? getDispayForMechanism(record.awardMechanism)
                      : "N/A"}
                  </b>
                </span>
              ) : (
                <span className="usaid-details-label">
                  Technical sector: <b>{record.awardingAgencyName ?? "N/A"}</b>
                </span>
              )}
              <span className="usaid-details-label">
                Country: <b>{record.country ?? "N/A"}</b>
              </span>
              <span className="usaid-details-label">
                Obligated Amount:
                {record.obligatedAmount ? (
                  <b>
                    {" "}
                    {currencyFormatterNoDecimals.format(
                      Math.round(record.obligatedAmount),
                    )}
                  </b>
                ) : (
                  <b>N/A</b>
                )}
              </span>
            </div>
            <span className="usaid-details-label">
              {" "}
              Up to date as of <b>{todayString}</b>
            </span>
          </div>
        </div>
        <div className="usaid-details-tab-selector-container">
          <button
            className={`usaid-details-tab-selector ${
              activeTabIndex === 0 ? "active" : ""
            }`}
            onClick={() => handleTabChange(0)}
          >
            <span>Project Description</span>
          </button>
          {!isForeignAssistance && (
            <button
              className={`usaid-details-tab-selector ${
                activeTabIndex === 1 ? "active" : ""
              }`}
              onClick={() => handleTabChange(1)}
            >
              <span>Sub-awardees</span>
            </button>
          )}
        </div>
      </div>
      <div className="usaid-details-body">
        <div className="usaid-details-body-left-side">
          {activeTabIndex === 0 ? (
            <>
              <div className="usaid-details-body-container no-border">
                <div className="usaid-details-body-label-container">
                  <img src={styles.aidkonektIcon} />
                  <span>Project Description</span>
                </div>
                <span className="usaid-details-body-data">
                  {record.projectDescription ?? "N/A"}
                </span>
              </div>
              {!isForeignAssistance && (
                <div className="usaid-details-body-container">
                  <div className="usaid-details-body-label-container">
                    <img src={styles.aidkonektIcon} />
                    <span>Award Details</span>
                  </div>
                  <div className="usaid-details-body-data-row">
                    <span className="usaid-details-body-data">
                      Award Mechanism
                    </span>
                    <span className="usaid-details-body-data-value">
                      {record.awardMechanism
                        ? getDispayForMechanism(record.awardMechanism)
                        : "N/A"}
                    </span>
                  </div>
                  <div className="usaid-details-body-data-row">
                    <span className="usaid-details-body-data">Agency Name</span>
                    <span className="usaid-details-body-data-value">
                      {record.awardingAgencyName ?? "N/A"}
                    </span>
                  </div>
                  <div className="usaid-details-body-data-row no-border">
                    <span className="usaid-details-body-data">
                      Awarding Office
                    </span>
                    <span className="usaid-details-body-data-value">
                      {record.awardingOffice ?? "N/A"}
                    </span>
                  </div>
                </div>
              )}
              <div className="usaid-details-body-container">
                <div className="usaid-details-body-label-container">
                  <img src={styles.aidkonektIcon} />
                  <span>Implementer Details</span>
                </div>
                <div
                  className={`usaid-details-body-data-row${
                    isForeignAssistance ? " no-border" : ""
                  }`}
                >
                  <span className="usaid-details-body-data">Name</span>
                  <span className="usaid-details-body-data-value">
                    {record.implementer ?? "N/A"}
                  </span>
                </div>
                {/*{isForeignAssistance && <div className="usaid-details-body-data-row no-border">*/}
                {/*  <span className="usaid-details-body-data">Technical sector </span>*/}
                {/*  <span className="usaid-details-body-data-value">*/}
                {/*    {record.awardingAgencyName ?? "N/A"}*/}
                {/*  </span>*/}
                {/*</div>}*/}
                {!isForeignAssistance && (
                  <div className="usaid-details-body-data-row no-border">
                    <span className="usaid-details-body-data">Address</span>
                    <span className="usaid-details-body-data-value">
                      {record.recipientAddress ?? "N/A"}
                    </span>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="usaid-details-body-container">
              <div className="usaid-details-body-label-container">
                <img src={styles.aidkonektIcon} />
                <span>Subawards</span>
              </div>
              {record.subawardRecipients ? (
                getSubawardsList(record.subawardRecipients).map(
                  (recipient, index) => (
                    <div
                      className={`
                    usaid-details-body-data-row 
                    ${
                      index + 1 ===
                      getSubawardsList(record.subawardRecipients).length
                        ? "no-border"
                        : ""
                    }
                  `}
                    >
                      <span className="usaid-details-body-data">
                        {recipient}
                      </span>
                    </div>
                  ),
                )
              ) : (
                <div
                  className="
                    usaid-details-body-data-row no-border"
                >
                  <span className="usaid-details-body-data">No subawards</span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="usaid-details-body-right-side">
          <div className="usaid-details-body-container">
            <div className="project-progress-status">
              {isOutdated ? (
                <span className="closed">Closed</span>
              ) : (
                <span>Active</span>
              )}
            </div>
            <div className="usaid-details-body-label-container">
              <img src={styles.aidkonektIcon} />
              <span>Project Progress</span>
            </div>
            <div className="usaid-details-body-data-row">
              <span className="usaid-details-body-data">Remaining</span>
              <span className="usaid-details-body-data-value">
                {getRemainingTimeLabel()}
              </span>
            </div>
            <div className="usaid-details-body-data-row">
              <span className="usaid-details-body-data">Start date</span>
              <span className="usaid-details-body-data-value">
                {!!record.startDate &&
                !record.startDate.toString().includes("0001-01-01T00:00:00")
                  ? format(new Date(record.startDate), "dd/MM/yyyy")
                  : "N/A"}
              </span>
            </div>
            <div className="usaid-details-body-data-row">
              <span className="usaid-details-body-data">Current end date</span>
              <span className="usaid-details-body-data-value">
                {!!record.currentEndDate &&
                !record.currentEndDate
                  .toString()
                  .includes("0001-01-01T00:00:00")
                  ? format(new Date(record.currentEndDate), "dd/MM/yyyy")
                  : "N/A"}
              </span>
            </div>
            {!isForeignAssistance && (
              <div className="usaid-details-body-data-row">
                <span className="usaid-details-body-data">
                  Potential end date
                </span>
                <span className="usaid-details-body-data-value">
                  {record.potentialEndDate &&
                  !new Date(record.potentialEndDate)
                    .toISOString()
                    .startsWith("0")
                    ? format(new Date(record.potentialEndDate), "dd/MM/yyyy")
                    : "N/A"}
                </span>
              </div>
            )}
            <div className="spacer" />
            <div className="usaid-details-body-data-row">
              <span className="usaid-details-body-data">Obligated Amount</span>
              <span className="usaid-details-body-data-value">
                {record.obligatedAmount
                  ? currencyFormatterNoDecimals.format(record.obligatedAmount)
                  : "$0"}
              </span>
            </div>
            <div className="usaid-details-body-data-row">
              <span className="usaid-details-body-data">
                {isForeignAssistance ? "Disbursements" : "Current Amount"}
              </span>
              <span className="usaid-details-body-data-value">
                {record.currentAmount
                  ? currencyFormatterNoDecimals.format(record.currentAmount)
                  : "$0"}
              </span>
            </div>
            {!isForeignAssistance && (
              <div className="usaid-details-body-data-row no-padding">
                <span className="usaid-details-body-data">
                  Potential Amount
                </span>
                <span className="usaid-details-body-data-value">
                  {record.potentialAmount
                    ? currencyFormatterNoDecimals.format(record.potentialAmount)
                    : "$0"}
                </span>
              </div>
            )}
          </div>
          {!isForeignAssistance && (
            <div className="usaid-details-body-container">
              <div className="usaid-details-body-data-row">
                <span className="usaid-details-body-data">
                  Parent IDV Agency Name
                </span>
                <span className="usaid-details-body-data-value">
                  {record.parentIDVAgencyName ?? "N/A"}
                </span>
              </div>
              <div className="usaid-details-body-data-row">
                <span className="usaid-details-body-data">Set-Aside Type</span>
                <span className="usaid-details-body-data-value">
                  {record.setAside ?? "N/A"}
                </span>
              </div>
              <div className="usaid-details-body-data-row no-padding">
                <span className="usaid-details-body-data">NAICS Code</span>
                <span className="usaid-details-body-data-value">
                  {record.naicsCode ?? "N/A"}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="usaid-details-disclaimer">
        <span>
          <b>Disclaimer</b>
        </span>
        <span>
          This report and data is based upon public information and data
          obtained from various public sources, the accuracy of which cannot be
          guaranteed. There may be inherent contradictions among various public
          data sets. AidKonekt makes no representation or warranty as to the
          accuracy of information provided. This is only intended as an
          information resource and not an advisory document. As with any
          information resource, it is your responsibility to determine the
          quality and credibility of information obtained, as well as the
          reasonableness of relying on such information for a particular use.
        </span>
      </div>
    </div>
  );
};

export default USAidDetails;
