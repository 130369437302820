import React from "react";
import ThemeContext from "../configs/theme";
import { version } from "../../package.json";
import { useLocation } from "react-router";

function Footer() {
  const theme = React.useContext(ThemeContext);
  const location = useLocation();

  const isDashboard = location.pathname === "/";

  const style = {
    container: {
      backgroundColor: theme.v2.darkBlue,
      paddingTop: 5,
      paddingBottom: 5,
      width: "100%",
    },
    message: {
      color: theme.v2.accentFaded,
      fontFamily: theme.primaryFont,
      fontSize: "14px",
      lineHeight: "29px",
    },
    links: {
      color: theme.v2.accentFaded,
      fontFamily: theme.primaryFont,
      fontSize: "14px",
      lineHeight: "29px",
      textDecorationLine: "underline",
      textDecorationStyle: "dashed" as any,
      textDecorationColor: "black",
      textUnderlineOffset: "5px",
    },
  };

  return (
    <div style={style.container}>
      <div>
        <span style={style.message}>
          Copyright AidKonekt Data {new Date().getFullYear()} - V{version}
        </span>
      </div>
      {
        <div>
          <a
            style={style.links}
            // href="https://konektid-resources.s3.amazonaws.com/AidKonekt Terms and Conditions User Agreement_Mar 2021.pdf"
            href="https://konektid-resources.s3.amazonaws.com/AidKonekt+Data+-+Terms+and+Conditions+Policy.pdf"
            target="blank"
          >
            Terms and Conditions
          </a>
          &nbsp;&nbsp;&nbsp;
          <a
            style={style.links}
            href="https://konektid-resources.s3.amazonaws.com/AidKonekt Privacy Policy_2021.pdf"
            target="blank"
          >
            Privacy Policy
          </a>
        </div>
      }
    </div>
  );
}

export default Footer;
