import React, { FC, useContext, useEffect, useState } from "react";
import { RequestStatus } from "../../../utils/Helpers/fetchStatus";
import { Box, Chip, Divider, Typography } from "@mui/material";
import Input from "../Inputs/Input";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { ShareUser } from "../../../utils/types/Forecast";
import SimpleModal from "../../Modals/SimpleModal";
import { RootState, useDispatch, useSelector } from "../../../store";
import UserContext from "../../../services/UserContext";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { validateEmailSimple } from "../../../utils/validation/input-validation";
import { getOrganizationUsers } from "../../../slices/organization";
import { shareOpportunities } from "../../../slices/forecast";

interface ShareModalProps {
  open: boolean;
  handleClose: () => void;
  openFor: { [key: string]: any } | null;
  onSubmit: (values: any) => void;
  shareFetchStatus: string;
  type: "forecast" | "contracts" | "grants";
  explicitIdParamName?: string;
}

const defaultValues = {
  external: "",
  company: [],
  message: "",
};

const SHARE_FORM_ID = "usaid-bid-search-component-share-modal-form";

const ShareModal: FC<ShareModalProps> = (props) => {
  const {
    open,
    handleClose,
    openFor,
    onSubmit,
    shareFetchStatus,
    type,
    explicitIdParamName = undefined,
  } = props;

  const context = useContext(UserContext);
  const dispatch = useDispatch();

  const { organizationUsers, fetchStatus: organizationUsersFetchStatus } =
    useSelector((state: RootState) => state.organization);

  const { control, handleSubmit, reset, getValues, setValue } = useForm<{
    external: string;
    company: Array<{ value: string; label: string }>;
    message: string;
  }>({
    defaultValues,
  });

  const [externalShareRecipients, setExternalShareRecipients] = useState<
    Array<{ email: string }>
  >([]);

  const shareIdParameterName = explicitIdParamName
    ? explicitIdParamName
    : type === "forecast"
    ? "rowId"
    : type === "contracts"
    ? "contractOpportunityBase_Id"
    : "id";

  const handleAddExternalRecipient = (): void => {
    const externalEmail = getValues("external");
    if (
      !!externalShareRecipients.find(
        (recipient) => recipient.email === externalEmail,
      ) ||
      !!getValues("company").find(
        (recipient) => recipient.value === externalEmail,
      )
    )
      toast.error("Email already added");
    else if (validateEmailSimple(externalEmail)) {
      setExternalShareRecipients((prev) => [
        ...prev,
        { email: getValues("external") },
      ]);
      setValue("external", "");
    } else {
      toast.error("Invalid email address");
    }
  };

  const handleRemoveExternalRecipientFromList = (email: string): void => {
    setExternalShareRecipients((prev) =>
      prev.filter((recipient) => recipient.email !== email),
    );
  };

  const extendedOnSubmit = (values) => {
    if (!openFor) return;
    if (
      getValues("company")?.length === 0 &&
      externalShareRecipients?.length === 0
    ) {
      toast.error(`You haven't added / selected any recipients`);
      return;
    }
    onSubmit({
      message: values?.message?.replaceAll("\n", "<br>") ?? null,
      opportunityId: Array.isArray(openFor)
        ? openFor.map((opportunity) => parseInt(opportunity, 10))
        : openFor[shareIdParameterName],
      addresses: [
        ...getValues("company").map((recipient) => recipient.value),
        ...externalShareRecipients.map((recipient) => recipient.email),
      ],
    });
  };

  useEffect(() => {
    if (open) {
      dispatch(getOrganizationUsers(context));
    } else {
      setTimeout(() => {
        reset(defaultValues);
        setExternalShareRecipients([]);
      }, 150);
    }
  }, [open]);

  return (
    <>
      <SimpleModal
        open={open}
        handleClose={handleClose}
        acceptButtonType={"submit"}
        form={SHARE_FORM_ID}
        title={`Share ${
          Array.isArray(openFor) ? "selected opportunities" : "opportunity"
        }`}
        minWidth={"600px"}
        acceptLabel={"Share"}
        acceptButtonLoading={RequestStatus.isFetching(shareFetchStatus)}
      >
        <form
          onSubmit={handleSubmit(extendedOnSubmit)}
          id={SHARE_FORM_ID}
          style={{
            paddingTop: "16px",
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Typography variant={"h5"}>Recipients</Typography>
          <Divider
            sx={{
              mb: 2,
            }}
          />
          <Box
            sx={{
              display: "flex",
              gap: "16px",
              mb: 1,
            }}
          >
            <Input
              type={"text"}
              name={"external"}
              control={control}
              label={"External user"}
              fullWidth
            />
            <Button
              endIcon={<AddIcon />}
              variant={"contained"}
              // color={"secondary"}
              onClick={handleAddExternalRecipient}
            >
              Add
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              flexWrap: "wrap",
              mb: externalShareRecipients.length > 0 ? 2 : 1,
            }}
          >
            {externalShareRecipients.map((recipient, idx) => (
              <Chip
                key={`${recipient.email}-${idx}`}
                label={recipient.email}
                onDelete={() =>
                  handleRemoveExternalRecipientFromList(recipient.email)
                }
                sx={{
                  borderRadius: "16px !important",
                  mb: 0.5,
                  "& > svg": {
                    height: "24px",
                    width: "24px",
                  },
                }}
              />
            ))}
          </Box>
          <Input
            type={"combo-box"}
            name={"company"}
            control={control}
            label={"Company users"}
            fullWidth
            options={organizationUsers
              .filter(
                (r: ShareUser) =>
                  r.email?.toLocaleLowerCase() !==
                  context.user.parsedIdToken?.email,
              )
              .map((user: ShareUser) => ({
                value: user.email,
                label:
                  !!user.firstName && !!user.lastName
                    ? `${user.firstName} ${user.lastName}`
                    : "",
                secondaryLabel: `${user.email}`,
              }))}
            multiple
            withCheckboxes
            sx={{
              mb: 2,
            }}
          />
          <Typography variant={"h5"}>Message</Typography>
          <Divider
            sx={{
              mb: 2,
            }}
          />
          <Input
            type={"text"}
            name={"message"}
            control={control}
            // label={"Message"}
            multiline
            rows={5}
            fullWidth
          />
        </form>
      </SimpleModal>
    </>
  );
};

export default ShareModal;
