import React, { FC, useContext, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import { ContractsFileRecord } from "../../slices/contracts";
import FileListItem from "../Widgets/FileListItem";
import {
  ContractsResourceServer,
  GrantsResourceServer,
} from "../../configs/resources";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import axios from "axios";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../configs/axios-export.custom";
import { saveAs } from "file-saver";
import toast from "react-hot-toast";
import UserContext from "../../services/UserContext";
import { SourceType } from "./opportunityDetailsUtils";

interface DocumentsListProps {
  files: any[];
  id: string;
  source: SourceType;
}

interface SortOption {
  sortField: string;
  label: string;
  sortOrder: number;
}

const sortOptions: Array<SortOption> = [
  { sortField: "createdOn", label: "Most recent", sortOrder: -1 },
  { sortField: "createdOn", label: "Oldest", sortOrder: 1 },
  { sortField: "name", label: "Name: A → Z", sortOrder: 1 },
  { sortField: "name", label: "Name: Z → A", sortOrder: -1 },
  { sortField: "disable-sort", label: "Disable sorting", sortOrder: 0 },
];

const DocumentsList: FC<DocumentsListProps> = (props): JSX.Element => {
  const { files: _files, id, source } = props;
  const context = useContext(UserContext);
  const theme = useTheme();

  const files = useMemo(() => {
    return _files.map((file) => ({
      ...file,
      name: file.name ?? file.fileName,
    }));
  }, [_files]);

  const [selectedSortOption, setSelectedSortOption] = useState<SortOption>(
    sortOptions[0],
  );
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleDownloadAll = async () => {
    setIsDownloading(true);
    axios
      .post(
        getFullUrl(
          source === "contracts"
            ? `/api/contract/details/${id}/getzip`
            : `/api/grant/details/${id}/getzip`,
          {
            useDedicatedEnvironment: true,
          },
        ),
        {},
        {
          ...createAuthenticatedRequest(context),
          responseType: "blob",
        } as any,
      )
      .then(async (response) => {
        let fileName = `export-${new Date().valueOf()}.zip`;
        try {
          fileName = response.headers["content-disposition"]
            .split(";")[1]
            .replace("filename=", "")
            .replaceAll('"', "")
            .trim();
        } catch {
          /* NO-OP */
        }

        // in deployed version the response is still getting converted back
        // to b64 so need to un-load that. if running web server locally the
        // response is already decoded so can directly save response.data instead
        // of this BS
        // const decoded = atob(await response.data.text());
        // const array = new Uint8Array(decoded.length);
        // for (let i = 0; i < decoded.length; i++) {
        //   array[i] = decoded.charCodeAt(i);
        // }

        // saveAs(
        //   new Blob([array], { type: "application/zip" }),
        //   fileName
        // );

        saveAs(new Blob([response.data]), fileName);
        setIsDownloading(false);
      })
      .catch((error) => {
        toast.error("Error fetching data.");
        setIsDownloading(false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: "24px",
      }}
    >
      <>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            select
            size={"small"}
            InputLabelProps={{
              shrink: true,
            }}
            value={`${selectedSortOption.sortField}-${selectedSortOption.sortOrder}`}
            placeholder={"Select sort option"}
            sx={{
              minWidth: "250px",
            }}
            InputProps={{
              sx: { borderRadius: "8px" },
            }}
            onChange={(e) =>
              setSelectedSortOption(() => {
                const option =
                  sortOptions.find(
                    (option) =>
                      e.target.value ===
                      `${option.sortField}-${option.sortOrder}`,
                  ) ?? sortOptions[0];

                return option;
              })
            }
          >
            {sortOptions.map((option, idx) => (
              <MenuItem
                value={`${option.sortField}-${option.sortOrder}`}
                key={idx}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Button
            variant={"contained"}
            size={"small"}
            endIcon={
              <>
                {" "}
                <DownloadIcon
                  sx={{
                    height: "24px !important",
                    width: "24px !important",
                  }}
                />
                {isDownloading && (
                  <CircularProgress size={16} sx={{ color: "white" }} />
                )}
              </>
            }
            sx={{
              // height: "56px",
              borderRadius: "8px",
            }}
            onClick={handleDownloadAll}
            disabled={isDownloading || !files.length}
          >
            Download All
          </Button>
        </Box>
        <Box
          sx={{
            height: "348px",
            overflowY: "scroll",
            my: 1,
          }}
        >
          {files.length > 0 ? (
            [...files]
              .sort((a: ContractsFileRecord, b: ContractsFileRecord) =>
                selectedSortOption.sortField === "disable-sort"
                  ? 0
                  : a[selectedSortOption.sortField] >
                    b[selectedSortOption.sortField]
                  ? selectedSortOption.sortOrder
                  : -selectedSortOption.sortOrder,
              )
              .map((file, idx) => (
                <FileListItem
                  key={`file-${idx}`}
                  file={
                    source === "contracts"
                      ? {
                          ...file,
                          uri:
                            file?.uri ??
                            `${ContractsResourceServer}${encodeURIComponent(
                              file.name,
                            )}`,
                        }
                      : {
                          name: file.fileName,
                          description: file.fileDescription,
                          createdOn: file.createdOn,
                          uri: `${GrantsResourceServer}${encodeURIComponent(
                            file.fileName,
                          )}`,
                        }
                  }
                  source={source}
                  listItemFor={"profile-page"}
                />
              ))
          ) : (
            <Typography variant={"h6"} color={"textSecondary"}>
              No files found
            </Typography>
          )}
        </Box>
      </>
    </Box>
  );
};

export default DocumentsList;
