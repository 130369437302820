import React, { FC, useEffect, useState } from "react";
import { RequestStatus } from "../../../utils/Helpers/fetchStatus";
import {
  alpha,
  Box,
  Button as MuiButton,
  IconButton,
  Tooltip,
} from "@mui/material";
import Input from "../../Widgets/Inputs/Input";
import SimpleModal from "../../Modals/SimpleModal";
import AddIcon from "@mui/icons-material/Add";
import { useForm } from "react-hook-form";
import {
  createNewEvent,
  NewEventValues,
  resetCreateEventState,
  resetUpdateEventState,
  updateEvent,
} from "../../../slices/dashboard";
import { getNewDateNoTimezoneAdjustment } from "../../../utils/conversion/date-converters";
import toast from "react-hot-toast";
import { RootState, useDispatch, useSelector } from "../../../store";
import UserContext from "../../../services/UserContext";
import { sharedStyles } from "./EventsCalendar";
import { Button } from "primereact/button";
import EditIcon from "@mui/icons-material/Edit";

interface AddEditEventModalProps {
  canEditEvents: boolean;
  loadDataAsync: any;
  onlyIcon?: boolean;
  oldEditIcon?: boolean;
  edit?: {
    event: NewEventValues;
  };
}

const initialValues: NewEventValues = {
  date: getNewDateNoTimezoneAdjustment(new Date().toISOString()),
  name: "",
  url: "",
  location: "",
  organization: "",
  description: "",
};

const NEW_EVENT_FORM_ID = "NEW_EVENT_FORM_ID";

const AddEditEventModal: FC<AddEditEventModalProps> = (props) => {
  const {
    canEditEvents,
    loadDataAsync,
    onlyIcon = false,
    oldEditIcon = true,
    edit = null,
  } = props;

  const dispatch = useDispatch();
  const userContext = React.useContext(UserContext);

  const { post, put } = useSelector(
    (state: RootState) => state.dashboard.event,
  );

  const { control, handleSubmit, reset, setValue, getValues, watch } =
    useForm<NewEventValues>({
      defaultValues: initialValues,
    });

  const [newEventModalOpen, setNewEventModalOpen] = useState<boolean>(false);
  const [editOpenFor, setEditOpenFor] = useState<number | null>(null);

  const watchNewEvent = watch(["date", "name"]);

  const onSubmit = (values: NewEventValues): void => {
    dispatch(
      !edit
        ? createNewEvent({
            context: userContext,
            values,
          })
        : updateEvent({
            context: userContext,
            values,
            id: edit.event.id,
          }),
    );
  };

  const handleCancelNewEvent = (): void => {
    setNewEventModalOpen(false);
    setEditOpenFor(null);
    !edit && setTimeout(() => reset(initialValues), 150);
  };

  useEffect(() => {
    if (!edit) return;
    reset(
      {
        date: edit.event.date
          ? getNewDateNoTimezoneAdjustment(edit.event.date as string)
          : null,
        name: edit.event.name,
        organization: edit.event.organization,
        location: edit.event.location,
        url: edit.event.url,
        description: edit.event.description,
      } ?? initialValues,
    );
  }, [edit]);

  useEffect(() => {
    if (!!edit) return;
    if (RequestStatus.isDone(post.fetchStatus)) {
      toast.success("Event created!");
      handleCancelNewEvent();
      loadDataAsync();
      dispatch(resetCreateEventState());
    } else if (RequestStatus.isError(post.fetchStatus)) {
      toast.error("There was an error creating a new event.");
      dispatch(resetCreateEventState());
    }
  }, [post.fetchStatus]);

  useEffect(() => {
    if (!edit || editOpenFor !== edit.event.id) return;
    if (RequestStatus.isDone(put.fetchStatus)) {
      toast.success("Event updated!");
      handleCancelNewEvent();
      loadDataAsync();
      dispatch(resetUpdateEventState());
    } else if (RequestStatus.isError(put.fetchStatus)) {
      toast.error("There was an error updating a new event.");
      dispatch(resetUpdateEventState());
    }
  }, [put.fetchStatus]);

  return (
    <>
      <SimpleModal
        open={newEventModalOpen}
        handleClose={handleCancelNewEvent}
        title={edit ? "Edit an event" : "Post an event"}
        form={NEW_EVENT_FORM_ID}
        acceptButtonType={"submit"}
        loading={
          RequestStatus.isFetching(post.fetchStatus) ||
          RequestStatus.isFetching(put.fetchStatus)
        }
        acceptButtonDisabled={watchNewEvent.some((value) =>
          typeof value === "string" ? value?.length === 0 : !value,
        )}
        acceptButtonLoading={
          RequestStatus.isFetching(post.fetchStatus) ||
          RequestStatus.isFetching(put.fetchStatus)
        }
      >
        <Box
          component={"form"}
          id={NEW_EVENT_FORM_ID}
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            py: 1,
            "& > div": {
              my: 1,
            },
          }}
        >
          <Input
            type={"date"}
            name={"date"}
            control={control}
            label={"Date"}
            fullWidth
            requiredSign
          />
          <Input
            type={"text"}
            name={"name"}
            control={control}
            label={"Event Name"}
            fullWidth
            requiredSign
          />
          <Input
            type={"text"}
            name={"organization"}
            control={control}
            label={"Organization"}
            fullWidth
          />
          <Input
            type={"text"}
            name={"location"}
            control={control}
            label={"Location"}
            fullWidth
          />
          <Input
            type={"text"}
            name={"url"}
            control={control}
            label={"RSVP Link"}
            fullWidth
          />
          <Input
            type={"text"}
            name={"description"}
            control={control}
            label={"Description"}
            multiline
            rows={3}
            fullWidth
          />
        </Box>
      </SimpleModal>
      {canEditEvents ? (
        !edit ? (
          onlyIcon ? (
            <Tooltip title={"Add event"}>
              <IconButton
                size={"small"}
                onClick={() => {
                  setNewEventModalOpen(true);
                }}
                color={"primary"}
                sx={{
                  border: "solid 2px",
                  // backgroundColor: alpha(theme.palette.primary.main, 0.1),
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <MuiButton
              variant={"contained"}
              startIcon={<AddIcon />}
              size={"small"}
              sx={{
                px: 2,
                py: 0,
                borderRadius: "16px",
              }}
              onClick={() => {
                setNewEventModalOpen(true);
              }}
            >
              Add Event
            </MuiButton>
          )
        ) : oldEditIcon ? (
          <Button
            icon={"pi pi-pencil"}
            className="p-button-rounded"
            style={sharedStyles.iconButton}
            tooltip={"Edit Event"}
            tooltipOptions={{ position: "top" }}
            onClick={() => {
              setNewEventModalOpen(true);
              setEditOpenFor(edit.event.id);
            }}
          />
        ) : (
          <Tooltip title={"Edit event"}>
            <IconButton
              size={"small"}
              onClick={() => {
                setNewEventModalOpen(true);
                setEditOpenFor(edit.event.id);
              }}
              color={"success"}
              sx={{
                border: "solid 2px",
                // backgroundColor: alpha(theme.palette.primary.main, 0.1),
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default AddEditEventModal;
