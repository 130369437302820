import React, { FC } from "react";
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import { format } from "date-fns";
import {
  ContractsResourceServer,
  GrantsResourceServer,
} from "../../configs/resources";

interface FileListItemProps {
  file: {
    name: string;
    createdOn: string | null;
    type?: string;
    description?: string;
    uri?: string;
  };
  highlight?: {
    show: boolean;
    text?: string;
  };
  source: "forecast" | "grants" | "contracts";
  listItemFor?: "profile-page" | "table-details";
}

const FileListItem: FC<FileListItemProps> = (props) => {
  const {
    file,
    highlight = {
      show: false,
      text: "",
    },
    source,
    listItemFor = "table-details",
  } = props;

  const theme = useTheme();

  const betweenMdAndLgEx = useMediaQuery(
    theme.breakpoints.between("md", "lgEx"),
  );
  const lessThanSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Tooltip
      title={"Click to open in a new window"}
      disableInteractive
      PopperProps={{
        disablePortal: true,
        sx: {
          "&[data-popper-reference-hidden]": {
            visibility: "hidden",
            "pointer-events": "none",
          },
        },
      }}
    >
      <span>
        <ListItemButton
          sx={{
            px: listItemFor === "profile-page" ? 2 : 0,
            my: 0.5,
            borderRadius: "8px",
            ...(highlight.show && {
              backgroundColor: theme.palette.success.light,
            }),
            ...(listItemFor === "profile-page" && {
              border: `solid 1px ${theme.palette.divider}`,
              ...((betweenMdAndLgEx || lessThanSm) && { display: "block" }),
            }),
          }}
          component={"a"}
          href={
            file?.type === "link" && file?.uri !== null
              ? file.uri
              : `${
                  source === "contracts"
                    ? ContractsResourceServer
                    : source === "grants"
                    ? GrantsResourceServer
                    : ""
                }${encodeURIComponent(file.name)}`
          }
          rel={"noreferrer noopener"}
          target={"_blank"}
        >
          <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
            <ListItemIcon>
              <DescriptionIcon
                sx={{
                  ...(highlight.show
                    ? { color: theme.palette.success.main }
                    : listItemFor === "profile-page" && {
                        color: theme.palette.secondary.main,
                      }),
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={file.name}
              {...(listItemFor === "table-details" && {
                secondary: (
                  <>
                    {file?.createdOn ? (
                      <>
                        {`${format(new Date(file.createdOn), "dd MMMM yyyy")}`}
                        <b>{` ${
                          highlight.show && highlight.text ? highlight.text : ""
                        }`}</b>
                      </>
                    ) : (
                      <b>{`${
                        highlight.show && highlight.text ? highlight.text : ""
                      }`}</b>
                    )}
                  </>
                ),
              })}
              {...(listItemFor === "profile-page" && {
                primaryTypographyProps: {
                  variant: "subtitle2",
                  sx: {
                    mr: 1,
                  },
                },
              })}
            />
          </Box>
          {listItemFor === "profile-page" && (
            <Typography
              variant={"body2"}
              color={"textSecondary"}
              sx={{
                minWidth: "fit-content",
              }}
              align={"right"}
            >
              Last Update:{" "}
              {file?.createdOn ? (
                <>
                  {`${format(new Date(file.createdOn), "dd/MM/yyyy")}`}
                  <b>{` ${
                    highlight.show && highlight.text ? highlight.text : ""
                  }`}</b>
                </>
              ) : (
                <b>{`${
                  highlight.show && highlight.text ? highlight.text : ""
                }`}</b>
              )}
            </Typography>
          )}
        </ListItemButton>
      </span>
    </Tooltip>
  );
};

export default FileListItem;
