export function removeHtmlTags(expression: string): string {
  let temp = document.createElement("DIV");
  temp.innerHTML = expression;
  return temp.textContent || temp.innerText || "";
}

export function removeHtmlTagsExceptLink(expression: string): string {
  let temp = document.createElement("DIV");
  temp.innerHTML = expression;
  let allLinks = temp.getElementsByTagName("a");
  for (var i = 0; i < allLinks.length; i++) {
    let linkElem = allLinks[i];
    let href = linkElem.getAttribute("href") ?? "";
    let newLinkLabel = document.createElement("label");
    newLinkLabel.innerHTML = " " + href;
    linkElem?.parentNode?.insertBefore(newLinkLabel, linkElem.nextSibling);
  }
  return temp.textContent || temp.innerText || "";
}
