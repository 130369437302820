import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";

export interface DynamicPageTitle {
  pageTitle: string | null;
  setPageTitle: (value: string | null) => void;
  pageTitleLoading: boolean;
  setPageTitleLoading: (value: boolean) => void;
  titleBadge: ReactNode | null;
  setTitleBadge: (value: ReactNode | null) => void;
}

interface DynamicPageTitleProviderProps {
  children?: ReactNode;
}

const initialDynamicPageTitle: DynamicPageTitle = {
  pageTitle: null,
  setPageTitle: () => {},
  pageTitleLoading: false,
  setPageTitleLoading: () => {},
  titleBadge: null,
  setTitleBadge: () => {},
};

export const DynamicPageTitleContext = createContext<DynamicPageTitle>({
  ...initialDynamicPageTitle,
});

export const DynamicPageTitleProvider: FC<DynamicPageTitleProviderProps> = (
  props,
) => {
  const { children } = props;
  const [pageTitle, setPageTitle] = useState<string | null>(null);
  const [pageTitleLoading, setPageTitleLoading] = useState<boolean>(false);
  const [titleBadge, setTitleBadge] = useState<ReactNode | null>(null);

  return (
    <DynamicPageTitleContext.Provider
      value={{
        pageTitle,
        setPageTitle: (value) => setPageTitle(value),
        pageTitleLoading,
        setPageTitleLoading: (value) => setPageTitleLoading(value),
        titleBadge,
        setTitleBadge: (value) => setTitleBadge(value),
      }}
    >
      {children}
    </DynamicPageTitleContext.Provider>
  );
};

export const DynamicPageTitleConsumer = DynamicPageTitleContext.Consumer;
