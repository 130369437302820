import { createSvgIcon } from "@mui/material/utils";

export const ManageColumnsIcon = createSvgIcon(
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 0.666748H1.49996C1.27895 0.666748 1.06698 0.754545 0.910704 0.910826C0.754423 1.06711 0.666626 1.27907 0.666626 1.50008V16.5001C0.666626 16.7211 0.754423 16.9331 0.910704 17.0893C1.06698 17.2456 1.27895 17.3334 1.49996 17.3334H16.5C16.721 17.3334 16.9329 17.2456 17.0892 17.0893C17.2455 16.9331 17.3333 16.7211 17.3333 16.5001V1.50008C17.3333 1.27907 17.2455 1.06711 17.0892 0.910826C16.9329 0.754545 16.721 0.666748 16.5 0.666748ZM5.66663 15.6667H2.33329V2.33341H5.66663V15.6667ZM10.6666 15.6667H7.33329V2.33341H10.6666V15.6667ZM15.6666 15.6667H12.3333V2.33341H15.6666V15.6667Z"
      fill="inherit"
    />
  </svg>,
  "ManageColumnsIcon",
);
