import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { css } from "glamor";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import PillButton from "../../../PillButton";
import { useSharedStyles } from "../../useSharedStyles";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../../../configs/axios-export.custom";
import GrowlContext from "../../../../services/growlContext";
import UserContext from "../../../../services/UserContext";
import axios from "axios";
import SectionHeader, { SubHeaders } from "../../SectionHeader";
import "./organizationInfo.css";
import toBase64 from "../../../../utils/base64/converToBase64";
import styled from "@emotion/styled";
import addphoto from "../../../../assets/icons/addphoto.svg";
import { ProgressBar } from "primereact/progressbar";
import { InputTextarea } from "primereact/inputtextarea";
import Chip from "../../../Chip";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { type } from "os";
import { countries } from "../../MarketPreferences/market_preference_configs";
import ThemeContext from "../../../../configs/theme";

const styles = {
  fullWidthDropdown: {
    width: "100%",
  },
  fullWidth: {
    display: "block",
    width: "100%",
  },
  buttonIcon: {
    padding: "5px",
  },
  orgLogo: {
    borderRadius: "50%",
    border: "1px solid  #18a0fb",
    width: "150px",
    height: "150px",
    margin: "auto",
    // marginBottom:"5px"
  },
  localOfficesBadge: {
    padding: "7px",
    // margin:"4px"
  },
  checkBox: {
    background: "#367FB6",
    padding: "2px",
    borderRadius: "5px",
    color: "#FFFFFF",
  },
  uploadButton: {
    background: "#ECAC37",
    width: "66px",
    borderRadius: "14px",
    padding: "3px",
    border: "none",
    cursor: "pointer",
    marginTop: "8px",
  },
  chipsContainer: {
    marginTop: "5px",
    display: "flex",
    flexWrap: "wrap" as "wrap",
  },
  multiSelect: {
    width: "100%",
    borderRadius: 98,
    borderWidth: 2,
    borderColor: "#d9d9d9",
    padding: 2,
    paddingLeft: 10,
  },
};
const Img = styled.img({
  // margin: "5px",
  height: "140px",
  width: "140px",
  borderRadius: "50%",
  position: "relative",
});
type orgInformation = {
  name: string;
  domain: string;
  marketingOptIn: boolean;
  companySize: string;
  websiteUrl: string;
  description: string;
  address: string;
  country: string;
  region: string;
  officeLocations: Array<string>;
};
const defaultOrgSettings: orgInformation = {
  name: "",
  domain: "",
  marketingOptIn: false,
  companySize: "",
  websiteUrl: "",
  description: "",
  address: "",
  country: "",
  region: "",
  officeLocations: [""],
};

type Locations = {
  name: string;
  code: string;
};
const OrganizationInformation: FunctionComponent = () => {
  const growl = React.useContext(GrowlContext);
  const userContext = React.useContext(UserContext);
  const theme = useContext(ThemeContext);
  const [img, setImg] = React.useState({ preview: "", raw: "" });
  const [isLoading, setIsLoading] = React.useState(false);
  const [ButtonText, setButtonText] = React.useState("Upload");
  const [selectedLocations, setSelectedLocations] = React.useState<Locations[]>(
    []
  );
  const [orgSettings, setOrgSettings] =
    React.useState<orgInformation>(defaultOrgSettings);
  const email = userContext.user.parsedIdToken?.email ?? "";
  const [orgBase64String, setorgBase64String] = React.useState("");

  const sharedStyles = useSharedStyles();

  const [serverValues, setServerValues] = React.useState<orgInformation>();

  const request = createAuthenticatedRequest(userContext);

  // load organization data
  const loadOrgDataAsync = useCallback(() => {
    axios
      .get(
        getFullUrl("/api/organization/settings", {
          useDedicatedEnvironment: true,
        }),
        request
      )
      .then((response) => {
        setOrgSettings(response.data);
        setServerValues(response.data);
        const { officeLocations }: orgInformation = response.data;
        const se = officeLocations.map((r) => {
          return { name: r, code: r };
        });
        setSelectedLocations(se);
      })
      .catch((e) => {
        console.error(e);
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: "Unable to get data",
          });
        }
      });

    //  load the logo too
    axios
      .get(
        getFullUrl("/api/organization/logo", { useDedicatedEnvironment: true }),
        request
      )
      .then((response) => {
        let str = response.data;
        let base64ToString = Buffer.from(str, "base64").toString();
        setorgBase64String(base64ToString);
      })
      .catch((e) => {
        console.error(e);
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: "Unable to load data",
          });
        }
      });
  }, []);

  const canSaveInfo =
    orgSettings.name !== serverValues?.name ||
    orgSettings.companySize !== serverValues?.companySize ||
    orgSettings.country !== serverValues?.country ||
    orgSettings.description !== serverValues?.description ||
    [...selectedLocations.map((l) => l.name)].toString() !==
      serverValues?.officeLocations.toString() ||
    orgSettings.region !== serverValues?.region ||
    orgSettings.address !== serverValues?.address ||
    orgSettings.websiteUrl !== serverValues?.websiteUrl ||
    orgSettings.marketingOptIn !== serverValues?.marketingOptIn;

  const saveOrgInformation = () => {
    const errors = [];

    const Officelocation = selectedLocations.map((l) => {
      return l.name;
    });

    if (orgSettings.name === "") {
      errors.push({
        severity: "error",
        summary: "Failed, Organization name field cannot be empty.",
      } as never);
    }

    if (orgSettings.country === "") {
      errors.push({
        severity: "error",
        summary: "Failed, Country field cannot be empty.",
      } as never);
    }

    if (orgSettings.region === "") {
      errors.push({
        severity: "error",
        summary: "Failed, Region field cannot be empty.",
      } as never);
    }

    if (orgSettings.address === "") {
      errors.push({
        severity: "error",
        summary: "Failed, Address field cannot be empty.",
      } as never);
    }

    if (orgSettings.companySize === "") {
      errors.push({
        severity: "error",
        summary: "Failed, Company size field cannot be empty.",
      } as never);
    }

    if (orgSettings.websiteUrl === "") {
      errors.push({
        severity: "error",
        summary: "Failed, Website Url field cannot be empty.",
      } as never);
    }

    if (orgSettings.description === "") {
      errors.push({
        severity: "error",
        summary: "Failed, Company description field cannot be empty.",
      } as never);
    }

    if (
      orgSettings.name === "" ||
      orgSettings.country === "" ||
      orgSettings.region === "" ||
      orgSettings.address === "" ||
      orgSettings.companySize === "" ||
      orgSettings.websiteUrl === "" ||
      orgSettings.description === ""
    ) {
      growl.current.show(errors);
      return;
    }

    if (
      orgSettings.websiteUrl &&
      orgSettings.websiteUrl.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      )
    ) {
      axios
        .post(
          getFullUrl("/api/organization/settings", {
            useDedicatedEnvironment: true,
          }),
          {
            name: orgSettings.name,
            marketingOptIn: orgSettings.marketingOptIn,
            domain: email,
            companySize: orgSettings.companySize,
            websiteUrl: orgSettings.websiteUrl,
            description: orgSettings.description,
            address: orgSettings.address,
            country: orgSettings.country,
            region: orgSettings.region,
            officeLocations: Officelocation,
          },
          createRequestWithAuthHeaders(userContext)
        )
        .then(() => {
          growl.current.show({
            severity: "success",
            summary: "Organization profile update successful",
          });
          userContext.refreshUserToken(true);
        })
        .catch((message) => {
          console.error(message);
          if (message.response.status === 400) {
            growl.current.show({
              severity: "error",
              summary: "Please  fill  all the required fields marked *",
            });
          }
        });
    } else {
      growl.current.show({
        severity: "error",
        summary: "Website Url type is not supported",
      });
    }
  };

  React.useEffect(() => {
    loadOrgDataAsync();
  }, []);

  const saveProfilePhoto = async (base64string: string) => {
    setIsLoading(true);
    setButtonText("Uploading");
    const header = {
      headers: {
        Authorization: userContext.user.jwt,
        AccessToken: userContext.user.accessToken,
        X_Konektid_Authorization: userContext.user.konektidToken,
        "Content-Type": " image/jpeg",
      },
    };
    let basestring = base64string.split("base64,")[1];

    axios
      .post(
        getFullUrl("/api/organization/logo", {
          useDedicatedEnvironment: true,
        }),
        basestring,
        header
      )
      .then(() => {
        growl.current.show({
          severity: "success",
          summary: "Your organization logo has been uploaded successfully",
        });

        setIsLoading(false);
        setButtonText("Upload");
      })
      .catch((error) => {
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: "Unable to upload the logo",
          });
        }
      });
  };
  const handleImgChange = (e) => {
    if (e.target.files.length) {
      setImg({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const imgHandler = () => {
    if (img.raw) {
      return (
        <Img
          className="img-centered-clipped"
          src={img.preview}
          alt=""
          style={{ display: "none", margin: "4px" }}
          onLoad={(e) => (e.currentTarget.style.display = "")}
        />
      );
    } else if (orgBase64String) {
      return (
        <img
          style={{ borderRadius: "50%", display: "none" }}
          className="img-centered-clipped"
          height={145}
          width={145}
          src={"data:image/jpeg;base64," + orgBase64String}
          alt="loading"
          onLoad={(e) => (e.currentTarget.style.display = "")}
        />
      );
    } else {
      return (
        <div style={{ marginTop: "50px" }}>
          <img style={{ paddingTop: "0px" }} src={addphoto} alt=""></img>
        </div>
      );
    }
  };
  const onImageUpload = async (e) => {
    e.preventDefault();
    if (img.raw) {
      saveProfilePhoto(await toBase64(img.raw));
    }
  };

  const onOrgChange = <O extends keyof orgInformation>(
    prop: O,
    value: orgInformation[O]
  ) => {
    setOrgSettings({ ...orgSettings, [prop]: value });
  };

  const unSelectLocations = useCallback(
    (locationToRemove: Locations, selectedOfficelocations: Locations[]) => {
      const updatedOfficeLocations = selectedOfficelocations.filter(
        (r) => r.code !== locationToRemove.code
      );
      setSelectedLocations(updatedOfficeLocations);
    },
    []
  );

  const selectCountries = useCallback(async (locationsToAdd: Locations[]) => {
    setSelectedLocations(locationsToAdd);
  }, []);

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <SectionHeader text={"Organization Settings"} />
      </div>
      {/* left pane */}
      <div className="p-col-3">
        <div className="profile-picture-title">Upload logo here</div>
        <div style={styles.orgLogo}>
          <label htmlFor="upload-button">{imgHandler()}</label>
          <input
            accept="image/*"
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            onChange={handleImgChange}
          />
        </div>
        <div className="image-upload-button">
          <button style={styles.uploadButton} onClick={onImageUpload}>
            {ButtonText}
          </button>
          {isLoading && (
            <ProgressBar
              mode="indeterminate"
              style={{ height: "6px" }}
            ></ProgressBar>
          )}
        </div>
      </div>
      {/* right pane */}
      <div className="p-col-9" style={{ textAlign: "left" }}>
        <SubHeaders text={"Organization Details"} />

        <div
          className="p-col-8"
          style={{
            marginTop: 10,
          }}
        >
          <label
            {...css(sharedStyles.floatingLabel)}
            htmlFor="orgname"
            style={{ marginBottom: 8 }}
          >
            Organization Name<span className="required">*</span>
          </label>
          <InputText
            value={orgSettings.name}
            placeholder="Organization Name"
            style={{
              ...sharedStyles.text,
              ...sharedStyles.radiusBordered,
              ...styles.fullWidth,
              fontWeight: 500,
            }}
            onChange={(e) => {
              onOrgChange("name", e.currentTarget.value);
            }}
          />
        </div>

        <div
          className="p-col-8"
          style={{
            marginTop: -5,
          }}
        >
          <label
            {...css(sharedStyles.floatingLabel)}
            htmlFor="email"
            style={{ marginBottom: 8 }}
          >
            Email Domain
          </label>

          <div
            style={{ backgroundColor: "#e7e7e7", marginTop: "2px" }}
            id="email"
            {...css({ ...sharedStyles.text, ...sharedStyles.radiusBordered })}
          >
            {email}
          </div>
        </div>
        <div
          className="p-col-5"
          style={{
            marginTop: -5,
          }}
        >
          <label
            {...css(sharedStyles.floatingLabel)}
            htmlFor="timeZone"
            style={{ marginBottom: 8 }}
          >
            Company Size<span className="required">*</span>
          </label>
          <Dropdown
            style={{
              fontWeight: 500,
              width: "100%",
              padding: 2,
              paddingLeft: 10,
              borderRadius: 120,
              borderColor: theme.v2.lightGrey,
              borderWidth: 2,
            }}
            value={orgSettings.companySize}
            options={[
              "<10 Employees",
              "10-49 Employees",
              "50-249 Employees",
              "250 and more Employees",
            ]}
            placeholder="< 10 Employees"
            onChange={(e) => {
              onOrgChange("companySize", e.target.value);
            }}
          />
        </div>
        <div className="p-col-8">
          <label
            {...css(sharedStyles.floatingLabel)}
            htmlFor="Website"
            style={{ marginBottom: 8 }}
          >
            Website<span className="required">*</span>
          </label>
          <InputText
            value={orgSettings.websiteUrl}
            placeholder="website"
            style={{
              ...sharedStyles.text,
              ...sharedStyles.radiusBordered,
              ...styles.fullWidth,
              fontWeight: 500,
            }}
            onChange={(e) => {
              onOrgChange("websiteUrl", e.currentTarget.value);
            }}
          />
        </div>
        <div className="p-col-8">
          <label
            {...css(sharedStyles.floatingLabel)}
            htmlFor="Description"
            style={{ marginBottom: 8 }}
          >
            Description<span className="required">*</span>
          </label>
          <InputTextarea
            value={orgSettings.description}
            placeholder="Description"
            className="input-textarea"
            rows={5}
            cols={30}
            style={{
              ...sharedStyles.text,
              ...styles.fullWidth,
              fontWeight: 500,
              paddingLeft: 12,
              borderColor: theme.v2.lightGrey,
              borderWidth: 2,
              borderRadius: 16,
            }}
            onChange={(e) => {
              onOrgChange("description", e.currentTarget.value);
            }}
          />
        </div>

        <div
          style={{
            marginTop: 10,
          }}
        >
          <SubHeaders text={"Address Details"} />
        </div>
        <label
          {...css(sharedStyles.floatingLabel)}
          htmlFor="Headquater"
          style={{ marginLeft: 5, marginTop: 15 }}
        >
          Headquarters Location(Country / Region)
          <span className="required">*</span>
        </label>
        <div className="p-col-10" style={{ display: "flex", marginTop: -15 }}>
          <div className="p-col-5" style={{ marginLeft: "-11px" }}>
            <Dropdown
              style={{
                fontWeight: 700,
                width: "100%",
                padding: 2,
                paddingLeft: 10,
                borderRadius: 120,
                borderColor: theme.v2.lightGrey,
                borderWidth: 2,
                marginTop: 10,
              }}
              placeholder="Countries"
              options={countries}
              value={orgSettings.country}
              valueTemplate={(option: { name: string; code: string }) => {
                if (option) {
                  return <p>{option.name}</p>;
                } else {
                  return orgSettings.country;
                }
              }}
              itemTemplate={(option) => {
                return <p>{option.name}</p>;
              }}
              onChange={(e) => {
                onOrgChange("country", e.target.value["name"]);
              }}
            />
          </div>
          <div className="p-col-5">
            <InputText
              value={orgSettings.region}
              placeholder="region"
              style={{
                ...sharedStyles.text,
                ...sharedStyles.radiusBordered,
                ...styles.fullWidth,
                fontWeight: 700,
                marginTop: 10,
              }}
              onChange={(e) => {
                onOrgChange("region", e.currentTarget.value);
              }}
            />
          </div>
        </div>
        <div
          className="p-col-8"
          style={{
            marginTop: -10,
          }}
        >
          <label
            {...css(sharedStyles.floatingLabel)}
            htmlFor="Address"
            style={{ marginBottom: 8 }}
          >
            Address<span className="required">*</span>
          </label>
          <InputTextarea
            value={orgSettings.address}
            placeholder="address"
            className="input-textarea"
            rows={5}
            cols={30}
            style={{
              ...sharedStyles.text,
              ...styles.fullWidth,
              fontWeight: 500,
              paddingLeft: 12,
              paddingTop: 12,
              borderColor: theme.v2.lightGrey,
              borderWidth: 2,
              borderRadius: 16,
            }}
            onChange={(e) => {
              onOrgChange("address", e.currentTarget.value);
            }}
          />
        </div>
        <div className="p-col-8">
          <label
            {...css(sharedStyles.floatingLabel)}
            htmlFor=" LocalOffices"
            style={{ marginBottom: 8 }}
          >
            Local Offices
          </label>
          <div style={styles.chipsContainer}>
            <MultiSelect
              optionLabel="name"
              filter={true}
              style={styles.multiSelect}
              placeholder={selectedLocations.length + " Local Offices"}
              fixedPlaceholder
              value={selectedLocations}
              options={countries}
              onChange={(e) => selectCountries(e.value)}
            />
            <div
              style={{ marginTop: "7px", display: "flex", flexWrap: "wrap" }}
            >
              {selectedLocations.map((l) => (
                <Chip
                  key={l.name}
                  label={l.name}
                  onIconClick={() => unSelectLocations(l, selectedLocations)}
                />
              ))}
            </div>
          </div>
        </div>

        <div style={{ marginTop: "10px", marginBottom: 8 }}>
          <SubHeaders text={"Marketing Opt-in"} />
        </div>
        <div className="p-col-8">
          <div
            style={{
              display: "flex",
            }}
          >
            <span className="field-checkbox" style={{ paddingRight: "10px" }}>
              <Checkbox
                inputId="binary"
                checked={orgSettings.marketingOptIn}
                onChange={() => {
                  onOrgChange("marketingOptIn", !orgSettings.marketingOptIn);
                }}
              />
            </span>
            <span style={{ fontSize: 15 }}>
              Yes, share our logo and name in Aidkonekt's marketing and on the
              landing page.
            </span>
          </div>
          <p
            style={{
              lineHeight: "20px",
              fontSize: "10px",
              marginLeft: 30,
              marginTop: 5,
            }}
          >
            * Please email support@aidkonekt.com to opt-out of your marketing
            preference. Please note, it can take about a month for us to process
            this request.
          </p>
        </div>
        <div className="p-col-8" style={{ textAlign: "right" }}>
          <PillButton
            label="Save"
            style={{ width: "150px" }}
            disabled={!canSaveInfo}
            onClick={saveOrgInformation}
          />
        </div>
      </div>
    </div>
  );
};

export default OrganizationInformation;
