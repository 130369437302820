export class RequestStatus {
  static statuses: {
    NULL: string;
    FETCHING: string;
    DONE: string;
    HALF_DONE: string; //very rarely used
    ERROR: string;
  } = {
    NULL: "null",
    FETCHING: "fetching",
    DONE: "done",
    HALF_DONE: "half-done",
    ERROR: "error",
  };

  static isNull(status) {
    return status === this.statuses.NULL;
  }

  static isFetching(status) {
    return status === this.statuses.FETCHING;
  }

  static isDone(status) {
    return status === this.statuses.DONE;
  }

  static isHalfDone(status) {
    return status === this.statuses.HALF_DONE;
  }

  static isError(status) {
    return status === this.statuses.ERROR;
  }
}
