import styled from "@emotion/styled";
import React, { FC } from "react";
import GrowlContext from "../../../../../services/growlContext";
import DashboardSection from "../../../DashboardSection";
import SectionHeader from "../../../SectionHeader";
import axios from "axios";
import { getFullUrl } from "../../../../../configs/axios-export.custom";
import UserContext from "../../../../../services/UserContext";

interface currentUser {
  user: string;
}
let Button = styled.button({
  background: "#ECAC37",
  width: "91px",
  padding: "10px",
  borderRadius: "20px",
  border: "none",
  fontSize: "15px",
  "& :focus": {
    background: "#ECAC37",
  },
});

let Label = styled.label({
  display: "block",
  fontSize: "16px",
  textAlign: "left",
});
let Inputs = styled.input({
  height: "35px",
  border: "2px solid #D9D9D9",
});

const ChangePassword: FC<currentUser> = ({ user }) => {
  const context = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);
  const [passwordConfirm, setPasswordConfirm] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const forgotPasswordSubmit = () => {
    if (newPassword !== passwordConfirm) {
      if (growl.current) {
        growl.current.show({
          severity: "error",
          summary: "Password Mismatch!",
        });
      }
      return;
    }

    const confirmRequest = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        AccessToken: context.user.accessToken,
        Authorization: context.user.jwt,
      },
    };

    axios
      .post(
        getFullUrl("/api/auth/changepassword", {
          useDedicatedEnvironment: true,
        }),
        {
          newPassword: newPassword,
          oldPassword: oldPassword,
        },
        confirmRequest
      )
      .then(() => {
        if (growl.current) {
          growl.current.show({
            severity: "success",
            summary: "successfully changed password!",
          });
        }
      })
      .catch((err) => {
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: `Error resetting password: ${err}`,
          });
        }
      });
  };

  return (
    <DashboardSection>
      <SectionHeader text={"Password Reset"} />
      <div className="p-grid">
        <div className="p-col-12">
          <div className="p-col-4">
            <Label className="field-titles" htmlFor="currentpassword">
              Current Password
            </Label>
            <Inputs
              placeholder=" Password"
              className="field-inputs"
              type="password"
              onChange={(e) =>
                setOldPassword((e.target as HTMLInputElement).value)
              }
            />
          </div>
        </div>
        <div className="p-col-12" style={{ display: "flex" }}>
          <div className="p-col-4">
            <Label className="field-titles" htmlFor="newpassword">
              New Password
            </Label>
            <Inputs
              type="password"
              placeholder=" Password"
              className="field-inputs"
              onChange={(e) =>
                setNewPassword((e.target as HTMLInputElement).value)
              }
            />
          </div>
          <div className="p-col-4">
            <Label className="field-titles" htmlFor="retypepassword">
              Retype Password
            </Label>
            <Inputs
              placeholder="Password"
              className="field-inputs"
              type="password"
              onChange={(e) =>
                setPasswordConfirm((e.target as HTMLInputElement).value)
              }
            />
          </div>
        </div>
        <div className="p-col-12">
          <Button onClick={forgotPasswordSubmit}>Save</Button>
        </div>
      </div>
    </DashboardSection>
  );
};

export default ChangePassword;
