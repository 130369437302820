import React, { FC, useCallback, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import Chip from "../../Chip";
import "./MarketPreferences.css";
import { useSharedStyles } from "../useSharedStyles";
import { countries, technicalSectors } from "./market_preference_configs";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../../configs/axios-export.custom";
import UserContext from "../../../services/UserContext";
import GrowlContext from "../../../services/growlContext";
import styled from "@emotion/styled";
import { Dropdown } from "primereact/dropdown";
import { PreferenceFilterTypes } from "../../../services/enums";
import { InputText } from "primereact/inputtext";
import axios from "axios";

interface Item {
  name: string;
  code: string;
}

const styles = {
  chipsContainer: {
    marginTop: "5px",
    display: "flex",
    flexWrap: "wrap" as "wrap",
  },
  multiSelect: {
    width: "100%",
    borderRadius: 10,
  },
  multiSelectContainer: {
    margin: "5px 0px",
  },
};
const CustomLabel = styled.label({
  fontSize: "15px",
  textAlign: "left",
  paddingLeft: 0,
});
enum PreferenceTypes {
  country = "Country",
  technicalSector = "TechnicalSector",
}

type MarketPreference = {
  preferenceType: PreferenceTypes;
  name: string;
};
const preferenceOptions = [
  { label: "AND", value: PreferenceFilterTypes.And },
  { label: "OR", value: PreferenceFilterTypes.Or },
];

interface Props {
  GetSelectedCountries: ({}) => void;
  GetSelectedSectors: ({}) => void;
  GetPreferenecFilterType: ({}) => void;
  GetSelectedKeywords: ({}) => void;
  preferenceFType: PreferenceFilterTypes;
  marketPreferences: any;
  keywords: any;
  pipelineId: number;
  disabled: boolean;
}

const MarketPreferencesBeta: FC<Props> = ({
  GetSelectedCountries,
  GetSelectedSectors,
  GetPreferenecFilterType,
  GetSelectedKeywords,
  preferenceFType,
  marketPreferences,
  keywords,
  pipelineId,
  disabled,
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [keywordSearchLimit, setKeywordSearchLimit] = React.useState(1);
  const [selectedCountries, setSelectedCountries] = useState<Item[]>([]);
  const [selectedTechnicalSectors, setSelectedTechnicalSectors] = useState<
    Item[]
  >([]);
  const [selectedKeywords, setSelectedKeywords] = useState<any[]>(keywords);
  const [selectedPreferenceFilterType, setSelectedPreferenceFilterType] =
    useState(preferenceFType);

  const sharedStyles = useSharedStyles();
  const context = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);
  const header = createAuthenticatedRequest(context);

  React.useEffect(() => {
    var r: Array<MarketPreference> = marketPreferences;

    const seCountries = r
      .filter((x) => x.preferenceType === PreferenceTypes.country)
      .map((x) => countries.find((r) => r.code === x.name || r.name.toLocaleLowerCase() === x.name.toLowerCase()))
      .filter((x) => x) as Array<Item>;

    const formattedCountries = seCountries.map((r) => ({
      preferenceType: PreferenceTypes.country,
      name: r.name,
      code: r.code,
    }));

    GetSelectedCountries(formattedCountries);
    setSelectedCountries(seCountries);

    const seSectors = r
      .filter((x) => x.preferenceType === PreferenceTypes.technicalSector)
      .map((x) => technicalSectors.find((r) => r.name === x.name))
      .filter((x) => x) as Array<Item>;

    const formattedSectors = seSectors.map((r) => ({
      preferenceType: PreferenceTypes.technicalSector,
      name: r.name,
    }));

    GetSelectedSectors(formattedSectors);
    setSelectedTechnicalSectors(seSectors);

    GetSelectedKeywords(keywords);
  }, []);

  const selectCountries = useCallback(async (countriesToAdd: Item[]) => {
    let body = countriesToAdd.map((r) => ({
      preferenceType: PreferenceTypes.country,
      name: r.name,
      code: r.code
    }));
    GetSelectedCountries(body);
    setSelectedCountries(countriesToAdd);
  }, []);

  const unSelectCountry = (
    countryToRemove: Item,
    selectedCountries: Item[]
  ) => {
    const updatedCountries = selectedCountries.filter(
      (country) => country.code !== countryToRemove.code
    );
    setSelectedCountries(updatedCountries);
    GetSelectedCountries(
      updatedCountries.map((r) => ({
        preferenceType: PreferenceTypes.country,
        name: r.name,
        code: r.code
      }))
    );
  };

  const setPreferenceFilterType = (data: any) => {
    setSelectedPreferenceFilterType(data);
    GetPreferenecFilterType(data);
  };

  const selectSectors = useCallback((sectorsToAdd: Item[]) => {
    let body = sectorsToAdd.map((r) => ({
      preferenceType: PreferenceTypes.technicalSector,
      name: r.name,
    }));
    GetSelectedSectors(body);
    setSelectedTechnicalSectors(sectorsToAdd);
  }, []);

  const setKeywords = (keyword: any) => {
    if (keyword) {
      const found = selectedKeywords?.find((obj) => {
        return obj === keyword;
      });
      if (!found) {
        setSelectedKeywords([...selectedKeywords, keyword]);
        GetSelectedKeywords([...selectedKeywords, keyword]);
      }
    }
  };

  const unSelectTechnicalSector = (
    techSectorToRemove: Item,
    selectedTechnicalSectors: Item[]
  ) => {
    const updatedSectors = selectedTechnicalSectors.filter(
      (techSector) => techSector.name !== techSectorToRemove.name
    );

    setSelectedTechnicalSectors(updatedSectors);
    GetSelectedSectors([
      {
        preferenceType: PreferenceTypes.technicalSector.toString(),
        name: techSectorToRemove.name,
      },
    ]);
    GetSelectedSectors(
      updatedSectors.map((r) => ({
        preferenceType: PreferenceTypes.technicalSector,
        name: r.name,
      }))
    );
  };
  const unSelectKeyword = (keywordToRemove: any, selectedKeywords: any[]) => {
    const updatedKeywords = selectedKeywords.filter(
      (keyword) => keyword !== keywordToRemove
    );

    setSelectedKeywords(updatedKeywords);

    GetSelectedKeywords(updatedKeywords);
  };

  const getKeywordSearchLimit = async () => {
    await axios
      .get(
        getFullUrl("/api/pipeline/keywordsearchlimit", {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context)
      )
      .then((response) => {
        const { data } = response;
        setKeywordSearchLimit(data);
      })
      .catch((e) => {
        console.error(e);
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: "Unable to load users",
          });
        }
      });
  };

  React.useEffect(() => {
    getKeywordSearchLimit();
  }, []);

  return (
    <>
      {" "}
      <div style={{ display: "flex" }}>
        <div></div>
        <div>
          <div
            style={{
              width: "54%",
              height: "35%",
              borderLeft: "2px dashed #B2D5EB",
              borderTop: "2px dashed #B2D5EB",
              marginTop: "13px",
              marginLeft: "23px",
            }}
          ></div>
          <select
            value={selectedPreferenceFilterType}
            className="preference-ddl"
            onChange={(s) =>
              setPreferenceFilterType(
                s.target.value === "And"
                  ? PreferenceFilterTypes.And
                  : PreferenceFilterTypes.Or
              )
            }
          >
            {preferenceOptions.map((option) => (
              <option value={option.value}>{option.label}</option>
            ))}
          </select>

          <div
            style={{
              width: "54%",
              height: "40%",
              borderLeft: "2px dashed #B2D5EB",
              borderBottom: "2px dashed #B2D5EB",
              marginBottom: "5px",
              marginLeft: "23px",
            }}
          ></div>
        </div>
        {/* <div
          style={ styles.multiSelectContainer}
          className="dashboard-market-preferences"
        ></div> */}
        <div
          style={styles.multiSelectContainer}
          className="dashboard-market-preferences"
        >
          <CustomLabel className="field-titles" htmlFor="regions">
            Regions and Countries
          </CustomLabel>
          <MultiSelect
            optionLabel="name"
            filter={true}
            disabled={disabled}
            style={{ ...styles.multiSelect, marginTop: 8 }}
            placeholder=" Regions and Countries"
            fixedPlaceholder
            value={selectedCountries}
            options={countries}
            onChange={(e) => selectCountries(e.value)}
          />
          <div style={styles.chipsContainer}>
            {selectedCountries
              .sort((c1, c2) => c1.name.localeCompare(c2.name))
              .map((country) => (
                <Chip
                  key={country.name}
                  label={country.name}
                  onIconClick={() =>
                    disabled
                      ? null
                      : unSelectCountry(country, selectedCountries)
                  }
                />
              ))}
          </div>
        </div>
      </div>
      <div
        style={{ margin: "5px 0px 5px 66px" }}
        className="dashboard-market-preferences"
      >
        <CustomLabel className="field-titles" htmlFor="regions">
          Technical Sectors
        </CustomLabel>
        <MultiSelect
          optionLabel="name"
          filter={true}
          disabled={disabled}
          style={styles.multiSelect}
          placeholder="Technical Sectors"
          fixedPlaceholder
          value={selectedTechnicalSectors}
          options={technicalSectors}
          onChange={(e) => selectSectors(e.value)}
        />
        <div style={styles.chipsContainer}>
          {selectedTechnicalSectors
            .sort((s1, s2) => s1.name.localeCompare(s2.name))
            .map((technicalSector) => (
              <Chip
                key={technicalSector.name}
                label={technicalSector.name}
                onIconClick={() =>
                  disabled
                    ? null
                    : unSelectTechnicalSector(
                        technicalSector,
                        selectedTechnicalSectors
                      )
                }
              />
            ))}
        </div>
      </div>
      {/* <div style={{ margin: "5px 0px 5px 66px" }}>
        <CustomLabel className="field-titles" htmlFor="Keyword">
          Keyword
        </CustomLabel>
        <InputText
          placeholder="You can add up to three keywords"
          className="field-inputs"
          // value={}
          onBlur={(e) => {
            e.preventDefault();
            setKeywords((e.target as HTMLInputElement).value);
            (e.target as HTMLInputElement).value = "";
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setKeywords((e.target as HTMLInputElement).value);
              (e.target as HTMLInputElement).value = "";
            }
          }}
          disabled={selectedKeywords?.length === keywordSearchLimit}
          style={{
            marginTop: 8,
          }}
        />

        <div style={styles.chipsContainer}>
          {selectedKeywords
            ?.sort((s1, s2) => s1?.localeCompare(s2))
            .map((keyword) => (
              <Chip
                key={keyword}
                label={keyword}
                onIconClick={() =>
                  disabled ? null : unSelectKeyword(keyword, selectedKeywords)
                }
              />
            ))}
        </div>
      </div> */}
    </>
  );
};

export default MarketPreferencesBeta;
