import axios from "axios";
import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../configs/axios-export.custom";
import { UserContextType } from "../services/UserContext";
import { RequestStatus } from "../utils/Helpers/fetchStatus";
import { buildQuery } from "../utils/Helpers/queryBuilder";

interface Notification {
  notificationTime: string;
  notificationDay: string;
  notificationType: string;
  recipient: string;
}

export interface CalendarEvent {
  id: number;
  date: string;
  name: string;
  organization: string;
  url: string;
  location: string;
  createdOn: string;
  description?: string | null;
}

export interface DashboardUpdates {
  fetchStatus: string;
  forecast: any;
  contracts: any;
  grants: any;
  enhancements: any[];
  updated: any[];
  removed: any[];
  forecastSummaryId: number | null;
  contractsDatasetSummaryId: number | null;
  grantsDatasetSummaryId: number | null;
  feedback: {
    fetchStatus: string;
    errors: Record<string, unknown>;
  };
  updateFeedback: {
    fetchStatus: string;
    errors: Record<string, unknown>;
  };
  deleteFeedback: {
    fetchStatus: string;
    errors: Record<string, unknown>;
  };
}

export interface PipelineUpdate {
  changedColumn: string;
  pipelineId: number;
  pipelineName: string;
  title: string;
  updatedOn: string;
}

interface Organization {
  name: string;
  tier: string;
  tierExpiration: string;
}

interface User {
  firstName: string;
  lastName: string;
  timezone: string;
}

interface OverviewSection {
  startDate: string;
  endDate: string;
  datasetType: "Forecast" | "Contract" | "Grant";
  summaryHtml: string;
  summaryText: string;
}

interface DashboardState {
  fetchStatus: string;
  user: User | null;
  organization: Organization | null;
  // events: CalendarEvent[] | null;
  events: {
    items: CalendarEvent[] | null;
    total: number;
    pageIndex: number;
    pageSize: number;
    attributes: Record<string, any>;
    lastUpdatedUtc: string | null;
    fetchStatus: string;
  };
  notifications: Notification[];
  companyEmails: { emailAddress: string }[];
  updates: DashboardUpdates;
  pipelineUpdates: {
    items: PipelineUpdate[];
    paginatedItems: PipelineUpdate[];
    count: number;
    fetchStatus: string;
  };
  opportunitiesDetails: {
    fetchStatus: string;
    data: any[];
    lastUpdatedUtc: string | null;
    pageIndex: number;
    pageSize: number;
    total: number;
    attributes: Record<string, string>;
  };
  event: {
    post: {
      fetchStatus: string;
    };
    put: {
      fetchStatus: string;
    };
    delete: {
      fetchStatus: string;
    };
  };
  deleteAccount: {
    fetchStatus: string;
    error: string | null;
  };
}

export interface NewEventValues {
  id?: any;
  date: string | Date | null;
  name: string;
  organization?: string;
  location?: string;
  url?: string;
  description?: string | null;
}

export const UNUSUAL_ACTIVITY_TRIGGER_COUNT = 50;

const initialState: DashboardState = Object.freeze({
  fetchStatus: RequestStatus.statuses.NULL,
  companyEmails: [],
  events: {
    items: null,
    total: 0,
    pageIndex: 0,
    pageSize: 0,
    attributes: {},
    lastUpdatedUtc: null,
    fetchStatus: RequestStatus.statuses.NULL,
  },
  notifications: [],
  user: null,
  organization: null,
  updates: {
    fetchStatus: RequestStatus.statuses.NULL,
    forecast: null,
    contracts: null,
    grants: null,
    enhancements: [],
    updated: [],
    removed: [],
    feedback: {
      fetchStatus: RequestStatus.statuses.NULL,
      errors: {},
    },
    updateFeedback: {
      fetchStatus: RequestStatus.statuses.NULL,
      errors: {},
    },
    deleteFeedback: {
      fetchStatus: RequestStatus.statuses.NULL,
      errors: {},
    },
    forecastSummaryId: null,
    contractsDatasetSummaryId: null,
    grantsDatasetSummaryId: null,
  },
  pipelineUpdates: {
    items: [],
    paginatedItems: [],
    count: 0,
    fetchStatus: RequestStatus.statuses.NULL,
  },
  opportunitiesDetails: {
    fetchStatus: RequestStatus.statuses.NULL,
    data: [],
    lastUpdatedUtc: null,
    pageIndex: 0,
    pageSize: 0,
    total: 0,
    attributes: {},
  },
  event: {
    post: {
      fetchStatus: RequestStatus.statuses.NULL,
    },
    put: {
      fetchStatus: RequestStatus.statuses.NULL,
    },
    delete: {
      fetchStatus: RequestStatus.statuses.NULL,
    },
  },
  deleteAccount: {
    fetchStatus: RequestStatus.statuses.NULL,
    error: null,
  },
});

export const getDashboardData: any = createAsyncThunk(
  "dashboard/getDashboardData",
  async (context: UserContextType) => {
    const response = await axios.get(
      getFullUrl("/api/user/profile", { useDedicatedEnvironment: true }),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? {};
  },
);

export const getEvents: any = createAsyncThunk(
  "dashboard/getEvents",
  async (data: {
    context: UserContextType;
    params: {
      name: string;
      PageIndex: number;
      PageSize: number;
    };
  }) => {
    const { context, params } = data;
    const response = await axios.get(
      getFullUrl(
        `/api/user/event${buildQuery({
          ...params,
          PageIndex: params.PageIndex ?? 0,
          PageSize: params.PageSize ?? 20,
          EventDateAfter: new Date(),
          SortField: "date",
          SortOrder: 1,
        })}`,
        {
          useDedicatedEnvironment: true,
        },
      ),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? {};
  },
);

export const getOverviewData: any = createAsyncThunk(
  "dashboard/getOverviewData",
  async (data: {
    context: UserContextType;
    values: {
      startDate: string;
      endDate: string;
      forceReload: boolean;
    };
  }) => {
    const { context, values } = data;
    const rawResponse = await Promise.all(
      ["Forecast"].map((datasetType) =>
        axios.post(
          getFullUrl("/api/summary/data", { useDedicatedEnvironment: true }),
          {
            ...values,
            datasetType,
          },
          createAuthenticatedRequest(context),
        ),
      ),
    );

    const aiSummaryResponse = await Promise.all(
      ["Forecast", "Contract", "Grant"].map((datasetType) =>
        axios.post(
          getFullUrl("/api/summary/ai", { useDedicatedEnvironment: true }),
          { ...values, datasetType, filterToUserPipelines: false },
          createAuthenticatedRequest(context),
        ),
      ),
    );

    return {
      rawResponse:
        rawResponse.map((_r, idx) => ({
          ..._r.data,
          context: idx === 0 ? "Forecast" : idx === 1 ? "Contract" : "Grant",
        })) ?? [],
      summaries: aiSummaryResponse.map((_r) => _r.data) ?? [],
    };
  },
);

export const sendSummaryFeedback: any = createAsyncThunk(
  "dashboard/sendSummaryFeedback",
  async (
    data: {
      context: UserContextType;
      aiSummaryId?: number;
      datasetSummaryId?: number;
      score: number;
      notes?: string;
    },
    thunkAPI,
  ) => {
    const { context, ...other } = data;

    const response = await axios.post(
      getFullUrl("/api/summary/ai/feedback", { useDedicatedEnvironment: true }),
      other,
      createAuthenticatedRequest(context),
    );

    return response?.data;
  },
);

export const updateSummaryFeedback: any = createAsyncThunk(
  "dashboard/updateSummaryFeedback",
  async (
    data: {
      context: UserContextType;
      aiSummaryId: number;
      score: number;
      notes?: string;
    },
    thunkAPI,
  ) => {
    const { context, ...other } = data;

    const response = await axios.put(
      getFullUrl("/api/summary/ai/feedback", { useDedicatedEnvironment: true }),
      other,
      createAuthenticatedRequest(context),
    );

    return response?.data;
  },
);

export const deleteSummaryFeedback: any = createAsyncThunk(
  "dashboard/deleteSummaryFeedback",
  async (
    data: {
      context: UserContextType;
      id: number;
    },
    thunkAPI,
  ) => {
    const { context, id } = data;

    const response = await axios.delete(
      getFullUrl(`/api/summary/ai/feedback?id=${id}`, {
        useDedicatedEnvironment: true,
      }),
      createAuthenticatedRequest(context),
    );

    return response?.data;
  },
);

export const getPipelineUpdatesDetails: any = createAsyncThunk(
  "dashboard/getPipelineUpdatesDetails",
  async (data: {
    context: UserContextType;
    params: {
      PageIndex: number;
      PageSize: number;
      DatasetTypes: ("Forecast" | "Contract" | "Grant")[];
      UpdatedAfter: string;
      UpdatedBefore: string;
      SortField?: string;
      SortOrder?: number;
      Keyword?: string;
      CountryGenerics?: string[];
      CountrySpecifics?: string[];
      Sectors?: string[];
      Agencies?: string[];
      OpportunityNumbers?: string[];
      FilterToUserPipelines?: "true" | "false";
      PipelineIds?: string[];
    };
    loadMore?: boolean;
  }) => {
    const { context, params, loadMore = false } = data;

    const response = await axios.get(
      getFullUrl(`/api/combinedDataset${buildQuery({ ...params })}`, {
        useDedicatedEnvironment: true,
      }),
      createAuthenticatedRequest(context),
    );

    return {
      ...response.data,
      loadMore,
    };
  },
);

export const getPipelineUpdates: any = createAsyncThunk(
  "dashboard/getPipelineUpdates",
  async (data: {
    context: UserContextType;
    period: "week" | "month" | "year";
    type: "allPipelines" | "myPipeline" | "sharedWithMe";
  }) => {
    const { context, period, type } = data;
    const response = await axios.get(
      getFullUrl(
        `/api/user/recentPipeLineUpdates${buildQuery({ period, type })}`,
        {
          useDedicatedEnvironment: true,
        },
      ),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? {};
  },
);

export const createNewEvent: any = createAsyncThunk(
  "dashboard/createNewEvent",
  async (data: { context: UserContextType; values: NewEventValues }) => {
    const { context, values } = data;
    const response = await axios.post(
      getFullUrl("/api/user/event", { useDedicatedEnvironment: true }),
      values,
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

export const updateEvent: any = createAsyncThunk(
  "dashboard/updateEvent",
  async (data: {
    context: UserContextType;
    values: NewEventValues;
    id: number;
  }) => {
    const { context, values, id } = data;
    const response = await axios.put(
      getFullUrl("/api/user/event", { useDedicatedEnvironment: true }),
      {
        id,
        event: values,
      },
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

export const deleteEvent: any = createAsyncThunk(
  "dashboard/deleteEvent",
  async (data: { context: UserContextType; id: number }) => {
    const { context, id } = data;
    const response = await axios.delete(
      getFullUrl("/api/user/event", { useDedicatedEnvironment: true }),
      {
        ...createRequestWithAuthHeaders(context),
        data: { id },
      } as any,
    );

    return response?.data ?? [];
  },
);

export const deleteAccount: any = createAsyncThunk(
  "dashboard/deleteAccount",
  async (context: UserContextType) => {
    const response = await axios.delete(
      getFullUrl("/api/user/delete", { useDedicatedEnvironment: true }),
      createRequestWithAuthHeaders(context),
    );

    return response?.data ?? [];
  },
);

export const clearStoreState = createAsyncThunk(
  "dashboard/clearStoreState",
  async function (undefined, thunkAPI) {
    thunkAPI.dispatch({ type: "logout/LOGOUT" });
  },
);

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetCreateEventState(state: DashboardState) {
      state.event.post = initialState.event.post;
    },
    resetUpdateEventState(state: DashboardState) {
      state.event.put = initialState.event.put;
    },
    resetDeleteAccountState(state: DashboardState) {
      state.deleteAccount = initialState.deleteAccount;
    },
    resetFeedbackUpdateState(state: DashboardState) {
      state.updates.feedback = initialState.updates.feedback;
      state.updates.updateFeedback = initialState.updates.updateFeedback;
      state.updates.deleteFeedback = initialState.updates.deleteFeedback;
    },
  },
  extraReducers: {
    [getDashboardData.pending]: (state) => {
      state.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getDashboardData.fulfilled]: (state, action) => {
      const data = action.payload;
      state.user = {
        firstName: data.firstName,
        lastName: data.lastName,
        timezone: data.timezone,
      };
      state.organization = data.organization;
      state.notifications = data.notifications;
      state.companyEmails = data.companyEmails;
      state.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getDashboardData.rejected]: (state) => {
      state.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [getEvents.pending]: (state) => {
      state.events.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getEvents.fulfilled]: (state, action) => {
      const data = action.payload;
      state.events.items = data.data;
      state.events.total = data.totalItems;
      state.events.lastUpdatedUtc = data.lastUpdatedUtc;
      state.events.attributes = data.attributes;
      state.events.pageIndex = data.pageIndex;
      state.events.pageSize = data.pageSize;
      state.events.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getEvents.rejected]: (state) => {
      state.events.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [getOverviewData.pending]: (state) => {
      state.updates.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getOverviewData.fulfilled]: (state, action) => {
      const totalForecastChanges = Object.values(action.payload.rawResponse[0])
        .filter(
          (val: any) => !val.EnhancementUpdates && typeof val !== "string",
        )
        .map((item: any) => item.Records.length)
        .reduce((acc, cur) => acc + cur, 0);

      state.updates.forecast = {
        summary: action.payload.summaries[0]?.summary ?? null,
        createdOn: action.payload.summaries[0]?.createdOn ?? null,
        feedback: action.payload.summaries[0]?.feedback ?? null,
        totalChanges: totalForecastChanges,
        created:
          Object.values(
            action.payload.rawResponse[0].createdRecords?.records ??
              action.payload.rawResponse[0].CreatedRecords?.Records,
          )?.length ?? 0,
        updated:
          Object.values(
            action.payload.rawResponse[0].updatedRecords?.records ??
              action.payload.rawResponse[0].UpdatedRecords?.Records,
          )?.length ?? 0,
        deleted:
          Object.values(
            action.payload.rawResponse[0].deletedRecords?.records ??
              action.payload.rawResponse[0].DeletedRecords?.Records,
          )?.length ?? 0,
        unusualActivity: totalForecastChanges > UNUSUAL_ACTIVITY_TRIGGER_COUNT,
      };
      state.updates.contracts = {
        summary: action.payload.summaries[1]?.summary ?? null,
        createdOn: action.payload.summaries[1]?.createdOn ?? null,
        feedback: action.payload.summaries[1]?.feedback ?? null,
      };
      state.updates.grants = {
        summary: action.payload.summaries[2]?.summary ?? null,
        createdOn: action.payload.summaries[2]?.createdOn ?? null,
        feedback: action.payload.summaries[2]?.feedback ?? null,
      };

      state.updates.forecastSummaryId = action.payload.summaries[0].aiSummaryId;
      state.updates.contractsDatasetSummaryId =
        action.payload.summaries[1].datasetSummaryId;
      state.updates.grantsDatasetSummaryId =
        action.payload.summaries[2].datasetSummaryId;

      state.updates.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getOverviewData.rejected]: (state) => {
      state.updates.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [getPipelineUpdatesDetails.pending]: (state) => {
      state.opportunitiesDetails.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getPipelineUpdatesDetails.fulfilled]: (
      state,
      action: PayloadAction<any>,
    ) => {
      const {
        attributes,
        totalItems,
        lastUpdatedUtc,
        data,
        pageIndex,
        pageSize,
        loadMore,
      } = action.payload;
      const currentState = current(state);
      state.opportunitiesDetails.attributes = attributes;
      state.opportunitiesDetails.data = data;
      state.opportunitiesDetails.lastUpdatedUtc = lastUpdatedUtc;
      state.opportunitiesDetails.pageIndex = pageIndex;
      state.opportunitiesDetails.pageSize = pageSize;
      state.opportunitiesDetails.total = totalItems;
      state.opportunitiesDetails.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getPipelineUpdatesDetails.rejected]: (state) => {
      state.opportunitiesDetails.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [getPipelineUpdates.pending]: (state) => {
      state.pipelineUpdates.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getPipelineUpdates.fulfilled]: (state, action) => {
      const items = action.payload;
      state.pipelineUpdates.fetchStatus = RequestStatus.statuses.DONE;
      state.pipelineUpdates.count = items.length;
      state.pipelineUpdates.items = items;
      state.pipelineUpdates.paginatedItems = items.slice(0, 20);
    },
    [getPipelineUpdates.rejected]: (state) => {
      state.pipelineUpdates.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [createNewEvent.pending]: (state) => {
      state.event.post.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [createNewEvent.fulfilled]: (state) => {
      state.event.post.fetchStatus = RequestStatus.statuses.DONE;
    },
    [createNewEvent.rejected]: (state) => {
      state.event.post.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [updateEvent.pending]: (state) => {
      state.event.put.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [updateEvent.fulfilled]: (state) => {
      state.event.put.fetchStatus = RequestStatus.statuses.DONE;
    },
    [updateEvent.rejected]: (state) => {
      state.event.put.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [deleteEvent.pending]: (state) => {
      state.event.delete.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [deleteEvent.fulfilled]: (state) => {
      state.event.delete.fetchStatus = RequestStatus.statuses.DONE;
    },
    [deleteEvent.rejected]: (state) => {
      state.event.delete.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [deleteAccount.pending]: (state) => {
      state.deleteAccount.fetchStatus = RequestStatus.statuses.FETCHING;
      state.deleteAccount.error = null;
    },
    [deleteAccount.fulfilled]: (state) => {
      state.deleteAccount.fetchStatus = RequestStatus.statuses.DONE;
    },
    [deleteAccount.rejected]: (state, action) => {
      state.deleteAccount.fetchStatus = RequestStatus.statuses.ERROR;
      state.deleteAccount.error =
        action.payload?.error ?? "There was an error.";
    },

    [sendSummaryFeedback.pending]: (state) => {
      state.updates.feedback.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [sendSummaryFeedback.fulfilled]: (state) => {
      state.updates.feedback.fetchStatus = RequestStatus.statuses.DONE;
    },
    [sendSummaryFeedback.rejected]: (state, action) => {
      state.updates.feedback.errors = action.payload;
      state.updates.feedback.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [updateSummaryFeedback.pending]: (state) => {
      state.updates.updateFeedback.fetchStatus =
        RequestStatus.statuses.FETCHING;
    },
    [updateSummaryFeedback.fulfilled]: (state) => {
      state.updates.updateFeedback.fetchStatus = RequestStatus.statuses.DONE;
    },
    [updateSummaryFeedback.rejected]: (state, action) => {
      state.updates.updateFeedback.errors = action.payload;
      state.updates.updateFeedback.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [deleteSummaryFeedback.pending]: (state) => {
      state.updates.deleteFeedback.fetchStatus =
        RequestStatus.statuses.FETCHING;
    },
    [deleteSummaryFeedback.fulfilled]: (state) => {
      state.updates.deleteFeedback.fetchStatus = RequestStatus.statuses.DONE;
    },
    [deleteSummaryFeedback.rejected]: (state, action) => {
      state.updates.deleteFeedback.errors = action.payload;
      state.updates.deleteFeedback.fetchStatus = RequestStatus.statuses.ERROR;
    },
  },
});

export const { reducer } = slice;

export const {
  resetCreateEventState,
  resetUpdateEventState,
  resetDeleteAccountState,
  resetFeedbackUpdateState,
} = slice.actions;
