import * as React from "react";
import { useRef } from "react";
import { css } from "glamor";
import { Tooltip } from "primereact/tooltip";
import ThemeContext from "../configs/theme";

type IconToolTipProps = {
  tooltipText: string;
  className?: string;
};

const IconTooltip: React.FunctionComponent<IconToolTipProps> = ({
  tooltipText,
  className,
  children,
}) => {
  const theme = React.useContext(ThemeContext);

  const styles = {
    icon: {
      display: "inline-block",
      position: "relative",
      backgroundColor: theme.v2.darkBlue,
      borderRadius: "50%",
      height: "1.5em",
      width: "1.5em",
      border: "1px solid black",
      color: "white",
      textAlign: "center",
    },
  };

  const iconRef = useRef(null);

  const defaultIcon = (
    <div {...css(styles.icon)} ref={iconRef} data-pr-tooltip={tooltipText}>
      ?
    </div>
  );

  const passedInChildren = (
    <div ref={iconRef} data-pr-tooltip={tooltipText}>
      {children}
    </div>
  );

  return (
    <>
      <Tooltip target={iconRef.current} position="top" className={className} />
      {children ? passedInChildren : defaultIcon}
    </>
  );
};

export default IconTooltip;
