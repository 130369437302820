import React, { FC, useState } from "react";
import { Box, ButtonGroup, TextField } from "@mui/material";
import Button from "@mui/material/Button";

type Tab = "all" | "my";

interface NotesProps {}

const Notes: FC<NotesProps> = (props) => {
  const {} = props;

  const [selectedTab, setSelectedTab] = useState<Tab>("all");

  const handleChangeTab = (tab: Tab): void => {
    if (selectedTab !== tab) {
      setSelectedTab(tab);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: "24px",
      }}
    >
      <ButtonGroup
        disableElevation
        size={"small"}
        sx={{
          "& > button": { borderRadius: "8px" },
        }}
      >
        <Button
          onClick={() => handleChangeTab("all")}
          color={"secondary"}
          variant={selectedTab === "all" ? "contained" : "secondaryContained"}
        >
          All Notes
        </Button>
        <Button
          onClick={() => handleChangeTab("my")}
          color={"secondary"}
          variant={selectedTab === "my" ? "contained" : "secondaryContained"}
        >
          My Notes
        </Button>
      </ButtonGroup>
      <TextField multiline rows={15} />
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
        <Button
          sx={{
            borderRadius: "8px",
          }}
          variant={"secondaryContained"}
        >
          Cancel
        </Button>
        <Button
          sx={{
            borderRadius: "8px",
          }}
          variant={"contained"}
          color={"secondary"}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default Notes;
