import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";
import withReact from "sweetalert2-react-content";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { convertToUtc, UpdateType } from "../Update";
import { removeHtmlTags } from "../../services/dataParser";
const MySwal = withReact(Swal);

export const AddUpdateFormComp = async (props: UpdateType) => {
  const options = [
    { label: "", value: "" },
    { label: "USAID Press Release", value: "USAID Press Release" },
  ];
  return await MySwal.fire({
    title: "Create New Update",
    width: "800px",
    html: (
      <>
        <div key="updateTitle">
          <label>
            Title <label style={{ color: "red" }}>*</label>
          </label>
          <InputText
            style={{
              margin: "10px 0",
              borderRadius: "30px",
              width: "100%",
            }}
            onChange={(c) => (props.title = (c.target as any).value)}
          />
        </div>

        <div key="updateDate">
          <label>
            Date <label style={{ color: "red" }}>*</label>
          </label>
          <Calendar
            placeholder="mm/dd/yyyy"
            dateFormat="mm/dd/yy"
            value={props.createdOn ?? new Date()}
            style={{
              margin: "10px 0",
              borderRadius: "30px",
              width: "100%",
            }}
            onChange={(c) =>
              (props.createdOn = convertToUtc((c.target as any).value))
            }
          />
        </div>
        <div key="updateSource">
          <label>Source</label>
          <select
            style={{
              margin: "10px 0",
              borderRadius: "30px",
              width: "100%",
              height: "35px",
              padding: "9px",
            }}
            onChange={(s) => (props.source = s.target.value)}
          >
            {options.map((option) => (
              <option value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>

        <div style={{ height: 250 }} key="updatemessage">
          <label>
            Update Body <label style={{ color: "red" }}>*</label>
          </label>
          <ReactQuill
            style={{ height: 150 }}
            theme="snow"
            value={props.message}
            onChange={(c) => (props.message = c)}
          />
        </div>
      </>
    ),
    showCancelButton: true,
    showLoaderOnConfirm: true,
    validationMessage:
      "Please fill out all mandatory fields, or click “Cancel” to disregard the changes.",
    preConfirm: () => {
      if (props.message && props.title && removeHtmlTags(props.message)) {
        MySwal.resetValidationMessage();
        return true;
      } else {
        MySwal.showValidationMessage(
          "Please fill out all mandatory fields, or click “Cancel” to disregard the changes."
        );
        return false;
      }
    },
    allowOutsideClick: () => !MySwal.isLoading(),
  }).then((r) => {
    return { isConfirmed: r.isConfirmed, model: props };
  });
};
