import { createSvgIcon } from "@mui/material/utils";

export const AwardIcon = createSvgIcon(
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7453 3.09104C11.7239 3.01123 11.687 2.93644 11.6366 2.871C11.5862 2.80556 11.5233 2.75077 11.4515 2.70979L8.20153 0.834788C8.13057 0.793205 8.0521 0.766037 7.97062 0.754841C7.88914 0.743645 7.80625 0.748642 7.7267 0.769545C7.64715 0.790447 7.57251 0.826845 7.50706 0.876651C7.44161 0.926457 7.38663 0.988692 7.34528 1.05979L5.88278 3.59729L4.42028 1.05979C4.37893 0.988692 4.32395 0.926457 4.2585 0.876651C4.19305 0.826845 4.11841 0.790447 4.03886 0.769545C3.95931 0.748642 3.87642 0.743645 3.79494 0.754841C3.71346 0.766037 3.63499 0.793205 3.56403 0.834788L0.314029 2.70979C0.242299 2.75077 0.179406 2.80556 0.128985 2.871C0.0785639 2.93644 0.0416165 3.01123 0.0202794 3.09104C-0.0210852 3.24944 0.00135646 3.41775 0.0827795 3.55979L2.58278 7.82854C2.30387 8.3395 2.14968 8.90915 2.13278 9.49104C2.13129 10.0587 2.25871 10.6193 2.50542 11.1306C2.75213 11.6419 3.11169 12.0904 3.557 12.4425C4.00231 12.7946 4.52175 13.041 5.07614 13.1631C5.63053 13.2852 6.20541 13.2798 6.75743 13.1474C7.30945 13.015 7.82421 12.759 8.26289 12.3987C8.70157 12.0384 9.05272 11.5832 9.28987 11.0674C9.52702 10.5517 9.64398 9.98878 9.63192 9.42123C9.61987 8.85368 9.47912 8.29627 9.22028 7.79104L11.6578 3.56604C11.7031 3.49663 11.7342 3.41888 11.7493 3.33733C11.7643 3.25578 11.7629 3.17206 11.7453 3.09104ZM1.50778 3.47854L3.67028 2.22854L5.68278 5.74104C4.82492 5.79404 4.01202 6.14305 3.38278 6.72854L1.50778 3.47854ZM5.88278 11.991C5.21974 11.991 4.58385 11.7276 4.11501 11.2588C3.64617 10.79 3.38278 10.1541 3.38278 9.49104C3.38278 8.828 3.64617 8.19211 4.11501 7.72327C4.58385 7.25443 5.21974 6.99104 5.88278 6.99104C6.54582 6.99104 7.18171 7.25443 7.65055 7.72327C8.11939 8.19211 8.38278 8.828 8.38278 9.49104C8.38278 10.1541 8.11939 10.79 7.65055 11.2588C7.18171 11.7276 6.54582 11.991 5.88278 11.991ZM8.38278 6.70979C8.04345 6.39986 7.64918 6.15609 7.22028 5.99104L6.60778 4.84729L8.12028 2.22854L10.2828 3.47854L8.38278 6.70979Z"
      fill="#828282"
    />
  </svg>,
  "Award",
);
