import { createSvgIcon } from "@mui/material/utils";

export const BoltIcon = createSvgIcon(
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.26 5.36653C11.2046 5.25659 11.1199 5.16413 11.0151 5.09941C10.9104 5.0347 10.7898 5.00024 10.6667 4.99987H7.33336V0.999866C7.34052 0.853655 7.29935 0.709154 7.21621 0.588666C7.13308 0.468179 7.0126 0.378404 6.87336 0.333199C6.73951 0.289161 6.59515 0.288667 6.461 0.331786C6.32686 0.374905 6.20982 0.45942 6.1267 0.573199L0.793363 7.90653C0.72654 8.00311 0.686416 8.11563 0.67705 8.23269C0.667685 8.34976 0.689411 8.46723 0.74003 8.5732C0.786644 8.69437 0.867622 8.79928 0.973023 8.87508C1.07842 8.95087 1.20366 8.99424 1.33336 8.99987H4.6667V12.9999C4.6668 13.1405 4.71134 13.2774 4.79396 13.3912C4.87658 13.5049 4.99304 13.5896 5.1267 13.6332C5.19368 13.654 5.26325 13.6652 5.33336 13.6665C5.43855 13.6668 5.54232 13.6422 5.63617 13.5947C5.73002 13.5472 5.8113 13.4781 5.87336 13.3932L11.2067 6.05987C11.2785 5.9604 11.3215 5.84304 11.3309 5.7207C11.3403 5.59837 11.3158 5.47582 11.26 5.36653ZM6.00003 10.9465V8.3332C6.00003 8.15639 5.92979 7.98682 5.80477 7.86179C5.67974 7.73677 5.51017 7.66653 5.33336 7.66653H2.6667L6.00003 3.0532V5.66653C6.00003 5.84334 6.07027 6.01291 6.19529 6.13794C6.32032 6.26296 6.48989 6.3332 6.6667 6.3332H9.33336L6.00003 10.9465Z"
      fill="white"
    />
  </svg>,
  "Bolt",
);
