import React from "react";
import { Column } from "primereact/column";
import {
  GetCustomColumnBody,
  GetCustomColumnShortened,
} from "../../../components/DataTable/Columns";
import {
  DateFormat,
  formatDate,
} from "../../../utils/conversion/date-converters";
import { DataTable } from "primereact/datatable";
import { getSortIfDescendingFirst } from "../../../services/functions";
import { useGridStyles } from "../../../hooks/useGridStyles";
import { Checkbox } from "primereact/checkbox";
import { rowsPerPageOptionsStandard } from "../../../utils/constants/gridsOptions";

export interface TableRecord {
  id: string;
  organization: string;
  userName: string;
  email: string;
  tier: string;
  expirationDate: Date;
  paymentType: string;
  emailPreferences: string;
  lastLogin: Date;
  actions: string;
  passwordReset: string;
  confirmAccount: boolean;
  freezeAccount: boolean;
}

type UserManagementTableProps = {
  isLoading: boolean;

  // Filtering/sorting/querying
  rowsPerPage: number;
  sortField: string;
  setSortField: React.Dispatch<string>;
  sortOrder: number;
  setSortOrder: React.Dispatch<number>;
  first: number;
  totalRecords: number;

  // Grid state
  data: Array<TableRecord>;
  setData: React.Dispatch<Array<TableRecord>>;
  lastUpdated: string;
  gridWidth: number;

  // Notes info
  originalNotes: Array<{ id: number; note: string }>;
  setOriginalNotes: React.Dispatch<Array<{ id: number; note: string }>>;

  isMyContracts?: boolean;
  decrement?: () => void;

  queryData: (event: {
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
  }) => void;
};

const UserManagementTable: React.FC<UserManagementTableProps> = ({
  isLoading,
  rowsPerPage,
  sortField,
  setSortField,
  sortOrder,
  setSortOrder,
  first,
  totalRecords,
  data,
  gridWidth,
  queryData,
}): JSX.Element => {
  const styles = useGridStyles();

  const [expandedRows, setExpandedRows] = React.useState(Array<TableRecord>());

  const sort = (event: { sortField: string; sortOrder: number }) => {
    const newSortOrder = getSortIfDescendingFirst(event, sortField);
    setSortField(event.sortField);
    setSortOrder(newSortOrder);
    queryData({
      first,
      rows: rowsPerPage,
      sortField: event.sortField,
      sortOrder: newSortOrder,
    });
  };

  const RecordExpandedView = ({ record }: { record: TableRecord }) => {
    const styles = useGridStyles();

    return (
      <DataTable value={data} scrollHeight="600px" scrollable={true}>
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => x.emailPreferences,
          "Email Preferences",
          "emailPreferences",
          { sortable: false }
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => formatDate(x.lastLogin, DateFormat.dd_MMMM_yyyy),
          "Last Login",
          "lastLogin",
          { sortable: false }
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => x.tier,
          "Tier",
          "tier",
          { sortable: false }
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => x.actions,
          "Actions",
          "actions",
          { sortable: false }
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => x.passwordReset,
          "Password Reset",
          "passwordReset",
          { sortable: false }
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => {
            return (
              <Checkbox
                inputId="cb32"
                value="confirmAccount"
                checked={x.confirmAccount}
              />
            );
          },
          "Confirm Account",
          "confirmAccount",
          { sortable: false }
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => {
            return (
              <Checkbox
                inputId="cb33"
                value="freezeAccount"
                checked={x.freezeAccount}
              />
            );
          },
          "Freeze Account",
          "freezeAccount",
          { sortable: false }
        )}
      </DataTable>
    );
  };

  return (
    <DataTable
      value={data}
      style={{ marginTop: 20, width: `${gridWidth + 20}px` }}
      paginator={true}
      rows={rowsPerPage}
      totalRecords={totalRecords}
      lazy={true}
      first={first}
      onPage={queryData}
      loading={isLoading}
      sortField={sortField}
      sortOrder={sortOrder}
      onSort={sort}
      header={<div />}
      scrollable={true}
      scrollHeight="400px"
      expandedRows={expandedRows}
      onRowToggle={(e: any) => setExpandedRows(e.data)}
      rowExpansionTemplate={(d: TableRecord) => (
        <RecordExpandedView record={d} />
      )}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      rowsPerPageOptions={rowsPerPageOptionsStandard}
    >
      <Column
        headerStyle={styles.headerStyle(40)}
        style={styles.columnStyle(40)}
        expander
      />
      {GetCustomColumnBody<TableRecord>(
        styles,
        250,
        (x) => x.organization,
        "Organization",
        "organization"
      )}
      {GetCustomColumnBody<TableRecord>(
        styles,
        250,
        (x) => x.userName,
        "User Name",
        "userName",
        { sortable: false }
      )}
      {GetCustomColumnBody<TableRecord>(
        styles,
        350,
        (x) => x.email,
        "Email",
        "email",
        { sortable: false }
      )}
      {GetCustomColumnBody<TableRecord>(
        styles,
        150,
        (x) => x.tier,
        "Tier",
        "tier"
      )}
      {GetCustomColumnBody<TableRecord>(
        styles,
        150,
        (x) => formatDate(x.expirationDate, DateFormat.dd_MMMM_yyyy),
        "Expiration Date",
        "expirationDate"
      )}
      {GetCustomColumnBody<TableRecord>(
        styles,
        250,
        (x) => x.paymentType,
        "Payment Type",
        "paymentType",
        { sortable: false }
      )}
    </DataTable>
  );
};

export default UserManagementTable;
