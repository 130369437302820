import { useState } from "react";
import "./SubmitFeedBack.css";
import { AskAQuestionUrl } from "../configs/resources";

const SubmitFeedback = () => {
  const [visibleTooltip, setVisibleTooltip] = useState<boolean>(false);

  return (
    <div className="feedback-cont">
      <div
        onMouseOver={() => setVisibleTooltip(true)}
        onMouseLeave={() => setVisibleTooltip(false)}
        className={
          visibleTooltip
            ? "feedback-tooltip visible"
            : "feedback-tooltip hidden"
        }
      >
        <div>
          <p className="feedback-tooltip-header">Are we missing something?</p>
        </div>
        <div>
          <p className="feedback-tooltip-body">
            We want AidKonekt to be the one stop shop for all your USAID
            Business Development.
          </p>
        </div>
        <a
          rel="noreferrer"
          className="submit-feedback"
          href={AskAQuestionUrl} //"https://airtable.com/shruq8FTaSVt5M1Jf"
          target="_blank"
        >
          Submit your Feedback
        </a>
      </div>
      <svg
        className="feedback"
        width="70"
        height="70"
        onMouseOver={() => setVisibleTooltip(true)}
        onMouseLeave={() => setVisibleTooltip(false)}
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_378_5488)">
          <rect x="8" y="9" width="50" height="50" rx="25" fill="#ECAC37" />
          <path
            d="M25.8224 35.4138C25.446 35.4827 25.0609 35.5175 24.6773 35.5175C21.1894 35.5178 18.3514 32.6801 18.3514 29.1922C18.3514 25.7043 21.1894 22.8666 24.677 22.8666C26.5514 22.8666 28.3184 23.6913 29.5246 25.1291C29.8371 25.5022 30.3938 25.5511 30.7672 25.238C31.1403 24.9249 31.1892 24.3685 30.8761 23.9954C29.3336 22.157 27.0739 21.103 24.677 21.103C20.2168 21.1027 16.5881 24.7318 16.5881 29.1919C16.5881 33.6523 20.2172 37.2815 24.6778 37.2815C25.1677 37.2815 25.6602 37.2366 26.1409 37.1483C26.6201 37.061 26.9372 36.6012 26.8496 36.1223C26.7616 35.6428 26.3027 35.3254 25.8229 35.4136L25.8224 35.4138Z"
            fill="#FFE4B8"
          />
          <path
            d="M21.186 27.0068V27.9197C21.186 28.4066 21.5813 28.8015 22.0678 28.8015C22.5547 28.8015 22.9496 28.4063 22.9496 27.9197V27.0068C22.9496 26.5199 22.5544 26.125 22.0678 26.125C21.5812 26.125 21.186 26.5196 21.186 27.0068Z"
            fill="#FFE4B8"
          />
          <path
            d="M28.1679 27.9192V27.0063C28.1679 26.5194 27.7727 26.1245 27.2861 26.1245C26.7992 26.1245 26.4043 26.5197 26.4043 27.0063V27.9192C26.4043 28.4061 26.7995 28.801 27.2861 28.801C27.773 28.801 28.1679 28.4064 28.1679 27.9192Z"
            fill="#FFE4B8"
          />
          <path
            d="M26.226 30.3643H23.1281C22.6412 30.3643 22.2463 30.7595 22.2463 31.2461C22.2463 31.7329 22.6416 32.1279 23.1281 32.1279H26.226C26.7129 32.1279 27.1078 31.7326 27.1078 31.2461C27.1078 30.7592 26.7132 30.3643 26.226 30.3643Z"
            fill="#FFE4B8"
          />
          <path
            d="M38.6347 24.2939C32.5518 24.2939 27.6025 29.2425 27.6025 35.3261C27.6025 41.409 32.5511 46.3582 38.6347 46.3582C44.7182 46.3582 49.6668 41.409 49.6668 35.3261C49.6668 29.2428 44.7176 24.2939 38.6347 24.2939ZM38.6347 44.5936C33.5243 44.5936 29.3663 40.4361 29.3663 35.3253C29.3663 30.2144 33.5241 26.0577 38.6347 26.0577C43.7452 26.0577 47.903 30.2152 47.903 35.3261C47.903 40.4369 43.7449 44.5936 38.6347 44.5936Z"
            fill="#FFE4B8"
          />
          <path
            d="M35.8425 33.5343V32.248C35.8425 31.7611 35.4472 31.3662 34.9607 31.3662C34.4738 31.3662 34.0789 31.7614 34.0789 32.248V33.5343C34.0789 34.0212 34.4741 34.4161 34.9607 34.4161C35.4475 34.4161 35.8425 34.0212 35.8425 33.5343Z"
            fill="#FFE4B8"
          />
          <path
            d="M42.3086 31.3657C41.8217 31.3657 41.4268 31.761 41.4268 32.2475V33.5338C41.4268 34.0207 41.822 34.4156 42.3086 34.4156C42.7954 34.4156 43.1904 34.0204 43.1904 33.5338V32.2475C43.1904 31.7603 42.7957 31.3657 42.3086 31.3657Z"
            fill="#FFE4B8"
          />
          <path
            d="M40.186 37.1412C39.331 37.9956 37.9398 37.9965 37.0844 37.1412C36.7405 36.7967 36.1813 36.7967 35.8375 37.1412C35.493 37.4857 35.493 38.0439 35.8375 38.3881C36.6092 39.1598 37.622 39.5452 38.6351 39.5452C39.6483 39.5452 40.6617 39.1598 41.4328 38.3881C41.7773 38.0436 41.7773 37.4853 41.4328 37.1412C41.0886 36.797 40.5304 36.797 40.1859 37.1412H40.186Z"
            fill="#FFE4B8"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_378_5488"
            x="0"
            y="0"
            width="70"
            height="70"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="1" />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.408333 0 0 0 0 0.404931 0 0 0 0 0.404931 0 0 0 0.29 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_378_5488"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_378_5488"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default SubmitFeedback;
