import { saveAs } from "file-saver";
import ExcelJS, {
  Column,
  Table,
  TableColumnProperties,
  TableProperties,
} from "exceljs/dist/exceljs";
import axios from "axios";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../configs/axios-export.custom";
import { getISODateWithAdjustedTimezone } from "../utils/conversion/date-converters";

export const MaxCharsAllowed = 32766;

export const buildExcelTable = (
  tableName: string,
  columns: TableColumnProperties[],
  rows: any[][],
): TableProperties => {
  return {
    name: tableName,
    ref: "A1",
    columns: columns,
    rows: rows,
  };
};

export const generateExcel = async (
  sheetName: string,
  fileName: string,
  dataTable: TableProperties,
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);
  const { table } = worksheet.addTable(dataTable) as unknown as {
    table: Table;
  }; // TS definition is wrong

  for (let i = 1; i <= dataTable.columns.length; i++) {
    const column = worksheet.getColumn(i);
    column.width = determineColumnWidth(column, table.columns[i - 1].name);
    column.alignment = { wrapText: true };
  }

  const buffer = await workbook.xlsx.writeBuffer();
  saveAsExcelFile(buffer, fileName);
};

export const generateExcelFromBlob = (file: string, fileName: string): void => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.xlsx`);
  document.body.appendChild(link);
  link.click();
};

const saveAsExcelFile = (buffer: any, fileName: string): void => {
  import("file-saver").then(() => {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(data, fileName + EXCEL_EXTENSION);
  });
};

const determineColumnWidth = (column: Column, columnName: string) => {
  const min_column_width = 15;
  const max_column_width = 60;
  let widthBasedOnContent = min_column_width;

  column.eachCell(
    (cell) =>
      (widthBasedOnContent = Math.max(
        widthBasedOnContent,
        Math.min(cell.text.length, max_column_width),
      )),
  );

  return Math.max(widthBasedOnContent, min_column_width);
};

export const getChunksFromString = (
  str: string,
  chunkSize: number,
): string[] => {
  if (str === null) return [""];
  const numChunks = Math.ceil(str.length / chunkSize);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += chunkSize) {
    chunks[i] = str.substr(o, chunkSize);
  }

  return chunks;
};

export const genEmptyRow = (size: number): string[] => {
  const chunks = new Array(size);

  for (let i = 0; i < size; ++i) {
    chunks[i] = "";
  }

  return chunks;
};

const converOpportunitiestDate = (date: Date) =>
  `${date.getFullYear()}-${
    date.getMonth() + 1 > 10 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
  }-${
    date.getDate() + 1 > 10 ? date.getDate() : "0" + date.getDate()
  }T11:00:00.000Z`;

export const excelFileRequest = async (
  context,
  toDate: Date,
  fromDate: Date,
  ids: number[],
  username?: string,
) => {
  const fromDateDisplay = `${
    fromDate.getDate() + 1 > 10 ? fromDate.getDate() : "0" + fromDate.getDate()
  }-${
    fromDate.getMonth() + 1 >= 10
      ? fromDate.getMonth() + 1
      : "0" + (fromDate.getMonth() + 1)
  }-${fromDate.getFullYear()}`;
  const toDateDisplay = `${
    toDate.getDate() + 1 > 10 ? toDate.getDate() : "0" + toDate.getDate()
  }-${
    toDate.getMonth() + 1 >= 10
      ? toDate.getMonth() + 1
      : "0" + (toDate.getMonth() + 1)
  }-${toDate.getFullYear()}`;

  try {
    const { data } = await axios.post(
      getFullUrl(`/api/pipeline/downloadopportunities`, {
        useDedicatedEnvironment: true,
      }),
      {
        // start: converOpportunitiestDate(fromDate),
        start: getISODateWithAdjustedTimezone(fromDate),
        // end: converOpportunitiestDate(toDate),
        end: getISODateWithAdjustedTimezone(
          toDate,
          false,
          true,
          "T11:00:00+00:00",
        ),
        pipelineIds: ids,
        username,
      },
      { ...createAuthenticatedRequest(context), responseType: "blob" },
    );
    generateExcelFromBlob(
      data,
      ids.length === 1
        ? `Aidkonekt_pipeline_#${ids[0]}_${fromDateDisplay}_${toDateDisplay}`
        : `Aidkonekt_pipelines_${fromDateDisplay}_${toDateDisplay}`,
    );
  } catch (e) {
    throw new Error(e);
  }
};

// function exportPdf() {
//     const doc = new jsPDF(0, 0);

//     doc.autoTable(this.exportColumns, this.products);
//     doc.save('products.pdf');

//     // import("jspdf").then(jsPDF => {
//     //     import("jspdf-autotable").then(x => {
//     //         const doc = new jsPDF.default(0,0);
//     //         doc.autoTable(this.exportColumns, this.products);
//     //         doc.save('products.pdf');
//     //     })
//     // })
// }

/*

exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default(0,0);
            doc.autoTable(this.exportColumns, this.products);
            doc.save('products.pdf');
        })
    })
}

 <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
            <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
            <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button>

*/
