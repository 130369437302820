import * as React from "react";
import UserContext from "../../services/UserContext";
import {
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import { Button } from "primereact/button";
import { useGridStyles } from "../../hooks/useGridStyles";
import axios from "axios";
import pricingIcon from "../../assets/imgs/Pricing_Tiers.png";

const UpgradePlanScreen: React.FunctionComponent = () => {
  const userContext = React.useContext(UserContext);
  const goToStripe = () => {
    axios
      .post(
        getFullUrl("/api/Payment/submitsubscription", {
          useDedicatedEnvironment: true,
        }),
        null,
        createRequestWithAuthHeaders(userContext)
      )
      .then((r) => {
        const { url } = r.data;
        window.open(url, "_blank");
      })
      .catch((e) => alert(e));
  };

  const styles = useGridStyles();

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <Button
          style={{ ...styles.pageButton, position: "fixed", right: 30 }}
          onClick={goToStripe}
          label="Upgrade Plan"
        />
      </div>
      <div className="p-col-10">
        <img
          src={pricingIcon}
          alt="Pricing tiers"
          style={{
            objectFit: "contain",
            maxWidth: "100%",
            maxHeight: "100%",
            minWidth: "650px",
          }}
        ></img>
      </div>
    </div>
  );
};

export default UpgradePlanScreen;
