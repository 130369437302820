import { Box, Card, Typography } from "@mui/material";
import React, { useState } from "react";
import ProjectsTable, { ProjectsTableContextType } from "./ProjectsTable";

export default function Projects() {
  const [tableContext, setTableContext] =
    useState<ProjectsTableContextType>("usa-spending");

  return (
    <Box
      sx={{
        p: 2,
        textAlign: "start",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant={"body2"} sx={{ mb: 3 }}>
        {tableContext === "usa-spending"
          ? "A database of USAID projects that allows you to quickly sort USAID project activity by closing date for your target countries, competitors, and technical sectors. This tool is designed to deliver rapid, landscape snapshots of USAID projects, but is not meant to be comprehensive of every USAID project. It is based on USAID project data from USA Spending."
          : "Search current and past USAID projects by country, sector, and closing date. This page allows you to quickly sort USAID project activity by closing date for your target countries, competitors, and technical sectors. This tool is designed to deliver rapid, landscape snapshots of USAID projects, and may not have every USAID project."}
      </Typography>
      <ProjectsTable
        containerSx={{
          height: "100%",
          position: "initial",
          borderRadius: "0 0 4px 4px !important",
        }}
        externalSetTableContext={setTableContext}
        useFragmentHeader={false}
        allowViewTypeSwitch
        showActions
        loadDataImmediately={false}
      />
    </Box>
  );
}
