import React, { FC, ReactNode, useEffect, useState } from "react";
import { Tooltip } from "@mui/material";

interface TooltipWithScrollHideProps {
  title: string;
  querySelectors: string;
  tooltipProps?: any;
  children: ReactNode;
}

const TooltipWithScrollHide: FC<TooltipWithScrollHideProps> = (props) => {
  const { title, querySelectors, tooltipProps = {}, children } = props;

  const [tipOpen, setTipOpen] = useState<boolean>(false);

  useEffect(() => {
    const scrollableElements = [
      document.querySelector(querySelectors),
      document,
    ];
    scrollableElements.forEach((element) => {
      element !== null &&
        element.addEventListener("scroll", (event) => {
          setTipOpen(false);
        });
    });
  }, [tipOpen]);

  return (
    <Tooltip
      title={title}
      open={tipOpen}
      onMouseEnter={() => {
        if (!tipOpen) setTipOpen(true);
      }}
      onMouseLeave={() => {
        if (tipOpen) setTipOpen(false);
      }}
      {...tooltipProps}
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export default TooltipWithScrollHide;
