import React, { useState } from "react";
import "./Webinars.css";
import YouTube from "react-youtube";
import { useGridStyles } from "../../hooks/useGridStyles";
import UserContext, {
  doesUserHaveRole,
  ServerOrgRoles,
} from "../../services/UserContext";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import { tryCatchServerError } from "../../services/functions";
import GrowlContext from "../../services/growlContext";
import { ProgressSpinner } from "primereact/progressspinner";
import axios from "axios";
import { useStyles } from "../../hooks/useStyles";

const Webinars: React.FC = (): JSX.Element => {
  const styles = useGridStyles();
  const style = useStyles();
  const user = React.useContext(UserContext);

  const [editable, setEditable] = useState(false);
  const [newPostTitle, setNewPostTitle] = useState("");
  const [newPostDescription, setNewPostDescription] = useState("");
  const [newPostLink, setNewPostLink] = useState("");

  const [editIndex, setEditIndex] = useState(-1);
  const [editPostTitle, setEditPostTitle] = useState("");
  const [editPostDescription, setEditPostDescription] = useState("");
  const [editPostLink, setEditPostLink] = useState("");

  const growl = React.useContext(GrowlContext);
  const [loading, setLoading] = React.useState(false);
  const [records, fetchRecords] = React.useState(
    Array<{ id: number, title: string; description: string; link: string }>()
  );
  const [reloader, reload] = React.useState(false);

  const videoOpts = {
    height: "200",
    width: "340",
  };

  const addPost = async () => {
    if (!newPostLink || !newPostTitle || !newPostDescription) return;

    try {
      const youtubeURL = new URL(newPostLink);
      const videoId = youtubeURL.searchParams.get("v");
      await axios
        .post(
          getFullUrl("/api/webinar", { useDedicatedEnvironment: true }),
          {
            Title: newPostTitle,
            Description: newPostDescription,
            Link: videoId,
          },
          createRequestWithAuthHeaders(user)
        )
        .then((r) => {
          setEditable(false);
          setNewPostTitle("");
          setNewPostDescription("");
          setNewPostLink("");
          reload(!reloader);
        });
    } catch (err) {
      //ignore when url is wrong
    }
  };

  const editPost = async () => {
    if (!editPostTitle || !editPostDescription || !editPostLink) return;

    const currentPost = records.find((r) => r.id === editIndex)

    const youtubeURL = new URL(editPostLink);
    const videoId = youtubeURL.searchParams.get("v");

    if (currentPost
      && currentPost.description === editPostDescription
      && currentPost.title === editPostTitle
      && currentPost.link === videoId
    ) {
      setEditPostTitle("");
      setEditPostDescription("");
      setEditPostLink("");
      setEditIndex(-1);
      return
    }

    try {

      await axios
        .put(
          getFullUrl("/api/webinar", { useDedicatedEnvironment: true }),
          {
            id: editIndex,
            Title: editPostTitle,
            Description: editPostDescription,
            Link: videoId,
          },
          createRequestWithAuthHeaders(user)
        )
        .then((r) => {
          setEditPostTitle("");
          setEditPostDescription("");
          setEditPostLink("");
          setEditIndex(-1);
          reload(!reloader);
        });
    } catch (err) {
      //ignore when url is wrong
    } finally {
      growl.current.show({
        severity: "success",
        summary: "Record has been edited",
      });
    }
  };


  const deletePost = async (id: number) => {

    try {
      await axios
        .delete(
          getFullUrl(`/api/webinar/${id}`, { useDedicatedEnvironment: true }),
          createRequestWithAuthHeaders(user)
        )
        .then((r) => {
          reload(!reloader);
        });
    } catch (err) {
      //ignore when url is wrong
    } finally {
      growl.current.show({
        severity: "success",
        summary: "Record has been deleted",
      });
    }
  };

  const isAdmin =
    doesUserHaveRole(user, ServerOrgRoles.admin) ||
    doesUserHaveRole(user, ServerOrgRoles.partialAdmin);

  React.useEffect(() => {
    setLoading(true);
    const request = createAuthenticatedRequest(user);

    axios
      .get(
        getFullUrl("/api/webinar", { useDedicatedEnvironment: true }),
        request
      )
      .then((r) => {
        fetchRecords(r.data);
        setLoading(false);
      })
      .catch(
        tryCatchServerError(() => {
          growl.current.show({
            severity: "error",
            summary: "Error getting webinars",
            detail: "Error getting webinars",
          });
          setLoading(false);
        })
      );
  }, [reloader]);

  if (loading) {
    return <ProgressSpinner />;
  }

  return (
    <div className="resources-screen webinars">
      <section className="header">
        <div className="header-title">
          <img
            src={styles.aidkonektIcon}
            style={styles.icon}
            alt="Aidkonekt"
            className="formIcon"
          />
          <span>USAID Resources</span>
        </div>
        <span className="header-subtitle">
          Curated Key Resources for working with USAID
        </span>
        <span className="header-description">
          As a company, we have more than half a decade of experience supporting
          our clients' USAID proposal development, and collectively, our core
          network of professionals has centuries of experience in all aspects of
          working with USAID, from proposals to implementation to closeout and
          follow-on activities. With this depth and breadth of experience
          working with USAID, we would like to share the following resources. We
          hope you find them useful as you and your team partner with USAID to
          implement their important work.
        </span>
      </section>

      {isAdmin && (
        <section>
          {editable ? (
            <div className="add-new-post">
              <input
                type="text"
                className="new-post-header"
                onChange={(e) => setNewPostTitle(e.currentTarget.value)}
              />
              <div className="new-post-content">
                <div className="new-post-date-container">
                  <span className="new-post-date-helper description">
                    description:
                  </span>
                  <textarea
                    className="new-post-date"
                    onChange={(e) =>
                      setNewPostDescription(e.currentTarget.value)
                    }
                  />
                </div>
                <div className="new-post-link-container">
                  <span className="new-post-link-helper">video link:</span>
                  <input
                    type="text"
                    className="new-post-link"
                    onChange={(e) => setNewPostLink(e.currentTarget.value)}
                  />
                </div>
              </div>
              <button
                type="button"
                className="card-btn aid-blu-btn"
                onClick={() => addPost()}
                disabled={!newPostLink || !newPostTitle || !newPostDescription}
              >
                Save
              </button>
            </div>
          ) : (
            <div
              className="add-btn-container"
              onClick={() => setEditable(true)}
            >
              <button type="button" className="add-btn aid-blu-btn">
                +
              </button>
              <span className="add-btn-title">Add new post</span>
            </div>
          )}
        </section>
      )}

      <section className="resources-cards-block">
        {records.map((r, index) => (
          <div className="card-container">
            <div className="card-title-container">
              {r.id === editIndex
                ? <input
                  className="card-title"
                  value={editPostTitle}
                  onChange={e => setEditPostTitle(e.currentTarget.value)}
                />
                : <h3 className="card-title">{r.title.toUpperCase()}</h3>
              }
              {isAdmin && <div>
                {r.id === editIndex
                  ? <img
                    src={style.checkIcon}
                    style={style.icon}
                    alt="edit"
                    className="removeeIcon"
                    onClick={editPost}
                  />
                  : <img
                  src={style.editIcon}
                  style={style.icon}
                  alt="edit"
                  className="removeeIcon"
                  onClick={() => {
                    setEditPostTitle(r.title.toUpperCase());
                    setEditPostDescription(r.description);
                    setEditPostLink(`https://www.youtube.com/watch?v=${r.link}`);
                    setEditIndex(r.id);
                  }}
                />}
                <img
                  src={style.removeIcon}
                  style={style.icon}
                  alt="remove"
                  className="removeeIcon"
                  onClick={() => deletePost(r.id)}
                />
              </div>}
            </div>
            <div className="card-content">
              {r.id === editIndex
                ? <textarea
                  value={editPostDescription}
                  onChange={(e) => setEditPostDescription(e.currentTarget.value)}
                />
                : <span>{r.description}</span>
              }
            </div>
            {r.id === editIndex
              ? <input
                className="edit-link-input"
                value={editPostLink}
                onChange={e => setEditPostLink(e.currentTarget.value)}
              />
              : <YouTube videoId={r.link} opts={videoOpts} className="card-video" />}
            {r.id !== editIndex && <button
              type="button"
              className="card-btn aid-blu-btn"
              onClick={() =>
                window.open(
                  `https://www.youtube.com/watch?v=${r.link}`,
                  "_blank"
                )
              }
            >
              Open in Youtube
            </button>}
          </div>
        ))}
      </section>
    </div>
  );
};

export default Webinars;
