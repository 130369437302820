import Input from "../../components/Widgets/Inputs/Input";
import { RootState, useDispatch, useSelector } from "../../store";
import React, { useContext } from "react";
import UserContext from "../../services/UserContext";
import { getCountryDropdownOptions } from "../../slices/contracts";
import { getDropdownOptionsForReports } from "../../slices/reports";

interface ReportsFiltersProps {
  control: any;
}

export default function ReportsFilters({ control }: ReportsFiltersProps) {
  const dispatch = useDispatch();
  const context = useContext(UserContext);

  const {
    dropdownOptions: {
      country: { items: _country, fetchStatus: countryFetchStatus },
      technicalSector: {
        items: _technicalSector,
        fetchStatus: technicalSectorFetchStatus,
      },
      authoringOrg: {
        items: _authoringOrg,
        fetchStatus: authoringOrgFetchStatus,
      },
    },
  } = useSelector((state: RootState) => state.reports);

  return (
    <>
      <Input
        type={"switch"}
        name={"onlyAttachments"}
        control={control}
        label={"Only show results with attachments"}
      />
      <Input
        type={"combo-box"}
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        control={control}
        name={"country"}
        label={"COUNTRY"}
        options={_country}
        multiple
        withCheckboxes
        fullWidth
        fetchStatus={countryFetchStatus}
        asyncCallback={(value: string) => {
          dispatch(
            getDropdownOptionsForReports({
              context,
              target: ["country"],
              filter: value,
            }),
          );
        }}
      />
      <Input
        type={"combo-box"}
        withCheckboxes
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        control={control}
        name={"sector"}
        label={"TECHNICAL SECTOR"}
        options={_technicalSector}
        fullWidth
        multiple
        fetchStatus={technicalSectorFetchStatus}
        asyncCallback={(value: string) => {
          dispatch(
            getDropdownOptionsForReports({
              context,
              target: ["technicalSector"],
              filter: value,
            }),
          );
        }}
      />
      <Input
        type={"combo-box"}
        withCheckboxes
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        control={control}
        name={"authoringOrg"}
        label={"AUTHORING ORGANIATION"}
        options={_authoringOrg}
        fullWidth
        multiple
        fetchStatus={authoringOrgFetchStatus}
        asyncCallback={(value: string) => {
          dispatch(
            getDropdownOptionsForReports({
              context,
              target: ["authoringOrg"],
              filter: value,
            }),
          );
        }}
      />
      {/*<Input*/}
      {/*  type={"text"}*/}
      {/*  fullWidth*/}
      {/*  sx={{*/}
      {/*    "& .MuiFormLabel-root": {*/}
      {/*      fontSize: "0.875em",*/}
      {/*      lineHeight: "1.875em",*/}
      {/*    },*/}
      {/*  }}*/}
      {/*  name={"filter"}*/}
      {/*  control={control}*/}
      {/*  label={"KEYWORD SEARCH REPORTS DATA"}*/}
      {/*/>*/}
      <Input
        type={"text"}
        fullWidth
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"fileKeyword"}
        control={control}
        label={"KEYWORD SEARCH IN-DOCUMENT TEXT"}
      />
      <Input
        type={"text"}
        fullWidth
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"documentId"}
        control={control}
        label={"SEARCH BY DEC DOCUMENT ID"}
      />
      <Input
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        type={"date"}
        name={"startDate"}
        control={control}
        label={"PUBLICATION DATE FROM"}
      />
      <Input
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        type={"date"}
        name={"endDate"}
        control={control}
        label={"PUBLICATION DATE TO"}
      />
    </>
  );
}
