import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getDropdownOptionsForUSASpending,
  getUsaSpending,
  resetUsaSpendingItems,
  UsaSpendingFilters,
  addToMyList as usaSpendingAddToMyList,
  removeFromMyList as usaSpendingRemoveFromMyList,
  resetChangeListStatus as usaSpendingResetChangeListStatus,
} from "../../slices/usa-spending";
import {
  ForeignAssistanceFilters,
  getDropdownOptionsForForeignAssistance,
  getForeignAssistance,
  resetForeignAssistanceItems,
  addToMyList as foreignAssistanceAddToMyList,
  removeFromMyList as foreignAssistanceRemoveFromMyList,
  resetChangeListStatus as foreignAssistanceResetChangeListStatus,
} from "../../slices/foreign-assistance";
import { RootState, useDispatch, useSelector } from "../../store";
import UserContext from "../../services/UserContext";
import {
  alpha,
  Avatar,
  Box,
  ButtonGroup,
  Card,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  SxProps,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import { rowsPerPageOptionsStandard } from "../../utils/constants/gridsOptions";
import CustomTable, {
  ColumnsSettingsData,
  DataColumnConfigProps,
  ModalContent,
  StickyColumnConfigProps,
} from "../../components/MaterialTable/Table";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import {
  getForeignAssistanceColumnsConfig,
  getStickyColumnsConfig,
  getUsaSpendingColumnsConfig,
} from "./projectsTableColumns.config";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { FiltersIcon } from "../../components/Icons/FiltersIcon";
import { debounce } from "lodash";
import { useForm } from "react-hook-form";
import { format, formatISO, isBefore } from "date-fns";
import ProjectsTableFilters from "./ProjectsTableFilters";
import {
  getTableSettings,
  patchTableSettings,
} from "../../slices/user-settings";
import { getSortableColumnPropertyName } from "../ForecastV2/forecastUtils";
import { Add, LocalOfferOutlined, Visibility } from "@mui/icons-material";
import { getEpochDateWithoutTimezone } from "../../utils/conversion/date-converters";
import { handleInvalidDatesToast } from "../../utils/Helpers/invalidDatesErrorToastHandler";
import Stack from "@mui/material/Stack";
import SimpleModal from "../../components/Modals/SimpleModal";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { ProgressBar } from "primereact/progressbar";
import {
  currencyFormatterNoDecimals,
  UsaSpendingAwardDto,
} from "../USAidScreen/USAid";
import { Link, useHistory } from "react-router-dom";
import { generateExcel } from "../../services/exporter";
import {
  getExcelDataForeignAssistance,
  getExcelDataUsaSpending,
  getExportableDataTable,
  getLabelForMechanism,
} from "./projectsUtils";
import { OfficeBagIcon } from "../../assets/icons/material-icons/office-bag";
import { LastUpdatedIcon } from "../../components/Icons/LastUpdatedIcon";
import {
  getSearchParamValue,
  setUrlParams,
} from "../../utils/Helpers/extractDataFromSearchParams";
import TooltipWithScrollHide from "../../components/Widgets/TooltipWithScrollHide";
import { FolderAddIcon } from "../../assets/icons/material-icons/folder-add";
import { FolderRemoveIcon } from "../../assets/icons/material-icons/folder-remove";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { constants } from "../../utils/constants/general";

interface ProjectsTableForEditsProps {
  containerSx?: SxProps;
  externalSetTableContext?: Dispatch<SetStateAction<ProjectsTableContextType>>;
  useFragmentHeader?: boolean;
  allowViewTypeSwitch?: boolean;
  showActions?: boolean;
  loadDataImmediately?: boolean;
}

export type ProjectsTableContextType =
  | "usa-spending"
  | "foreign-assistance"
  | null;

const getInitialUSASpendingFilters = (date): UsaSpendingFilters => ({
  isMyList: false,
  pageIndex: 0,
  pageSize: rowsPerPageOptionsStandard[0],
  // sortField: "",
  // sortOrder: 0,
  country: [],
  implementer: [],
  projectNumber: "",
  mechanism: [],
  naics: [],
  setAside: [],
  keyword: "",
  dateStartFrom: "",
  dateStartTo: "",
  dateCloseFrom: formatISO(date),
  dateCloseTo: "",
  minimumAmount: 500000,
  subawardRecipient: [],
  projectName: "",
  projectDescription: "",
});

const getInitialForeignAssistanceFilters = (
  date,
): ForeignAssistanceFilters => ({
  Sort: true,
  // SortOrder: 0,
  // SortField: "",
  PageIndex: 0,
  PageSize: rowsPerPageOptionsStandard[0],
  // ManagingAgencyNames: [],
  // ActivityId: null,
  ProjectNumber: "",
  Keyword: "",
  Email: "",
  Name: "",
  IsMyList: false,
  ClosingStartDate: "",
  ClosingEndDate: "",
  FundingAgency: [],
  Implementer: [],
  Country: [],
  Sector: [],
  FundingAccount: [],
  DateStartFrom: "",
  DateStartTo: "",
  DateCloseFrom: formatISO(date),
  DateCloseTo: "",
  MinimumAmount: 500000,
});

const FILTERS_FORM_ID = "projects-form-id-filters";
const FORECASTS_USA_SPENDING_FOR_ADMIN_COLUMNS_SETTINGS_KEY =
  "forecastsUsaSpendingForAdminColumnsSettingsKey";
const FORECASTS_FOREIGN_ASSISTANCE_FOR_ADMIN_COLUMNS_SETTINGS_KEY =
  "forecastsForeignAssistanceForAdminColumnsSettingsKey";

const ProjectTable: FC<ProjectsTableForEditsProps> = (props) => {
  const {
    containerSx = {},
    externalSetTableContext,
    useFragmentHeader = true,
    allowViewTypeSwitch = false,
    showActions = false,
    loadDataImmediately = true,
  } = props;

  const dispatch = useDispatch();
  const MUItheme = useTheme();
  const context = useContext(UserContext);
  const history = useHistory();

  const {
    items: usaSpendingItems,
    fetchStatus: usaSpendingFetchStatus,
    total: usaSpendingTotal,
    lastUpdatedUtc: usaSpendingLastUpdatedUtc,
    dropdownOptions: usaSpendingDropdownOptions,
    addToMyList: { fetchStatus: usaSpendingAddToMyListFetchStatus },
    removeFromMyList: { fetchStatus: usaSpendingRemoveFromMyListFetchStatus },
  } = useSelector((state: RootState) => state.usaSpending);
  const {
    items: foreignAssistanceItems,
    fetchStatus: foreignAssistanceFetchStatus,
    total: foreignAssistanceTotal,
    lastUpdatedUtc: foreignAssistanceLastUpdatedUtc,
    dropdownOptions: foreignAssistanceDropdownOptions,
    addToMyList: { fetchStatus: foreignAssistanceAddToMyListFetchStatus },
    removeFromMyList: {
      fetchStatus: foreignAssistanceRemoveFromMyListFetchStatus,
    },
  } = useSelector((state: RootState) => state.foreignAssistance);
  const {
    tableSettings: { columnsOrder, fetchStatus: tableSettingsFetchStatus },
  } = useSelector((state: RootState) => state.userSettings);

  // const usaSpendingColumns = getUsaSpendingColumnsConfig(
  //   (_propertyName) => _propertyName,
  // );
  // const foreignAssistanceColumns = getForeignAssistanceColumnsConfig(
  //   (_propertyName) => _propertyName,
  // );

  const initialUSASpendingFilters = getInitialUSASpendingFilters(Date.today());
  const initialForeignAssistanceFilters = getInitialForeignAssistanceFilters(
    Date.today(),
  );

  // const [columns, setColumns] = useState<Array<any>>([]);
  const [usaSpendingColumns, setUsaSpendingColumns] = useState<Array<any>>([]);
  const [foreignAssistanceColumns, setForeignAssistanceColumns] = useState<
    Array<any>
  >([]);

  const stickyColumns = useMemo(() => getStickyColumnsConfig(), []);

  const [tableContext, _setTableContext] = useState<ProjectsTableContextType>(
    getSearchParamValue<ProjectsTableContextType>(
      history,
      "context",
      "usa-spending",
    ),
  );

  const [usaSpendingFilters, setUsaSpendingFilters] =
    useState<UsaSpendingFilters>(initialUSASpendingFilters);
  const [foreignAssistanceFilters, setForeignAssistanceFilters] =
    useState<ForeignAssistanceFilters>(initialForeignAssistanceFilters);

  const [inputValue, setInputValue] = useState<string>("");

  const [filtersOpen, setFiltersOpen] = useState<boolean>(true);

  const [minimumAmountDisabled, setMinimumAmountDisabled] =
    useState<boolean>(false);

  const [showOnlyRecentRecords, setShowOnlyRecentRecords] =
    useState<boolean>(false);

  const [switchesStateWhenFiltersOpened, setSwitchesStateWhenFiltersOpened] =
    useState<{
      minimumAmountDisabled: boolean | null;
      showOnlyRecentRecords: boolean | null;
    }>({
      minimumAmountDisabled: null,
      showOnlyRecentRecords: null,
    });

  const [modalContent, setModalContent] = useState<ModalContent | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [listStatusModificationFor, setListStatusModificationFor] = useState<
    number | string | null
  >(null);

  const allowDataLoadRef = useRef<boolean>(loadDataImmediately);
  const allowFADataLoadRef = useRef<boolean>(loadDataImmediately);

  const isAnyStatusChanged =
    RequestStatus.isDone(usaSpendingAddToMyListFetchStatus) ||
    RequestStatus.isDone(usaSpendingRemoveFromMyListFetchStatus) ||
    RequestStatus.isDone(foreignAssistanceAddToMyListFetchStatus) ||
    RequestStatus.isDone(foreignAssistanceRemoveFromMyListFetchStatus);

  const isAnyStatusChanging =
    RequestStatus.isFetching(usaSpendingAddToMyListFetchStatus) ||
    RequestStatus.isFetching(usaSpendingRemoveFromMyListFetchStatus) ||
    RequestStatus.isFetching(foreignAssistanceAddToMyListFetchStatus) ||
    RequestStatus.isFetching(foreignAssistanceRemoveFromMyListFetchStatus);

  const {
    control: usaSpendingControl,
    handleSubmit: usaSpendingHandleSubmit,
    reset: usaSpendingReset,
    getValues: usaSpendingGetValues,
    setValue: usaSpendingSetValue,
  } = useForm<UsaSpendingFilters>({
    defaultValues: initialUSASpendingFilters,
  });

  const {
    control: foreignAssistanceControl,
    handleSubmit: foreignAssistanceHandleSubmit,
    reset: foreignAssistanceReset,
    getValues: foreignAssistanceGetValues,
    setValue: foreignAssistanceSetValue,
  } = useForm<ForeignAssistanceFilters>({
    defaultValues: initialForeignAssistanceFilters,
  });

  const setTableContext = (value: ProjectsTableContextType) => {
    _setTableContext(value);
    value !== null && setUrlParams(history, { context: value });
    externalSetTableContext?.(value);
  };

  const changeTableContext = (newContext: ProjectsTableContextType): void => {
    setTableContext(null);
    allowDataLoadRef.current = loadDataImmediately;
    allowFADataLoadRef.current = loadDataImmediately;
    !loadDataImmediately && dispatch(resetUsaSpendingItems());
    !loadDataImmediately && dispatch(resetForeignAssistanceItems());
    setTimeout(() => {
      allowDataLoadRef.current = loadDataImmediately;
      allowFADataLoadRef.current = loadDataImmediately;
      setFiltersOpen(!loadDataImmediately);
      setTableContext(newContext);
    }, 150);
  };

  const onChange = (e) => {
    setInputValue(e.target.value);
    debouncedKeywordChange(e.target.value);
  };

  const searchCallback = (value) => {
    if (tableContext === "usa-spending") {
      setUsaSpendingFilters((prev) => ({
        ...prev,
        pageIndex: 0,
        keyword: value,
      }));
      // usaSpendingSetValue("keyword", value);
    } else if (tableContext === "foreign-assistance") {
      setForeignAssistanceFilters((prev) => ({
        ...prev,
        pageIndex: 0,
        Keyword: value,
      }));
      // foreignAssistanceSetValue("Keyword", value);
    }
  };

  const debouncedKeywordChange = useCallback(debounce(searchCallback, 750), [
    tableContext,
  ]);

  const handleOpenFilters = (): void => {
    setFiltersOpen(true);
    setSwitchesStateWhenFiltersOpened({
      minimumAmountDisabled,
      showOnlyRecentRecords,
    });
  };

  const handleCloseFilters = (
    resetSwitchesValues: boolean = true,
    resetValues: boolean = true,
  ): void => {
    setFiltersOpen(false);
    setTimeout(() => {
      if (resetSwitchesValues) {
        setMinimumAmountDisabled(
          switchesStateWhenFiltersOpened.minimumAmountDisabled !== null
            ? switchesStateWhenFiltersOpened.minimumAmountDisabled
            : false,
        );
        setShowOnlyRecentRecords(
          switchesStateWhenFiltersOpened.showOnlyRecentRecords !== null
            ? switchesStateWhenFiltersOpened.showOnlyRecentRecords
            : false,
        );
      }
      if (tableContext === "usa-spending")
        resetValues && usaSpendingReset(usaSpendingFilters);
      else if (tableContext === "foreign-assistance")
        resetValues && foreignAssistanceReset(foreignAssistanceFilters);
    });
  };

  const genericSetValue = (name: any, value: any) => {
    if (!tableContext) return;
    if (tableContext === "usa-spending") usaSpendingSetValue(name, value);
    else foreignAssistanceSetValue(name, value);
  };

  const handleChangeShowOnlyRecentRecords = () => {
    setShowOnlyRecentRecords((current) => {
      const dateStartFromName =
        tableContext === "usa-spending" ? "dateStartFrom" : "DateStartFrom";
      const dateStartToName =
        tableContext === "usa-spending" ? "dateStartTo" : "DateStartTo";
      const dateCloseFromName: string =
        tableContext === "usa-spending" ? "dateCloseFrom" : "DateCloseFrom";
      if (current) {
        genericSetValue(dateCloseFromName, formatISO(Date.today()));
        genericSetValue(dateStartFromName, "");
        genericSetValue(dateStartToName, "");
      } else {
        genericSetValue(dateCloseFromName, "");
        genericSetValue(
          dateStartFromName,
          formatISO(new Date(Date.today().addDays(-90))),
        );
        genericSetValue(dateStartToName, formatISO(Date.today()));
      }
      return !current;
    });
  };

  const onUsaSpendingFiltersSubmit = (values: UsaSpendingFilters): void => {
    handleInvalidDatesToast(values, () => {
      setUsaSpendingFilters((prev) => ({ ...prev, ...values }));
      setTimeout(() => {
        handleCloseFilters(false, false);
      }, 150);
    });
  };
  const onForeignAssistanceFiltersSubmit = (
    values: ForeignAssistanceFilters,
  ): void => {
    handleInvalidDatesToast(values, () => {
      setForeignAssistanceFilters((prev) => ({ ...prev, ...values }));
      setTimeout(() => {
        handleCloseFilters(false, false);
      }, 150);
    });
  };

  const handleClearFilters = (): void => {
    minimumAmountDisabled && setMinimumAmountDisabled(false);
    showOnlyRecentRecords && setShowOnlyRecentRecords(false);
    if (tableContext === "usa-spending")
      usaSpendingReset(initialUSASpendingFilters);
    else if (tableContext === "foreign-assistance")
      foreignAssistanceReset(initialForeignAssistanceFilters);
  };

  const handleSaveColumnsConfiguration = (
    data: {
      [key: string]: Array<ColumnsSettingsData>;
    },
    key: string,
  ): void => {
    dispatch(
      patchTableSettings({
        context,
        key,
        configuration: data,
      }),
    );
  };

  const handleSetColumnsInOrder = (getColumns, setColumns) => {
    setColumns(
      getColumns(getSortableColumnPropertyName)
        .map((column, idx) => ({
          ...column,
          order:
            columnsOrder.find(
              (_column) => _column.propertyName === column.propertyName,
            )?.order ?? idx,
          isVisible:
            columnsOrder.find(
              (_column) => _column.propertyName === column.propertyName,
            )?.isVisible ?? column.isVisible,
        }))
        .sort((a, b) => a.order - b.order),
    );
  };

  const handleGetAwardMechanismDisplayValue = (mechanismKey: string): string =>
    usaSpendingDropdownOptions.mechanism.find(
      (mechanism) => mechanism.value === mechanismKey,
    )?.label ?? "";

  const handleOpenModal = (params: ModalContent): void => {
    setModalContent(params);
    setModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setModalOpen(false);
    setTimeout(() => {
      setModalContent(null);
    }, 150);
  };

  const getPercentage = (record: UsaSpendingAwardDto) => {
    const isOutdated =
      record.currentEndDate &&
      isBefore(new Date(record.currentEndDate), new Date());

    if (isOutdated || !record.startDate || !record.currentEndDate) {
      return 100;
    }

    const todayDate = new Date().getTime();
    const startDate = new Date(record.startDate ?? 0).getTime();
    const endDate = new Date(record.currentEndDate ?? 0).getTime();

    return Math.round(((todayDate - startDate) / (endDate - startDate)) * 100);
  };

  useEffect(() => {
    minimumAmountDisabled && setMinimumAmountDisabled(false);
    showOnlyRecentRecords && setShowOnlyRecentRecords(false);
    if (tableContext === "usa-spending") {
      setForeignAssistanceFilters(initialForeignAssistanceFilters);
      setForeignAssistanceColumns([]);
      dispatch(
        getTableSettings({
          context,
          key: FORECASTS_USA_SPENDING_FOR_ADMIN_COLUMNS_SETTINGS_KEY,
        }),
      );
    } else if (tableContext === "foreign-assistance") {
      setUsaSpendingFilters(initialUSASpendingFilters);
      setUsaSpendingColumns([]);
      dispatch(
        getTableSettings({
          context,
          key: FORECASTS_FOREIGN_ASSISTANCE_FOR_ADMIN_COLUMNS_SETTINGS_KEY,
        }),
      );
    }
    setInputValue("");
  }, [tableContext]);

  const getUsaSpendingFilters = () => ({
    ...Object.fromEntries(
      Object.entries({
        ...usaSpendingFilters,
        ...usaSpendingGetValues(),
        pageIndex: usaSpendingFilters.pageIndex,
        pageSize: usaSpendingFilters.pageSize,
        keyword: usaSpendingFilters.keyword,
        ...(minimumAmountDisabled && { minimumAmount: null }),
      })
        .map(([key, value]: [key: string, value: any]) => [
          key,
          value === null ? "" : value,
        ])
        .map(([key, value]) =>
          Array.isArray(value)
            ? [
                key,
                value.map((v: any) =>
                  v.hasOwnProperty("value") ? v.value : v,
                ),
              ]
            : typeof value?.getMonth === "function"
            ? [
                key,
                ["dateStartTo", "dateCloseTo"].includes(key)
                  ? formatISO(
                      new Date(
                        (getEpochDateWithoutTimezone(value) as number) +
                          36000000,
                      ),
                    ).split("+")[0] + "Z"
                  : formatISO(new Date(value)),
              ]
            : [key, value === null ? "" : value],
        ),
    ),
  });

  const getForeignAssistanceFilters = () => ({
    ...Object.fromEntries(
      Object.entries({
        ...foreignAssistanceFilters,
        ...foreignAssistanceGetValues(),
        PageIndex: foreignAssistanceFilters.PageIndex,
        PageSize: foreignAssistanceFilters.PageSize,
        Keyword: foreignAssistanceFilters.Keyword,
        ...(minimumAmountDisabled && { MinimumAmount: null }),
      })
        .map(([key, value]: [key: string, value: any]) => [
          key,
          value === null ? "" : value,
        ])
        .map(([key, value]) =>
          (key === "SortOrder" && value === 0) ||
          (key === "SortField" && value === "")
            ? [null, null]
            : Array.isArray(value)
            ? [
                key,
                value.map((v: any) =>
                  v.hasOwnProperty("value") ? v.value : v,
                ),
              ]
            : typeof value?.getMonth === "function"
            ? [
                key,
                [
                  // "DateStartFrom",
                  "DateStartTo",
                  // "DateCloseFrom",
                  "DateCloseTo",
                ].includes(key)
                  ? formatISO(
                      new Date(
                        (getEpochDateWithoutTimezone(value) as number) +
                          36000000,
                      ),
                    ).split("+")[0] + "Z"
                  : formatISO(new Date(value)),
              ]
            : [key, value === null ? "" : value],
        )
        .filter(([key, value]) => key !== null),
    ),
  });

  const queryUsaSpending = () => {
    if (tableContext === "usa-spending") {
      dispatch(
        getUsaSpending({
          context,
          params: {
            ...getUsaSpendingFilters(),
          },
        }),
      );
    }
  };

  const queryForeignAssistance = () => {
    if (tableContext === "foreign-assistance") {
      dispatch(
        getForeignAssistance({
          context,
          params: {
            ...getForeignAssistanceFilters(),
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (allowDataLoadRef.current) {
      queryUsaSpending();
    } else {
      allowDataLoadRef.current = true;
    }
  }, [tableContext, usaSpendingFilters]);

  useEffect(() => {
    if (allowFADataLoadRef.current) {
      queryForeignAssistance();
    } else {
      allowFADataLoadRef.current = true;
    }
  }, [tableContext, foreignAssistanceFilters]);

  useEffect(() => {
    if (
      (RequestStatus.isDone(tableSettingsFetchStatus) ||
        RequestStatus.isError(tableSettingsFetchStatus)) &&
      tableContext === "usa-spending"
    ) {
      handleSetColumnsInOrder(
        getUsaSpendingColumnsConfig,
        setUsaSpendingColumns,
      );
    }
  }, [tableSettingsFetchStatus]);

  useEffect(() => {
    if (
      (RequestStatus.isDone(tableSettingsFetchStatus) ||
        RequestStatus.isError(tableSettingsFetchStatus)) &&
      tableContext === "foreign-assistance"
    ) {
      handleSetColumnsInOrder(
        getForeignAssistanceColumnsConfig,
        setForeignAssistanceColumns,
      );
    }
  }, [tableSettingsFetchStatus]);

  useEffect(() => {
    RequestStatus.isNull(usaSpendingDropdownOptions.fetchStatus) &&
      dispatch(getDropdownOptionsForUSASpending(context));
    RequestStatus.isNull(foreignAssistanceDropdownOptions.fetchStatus) &&
      dispatch(getDropdownOptionsForForeignAssistance(context));

    return () => {
      setUsaSpendingColumns([]);
      setForeignAssistanceColumns([]);
      dispatch(resetUsaSpendingItems());
      dispatch(resetForeignAssistanceItems());
    };
  }, []);

  useEffect(() => {
    if (isAnyStatusChanged) {
      queryUsaSpending();
      queryForeignAssistance();
      dispatch(usaSpendingResetChangeListStatus());
      dispatch(foreignAssistanceResetChangeListStatus());
      setListStatusModificationFor(null);
    }
  }, [
    usaSpendingAddToMyListFetchStatus,
    usaSpendingRemoveFromMyListFetchStatus,
    foreignAssistanceAddToMyListFetchStatus,
    foreignAssistanceRemoveFromMyListFetchStatus,
  ]);

  const getFiltersAmount = (): JSX.Element => {
    const extendedFiltersLength = !tableContext
      ? 0
      : Object.entries(
          tableContext === "usa-spending"
            ? usaSpendingFilters
            : foreignAssistanceFilters,
        ).filter(
          ([key, value]) =>
            (Array.isArray(value) ? value.length > 0 : !!value) &&
            ![
              "pageindex",
              "pagesize",
              "sort",
              "sortfield",
              "sortorder",
            ].includes(key.toLowerCase()),
        ).length - (minimumAmountDisabled ? 1 : 0);

    return extendedFiltersLength > 0 ? (
      <Avatar
        sx={{
          position: "absolute",
          height: "16px",
          width: "16px",
          top: "2px",
          right: "2px",
          fontSize: "12px",
          backgroundColor: MUItheme.palette.secondary.main,
          // border: `solid 1px ${theme.palette.secondary.main}`,
          pointerEvents: "none",
        }}
      >
        {extendedFiltersLength}
      </Avatar>
    ) : (
      <></>
    );
  };
  const handleAddToMyList = (
    uniqueId: number | string,
    isMyProject: boolean,
  ): void => {
    setListStatusModificationFor(uniqueId);
    if (tableContext === "usa-spending") {
      isMyProject
        ? dispatch(
            usaSpendingRemoveFromMyList({ uniqueAwardId: uniqueId, context }),
          )
        : dispatch(
            usaSpendingAddToMyList({ uniqueAwardId: uniqueId, context }),
          );
    } else {
      isMyProject
        ? dispatch(foreignAssistanceRemoveFromMyList({ uniqueId, context }))
        : dispatch(foreignAssistanceAddToMyList({ uniqueId, context }));
    }
  };

  const getFragmentTableHeader = (): ReactNode => (
    <>
      <ButtonGroup size={"small"}>
        <Button
          onClick={() =>
            tableContext === "foreign-assistance" &&
            changeTableContext("usa-spending")
          }
          variant={tableContext === "usa-spending" ? "contained" : "outlined"}
        >
          USA Spending
        </Button>
        <Button
          onClick={() =>
            tableContext === "usa-spending" &&
            changeTableContext("foreign-assistance")
          }
          variant={
            tableContext === "foreign-assistance" ? "contained" : "outlined"
          }
        >
          Foreign Assistance
        </Button>
      </ButtonGroup>
      <TextField
        value={inputValue}
        onChange={onChange}
        sx={{
          width: "300px",
        }}
        InputProps={{
          startAdornment: (
            <SearchIcon sx={{ color: MUItheme.palette.action.active }} />
          ),
          endAdornment: (
            <>
              {inputValue?.length > 0 && (
                <IconButton
                  onClick={() => onChange({ target: { value: "" } })}
                  sx={{ mr: 0.5 }}
                  disabled={
                    RequestStatus.isFetching(usaSpendingFetchStatus) ||
                    RequestStatus.isFetching(foreignAssistanceFetchStatus)
                  }
                >
                  <ClearIcon />
                </IconButton>
              )}
              <Box
                sx={{
                  borderLeft: `solid 1px ${MUItheme.palette.divider}`,
                  pl: 0.5,
                }}
              >
                <Tooltip title={"Filters"}>
                  <span>
                    <IconButton onClick={handleOpenFilters}>
                      <FiltersIcon sx={{ width: "24px", height: "24px" }} />
                    </IconButton>
                    {getFiltersAmount()}
                  </span>
                </Tooltip>
              </Box>
            </>
          ),
          sx: {
            px: 0.5,
          },
        }}
        size={"small"}
        placeholder={"Search"}
        disabled={
          RequestStatus.isFetching(usaSpendingFetchStatus) ||
          RequestStatus.isFetching(foreignAssistanceFetchStatus)
        }
      />
    </>
  );

  const getTableHeader = (): ReactNode => (
    <Stack
      direction={{ xs: "column", md: "row" }}
      sx={{
        width: "100%",
        justifyContent: "space-between",
        pr: 3,
      }}
    >
      <Stack direction={"row"} sx={{ gap: 2, alignItems: "center" }}>
        <TextField
          value={inputValue}
          onChange={onChange}
          sx={{
            width: "300px",
          }}
          InputProps={{
            startAdornment: (
              <SearchIcon sx={{ color: MUItheme.palette.action.active }} />
            ),
            endAdornment: (
              <>
                {inputValue?.length > 0 && (
                  <IconButton
                    onClick={() => onChange({ target: { value: "" } })}
                    sx={{ mr: 0.5 }}
                    disabled={
                      RequestStatus.isFetching(usaSpendingFetchStatus) ||
                      RequestStatus.isFetching(foreignAssistanceFetchStatus)
                    }
                  >
                    <ClearIcon />
                  </IconButton>
                )}
                <Box
                  sx={{
                    borderLeft: `solid 1px ${MUItheme.palette.divider}`,
                    pl: 0.5,
                  }}
                >
                  <Tooltip title={"Filters"} placement={"right"}>
                    <span>
                      <IconButton onClick={handleOpenFilters}>
                        <FiltersIcon sx={{ width: "24px", height: "24px" }} />
                      </IconButton>
                      {getFiltersAmount()}
                    </span>
                  </Tooltip>
                </Box>
              </>
            ),
            sx: {
              px: 0.5,
            },
          }}
          // inputProps={{
          //   style: {
          //     padding: "8px",
          //   },
          // }}
          size={"small"}
          placeholder={"Search"}
          disabled={
            RequestStatus.isFetching(usaSpendingFetchStatus) ||
            RequestStatus.isFetching(foreignAssistanceFetchStatus)
          }
        />
        <Box>
          <Typography
            variant={"subtitle2"}
            color={"textSecondary"}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            Source:{" "}
            {tableContext === "usa-spending"
              ? "USA Spending"
              : "Foreign Assistance"}
          </Typography>
          <Typography
            variant={"subtitle2"}
            color={"textSecondary"}
            sx={{
              display:
                (!usaSpendingLastUpdatedUtc &&
                  tableContext === "usa-spending") ||
                (!foreignAssistanceLastUpdatedUtc &&
                  tableContext === "foreign-assistance")
                  ? "none"
                  : "flex",
              alignItems: "center",
            }}
          >
            <LastUpdatedIcon
              sx={{
                height: "16px",
                width: "16px",
                mr: 0.5,
                mt: "-2px",
              }}
            />
            Last updated:{" "}
            {tableContext === "usa-spending"
              ? usaSpendingLastUpdatedUtc
                ? format(
                    new Date(usaSpendingLastUpdatedUtc),
                    "dd MMMM yyyy, HH:mm",
                  )
                : "N/A"
              : foreignAssistanceLastUpdatedUtc
              ? format(
                  new Date(foreignAssistanceLastUpdatedUtc),
                  "dd MMMM yyyy, HH:mm",
                )
              : "N/A"}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );

  const addToMyListButton = (uniqueId: string | number, cell: any) => (
    <TooltipWithScrollHide
      title={!cell.isMyProject ? "Add to MyProjects" : "Remove from MyProjects"}
      querySelectors={"[data-virtuoso-scroller]"}
    >
      <span>
        <IconButton
          color={!cell.isMyProject ? "success" : "error"}
          sx={{
            border: "solid 2px",
            backgroundColor: alpha(
              MUItheme.palette[!cell.isMyProject ? "success" : "error"].main,
              0.1,
            ),
          }}
          onClick={() => handleAddToMyList(uniqueId, cell.isMyProject)}
          disabled={
            isAnyStatusChanging && uniqueId === listStatusModificationFor
          }
        >
          {!cell.isMyProject ? (
            <FolderAddIcon
              sx={{
                height: "18px !important",
                width: "18px !important",
              }}
            />
          ) : (
            <FolderRemoveIcon />
          )}
        </IconButton>
      </span>
    </TooltipWithScrollHide>
  );

  const renderCardRowFunction = (index: number, row: any[]) => {
    return (
      <Stack
        direction={"row"}
        sx={{
          width: "100%",
          gap: 3,
        }}
        key={`${index}-card-row`}
      >
        {row.map((cell, idx) => {
          if (cell?.noData) {
            return (
              <Typography
                sx={{
                  position: "fixed",
                }}
                variant={"h5"}
              >
                {RequestStatus.isNull(usaSpendingFetchStatus)
                  ? "Select filters to see data"
                  : "No data available"}
              </Typography>
            );
          }

          const startDate = cell.startDate ?? cell.activityStartDate;
          const endDate = cell.currentEndDate ?? cell.activityEndDate;

          const dataAvailable =
            !!startDate &&
            !startDate.toString().includes("0001-01-01T00:00:00") &&
            !!endDate &&
            !endDate.toString().includes("0001-01-01T00:00:00");

          const uniqueId = cell.uniqueAwardId
            ? cell.uniqueAwardId
            : cell.activityId;

          return (
            <Card
              sx={{
                my: 1,
                width: "100%",
                p: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              variant={"outlined"}
              key={`${idx}-${index}-card-row-cell`}
            >
              <Box>
                <Typography
                  fontWeight={400}
                  variant={"subtitle2"}
                  color={"textSecondary"}
                >
                  Project Number: <strong>{cell.projectNumber ?? "-"}</strong>
                </Typography>
                <Typography variant={"h6"} color={"primary"}>
                  {cell.projectName ?? "-"}
                </Typography>
                <Typography
                  fontWeight={400}
                  variant={"subtitle2"}
                  color={"textSecondary"}
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                  }}
                  onClick={() =>
                    handleOpenModal({
                      propertyName: "projectDescription",
                      propertyLabel: "Description",
                      content: cell.projectDescription ?? "-",
                    })
                  }
                >
                  {cell.projectDescription ?? "-"}
                </Typography>
              </Box>
              <Box>
                <Stack
                  direction={"row"}
                  sx={{
                    width: "100%",
                    borderTop: `solid 1px ${MUItheme.palette.divider}`,
                    borderBottom: `solid 1px ${MUItheme.palette.divider}`,
                    gap: 3,
                    my: 1,
                    py: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Stack
                      direction={"row"}
                      sx={{
                        gap: 1,
                        alignItems: "center",
                      }}
                    >
                      <LocationOnOutlinedIcon
                        sx={{
                          height: "18px !important",
                          width: "18px !important",
                        }}
                      />
                      <Typography variant={"subtitle2"} color={"inherit"}>
                        {cell.country ?? "-"}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      sx={{
                        gap: 1,
                        alignItems: "center",
                      }}
                    >
                      <LocalOfferOutlined
                        sx={{
                          height: "18px !important",
                          width: "18px !important",
                        }}
                      />
                      <Typography variant={"subtitle2"} color={"inherit"}>
                        {tableContext === "usa-spending"
                          ? cell.awardingAgencyName ?? "-"
                          : cell.technicalSector ?? "-"}
                      </Typography>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      sx={{
                        gap: 1,
                        alignItems: "center",
                        "& > svg": {
                          fill: MUItheme.palette.text.primary,
                          height: "18px !important",
                          width: "18px !important",
                        },
                      }}
                    >
                      <OfficeBagIcon />
                      <Typography variant={"subtitle2"} color={"inherit"}>
                        {tableContext === "foreign-assistance"
                          ? `${currencyFormatterNoDecimals.format(
                              Math.round(cell.disbursements ?? 0),
                            )} disbursements`
                          : `${currencyFormatterNoDecimals.format(
                              Math.round(cell.currentAmount ?? 0),
                            )} current award amount`}
                        <span>
                          {` (${currencyFormatterNoDecimals.format(
                            Math.round(cell.obligations ?? 0),
                          )} obligated)`}
                        </span>
                      </Typography>
                    </Stack>
                    {tableContext === "usa-spending" && (
                      <Stack
                        direction={"row"}
                        sx={{
                          gap: 1,
                          alignItems: "center",
                        }}
                      >
                        <LocalOfferOutlined
                          sx={{
                            height: "18px !important",
                            width: "18px !important",
                          }}
                        />
                        <Typography variant={"subtitle2"} color={"inherit"}>
                          {getLabelForMechanism(
                            cell.awardMechanism,
                            usaSpendingDropdownOptions.mechanism,
                          )}
                        </Typography>
                      </Stack>
                    )}
                  </Box>
                </Stack>
                <Box
                  sx={{
                    position: "relative",
                    my: 1,
                    height: "16px",
                    "& > p": {
                      position: "absolute",
                      right: "10px",
                      margin: "1px 0 0 0",
                      padding: "0",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "15px",
                      color: dataAvailable
                        ? MUItheme.palette.primary.contrastText
                        : "#605F5F",
                      zIndex: "10",
                    },
                  }}
                >
                  {dataAvailable ? (
                    <p>
                      {getPercentage({
                        ...cell,
                        startDate,
                        currentEndDate: endDate,
                      })}
                      % completed
                    </p>
                  ) : (
                    <p>Data not available</p>
                  )}
                  <LinearProgress
                    color={dataAvailable ? "success" : "inherit"}
                    sx={{ height: "100%", borderRadius: "4px" }}
                    variant={"determinate"}
                    value={
                      dataAvailable
                        ? getPercentage({
                            ...cell,
                            startDate,
                            currentEndDate: endDate,
                          })
                        : 0
                    }
                  />
                </Box>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  sx={{
                    mt: 2,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Link
                    to={`/project-view/record?${
                      tableContext === "foreign-assistance"
                        ? `projectNumber=${cell.projectNumber}`
                        : `id=${cell.id}`
                    }&fa=${tableContext === "foreign-assistance"}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      variant={"secondaryContained"}
                      disabled={
                        tableContext === "foreign-assistance"
                          ? !cell.projectNumber
                          : !cell.id
                      }
                    >
                      View Details
                    </Button>
                  </Link>
                  {addToMyListButton(uniqueId, cell)}
                </Stack>
              </Box>
            </Card>
          );
        })}
      </Stack>
    );
  };

  return (
    <>
      {showActions && (
        <Box
          sx={{
            display: "flex",
            position: "relative",
            zIndex: 999,
            bottom: "-2px",
          }}
        >
          <Button
            onClick={() =>
              tableContext === "foreign-assistance" &&
              changeTableContext("usa-spending")
            }
            variant={tableContext === "usa-spending" ? "outlined" : "text"}
            sx={{
              minWidth: "160px",
              ...(tableContext === "usa-spending"
                ? {
                    color: MUItheme.palette.text.primary,
                    backgroundColor: MUItheme.palette.background.paper,
                    border: `solid 1px ${MUItheme.palette.divider}`,
                    borderBottom: "none",
                    // borderRight: `solid 1px ${MUItheme.palette.divider} !important`,
                    borderRadius: "8px 8px 0 0 !important",

                    "&:hover": {
                      border: `solid 1px ${MUItheme.palette.divider}`,
                      borderBottom: "none",
                    },
                  }
                : {
                    color: MUItheme.palette.text.secondary,
                  }),
            }}
          >
            USA Spending
          </Button>
          <Button
            onClick={() =>
              tableContext === "usa-spending" &&
              changeTableContext("foreign-assistance")
            }
            variant={
              tableContext === "foreign-assistance" ? "outlined" : "text"
            }
            sx={{
              minWidth: "160px",
              ...(tableContext === "foreign-assistance"
                ? {
                    color: MUItheme.palette.text.primary,
                    backgroundColor: MUItheme.palette.background.paper,
                    border: `solid 1px ${MUItheme.palette.divider}`,
                    borderBottom: "none",
                    // borderRight: `solid 1px ${MUItheme.palette.divider} !important`,
                    borderRadius: "8px 8px 0 0 !important",

                    "&:hover": {
                      border: `solid 1px ${MUItheme.palette.divider}`,
                      borderBottom: "none",
                    },
                  }
                : {
                    color: MUItheme.palette.text.secondary,
                  }),
            }}
          >
            Foreign Assistance
          </Button>
        </Box>
      )}

      <Box
        sx={{
          width: "100%",
          position: "relative",
          ...containerSx,
        }}
      >
        {(tableContext === null ||
          (tableContext === "usa-spending" && !usaSpendingColumns.length) ||
          (tableContext === "foreign-assistance" &&
            !foreignAssistanceColumns.length)) && (
          <Box
            sx={{
              position: "absolute",
              p: 4,
              height: "100%",
              width: "100%",
              zIndex: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            transition: "background-color 75ms",
            ...((tableContext === null ||
              (tableContext === "usa-spending" && !usaSpendingColumns.length) ||
              (tableContext === "foreign-assistance" &&
                !foreignAssistanceColumns.length)) && {
              // filter: "blur(5px)",
              backgroundColor: MUItheme.palette.background.paper,
            }),
          }}
        >
          <Drawer
            open={filtersOpen}
            onClose={() => handleCloseFilters()}
            variant={"temporary"}
            anchor={"right"}
            sx={{
              zIndex: constants.zIndexes.drawer,
            }}
            PaperProps={{
              sx: {
                width: "480px",
                "& > div": {
                  px: 2,
                },
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
                backgroundColor: MUItheme.palette.secondary.light,
                borderBottom: `solid 3px ${MUItheme.palette.secondary.main}`,
                py: 3,
              }}
            >
              <FiltersIcon />
              <Typography variant={"h5"}>
                {tableContext === null
                  ? ""
                  : tableContext === "usa-spending"
                  ? "USA Spending Filters"
                  : "Foreign Assistance Filters"}
              </Typography>
            </Box>
            <Box
              sx={{
                py: 2,
                overflowY: "scroll",
                flex: 1,
                "& > form": {
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                },
              }}
            >
              <ProjectsTableFilters
                filtersFormId={FILTERS_FORM_ID}
                tableContext={tableContext}
                usaSpendingControl={usaSpendingControl}
                foreignAssistanceControl={foreignAssistanceControl}
                usaSpendingHandleSubmit={usaSpendingHandleSubmit}
                foreignAssistanceHandleSubmit={foreignAssistanceHandleSubmit}
                onUsaSpendingFiltersSubmit={onUsaSpendingFiltersSubmit}
                onForeignAssistanceFiltersSubmit={
                  onForeignAssistanceFiltersSubmit
                }
                minimumAmountDisabled={minimumAmountDisabled}
                setMinimumAmountDisabled={setMinimumAmountDisabled}
                showOnlyRecentRecords={showOnlyRecentRecords}
                handleChangeShowOnlyRecentRecords={
                  handleChangeShowOnlyRecentRecords
                }
              />
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "16px",
                py: 2,
              }}
            >
              <Button
                variant={"secondaryContained"}
                color={"secondary"}
                onClick={() => handleCloseFilters()}
              >
                Cancel
              </Button>
              <Button
                variant={"secondaryContained"}
                color={"secondary"}
                onClick={handleClearFilters}
              >
                Clear
              </Button>
              <Button
                variant={"contained"}
                color={"secondary"}
                // onClick={handleFilterData}
                type={"submit"}
                form={FILTERS_FORM_ID}
              >
                Save
              </Button>
            </Box>
          </Drawer>
          <SimpleModal
            open={modalOpen}
            handleClose={handleCloseModal}
            handleAccept={handleCloseModal}
            disableCancelButton
            title={modalContent?.propertyLabel}
          >
            <Typography
              variant={"body1"}
              dangerouslySetInnerHTML={{ __html: modalContent?.content ?? "" }}
            />
          </SimpleModal>
          {tableContext === null ? (
            <></>
          ) : tableContext === "usa-spending" ? (
            usaSpendingColumns.length > 0 ? (
              <CustomTable
                {...(!allowViewTypeSwitch
                  ? {
                      TableContainerComponent: Box,
                      tableContainerComponentSx: {
                        border: `solid 1px ${MUItheme.palette.divider}`,
                      },
                    }
                  : {
                      CardVariant: "outlined",
                    })}
                config={{
                  allowFullscreen: allowViewTypeSwitch,
                  ...(RequestStatus.isNull(usaSpendingFetchStatus) && {
                    noDataMessage: "Select filters to see data",
                  }),
                  data: usaSpendingItems.map((item) => ({
                    ...item,
                    awardMechanism: handleGetAwardMechanismDisplayValue(
                      item.awardMechanism,
                    ),
                    view: (
                      <Stack
                        direction={"row"}
                        sx={{
                          gap: 1,
                          alignItems: "center",
                        }}
                      >
                        {showActions &&
                          addToMyListButton(
                            item.uniqueAwardId
                              ? item.uniqueAwardId
                              : item.activityId,
                            item,
                          )}
                        <TooltipWithScrollHide
                          title={"View"}
                          querySelectors={"[data-virtuoso-scroller]"}
                        >
                          <span>
                            <IconButton
                              color={"primary"}
                              sx={{
                                border: "solid 2px",
                                backgroundColor: alpha(
                                  MUItheme.palette.primary.main,
                                  0.1,
                                ),
                              }}
                              component={"a"}
                              href={`/project-view/record?id=${item?.id}&fa=false`}
                              disabled={!item.id}
                              rel={"noreferrer noopener"}
                              target={"_blank"}
                            >
                              <Visibility
                                sx={{
                                  height: "16px",
                                  width: "16px",
                                }}
                              />
                            </IconButton>
                          </span>
                        </TooltipWithScrollHide>
                      </Stack>
                    ),
                  })),
                  columns: usaSpendingColumns,
                  stickyColumns,
                  header: useFragmentHeader
                    ? getFragmentTableHeader()
                    : getTableHeader(),
                  columnsConfiguration: {
                    allowColumnsConfiguration: true,
                    onSaveColumnsConfiguration: (data) =>
                      handleSaveColumnsConfiguration(
                        data,
                        FORECASTS_USA_SPENDING_FOR_ADMIN_COLUMNS_SETTINGS_KEY,
                      ),
                    columnsSettingsKey:
                      FORECASTS_USA_SPENDING_FOR_ADMIN_COLUMNS_SETTINGS_KEY,
                  },
                  allowViewTypeSwitch,
                  renderCardRowFunction,
                  exportExcelFunc: !showActions
                    ? undefined
                    : async () =>
                        generateExcel(
                          "Projects+",
                          `Aidkonekt_projects_plus_${new Date().getFullYear()}`,
                          getExportableDataTable(
                            getExcelDataUsaSpending
                              ? await getExcelDataUsaSpending(
                                  context,
                                  getUsaSpendingFilters(),
                                  usaSpendingTotal,
                                )
                              : usaSpendingItems,
                            usaSpendingDropdownOptions.mechanism,
                            false,
                          ),
                        ),
                }}
                loading={RequestStatus.isFetching(usaSpendingFetchStatus)}
                onPageChange={(e, page) =>
                  setUsaSpendingFilters((prev) => ({
                    ...prev,
                    pageIndex: page,
                  }))
                }
                onRowsPerPageChange={(e) =>
                  setUsaSpendingFilters((prev) => ({
                    ...prev,
                    pageSize: +e.target.value,
                    pageIndex: 0,
                  }))
                }
                pagination={{
                  page: usaSpendingFilters.pageIndex,
                  rowsPerPage: usaSpendingFilters.pageSize,
                  total: usaSpendingTotal,
                }}
                currentSort={{
                  sortOrder: usaSpendingFilters.sortOrder ?? 0,
                  sortField: usaSpendingFilters.sortField ?? "",
                }}
                onSortClick={(
                  column: DataColumnConfigProps | StickyColumnConfigProps,
                ) =>
                  setUsaSpendingFilters((prev) => {
                    const propertyName = column.propertyName;
                    return prev.sortField === propertyName
                      ? prev.sortOrder === 0
                        ? {
                            ...prev,
                            pageIndex: 0,
                            sortField: propertyName,
                            sortOrder: 1,
                          }
                        : prev.sortOrder === 1
                        ? {
                            ...prev,
                            pageIndex: 0,
                            sortField: propertyName,
                            sortOrder: -1,
                          }
                        : { ...prev, pageIndex: 0, sortField: "", sortOrder: 0 }
                      : {
                          ...prev,
                          pageIndex: 0,
                          sortField: propertyName,
                          sortOrder: 1,
                        };
                  })
                }
              />
            ) : (
              <></>
            )
          ) : foreignAssistanceColumns.length > 0 ? (
            <CustomTable
              {...(!allowViewTypeSwitch
                ? {
                    TableContainerComponent: Box,
                    tableContainerComponentSx: {
                      border: `solid 1px ${MUItheme.palette.divider}`,
                    },
                  }
                : {
                    CardVariant: "outlined",
                  })}
              config={{
                allowFullscreen: allowViewTypeSwitch,
                ...(RequestStatus.isNull(foreignAssistanceFetchStatus) && {
                  noDataMessage: "Select filters to see data",
                }),
                data: foreignAssistanceItems.map((item) => ({
                  ...item,
                  view: (
                    <Stack
                      direction={"row"}
                      sx={{
                        gap: 1,
                        alignItems: "center",
                      }}
                    >
                      {showActions &&
                        addToMyListButton(
                          item.uniqueAwardId
                            ? item.uniqueAwardId
                            : item.activityId,
                          item,
                        )}
                      <TooltipWithScrollHide
                        title={"View"}
                        querySelectors={"[data-virtuoso-scroller]"}
                      >
                        <span>
                          <IconButton
                            color={"primary"}
                            sx={{
                              border: "solid 2px",
                              backgroundColor: alpha(
                                MUItheme.palette.primary.main,
                                0.1,
                              ),
                            }}
                            component={"a"}
                            href={`/project-view/record?projectNumber=${item?.projectNumber}&fa=true`}
                            disabled={!item.projectNumber}
                            rel={"noreferrer noopener"}
                            target={"_blank"}
                          >
                            <Visibility
                              sx={{
                                height: "16px",
                                width: "16px",
                              }}
                            />
                          </IconButton>
                        </span>
                      </TooltipWithScrollHide>
                    </Stack>
                  ),
                })),
                columns: foreignAssistanceColumns,
                stickyColumns,
                header: useFragmentHeader
                  ? getFragmentTableHeader()
                  : getTableHeader(),
                columnsConfiguration: {
                  allowColumnsConfiguration: true,
                  onSaveColumnsConfiguration: (data) =>
                    handleSaveColumnsConfiguration(
                      data,
                      FORECASTS_FOREIGN_ASSISTANCE_FOR_ADMIN_COLUMNS_SETTINGS_KEY,
                    ),
                  columnsSettingsKey:
                    FORECASTS_FOREIGN_ASSISTANCE_FOR_ADMIN_COLUMNS_SETTINGS_KEY,
                },
                allowViewTypeSwitch,
                renderCardRowFunction,
                exportExcelFunc: !showActions
                  ? undefined
                  : async () =>
                      generateExcel(
                        "Foreign Assistance",
                        `Aidkonekt_foreign_assistance_${new Date().getFullYear()}`,
                        getExportableDataTable(
                          getExcelDataForeignAssistance
                            ? await getExcelDataForeignAssistance(
                                context,
                                getForeignAssistanceFilters(),
                                foreignAssistanceTotal,
                              )
                            : foreignAssistanceItems,
                          usaSpendingDropdownOptions.mechanism,
                          true,
                        ),
                      ),
              }}
              loading={RequestStatus.isFetching(foreignAssistanceFetchStatus)}
              onPageChange={(e, page) =>
                setForeignAssistanceFilters((prev) => ({
                  ...prev,
                  PageIndex: page,
                }))
              }
              onRowsPerPageChange={(e) =>
                setForeignAssistanceFilters((prev) => ({
                  ...prev,
                  PageSize: +e.target.value,
                  PageIndex: 0,
                }))
              }
              pagination={{
                page: foreignAssistanceFilters.PageIndex,
                rowsPerPage: foreignAssistanceFilters.PageSize,
                total: foreignAssistanceTotal,
              }}
              currentSort={{
                sortOrder: foreignAssistanceFilters.SortOrder ?? 0,
                sortField: foreignAssistanceFilters.SortField ?? "",
              }}
              onSortClick={(
                column: DataColumnConfigProps | StickyColumnConfigProps,
              ) =>
                setForeignAssistanceFilters((prev) => {
                  const propertyName = column.propertyName;

                  return prev.SortField === propertyName
                    ? prev.SortOrder === 0
                      ? {
                          ...prev,
                          PageIndex: 0,
                          SortField: propertyName,
                          SortOrder: 1,
                        }
                      : prev.SortOrder === 1
                      ? {
                          ...prev,
                          PageIndex: 0,
                          SortField: propertyName,
                          SortOrder: -1,
                        }
                      : {
                          ...prev,
                          PageIndex: 0,
                          SortField: "",
                          SortOrder: 0,
                        }
                    : {
                        ...prev,
                        PageIndex: 0,
                        SortField: propertyName,
                        SortOrder: 1,
                      };
                })
              }
            />
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ProjectTable;
