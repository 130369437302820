import React from "react";
import { useGridStyles } from "../../../hooks/useGridStyles";
import { getSortIfDescendingFirst } from "../../../services/functions";
import { DataTable } from "primereact/datatable";
import { GetCustomColumnBody } from "../../../components/DataTable/Columns";
import {
  DateFormat,
  formatDate,
} from "../../../utils/conversion/date-converters";
import { rowsPerPageOptionsStandard } from "../../../utils/constants/gridsOptions";

export interface TableRecord {
  id: string;
  site: string;
  lasUpdated: Date;
  mostRecentChange: string;
  error: string;
}

type ApiStatusTableProps = {
  isLoading: boolean;

  // Filtering/sorting/querying
  rowsPerPage: number;
  sortField: string;
  setSortField: React.Dispatch<string>;
  sortOrder: number;
  setSortOrder: React.Dispatch<number>;
  first: number;
  totalRecords: number;

  // Grid state
  data: Array<TableRecord>;
  setData: React.Dispatch<Array<TableRecord>>;
  lastUpdated: string;
  gridWidth: number;

  // Notes info
  originalNotes: Array<{ id: number; note: string }>;
  setOriginalNotes: React.Dispatch<Array<{ id: number; note: string }>>;

  isMyContracts?: boolean;
  decrement?: () => void;

  queryData: (event: {
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
  }) => void;
};

const ApiStatusTable: React.FC<ApiStatusTableProps> = ({
  isLoading,
  rowsPerPage,
  sortField,
  setSortField,
  sortOrder,
  setSortOrder,
  first,
  totalRecords,
  data,
  gridWidth,
  queryData,
}): JSX.Element => {
  const styles = useGridStyles();

  const [expandedRows, setExpandedRows] = React.useState(Array<TableRecord>());

  const sort = (event: { sortField: string; sortOrder: number }) => {
    const newSortOrder = getSortIfDescendingFirst(event, sortField);
    setSortField(event.sortField);
    setSortOrder(newSortOrder);
    queryData({
      first,
      rows: rowsPerPage,
      sortField: event.sortField,
      sortOrder: newSortOrder,
    });
  };

  return (
    <DataTable
      value={data}
      style={{ marginTop: 20, width: `${gridWidth + 20}px` }}
      paginator={true}
      rows={rowsPerPage}
      totalRecords={totalRecords}
      lazy={true}
      first={first}
      onPage={queryData}
      loading={isLoading}
      sortField={sortField}
      sortOrder={sortOrder}
      onSort={sort}
      header={<div />}
      scrollable={true}
      scrollHeight="400px"
      expandedRows={expandedRows}
      onRowToggle={(e: any) => setExpandedRows(e.data)}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      rowsPerPageOptions={rowsPerPageOptionsStandard}
    >
      {GetCustomColumnBody<TableRecord>(
        styles,
        250,
        (x) => x.site,
        "Site",
        "site",
        { sortable: false }
      )}
      {GetCustomColumnBody<TableRecord>(
        styles,
        150,
        (x) => formatDate(x.lasUpdated, DateFormat.dd_MMMM_yyyy),
        "Last Updated",
        "lasUpdated"
      )}
      {GetCustomColumnBody<TableRecord>(
        styles,
        250,
        (x) => x.mostRecentChange,
        "Most Recent Record Change",
        "mostRecentChange",
        { sortable: false }
      )}
      {GetCustomColumnBody<TableRecord>(
        styles,
        250,
        (x) => x.error,
        "Error",
        "error",
        { sortable: false }
      )}
    </DataTable>
  );
};

export default ApiStatusTable;
