import { ThemeOptions } from "@mui/material";
import {
  createTheme,
  createTheme as createMuiTheme,
  styled,
} from "@mui/material/styles";
import {
  BpCheckedIcon,
  BpIcon,
  BpIndeterminateIcon,
} from "./CustomCheckboxIcons";

const globalTheme = createTheme();

declare module "@mui/material/styles" {
  interface ButtonPropsVariants {
    secondaryContained: boolean;
  }

  // allow configuration using `createTheme`
  interface ButtonPropsVariantsOptions {
    secondaryContained?: boolean;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    secondaryContained: true;
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    lgEx: true;
    xl: true;
  }
}

interface Neutral {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

declare module "@mui/material/styles" {
  interface Palette {
    neutral?: Neutral;
  }

  interface PaletteOptions {
    neutral?: Neutral;
  }
}

type Size = "small" | "medium" | "large" | undefined;

const createSizedButtonVariants = (
  props: Record<string, string>,
  style: Record<string, any>,
) => {
  return ["small", "medium", "large"].map((size) => ({
    props: { ...props, size: size as Size },
    style: {
      ...style,
      ...(size === "small" && {
        padding: "6px 16px",
        fontSize: globalTheme.typography.pxToRem(13),
      }),
      ...(size === "large" && {
        padding: "8px 11px",
        fontSize: globalTheme.typography.pxToRem(15),
      }),
    },
  }));
};

const neutral = {
  100: "#F3F5F6",
  200: "#E5EAEB",
  300: "#C4C7C8",
  400: "#96A6B6",
  500: "#7c878e",
  600: "#495A69",
  700: "#3E4C59",
  800: "#242D34",
  900: "#161B1F",
};

const primary = {
  main: "#0A77B2",
  light: "#E7F1F7",
  dark: "#0F188F",
  contrastText: "#FFFFFF",
};

const secondary = {
  main: "#ECAC37",
  light: "#FAEACD",
  dark: "#C28203",
  contrastText: "#FFFFFF",
};

const success = {
  main: "#1B8C42",
  light: "#E8F4EC",
  dark: "#0F5126",
  contrastText: "#FFFFFF",
};

const info = {
  main: "#2196F3",
  light: "#64B6F7",
  dark: "#0B79D0",
  contrastText: "#FFFFFF",
};

const warning = {
  main: "#FFB020",
  light: "#FFBF4C",
  dark: "#B27B16",
  contrastText: "#FFFFFF",
};

const error = {
  main: "#D14343",
  light: "#DA6868",
  dark: "#922E2E",
  contrastText: "#FFFFFF",
};

const text = {
  primary: "#323E48",
  secondary: "#7c878e",
  disabled: "rgba(55, 76, 81, 0.48)",
};

const background = {
  default: "#F9FAFE",
  paper: "#FFFFFF",
};

const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      lgEx: 1350,
      xl: 1500,
    },
  },
  typography: {
    // useNextVariants: true,
    // fontFamily: "Roboto",
    fontFamily:
      '"Roboto"' +
      // ', "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"' +
      "",
    h1: {
      // fontFamily: "Roboto, sans-serif",
      fontWeight: 700,
      color: "#187AB5",
      fontSize: 45,
    },
    h2: {
      // fontFamily: "Roboto, sans-serif",
      fontWeight: 700,
      color: "#187AB5",
      fontSize: 40,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.57,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.75,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.57,
    },
    h5: {
      // fontFamily: "Roboto, sans-serif",
      fontSize: 18,
      fontWeight: "500",
    },
    h6: {
      // fontFamily: "Roboto, sans-serif",
      color: "#187AB5",
      fontSize: 16,
      fontWeight: "bold",
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          // shrink: true,
        },
      },
      styleOverrides: {
        root: {
          // "& .MuiFormLabel-root": {
          //   fontSize: "0.875em",
          //   lineHeight: "1.875em",
          // },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        sizeSmall: {
          padding: "6px 16px",
        },
        sizeMedium: {
          padding: "8px 20px",
        },
        sizeLarge: {
          padding: "11px 24px",
        },
        textSizeSmall: {
          padding: "7px 12px",
        },
        textSizeMedium: {
          padding: "9px 16px",
        },
        textSizeLarge: {
          padding: "12px 16px",
        },
      },
      variants: createSizedButtonVariants(
        { variant: "secondaryContained" },
        {
          color: globalTheme.palette.text.primary,
          backgroundColor: "#FAEACD",
        },
      ),
      //   [
      //   {
      //     props: { variant: "secondaryContained" },
      //     style: {
      //       color: globalTheme.palette.text.primary,
      //       backgroundColor: "#FAEACD",
      //     },
      //   },
      // ],
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          // borderRadius: "8px !important",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: globalTheme.palette.primary.light,
            color: globalTheme.palette.primary.contrastText,
          },
          "&.Mui-selected": {
            backgroundColor: globalTheme.palette.primary.main,
            color: globalTheme.palette.primary.contrastText,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: "8px",
          margin: "4px",
          ...(ownerState.size === "small"
            ? {
                "& svg": {
                  fontSize: "16px !important",
                },
              }
            : ownerState.size === "medium" && {
                "& svg": {
                  fontSize: "24px !important",
                },
              }),
        }),
      },
      defaultProps: {
        icon: <BpIcon />,
        checkedIcon: <BpCheckedIcon />,
        indeterminateIcon: <BpIndeterminateIcon />,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          // border: "solid 1px",
          height: "32px",
          width: "32px",
          "& > svg": {
            height: "24px !important",
            width: "24px !important",
            fontSize: "24px !important",
          },
          "&:hover": {
            boxShadow: "unset",
          },
          "&:disabled": {
            // backgroundColor: "rgba(55, 76, 81, 0.12)",
            "& > svg": {
              fill: "rgba(55, 76, 81, 0.12)",
              color: "rgba(55, 76, 81, 0.12)",
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "15px 16px",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root": {
            fontSize: "12px",
            fontWeight: 600,
            lineHeight: 1,
            letterSpacing: 0.5,
            textTransform: "uppercase",
            borderTop: `solid 2px ${globalTheme.palette.divider}`,
            borderBottom: `solid 2px ${globalTheme.palette.divider}`,
            backgroundColor: "#F9FAFE", //globalTheme.palette.background.default,
            padding: "8px",
          },
          "& .MuiTableCell-paddingCheckbox": {
            paddingTop: 4,
            paddingBottom: 4,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        // input: {
        //   padding: "8px",
        // },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: "primary",
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          height: "46px !important",
          width: "64px",
        },
        switchBase: {
          top: "4px",
          left: "4px",
          transition: "all 100ms ease-in-out",
          "&.Mui-checked": {
            transform: "translateX(18px)",
          },
        },
        track: {
          borderRadius: "16px",
          boxShadow: "inset 1px 1px 4px 0px rgba(66, 68, 90, 1)",
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        disableInteractive: true,
        placement: "top",
        arrow: true,
        PopperProps: {
          // disablePortal: true,
          sx: {
            "&[data-popper-reference-hidden]": {
              visibility: "hidden",
              "pointer-events": "none",
            },
          },
        },
      },
    },
  },
  palette: {
    primary,
    secondary,
    success,
    warning,
    info,
    error,
    text,
    background,
    neutral,
    action: {
      active: neutral[500],
      focus: "rgba(55, 76, 81, 0.12)",
      hover: "rgba(55, 76, 81, 0.04)",
      selected: "rgba(55, 76, 81, 0.08)",
      disabledBackground: "rgba(55, 76, 81, 0.12)",
      disabled: "rgba(55, 76, 81, 0.26)",
    },
  },
};

export const MUITheme = createMuiTheme(themeOptions);

export const customColors = {
  forecastRemovedRecordBackground: "#F2F3F3",
  forecastRemovedRecordText: "#00000080",
  dashboardSectionTitle: "#714F0B",
};
