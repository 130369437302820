import React, { FunctionComponent } from "react";
import { css, style } from "glamor";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import PillButton from "../../../PillButton";
import {
  allTimeZones,
  newyorkCountryName,
  washingtonCountryName,
} from "../../../../services/timezone";
import { useSharedStyles } from "../../useSharedStyles";
import { AccountInformationContext } from "./reducer";
import { setFirstName, setLastName, setTimeZone } from "./actions";
import {
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../../../configs/axios-export.custom";
import GrowlContext from "../../../../services/growlContext";
import UserContext from "../../../../services/UserContext";
import axios from "axios";
import toBase64 from "../../../../utils/base64/converToBase64";
import addphoto from "../../../../assets/icons/addphoto.svg";
import "../Users/UserMenu.css";
import { ProgressBar } from "primereact/progressbar";
import styled from "@emotion/styled";
import { RootState, useSelector } from "../../../../store";
import { Box, Card, CardHeader, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import SimpleModal from "../../../Modals/SimpleModal";
import { useForm } from "react-hook-form";
import Input from "../../../Widgets/Inputs/Input";
import { RequestStatus } from "../../../../utils/Helpers/fetchStatus";
import { useDispatch } from "react-redux";
import {
  deleteAccount,
  resetDeleteAccountState,
} from "../../../../slices/dashboard";
import toast from "react-hot-toast";

const styles = {
  fullWidthDropdown: {
    width: "100%",
  },
  fullWidth: {
    display: "block",
    width: "100%",
  },
  profileImg: {
    height: "75px",
    width: "75px",
    borderRadius: "50%",
    margin: "auto",
  },
  buttonIcon: {
    padding: "5px",
  },
  cropPhoto: {
    // alignItems:"flex-start",
    borderRadius: "50%",
    border: "1px solid  #18a0fb",
    width: "150px",
    height: "150px",
  },
  uploadButton: {
    background: "#ECAC37",
    width: "auto",
    borderRadius: "14px",
    padding: "5px",
    border: "none",
    cursor: "pointer",
    marginBottom: "8px",
  },
};
const Img = styled.img({
  margin: "1px",
  height: "115px",
  width: "115px",
  borderRadius: "50%",
  position: "relative",
});
interface ImageString {
  updateBase64String: ({}) => void;
}

const DELETE_ACCOUNT_STRING_TO_ENTER = "delete my account";

const AccountInformation: FunctionComponent<ImageString> = ({
  updateBase64String,
}) => {
  const growl = React.useContext(GrowlContext);
  const userContext = React.useContext(UserContext);
  const dispatch = useDispatch();

  const { fetchStatus, error } = useSelector(
    (state: RootState) => state.dashboard.deleteAccount,
  );

  const { control, watch } = useForm<{
    keywordToDelete: string;
  }>({
    defaultValues: {
      keywordToDelete: "",
    },
  });

  const [profileImgString, setProfileImg] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [ButtonText, setButtonText] = React.useState("Upload");
  const { accountInformationState, accountInformationDispatch } =
    React.useContext(AccountInformationContext);

  // upload profile image

  const [img, setImg] = React.useState({ preview: "", raw: "" });

  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);

  const watchDeleteInput = watch("keywordToDelete");

  const handleCloseDeleteModal = (): void => {
    setDeleteModalOpen(false);
  };

  const handleDeleteAccount = (): void => {
    dispatch(deleteAccount(userContext));
  };

  const handleImgChange = (e) => {
    if (e.target.files.length) {
      setImg({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const saveProfilePhoto = async (base64string: string) => {
    setIsLoading(true);
    setButtonText("Uploading");
    // remove the metadata
    let base64String = base64string.split("base64,")[1];

    const header = {
      headers: {
        Authorization: userContext.user.jwt,
        AccessToken: userContext.user.accessToken,
        X_Konektid_Authorization: userContext.user.konektidToken,
        "Content-Type": " image/jpeg",
      },
    };
    axios
      .post(
        getFullUrl("/api/user/profile/picture", {
          useDedicatedEnvironment: true,
        }),
        base64String,
        header,
      )
      .then((res) => {
        //  set new state in profile overview
        updateBase64String(base64String);
        growl.current.show({
          severity: "success",
          summary: "Profile picture uploaded successfully",
        });

        setIsLoading(false);
        setButtonText("Upload");
      })
      .catch((error) => {
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: "Unable to upload the image",
          });
        }
      });
  };
  const handleImgUpload = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (img.raw) {
      saveProfilePhoto(await toBase64(img.raw));
    }
  };

  const sharedStyles = useSharedStyles();

  const canSavePersonalInfo =
    accountInformationState.firstName.currentValue !==
      accountInformationState.firstName.serverValue ||
    accountInformationState.lastName.currentValue !==
      accountInformationState.lastName.serverValue ||
    accountInformationState.timeZone.currentValue !==
      accountInformationState.timeZone.serverValue;

  const savePersonalInfo = () => {
    // if DC, send correct country name (new york)
    const timezoneInfo =
      accountInformationState.timeZone.currentValue === washingtonCountryName
        ? newyorkCountryName
        : accountInformationState.timeZone.currentValue;

    if (accountInformationState.firstName.currentValue == "") {
      growl.current.show({
        severity: "error",
        summary: "Failed, first name is required.",
      });
      return;
    } else if (accountInformationState.lastName.currentValue == "") {
      growl.current.show({
        severity: "error",
        summary: "Failed, last name is required.",
      });
      return;
    } else {
      axios
        .post(
          getFullUrl("/api/user/profile", { useDedicatedEnvironment: true }),
          {
            name: accountInformationState.firstName.currentValue,
            familyName: accountInformationState.lastName.currentValue,
            timezone: timezoneInfo,
          },
          createRequestWithAuthHeaders(userContext),
        )
        .then(() => {
          // If pass then need to refresh token and reload this component
          userContext.refreshUserToken(true);
        })
        .catch((message) => {
          console.error(message);
          growl.current.show({
            severity: "error",
            summary: "Error saving profile",
          });
        });
    }
  };

  // TO DO - implement reducers/ actions

  React.useEffect(() => {
    axios
      .get(
        getFullUrl("/api/user/profile/picture", {
          useDedicatedEnvironment: true,
        }),
        createRequestWithAuthHeaders(userContext),
      )
      .then(async (response) => {
        let bstr = await response.data;
        let base64ToString = Buffer.from(bstr, "base64").toString();
        setProfileImg(base64ToString);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  React.useEffect(() => {
    if (RequestStatus.isDone(fetchStatus)) {
      setDeleteModalOpen(false);
      window.location.reload();
      dispatch(resetDeleteAccountState());
    } else if (RequestStatus.isError(fetchStatus)) {
      toast.error(error);
      dispatch(resetDeleteAccountState());
    }
  }, [fetchStatus]);

  const imgHandler = () => {
    if (img.raw) {
      return (
        <Img
          className="img-centered-clipped"
          src={img.preview}
          alt=""
          style={{ display: "none" }}
          onLoad={(e) => (e.currentTarget.style.display = "")}
        />
      );
    } else if (profileImgString) {
      return (
        <img
          style={{ borderRadius: "50%", marginTop: "4px", display: "none" }}
          className="img-centered-clipped"
          height={115}
          width={115}
          src={"data:image/jpeg;base64," + profileImgString}
          alt="loading"
          onLoad={(e) => (e.currentTarget.style.display = "")}
        />
      );
    } else {
      return (
        <div>
          <img style={{ paddingTop: "0px" }} src={addphoto} alt=""></img>
        </div>
      );
    }
  };

  return (
    <div className="p-grid">
      {/* left pane */}
      <div className="p-col-3" style={{ textAlign: "center" }}>
        <div className="profile-picture-title">Profile Picture</div>
        <div className="profile-image-container">
          <label htmlFor="upload-button">{imgHandler()}</label>
          <input
            accept="image/*"
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            onChange={handleImgChange}
          />
        </div>
        <div className="image-upload-button">
          <button style={styles.uploadButton} onClick={handleImgUpload}>
            {ButtonText}
          </button>
          {isLoading && (
            <ProgressBar
              mode="indeterminate"
              style={{ height: "6px" }}
            ></ProgressBar>
          )}
        </div>
      </div>
      {/* right panel */}
      <div className="p-col-9">
        {/* Hide username field */}
        {/* <div className="p-col-12">
            <label {...css(sharedStyles.floatingLabel)} htmlFor="username">
              Username
            </label>
            <div style={{backgroundColor:"#e7e7e7"}}
              id="email"
              placeholder="username"
              {...css({ ...sharedStyles.text, ...sharedStyles.radiusBordered, })}
            >
              {accountInformationState.firstName.currentValue &&(<strong>
               {accountInformationState.firstName.currentValue}
               {"."}
               {accountInformationState.lastName.currentValue}</strong>)}
              
            </div>
          </div> */}

        <div
          className="p-col-12"
          style={{ display: "flex", paddingLeft: "0px" }}
        >
          <div className="p-col-6">
            <label {...css(sharedStyles.floatingLabel)} htmlFor="firstName">
              First Name
            </label>
            <InputText
              value={accountInformationState.firstName.currentValue}
              placeholder="First Name"
              style={{
                ...sharedStyles.text,
                ...sharedStyles.radiusBordered,
                ...styles.fullWidth,
                fontWeight: 600,
              }}
              onChange={(e) =>
                accountInformationDispatch(
                  setFirstName((e.target as HTMLInputElement).value),
                )
              }
            />
          </div>

          <div className="p-col-6">
            <label {...css(sharedStyles.floatingLabel)} htmlFor="lastName">
              Last Name
            </label>
            <InputText
              value={accountInformationState.lastName.currentValue}
              placeholder="Last Name"
              style={{
                ...sharedStyles.text,
                ...sharedStyles.radiusBordered,
                ...styles.fullWidth,
                fontWeight: 600,
              }}
              onChange={(e) =>
                accountInformationDispatch(
                  setLastName((e.target as HTMLInputElement).value),
                )
              }
            />
          </div>
        </div>

        <div className="p-col-12">
          <label {...css(sharedStyles.floatingLabel)} htmlFor="email">
            Email Address
          </label>
          <div
            style={{ backgroundColor: "#e7e7e7" }}
            id="email"
            {...css({ ...sharedStyles.text, ...sharedStyles.radiusBordered })}
          >
            {accountInformationState.email}
          </div>
        </div>

        <div className="p-col-12">
          <label {...css(sharedStyles.floatingLabel)} htmlFor="timeZone">
            Time Zone
          </label>
          <Dropdown
            placeholder="Local Timezone"
            options={allTimeZones}
            value={accountInformationState.timeZone.currentValue}
            valueTemplate={(option) => {
              if (option) {
                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ minWidth: 240, margin: 0 }}>{option.country}</p>
                    <span>{option.utc}</span>
                  </div>
                );
              } else {
                return accountInformationState.timeZone.currentValue;
              }
            }}
            itemTemplate={(option) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 400,
                  }}
                >
                  <p style={{ minWidth: 240, borderRadius: "20px" }}>
                    {option.country}
                  </p>
                  <span>{option.utc}</span>
                </div>
              );
            }}
            onChange={(e) =>
              accountInformationDispatch(setTimeZone(e.value.country))
            }
            style={{
              ...styles.fullWidthDropdown,
              ...sharedStyles.radiusBordered,
              fontWeight: 600,
              height: 40,
              padding: 3,
              paddingLeft: 10,
            }}
            className="p-dropdown-radiused dropdown-arrow"
          />
        </div>

        <div className="p-col-12" style={{ textAlign: "center" }}>
          <PillButton
            label="Save"
            style={{ width: "150px" }}
            disabled={!canSavePersonalInfo}
            onClick={() => savePersonalInfo()}
          />
        </div>
      </div>
      <Card
        variant={"outlined"}
        sx={{
          // p: 2,
          borderRadius: "8px",
          width: "100%",
          borderWidth: "2px",
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "space-between",
          alignItems: "center",
          pr: 2,
          gap: {
            xs: 0,
            md: 2,
          },
          pb: {
            xs: 2,
            md: 0,
          },
        }}
      >
        <SimpleModal
          open={deleteModalOpen}
          handleClose={handleCloseDeleteModal}
          contentSx={{
            overflowY: "auto",
          }}
          title={"Detele Account"}
          acceptLabel={"Delete"}
          acceptButtonColor={"error"}
          acceptButtonDisabled={
            watchDeleteInput !== DELETE_ACCOUNT_STRING_TO_ENTER ||
            RequestStatus.isFetching(fetchStatus)
          }
          acceptButtonType={"button"}
          handleAccept={handleDeleteAccount}
        >
          <Typography variant={"subtitle2"} color={"inherit"}>
            Are you sure you want to delete your account? You will lose all the
            data associated with your account and you cannot revert this action.
          </Typography>

          <Typography
            variant={"subtitle2"}
            color={"inherit"}
            sx={{
              mt: 2,
              mb: 1,
            }}
          >
            <strong>
              To proceed, please type "<i>{DELETE_ACCOUNT_STRING_TO_ENTER}</i>
              ":
            </strong>
          </Typography>
          <Input
            type={"text"}
            name={"keywordToDelete"}
            control={control}
            fullWidth
            size={"small"}
          />
        </SimpleModal>
        <CardHeader
          title={
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <WarningAmberIcon
                sx={{
                  height: "18px !important",
                  width: "18px !important",
                }}
                color={"error"}
              />
              Delete Account
            </Box>
          }
          titleTypographyProps={{
            fontSize: "var(--font-size-xxs2)",
            fontWeight: 700,
          }}
          subheaderTypographyProps={{
            fontSize: "15px",
            fontWeight: 600,
          }}
          subheader={
            "This action will remove your account and cannot be reverted."
          }
        />
        <Button
          color={"error"}
          variant={"contained"}
          sx={{
            width: "200px",
            height: "40px",
            borderRadius: "20px",
            fontWeight: "bold",
            fontSize: "1em",
          }}
          onClick={() => setDeleteModalOpen(true)}
        >
          Delete
        </Button>
      </Card>
    </div>
  );
};

export default AccountInformation;
