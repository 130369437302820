import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import DashboardSection from "./DashboardSection";
import Update, { UpdateType } from "../Update";
import { css } from "glamor";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Box, Button as MuiButton, LinearProgress } from "@mui/material";
import ThemeContext from "../../configs/theme";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import UserContext, {
  doesUserHaveRole,
  isUserAdminOrSemiAdminWith,
  ServerOrgRoles,
} from "../../services/UserContext";
import GrowlContext from "../../services/growlContext";
import SectionHeader from "./SectionHeader";
import IconTooltip from "../IconTooltip";
import axios from "axios";
import "../update.css";
import { AddUpdateFormComp } from "./AddUpdateForm";
import { useSharedStyles } from "./useSharedStyles";
import { goToUrlInNewTab } from "../../configs/resources";
import { useHistory } from "react-router-dom";
import { rowsPerPageOptionsStandard } from "../../utils/constants/gridsOptions";
import { buildQuery } from "../../utils/Helpers/queryBuilder";
import { uniqBy } from "lodash";
import UpgradePlanModal from "../Modals/UpgradePlanModal";

const useStyles: () => { [key: string]: React.CSSProperties } = () => {
  const theme = React.useContext(ThemeContext);

  return {
    header: {
      font: theme.v2Fonts.mediumHeader,
    },
    buttonContainer: {
      marginTop: "10px",
      display: "flex",
      justifyContent: "flex-end",
    },
    tooltip: {
      font: theme.v2Fonts.context,
      verticalAlign: "middle",
    },
  };
};

interface UpdatesPagination {
  pageIndex: number;
  rowsPerPage: number;
}

const initialPagination = {
  pageIndex: 0,
  rowsPerPage: rowsPerPageOptionsStandard[0],
};

const AidkonektUpdates: FunctionComponent = () => {
  const userContext = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);
  const styles = useStyles();

  const history = useHistory();

  const isMember = doesUserHaveRole(
    userContext,
    ServerOrgRoles.organizationMember,
  );
  const [upgradePlanModalOpen, setUpgradePlanModalOpen] =
    useState<boolean>(false);

  const [updates, setUpdates] = useState<UpdateType[]>([]);
  const [updatesLoading, setUpdatesLoading] = useState<boolean>(false);
  const [keyword, setKeyword] = useState("");
  const [pagination, setPagination] =
    useState<UpdatesPagination>(initialPagination);

  const sharedStyles = useSharedStyles();

  const fetchUpdatesAsync = useCallback(() => {
    const request = createAuthenticatedRequest(userContext);
    setUpdatesLoading(true);
    axios
      .get(
        getFullUrl(
          `/api/user/update${buildQuery(
            !sharedStyles.isFree
              ? { ...pagination }
              : {
                  pageIndex: 0,
                  rowsPerPage: 3,
                },
          )}`,
          {
            useDedicatedEnvironment: true,
          },
        ),
        request,
      )
      .then((response) => {
        setUpdatesLoading(false);
        type AdminUpdateResponse = {
          id: number;
          message: string;
          title: string;
          source: string;
          createdOn: Date;
        };
        const updates: AdminUpdateResponse[] = response.data;
        if (updates.length > 0)
          setUpdates((prev) => uniqBy([...prev, ...updates], "id"));
        else
          growl.current.show({
            severity: "info",
            summary: "No more updates to load",
          });
      })
      .catch((e) => {
        setUpdatesLoading(false);
        console.error(e);
        growl.current.show({
          severity: "error",
          summary: "Unable to load user profile",
        });
      });
  }, [pagination]);

  useEffect(fetchUpdatesAsync, [userContext, pagination]);

  const addUpdate = useCallback(
    (updateModel: UpdateType, updates: UpdateType[]) => {
      axios
        .post(
          getFullUrl("/api/admin/update", { useDedicatedEnvironment: true }),
          {
            message: updateModel.message,
            title: updateModel.title,
            createdOn: updateModel.createdOn,
            source: updateModel.source,
          },
          createRequestWithAuthHeaders(userContext),
        )
        .then((response) => {
          const newUpdate = { ...response.data };
          setUpdates([newUpdate, ...updates]);
        })
        .catch((e) => {
          console.error(e);
          growl.current.show({
            severity: "error",
            summary: "Error saving new update",
            detail: e,
          });
        });
    },
    [],
  );

  const deleteUpdate = useCallback(
    (updateId: number) => {
      const request = {
        ...createRequestWithAuthHeaders(userContext),
        data: { id: updateId },
      };
      axios
        .delete(
          getFullUrl("/api/admin/update", { useDedicatedEnvironment: true }),
          request,
        )
        .then(() => {
          const updatedUpdates = updates.filter(
            (update) => update.id !== updateId,
          );
          setUpdates(updatedUpdates);
        })
        .catch((e) => {
          console.error(e);
          growl.current.show({
            severity: "error",
            summary: "Error deleting update",
            detail: JSON.stringify(e),
          });
        });
    },
    [updates],
  );

  const editUpdate = useCallback(
    (
      updateId: number,
      editedUpdateText: string,
      editedUpdateTitle: string,
      editedUpdateDate: null | Date,
      editedUpdateSource: string,
    ) => {
      axios
        .put(
          getFullUrl("/api/admin/update", { useDedicatedEnvironment: true }),
          {
            id: updateId,
            message: editedUpdateText,
            title: editedUpdateTitle,
            createdOn: editedUpdateDate,
            source: editedUpdateSource,
          },
          createAuthenticatedRequest(userContext),
        )
        .then(() => {
          const updatedUpdates = updates.map((update) =>
            update.id === updateId
              ? {
                  ...update,
                  message: editedUpdateText,
                  createdOn: editedUpdateDate,
                  title: editedUpdateTitle,
                  source: editedUpdateSource,
                }
              : update,
          );
          setUpdates(updatedUpdates);
        })
        .catch((e) => {
          console.error(e);
          growl.current.show({
            severity: "error",
            summary: "Error editing update",
            detail: e,
          });
        });
    },
    [updates],
  );

  const canEditUpdates = isUserAdminOrSemiAdminWith(
    userContext,
    ServerOrgRoles.EditUserNotifications,
  );

  const showNewUpdateMessage = React.useCallback(
    async (updates: UpdateType[]) => {
      const { isConfirmed, model } = await AddUpdateFormComp({
        createdOn: null,
        id: 0,
        message: "",
        source: "",
        title: "",
      });

      if (isConfirmed) {
        addUpdate(model, updates);
      }
    },
    [],
  );

  return (
    <DashboardSection>
      <UpgradePlanModal
        upgradePlanModalOpen={upgradePlanModalOpen}
        setUpgradePlanModalOpen={(val: boolean) => setUpgradePlanModalOpen(val)}
      />
      <div className="cal-header-container">
        <SectionHeader text={"USAID Market News Feed"}>
          <span {...css(styles.tooltip)}>
            <IconTooltip
              tooltipText={
                "We share relevant updates from USAID and key USAID market partners here. Many of these come from the several USAID newsletter that we monitor for you. This is a great place for our paid subscribers to share their own updates relevant to USAID. Please message us if you have any updates that you would like us to share here."
              }
            />
          </span>
        </SectionHeader>
        {canEditUpdates && (
          <div className="add-update-btn">
            <div {...css(styles.buttonContainer)}>
              <Button
                icon="pi pi-plus"
                label="Add Update"
                className="aid-blu-btn"
                onClick={() => showNewUpdateMessage(updates)}
              />
            </div>
          </div>
        )}
      </div>

      {/* {canEditUpdates && (
        <>
          <div {...css(styles.buttonContainer)}>
            <Button
              icon="pi pi-plus"
              label="Add Update"
              className="aid-blu-btn"
              onClick={() => showNewUpdateMessage(updates)}
            />
          </div>
        </>
      )} */}
      <div>
        <InputText
          placeholder="Search..."
          value={keyword}
          style={{
            margin: "3px 0 10px",
            borderRadius: "30px",
            width: "100%",
            padding: "8px 16px",
            ...(sharedStyles.isFree && { display: "none" }),
          }}
          className="aid-search"
          onChange={(r) => setKeyword((r.target as any).value)}
        />
      </div>
      <div className="search-box-sep"></div>
      <Box sx={{ height: "4px", ml: "-20px", width: "calc(100% + 40px)" }}>
        {updatesLoading && <LinearProgress />}
      </Box>
      <div
        style={{
          height: sharedStyles.isFree ? "75vh" : "70vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {updates
          .filter(
            (r) =>
              r.message.match(new RegExp(keyword, "i")) ||
              r.title?.match(new RegExp(keyword, "i")),
          )
          .map((update) => (
            <Update
              key={update.id}
              update={update}
              canEditUpdates={canEditUpdates}
              editUpdate={editUpdate}
              deleteUpdate={deleteUpdate}
            />
          ))}
        {!sharedStyles.isFree && (
          <MuiButton
            variant={"secondaryContained"}
            sx={{
              my: 2,
            }}
            onClick={() =>
              setPagination((prev) => ({
                ...prev,
                pageIndex: prev.pageIndex + 1,
              }))
            }
            disabled={updatesLoading}
          >
            Load more
          </MuiButton>
        )}
        {sharedStyles.isFree && !updatesLoading && (
          <MuiButton
            variant={"secondaryContained"}
            sx={{
              my: 2,
            }}
            {...(isMember
              ? { onClick: () => setUpgradePlanModalOpen(true) }
              : {
                  component: "a",
                  href: "/tiers",
                  target: "_blank",
                })}
          >
            Upgrade your plan to see more
          </MuiButton>
        )}
      </div>
    </DashboardSection>
  );
};

export default AidkonektUpdates;
