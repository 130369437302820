import React, { ChangeEvent, useState } from "react";
import { useStyles } from "../../hooks/useStyles";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { onEnter } from "../../services/onEnterKey";

import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import UserContext from "../../services/UserContext";
import toast from "react-hot-toast";
import { Box, CircularProgress } from "@mui/material";

type ConfirmationEmailParams = {
  email: string;
};

type SetPasswordParams = {
  updateErrorMessage: React.Dispatch<string | null>;
  switchState: React.Dispatch<string>;
  errorMessage: string | null | undefined;
};

function UserConfirmation({
  updateErrorMessage,
  switchState,
  errorMessage,
}: SetPasswordParams) {
  const searchParams = new URLSearchParams(document.location.search);
  const styles = useStyles();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as ConfirmationEmailParams;
  const context = React.useContext(UserContext);
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");
  const [state, setState] = useState({
    email: searchParams.get("email"),
    authCode: "",
    requestSent: false,
    errorMessage: "",
    message: "",
  });
  const [resetLoading, setResetLoading] = useState<boolean>(false);

  const setNewPasswordSubmit = async () => {
    if (password !== passwordConfirm) {
      setState((state) => ({
        ...state,
        errorMessage: "Your password and confirmation password do not match",
        message: "",
      }));
      return;
    }

    const confirmRequest = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post(
        getFullUrl("/api/auth/confirmencryptedpasswordreset", {
          useDedicatedEnvironment: true,
        }),
        {
          email: state.email,
          password: password,
          authCode: state.authCode,
        },
        confirmRequest,
      )
      .then(() => {
        alert("successfully set password!");
        switchState("showSignIn");
        history.push("/auth");
      })
      .catch((err) => {
        if (err.response.status === 422) {
          updateErrorMessage(
            "Invalid verification code provided, please try again",
          );
        } else if (err && err.message) {
          const errMessage = err?.response?.data?.includes(
            "Password does not conform to policy: Password not long enough",
          )
            ? "Password must be at least 8 characters long"
            : err?.response?.data?.includes(
                "Invalid verification code provided",
              )
            ? "Invalid verification code provided"
            : "500-Authentication failed. For assistance, please contact support@aidkonekt.com";
          setState((state) => ({
            ...state,
            errorMessage: errMessage,
            message: "",
          }));
        }
      });
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const key = event.currentTarget.id;
    const value = event.currentTarget.value;

    setState((state) => ({ ...state, [key]: value }));
  };

  const resetPasswordReq = async () => {
    let url = getFullUrl("/api/auth/resetencryptedpassword", {
      useDedicatedEnvironment: true,
    });
    setResetLoading(true);
    await axios
      .post(url, { email: state.email }, createRequestWithAuthHeaders(context))
      .then(() => {
        setResetLoading(false);
        setState((state) => ({
          ...state,
          errorMessage: "",
          requestSent: true,
          message: "",
        }));
      })
      .catch((err) => {
        setResetLoading(false);
        if (err?.response?.status === 409) {
          err?.response?.data?.Message &&
            toast.error(err.response.data.Message);
          switchState("showSignIn");
          history.push("/auth");
        } else setState((state) => ({ ...state, errorMessage: err.message }));
      });
  };

  React.useEffect(() => {
    //  call reset password on load to send confirmation code to user email.
    resetPasswordReq();
  }, []);

  const verifyCode = async () => {
    if (!state.authCode) {
      setState((state) => ({
        ...state,
        errorMessage: "Please enter a verification code",
      }));
      return;
    }

    let url = getFullUrl("/api/auth/verify", { useDedicatedEnvironment: true });
    await axios
      .post(
        url,
        {
          email: state.email,
          authCode: state.authCode,
        },
        createRequestWithAuthHeaders(context),
      )
      .then(() => {
        history.push("/auth");
      })
      .catch((err) => {
        setState((state) => ({ ...state, errorMessage: err.message }));
      });
  };

  return (
    <div style={styles.innerContainer}>
      <Box
        sx={{
          position: "absolute",
          width: "550px",
          height: "520px",
          marginTop: "-24px",
          display: resetLoading ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 9999,
          borderRadius: "20px",
          backgroundColor: "#DDDDDDBB",
        }}
      >
        <CircularProgress />
      </Box>
      <div style={styles.header}>
        <img
          src={styles.aidkonektIcon}
          style={styles.icon}
          alt="Aidkonekt"
          className="formIcon"
        ></img>
        Set Password
      </div>
      {state.requestSent && (
        <>
          <div style={styles.errorDiv}>
            <p>{state.errorMessage}</p>
          </div>

          <label htmlFor="authCode" style={styles.inputLabel}>
            Confirmation Code
          </label>
          <input
            autoComplete="off"
            id="authCode"
            type="text"
            onChange={onChange}
            style={styles.inputStyle}
          />

          <label htmlFor="newpassword" style={styles.inputLabel}>
            Password
          </label>
          <input
            autoComplete="new-password"
            id="newpassword"
            onChange={(evt) => setPassword(evt.target.value)}
            style={styles.customInputStyle}
            type="password"
          />

          <label htmlFor="passwordConfirm" style={styles.inputLabel}>
            Confirm Password
          </label>
          <input
            autoComplete="off"
            id="passwordConfirm"
            onChange={(evt) => setPasswordConfirm(evt.target.value)}
            onKeyUp={onEnter(setNewPasswordSubmit)}
            style={styles.customInputStyle}
            type="password"
          />

          <div
            style={styles.buttonStyle}
            onClick={setNewPasswordSubmit}
            className="aid-blu-btn"
          >
            <p style={styles.buttonTextStyle} className="set-password">
              Set Password
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default UserConfirmation;
