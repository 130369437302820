import { DataColumnConfigProps } from "../../components/MaterialTable/Table";
import { format } from "date-fns";

export const getReportsColumnsConfig = (
  getSortableColumnPropertyName: (_propertyName: string) => string,
): Array<DataColumnConfigProps> => [
  {
    propertyName: "link",
    label: "Link",
    order: 0,
    isVisible: true,
    sortable: false,
    width: 50,
    disableModalWithDetails: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "country",
    label: "Country",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 200,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "title",
    label: "Title",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 300,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "documentType",
    label: "Document Type",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 200,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "publicationDate",
    label: "Publication Date",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 200,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    cellFormatter: (value) =>
      new Date(value) && new Date(value).toISOString().startsWith("0")
        ? "N/A"
        : format(new Date(value), "dd MMMM yyyy"),
    disableModalWithDetails: true,
  },
  {
    propertyName: "authoringOrganization",
    label: "Authoring Organization",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "personalAuthors",
    label: "Authors",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 300,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "technicalSectors",
    label: "Technical Sectors",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 300,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "abstract",
    label: "Abstract",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "contractGrantNumber",
    label: "Contract Grant Number",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 200,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "contractGrantNumberEvaluated",
    label: "Contract Grant Number Evaluated",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
];
