import axios from "axios";
import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../configs/axios-export.custom";
import { UserContextType } from "../services/UserContext";
import { ForecastRecord, ForecastSearch } from "../utils/types/Forecast";
import { RequestStatus } from "../utils/Helpers/fetchStatus";
import { buildQuery } from "../utils/Helpers/queryBuilder";
import { compact } from "lodash";
import { ColumnsSettingsData } from "../components/MaterialTable/Table";
import { FORECAST_COLUMNS_SETTINGS_KEY } from "../screens/ForecastV2/ForecastV2";
import { htmlDecode } from "../utils/Helpers/htmlDecode";
import { shareOpportunityToUser } from "../utils/Helpers/asyncFunctions";

interface DropdownOption {
  value: string;
  label: string;
}

interface ForecastState {
  items: Array<ForecastRecord>;
  total: number;
  attributes: { [key: string]: any };
  lastUpdatedUtc: string | null;
  totalLiveRecords: number;
  fetchStatus: string;
  details: {
    fetchStatus: string;
    records: any[];
  };
  saveAdminData: {
    postFetchStatus: string;
  };
  shareOpportunities: {
    postFetchStatus: string;
  };
  dropdownOptions: {
    fetchStatus: string;
    contriesOffices: Array<DropdownOption>;
    sectors: Array<DropdownOption>;
    awardTypes: Array<DropdownOption>;
    geographicalCodes: Array<DropdownOption>;
  };
  tableSettings: {
    columnsOrder: Array<ColumnsSettingsData>;
    fetchStatus: string;
    patchFetchStatus: string;
    deleteFetchStatus: string;
  };
}

const initialState: ForecastState = Object.freeze({
  items: [],
  total: 0,
  attributes: {},
  lastUpdatedUtc: null,
  totalLiveRecords: 0,
  fetchStatus: RequestStatus.statuses.NULL,
  details: {
    fetchStatus: RequestStatus.statuses.NULL,
    records: [],
  },
  saveAdminData: {
    postFetchStatus: RequestStatus.statuses.NULL,
  },
  shareOpportunities: {
    postFetchStatus: RequestStatus.statuses.NULL,
  },
  dropdownOptions: {
    fetchStatus: RequestStatus.statuses.NULL,
    contriesOffices: [],
    sectors: [],
    awardTypes: [],
    geographicalCodes: [],
  },
  tableSettings: {
    columnsOrder: [],
    fetchStatus: RequestStatus.statuses.NULL,
    patchFetchStatus: RequestStatus.statuses.NULL,
    deleteFetchStatus: RequestStatus.statuses.NULL,
  },
});

export const getForecasts: any = createAsyncThunk(
  "forecast/getForecasts",
  async (
    data: { context: UserContextType; params: ForecastSearch },
    thunkAPI,
  ) => {
    const url = `/api/BusinessForecast${buildQuery(data.params)}`;
    const response = await axios.get(
      getFullUrl(url, {
        useDedicatedEnvironment: true,
      }),
      createRequestWithAuthHeaders(data.context),
    );

    return response?.data;
  },
);

export const getForecastDetails: any = createAsyncThunk(
  "forecast/getForecastDetails",
  async (
    data: {
      context: UserContextType;
      id: string;
    },
    thunkAPI,
  ) => {
    const { context, id } = data;
    const response = await axios.get(
      getFullUrl(`/api/BusinessForecast/${id}/details`, {
        useDedicatedEnvironment: true,
      }),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

export const saveAdminData: any = createAsyncThunk(
  "forecast/saveAdminData",
  async (
    data: {
      context: UserContextType;
      loadedForecastId: number;
      subPrimes: string | null;
      quarterlyCallNotes: string | null;
      externalLinkHtml: string | null;
      externalLinkText: string | null;
    },
    thunkAPI,
  ) => {
    const { context, ...otherData } = data;
    const response = await axios.post(
      getFullUrl("/api/BusinessForecast/EnhancedForecast", {
        useDedicatedEnvironment: true,
      }),
      otherData,
      createRequestWithAuthHeaders(context),
    );
    return response?.data;
  },
);

export const shareOpportunities: any = createAsyncThunk(
  "forecast/shareOpportunities",
  async (
    data: {
      context: UserContextType;
      message: string;
      opportunityId: number | Array<number>;
      addresses: Array<string>;
    },
    thunkAPI,
  ) => {
    const { context, message, opportunityId, addresses } = data;
    return Array.isArray(opportunityId)
      ? await Promise.all(
          opportunityId.map(
            async (id) =>
              await Promise.all(
                addresses.map(async (address) => {
                  await shareOpportunityToUser({
                    context,
                    message,
                    opportunityId: id,
                    recordType: "F",
                    toAdderess: address,
                  });
                }),
              ),
          ),
        )
      : await Promise.all(
          addresses.map(async (address) => {
            await shareOpportunityToUser({
              context,
              message,
              opportunityId,
              recordType: "F",
              toAdderess: address,
            });
          }),
        );
  },
);

export const getForecastFiltersDropdownOptions: any = createAsyncThunk(
  "forecast/getForecastFiltersDropdownOptions",
  async (context: UserContextType, thunkAPI) => {
    const response = await Promise.all([
      axios.get(
        getFullUrl("/api/BusinessForecast/dropdownoptions/country", {
          useDedicatedEnvironment: true,
        }),
        createRequestWithAuthHeaders(context),
      ),
      axios.get(
        getFullUrl("/api/BusinessForecast/dropdownoptions/sector", {
          useDedicatedEnvironment: true,
        }),
        createRequestWithAuthHeaders(context),
      ),
      axios.get(
        getFullUrl("/api/BusinessForecast/dropdownoptions/awardtype", {
          useDedicatedEnvironment: true,
        }),
        createRequestWithAuthHeaders(context),
      ),
      axios.get(
        getFullUrl("/api/BusinessForecast/dropdownoptions/geographicalcode", {
          useDedicatedEnvironment: true,
        }),
        createRequestWithAuthHeaders(context),
      ),
    ]);
    return {
      contriesOffices: compact(response[0].data).map((option) => ({
        value: option, //htmlDecode(option),
        label: option, //htmlDecode(option),
      })),
      sectors: compact(response[1].data).map((option) => ({
        value: option, //htmlDecode(option),
        label: option, //htmlDecode(option),
      })),
      awardTypes: compact(response[2].data).map((option) => ({
        value: option, //htmlDecode(option),
        label: option, //htmlDecode(option),
      })),
      geographicalCodes: compact(response[3].data).map((option) => ({
        value: option, //htmlDecode(option),
        label: option, //htmlDecode(option),
      })),
    };
  },
);

export const getTableSettings: any = createAsyncThunk(
  "forecast/getTableSettings",
  async (data: { context: UserContextType; key: string }, thunkAPI) => {
    const { context, key } = data;
    const response = await axios.get(
      getFullUrl(`/api/UserSetting${buildQuery({ key })}`, {
        useDedicatedEnvironment: true,
      }),
      createRequestWithAuthHeaders(context),
    );

    return response?.data ?? [];
  },
);

export const patchTableSettings: any = createAsyncThunk(
  "forecast/patchTableSettings",
  async (
    data: {
      context: UserContextType;
      key: string;
      configuration: { [key: string]: Array<ColumnsSettingsData> };
    },
    thunkAPI,
  ) => {
    const { context, key, configuration } = data;
    const response = await axios.patch(
      getFullUrl(`/api/UserSetting/${key}`, {
        useDedicatedEnvironment: true,
      }),
      configuration,
      createRequestWithAuthHeaders(context),
    );

    return response?.data ?? [];
  },
);

export const deleteTableSettings: any = createAsyncThunk(
  "forecast/deleteTableSettings",
  async (data: { context: UserContextType; key: string }, thunkAPI) => {
    const { context, key } = data;
    return await axios.delete(
      getFullUrl(`/api/UserSetting/${key}`, {
        useDedicatedEnvironment: true,
      }),
      createRequestWithAuthHeaders(context),
    );
  },
);

const slice = createSlice({
  name: "forecast",
  initialState,
  reducers: {
    resetGetForecastsStatus(state: ForecastState) {
      state.fetchStatus = initialState.fetchStatus;
    },
    resetGetForecasts(state: ForecastState) {
      state.fetchStatus = initialState.fetchStatus;
      state.items = initialState.items;
      state.total = initialState.total;
      state.totalLiveRecords = initialState.totalLiveRecords;
      state.attributes = initialState.attributes;
      state.lastUpdatedUtc = initialState.lastUpdatedUtc;
    },
    resetSaveAdminDataStatus(state: ForecastState) {
      state.saveAdminData = initialState.saveAdminData;
    },
    updateAfterAdminEdit(
      state: ForecastState,
      action: PayloadAction<{
        businessForecastId: string;
        subPrimes: string | null;
        quarterlyCallNotes: string | null;
        externalLinkHtml: string | null;
        externalLinkText: string | null;
      }>,
    ) {
      const {
        businessForecastId,
        subPrimes,
        quarterlyCallNotes,
        externalLinkHtml,
        externalLinkText,
      } = action.payload;
      const currentState = current(state);
      state.items = currentState.items.map((r: ForecastRecord) => {
        if (r.id === businessForecastId)
          return {
            ...r,
            primeOrIncumbent: subPrimes,
            quarterlyCallNotes: quarterlyCallNotes,
            externalLinkHtml: externalLinkHtml,
            externalLinkText: externalLinkText,
          };
        else return r;
      });
    },
    resetShareOpportunitiesStatus(state: ForecastState) {
      state.shareOpportunities = initialState.shareOpportunities;
    },
    resetTableSettings(state: ForecastState) {
      state.tableSettings = initialState.tableSettings;
    },
  },
  extraReducers: {
    [getForecasts.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getForecasts.fulfilled]: (state, action: PayloadAction<any>) => {
      const { data, totalItems, lastUpdatedUtc, attributes } = action.payload;
      // state.items = transformData(data);
      state.items = data.map((item) => ({
        ...item,
        changedColumns: item.changedColumns
          ? item.changedColumns
              .split(",")
              .map((item) => item?.trim()?.toLowerCase())
          : null,
      }));
      state.total = totalItems;
      state.attributes = attributes;
      state.lastUpdatedUtc = lastUpdatedUtc;
      if (!!attributes?.TotalLiveRecords)
        state.totalLiveRecords = attributes.TotalLiveRecords;
      state.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getForecasts.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [getForecastDetails.pending]: (state, action) => {
      state.details.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getForecastDetails.fulfilled]: (state, action: PayloadAction<any>) => {
      state.details.records = action.payload?.sort(
        (r1, r2) =>
          new Date(r2.createdOn).getTime() - new Date(r1.createdOn).getTime(),
      );
      state.details.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getForecastDetails.rejected]: (state, action) => {
      state.details.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [saveAdminData.pending]: (state, action) => {
      state.saveAdminData.postFetchStatus = RequestStatus.statuses.FETCHING;
    },
    [saveAdminData.fulfilled]: (state, action: PayloadAction<any>) => {
      state.saveAdminData.postFetchStatus = RequestStatus.statuses.DONE;
    },
    [saveAdminData.rejected]: (state, action) => {
      state.saveAdminData.postFetchStatus = RequestStatus.statuses.ERROR;
    },

    [shareOpportunities.pending]: (state, action) => {
      state.shareOpportunities.postFetchStatus =
        RequestStatus.statuses.FETCHING;
    },
    [shareOpportunities.fulfilled]: (state, action: PayloadAction<any>) => {
      state.shareOpportunities.postFetchStatus = RequestStatus.statuses.DONE;
    },
    [shareOpportunities.rejected]: (state, action) => {
      state.shareOpportunities.postFetchStatus = RequestStatus.statuses.ERROR;
    },

    [getForecastFiltersDropdownOptions.pending]: (state, action) => {
      state.dropdownOptions.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getForecastFiltersDropdownOptions.fulfilled]: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions = {
        ...action.payload,
        fetchStatus: RequestStatus.statuses.DONE,
      };
    },
    [getForecastFiltersDropdownOptions.rejected]: (state, action) => {
      state.dropdownOptions.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [getTableSettings.pending]: (state, action) => {
      state.tableSettings.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getTableSettings.fulfilled]: (state, action: PayloadAction<any>) => {
      state.tableSettings.columnsOrder =
        action.payload.setting[FORECAST_COLUMNS_SETTINGS_KEY];
      state.tableSettings.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getTableSettings.rejected]: (state, action) => {
      state.tableSettings.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [patchTableSettings.pending]: (state, action) => {
      state.tableSettings.patchFetchStatus = RequestStatus.statuses.FETCHING;
    },
    [patchTableSettings.fulfilled]: (state, action: PayloadAction<any>) => {
      state.tableSettings.patchFetchStatus = RequestStatus.statuses.DONE;
    },
    [patchTableSettings.rejected]: (state, action) => {
      state.tableSettings.patchFetchStatus = RequestStatus.statuses.ERROR;
    },

    [deleteTableSettings.pending]: (state, action) => {
      state.tableSettings.deleteFetchStatus = RequestStatus.statuses.FETCHING;
    },
    [deleteTableSettings.fulfilled]: (state, action: PayloadAction<any>) => {
      state.tableSettings.deleteFetchStatus = RequestStatus.statuses.DONE;
    },
    [deleteTableSettings.rejected]: (state, action) => {
      state.tableSettings.deleteFetchStatus = RequestStatus.statuses.ERROR;
    },
  },
});

export const { reducer } = slice;

export const {
  resetGetForecastsStatus,
  resetGetForecasts,
  updateAfterAdminEdit,
  resetSaveAdminDataStatus,
  resetShareOpportunitiesStatus,
  resetTableSettings,
} = slice.actions;
export default slice;
