import axios from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../configs/axios-export.custom";
import { UserContextType } from "../services/UserContext";
import { RequestStatus } from "../utils/Helpers/fetchStatus";
import { ShareUser } from "../screens/Forecast/Forecast";

interface OrganizationState {
  organizationUsers: Array<any>;
  fetchStatus: string;
}

const initialState: OrganizationState = Object.freeze({
  organizationUsers: [],
  fetchStatus: RequestStatus.statuses.NULL,
});

export const getOrganizationUsers: any = createAsyncThunk(
  "organization/getOrganizationUsers",
  async (context: UserContextType, thunkAPI) => {
    const response = await axios.get(
      getFullUrl("/api/organization/user", { useDedicatedEnvironment: true }),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

const slice = createSlice({
  name: "organization",
  initialState,
  reducers: {},
  extraReducers: {
    [getOrganizationUsers.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getOrganizationUsers.fulfilled]: (state, action: PayloadAction<any>) => {
      state.organizationUsers = action.payload;
      state.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getOrganizationUsers.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.ERROR;
    },
  },
});

export const { reducer } = slice;

export const {} = slice.actions;
export default slice;
