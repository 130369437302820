export const validateEmailSimple = (email: string): boolean => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export const emailDomainsMatch = (email1: string, email2: string): boolean => {
  const address1 = email1.split("@").pop();
  const address2 = email2.split("@").pop();

  return !!(
    address1 &&
    address2 &&
    address1.toUpperCase() === address2.toUpperCase()
  );
};
