import React, { FC, ReactNode } from "react";
import { Chip, SxProps } from "@mui/material";

interface SeverityPillProps {
  label: string;
  color: string;
  backgroundColor: string;
  fontWeight?: string | number;
  fontSize?: string;
  height?: number;
  borderRadius?: string;
  boxShadow?: string;
  icon?: JSX.Element;
  margin?: string;
  sx?: SxProps;
  onClick?: () => void;
}

const SeverityPill: FC<SeverityPillProps> = (props) => {
  const {
    label,
    color,
    backgroundColor,
    fontWeight = 600,
    fontSize = "0.8125rem",
    height = 32,
    borderRadius = null,
    boxShadow = null,
    icon = null,
    margin = null,
    sx = {},
    onClick = null,
  } = props;

  return (
    <Chip
      label={label}
      icon={icon ? icon : <></>}
      {...(onClick && { onClick })}
      sx={{
        color,
        backgroundColor,
        fontWeight,
        fontSize,
        height,
        ...(borderRadius && { borderRadius }),
        ...(boxShadow && { boxShadow }),
        ...(margin && { margin }),
        ...(onClick && { cursor: "pointer" }),
        ...sx,
      }}
    />
  );
};

export default SeverityPill;
