import {
  DataColumnConfigProps,
  StickyColumnConfigProps,
} from "../../components/MaterialTable/Table";
import { format } from "date-fns";
import { currencyFormatterNoDecimals } from "../Projects/projectsTableColumns.config";
import { compact } from "lodash";
import { getNewDateNoTimezoneAdjustment } from "../../utils/conversion/date-converters";

export const getContractsStickyColumnsConfig = (
  canShare: boolean,
  onlyLink: boolean = false,
): Array<StickyColumnConfigProps> => [
  {
    propertyName: "actions",
    label: "Actions",
    width: onlyLink ? 80 : canShare ? 210 : 170,
    isSticky: true,
    cellAlign: "center",
    disableModalWithDetails: true,
  },
];

export const getContractsColumnsConfig = (
  getSortableColumnPropertyName: (_propertyName: string) => string,
  isDetails: boolean = false,
): Array<DataColumnConfigProps> =>
  compact([
    {
      propertyName: "agencyName",
      label: "Department / Agency",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 250,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "title",
      label: "Title",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 300,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    ...(!isDetails
      ? [null]
      : [
          {
            propertyName: "version",
            label: "Version",
            order: 0,
            isVisible: true,
            sortable: true,
            width: 150,
            getSortableColumnPropertyName: getSortableColumnPropertyName,
            disableModalWithDetails: true,
            cellAlign: "center",
            headerAlign: "center",
          },
          {
            propertyName: "modifiedDateIn",
            label: "Modified Date",
            order: 0,
            isVisible: true,
            sortable: true,
            width: 150,
            cellFormatter: (value) =>
              new Date(value) && new Date(value).toISOString().startsWith("0")
                ? "N/A"
                : value
                ? format(getNewDateNoTimezoneAdjustment(value), "dd MMMM yyyy")
                : null,
            getSortableColumnPropertyName: getSortableColumnPropertyName,
          },
        ]),
    {
      propertyName: "country",
      label: "Country / Office",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 200,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "description",
      label: "Description",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 300,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "type",
      label: "Contract Opportunity Type",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 250,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "status",
      label: "Status",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 200,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "contractorAwardedName",
      label: "Contractor Awarded Name",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 200,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "contractorAwardedDate",
      label: "Awarded Date",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 150,
      cellFormatter: (value) =>
        new Date(value) && new Date(value).toISOString().startsWith("0")
          ? "N/A"
          : value
          ? format(getNewDateNoTimezoneAdjustment(value), "dd MMMM yyyy")
          : null,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "awardNumber",
      label: "Contract Award Number",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 200,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "contractorAwardedAmount",
      label: "Awarded Amount",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 250,
      cellFormatter: (value) =>
        value !== null
          ? currencyFormatterNoDecimals.format(Math.round(value as number))
          : "",
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "solicitationNumber",
      label: "Solicitation Number",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 250,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "postedDateIn",
      label: "Original Publish Date",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 150,
      cellFormatter: (value) =>
        new Date(value) && new Date(value).toISOString().startsWith("0")
          ? "N/A"
          : value
          ? format(getNewDateNoTimezoneAdjustment(value), "dd MMMM yyyy")
          : null,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "solicitationDeadlineResponseIn",
      label: "Offer Due Date",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 150,
      cellFormatter: (value) =>
        new Date(value) && new Date(value).toISOString().startsWith("0")
          ? "N/A"
          : value
          ? format(getNewDateNoTimezoneAdjustment(value), "dd MMMM yyyy")
          : null,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "modifiedDateIn",
      label: "Last Updated Date",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 150,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
      cellFormatter: (value) =>
        new Date(value) && new Date(value).toISOString().startsWith("0")
          ? "N/A"
          : value
          ? format(getNewDateNoTimezoneAdjustment(value), "dd MMMM yyyy")
          : null,
      disableModalWithDetails: true,
    },
    {
      propertyName: "setAside",
      label: "Set Aside",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 250,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
      disableModalWithDetails: true,
    },
    {
      propertyName: "classificationCode",
      label: "Product Service Code",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 150,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
      disableModalWithDetails: true,
    },
    {
      propertyName: "naicsCodes",
      label: "NAICS",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 100,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "placeOfPerformance",
      label: "Place Of Performance",
      order: 0,
      isVisible: true,
      sortable: true,
      width: 250,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
    },
    {
      propertyName: "contactInfo",
      label: "Contact Info",
      order: 0,
      isVisible: true,
      // sortable: true,
      width: 350,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
      maxNumberOfLines: 3,
      // cellAlign: "center",
      // headerAlign: "center",
    },
    {
      propertyName: "secondaryContactInfo",
      label: "Secondary Contact Info",
      order: 0,
      isVisible: true,
      // sortable: true,
      width: 350,
      getSortableColumnPropertyName: getSortableColumnPropertyName,
      maxNumberOfLines: 3,
      // cellAlign: "center",
      // headerAlign: "center",
    },
  ] as (DataColumnConfigProps | null)[]) as DataColumnConfigProps[];
