import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
} from "react";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import TopBar from "./TopBar";
import {
  getSearchParamValue,
  setUrlParams,
} from "../../utils/Helpers/extractDataFromSearchParams";
import { useHistory } from "react-router-dom";
import { DynamicPageTitleContext } from "../../services/dynamicPageTitleContext";
import { businessForecastRecordType } from "../../utils/types/Forecast";
import Notes from "./Notes";
import {
  getDateDisplay,
  getInfoPanelContent,
  getTabButtons,
  getTopBarData,
  SourceType,
  Tab,
} from "./opportunityDetailsUtils";
import InfoRow from "./InfoRow";
import { ClipboardIcon } from "../../assets/icons/material-icons/clipboard";
import { AwardIcon } from "../../assets/icons/material-icons/award";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import { currencyFormatterNoDecimals } from "../../screens/Projects/projectsTableColumns.config";
import { FolderInfoIcon } from "../../assets/icons/material-icons/folder-info";
import PortraitOutlinedIcon from "@mui/icons-material/PortraitOutlined";
import Stack from "@mui/material/Stack";
import DocumentsList from "./DocumentsList";
import {
  getPipelines,
  resetPipelinesState,
  updatePipelines,
} from "../../slices/pipelines";
import { RootState, useDispatch, useSelector } from "../../store";
import UserContext from "../../services/UserContext";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import toast from "react-hot-toast";
import { getStatusPill } from "../../screens/ForecastV2/forecastUtils";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

interface OpportunityDetailsProps {
  source: SourceType;
  data: any;
  loading: boolean;
  files?: any[];
  addToPipelinesOpen: boolean;
  setAddToPipelinesOpen: Dispatch<SetStateAction<boolean>>;
  handleOpenAddToPipelines: (item: any) => void;
  handleCloseAddToPipelines: () => void;
  pipelinesFetchStatus: string;
  explicitIdToUse?: string | number | null;
  shareOpportunitiesFetchStatus: string;
  onShareSubmit?: (values: any) => void;
  handleResetShareOpportunitiesStatus?: () => void;
}

const OpportunityDetails: FC<OpportunityDetailsProps> = (
  props,
): JSX.Element => {
  const {
    source,
    data,
    loading,
    files = [],
    handleOpenAddToPipelines,
    handleCloseAddToPipelines,
    addToPipelinesOpen,
    setAddToPipelinesOpen,
    pipelinesFetchStatus,
    explicitIdToUse = null,
    shareOpportunitiesFetchStatus,
    onShareSubmit = undefined,
    handleResetShareOpportunitiesStatus = undefined,
  } = props;
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const context = useContext(UserContext);
  const { setPageTitle, setPageTitleLoading, pageTitleLoading, setTitleBadge } =
    useContext(DynamicPageTitleContext);
  const { rawItems, fetchStatus } = useSelector(
    (state: RootState) => state.pipelines,
  );

  const adjustPipelinesData = async (): Promise<void> => {
    dispatch(
      updatePipelines({
        pipelines: rawItems,
      }),
    );
  };

  const selectedTab: Tab = useMemo(
    () =>
      getSearchParamValue<Tab>(
        history,
        "tab",
        source === "forecast"
          ? "primes"
          : source === "contracts"
          ? "award-details"
          : "eligibility",
      ),
    [history.location.search],
  );
  const topBarData = useMemo(
    () => getTopBarData(theme, data, source),
    [theme, source, data],
  );
  const infoPanelContent = useMemo(
    () => getInfoPanelContent(theme, data, source),
    [theme, source, data],
  );
  const handleChangeTab = (tab: Tab): void => {
    if (selectedTab !== tab) {
      // const params = new URLSearchParams(history.location.search);
      // params.set("tab", tab);
      // history.replace(`${history.location.pathname}?${params.toString()}`);
      setUrlParams(history, { tab }, { replace: true });
      // setSelectedTab(tab);
    }
  };

  useEffect(() => {
    if (!data) {
      !pageTitleLoading && setPageTitleLoading(true);
    } else {
      setPageTitleLoading(false);
      setPageTitle(
        data?.awardTitle ??
          data?.title ??
          data?.opportunityTitle ??
          `Opportunity details`,
      );
      if (source === "forecast") {
        setTitleBadge(getStatusPill(data, theme, "8px", 28));
      }
    }

    return () => {
      setPageTitle(null);
      setPageTitleLoading(false);
      setTitleBadge(null);
    };
  }, [data]);

  useEffect(() => {
    dispatch(getPipelines(context));
  }, []);

  useEffect(() => {
    //it differs from regular fetch status value assignment since this requires updating the pipelines with additional data
    if (RequestStatus.isHalfDone(fetchStatus)) {
      adjustPipelinesData();
    } else if (RequestStatus.isError(fetchStatus)) {
      toast.error(
        "Couldn't load pipelines details for Grants. Try again later.",
      );
      dispatch(resetPipelinesState());
    }
  }, [fetchStatus]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        pb: 2,
        width: "calc(100% - 32px)",
      }}
      id={"opportunity-page-box"}
    >
      {loading ? (
        <Stack
          direction={"column"}
          sx={{ height: "100%" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Box sx={{ pb: 3 }} id={"opportunity-page-content"}>
          <TopBar
            pipelinesNumber={data?.numberIncludedPipelines ?? 0}
            urlDisabled={
              source === "forecast"
                ? data?.status === businessForecastRecordType.FinalRecord ??
                  "Removed"
                : !data?.id
            }
            data={topBarData}
            source={source}
            id={explicitIdToUse ?? data?.id}
            addToPipelinesOpen={addToPipelinesOpen}
            setAddToPipelinesOpen={setAddToPipelinesOpen}
            handleCloseAddToPipelines={handleCloseAddToPipelines}
            handleOpenAddToPipelines={handleOpenAddToPipelines}
            fetchStatus={pipelinesFetchStatus}
            item={data}
            shareOpportunitiesFetchStatus={shareOpportunitiesFetchStatus}
            onShareSubmit={onShareSubmit}
            handleResetShareOpportunitiesStatus={
              handleResetShareOpportunitiesStatus
            }
          />
          <Grid container spacing={"24px"} sx={{ mt: 0 }}>
            <Grid item lg={4} md={5} xs={12} spacing={"24px"}>
              {/*<InfoPanel data={data} />*/}
              <Card
                variant={"outlined"}
                sx={{
                  borderRadius: "8px",
                  px: 2,
                }}
              >
                {infoPanelContent}
              </Card>
            </Grid>
            <Grid
              item
              container
              lg={8}
              md={7}
              xs={12}
              spacing={"24px"}
              sx={{
                height: "fit-content",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  variant={"h6"}
                  color={"textPrimary"}
                  align={"left"}
                  fontWeight={"600"}
                >
                  {source === "forecast" ? "Opportunity " : ""}Description
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant={"subtitle2"}
                  color={"textPrimary"}
                  align={"left"}
                  dangerouslySetInnerHTML={{
                    __html:
                      data?.awardDescription ?? data?.description ?? "N/A",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Card
                  variant={"outlined"}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "8px",
                    p: 2,
                    borderRadius: "8px",
                    "& > button": {
                      borderRadius: "8px",
                    },
                  }}
                >
                  {getTabButtons(source, selectedTab, handleChangeTab)}
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card
                  variant={"outlined"}
                  sx={{
                    borderRadius: "8px",
                    p: 2,
                  }}
                >
                  {[
                    // "primes",
                    "q&a",
                    "bid",
                  ].includes(selectedTab) ? (
                    <Box
                      dangerouslySetInnerHTML={{
                        __html:
                          selectedTab === "q&a"
                            ? data?.quarterlyCallNotes ??
                              "Data not yet available"
                            : data?.externalLinkHtml ??
                              "Data not yet available",
                      }}
                      sx={{
                        textAlign: "left",
                      }}
                    />
                  ) : selectedTab === "notes" ? (
                    <Notes />
                  ) : source === "forecast" &&
                    (typeof data?.primeOrIncumbent === "string" ||
                      data?.primeOrIncumbent === null) ? (
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: data?.primeOrIncumbent?.length
                          ? data?.primeOrIncumbent
                          : "Data not yet available",
                      }}
                      sx={{
                        textAlign: "left",
                      }}
                    />
                  ) : selectedTab === "award-details" ? (
                    <Grid container columnSpacing={2} sx={{ px: 4 }}>
                      <Grid
                        item
                        lg={12}
                        lgEx={6}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <InfoRow
                          label={"Contract Award Number"}
                          icon={<ClipboardIcon />}
                          value={data?.awardNumber ?? "N/A"}
                        />
                        <InfoRow
                          label={"Contractor Awarded Name"}
                          icon={<AwardIcon />}
                          value={data?.contractorAwardedName ?? "N/A"}
                        />
                        <InfoRow
                          label={"Awarded Amount"}
                          icon={<RequestQuoteOutlinedIcon />}
                          value={
                            data?.contractorAwardedAmount !== null
                              ? currencyFormatterNoDecimals.format(
                                  Math.round(
                                    data?.contractorAwardedAmount as number,
                                  ),
                                )
                              : "N/A"
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        lgEx={6}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <InfoRow
                          label={"Contract Opportunity Type"}
                          icon={<FolderInfoIcon />}
                          value={data?.type ?? "N/A"}
                        />
                        <InfoRow
                          label={"Awarded Date"}
                          icon={<AwardIcon />}
                          value={getDateDisplay(
                            data?.contractorAwardedDate,
                            true,
                            "dd MMMM yyyy",
                          )}
                        />
                      </Grid>
                    </Grid>
                  ) : selectedTab === "contact-details" ? (
                    source === "contracts" ? (
                      <Grid container columnSpacing={2} sx={{ px: 4 }}>
                        <Grid
                          item
                          md={12}
                          lg={6}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {/*  pointOfContactOne: {*/}
                          {/*  fullName: "Samsak Sao",*/}
                          {/*  email: "ssao@usaid.gov",*/}
                          {/*  phone: "85523728334",*/}
                          {/*  fax: "",*/}
                          {/*},*/}
                          <InfoRow
                            label={"Primary"}
                            icon={<PortraitOutlinedIcon />}
                            value={data?.pointOfContactOne?.fullName ?? "N/A"}
                            secondValue={
                              data?.pointOfContactOne?.email ?? "N/A"
                            }
                            secondValueProps={{
                              ...(data?.pointOfContactOne?.email && {
                                component: "a",
                                href: `mailto:${data?.pointOfContactOne?.email}`,
                              }),
                              display: "block",
                              fontWeight: "400",
                              color: "primary",
                              sx: {
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              },
                            }}
                            extraValues={[
                              ...(data?.pointOfContactOne?.fax
                                ? [`Fax: ${data?.pointOfContactOne?.fax}`]
                                : []),
                              ...(data?.pointOfContactOne?.phone
                                ? [`Phone: ${data?.pointOfContactOne?.phone}`]
                                : []),
                            ]}
                            extraValuesProps={{
                              fontWeight: 400,
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          md={12}
                          lg={6}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <InfoRow
                            label={"Secondary"}
                            icon={<PortraitOutlinedIcon />}
                            value={data?.pointOfContactTwo?.fullName ?? "N/A"}
                            secondValue={
                              data?.pointOfContactTwo?.email ?? "N/A"
                            }
                            secondValueProps={{
                              ...(data?.pointOfContactTwo?.email && {
                                component: "a",
                                href: `mailto:${data?.pointOfContactTwo?.email}`,
                              }),
                              display: "block",
                              fontWeight: "400",
                              color: "primary",
                              sx: {
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              },
                            }}
                            extraValues={[
                              ...(data?.pointOfContactTwo?.fax
                                ? [`Fax: ${data?.pointOfContactTwo?.fax}`]
                                : []),
                              ...(data?.pointOfContactTwo?.phone
                                ? [`Phone: ${data?.pointOfContactTwo?.phone}`]
                                : []),
                            ]}
                            extraValuesProps={{
                              fontWeight: 400,
                            }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <InfoRow
                          label={"Contact"}
                          icon={<PortraitOutlinedIcon />}
                          value={data?.agencyContactName ?? "N/A"}
                          secondValue={data?.agencyContactEmail ?? "N/A"}
                          secondValueProps={{
                            ...(data?.agencyContactEmail && {
                              component: "a",
                              href: `mailto:${data?.agencyContactEmail}`,
                            }),
                            display: "block",
                            fontWeight: "400",
                            color: "primary",
                            sx: {
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            },
                          }}
                        />
                        {data?.agencyPhone && (
                          <InfoRow
                            label={"Phone"}
                            icon={<LocalPhoneIcon />}
                            value={data?.agencyPhone ?? "N/A"}
                          />
                        )}
                      </>
                    )
                  ) : selectedTab === "eligibility" ? (
                    <Box
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        sx={{ mb: 1 }}
                        variant={"subtitle2"}
                        fontWeight={"600"}
                        color={"textPrimary"}
                      >
                        Eligible Applicants
                      </Typography>
                      <Typography
                        sx={{ mb: 3 }}
                        variant={"subtitle2"}
                        color={"textPrimary"}
                      >
                        {Array.isArray(data?.applicantTypeDescription) &&
                        data?.applicantTypeDescription?.length > 0
                          ? data?.applicantTypeDescription
                              .map((type) => type.description)
                              .join(". ")
                          : "N/A"}
                      </Typography>
                      <Typography
                        sx={{ mb: 1 }}
                        variant={"subtitle2"}
                        fontWeight={"600"}
                        color={"textPrimary"}
                      >
                        Additional Information on Eligibility
                      </Typography>
                      <Typography
                        sx={{ mb: 3 }}
                        variant={"subtitle2"}
                        color={"textPrimary"}
                        dangerouslySetInnerHTML={{
                          __html:
                            data?.applicantEligibilityDescription ?? "N/A",
                        }}
                      />
                    </Box>
                  ) : selectedTab === "related-documents" ? (
                    <DocumentsList
                      files={files}
                      id={
                        source === "forecast"
                          ? ""
                          : source === "contracts"
                          ? data.contractOpportunityBase_Id
                          : data.id
                      }
                      source={source}
                    />
                  ) : (
                    <></>
                  )}
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default OpportunityDetails;
