import axios from "axios";
import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../configs/axios-export.custom";
import { UserContextType } from "../services/UserContext";
import { RequestStatus } from "../utils/Helpers/fetchStatus";
import { buildQuery } from "../utils/Helpers/queryBuilder";
import { DropdownOption } from "../components/Widgets/Inputs/Input";

export interface ReportRecord {
  rowId: number;
  country: string;
  title: string;
  documentType: string;
  abstract: string;
  publicationDate: string;
  authoringOrganization: string;
  personalAuthors: string;
  technicalSectors: string;
  uniqueId: string;
  contractGrantNumber: string;
  contractGrantNumberEvaluated: string;
  fileSyncStatus: string;
}

interface ReportsState {
  items: ReportRecord[];
  fetchStatus: string;
  total: number;
  lastUpdatedUtc: string | null;
  attributes: Record<string, unknown>;
  dropdownOptions: {
    country: {
      items: { value: string; label: string }[];
      fetchStatus: string;
    };
    technicalSector: {
      items: { value: string; label: string }[];
      fetchStatus: string;
    };
    authoringOrg: {
      items: { value: string; label: string }[];
      fetchStatus: string;
    };
  };
}

export interface ReportsFiltersProps {
  pageIndex: number;
  pageSize: number;
  sortField?: string;
  sortOrder?: number;
  onlyAttachments?: boolean;
  country?: string[] | DropdownOption[];
  sector?: string[] | DropdownOption[];
  authoringOrg?: string[] | DropdownOption[];
  filter?: string;
  fileKeyword?: string;
  documentId?: string;
  startDate?: Date | string | null;
  endDate?: Date | string | null;
}

const initialState: ReportsState = Object.freeze({
  items: [],
  fetchStatus: RequestStatus.statuses.NULL,
  total: 0,
  lastUpdatedUtc: null,
  attributes: {},
  dropdownOptions: {
    country: {
      items: [],
      fetchStatus: RequestStatus.statuses.NULL,
    },
    technicalSector: {
      items: [],
      fetchStatus: RequestStatus.statuses.NULL,
    },
    authoringOrg: {
      items: [],
      fetchStatus: RequestStatus.statuses.NULL,
    },
    fetchStatus: RequestStatus.statuses.NULL,
  },
});

export const getReports: any = createAsyncThunk(
  "reports/getReports",
  async (
    data: {
      context: UserContextType;
      params: ReportsFiltersProps;
    },
    thunkAPI,
  ) => {
    const { context, params } = data;
    const response = await axios.get(
      getFullUrl(`/api/Dec${buildQuery({ ...params }, "|")}`, {
        useDedicatedEnvironment: true,
      }),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

export const getDropdownOptionsForReports: any = createAsyncThunk(
  "reports/getDropdownOptionsForReports",
  async (
    data: {
      context: UserContextType;
      target?: ("country" | "technicalSector" | "authoringOrg")[];
      filter?: string;
    },
    thunkAPI,
  ) => {
    const {
      context,
      target = ["country", "technicalSector", "authoringOrg"],
      filter = "",
    } = data;
    const responses = await Promise.all([
      ...(target.includes("country")
        ? [
            axios.get(
              getFullUrl(
                `/api/dec/dropdownoptions/country${buildQuery({ filter })}`,
                {
                  useDedicatedEnvironment: true,
                },
              ),
              createAuthenticatedRequest(context),
            ),
          ]
        : []),
      ...(target.includes("technicalSector")
        ? [
            axios.get(
              getFullUrl(
                `/api/dec/dropdownoptions/technicalSector${buildQuery({
                  filter,
                })}`,
                {
                  useDedicatedEnvironment: true,
                },
              ),
              createAuthenticatedRequest(context),
            ),
          ]
        : []),
      ...(target.includes("authoringOrg")
        ? [
            axios.get(
              getFullUrl(
                `/api/dec/dropdownoptions/authoringOrg${buildQuery({
                  filter,
                })}`,
                {
                  useDedicatedEnvironment: true,
                },
              ),
              createAuthenticatedRequest(context),
            ),
          ]
        : []),
    ]);

    return Object.fromEntries(
      target.map((target, idx) => [[target], responses[idx].data ?? []]),
    );
  },
);

const slice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    resetReportsItems(state: ReportsState) {
      state.items = initialState.items;
      state.total = initialState.total;
      state.fetchStatus = initialState.fetchStatus;
    },
  },
  extraReducers: {
    [getReports.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getReports.fulfilled]: (state, action: PayloadAction<any>) => {
      const { attributes, data, lastUpdatedUtc, totalItems } = action.payload;
      state.items = data;
      state.total = totalItems;
      state.lastUpdatedUtc = lastUpdatedUtc;
      state.attributes = attributes;
      state.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getReports.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [getDropdownOptionsForReports.pending]: (state, action) => {
      if (action.meta.arg.target.includes("country"))
        state.dropdownOptions.country.fetchStatus =
          RequestStatus.statuses.FETCHING;
      if (action.meta.arg.target.includes("technicalSector"))
        state.dropdownOptions.technicalSector.fetchStatus =
          RequestStatus.statuses.FETCHING;
      if (action.meta.arg.target.includes("authoringOrg"))
        state.dropdownOptions.authoringOrg.fetchStatus =
          RequestStatus.statuses.FETCHING;
    },
    [getDropdownOptionsForReports.fulfilled]: (
      state,
      action: PayloadAction<any>,
    ) => {
      const currentState = current(state);
      state.dropdownOptions = {
        country: {
          items: action.payload?.country
            ? action.payload?.country.map((v) => ({ value: v, label: v }))
            : currentState.dropdownOptions.country.items,
          fetchStatus: RequestStatus.statuses.DONE,
        },
        technicalSector: {
          items: action.payload?.technicalSector
            ? action.payload?.technicalSector.map((v) => ({
                value: v,
                label: v,
              }))
            : currentState.dropdownOptions.technicalSector.items,
          fetchStatus: RequestStatus.statuses.DONE,
        },
        authoringOrg: {
          items: action.payload?.authoringOrg
            ? action.payload?.authoringOrg.map((v: string) => ({
                value: v,
                label: v,
              }))
            : currentState.dropdownOptions.authoringOrg.items,
          fetchStatus: RequestStatus.statuses.DONE,
        },
      };
    },
    [getDropdownOptionsForReports.rejected]: (state, action) => {
      state.dropdownOptions.country.fetchStatus = RequestStatus.statuses.ERROR;
      state.dropdownOptions.technicalSector.fetchStatus =
        RequestStatus.statuses.ERROR;
      state.dropdownOptions.authoringOrg.fetchStatus =
        RequestStatus.statuses.ERROR;
    },
  },
});

export const { reducer } = slice;

export const { resetReportsItems } = slice.actions;
export default slice;
