import React, { Dispatch, FC, SetStateAction } from "react";
import styled from "@emotion/styled";
import { IDIQModalProps, IDIQItemType } from "./IDIQTable";
import { DateFormat, formatDate } from "../../utils/conversion/date-converters";

interface CellProps {
  item: IDIQItemType;
  contentParameterName: string;
  setModal?: Dispatch<SetStateAction<IDIQModalProps>>;
  columnTitle?: string;
  textLength?: number;
  isDate?: boolean;
}

const TableCell = styled.div<any>`
  padding: 8px;
  ${({ isClickable }) => isClickable && "cursor: pointer;"}
`;

const IDIQTableCell: FC<CellProps> = (props) => {
  const {
    item,
    contentParameterName,
    setModal = null,
    columnTitle = null,
    textLength = null,
    isDate = false,
  } = props;

  const getValue = (): string => {
    const value = item[contentParameterName];
    return !!value
      ? !!textLength && value?.length > textLength
        ? isDate
          ? `${formatDate(value, DateFormat.dd_MMMM_yyyy)?.substring(
              0,
              textLength,
            )}...`
          : `${value?.substring(0, textLength)}...`
        : isDate
        ? formatDate(value, DateFormat.dd_MMMM_yyyy)
        : value
      : "";
  };

  return (
    <TableCell
      isClickable={!!item[contentParameterName] && !!setModal}
      onClick={() =>
        !!item[contentParameterName] &&
        setModal?.((prev) => ({
          ...prev,
          title: columnTitle ?? "",
          content: item[contentParameterName] ?? "",
          isOpen: true,
        }))
      }
    >
      {getValue()}
    </TableCell>
  );
};

export default IDIQTableCell;
