import {
  DataColumnConfigProps,
  StickyColumnConfigProps,
} from "../../components/MaterialTable/Table";
import { format } from "date-fns";

export const getECDStickyColumnsConfig = (): Array<StickyColumnConfigProps> => [
  {
    propertyName: "documents",
    label: "Documents",
    isSticky: true,
    width: 100,
    disableModalWithDetails: true,
  },
  {
    propertyName: "countries",
    label: "Country/Operating Unit",
    isSticky: true,
    sortable: true,
    width: 175,
  },
  {
    propertyName: "originatingBureauSubregionOffice",
    label: "Bureau",
    isSticky: true,
    sortable: true,
    width: 200,
  },
];

export const getECDColumnsConfig = (
  getSortableColumnPropertyName: (_propertyName: string) => string,
): Array<DataColumnConfigProps> => [
  {
    propertyName: "projectDateEnvironmentalDeterminationSignatureFyParsed",
    label: "FY Approved",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 200,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "sectorTypes",
    label: "Sector Types",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 200,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "documentTitle",
    label: "Document Title",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 200,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "documentType",
    label: "Document Type",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 200,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "fileNumber",
    label: "File Number",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 200,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "activityDateRange",
    label: "Activity Date Range",
    order: 0,
    isVisible: true,
    // sortable: true,
    width: 300,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "projectDateExpirationDateParsed",
    label: "Expiration Date",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 200,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    cellFormatter: (value) =>
      new Date(value) && new Date(value).toISOString().startsWith("0")
        ? "N/A"
        : format(new Date(value), "dd MMMM yyyy"),
    disableModalWithDetails: true,
  },
  {
    propertyName: "projectDateEnvironmentalDeterminationSignatureDateParsed",
    label: "ETD Signature Date",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 200,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    cellFormatter: (value) =>
      new Date(value) && new Date(value).toISOString().startsWith("0")
        ? "N/A"
        : format(new Date(value), "dd MMMM yyyy"),
    disableModalWithDetails: true,
  },
  {
    propertyName: "thresholdDetermination",
    label: "Threshold Determination",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 225,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    disableModalWithDetails: true,
  },
  {
    propertyName: "climateRiskManagementAssessment",
    label: "Climate Risk Management Assessment",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 225,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    disableModalWithDetails: true,
  },
  {
    propertyName: "specificConditions",
    label: "Specific Conditions",
    order: 0,
    isVisible: true,
    sortable: true,
    width: 250,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "adminActions",
    label: "Admin Actions",
    order: 0,
    isVisible: true,
    disableModalWithDetails: true,
    cellAlign: "center",
    headerAlign: "center",
    hiddenBasedOnRole: true,
    width: 80,
  },
];
