import React, {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ForecastRecord, PipelineDto } from "../../utils/types/Forecast";
import { BpCheckedIcon, BpIcon } from "../../theme/CustomCheckboxIcons";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { RootState, useDispatch, useSelector } from "../../store";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import { compact, debounce, difference, intersection, get } from "lodash";
import Button from "@mui/material/Button";
import { addOrRemoveFromPipelines } from "../../slices/pipelines";
import UserContext from "../../services/UserContext";

interface AddToPipelinesTooltipProps {
  handleCloseAddToPipelines: () => void;
  item: any;
  source: "forecast" | "contracts" | "grants";
  onChangePipelines?: (
    addedToPipelinesIds: number[],
    removedFromPipelinesIds: number[],
    currentPipelinesIds: number[],
    item: any,
  ) => void;
}

const AddToPipelinesTooltip: FC<AddToPipelinesTooltipProps> = (props) => {
  const { handleCloseAddToPipelines, item, source, onChangePipelines } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const context = useContext(UserContext);

  const mainPath =
    source === "forecast"
      ? "businessForcast"
      : source === "contracts"
      ? "contracts"
      : "grants";

  const pathToId =
    source === "forecast"
      ? ["forecast", "id"]
      : source === "contracts"
      ? ["id"]
      : ["id"];

  const sourceTable =
    source === "forecast"
      ? "forecast"
      : source === "contracts"
      ? "contract"
      : "grant";

  const {
    items: pipelines,
    addOrRemoveFromPipelines: { postFetchStatus: addToPipelinesFetchStatus },
  } = useSelector((state: RootState) => state.pipelines);

  const {
    items: pipelinesForRecord,
    fetchStatus: pipelinesForRecordFetchStatus,
    itemsFor: pipelinesForRecordFor,
  } = useSelector((state: RootState) => state.pipelines.pipelinesForRecord);

  const loading = RequestStatus.isFetching(addToPipelinesFetchStatus);

  const [selectedPipelines, setSelectedPipelines] = useState<Array<number>>(
    pipelines.length > 0 && pipelinesForRecord.length > 0
      ? compact(
          pipelines.map((pipeline) =>
            pipelinesForRecord.find((_item) => _item.pipelineId === pipeline.id)
              ? pipeline.id
              : null,
          ),
        )
      : [],
  );

  const [filteredPipelines, setFilteredPipelines] =
    useState<Array<PipelineDto>>(pipelines);
  const [searchInputValue, setSearchInputValue] = useState<string>("");

  const onSearchChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearchInputValue(e.target.value);
    debouncedCountryInputChange(e.target.value);
  };

  const searchCallback = (value) => {
    setFilteredPipelines(
      pipelines.filter((pipeline) => pipeline.name.includes(value)),
    );
  };

  const debouncedCountryInputChange = useCallback(
    debounce(searchCallback, 250),
    [],
  );

  const handleOnChange = (id: number, checked: boolean): void => {
    if (selectedPipelines.includes(id))
      setSelectedPipelines((prev) => prev.filter((_id) => _id !== id));
    else setSelectedPipelines((prev) => [...prev, id]);
  };

  const handleClearInput = (): void => {
    onSearchChange({ target: { value: "" } } as ChangeEvent<HTMLInputElement>);
  };

  const handleSavePipelines = (): void => {
    const currentUsersPipelines = compact(
      pipelines.map((pipeline) =>
        pipelinesForRecord.find((_item) => _item.pipelineId === pipeline.id)
          ? pipeline.id
          : null,
      ),
    );
    const removedFromPipelines = difference(
      currentUsersPipelines,
      selectedPipelines,
    );
    const addedToPipelines = difference(
      selectedPipelines,
      currentUsersPipelines,
    );

    onChangePipelines &&
      onChangePipelines(
        addedToPipelines,
        removedFromPipelines,
        currentUsersPipelines,
        item,
      );

    dispatch(
      addOrRemoveFromPipelines({
        context,
        sourceTable,
        forecastPipelineIdsToAdd: addedToPipelines.map((pipelineId) => ({
          forecastId: item.id,
          pipelineId: pipelineId,
        })),
        forecastPipelineIdsToRemove: removedFromPipelines.map((pipelineId) => ({
          forecastId: item.id,
          pipelineId: pipelineId,
        })),
      }),
    );
  };

  useEffect(() => {
    setSelectedPipelines(
      pipelines.length > 0 && pipelinesForRecord.length > 0
        ? compact(
            pipelines.map((pipeline) =>
              pipelinesForRecord.find(
                (_item) => _item.pipelineId === pipeline.id,
              )
                ? pipeline.id
                : null,
            ),
          )
        : [],
    );
  }, [pipelines]);

  return (
    <ClickAwayListener onClickAway={handleCloseAddToPipelines}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box sx={{ height: "4px", m: "-4px -8px 0" }}>
          {loading && (
            <LinearProgress
              sx={{
                borderRadius: "8px 8px 0 0",
              }}
            />
          )}
        </Box>
        <Typography
          variant={"h5"}
          sx={{
            color: theme.palette.primary.contrastText,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Add to pipeline(s)
          <IconButton onClick={handleCloseAddToPipelines}>
            <ClearIcon
              sx={{
                color: theme.palette.primary.contrastText,
                "&:hover": {
                  color: theme.palette.text.secondary,
                },
              }}
            />
          </IconButton>
        </Typography>
        <TextField
          size={"small"}
          onChange={onSearchChange}
          value={searchInputValue}
          placeholder={`${selectedPipelines.length} Pipeline${
            selectedPipelines.length === 1 ? "" : "s"
          } Selected`}
          InputProps={{
            sx: {
              backgroundColor: theme.palette.background.paper,
              // borderRadius: "20px",
              px: 0.5,
            },
            startAdornment: (
              <SearchIcon sx={{ color: theme.palette.text.secondary }} />
            ),
            endAdornment: searchInputValue.length > 0 && (
              <IconButton onClick={handleClearInput}>
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
        {pipelines.length > filteredPipelines.length && (
          <Typography
            variant={"subtitle2"}
            sx={{ color: theme.palette.primary.contrastText }}
          >
            Currently showing {filteredPipelines?.length} out of{" "}
            {pipelines?.length} pipelines.
          </Typography>
        )}
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "150px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {filteredPipelines.length === 0 ? (
            <Typography
              variant={"subtitle2"}
              sx={{ color: theme.palette.primary.contrastText }}
            >
              No pipelines found
            </Typography>
          ) : (
            filteredPipelines.map((pipeline) => (
              <FormControlLabel
                key={pipeline?.id}
                control={
                  <Checkbox
                    checked={selectedPipelines.includes(pipeline?.id)}
                    disabled={loading}
                    sx={{
                      p: 0.5,
                      m: 0,
                      mx: 0.5,
                      ml: 1,
                    }}
                    onChange={(event, checked) =>
                      handleOnChange(pipeline.id, checked)
                    }
                  />
                }
                label={pipeline.name}
                slotProps={{
                  typography: {
                    variant: "subtitle2",
                  },
                }}
              />
            ))
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", my: 1 }}>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={handleSavePipelines}
          >
            Save
          </Button>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default AddToPipelinesTooltip;
