import React, { useEffect, useState } from "react";
import { rowsPerPageOptionsStandard } from "../../../utils/constants/gridsOptions";
import { GetCustomColumnBody } from "../../../components/DataTable/Columns";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Button as PButton } from "primereact/components/button/Button";
import {
  MaximizeGrid,
  MinimizeGrid,
} from "../../../components/DataTable/ActionButtons";
import { useGridStyles } from "../../../hooks/useGridStyles";
import { Column } from "primereact/column";

export interface ProjectTableRecord {
  id: string;
  notes: string;
  status: string;
  country: string;
  name: string;
  description: string;
}

const ProjectInfoTable: React.FC<{
  tableData: ProjectTableRecord[] | undefined;
}> = ({ tableData }) => {
  const styles = useGridStyles();
  let dt = React.useRef<DataTable>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sortField, setSortField] = useState("expirationDate");
  const [sortOrder, setSortOrder] = useState(-1);
  const [expandedRows, setExpandedRows] = React.useState(
    Array<ProjectTableRecord>()
  );
  const [totalRecords, setTotalRecords] = useState(0);
  const [data, setData] = useState(Array<ProjectTableRecord>());
  const [keyword, setKeyword] = React.useState("");
  const [isGridFullScreen, setIsGridFullScreen] = React.useState(false);

  const RecordExpandedView = ({ record }: { record: ProjectTableRecord }) => {
    return <div>test</div>;
  };

  const tableHeader = (
    <div className="project-reports-header">
      <InputText
        type="search"
        className="table-header-search"
        value={keyword}
        onChange={(e) => setKeyword((e.target as any).value)}
        placeholder="Search"
      />
      <div className="project-reports-header-buttons-container">
        <PButton
          type="button"
          icon="pi pi-file"
          iconPos="left"
          className="aid-blu-btn all"
          style={{ ...styles.tableHeaderButton, height: "auto" }}
          label="All Forecasts"
          onClick={() => null}
        />
        <PButton
          type="button"
          icon="pi pi-file-o"
          className="aid-blu-btn"
          style={{ ...styles.tableHeaderButton, height: "auto" }}
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => null}
        />
        <PButton
          type="button"
          icon="pi pi-file-o"
          className="aid-blu-btn"
          style={{ ...styles.tableHeaderButton, height: "auto" }}
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
          onClick={() => null}
        />
        <PButton
          type="button"
          className="aid-blu-btn"
          icon={
            isGridFullScreen ? "pi pi-window-minimize" : "pi pi-window-maximize"
          }
          iconPos="left"
          onClick={(e) => {
            if (isGridFullScreen) {
              MinimizeGrid(e.target as HTMLElement);
            } else {
              MaximizeGrid(
                e.target as HTMLElement,
                () => setIsGridFullScreen(false),
                true
              );
            }

            setIsGridFullScreen(!isGridFullScreen);
          }}
          tooltip={isGridFullScreen ? "Minimize" : "Maximize"}
          tooltipOptions={{ position: "top" }}
          style={{ ...styles.tableHeaderButton, height: "auto" }}
        />
      </div>
    </div>
  );

  useEffect(() => {
    if (tableData) {
      setData(tableData);
    }
  }, [tableData]);

  return (
    <DataTable
      ref={dt}
      value={data}
      style={{ marginTop: 20, width: "100%" }}
      paginator={true}
      rows={rowsPerPage}
      totalRecords={totalRecords}
      lazy={true}
      loading={isLoading}
      sortField={sortField}
      sortOrder={sortOrder}
      scrollable={true}
      scrollHeight="400px"
      header={tableHeader}
      rowsPerPageOptions={rowsPerPageOptionsStandard}
      expandedRows={expandedRows}
      onRowToggle={(e: any) => setExpandedRows(e.data)}
      rowExpansionTemplate={(d: any) => <RecordExpandedView record={d} />}
    >
      <Column
        headerStyle={styles.headerStyle(50)}
        style={styles.columnStyle(50)}
        expander
      />
      {GetCustomColumnBody<ProjectTableRecord>(
        styles,
        300,
        (x) => x.notes,
        "Notes",
        "notes",
        { sortable: false }
      )}
      {GetCustomColumnBody<ProjectTableRecord>(
        styles,
        150,
        (x) => x.status,
        "Status",
        "status",
        { sortable: false }
      )}
      {GetCustomColumnBody<ProjectTableRecord>(
        styles,
        150,
        (x) => x.country,
        "Country",
        "country",
        { sortable: false }
      )}
      {GetCustomColumnBody<ProjectTableRecord>(
        styles,
        300,
        (x) => x.name,
        "Name",
        "name",
        { sortable: false }
      )}
      {GetCustomColumnBody<ProjectTableRecord>(
        styles,
        400,
        (x) => x.description,
        "Description",
        "description",
        { sortable: false }
      )}
    </DataTable>
  );
};

export default ProjectInfoTable;
