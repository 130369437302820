import styled from "@emotion/styled";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import React, { FC, useCallback } from "react";
import MarketPreferencesBeta from "../../../components/Dashboard/MarketPreferences/MarketPreferencesBeta";
import UserContext, {
  isUserInRoles,
  UserRoleGroups,
} from "../../../services/UserContext";
import axios from "axios";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../../configs/axios-export.custom";
import GrowlContext from "../../../services/growlContext";
import { MultiSelect } from "primereact/multiselect";
import Chip from "../../../components/Chip";
import { TabPanel, TabView } from "primereact/tabview";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import DebouncedButton from "./DebouncedButton";
import { useHistory } from "react-router-dom";
import { OverlayPanel } from "primereact/overlaypanel";
import { Capitalize } from "../../../utils/Helpers/Capitalize";
import { PreferenceFilterTypes } from "../../../services/enums";
const Divider = styled.hr({
  width: "100%",
  border: "1px solid #D9D9D9",
});
const CustomLabel = styled.label({
  fontSize: "15px",
});
const styles = {
  multiSelect: {
    width: "100%",
    borderRadius: 10,
  },
  chipsContainer: {
    marginTop: "5px",
    display: "flex",
    flexWrap: "wrap" as "wrap",
  },
};
type User = {
  publicUserId: string;
  firstName: string;
  lastName: string;
  userRole: string;
  pipelineUserRole: string;
  email: string;
  label: string;
};

type PipelineDto = {
  id: number;
  name: string;
  description: string;
  createdByCognitoId: string;
  businessForecastCustomColumnName: string;
  contractCustomColumnName: string;
  grantCustomColumnName: string;
  pipelineUserRole: string;
  createdOnUtc: string;
  users: User[];
  marketPreferences: any;
  keywords: [];
  grantCount: number;
  contractCount: number;
  forecastCount: number;
  totalOpportunities: number;
  preferenceFilterType: PreferenceFilterTypes;
};

type Prop = {
  pipelines: PipelineDto;
  toggleEdits: boolean;
  toggleViewMode: boolean;
  divRef: React.RefObject<OverlayPanel>;
};
enum PreferenceTypes {
  country = "Country",
  technicalSector = "TechnicalSector",
  keyword = "Keyword",
}

interface Item {
  Name: string;
  code: string;
}
type MarketPreference = {
  preferenceType: string;
  name: string;
  code: string
};

const Sidebar: FC<Prop> = ({
  pipelines,
  toggleEdits,
  toggleViewMode,
  divRef,
}) => {
  const setUserLabel = (userList) => {
    userList.map((user) => {
      if (user) {
        user.label =
          Capitalize(
            !user.firstName && !user.lastName ? user.email : user.firstName
          ) +
          " " +
          Capitalize(user.lastName ?? "");
      }
    });
  };

  const [title, setTitle] = React.useState(pipelines.name);
  const [desc, setDesc] = React.useState(pipelines.description);
  const [preferenceFilterType, setPreferenceFilterType] = React.useState(
    pipelines.preferenceFilterType
  );

  const context = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);
  const header = createAuthenticatedRequest(context);
  const history = useHistory();
  const [companyUsers, setCompanyUsers] = React.useState<User[]>([]);
  const [selected, setSelected] = React.useState({});
  const isFree = !isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const { user } = context;
  const { users } = pipelines;
  setUserLabel(users);
  const currentUserRole = pipelines.pipelineUserRole;
  const [prevPipelineMembers, setPrevPipelineUsers] =
    React.useState<User[]>(users);
  const [pipelineMembers, setPipelineUser] = React.useState<User[]>(users);
  const [selectedCountries, setSelectedCountires] = React.useState<
    MarketPreference[]
  >(
    pipelines.marketPreferences.filter((obj: MarketPreference) => {
      return obj.preferenceType === PreferenceTypes.country.toString();
    })
  );
  const [selectedSectors, setSelectedSectors] = React.useState<
    MarketPreference[]
  >(
    pipelines.marketPreferences.filter((obj: MarketPreference) => {
      return obj.preferenceType !== PreferenceTypes.country.toString();
    })
  );
  const [selectedKeywords, setSelectedKeywords] = React.useState<any[]>(
    pipelines.keywords
  );

  const GetSelectedCountries = (data: any) => {
    setSelectedCountires(data);
  };
  const GetSelectedSectors = (data: any) => {
    setSelectedSectors(data);
  };
  const GetSelectedKeywords = (data: any) => {
    setSelectedKeywords(data);
  };
  const GetPreferenecFilterType = (data: any) => {
    setPreferenceFilterType(data);
  };

  const preferenceHasChanged = (oldPref: any, newPref: any) => {
    if (oldPref.length !== newPref.length) return true;

    let changed = false;

    oldPref.forEach((element) => {
      const found = newPref.find((obj) => {
        return (
          obj.preferenceType == element.preferenceType &&
          obj.name === element.name
        );
      });
      if (!found) changed = true;
    });
    return changed;
  };
  const keywordHasChanged = (oldKeyword: any, newKeyword: any) => {
    if (oldKeyword.length !== newKeyword.length) return true;

    let changed = false;

    oldKeyword.forEach((element) => {
      const found = newKeyword.find((obj) => {
        return obj.name === element;
      });
      if (!found) changed = true;
    });
    return changed;
  };

  const memberHasChanged = (oldMembers: any, newMembers: any) => {
    if (oldMembers.length !== newMembers.length) return true;

    let changed = false;

    oldMembers.forEach((element) => {
      const found = newMembers.find((obj) => {
        return obj.email == element.email;
      });
      if (!found) changed = true;
    });
    return changed;
  };
  React.useEffect(() => {
    axios
      .get(
        getFullUrl("/api/organization/user", { useDedicatedEnvironment: true }),
        createAuthenticatedRequest(context)
      )
      .then((response) => {
        const { data } = response;
        const email = user.parsedIdToken?.["cognito:username"].split("|");
        setUserLabel(data);
        if (email) {
          var id = email[0] + "@" + email[1];
          setCompanyUsers(data);
        } else {
          setCompanyUsers(data);
        }
      })
      .catch((e) => {
        console.error(e);
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: "Unable to load users",
          });
        }
      });
  }, []);

  const addSelectedMarketPref = async (id: number) => {
    let countries = selectedCountries.map((r) => {
      return {
        preferenceType: PreferenceTypes.country.toString(),
        name: r.code,
      };
    });
    let sectors = selectedSectors.map((r) => ({
      preferenceType: PreferenceTypes.technicalSector.toString(),
      name: r.name,
    }));

    let keywords = selectedKeywords.map((r) => {
      return {
        name: r,
        preferenceType: PreferenceTypes.keyword.toString(),
      };
    });

    const data = [...countries, ...sectors, ...keywords];

    if (
      !preferenceHasChanged(pipelines.marketPreferences, data) &&
      pipelines.preferenceFilterType === preferenceFilterType &&
      !keywordHasChanged(pipelines.keywords, keywords)
    ) {
      return;
    }

    await axios
      .put(
        getFullUrl(`/api/pipeline/${id}/marketpreference`, {
          useDedicatedEnvironment: true,
        }),
        data,
        header
      )
      .then((res) => {})
      .catch((message) => {
        console.error(message.response.data);
        growl.current.show({
          severity: "error",
          summary: "Error saving info",
        });
      });
  };

  const editPipeline = async () => {
    const pipelineUsers = pipelineMembers.map((sa) => {
      if (Object.keys(selected).length === 0) {
        return {
          publicUserId: sa.publicUserId.toUpperCase(),
          role:
            sa.userRole === "View only" ||
            sa.userRole === "Member" ||
            sa.userRole === null
              ? "Member"
              : "Admin",
        };
      } else {
        return {
          publicUserId: sa.publicUserId.toUpperCase(),
          role: selected[sa.firstName],
        };
      }
    });

    if (title === "") {
      growl.current.show({
        severity: "error",
        summary: "The title is required",
      });
      return;
    }
    await axios
      .put(
        getFullUrl(`/api/pipeline/${pipelines.id}`, {
          useDedicatedEnvironment: true,
        }),
        {
          Name: title,
          Description: desc,
          BusinessForecastCustomColumnName: "Internal ID",
          ContractCustomColumnName: "Internal ID",
          GrantCustomColumnName: "Internal ID",
          Users: pipelineUsers,
          PreferenceFilterType: preferenceFilterType,
        },
        header
      )
      .then(async (res) => {
        await addSelectedMarketPref(pipelines.id).finally(() => {
          let marketPref: MarketPreference[] =
            selectedCountries.concat(selectedSectors);
          const row = {} as PipelineDto;
          row.name = title;
          row.description = desc;
          row.pipelineUserRole = pipelines.pipelineUserRole;
          row.id = pipelines.id;
          row.users = pipelineMembers;
          row.marketPreferences = marketPref;
          row.createdByCognitoId = pipelines.createdByCognitoId;
          row.preferenceFilterType = pipelines.preferenceFilterType;
          //  update  pills
          setUserLabel(row.users);
          setPipelineUser(row.users);
          //  reset location state
          if (history.location.state) {
            let state = row;
            history.replace({ ...history.location, state });
          }
          growl.current.show({
            severity: "success",
            summary: "Pipeline has been edited",
          });
          // close the overlay
          window.location.replace(window.location.href);
          divRef.current?.hide();
        });
      })
      .catch((message) => {
        console.error(message);
        growl.current.show({
          severity: "error",
          summary: "Error saving edits",
        });
      });
  };

  const selectUsers = useCallback(async (usersToAdd: User[]) => {
    setUserLabel(usersToAdd);
    setPipelineUser(usersToAdd);
  }, []);

  const userTemplate = (option) => {
    return (
      <div className="inner-list" key={option.publicUserId}>
        {Capitalize(
          !option.firstName && !option.lastName
            ? option.email
            : option.firstName
        )}{" "}
        {Capitalize(option.lastName ?? "")}
      </div>
    );
  };
  const RemoveUser = (userToRemove: User, Users: User[]) => {
    const updatedUsers = Users.filter(
      (user) => user.publicUserId !== userToRemove.publicUserId
    );
    setPipelineUser(updatedUsers);
    // if user  is already in options list
    // return else add the removed user to the option list

    if (companyUsers.includes(userToRemove)) {
      return;
    } else {
      setCompanyUsers([userToRemove, ...companyUsers]);
    }
  };

  const country = pipelines.marketPreferences.filter(
    (obj: MarketPreference) => {
      return obj.preferenceType === PreferenceTypes.country.toString();
    }
  );
  const sectors = pipelines.marketPreferences.filter(
    (obj: MarketPreference) => {
      return obj.preferenceType !== PreferenceTypes.country.toString();
    }
  );

  let keywords = selectedKeywords.map((r) => {
    return {
      name: r,
      preferenceType: PreferenceTypes.keyword.toString(),
    };
  });

  const canEditData =
    memberHasChanged(pipelineMembers, users) ||
    // prevPipelineMembers.map((m) => m.userRole).toString() !==
    //   pipelineMembers.map((m) => m.userRole).toString() ||
    pipelines.name !== title ||
    pipelines.description !== desc ||
    pipelines.preferenceFilterType !== preferenceFilterType ||
    preferenceHasChanged(country, selectedCountries) ||
    preferenceHasChanged(sectors, selectedSectors) ||
    keywordHasChanged(pipelines.keywords, keywords);

  function getDifference(c1: User[], c2: User[]) {
    return c1.filter((sa) => {
      return !c2.some((r) => {
        return sa.publicUserId === r.publicUserId.toLowerCase();
      });
    });
  }

  const [admins, setAdmins] = React.useState<
    {
      email: string;
      firstName: string;
      lastName: string;
      publicUserId: string;
    }[]
  >([]);

  const getAdmin = () => {
    axios
      .get(
        getFullUrl("/api/organization/admin", {
          useDedicatedEnvironment: true,
        }),
        createRequestWithAuthHeaders(context)
      )
      .then(({ data }) => {
        setAdmins(data);
      });
  };

  React.useEffect(() => {
    getAdmin();
  }, []);

  const owner = pipelineMembers.find(
    (p) => p.publicUserId.toLowerCase() === pipelines.createdByCognitoId
  );

  const toLowerCase = (str?: string) => {
    return str ? str.toLocaleLowerCase() : "";
  };

  const formattedRole = (role: string) => {
    switch (role) {
      case "Admin":
        return "Can manage";
      default:
        return "View only";
    }
  };

  const roles = ["View only", "Can manage"];

  const handleRoleChanged = (userId: string, role: string) => {
    const updatedUsers = pipelineMembers.map((user) => {
      if (userId === user.publicUserId) {
        return { ...user, userRole: role };
      } else {
        return user;
      }
    });
    setPrevPipelineUsers(pipelineMembers);
    setPipelineUser(updatedUsers);
  };

  return (
    <>
      <TabView>
        <TabPanel header="Details">
          <div className="p-grid text-labels">
            <div className="p-col-12" style={{ textAlign: "left" }}>
              <div className="p-col-12">
                <CustomLabel htmlFor="Title">Title*</CustomLabel>
                <InputText
                  placeholder="Title"
                  className="field-inputs"
                  defaultValue={title}
                  // value={}
                  onChange={(e) => {
                    e.preventDefault();
                    setTitle((e.target as HTMLInputElement).value);
                  }}
                  style={{
                    marginTop: 8,
                  }}
                />
              </div>
              <div className="p-col-12">
                <CustomLabel className="field-titles" htmlFor="Message">
                  Description
                </CustomLabel>
                <InputTextarea
                  defaultValue={desc || pipelines.description}
                  placeholder=""
                  style={{
                    width: "100%",
                    marginTop: 8,
                  }}
                  rows={5}
                  cols={40}
                  onChange={(e) =>
                    setDesc((e.target as HTMLInputElement).value)
                  }
                />
              </div>

              {isFree ? null : (
                <div className="p-col-12">
                  <CustomLabel className="field-titles" htmlFor="users">
                    Share With
                  </CustomLabel>
                  <MultiSelect
                    optionLabel="label"
                    filter={true}
                    style={{ ...styles.multiSelect, marginTop: 8 }}
                    placeholder=" share with"
                    fixedPlaceholder
                    value={
                      pipelineMembers.map((user) => ({
                        firstName:
                          !user.firstName && !user.lastName
                            ? user.email
                            : user.firstName,
                        lastName: user.lastName,
                        publicUserId: user.publicUserId.toLowerCase(),
                        email: user.email,
                        pipelineUserRole: user.pipelineUserRole,
                        label: user.label,
                      })) as Pick<
                        User,
                        | "firstName"
                        | "lastName"
                        | "publicUserId"
                        | "email"
                        | "pipelineUserRole"
                        | "label"
                      >[]
                    }
                    options={
                      companyUsers
                        .map((user) => {
                          const currentUser = pipelineMembers.find(
                            (p) =>
                              user.publicUserId.toLowerCase() ===
                              p.publicUserId.toLowerCase()
                          );
                          return {
                            firstName:
                              !user.firstName && !user.lastName
                                ? user.email
                                : user.firstName,
                            lastName: user.lastName,
                            publicUserId: user.publicUserId.toLowerCase(),
                            email: user.email,
                            pipelineUserRole: currentUser
                              ? user.pipelineUserRole ??
                                currentUser.pipelineUserRole
                              : "Member",
                            label: user.label,
                          };
                        })
                        .filter(
                          (u) => u.publicUserId !== pipelines.createdByCognitoId
                        ) as Pick<
                        User,
                        | "firstName"
                        | "lastName"
                        | "publicUserId"
                        | "email"
                        | "pipelineUserRole"
                        | "label"
                      >[]
                    }
                    itemTemplate={userTemplate}
                    onChange={(e) => selectUsers(e.value)}
                  />

                  <div style={styles.chipsContainer}>
                    {pipelineMembers
                      .filter(
                        (user) =>
                          user.publicUserId.toLowerCase() !==
                          pipelines.createdByCognitoId
                      )
                      .map((sa, i) => (
                        <Chip
                          key={i}
                          label={
                            Capitalize(
                              sa.firstName && sa.lastName
                                ? sa.firstName
                                : sa.email
                            ) +
                            " " +
                            Capitalize(sa.lastName ?? " ")
                          }
                          onIconClick={() => RemoveUser(sa, pipelineMembers)}
                        />
                      ))}
                  </div>
                </div>
              )}
              <div className="p-col-12">
                <label>Preferences</label>
                <div>
                  <div>
                    <div className="p-col-12">
                      <MarketPreferencesBeta
                        pipelineId={pipelines.id}
                        GetSelectedCountries={GetSelectedCountries}
                        GetSelectedSectors={GetSelectedSectors}
                        preferenceFType={pipelines.preferenceFilterType}
                        GetSelectedKeywords={GetSelectedKeywords}
                        keywords={pipelines.keywords}
                        marketPreferences={[
                          ...selectedCountries,
                          ...selectedSectors,
                        ]}
                        GetPreferenecFilterType={GetPreferenecFilterType}
                        disabled={false}
                      />
                    </div>
                    <div className="p-col-12">
                      <div className="pipeline-save-button">
                        <DebouncedButton
                          disabled={!canEditData}
                          as={Button}
                          delay={5000}
                          className="modal-button"
                          label="Save"
                          onClick={editPipeline}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Privacy" contentStyle={{ textAlign: "left" }}>
          <div className="field-radiobutton text-labels">
            <RadioButton
              style={{}}
              inputId="private"
              name="private"
              checked={
                pipelineMembers.filter((user) =>
                  context.user.parsedIdToken?.sub.toLowerCase() ===
                  pipelines.createdByCognitoId
                    ? user.publicUserId.toLowerCase() !==
                      pipelines.createdByCognitoId
                    : true
                ).length < 1
              }
              value="private"
            />
            <label
              style={{ paddingLeft: "5px", fontWeight: 600 }}
              htmlFor="private"
            >
              Private
            </label>
            <p className="radio-button-info">
              Used for personal pipeline opportunities tracking
            </p>
          </div>
          <div className="field-radiobutton text-labels">
            <RadioButton
              inputId="public"
              name="public"
              checked={
                pipelineMembers.filter((user) =>
                  context.user.parsedIdToken?.sub.toLowerCase() ===
                  pipelines.createdByCognitoId
                    ? user.publicUserId.toLowerCase() !==
                      pipelines.createdByCognitoId
                    : true
                ).length >= 1
              }
              value="public"
            />
            <label
              style={{ fontSize: "16px", paddingLeft: "5px", fontWeight: 600 }}
              htmlFor="private"
            >
              Shared
            </label>
            <p className="radio-button-info">
              {" "}
              Multiple users can manage pipeline opportunities
            </p>
          </div>
          <div className="pipeline-user-label">
            <label
              style={{ fontSize: "16px", marginTop: "10px" }}
              className="field-titles"
              htmlFor="pipeline members"
            >
              Pipeline Members
            </label>
            <div className="shared-with-total">
              {"Shared with " +
                pipelineMembers.filter(
                  (user) =>
                    user.publicUserId.toLowerCase() !==
                    pipelines.createdByCognitoId
                ).length +
                " Member" +
                (pipelineMembers.filter(
                  (user) =>
                    user.publicUserId.toLowerCase() !==
                    pipelines.createdByCognitoId
                ).length !== 2
                  ? "s"
                  : "")}
            </div>
          </div>
          <div className="p-grid text-labels">
            {owner && (
              <>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div className="pipeline-members">
                    <div>
                      <p style={{ width: "200px" }}>
                        <strong>
                          {toLowerCase(owner.firstName)}{" "}
                          {toLowerCase(owner.lastName)}
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div className="pipeline-members privacy">
                    <span className="owner">Owner</span>
                  </div>
                </div>
                <Divider />
              </>
            )}
            {React.Children.toArray(
              pipelineMembers
                .filter((user) =>
                  owner?.publicUserId.toLowerCase() ===
                  pipelines.createdByCognitoId
                    ? user.publicUserId.toLowerCase() !==
                      pipelines.createdByCognitoId
                    : true
                )
                .map((user: User) => (
                  <>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div className="pipeline-members">
                        {user.pipelineUserRole === "Admin" && (
                          <div>
                            <p style={{ width: "200px" }}>
                              <strong>
                                {toLowerCase(user.firstName)}{" "}
                                {toLowerCase(user.lastName)}
                              </strong>
                            </p>
                            <span
                              style={{
                                width: 200,
                              }}
                            >
                              {user.email}
                            </span>
                          </div>
                        )}
                        {user.pipelineUserRole !== "Admin" && (
                          <div>
                            <p>
                              <strong>
                                {toLowerCase(user.firstName)}{" "}
                                {toLowerCase(user.lastName)}
                              </strong>
                            </p>
                            <span
                              style={{
                                width: 200,
                                lineBreak: "anywhere",
                              }}
                            >
                              {user.email}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="pipeline-members privacy">
                        <Dropdown
                          optionLabel="role"
                          placeholder={formattedRole(user.pipelineUserRole)}
                          disabled={
                            context.user.parsedIdToken?.sub.toLocaleLowerCase() ===
                              user.publicUserId.toLowerCase() &&
                            user.pipelineUserRole === "Member"
                          }
                          value={user.userRole}
                          options={roles}
                          valueTemplate={(v) => {
                            if (v === null) {
                              return formattedRole(user.pipelineUserRole);
                            } else {
                              return v;
                            }
                          }}
                          itemTemplate={(i) => i}
                          onChange={(e) =>
                            handleRoleChanged(user.publicUserId, e.value)
                          }
                          style={{
                            width: 175,
                          }}
                        />
                      </div>
                      {currentUserRole !== "Admin" ? null : (
                        <div
                          onClick={() => RemoveUser(user, pipelineMembers)}
                          className="member-close-button"
                        >
                          X
                        </div>
                      )}
                    </div>
                    <Divider />
                  </>
                ))
            )}
            <div className="p-col-12">
              <div className="pipeline-save-button">
                <DebouncedButton
                  disabled={!canEditData}
                  as={Button}
                  delay={5000}
                  className="modal-button"
                  label="Save"
                  onClick={editPipeline}
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </>
  );
};

export default Sidebar;
