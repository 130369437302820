import React, {
  FC,
  MutableRefObject,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  LinearProgress,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import ReactQuill from "react-quill";
import styled from "@emotion/styled";

interface RichTextEditorProps {
  setValue: (value: string) => void;
  value: string;
  label?: string | null;
  loading?: boolean;
  disabled?: boolean;
  containerSx?: SxProps;
  setUnformattedValue?: (value: string) => void;
}

const ReactQuillStyleWrapper = styled("div")(({ theme }) => ({
  "div[contenteditable=false]": {
    //@ts-ignore
    backgroundColor: theme.palette.action.disabled,
  },
}));

export const defaultEmptyLine = "<p><br></p>";

const RichTextEditor: FC<RichTextEditorProps> = (props) => {
  const {
    value,
    setValue,
    setUnformattedValue = null,
    label = null,
    loading = false,
    disabled = false,
    containerSx = {},
  } = props;

  const theme = useTheme();

  const quillRef = useRef<any>();
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerRefState, setContainerRefState] =
    useState<RefObject<HTMLDivElement> | null>(null);

  useEffect(() => {
    if (!quillRef || (quillRef && !quillRef.current)) return;
    loading || disabled
      ? quillRef.current.editor.enable(false)
      : quillRef.current.editor.enable(true);
  }, [quillRef, loading, disabled]);

  useEffect(() => {
    if (containerRef.current) setContainerRefState(containerRef);
  }, [containerRef.current]);

  return (
    <Box
      id={"rich-text-editor-container"}
      ref={containerRef}
      sx={{
        my: 1,
        height: !label ? "144px" : "176px",
        ...containerSx,
      }}
    >
      {label && (
        <Typography
          sx={{ mb: 0.5, fontSize: "16px", fontWeight: "400" }}
          variant={"body2"}
        >
          {label}
        </Typography>
      )}
      <Box sx={{ height: "4px" }}>{loading && <LinearProgress />}</Box>
      <ReactQuillStyleWrapper>
        {!!containerRefState?.current && (
          <ReactQuill
            // bounds={document.getElementById('rich-text-editor-container') as HTMLDivElement}
            bounds={containerRefState?.current}
            style={{
              height: 100,
            }}
            theme="snow"
            value={value}
            onChange={(val) => {
              setValue(val);
              !!setUnformattedValue &&
                !!quillRef.current &&
                setUnformattedValue(
                  quillRef?.current
                    ?.getEditor()
                    ?.getText(0, quillRef?.current?.getEditor()?.getLength()),
                );
            }}
            ref={quillRef}
          />
        )}
      </ReactQuillStyleWrapper>
    </Box>
  );
};

export default RichTextEditor;
