import * as React from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import "./UserMenu.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import UserContext, {
  convertRoleToFriendlyName,
  doesUserHaveRole,
  getMaxUserRole,
  isUserInRoles,
  ServerOrgRoles,
  UserRoleGroups,
  userRoleInOrg,
} from "../../../../services/UserContext";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../../../configs/axios-export.custom";
import {
  AskAQuestionUrl,
  goToUrlInNewTab,
} from "../../../../configs/resources";
import { useState } from "react";
import UpgradePlanModal from "../../../Modals/UpgradePlanModal";
import { useDispatch } from "../../../../store";
import { clearStoreState } from "../../../../slices/dashboard";

const styles = {
  usermenu: {
    paddingLeft: 50,
    paddingBottom: 40,
  },
  menuOptions: {
    borderRadius: "20px",
    marginTop: "10px",
  },
  button: {
    borderRadius: "20px",
    background: "rgba(250, 234, 205, 0.12)",
    width: "auto",
    // top: "19px",
    color: "black !important",
    border: "1px solid #c7e1ee",
    "& hover": {
      background: "#f0f7fb !important",
    },
  },
  popover: {
    zIndex: 1000,
    lef: "-10px",
  },
  profileImage: {
    width: "36px",
    height: "36px",
  },
};
export type UserProfile = {
  firstName: string;
  lastName: string;
  imgUrl: string;
  companyEmails: {
    emailAddress: string;
  }[];
};

export default function UserMenu() {
  const dispatch = useDispatch();
  const context = React.useContext(UserContext);
  const request = createAuthenticatedRequest(context);
  const role = React.useMemo(() => {
    return convertRoleToFriendlyName(getMaxUserRole(context));
  }, [context]);

  const isFree = !isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const isMember = doesUserHaveRole(context, ServerOrgRoles.organizationMember);
  const [upgradePlanModalOpen, setUpgradePlanModalOpen] =
    useState<boolean>(false);

  const [profilePic, setProfilePic] = React.useState("");

  const history = useHistory();
  const isOrgAdmin = userRoleInOrg(context, ServerOrgRoles.organizationAdmin);

  React.useEffect(() => {
    axios
      .get(
        getFullUrl("/api/user/profile/picture", {
          useDedicatedEnvironment: true,
        }),
        request,
      )
      .then(async (response) => {
        let bstr = await response.data;
        let base64ToString = Buffer.from(bstr, "base64").toString();
        setProfilePic(base64ToString);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const loadDataAsync = React.useCallback(() => {
    axios
      .get(
        getFullUrl("/api/user/profile", { useDedicatedEnvironment: true }),
        request,
      )
      .then((response) => {
        // extract user account details from response
        const { firstName, lastName }: UserProfile = response.data;

        setFirstName(firstName);
        setLastName(lastName);
      })
      .catch((e) => {
        console.error(e);
      });

    // load profile image too
  }, []);

  React.useEffect(loadDataAsync, [context]);

  const [FirstName, setFirstName] = React.useState("");
  const [LastName, setLastName] = React.useState("");

  // Modal Menu
  const [open, setOpen] = React.useState(false);

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const signOut = () => {
    setOpen(false);
    axios
      .post(
        getFullUrl("/api/auth/signout", { useDedicatedEnvironment: true }),
        null,
        createRequestWithAuthHeaders(context),
      )
      .then(() => {
        context
          .updateCurrentUser({
            accessToken: "",
            jwt: "",
            idToken: undefined,
            parsedIdToken: undefined,
            refreshToken: "",
            expiresIn: 0,
            groups: [],
          })
          .then(() => {
            dispatch(clearStoreState());
            history.push("/auth");
          });
      })
      .catch(() => {});
  };

  const toOrganizationPage = () => {
    history.push("/organization");
    setOpen(false);
  };
  const toUsersettingsPage = () => {
    history.push("/UserDashboard");
    setOpen(false);
  };
  return (
    <Stack direction="row" spacing={2}>
      <UpgradePlanModal
        upgradePlanModalOpen={upgradePlanModalOpen}
        setUpgradePlanModalOpen={(val: boolean) => setUpgradePlanModalOpen(val)}
      />
      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          endIcon={<KeyboardArrowDown />}
          style={styles.button}
        >
          <div className="profile-group">
            <div className="profile-overlap">
              <img
                onLoad={(e) => (e.currentTarget.style.display = "")}
                style={{ borderRadius: "50%", display: "none" }}
                className="img-centered-clipped"
                height={36}
                width={36}
                src={"data:image/jpeg;base64," + profilePic}
                alt=""
              />
            </div>
            <div className="profile-titles-container">
              <div className="profile-title">
                <p style={{ margin: 0 }}>
                  {FirstName} {LastName}
                </p>
                <p
                  style={{ margin: 0, textDecoration: "none" }}
                  className="profile-view-text"
                >
                  {role}
                </p>
              </div>
              <p
                style={{ display: "flex", textAlign: "center" }}
                className="user-roles"
              ></p>
            </div>
          </div>
        </Button>
        <Popper
          style={styles.popover}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <div className="user-drop-down-menu">
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    className="menu-list"
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {/* <hr /> */}
                    <div className="menu">
                      {!isFree ? (
                        <>
                          {isOrgAdmin ? (
                            <div
                              onClick={toOrganizationPage}
                              className="menu-items profile-menu-items"
                            >
                              <strong>Organization Settings</strong>
                            </div>
                          ) : null}
                        </>
                      ) : null}
                      {isFree && (
                        <div
                          onClick={
                            isMember
                              ? () => {
                                  setUpgradePlanModalOpen(true);
                                  setOpen(false);
                                }
                              : () => {
                                  goToUrlInNewTab("tiers")();
                                  setOpen(false);
                                }
                          }
                          className="menu-items profile-menu-items"
                        >
                          <strong>Upgrade Plan</strong>
                        </div>
                      )}
                      <div
                        onClick={toUsersettingsPage}
                        className="menu-items profile-menu-items"
                      >
                        <strong>Account Settings</strong>
                      </div>
                      <div
                        className="menu-items profile-menu-items"
                        onClick={() => {
                          goToUrlInNewTab(AskAQuestionUrl)();
                          setOpen(false);
                        }}
                      >
                        <strong>Submit Feedback</strong>
                      </div>
                      <div
                        className="menu-items profile-menu-items"
                        onClick={signOut}
                      >
                        <strong>Sign out</strong>
                      </div>
                    </div>
                  </MenuList>
                </ClickAwayListener>
              </div>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
