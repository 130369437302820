import { TableProperties } from "exceljs";
import { buildExcelTable, genEmptyRow } from "../../services/exporter";
import { ReportRecord } from "../../slices/reports";
import axios from "axios";
import {
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import { buildQuery } from "../../utils/Helpers/queryBuilder";

export function getReportsDownloadUrlFromId(id: string) {
  return `https://konektid-dec-resources.s3.amazonaws.com/${id.replace(
    /-/g,
    "",
  )}.pdf`;
}

export function getExportableDataTableReports(
  data: ReportRecord[],
): TableProperties {
  const columns = [
    { name: "Link" },
    { name: "Country" },
    { name: "Title" },
    { name: "Document Type" },
    { name: "Publication Date" },
    { name: "Authoring Organization" },
    { name: "Authors" },
    { name: "Technical Sector" },
    { name: "Abstract" },
    { name: "Contract Grant Number" },
    { name: "Contract Grant Number Evaluated" },
  ];

  let rows: any[][] = [];

  if (data.length == 0) {
    rows.push(genEmptyRow(columns.length));
  } else {
    for (let i = 0; i < data.length; i++) {
      const record = data[i];

      rows.push([
        getReportsDownloadUrlFromId(record.uniqueId),
        record.country,
        record.title,
        record.documentType,
        record.publicationDate ? record.publicationDate.substring(0, 10) : "",
        record.authoringOrganization,
        record.personalAuthors
          ? record.personalAuthors.replace(/ \|\| /g, "; ")
          : "",
        record.technicalSectors
          ? record.technicalSectors.replace(/ \|\| /g, "; ")
          : "",
        record.abstract,
        record.contractGrantNumber,
        record.contractGrantNumberEvaluated,
      ]);
    }
  }

  return buildExcelTable("DataExport", columns, rows);
}

export async function getExcelDataReports(
  context,
  queryDto,
  totalItems: number = 1000,
): Promise<any[]> {
  const q = queryDto;
  if (q) {
    q.pageSize = totalItems;
    q.pageIndex = 0;
  }
  let result = Array<any>();
  await axios
    .get(
      getFullUrl(`/api/Dec${buildQuery({ ...q }, "|")}`, {
        useDedicatedEnvironment: true,
      }),
      createRequestWithAuthHeaders(context),
    )
    .then((response) => {
      result = response.data.data;
    })
    .catch((error) => {
      alert("error fetching data");
    });
  return result;
}
