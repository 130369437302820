export enum Type {
  SET_FIRST_NAME = "SET_FIRST_NAME",
  SAVE_FIRST_NAME = "SAVE_FIRST_NAME",

  SET_LAST_NAME = "SET_LAST_NAME",
  SAVE_LAST_NAME = "SAVE_LAST_NAME",

  SET_TIME_ZONE = "SET_TIME_ZONE",
  SAVE_TIME_ZONE = "SAVE_TIME_ZONE",
}

type SetFirstNameAction = { type: Type.SET_FIRST_NAME; value: string };
type SaveFirstNameAction = { type: Type.SAVE_FIRST_NAME };
type SetLastNameAction = { type: Type.SET_LAST_NAME; value: string };
type SaveLastNameAction = { type: Type.SAVE_LAST_NAME };
type SetTimeZoneAction = { type: Type.SET_TIME_ZONE; value: string };
type SaveTimeZoneAction = { type: Type.SAVE_TIME_ZONE };

export const setFirstName = (firstName: string): SetFirstNameAction => ({
  type: Type.SET_FIRST_NAME,
  value: firstName,
});

export const saveFirstName = (): SaveFirstNameAction => ({
  type: Type.SAVE_FIRST_NAME,
});

export const setLastName = (lastName: string): SetLastNameAction => ({
  type: Type.SET_LAST_NAME,
  value: lastName,
});

export const saveLastName = (): SaveLastNameAction => ({
  type: Type.SAVE_LAST_NAME,
});

export const setTimeZone = (timeZone: string): SetTimeZoneAction => ({
  type: Type.SET_TIME_ZONE,
  value: timeZone,
});

export const saveTimeZone = (): SaveTimeZoneAction => ({
  type: Type.SAVE_TIME_ZONE,
});

export type Action =
  | SetFirstNameAction
  | SetLastNameAction
  | SetTimeZoneAction
  | SaveFirstNameAction
  | SaveLastNameAction
  | SaveTimeZoneAction;
