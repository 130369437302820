import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import OpportunityDetails from "../../components/OpportunityDetails/OpportunityDetails";
import UserContext from "../../services/UserContext";
import { RootState, useDispatch, useSelector } from "../../store";
import { useHistory } from "react-router-dom";
import {
  getPipelinesForRecord,
  resetAddOrRemoveFromPipelinesStatus,
  resetPipelinesForRecord,
  setPipelinesForRecordFor,
} from "../../slices/pipelines";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import toast from "react-hot-toast";
import {
  getForecastDetails,
  resetShareOpportunitiesStatus,
  shareOpportunities,
} from "../../slices/forecast";

const ForecastProfilePage: FC = (props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const context = useContext(UserContext);

  const {
    fetchStatus: pipelinesForRecordFetchStatus,
    itemsFor: pipelinesForRecordFor,
  } = useSelector((state: RootState) => state.pipelines.pipelinesForRecord);
  const { postFetchStatus } = useSelector(
    (state: RootState) => state.pipelines.addOrRemoveFromPipelines,
  );
  const { records, fetchStatus } = useSelector(
    (state: RootState) => state.forecast.details,
  );
  const { postFetchStatus: shareOpportunitiesFetchStatus } = useSelector(
    (state: RootState) => state.forecast.shareOpportunities,
  );

  const [addToPipelinesOpen, setAddToPipelinesOpen] = useState<boolean>(false);

  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);

  const id = useMemo(() => {
    const path = history.location.pathname.split("/");
    return path[path.length - 1];
  }, [history]);

  const [data, changelog] = useMemo(() => {
    const changelog = ([...records] as any)?.sort(
      (r1, r2) =>
        new Date(r2.createdOn).getTime() - new Date(r1.createdOn).getTime(),
    );
    const data = changelog.shift();
    return [data, changelog];
  }, [records]);

  const handleOpenAddToPipelines = (): void => {
    if (!id) return;
    if (addToPipelinesOpen) {
      handleCloseAddToPipelines();
      setTimeout(() => {
        dispatch(setPipelinesForRecordFor(id));
        dispatch(
          getPipelinesForRecord({
            context,
            table: "forecasts",
            recordId: id,
          }),
        );
      }, 100);
    } else {
      dispatch(setPipelinesForRecordFor(id));
      dispatch(
        getPipelinesForRecord({
          context,
          table: "forecasts",
          recordId: id,
        }),
      );
    }
  };

  const handleCloseAddToPipelines = (): void => {
    setAddToPipelinesOpen(false);
    dispatch(setPipelinesForRecordFor(null));
    dispatch(resetPipelinesForRecord());
  };

  const onShareSubmit = (values: any): void => {
    dispatch(
      shareOpportunities({
        context,
        ...values,
      }),
    );
  };

  const handleResetShareOpportunitiesStatus = () => {
    dispatch(resetShareOpportunitiesStatus());
  };

  useEffect(() => {
    if (
      RequestStatus.isDone(pipelinesForRecordFetchStatus) &&
      pipelinesForRecordFor !== null &&
      !addToPipelinesOpen
    )
      setAddToPipelinesOpen(true);
    if (RequestStatus.isError(pipelinesForRecordFetchStatus)) {
      toast.error(
        "Pipeline data for this row couldn't be loaded. Try again later.",
      );
    }
  }, [pipelinesForRecordFetchStatus, pipelinesForRecordFor]);

  useEffect(() => {
    if (RequestStatus.isDone(postFetchStatus)) {
      dispatch(resetAddOrRemoveFromPipelinesStatus());
      handleCloseAddToPipelines();
      id &&
        dispatch(
          getForecastDetails({
            context,
            id,
          }),
        );
    }
  }, [postFetchStatus]);

  useEffect(() => {
    if (!id) return;
    dispatch(
      getForecastDetails({
        context,
        id,
      }),
    );
  }, [id]);

  useEffect(() => {
    if (RequestStatus.isDone(fetchStatus) && isFirstLoading)
      setIsFirstLoading(false);
  }, [fetchStatus, isFirstLoading]);

  return (
    <>
      <OpportunityDetails
        source={"forecast"}
        data={data}
        loading={RequestStatus.isFetching(fetchStatus) && isFirstLoading}
        addToPipelinesOpen={addToPipelinesOpen}
        setAddToPipelinesOpen={setAddToPipelinesOpen}
        handleCloseAddToPipelines={handleCloseAddToPipelines}
        handleOpenAddToPipelines={handleOpenAddToPipelines}
        pipelinesFetchStatus={pipelinesForRecordFetchStatus}
        shareOpportunitiesFetchStatus={shareOpportunitiesFetchStatus}
        onShareSubmit={onShareSubmit}
        handleResetShareOpportunitiesStatus={
          handleResetShareOpportunitiesStatus
        }
      />
    </>
  );
};

export default ForecastProfilePage;
