import React, { FunctionComponent } from "react";
import { Button, ButtonProps } from "primereact/button";
import ThemeContext, { defaultTheme } from "../configs/theme";

const buttonStyle = {
  fontWeight: "bold" as "bold", // Okay Typescript...
  margin: "5px",
  height: "40px",
  borderRadius: "65px",
  fontSize: "1em",
};

interface RadiusButtonProps extends ButtonProps {
  color?: string;
  style?: React.CSSProperties;
}

const PillButton: FunctionComponent<RadiusButtonProps> = ({
  color,
  style,
  ...props
}) => {
  const theme = React.useContext(ThemeContext);
  return (
    <Button
      style={{
        ...buttonStyle,
        backgroundColor: color || theme.v2.darkYellow,
        ...style,
      }}
      {...props}
    />
  );
};

export default PillButton;
