import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useMemo, useState } from "react";
import SimpleModal from "../../components/Modals/SimpleModal";
import Stack from "@mui/material/Stack";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Button from "@mui/material/Button";
import { parseRiskManagementConsentValue } from "./ecdUtils";

interface RiskManagementContentProps {
  value: string | null;
}

export default function RiskManagementContent({
  value,
}: RiskManagementContentProps) {
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);

  const parsedValue = useMemo(
    () => parseRiskManagementConsentValue(value),
    [value],
  );

  function onOpen() {
    setOpen(true);
  }

  function onClose() {
    setOpen(false);
  }

  return (
    <>
      <SimpleModal
        open={open}
        handleClose={onClose}
        title={"Climate Risk Management Assessment"}
        actions={
          <Box sx={{ pt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant={"secondaryContained"}
              onClick={onClose}
              size={"small"}
            >
              Close
            </Button>
          </Box>
        }
      >
        {parsedValue !== null ? (
          <Table sx={{ border: `solid 2px ${theme.palette.divider}` }}>
            <TableHead>
              <TableRow>
                <TableCell width={250}>Risk</TableCell>
                <TableCell
                  width={100}
                  sx={{ color: theme.palette.success.main }}
                >
                  Low
                </TableCell>
                <TableCell
                  width={100}
                  sx={{ color: theme.palette.warning.main }}
                >
                  Moderate
                </TableCell>
                <TableCell width={100} sx={{ color: theme.palette.error.main }}>
                  High
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ color: theme.palette.primary.main, fontWeight: 600 }}
                >
                  Identified
                </TableCell>
                {parsedValue?.identified?.map((val) => (
                  <TableCell key={val}>{val}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ color: theme.palette.primary.main, fontWeight: 600 }}
                >
                  Addressed
                </TableCell>
                {parsedValue?.addressed?.map((val) => (
                  <TableCell key={val}>{val}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 2,
              // p: 4,
            }}
          >
            <AccessTimeOutlinedIcon
              sx={{
                height: "48px !important",
                width: "48px !important",
                color: theme.palette.action.disabled,
              }}
            />
            <Typography variant={"body2"}>
              The climate risk management assesment for this project has not
              been indexed yet. Please be patient
            </Typography>
          </Box>
        )}
      </SimpleModal>
      <Button
        onClick={onOpen}
        sx={{
          px: 1,
          py: 0.5,
          position: "unset",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            gap: 1,
          }}
        >
          {parsedValue !== null ? (
            <VisibilityOutlinedIcon
              color={"primary"}
              sx={{
                height: "18px !important",
                width: "18px !important",
              }}
            />
          ) : (
            <AccessTimeOutlinedIcon
              color={"warning"}
              sx={{
                height: "18px !important",
                width: "18px !important",
                mb: "2px",
              }}
            />
          )}
          <Typography
            variant={"body2"}
            sx={{
              color:
                parsedValue !== null
                  ? theme.palette.primary.main
                  : theme.palette.warning.main,
            }}
          >
            {parsedValue !== null ? "View" : "Pending"}
          </Typography>
        </Stack>
      </Button>
    </>
  );
}
