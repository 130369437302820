import React from "react";

export const ShowMoreText: React.FunctionComponent<{
  text: string;
  isFree?: boolean;
  charCount?: number;
}> = ({ text, isFree, charCount }) => {
  if (isFree) {
    // remove fixed color or replace it with transparent
    text = text.replace(/color: rgb/g, "");
    text = text.replace(/color: #/g, "");
    text = text.replace(/style="color: /g, "");
    text = text.replace(/<h1/g, "<h1 style='color:transparent' ");
    text = text.replace(/<h2/g, "<h2 style='color:transparent' ");
    text = text.replace(/<h3/g, "<h3 style='color:transparent' ");
   
  }
  const characterCount = charCount ?? 150;
  let minLength =
    text.length - 1 > characterCount ? characterCount : text.length - 1;

  // if there is an <a href> in the min text that would show, show 100 more characters to be safe
  const ahrefLocation = text.indexOf("a href");
  const closingTag = text.indexOf(">", ahrefLocation);
  if (
    ahrefLocation > -1 &&
    ahrefLocation < minLength &&
    closingTag + 150 < text.length
  ) {
    minLength = closingTag + 150;
  }

  const [truncated, setTruncated] = React.useState(true);
  const [tempText, setTempText] = React.useState(text.substring(0, minLength));

  const showMessage = text.length > characterCount;

  React.useEffect(() => {
    const length = truncated ? minLength : text.length;
    setTempText(text.substr(0, length));
  }, [truncated, minLength]);

  const more = "View Details";
  const less = "Hide Details";
  return (
    <div className={isFree ? "links-blur" : "update-cont"}>
      <div>
        <span dangerouslySetInnerHTML={{ __html: tempText }}></span>
      </div>
      <br />
      {showMessage && (
        <div className="view-details">
          {truncated && (
            <a
              href="javscript:void(0);"
              onClick={() => (!isFree ? setTruncated(false) : null)}
            >
              {more}
            </a>
          )}
          {!truncated && (
            <a
              href="javscript:void(0);"
              onClick={() => (!isFree ? setTruncated(true) : null)}
            >
              {less}
            </a>
          )}
        </div>
      )}
    </div>
  );
};
