import React, { FC } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface SortButtonProps {
  sortOrder: number;
  onClick: () => void;
  disabled?: boolean;
}

const SortButton: FC<SortButtonProps> = ({
  sortOrder,
  onClick,
  disabled = false,
}) => {
  const theme = useTheme();

  return (
    <IconButton
      sx={{
        width: "24px",
        height: "24px",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          "> .MuiSvgIcon-root": {
            fontSize: "24px !important",
          },
        }}
      >
        <ArrowDropUpIcon
          sx={{
            mb: "-9px",
            ...(sortOrder === 1
              ? { color: theme.palette.text.secondary }
              : { color: theme.palette.action.disabled }),
          }}
        />
        <ArrowDropDownIcon
          sx={{
            mt: "-9px",
            ...(sortOrder === -1
              ? { color: theme.palette.text.secondary }
              : { color: theme.palette.action.disabled }),
          }}
        />
      </Box>
    </IconButton>
  );
};

export default SortButton;
