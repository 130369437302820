import { Box, Divider, Typography } from "@mui/material";
import { ECDRecord, resetECDRecord } from "../../slices/ecd";
import { useDispatch } from "../../store";
import { useContext, useState } from "react";
import UserContext from "../../services/UserContext";
import Button from "@mui/material/Button";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import SimpleModal from "../../components/Modals/SimpleModal";

interface EditECDRowContentProps {
  record: ECDRecord | null;
  resetFetchStatus: string;
}

export default function EditECDRowContent({
  record,
  resetFetchStatus,
}: EditECDRowContentProps) {
  const dispatch = useDispatch();
  const context = useContext(UserContext);

  const [confirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false);

  function openConfirmationModal() {
    setConfirmationModalOpen(true);
  }

  function closeConfirmationModal() {
    setConfirmationModalOpen(false);
  }

  function resetRecord() {
    if (!record) return;
    closeConfirmationModal();
    dispatch(resetECDRecord({ context, id: record.id }));
  }

  return (
    <Box>
      <SimpleModal
        open={confirmationModalOpen}
        handleClose={closeConfirmationModal}
        title={"Confirm reset"}
        handleAccept={resetRecord}
        acceptLabel={"Reset"}
        acceptButtonColor={"error"}
        maxWidth={"400px"}
      >
        Are you sure you want to reset this record to it's original values? All
        of the edits made to any of the fields will be discarded.
      </SimpleModal>
      <Typography variant={"h6"} color={"inherit"}>
        Reset record to original values
      </Typography>
      <Typography variant={"subtitle2"} color={"textSecondary"}>
        This action will reset the values of this record to it's original
        values. That means all of the edits made to any of the fields will be
        discarded.
      </Typography>
      <Divider sx={{ mb: 1 }} />
      <Button
        onClick={openConfirmationModal}
        variant={"contained"}
        color={"error"}
        disabled={RequestStatus.isFetching(resetFetchStatus)}
      >
        Reset record
      </Button>
    </Box>
  );
}
