import axios from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../configs/axios-export.custom";
import { UserContextType } from "../services/UserContext";
import { RequestStatus } from "../utils/Helpers/fetchStatus";
import { ShareUser } from "../screens/Forecast/Forecast";
import { buildQuery } from "../utils/Helpers/queryBuilder";

interface USASpendingState {
  items: Array<any>;
  fetchStatus: string;
  total: number;
  lastUpdatedUtc: string | null;
  dropdownOptions: {
    country: Array<{ value: string; label: string }>;
    implementor: Array<{ value: string; label: string }>;
    mechanism: Array<{ value: string; label: string }>;
    naics: Array<{ value: string; label: string }>;
    setAside: Array<{ value: string; label: string }>;
    subawards: Array<{ value: string; label: string }>;
    fetchStatus: string;
  };
  addToMyList: {
    fetchStatus: string;
  };
  removeFromMyList: {
    fetchStatus: string;
  };
}

export interface UsaSpendingFilters {
  isMyList?: boolean;
  pageIndex: number;
  pageSize: number;
  sortField?: string;
  sortOrder?: number;
  country?: Array<string>;
  implementer?: Array<string>;
  projectNumber?: string;
  mechanism?: Array<string>;
  naics?: Array<string>;
  setAside?: Array<string>;
  keyword?: string;
  dateStartFrom?: string;
  dateStartTo?: string;
  dateCloseFrom?: string;
  dateCloseTo?: string;
  minimumAmount?: number;
  subawardRecipient?: Array<string>;
  projectName?: string;
  projectDescription?: string;
}

const initialState: USASpendingState = Object.freeze({
  items: [],
  fetchStatus: RequestStatus.statuses.NULL,
  total: 0,
  lastUpdatedUtc: null,
  dropdownOptions: {
    country: [],
    implementor: [],
    mechanism: [],
    naics: [],
    setAside: [],
    subawards: [],
    fetchStatus: RequestStatus.statuses.NULL,
  },
  addToMyList: {
    fetchStatus: RequestStatus.statuses.NULL,
  },
  removeFromMyList: {
    fetchStatus: RequestStatus.statuses.NULL,
  },
});

export const getUsaSpending: any = createAsyncThunk(
  "usaSpending/getUsaSpending",
  async (
    data: {
      context: UserContextType;
      params: UsaSpendingFilters;
    },
    thunkAPI,
  ) => {
    const { context, params } = data;
    const response = await axios.get(
      getFullUrl(`/api/usaspending${buildQuery({ ...params }, "|")}`, {
        useDedicatedEnvironment: true,
      }),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

export const getDropdownOptionsForUSASpending: any = createAsyncThunk(
  "usaSpending/getDropdownOptionsForUSASpending",
  async (context: UserContextType, thunkAPI) => {
    const responses = await Promise.all([
      axios.get(
        getFullUrl(`/api/UsaSpending/dropdownoptions/country`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
      axios.get(
        getFullUrl(`/api/UsaSpending/dropdownoptions/implementor`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
      axios.get(
        getFullUrl(`/api/UsaSpending/dropdownoptions/mechanism`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
      axios.get(
        getFullUrl(`/api/UsaSpending/dropdownoptions/naics`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
      axios.get(
        getFullUrl(`/api/UsaSpending/dropdownoptions/setAside`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
      axios.get(
        getFullUrl(`/api/UsaSpending/dropdownoptions/subawards`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
    ]);

    return responses.map((response) => response.data);
  },
);

export const addToMyList: any = createAsyncThunk(
  "usaSpending/addToMyList",
  async (
    data: {
      context: UserContextType;
      uniqueAwardId: number;
    },
    thunkAPI,
  ) => {
    const result = await axios.post(
      getFullUrl("/api/usaspending/mylist", {
        useDedicatedEnvironment: true,
      }),
      { uniqueAwardId: data.uniqueAwardId },
      createRequestWithAuthHeaders(data.context),
    );

    return result.data;
  },
);

export const removeFromMyList: any = createAsyncThunk(
  "usaSpending/removeFromMyList",
  async (
    data: {
      context: UserContextType;
      uniqueAwardId: number;
    },
    thunkAPI,
  ) => {
    const result = await axios.delete(
      getFullUrl("/api/usaspending/mylist", {
        useDedicatedEnvironment: true,
      }),
      {
        ...createRequestWithAuthHeaders(data.context),
        data: { uniqueAwardId: data.uniqueAwardId },
      },
    );

    return result.data;
  },
);

const slice = createSlice({
  name: "usaSpending",
  initialState,
  reducers: {
    resetUsaSpendingItems(state: USASpendingState) {
      state.items = initialState.items;
      state.total = initialState.total;
      state.fetchStatus = initialState.fetchStatus;
      state.lastUpdatedUtc = initialState.lastUpdatedUtc;
    },
    resetChangeListStatus(state: USASpendingState) {
      state.addToMyList = initialState.addToMyList;
      state.removeFromMyList = initialState.removeFromMyList;
    },
  },
  extraReducers: {
    [getUsaSpending.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getUsaSpending.fulfilled]: (state, action: PayloadAction<any>) => {
      const { attributes, data, lastUpdatedUtc, totalItems } = action.payload;
      state.items = data;
      state.total = totalItems;
      state.lastUpdatedUtc = lastUpdatedUtc;
      state.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getUsaSpending.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [getDropdownOptionsForUSASpending.pending]: (state, action) => {
      state.dropdownOptions.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getDropdownOptionsForUSASpending.fulfilled]: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions = {
        country: action.payload[0]?.map((v) => ({ value: v, label: v })),
        implementor: action.payload[1]?.map((v) => ({
          value: v,
          label: v,
        })),
        mechanism: action.payload[2]?.map((v) => ({
          value: v?.key,
          label: v?.value,
        })),
        naics: action.payload[3]?.map((v) => ({ value: v, label: v })),
        setAside: action.payload[4]?.map((v) => ({ value: v, label: v })),
        subawards: action.payload[5]?.map((v) => ({
          value: v,
          label: v,
        })),
        fetchStatus: RequestStatus.statuses.DONE,
      };
    },
    [getDropdownOptionsForUSASpending.rejected]: (state, action) => {
      state.dropdownOptions.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [addToMyList.pending]: (state, action) => {
      state.addToMyList.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [addToMyList.fulfilled]: (state, action: PayloadAction<any>) => {
      state.addToMyList.fetchStatus = RequestStatus.statuses.DONE;
    },
    [addToMyList.rejected]: (state, action) => {
      state.addToMyList.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [removeFromMyList.pending]: (state, action) => {
      state.removeFromMyList.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [removeFromMyList.fulfilled]: (state, action: PayloadAction<any>) => {
      state.removeFromMyList.fetchStatus = RequestStatus.statuses.DONE;
    },
    [removeFromMyList.rejected]: (state, action) => {
      state.removeFromMyList.fetchStatus = RequestStatus.statuses.ERROR;
    },
  },
});

export const { reducer } = slice;

export const { resetUsaSpendingItems, resetChangeListStatus } = slice.actions;
export default slice;
