import {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import "./ShowMoreWhenHover.css";

interface showMoreWhenHoverProps {
  showMoreInPopup: (data: string, show: boolean, top: any, left: any) => void;
  text: string;
}
const ShowMoreWhenHover: FunctionComponent<showMoreWhenHoverProps> = ({
  showMoreInPopup,
  text,
}) => {
  const forecastElement = useRef(null);
  const [top, setTop] = useState(-1);
  const [left, setLeft] = useState(-1);

  const calculateOffset = (text: any) => {
    var forecastEl = ReactDOM.findDOMNode(forecastElement.current) as Element;
    if (forecastEl) {
      const elementRect = forecastEl.getBoundingClientRect();
      setLeft(elementRect.left);
      setTop(elementRect.top);
      showMoreInPopup(text, true, elementRect.top, elementRect.left);
    }
  };

  return (
    <>
      <span
        ref={forecastElement}
        onMouseOver={() => calculateOffset(text)}
        onMouseLeave={() => showMoreInPopup("", false, top, left)}
      >
        {text?.length < 64 ? text : `${text?.substring(0, 64)} ...`}
      </span>
    </>
  );
};

export default ShowMoreWhenHover;
