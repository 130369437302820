import { createSvgIcon } from "@mui/material/utils";

export const OpenInNewIcon = createSvgIcon(
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.25 6.2625C10.0842 6.2625 9.92527 6.32835 9.80806 6.44556C9.69085 6.56277 9.625 6.72174 9.625 6.8875V11.375C9.625 11.5408 9.55915 11.6997 9.44194 11.8169C9.32473 11.9342 9.16576 12 9 12H2.125C1.95924 12 1.80027 11.9342 1.68306 11.8169C1.56585 11.6997 1.5 11.5408 1.5 11.375V4.5C1.5 4.33424 1.56585 4.17527 1.68306 4.05806C1.80027 3.94085 1.95924 3.875 2.125 3.875H6.6125C6.77826 3.875 6.93723 3.80915 7.05444 3.69194C7.17165 3.57473 7.2375 3.41576 7.2375 3.25C7.2375 3.08424 7.17165 2.92527 7.05444 2.80806C6.93723 2.69085 6.77826 2.625 6.6125 2.625H2.125C1.62772 2.625 1.15081 2.82254 0.799175 3.17417C0.447544 3.52581 0.25 4.00272 0.25 4.5V11.375C0.25 11.8723 0.447544 12.3492 0.799175 12.7008C1.15081 13.0525 1.62772 13.25 2.125 13.25H9C9.49728 13.25 9.97419 13.0525 10.3258 12.7008C10.6775 12.3492 10.875 11.8723 10.875 11.375V6.8875C10.875 6.72174 10.8092 6.56277 10.6919 6.44556C10.5747 6.32835 10.4158 6.2625 10.25 6.2625ZM12.7 1.1375C12.6366 0.984782 12.5152 0.863422 12.3625 0.8C12.2874 0.767974 12.2067 0.750987 12.125 0.75H8.375C8.20924 0.75 8.05027 0.815848 7.93306 0.933058C7.81585 1.05027 7.75 1.20924 7.75 1.375C7.75 1.54076 7.81585 1.69973 7.93306 1.81694C8.05027 1.93415 8.20924 2 8.375 2H10.6187L4.18125 8.43125C4.12267 8.48935 4.07617 8.55848 4.04444 8.63464C4.01271 8.7108 3.99638 8.79249 3.99638 8.875C3.99638 8.95751 4.01271 9.0392 4.04444 9.11536C4.07617 9.19152 4.12267 9.26065 4.18125 9.31875C4.23935 9.37733 4.30848 9.42383 4.38464 9.45556C4.4608 9.48729 4.54249 9.50362 4.625 9.50362C4.70751 9.50362 4.7892 9.48729 4.86536 9.45556C4.94152 9.42383 5.01065 9.37733 5.06875 9.31875L11.5 2.88125V5.125C11.5 5.29076 11.5658 5.44973 11.6831 5.56694C11.8003 5.68415 11.9592 5.75 12.125 5.75C12.2908 5.75 12.4497 5.68415 12.5669 5.56694C12.6842 5.44973 12.75 5.29076 12.75 5.125V1.375C12.749 1.29333 12.732 1.21264 12.7 1.1375Z"
      fill="#828282"
    />
  </svg>,
  "OpenInNew",
);
