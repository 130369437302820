import { createSvgIcon } from "@mui/material/utils";

export const ThumbsDownIcon = createSvgIcon(
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.6667 0.333313H3.17998C2.71211 0.333469 2.2591 0.497652 1.89977 0.797297C1.54044 1.09694 1.29754 1.51308 1.21332 1.97331L0.366651 6.63998C0.313897 6.92827 0.325148 7.22464 0.399608 7.5081C0.474068 7.79156 0.609917 8.0552 0.797542 8.28035C0.985167 8.5055 1.21998 8.68666 1.48537 8.81102C1.75076 8.93537 2.04024 8.99988 2.33332 8.99998H5.37332L4.99998 9.95331C4.8447 10.3707 4.79298 10.8196 4.84928 11.2614C4.90557 11.7032 5.06819 12.1247 5.32319 12.4898C5.5782 12.855 5.91797 13.1528 6.31336 13.3578C6.70875 13.5627 7.14796 13.6687 7.59332 13.6666C7.72156 13.6664 7.847 13.6291 7.9546 13.5594C8.06221 13.4896 8.14741 13.3903 8.19999 13.2733L10.1 8.99998H11.6667C12.1971 8.99998 12.7058 8.78927 13.0809 8.41419C13.4559 8.03912 13.6667 7.53041 13.6667 6.99998V2.33331C13.6667 1.80288 13.4559 1.29417 13.0809 0.9191C12.7058 0.544027 12.1971 0.333313 11.6667 0.333313ZM8.99998 8.19331L7.18665 12.2733C7.00111 12.2172 6.82903 12.1236 6.68101 11.9985C6.53299 11.8733 6.41216 11.7192 6.32597 11.5455C6.23978 11.3719 6.19006 11.1825 6.17985 10.9889C6.16965 10.7953 6.19919 10.6017 6.26665 10.42L6.61999 9.46665C6.69527 9.26514 6.72069 9.04842 6.69407 8.83496C6.66745 8.6215 6.58957 8.41766 6.4671 8.24082C6.34462 8.06398 6.18118 7.9194 5.99071 7.81942C5.80025 7.71944 5.58843 7.66702 5.37332 7.66665H2.33332C2.23538 7.66681 2.13861 7.64538 2.04988 7.6039C1.96116 7.56242 1.88267 7.5019 1.81998 7.42665C1.75576 7.35243 1.70872 7.26495 1.68221 7.17045C1.65571 7.07595 1.65039 6.97677 1.66665 6.87998L2.51332 2.21331C2.54172 2.05801 2.62431 1.91782 2.74639 1.81771C2.86848 1.7176 3.02213 1.66407 3.17998 1.66665H8.99998V8.19331ZM12.3333 6.99998C12.3333 7.17679 12.2631 7.34636 12.1381 7.47138C12.013 7.59641 11.8435 7.66665 11.6667 7.66665H10.3333V1.66665H11.6667C11.8435 1.66665 12.013 1.73688 12.1381 1.86191C12.2631 1.98693 12.3333 2.1565 12.3333 2.33331V6.99998Z" />
  </svg>,
  "ThumbsDown",
);
