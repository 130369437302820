import axios from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../configs/axios-export.custom";
import { UserContextType } from "../services/UserContext";
import { RequestStatus } from "../utils/Helpers/fetchStatus";
import { buildQuery } from "../utils/Helpers/queryBuilder";
import { ColumnsSettingsData } from "../components/MaterialTable/Table";

interface UserSettingsState {
  tableSettings: {
    columnsOrder: Array<ColumnsSettingsData>;
    fetchStatus: string;
    patchFetchStatus: string;
    deleteFetchStatus: string;
  };
  userSettings: {
    setting: Record<string, string | number>;
    fetchStatus: string;
    patchFetchStatus: string;
    deleteFetchStatus: string;
  };
}

const initialState: UserSettingsState = Object.freeze({
  tableSettings: {
    columnsOrder: [],
    fetchStatus: RequestStatus.statuses.NULL,
    patchFetchStatus: RequestStatus.statuses.NULL,
    deleteFetchStatus: RequestStatus.statuses.NULL,
  },
  userSettings: {
    setting: {},
    fetchStatus: RequestStatus.statuses.NULL,
    patchFetchStatus: RequestStatus.statuses.NULL,
    deleteFetchStatus: RequestStatus.statuses.NULL,
  },
});

export const getTableSettings: any = createAsyncThunk(
  "userSettings/getTableSettings",
  async (data: { context: UserContextType; key: string }, thunkAPI) => {
    const { context, key } = data;
    const response = await axios.get(
      getFullUrl(`/api/UserSetting${buildQuery({ key })}`, {
        useDedicatedEnvironment: true,
      }),
      createRequestWithAuthHeaders(context),
    );

    return { data: response?.data ?? [], key };
  },
);

export const patchTableSettings: any = createAsyncThunk(
  "userSettings/patchTableSettings",
  async (
    data: {
      context: UserContextType;
      key: string;
      configuration: { [key: string]: Array<ColumnsSettingsData> };
    },
    thunkAPI,
  ) => {
    const { context, key, configuration } = data;
    const response = await axios.patch(
      getFullUrl(`/api/UserSetting/${key}`, {
        useDedicatedEnvironment: true,
      }),
      configuration,
      createRequestWithAuthHeaders(context),
    );

    return response?.data ?? [];
  },
);

export const deleteTableSettings: any = createAsyncThunk(
  "userSettings/deleteTableSettings",
  async (data: { context: UserContextType; key: string }, thunkAPI) => {
    const { context, key } = data;
    return await axios.delete(
      getFullUrl(`/api/UserSetting/${key}`, {
        useDedicatedEnvironment: true,
      }),
      createRequestWithAuthHeaders(context),
    );
  },
);

export const getUserSetting: any = createAsyncThunk(
  "userSettings/getUserSetting",
  async (data: { context: UserContextType; key: string }, thunkAPI) => {
    const { context, key } = data;
    const response = await axios.get(
      getFullUrl(`/api/UserSetting${buildQuery({ key })}`, {
        useDedicatedEnvironment: true,
      }),
      createRequestWithAuthHeaders(context),
    );

    return { data: response?.data ?? [], key };
  },
);

export const patchUserSettings: any = createAsyncThunk(
  "userSettings/patchUserSettings",
  async (
    data: {
      context: UserContextType;
      key: string;
      setting: Record<string, string | number>;
    },
    thunkAPI,
  ) => {
    const { context, key, setting } = data;
    const response = await axios.patch(
      getFullUrl(`/api/UserSetting/${key}`, {
        useDedicatedEnvironment: true,
      }),
      setting,
      createRequestWithAuthHeaders(context),
    );

    return response?.data ?? [];
  },
);

export const deleteUserSettings: any = createAsyncThunk(
  "userSettings/deleteUserSettings",
  async (data: { context: UserContextType; key: string }, thunkAPI) => {
    const { context, key } = data;
    return await axios.delete(
      getFullUrl(`/api/UserSetting/${key}`, {
        useDedicatedEnvironment: true,
      }),
      createRequestWithAuthHeaders(context),
    );
  },
);

const slice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    resetTableSettings(state: UserSettingsState) {
      state.tableSettings = initialState.tableSettings;
    },
  },
  extraReducers: {
    [getTableSettings.pending]: (state, action) => {
      state.tableSettings.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getTableSettings.fulfilled]: (state, action: PayloadAction<any>) => {
      state.tableSettings.columnsOrder =
        action.payload.data.setting[action.payload.data.key];
      state.tableSettings.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getTableSettings.rejected]: (state, action) => {
      state.tableSettings.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [patchTableSettings.pending]: (state, action) => {
      state.tableSettings.patchFetchStatus = RequestStatus.statuses.FETCHING;
    },
    [patchTableSettings.fulfilled]: (state, action: PayloadAction<any>) => {
      state.tableSettings.patchFetchStatus = RequestStatus.statuses.DONE;
    },
    [patchTableSettings.rejected]: (state, action) => {
      state.tableSettings.patchFetchStatus = RequestStatus.statuses.ERROR;
    },

    [deleteTableSettings.pending]: (state, action) => {
      state.tableSettings.deleteFetchStatus = RequestStatus.statuses.FETCHING;
    },
    [deleteTableSettings.fulfilled]: (state, action: PayloadAction<any>) => {
      state.tableSettings.deleteFetchStatus = RequestStatus.statuses.DONE;
    },
    [deleteTableSettings.rejected]: (state, action) => {
      state.tableSettings.deleteFetchStatus = RequestStatus.statuses.ERROR;
    },

    [getUserSetting.pending]: (state, action) => {
      state.userSettings.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getUserSetting.fulfilled]: (state, action: PayloadAction<any>) => {
      state.userSettings.setting =
        action.payload.data.setting.animationShownFor;
      state.userSettings.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getUserSetting.rejected]: (state, action) => {
      state.userSettings.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [patchUserSettings.pending]: (state, action) => {
      state.userSettings.patchFetchStatus = RequestStatus.statuses.FETCHING;
    },
    [patchUserSettings.fulfilled]: (state, action: PayloadAction<any>) => {
      state.userSettings.patchFetchStatus = RequestStatus.statuses.DONE;
    },
    [patchUserSettings.rejected]: (state, action) => {
      state.userSettings.patchFetchStatus = RequestStatus.statuses.ERROR;
    },

    [deleteUserSettings.pending]: (state, action) => {
      state.userSettings.deleteFetchStatus = RequestStatus.statuses.FETCHING;
    },
    [deleteUserSettings.fulfilled]: (state, action: PayloadAction<any>) => {
      state.userSettings.deleteFetchStatus = RequestStatus.statuses.DONE;
    },
    [deleteUserSettings.rejected]: (state, action) => {
      state.userSettings.deleteFetchStatus = RequestStatus.statuses.ERROR;
    },
  },
});

export const { reducer } = slice;

export const { resetTableSettings } = slice.actions;
export default slice;
