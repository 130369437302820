import { useContext, useEffect } from "react";
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";
import UserContext, {
  doesUserHaveRole,
  ServerOrgRoles,
  UserRoleGroups,
} from "../../services/UserContext";
import NavBar, { Header } from "./Header";

import Authenticator from "../Authentication/Authenticator";
import Forecast from "../../screens/ForecastV2/ForecastV2";
import Pipeline from "../../screens/Pipelines/Pipeline";
import FAQ from "../../screens/FAQ/FAQ";
import FAQArticle from "../../screens/FAQ/FAQArticle/FAQArticle";
import LandingPage from "../Landing/Landing";
import ConfirmationEmail from "../Authentication/ConfirmationEmail";
import AdminScreen from "../../screens/AdminScreen/AdminScreen";
import Dashboard from "../../screens/Dashboard/Dashboard";
import DashboardOld from "../../screens/Dashboard";
import Data from "../../screens/Data/Data";
import PaymentScreen from "../../screens/PaymentScreen/PaymentScreen";
import SubscriptionsScreen from "../../screens/SubscriptionsScreen/SubscriptionsScreen";
import { MyItems } from "../../screens/MyItems/MyItems";
import UserManagementScreen from "../../screens/UserManagementScreen/UserManagementScreen";
import GrantsTableScreen from "../../screens/GrantsTableScreen/GrantsTableScreen";
import ContractsTableScreen from "../../screens/ContractTableScreen/ContractTableScreen";
import WeeklyForecast from "../../screens/WeeklyForecast/WeeklyForecast";
import USAidScreen from "../../screens/USAidScreen/USAid";
import UpgradePlan from "../../screens/UpgradePlan/UpgradePlan";
import AidKonektResources from "../../screens/AidKonektResources/AidKonektResources";
import Resources from "../../screens/Resources/Resources";
import Webinars from "../../screens/Webinars/Webinars";
import LCPArchives from "../../screens/LCPArchives/LCPArchives";
import IEEArchives from "../../screens/IEEArchives/IEEArchives";
import QAArchives from "../../screens/QAArchives/QAArchives";
import Footer from "../footer";
import useIsAuthenticated from "../../services/authCheck";
import { ProgressSpinner } from "primereact/progressspinner";
import ProjectDetails from "../../screens/ProjectDetails/ProjectDetails";
import UserDashboard from "../../screens/UserDashboard";
import UserMenu from "../Dashboard/DashboardManagement/Users/UserMenu";
import OrganizationDashboard from "../../screens/OrganizationDashboard";
import PipelineDetail from "../../screens/Pipelines/PipelineFragments/PipelineDetail";
import SubmitFeedback from "../SubmitFeedback";
import { Userpilot } from "userpilot";
import USAidDetails from "../../screens/USAidScreen/USAidDetails/USAidDetails";
import UserConfirmation from "../../components/Authentication/UserConfirmation";
import IDIQs from "../../screens/IDIQs/IDIQs";
import CDCSRepoList from "../../screens/CDCSRepository2/CDCSRepoList";
import PageTitle from "./PageTitle";
import Contracts from "../../screens/Contracts/Contracts";
import Grants from "../../screens/Grants/Grants";
import { Box } from "@mui/material";
import ForecastProfilePage from "../../screens/ForecastV2/ForecastProfilePage";
import ContractsProfilePage from "../../screens/Contracts/ContractsProfilePage";
import GrantsProfilePage from "../../screens/Grants/GrantsProfilePage";
import Projects from "../../screens/Projects/Projects";
import Reports from "../../screens/Reports/Reports";
import ECD from "../../screens/ECD/ECD";
function PrivateRouteComponent({ history, component: Component, children }) {
  const context = useContext(UserContext);
  const { isLoaded } = context;
  const [getIsAuthenticated] = useIsAuthenticated();
  const isAuthenticated = getIsAuthenticated();
  useEffect(() => {
    context.updateCurrentUser();
    const unlisten = history.listen(() => {
      context.updateCurrentUser();
    });
    return unlisten;
  }, []);

  return (
    <Route
      {...children}
      render={(props) => {
        if (!isLoaded) {
          return <ProgressSpinner />;
        }

        return isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/auth",
            }}
          />
        );
      }}
    />
  );
}

function RoleSpecificRouteComponent({
  history,
  component: Component,
  children,
  roles,
}) {
  const context = useContext(UserContext);
  const isRole = roles.find((r) => doesUserHaveRole(context, r)) ? true : false;
  const { isLoaded } = context;

  const [getIsAuthenticated] = useIsAuthenticated();
  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    context.updateCurrentUser();
    const unlisten = history.listen(() => {
      context.updateCurrentUser();
    });
    return unlisten;
  }, []);

  return (
    <Route
      {...children}
      render={(props) => {
        if (!isLoaded) {
          return <ProgressSpinner />;
        }

        if (!isAuthenticated) {
          return <Redirect to={{ pathname: "/auth" }} />;
        }

        return isRole ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/profile" }} />
        );
      }}
    />
  );
}

const RoleSpecificRoute = withRouter(RoleSpecificRouteComponent);
const PrivateRoute = withRouter(PrivateRouteComponent);

const BasicRouter = () => {
  const currentRoute = useLocation();
  const [getIsAuthenticated] = useIsAuthenticated();

  const context = useContext(UserContext);
  const { isLoaded } = context;

  const getIsLanding = () =>
    currentRoute.pathname.includes("/usaid-biz-dev") ||
    currentRoute.pathname === "/project-details" ||
    currentRoute.pathname.includes("/project-view");

  const isAuthenticated = getIsAuthenticated();
  const isLanding = getIsLanding();

  useEffect(() => {
    if (isAuthenticated) {
      Userpilot.reload();
    }
  }, [currentRoute.pathname]);

  const wrapperStyle = {
    main: !isLanding ? "p-grid main-container" : "",
    subMain: !isLanding
      ? currentRoute.pathname.includes("auth")
        ? "p-col p-grid p-justify-center content-block"
        : "p-col p-grid p-justify-center content-block main-back"
      : "",
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
      }}
      id={"main"}
    >
      {!isLanding && isAuthenticated && (
        <div className="p-grid">
          <div
            data-html2canvas-ignore="true"
            className="p-col-fixed"
            style={{
              textAlign: "left",
              width: "230px",
              backgroundColor: "white",
              boxShadow: "0px 4px 12px rgb(0 0 0 / 5%)",
              display: "flex",
              flexDirection: "column",
              position: "fixed",
              height: "100%",
            }}
          >
            <Header />
            <NavBar />
          </div>
        </div>
      )}
      <div
        // className={wrapperStyle.main}
        id={"content"}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          ...(!isLanding && { padding: "0 20px" }),
          ...(!isLanding &&
            !isAuthenticated && {
              alignItems: "center",
              height: "100%",
              paddingTop: "10vh",
            }),
          ...(!isLanding &&
            isAuthenticated && {
              paddingLeft: "calc(230px + 20px)",
            }),
        }}
      >
        {!isLanding && isAuthenticated && (
          <div
            id={"header"}
            className={
              currentRoute.pathname.includes("auth")
                ? "p-col p-grid"
                : "p-col p-grid main-back"
            }
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              flexGrow: 0,
            }}
          >
            {/* To do: return another item instead of empty div */}
            <Box
              sx={{
                display: "flex",
                gap: "24px",
                width: "100%",
                justifyContent: "space-between",
                mt: 2,
                mb: 1,
              }}
            >
              {isAuthenticated ? <PageTitle /> : <div></div>}
              {isAuthenticated ? <UserMenu /> : <div></div>}
            </Box>
          </div>
        )}
        <Box
          sx={{
            height: "100%",
            display: "flex",
          }}
          id={"content-box"}
        >
          <Switch>
            {isAuthenticated ? (
              <Route
                path="/auth"
                exact
                render={() => <Redirect to={{ pathname: "/" }} />}
              />
            ) : (
              <Route path="/auth" exact component={Authenticator} />
            )}
            <Route path="/usaid-biz-dev" exact component={LandingPage} />
            <Route
              path="/usaid-biz-dev/aidex-brussels"
              exact
              component={LandingPage}
            />
            <Route
              path="/usaid-biz-dev/germany"
              exact
              component={LandingPage}
            />
            <Route path="/verify-email" exact component={ConfirmationEmail} />
            <PrivateRoute path="/weeklyreport" component={WeeklyForecast} />
            <PrivateRoute path="/" exact component={Dashboard} />
            {/*<PrivateRoute path="/" exact component={DashboardOld} />*/}
            <PrivateRoute
              path="/dashboard-new-w68f5es4"
              exact
              component={Dashboard}
            />
            <RoleSpecificRoute
              path="/admin-panel"
              exact
              roles={[ServerOrgRoles.admin]}
              component={AdminScreen}
            />
            <PrivateRoute path="/pipeline" exact component={Pipeline} />
            <PrivateRoute
              path="/aidkonekt-resources"
              exact
              component={AidKonektResources}
            />
            <RoleSpecificRoute
              path="/resources"
              exact
              component={Resources}
              roles={UserRoleGroups.proOrHigher}
            />
            <PrivateRoute path="/webinars" exact component={Webinars} />
            <PrivateRoute
              path="/userdashboard"
              exact
              component={UserDashboard}
            />

            <PrivateRoute
              path="/cdcs-repository"
              exact
              component={CDCSRepoList}
            />
            <RoleSpecificRoute
              path="/lcp-archives"
              exact
              component={LCPArchives}
              roles={UserRoleGroups.proOrHigher}
            />
            <RoleSpecificRoute
              path="/iee-archives"
              exact
              component={IEEArchives}
              roles={UserRoleGroups.proOrHigher}
            />
            <PrivateRoute path="/qa-archives" exact component={QAArchives} />
            {/*<PrivateRoute path="/faq" exact component={FAQ} />*/}
            {/*<PrivateRoute path="/faq/" component={FAQArticle} />*/}
            {/*<RoleSpecificRoute*/}
            {/*  path="/decdata"*/}
            {/*  component={Data}*/}
            {/*  roles={UserRoleGroups.proOrHigher}*/}
            {/*/>*/}
            <RoleSpecificRoute
              path="/reports"
              component={Reports}
              roles={UserRoleGroups.proOrHigher}
            />
            <RoleSpecificRoute
              path="/payment"
              component={PaymentScreen}
              roles={UserRoleGroups.plusOrHigher}
            />
            <RoleSpecificRoute
              path="/subscriptions"
              component={SubscriptionsScreen}
              roles={UserRoleGroups.plusOrHigher}
            />
            <RoleSpecificRoute
              path="/mybids"
              component={MyItems}
              roles={UserRoleGroups.plusOrHigher}
            />

            <RoleSpecificRoute
              path="/users"
              roles={[ServerOrgRoles.admin]}
              component={UserManagementScreen}
            />
            <RoleSpecificRoute
              path="/forecast"
              roles={UserRoleGroups.plusOrHigher}
              component={Forecast}
              exact
            />
            <RoleSpecificRoute
              path="/grants"
              roles={UserRoleGroups.plusOrHigher}
              component={Grants}
              exact
            />
            <RoleSpecificRoute
              path="/contracts"
              roles={UserRoleGroups.plusOrHigher}
              component={Contracts}
              exact
            />
            {/*<RoleSpecificRoute*/}
            {/*  path="/projects"*/}
            {/*  roles={UserRoleGroups.proOrHigher}*/}
            {/*  component={USAidScreen}*/}
            {/*/>*/}
            <RoleSpecificRoute
              path="/projects"
              roles={UserRoleGroups.proOrHigher}
              component={Projects}
            />
            <RoleSpecificRoute
              path="/project-details"
              roles={UserRoleGroups.proOrHigher}
              component={ProjectDetails}
            />
            <RoleSpecificRoute
              path="/ecd"
              roles={UserRoleGroups.proOrHigher}
              component={ECD}
            />

            <RoleSpecificRoute
              path="/organization"
              roles={[ServerOrgRoles.organizationAdmin]}
              component={OrganizationDashboard}
            />

            <RoleSpecificRoute
              path="/pipeline/:id"
              roles={UserRoleGroups.organizationAdmins}
              component={PipelineDetail}
            />

            <RoleSpecificRoute
              path="/forecast/:record"
              roles={UserRoleGroups.plusOrHigher}
              component={ForecastProfilePage}
            />

            <RoleSpecificRoute
              path="/contracts/:record"
              roles={UserRoleGroups.plusOrHigher}
              component={ContractsProfilePage}
            />

            <RoleSpecificRoute
              path="/grants/:record"
              roles={UserRoleGroups.plusOrHigher}
              component={GrantsProfilePage}
            />

            <RoleSpecificRoute
              path="/project-view/:record"
              roles={UserRoleGroups.organizationAdmins}
              component={USAidDetails}
            />
            <PrivateRoute
              path="/confirmation/:email"
              component={UserConfirmation}
            />

            <PrivateRoute path="/tiers" component={UpgradePlan} />
            <PrivateRoute path="/idiqs" component={IDIQs} />
            <Route render={() => <Redirect to={{ pathname: "/" }} />} />
          </Switch>
        </Box>
      </div>
      {/*{!isLanding && <Footer />}*/}
      {/* {!isLanding && <SubmitFeedback />} */}
      {/* {isAuthenticated && !isLanding && <StartedGuide />} */}
    </div>
  );
};

const Routes = ({ currentUser }) => {
  return (
    <Router>
      <Switch>
        {/* <Route path="/weeklyreport" render={(props) => <WeeklyForecast {...props} />} /> */}
        <Route component={BasicRouter} />
      </Switch>
    </Router>
  );
};

export default Routes;
