import ReactSelect, { components } from "react-select";
import { InputText } from "primereact/inputtext";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React, { ChangeEvent, Dispatch, FC, SetStateAction } from "react";
import { useGridStyles } from "../../hooks/useGridStyles";
import { IDIQPaginationType } from "./IDIQTable";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Button from "@mui/material/Button";
import { getEpochDateWithoutTimezone } from "../../utils/conversion/date-converters";

interface IDIQFiltersProps {
  loading: boolean;
  pagination: IDIQPaginationType;
  setPagination: Dispatch<SetStateAction<IDIQPaginationType>>;
  vendorName: string;
  handleVendorNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  projectNumber: string;
  handleProjectNumberChange: (event: ChangeEvent<HTMLInputElement>) => void;
  getIDIQData: () => void;
}

const icons = {
  section: require("../../assets/icons/v2/Funding_Account_icon.png")?.default,
  keyword: require("../../assets/icons/v2/icon-06.png")?.default,
  calendar: require("../../assets/icons/v2/icon_calendar.png")?.default,
};

const IDIQTypesOptions = [
  {
    value: "",
    label: "Search all IDIQ, LWA, and BPA data",
  },
  {
    value: "Idiq",
    label: "IDIQ",
  },
  {
    value: "Lwa",
    label: "LWA",
  },
  {
    value: "BPA",
    label: "BPA",
  },
];

const IDIQFilters: FC<IDIQFiltersProps> = (props) => {
  const {
    loading,
    pagination,
    setPagination,
    vendorName,
    handleVendorNameChange,
    projectNumber,
    handleProjectNumberChange,
    getIDIQData,
  } = props;

  const styles = useGridStyles();

  const { ValueContainer, Placeholder } = components;

  const handleSetDate = (date: MaterialUiPickersDate, key: string): void => {
    setPagination((prev) => ({
      ...prev,
      [key]: getEpochDateWithoutTimezone(date),
    }));
  };

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      //@ts-ignore
      <ValueContainer {...props}>
        {/*@ts-ignore*/}
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null,
        )}
      </ValueContainer>
    );
  };

  return (
    <div>
      <div
        className="p-grid p-align-center"
        style={styles.filters.notTopFilterRow}
      >
        <div className="p-col-fixed icon-col">
          <img src={icons.section} alt="map" style={styles.icon} />
        </div>
        <div className="p-col" style={{ textAlign: "left", display: "block" }}>
          <ReactSelect
            components={{
              ValueContainer: CustomValueContainer,
            }}
            isDisabled={loading}
            placeholder="IDIQ Type"
            isMulti={false}
            onChange={(opt) => {
              let newVal = opt?.value ?? "";
              setPagination((prev) => ({
                ...prev,
                page_index: 0,
                first: 0,
                idiq_types: newVal,
              }));
            }}
            options={IDIQTypesOptions}
            defaultValue={[
              IDIQTypesOptions.find(
                (option) => option.value === pagination.idiq_types,
              ),
              true,
            ]}
            styles={{
              container: (provided) => ({
                ...provided,
                marginTop: "8px",
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                overflow: "visible",
              }),
              placeholder: (provided, state) => ({
                ...provided,
                position: "absolute",
                top:
                  state.hasValue || state.selectProps.inputValue ? -15 : "50%",
                transition: "top 0.1s, font-size 0.1s",
                fontSize:
                  (state.hasValue || state.selectProps.inputValue) && 13,
              }),
            }}
          />
        </div>
      </div>
      <div
        className="p-grid p-align-center"
        style={styles.filters.notTopFilterRow}
      >
        <div className="p-col-fixed icon-col">
          <img src={icons.keyword} alt="search" style={styles.icon} />
        </div>
        <div className="p-col" style={{ textAlign: "left" }}>
          <InputText
            style={styles.filters.inputBox}
            id="search"
            type="search"
            value={projectNumber}
            onChange={handleProjectNumberChange}
            placeholder="Search by Project Number"
            disabled={loading}
            label={"Search by Project Number"}
          />
        </div>
      </div>
      <div
        className="p-grid p-align-center"
        style={styles.filters.notTopFilterRow}
      >
        <div className="p-col-fixed icon-col">
          <img src={icons.keyword} alt="search" style={styles.icon} />
        </div>
        <div className="p-col" style={{ textAlign: "left" }}>
          <InputText
            style={styles.filters.inputBox}
            id="search"
            type="search"
            value={vendorName}
            onChange={handleVendorNameChange}
            placeholder="Search by Prime holder / vendor name"
            disabled={loading}
          />
        </div>
      </div>

      <div
        className="p-grid p-align-center"
        style={styles.filters.notTopFilterRow}
      >
        <div className="p-col-fixed icon-col">
          <img src={icons.calendar} alt="map" style={styles.icon} />
        </div>
        <div className="p-col" style={{ textAlign: "left", display: "flex" }}>
          <label
            style={{
              alignSelf: "center",
              ...styles.filters.inputTextLabel,
            }}
          >
            Project start date between{" "}
          </label>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            value={pagination.project_start_after}
            onError={(e) => {}}
            onChange={(date) => handleSetDate(date, "project_start_after")}
            style={{ marginLeft: 10 }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            disabled={loading}
          />
          <label
            style={{
              alignSelf: "center",
              ...styles.filters.inputTextLabel,
            }}
          >
            {" "}
            and{" "}
          </label>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            value={pagination.project_start_before}
            onError={(e) => {}}
            onChange={(date) => handleSetDate(date, "project_start_before")}
            style={{ marginLeft: 10 }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            disabled={loading}
          />
        </div>
      </div>

      <div
        className="p-grid p-align-center"
        style={styles.filters.notTopFilterRow}
      >
        <div className="p-col-fixed icon-col">
          <img src={icons.calendar} alt="map" style={styles.icon} />
        </div>
        <div className="p-col" style={{ textAlign: "left", display: "flex" }}>
          <label
            style={{
              alignSelf: "center",
              ...styles.filters.inputTextLabel,
            }}
          >
            Project end date between{" "}
          </label>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            value={pagination.project_end_after}
            onError={(e) => {}}
            onChange={(date) => handleSetDate(date, "project_end_after")}
            style={{ marginLeft: 10 }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            disabled={loading}
          />
          <label
            style={{
              alignSelf: "center",
              ...styles.filters.inputTextLabel,
            }}
          >
            {" "}
            and{" "}
          </label>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            value={pagination.project_end_before}
            onError={(e) => {}}
            onChange={(date) => handleSetDate(date, "project_end_before")}
            style={{ marginLeft: 10 }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            disabled={loading}
          />
        </div>
      </div>
      <div className="p-grid" style={styles.filters.notTopFilterRow}>
        <div className="p-col" style={styles.filters.searchButtonContainer}>
          <Button style={styles.filters.searchButton} onClick={getIDIQData}>
            <div style={styles.filters.searchButtonText}>Search</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default IDIQFilters;
