import React, { FC, useContext, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { RobotIcon } from "../../assets/icons/material-icons/robot";
import { PresentationBoardIcon } from "../../assets/icons/material-icons/presentation-board";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { ExpandLess } from "@mui/icons-material";
import UpgradePlanModal from "../../components/Modals/UpgradePlanModal";
import Button from "@mui/material/Button";
import UserContext, {
  doesUserHaveRole,
  isUserInRoles,
  ServerOrgRoles,
  UserRoleGroups,
} from "../../services/UserContext";
import { Link } from "react-router-dom";

interface ResourcesProps {}

const Resources: FC<ResourcesProps> = (props) => {
  const {} = props;

  const context = useContext(UserContext);
  const theme = useTheme();

  const isProUser = isUserInRoles(context, UserRoleGroups.proOrHigher);
  const isMember = doesUserHaveRole(context, ServerOrgRoles.organizationMember);

  const [showResources, setShowResources] = useState<boolean>(true);
  const [upgradePlanModalOpen, setUpgradePlanModalOpen] =
    useState<boolean>(false);

  return (
    <Card
      variant={"outlined"}
      sx={{
        borderRadius: "8px",
      }}
    >
      <CardContent>
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <PresentationBoardIcon color={"primary"} />
            <Typography variant={"h5"} color={"inherit"}>
              USAID Resources
            </Typography>
          </Stack>
          <IconButton onClick={() => setShowResources((prev) => !prev)}>
            <ExpandLess
              sx={{
                transition: "transform 250ms ease-in-out",
                transform: `rotate(${showResources ? "0deg" : "180deg"})`,
              }}
            />
          </IconButton>
        </Stack>
      </CardContent>
      <Collapse in={showResources}>
        <Box
          sx={{
            borderTop: `solid 1px ${theme.palette.divider}`,
          }}
        >
          <UpgradePlanModal
            upgradePlanModalOpen={upgradePlanModalOpen}
            setUpgradePlanModalOpen={(val: boolean) =>
              setUpgradePlanModalOpen(val)
            }
          />
          <div className="resource-list">
            {isProUser ? (
              <ul
                style={{
                  textAlign: "left",
                }}
              >
                <li>
                  <Link to={"/resources"} style={{ fontSize: "14px" }}>
                    {"USAID Best Practices and General Information"}
                  </Link>
                </li>
                <li>
                  <Link to={"/lcp-archives"} style={{ fontSize: "14px" }}>
                    {"USG Local Compensation Plan Archive"}
                  </Link>
                </li>
                <li>
                  <Link to={"/iee-archives"} style={{ fontSize: "14px" }}>
                    {"Initial Environmental Examination Archive"}
                  </Link>
                </li>
              </ul>
            ) : (
              <Button
                variant={"secondaryContained"}
                sx={{
                  my: 2,
                }}
                {...(isMember
                  ? { onClick: () => setUpgradePlanModalOpen(true) }
                  : {
                      component: "a",
                      href: "/tiers",
                      target: "_blank",
                    })}
              >
                Upgrade your plan to see resources
              </Button>
            )}
          </div>
        </Box>
      </Collapse>
    </Card>
  );
};

export default Resources;
