import Stack from "@mui/material/Stack";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { ThumbsUpIcon } from "../../../assets/icons/material-icons/thumbs-up";
import { ThumbsDownIcon } from "../../../assets/icons/material-icons/thumbs-down";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { RootState, useDispatch, useSelector } from "../../../store";
import UserContext from "../../../services/UserContext";
import {
  deleteSummaryFeedback,
  resetFeedbackUpdateState,
  sendSummaryFeedback,
  updateSummaryFeedback,
} from "../../../slices/dashboard";
import { RequestStatus } from "../../../utils/Helpers/fetchStatus";
import toast from "react-hot-toast";

export type SummaryRatingType = "like" | "dislike" | null;

interface SummaryRatingProps {
  currentState: SummaryRatingType;
  aiSummaryId?: number | null;
  datasetSummaryId?: number | null;
  feedbackId: number | null;
}

export default function SummaryRating({
  currentState,
  aiSummaryId,
  datasetSummaryId,
  feedbackId,
}: SummaryRatingProps) {
  const dispatch = useDispatch();
  const context = useContext(UserContext);
  const theme = useTheme();

  const { feedback, updateFeedback, deleteFeedback } = useSelector(
    (state: RootState) => state.dashboard.updates,
  );

  const [isUpdatingState, setIsUpdatingState] = useState<boolean>(false);

  const isUpdating = useMemo(
    () =>
      (RequestStatus.isFetching(feedback.fetchStatus) ||
        RequestStatus.isFetching(updateFeedback.fetchStatus) ||
        RequestStatus.isFetching(deleteFeedback.fetchStatus)) &&
      isUpdatingState,
    [
      feedback.fetchStatus,
      updateFeedback.fetchStatus,
      deleteFeedback.fetchStatus,
    ],
  );

  function like() {
    if (aiSummaryId !== null) {
      setIsUpdatingState(true);
      if (currentState === null) {
        dispatch(
          sendSummaryFeedback({
            context,
            ...(aiSummaryId && { aiSummaryId }),
            ...(datasetSummaryId && { datasetSummaryId }),
            score: 100,
          }),
        );
      } else {
        if (currentState === "like") {
          dispatch(
            deleteSummaryFeedback({
              context,
              id: feedbackId,
            }),
          );
        } else {
          dispatch(
            updateSummaryFeedback({
              context,
              id: feedbackId,
              score: 100,
            }),
          );
        }
      }
    }
  }

  function dislike() {
    if (aiSummaryId !== null) {
      setIsUpdatingState(true);
      if (currentState === null) {
        dispatch(
          sendSummaryFeedback({
            context,
            ...(aiSummaryId && { aiSummaryId }),
            ...(datasetSummaryId && { datasetSummaryId }),
            score: 0,
          }),
        );
      } else {
        if (currentState === "dislike") {
          dispatch(
            deleteSummaryFeedback({
              context,
              id: feedbackId,
            }),
          );
        } else {
          dispatch(
            updateSummaryFeedback({
              context,
              id: feedbackId,
              score: 0,
            }),
          );
        }
      }
    }
  }

  useEffect(() => {
    if (
      !RequestStatus.isFetching(feedback.fetchStatus) &&
      !RequestStatus.isFetching(updateFeedback.fetchStatus) &&
      !RequestStatus.isFetching(deleteFeedback.fetchStatus) &&
      isUpdatingState
    ) {
      if (
        RequestStatus.isError(feedback.fetchStatus) ||
        RequestStatus.isError(updateFeedback.fetchStatus) ||
        RequestStatus.isError(deleteFeedback.fetchStatus)
      ) {
        toast.error("Something went wrong");
        setIsUpdatingState(false);
        dispatch(resetFeedbackUpdateState());
      } else {
        setIsUpdatingState(false);
      }
    }
  }, [
    feedback.fetchStatus,
    updateFeedback.fetchStatus,
    deleteFeedback.fetchStatus,
    isUpdatingState,
  ]);

  return aiSummaryId === null || aiSummaryId === -1 ? (
    <Box sx={{ height: "8px" }}></Box>
  ) : (
    <Stack
      direction={{
        xs: "column",
        sm: "row",
      }}
      sx={{
        gap: 2,
        alignItems: "center",
        my: 1,
        "& > *": {
          fontWeight: 400,
        },
      }}
    >
      <Typography variant={"subtitle2"} color={"textSecondary"}>
        Have you found this summary helpful or accurate?
      </Typography>
      <Stack
        direction={"row"}
        sx={{
          minWidth: "250px",
          alignItems: "center",
          "& > button": {
            "& > svg": {
              height: "16px !important",
              width: "16px !important",
            },
          },
        }}
      >
        <IconButton
          onClick={like}
          disabled={isUpdating}
          {...(currentState === "like" && { color: "success" })}
        >
          <ThumbsUpIcon />
        </IconButton>
        {currentState === "like" && (
          <Typography
            variant={"subtitle2"}
            sx={{
              color: theme.palette.success.main,
            }}
          >
            Yes
          </Typography>
        )}
        <IconButton
          onClick={dislike}
          disabled={isUpdating}
          {...(currentState === "dislike" && { color: "error" })}
          sx={{ ml: 1 }}
        >
          <ThumbsDownIcon />
        </IconButton>
        {currentState === "dislike" && (
          <Typography variant={"subtitle2"} color={"error"}>
            No
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
