import { ja } from "date-fns/locale";

export enum NotificationTypeEnum {
  Default = "Default",
  Daily = "Daily",
  Weekly = "Weekly",
  BusinessForecastLiveUpdate = "BusinessForecastLiveUpdate",
}
export enum PreferenceFilterTypes {
  And = "And",
  Or = "Or",
}
