import { useContext } from "react";
import UserContext, { isUserLoggedIn } from "./UserContext";

function isAuthenticated() {
  const context = useContext(UserContext);
  const isAuthenticated = isUserLoggedIn(context);
  return isAuthenticated;
}

export default function useIsAuthenticated(): [() => boolean] {
  return [isAuthenticated];
}
