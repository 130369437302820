import { IdToken, ServerOrgRoles } from "../../services/UserContext";

export type SignInResponseModel = {
  user: {
    accessToken: string;
    expiresIn: number;
    idToken: string;
    refreshToken: string;
    tokenType: string;
  };
  groups: ServerOrgRoles[];
  konektidToken: string;
};

export function parseToken(token: string) {
  try {
    const parsedToken: IdToken = JSON.parse(atob(token.split(".")[1]));
    return parsedToken;
  } catch (e) {
    console.error(`errror parsing token ${token}`, e);
    return null;
  }
}
