import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { PipelineDto } from "../../utils/types/Forecast";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { RootState, useDispatch, useSelector } from "../../store";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import { debounce, compact } from "lodash";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SimpleModal from "../../components/Modals/SimpleModal";
import Button from "@mui/material/Button";
import UserContext from "../../services/UserContext";
import { addOrRemoveFromPipelines, getPipelines } from "../../slices/pipelines";

interface AddMultipleOpportunitiesToPipelinesModalProps {
  itemsIds: Array<string>;
  bulkAddToPipelinesModalOpen: boolean;
  handleCloseBulkAddToPipelinesModal: any;
  pipelines: Array<PipelineDto>;
}

interface SelectedPipeline {
  pipelineId: number;
  checkType: "checked" | "indeterminate" | "not-checked";
  tooltip: boolean;
}

const AddMultipleOpportunitiesToPipelinesModal: FC<
  AddMultipleOpportunitiesToPipelinesModalProps
> = (props) => {
  const {
    itemsIds,
    bulkAddToPipelinesModalOpen,
    handleCloseBulkAddToPipelinesModal,
    pipelines,
  } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const context = useContext(UserContext);

  const {
    fetchStatus: pipelinesFetchStatus,
    addOrRemoveFromPipelines: { postFetchStatus: addToPipelinesFetchStatus },
  } = useSelector((state: RootState) => state.pipelines);

  const loading =
    RequestStatus.isFetching(addToPipelinesFetchStatus) ||
    RequestStatus.isFetching(pipelinesFetchStatus);

  // const getCheckType = (pipeline: PipelineDto) => {
  //   if (!pipeline) return "not-checked";
  //   const pipelineIds =
  //     pipeline.businessForcast
  //       ?.map((forecast) => forecast.forecast.id)
  //       ?.filter((pipeline) => itemsIds.includes(pipeline)) ?? [];
  //   return JSON.stringify(pipelineIds.sort()) ===
  //     JSON.stringify(itemsIds.sort())
  //     ? "checked"
  //     : pipelineIds.length === 0
  //     ? "not-checked"
  //     : "indeterminate";
  // };
  //
  // const getSelectedPipelines = (): Array<SelectedPipeline> => {
  //   return pipelines.length > 0
  //     ? pipelines.map((pipeline: PipelineDto) => {
  //         return {
  //           pipelineId: pipeline.id,
  //           checkType: getCheckType(pipeline),
  //           tooltip: getCheckType(pipeline) === "indeterminate" ?? false,
  //         };
  //       })
  //     : [];
  // };

  const [selectedPipelines, setSelectedPipelines] = useState<
    // Array<SelectedPipeline>
    Array<number>
  >([]);
  const [filteredPipelines, setFilteredPipelines] = useState<
    Array<PipelineDto>
  >([]);
  const [searchInputValue, setSearchInputValue] = useState<string>("");

  const onSearchChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearchInputValue(e.target.value);
    debouncedCountryInputChange(e.target.value);
  };

  const searchCallback = (value) => {
    setFilteredPipelines(
      pipelines.filter((pipeline) => pipeline.name.includes(value)),
    );
  };

  const debouncedCountryInputChange = useCallback(
    // debounce(searchCallback, 250),
    debounce(searchCallback, 0),
    [pipelines],
  );

  const handleOnChange = (id: number, checked: boolean): void => {
    setSelectedPipelines((prev) =>
      prev.includes(id) ? prev.filter((_id) => _id !== id) : [...prev, id],
    );
    // if (
    //   selectedPipelines.find(
    //     (pipeline: SelectedPipeline) => pipeline.pipelineId === id,
    //   )?.checkType === "checked"
    // )
    //   setSelectedPipelines((prev) =>
    //     prev.map((pipeline) =>
    //       pipeline.pipelineId !== id
    //         ? pipeline
    //         : { ...pipeline, pipelineId: id, checkType: "not-checked" },
    //     ),
    //   );
    // else
    //   setSelectedPipelines((prev) =>
    //     prev.map((pipeline) =>
    //       pipeline.pipelineId === id
    //         ? {
    //             ...pipeline,
    //             pipelineId: id,
    //             checkType: "checked",
    //           }
    //         : pipeline,
    //     ),
    //   );
  };

  const handleClearInput = (): void => {
    onSearchChange({ target: { value: "" } } as ChangeEvent<HTMLInputElement>);
  };

  // const getCurrentState = (
  //   type: "checked" | "indeterminate",
  //   item: string,
  // ): Array<number> => {
  //   const initialPipelines = getSelectedPipelines();
  //   return compact(
  //     pipelines
  //       .filter((pipeline) =>
  //         initialPipelines
  //           .filter(
  //             (selected) =>
  //               selected.checkType !==
  //                 (type === "checked" ? "indeterminate" : "checked") &&
  //               selected.checkType !== "not-checked",
  //           )
  //           .map((selected) => selected.pipelineId)
  //           .includes(pipeline.id),
  //       )
  //       .map((pipeline) =>
  //         pipeline?.businessForcast?.find(
  //           (_item) =>
  //             _item?.userEdits?.isMyList && item === _item?.forecast?.id,
  //         )
  //           ? pipeline.id
  //           : null,
  //       ),
  //   );
  // };
  //
  // const getChanges = (type: "add" | "remove", item: string): Array<number> => {
  //   return [
  //     ...selectedPipelines
  //       .filter(
  //         (selected) =>
  //           selected.checkType !== "indeterminate" &&
  //           selected.checkType !== (type === "add" ? "not-checked" : "checked"),
  //       )
  //       .filter((selected) =>
  //         type === "add"
  //           ? getCurrentState("checked", item).includes(selected.pipelineId)
  //           : getCurrentState("checked", item).includes(selected.pipelineId),
  //       )
  //       .map((selected) => selected.pipelineId),
  //     ...selectedPipelines
  //       .filter(
  //         (selected) =>
  //           selected.checkType !== "indeterminate" &&
  //           selected.checkType !== (type === "add" ? "not-checked" : "checked"),
  //       )
  //       .filter((selected) =>
  //         type === "add"
  //           ? !getCurrentState("indeterminate", item).includes(
  //               selected.pipelineId,
  //             )
  //           : getCurrentState("indeterminate", item).includes(
  //               selected.pipelineId,
  //             ),
  //       )
  //       .map((selected) => selected.pipelineId),
  //   ];
  // };

  //todo: finish dispatch logic and filtering out which opportunities should be added/removed from which pipelines
  const handleSavePipelines = (): void => {
    const data = itemsIds
      .map((item) => ({
        addedToPipelines: selectedPipelines, //getChanges("add", item),
        removedFromPipelines: [], //getChanges("remove", item),
        opportunityId: item,
      }))
      .map((changes) => ({
        forecastPipelinesIdsToAdd: changes.addedToPipelines.map((added) => ({
          forecastId: changes.opportunityId,
          pipelineId: added,
        })),
        forecastPipelinesIdsToRemove: changes.removedFromPipelines.map(
          (removed) => ({
            forecastId: changes.opportunityId,
            pipelineId: removed,
          }),
        ),
      }));

    dispatch(
      addOrRemoveFromPipelines({
        context,
        sourceTable: "forecast",
        forecastPipelineIdsToAdd: data
          .map((item) => item.forecastPipelinesIdsToAdd)
          .flat(),
        forecastPipelineIdsToRemove: [],
        // todo: Currently modal allows only adding to pipelines, removal disabled for now
        //   data
        //   .map((item) => item.forecastPipelinesIdsToRemove)
        //   .flat(),
      }),
    );
  };

  // useEffect(() => {
  //   if (RequestStatus.isDone(pipelinesFetchStatus))
  //     setSelectedPipelines(getSelectedPipelines());
  // }, [pipelinesFetchStatus])
  useEffect(() => {
    if (RequestStatus.isDone(pipelinesFetchStatus))
      // setSelectedPipelines(getSelectedPipelines());
      setSelectedPipelines([]);
  }, [pipelinesFetchStatus]);

  useEffect(() => {
    if (bulkAddToPipelinesModalOpen) {
      dispatch(getPipelines(context));
    } else {
      setTimeout(() => {
        setSelectedPipelines([]);
        setFilteredPipelines([]);
        setSearchInputValue("");
      }, 250);
    }
  }, [bulkAddToPipelinesModalOpen]);

  useEffect(() => {
    if (RequestStatus.isDone(pipelinesFetchStatus)) {
      // setSelectedPipelines(getSelectedPipelines());
      setFilteredPipelines(pipelines);
    }
  }, [pipelinesFetchStatus]);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <SimpleModal
      open={bulkAddToPipelinesModalOpen}
      handleClose={handleCloseBulkAddToPipelinesModal}
      title={"Add to pipeline(s)"}
      acceptLabel={"Save"}
      loading={loading}
      handleAccept={handleSavePipelines}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          minWidth: "500px",
        }}
      >
        <Box sx={{ display: "flex", gap: "8px" }}>
          <TextField
            size={"small"}
            onChange={onSearchChange}
            value={searchInputValue}
            placeholder={`${
              //   .filter(
              //   (pipeline) => pipeline.checkType === "checked",
              // )?
              selectedPipelines.length
            } Pipeline${
              //   .filter(
              //   (pipeline) => pipeline.checkType === "checked",
              // )?
              selectedPipelines.length === 1 ? "" : "s"
            } Selected`}
            InputProps={{
              sx: {
                px: 0.5,
              },
              startAdornment: (
                <SearchIcon sx={{ color: theme.palette.text.secondary }} />
              ),
              endAdornment: searchInputValue.length > 0 && (
                <IconButton onClick={handleClearInput}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
            fullWidth
          />
          <Button
            sx={{ width: "200px" }}
            variant={"secondaryContained"}
            // onClick={() => setSelectedPipelines(getSelectedPipelines())}
            onClick={() => setSelectedPipelines([])}
          >
            Reset selection
          </Button>
        </Box>
        {/*{pipelinesWithForecastData.length > filteredPipelines.length &&*/}
        {pipelines.length > filteredPipelines.length &&
          RequestStatus.isDone(pipelinesFetchStatus) && (
            <Typography variant={"subtitle2"} color={"textPrimary"}>
              Currently showing {filteredPipelines?.length} out of{" "}
              {pipelines?.length} pipelines.
            </Typography>
          )}
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "calc(65vh - 150px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {filteredPipelines.length === 0 &&
          RequestStatus.isDone(pipelinesFetchStatus) ? (
            <Typography variant={"subtitle2"} color={"textPrimary"}>
              No pipelines found
            </Typography>
          ) : (
            filteredPipelines.map((pipeline) => (
              <FormControlLabel
                key={pipeline?.id}
                control={
                  <Checkbox
                    checked={
                      // Boolean(
                      //   pipeline?.businessForcast?.find(
                      //     (_item) =>
                      //       _item?.userEdits?.isMyList &&
                      //       item?.id === _item?.forecast?.id,
                      //   ),
                      // ) ?? false
                      // selectedPipelines.find(
                      //   (selectedPipeline) =>
                      //     selectedPipeline.pipelineId === pipeline.id,
                      // )?.checkType === "checked" ?? false
                      selectedPipelines.includes(pipeline.id)
                    }
                    // indeterminate={
                    //   selectedPipelines.find(
                    //     (selectedPipeline) =>
                    //       selectedPipeline.pipelineId === pipeline.id,
                    //   )?.checkType === "indeterminate" ?? false
                    // }
                    disabled={loading}
                    sx={{
                      p: 0.5,
                      m: 0,
                      mx: 0.5,
                      ml: 1,
                    }}
                    onChange={(event, checked) =>
                      handleOnChange(pipeline.id, checked)
                    }
                  />
                }
                label={
                  <Box
                    sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                  >
                    <Typography variant={"subtitle2"} color={"textPrimary"}>
                      {pipeline.name}
                    </Typography>
                    {/*{selectedPipelines.find(*/}
                    {/*  (selectedPipeline) =>*/}
                    {/*    selectedPipeline.pipelineId === pipeline.id,*/}
                    {/*)?.tooltip && (*/}
                    {/*  <Tooltip*/}
                    {/*    title={*/}
                    {/*      "Some selected opportunities are already added to this pipeline"*/}
                    {/*    }*/}
                    {/*    disableInteractive*/}
                    {/*  >*/}
                    {/*    <InfoOutlinedIcon*/}
                    {/*      sx={{*/}
                    {/*        height: "16px",*/}
                    {/*        width: "16px",*/}
                    {/*        mt: "-2px",*/}
                    {/*        "&:hover": {*/}
                    {/*          color: theme.palette.primary.main,*/}
                    {/*        },*/}
                    {/*      }}*/}
                    {/*    />*/}
                    {/*  </Tooltip>*/}
                    {/*)}*/}
                  </Box>
                }
              />
            ))
          )}
        </Box>
      </Box>
    </SimpleModal>
  );
};

export default AddMultipleOpportunitiesToPipelinesModal;
