import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { withRouter } from "react-router-dom";
import AidkonektUpdates from "../components/Dashboard/AidkonektUpdates";
import UserContext, {
  convertRoleToFriendlyName,
  getMaxUserRole,
  ServerOrgRoles,
  userRoleInOrg,
} from "../services/UserContext";
import { getLocalTimeZone } from "../services/timezone";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../configs/axios-export.custom";
import GrowlContext from "../services/growlContext";
import accountInformationReducer, {
  AccountInformationContext,
  createInitialAccountInformationState,
} from "../components/Dashboard/DashboardManagement/AccountInformation/reducer";
import EventsCalendar, {
  EventType,
} from "../components/Dashboard/EventsCalendar/EventsCalendar";
import axios from "axios";
import ResourcesWidget from "../components/Dashboard/Resources/ResourcesWidget";
import PipelineUpdates from "../components/Dashboard/PipelineUpdates/PipelineUpdates";

import { Userpilot } from "userpilot";

type ProfileResponse = {
  events: Array<EventType>;
};
const Dashboard = () => {
  // Context
  const userContext = useContext(UserContext);
  const growl = useContext(GrowlContext);

  const userPilotRole = useMemo(() => {
    let isOrgAdmin = userRoleInOrg(
      userContext,
      ServerOrgRoles.organizationAdmin,
    );
    let maxUserRole = getMaxUserRole(userContext);
    let role = convertRoleToFriendlyName(maxUserRole);
    if (
      maxUserRole === ServerOrgRoles.plus ||
      maxUserRole === ServerOrgRoles.pro
    )
      role = isOrgAdmin ? `${role} Admin` : role;

    return role;
  }, [userContext]);

  const [events, setEvents] = React.useState(Array<EventType>());

  const email = userContext.user.parsedIdToken?.email ?? "";

  // State
  const [accountInformationState, accountInfoDispatch] = useReducer(
    accountInformationReducer,
    createInitialAccountInformationState(
      email,
      "",
      "",
      email,
      getLocalTimeZone(),
    ),
  );
  // TODO: Combine reducers -> "Dashboard reducer"
  const request = createAuthenticatedRequest(userContext);

  const loadDataAsync = useCallback(() => {
    axios
      .get(
        getFullUrl("/api/user/profile", { useDedicatedEnvironment: true }),
        request,
      )
      .then((response) => {
        // Hook back to profile info
        const { events }: ProfileResponse = response.data;
        setEvents(events);

        const today = new Date();
        const created_at =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        const id = userContext.user.parsedIdToken?.sub;

        Userpilot.identify(id ?? "", {
          name: response.data?.firstName,
          email: email,
          role: userPilotRole,
          created_at: created_at,
        });
      })
      .catch((e) => {
        console.error(e);
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: "Unable to load user profile",
          });
        }
      });
  }, []);

  useEffect(loadDataAsync, [userContext]);

  const accountInformationContextValue = useMemo(() => {
    return {
      accountInformationState: accountInformationState,
      accountInformationDispatch: accountInfoDispatch,
    };
  }, [accountInformationState, accountInfoDispatch]);

  return (
    <AccountInformationContext.Provider value={accountInformationContextValue}>
      <div className="p-grid" style={{ marginRight: "20px" }}>
        {/* <HeaderCards /> */}

        <div className="p-col-12 p-md-12 p-lg-12 p-xl-8">
          <AidkonektUpdates />
        </div>
        <div className="p-col-12 p-md-12 p-lg-12 p-xl-4">
          <PipelineUpdates />
        </div>

        <div className="p-col-12 p-md-12 p-lg-12 p-xl-8">
          <EventsCalendar events={events} />
        </div>
        <div className="p-col-12 p-md-12 p-lg-12 p-xl-4">
          <ResourcesWidget />
        </div>
      </div>
    </AccountInformationContext.Provider>
  );
};

export default withRouter(Dashboard);
