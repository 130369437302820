export interface ServerSetting<T> {
  currentValue: T;
  serverValue: T;
}

export const initializeSettingValue = <T>(
  initialValue: T
): ServerSetting<T> => ({
  currentValue: initialValue,
  serverValue: initialValue,
});

export const updateSettingValue = <T>(
  setting: ServerSetting<T>,
  newValue: T
): ServerSetting<T> => ({
  ...setting,
  currentValue: newValue,
});

export const saveSettingValue = <T>(
  setting: ServerSetting<T>
): ServerSetting<T> => ({
  ...setting,
  serverValue: setting.currentValue,
});

const dashboardReducer = () => {};

export default dashboardReducer;
