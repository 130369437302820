import { Theme } from "@mui/material/styles";
import React, { Dispatch, ReactNode, SetStateAction } from "react";
import {
  businessForecastRecordType,
  ForecastExtendedFiltersProps,
  ForecastFiltersProps,
  ForecastPagination,
  ForecastRecord,
  ForecastSortOption,
} from "../../utils/types/Forecast";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import {
  Avatar,
  Box,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { FiltersIcon } from "../../components/Icons/FiltersIcon";
import { SourceIcon } from "../../components/Icons/SourceIcon";
import { LastUpdatedIcon } from "../../components/Icons/LastUpdatedIcon";
import {
  ContractsFiltersType,
  ContractsPagination,
} from "../../slices/contracts";
import { TableProperties } from "exceljs";
import {
  buildExcelTable,
  genEmptyRow,
  getChunksFromString,
  MaxCharsAllowed,
} from "../../services/exporter";
import {
  removeHtmlTags,
  removeHtmlTagsExceptLink,
} from "../../services/dataParser";
import axios from "axios/index";
import {
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import { buildQuery } from "../../utils/Helpers/queryBuilder";
import { DateFormat, formatDate } from "../../utils/conversion/date-converters";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

export const getSortableColumnPropertyName = (
  _propertyName: string,
): string => {
  return _propertyName === "contactInfo"
    ? "pointOfContactOne"
    : _propertyName === "secondaryContactInfo"
    ? "pointOfContactTwo"
    : _propertyName;
};

const isNumeric = (str: any) => {
  return !isNaN(str) && !isNaN(parseFloat(str));
};

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const getTableHeader = (
  theme: Theme,
  props: {
    handleOpenFilters: () => void;
    setFilters: Dispatch<SetStateAction<any>>;
    lastUpdated: string | null;
    fetchStatus: string;
    inputValue: string;
    onChange: (event) => void;
    // extendedFilters: ForecastExtendedFiltersProps;
    allFilters: {
      pagination: ContractsPagination;
      filters: ContractsFiltersType;
      sortOption: {
        sortField: string;
        sortOrder: number;
      };
    };
  },
): Array<ReactNode> => {
  const {
    handleOpenFilters,
    setFilters,
    lastUpdated,
    fetchStatus,
    inputValue,
    onChange,
    // extendedFilters,
    allFilters: { pagination, filters, sortOption },
  } = props;

  const isDataFetching = RequestStatus.isFetching(fetchStatus);

  const extendedFiltersLength = Object.entries(filters)
    ?.filter(
      ([key, value]) =>
        !key.includes("include") &&
        !key.includes("sort") &&
        key !== "showAwards" &&
        key !== "onlyUntrackedInPipeline",
    )
    ?.filter(([key, value]) =>
      Array.isArray(value) ? value.length > 0 : !!value,
    )
    ?.map(([key, value]) => key)?.length;

  const topLine = (
    <Box
      sx={{
        pt: 1,
        display: "flex",
        gap: "16px",
        alignItems: "center",
      }}
    >
      {/* todo: temporarily hidden, restore and adjust when possible */}
      <TextField
        value={inputValue}
        onChange={onChange}
        sx={{
          width: "300px",
        }}
        InputProps={{
          startAdornment: (
            <SearchIcon sx={{ color: theme.palette.action.active }} />
          ),
          endAdornment: (
            <>
              {inputValue?.length > 0 && (
                <IconButton
                  onClick={() => onChange({ target: { value: "" } })}
                  sx={{ mr: 0.5 }}
                  disabled={isDataFetching}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <Box
                sx={{
                  borderLeft: `solid 1px ${theme.palette.divider}`,
                  pl: 0.5,
                }}
              >
                <Tooltip title={"Filters"}>
                  <span>
                    <IconButton onClick={handleOpenFilters}>
                      <FiltersIcon sx={{ width: "24px", height: "24px" }} />
                    </IconButton>
                    {extendedFiltersLength > 0 && (
                      <Avatar
                        sx={{
                          position: "absolute",
                          height: "16px",
                          width: "16px",
                          top: "2px",
                          right: "2px",
                          fontSize: "12px",
                          backgroundColor: theme.palette.secondary.main,
                          // border: `solid 1px ${theme.palette.secondary.main}`,
                          pointerEvents: "none",
                        }}
                      >
                        {extendedFiltersLength}
                      </Avatar>
                    )}
                  </span>
                </Tooltip>
              </Box>
            </>
          ),
          sx: {
            px: 0.5,
          },
        }}
        // inputProps={{
        //   style: {
        //     padding: "8px",
        //   },
        // }}
        size={"small"}
        placeholder={"Search"}
        // disabled={isDataFetching}
      />
      {/*<Tooltip title={"Filters"}>*/}
      {/*  <span>*/}
      {/*    <Button*/}
      {/*      variant={"outlined"}*/}
      {/*      endIcon={<FiltersIcon sx={{ width: "24px", height: "24px" }} />}*/}
      {/*      size={"small"}*/}
      {/*      onClick={handleOpenFilters}*/}
      {/*    >*/}
      {/*      Filters*/}
      {/*      {extendedFiltersLength > 0 && (*/}
      {/*        <Avatar*/}
      {/*          sx={{*/}
      {/*            position: "absolute",*/}
      {/*            height: "16px",*/}
      {/*            width: "16px",*/}
      {/*            top: "2px",*/}
      {/*            right: "6px",*/}
      {/*            fontSize: "12px",*/}
      {/*            backgroundColor: theme.palette.secondary.main,*/}
      {/*            // border: `solid 1px ${theme.palette.secondary.main}`,*/}
      {/*            pointerEvents: "none",*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          {extendedFiltersLength}*/}
      {/*        </Avatar>*/}
      {/*      )}*/}
      {/*    </Button>*/}
      {/*  </span>*/}
      {/*</Tooltip>*/}
      <FormControlLabel
        onChange={(e, checked) =>
          setFilters((prev) => ({ ...prev, showAwards: checked }))
        }
        control={<Switch checked={filters.showAwards} />}
        label={"Show Awards Only"}
        disabled={isDataFetching}
      />
      <FormControlLabel
        onChange={(e, checked) =>
          setFilters((prev) => ({
            ...prev,
            onlyUntrackedInPipeline: checked,
          }))
        }
        control={<Switch checked={filters.onlyUntrackedInPipeline} />}
        label={"Exclude Tracked Opportunities"}
        disabled={isDataFetching}
      />
    </Box>
  );

  const bottomLine = (
    <Box
      sx={{
        display: "flex",
        gap: "16px",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <FormControlLabel
          onChange={(e, checked) =>
            setFilters((prev) => ({ ...prev, includeUsaid: checked }))
          }
          control={<Switch checked={filters.includeUsaid} />}
          label={"USAID"}
          disabled={isDataFetching}
        />
        <FormControlLabel
          onChange={(e, checked) =>
            setFilters((prev) => ({ ...prev, includeMcc: checked }))
          }
          control={<Switch checked={filters.includeMcc} />}
          label={"MCC"}
          disabled={isDataFetching}
        />
        <FormControlLabel
          onChange={(e, checked) =>
            setFilters((prev) => ({ ...prev, includeStateDept: checked }))
          }
          control={<Switch checked={filters.includeStateDept} />}
          label={"State Dept"}
          disabled={isDataFetching}
        />
        <FormControlLabel
          onChange={(e, checked) =>
            setFilters((prev) => ({ ...prev, includeCdc: checked }))
          }
          control={<Switch checked={filters.includeCdc} />}
          label={"CDC"}
          disabled={isDataFetching}
        />
        <FormControlLabel
          onChange={(e, checked) =>
            setFilters((prev) => ({ ...prev, includeDfc: checked }))
          }
          control={<Switch checked={filters.includeDfc} />}
          label={"DFC"}
          disabled={isDataFetching}
        />
        {/*<Button*/}
        {/*  variant={"outlined"}*/}
        {/*  endIcon={<FiltersIcon sx={{ width: "24px", height: "24px" }} />}*/}
        {/*  size={"small"}*/}
        {/*  onClick={(e) => {*/}
        {/*    e.preventDefault();*/}
        {/*  }}*/}
        {/*  sx={{*/}
        {/*    opacity: 0,*/}
        {/*    cursor: "initial",*/}
        {/*    mr: 2,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Filters*/}
        {/*</Button>*/}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "24px",
          "& > *": { display: "flex", alignItems: "center" },
        }}
      >
        <Typography variant={"subtitle2"} color={"textSecondary"}>
          <SourceIcon
            color={"secondary"}
            sx={{
              height: "16px",
              width: "16px",
              mr: 0.5,
              mt: "-2px",
            }}
          />
          Source: sam.gov
        </Typography>
        <Typography variant={"subtitle2"} color={"textSecondary"}>
          <LastUpdatedIcon
            sx={{
              height: "16px",
              width: "16px",
              mr: 0.5,
              mt: "-2px",
            }}
          />
          Last updated: {lastUpdated ?? "N/A"}
        </Typography>
      </Box>
    </Box>
  );

  return [topLine, bottomLine];
};

export const getExportableDataTable = (data: any): TableProperties => {
  const columns = [
    { name: "Sam.gov link" },
    { name: "Department/Agency" },
    { name: "Title" },
    { name: "Country/Office" },
    { name: "Description" },
    { name: "Contract Opportunity Type" },
    { name: "Status" },
    { name: "Contractor Awarded Name" },
    { name: "Awarded Date" },
    { name: "Contract Award Number" },
    { name: "Awarded Amount" },
    { name: "Solicitation Number" },
    { name: "Original Publish Date" },
    { name: "Offer Due Date" },
    { name: "Last Updated Date" },
    { name: "Set Aside" },
    { name: "Product Service Code" },
    { name: "NAICS" },
    { name: "Place of Performance" },
    { name: "Contact Info" },
    { name: "Secondary Contact Info" },
  ];

  // if (isPipelineScreen && !isMySuggestions) {
  //   columns.unshift({ name: "Notes" }, { name: customColumnName });
  // }

  let rows: any[][] = [];

  if (data.length === 0) {
    rows.push(genEmptyRow(columns.length));
  } else {
    for (let i = 0; i < data.length; i++) {
      const record = data[i];
      const chunkDescription = getChunksFromString(
        removeHtmlTags(record.description),
        MaxCharsAllowed,
      );
      let row = [
        record?.id ? `https://sam.gov/opp/${record?.id}/view` : "",
        record.agencyName,
        record.title,
        record.country,
        chunkDescription[0] || "",
        record.type,
        record.status,
        record.contractorAwardedName,
        record.contractorAwardedDate
          ? record.contractorAwardedDate.toString(DateFormat.dd_MMMM_yyyy)
          : "",
        record.awardNumber,
        isNumeric(record.contractorAwardedAmount)
          ? currencyFormatter.format(
              parseFloat(record.contractorAwardedAmount || ""),
            )
          : record.contractorAwardedAmount,
        record.solicitationNumber,
        formatDate(record.postedDateIn, DateFormat.dd_MMMM_yyyy),
        formatDate(
          record.solicitationDeadlineResponseIn,
          DateFormat.dd_MMMM_yyyy,
        ),
        formatDate(record.modifiedDateIn, DateFormat.dd_MMMM_yyyy),
        record.setAside,
        record.classificationCode,
        record.naicsCodes,
        record.placeOfPerformance,
        record.pointOfContactOne
          ? `${record.pointOfContactOne.fullName}; ${
              record.pointOfContactOne.email
            }${
              record.pointOfContactOne.phone
                ? `; ${record.pointOfContactOne.phone}`
                : ""
            }${
              record.pointOfContactOne.fax
                ? `; ${record.pointOfContactOne.fax}`
                : ""
            }`
          : "",
        record.pointOfContactTwo
          ? `${
              record.pointOfContactTwo.fullName
                ? record.pointOfContactTwo.fullName + ";"
                : ""
            } ${record.pointOfContactTwo.email ?? ""}${
              record.pointOfContactTwo.phone
                ? `; ${record.pointOfContactTwo.phone}`
                : ""
            }${
              record.pointOfContactTwo.fax
                ? `; ${record.pointOfContactTwo.fax}`
                : ""
            }`
          : "",
      ];

      // if (isPipelineScreen && !isMySuggestions) {
      //   row.unshift(
      //     removeHtmlTags(record.notes ?? ""),
      //     removeHtmlTags(record.customColumn ?? ""),
      //   );
      // }

      rows.push(row);

      for (let j = 1; j < chunkDescription.length; j++) {
        let emptyRow = genEmptyRow(columns.length);
        emptyRow[4] = chunkDescription[j];
        rows.push(emptyRow);
      }
    }
  }

  return buildExcelTable("GrantsExport", columns, rows);
};

export const getExcelData = async (
  context,
  queryDto,
  totalItems: number = 1000,
): Promise<ForecastRecord[]> => {
  const q = queryDto;
  if (q) {
    q.pageSize = totalItems;
    q.pageIndex = 0;
  }
  let result = Array<any>();
  await axios
    .get(
      getFullUrl(`/api/contract${buildQuery(q, "|")}`, {
        useDedicatedEnvironment: true,
      }),
      createRequestWithAuthHeaders(context),
    )
    .then((response) => {
      result = response.data.data;
    })
    .catch((error) => {
      alert("error fetching data");
    });
  return result;
};
