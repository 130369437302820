import React, { FC } from "react";
import Input, { DropdownOption } from "../../components/Widgets/Inputs/Input";

interface ForecastFiltersProps {
  control: any;
  countryOptions: Array<DropdownOption>;
  sectorOptions: Array<DropdownOption>;
  awardActionTypeOptions: Array<DropdownOption>;
  geoCodesOptions: Array<DropdownOption>;
}

const ForecastFilters: FC<ForecastFiltersProps> = (props) => {
  const {
    control,
    countryOptions,
    sectorOptions,
    awardActionTypeOptions,
    geoCodesOptions,
  } = props;

  return (
    <>
      <Input
        type={"combo-box"}
        withCheckboxes
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        control={control}
        // defaultValue={""}
        name={"Countries"}
        label={"OFFICE/COUNTRY"}
        options={countryOptions}
        // loading={loading}
        fullWidth
        multiple
      />
      <Input
        type={"date"}
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"DateOfChangeAfter"}
        control={control}
        defaultValue={null}
        label={"DATE OF CHANGE FROM"}
      />
      <Input
        type={"date"}
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"DateOfChangeBefore"}
        control={control}
        // defaultValue={null}
        label={"DATE OF CHANGE TO"}
      />
      <Input
        control={control}
        // defaultValue={""}
        type={"text"}
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"OpportunityTextKeyword"}
        label={"OPPORTUNITY NAME"}
        fullWidth
      />
      <Input
        control={control}
        // defaultValue={""}
        type={"text"}
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"OpportunityDescriptionKeyword"}
        label={"DESCRIPTION"}
        fullWidth
      />
      <Input
        control={control}
        // defaultValue={""}
        type={"text"}
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"SuggestedPrimesKeyword"}
        label={"SUGGESTED PRIMES/PARTNERS"}
        fullWidth
      />
      <Input
        control={control}
        //defaultValue={""}
        type={"text"}
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"QuarterlyForecastNotesKeyword"}
        label={"QUARTERLY FORECAST Q&A AND NOTES"}
        fullWidth
      />
      <Input
        type={"combo-box"}
        withCheckboxes
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        control={control}
        // defaultValue={""}
        name={"Sectors"}
        label={"SECTOR"}
        options={sectorOptions}
        // loading={loading}
        fullWidth
        multiple
      />
      {/*<Input*/}
      {/*  control={control}*/}
      {/*  // defaultValue={""}*/}
      {/*  type={"number"}*/}
      {/*  sx={{*/}
      {/*    "& .MuiFormLabel-root": {*/}
      {/*      fontSize: "0.875em",*/}
      {/*      lineHeight: "1.875em",*/}
      {/*    },*/}
      {/*  }}*/}
      {/*  name={"totalEstimatedCost"}*/}
      {/*  label={"COST RANGE OVER USD"}*/}
      {/*  fullWidth*/}
      {/*/>*/}
      <Input
        type={"combo-box"}
        withCheckboxes
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        control={control}
        // defaultValue={""}
        name={"AwardTypes"}
        label={"AWARD/ACTION TYPE"}
        options={awardActionTypeOptions}
        // loading={loading}
        fullWidth
        multiple
      />
      {/*<Input*/}
      {/*  type={"combo-box"}*/}
      {/*  withCheckboxes*/}
      {/*  sx={{*/}
      {/*    "& .MuiFormLabel-root": {*/}
      {/*      fontSize: "0.875em",*/}
      {/*      lineHeight: "1.875em",*/}
      {/*    },*/}
      {/*  }}*/}
      {/*  control={control}*/}
      {/*  // defaultValue={""}*/}
      {/*  name={"GeographicalCodes"}*/}
      {/*  label={"GEO CODES"}*/}
      {/*  options={geoCodesOptions}*/}
      {/*  // loading={loading}*/}
      {/*  fullWidth*/}
      {/*  multiple*/}
      {/*/>*/}
      <Input
        type={"date"}
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"AnticipatedReleaseDateAfter"}
        control={control}
        // defaultValue={null}
        label={"ANTICIPATED RELEASE DATE FROM"}
      />
      <Input
        type={"date"}
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"AnticipatedReleaseDateBefore"}
        control={control}
        // defaultValue={null}
        label={"ANTICIPATED RELEASE DATE TO"}
      />
      <Input
        type={"date"}
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"AnticipatedAwardDateAfter"}
        control={control}
        // defaultValue={null}
        label={"ANTICIPATED AWARD DATE FROM"}
      />
      <Input
        type={"date"}
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"AnticipatedAwardDateBefore"}
        control={control}
        // defaultValue={null}
        label={"ANTICIPATED AWARD DATE TO"}
      />
    </>
  );
};

export default ForecastFilters;
