import axios from "axios";
import { Toast } from "primereact/toast";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../configs/axios-export.custom";
import { UserContextType } from "../../services/UserContext";

type ResponseType = {
  country: string;
  date: Date | null;
  link: string;
  id: number;
};
type Record = { date: Date | null; link: string; id: number };
type Collection = { [country: string]: Record[] };
export async function getData(
  growl: Toast,
  user: UserContextType,
  type: "iee" | "lcp"
) {
  return axios
    .get(
      getFullUrl(`/api/admin/resource?type=${type}`, {
        useDedicatedEnvironment: true,
      }),
      createAuthenticatedRequest(user)
    )
    .then((r) => {
      const data: ResponseType[] = r.data;
      const c: Collection = {};
      const results = data.reduce((prev: Collection, curr: ResponseType) => {
        let collection = prev[curr.country];
        if (!collection) {
          collection = [];
          prev[curr.country] = collection;
        }
        collection.push({ date: curr.date, link: curr.link, id: curr.id });
        return prev;
      }, c);
      return results;
    })
    .catch((e) => {
      console.error(e);
      growl.show({
        severity: "error",
        summary: "Error loading resources",
      });
    });
}
