import { UserContextType } from "../../services/UserContext";
import axios from "axios/index";
import {
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";

export const shareOpportunityToUser = async (params: {
  context: UserContextType;
  message: string;
  opportunityId: number;
  recordType: string;
  toAdderess: string;
}): Promise<void> => {
  const { context, ...otherData } = params;
  const response = await axios.post(
    getFullUrl("/api/pipeline/share", {
      useDedicatedEnvironment: true,
    }),
    otherData,
    createRequestWithAuthHeaders(context),
  );

  return response?.data ?? null;
};
