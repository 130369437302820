import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  alpha,
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import {
  businessForecastRecordType,
  ForecastRecord,
} from "../../utils/types/Forecast";
import ReplyIcon from "@mui/icons-material/Reply";
import AddToPipelinesTooltip from "./AddToPipelinesTooltip";
import { RootState, useDispatch, useSelector } from "../../store";
import {
  getPipelinesForRecord,
  resetPipelinesForRecord,
} from "../../slices/pipelines";
import UserContext from "../../services/UserContext";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import TooltipWithScrollHide from "../../components/Widgets/TooltipWithScrollHide";
import InfoIcon from "@mui/icons-material/Info";

interface RowActionsProps {
  onShareClick: () => any;
  item: ForecastRecord;
  canShare: boolean;
  isFree: boolean;
  addToPipelinesOpen: string | number | null;
  setAddToPipelinesOpen: Dispatch<SetStateAction<string | number | null>>;
  handleOpenAddToPipelines: (item: ForecastRecord) => void;
  handleCloseAddToPipelines: () => void;
}

const icons = {
  addToPipeline: require("../../assets/icons/AddToPipeline.png")?.default,
};

const RowActions: FC<RowActionsProps> = (props) => {
  const {
    onShareClick,
    item,
    canShare,
    isFree,
    addToPipelinesOpen,
    setAddToPipelinesOpen,
    handleOpenAddToPipelines,
    handleCloseAddToPipelines,
  } = props;

  const theme = useTheme();

  const {
    items: pipelines,
    itemsFor,
    fetchStatus,
  } = useSelector((state: RootState) => state.pipelines.pipelinesForRecord);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        width: "min-content",
        ...(!canShare && isFree && { justifyContent: "center", width: "auto" }),
      }}
    >
      {canShare && (
        <TooltipWithScrollHide
          querySelectors={"[data-virtuoso-scroller]"}
          title={"Share"}
        >
          <IconButton
            color={"primary"}
            sx={{
              border: "solid 2px",
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
            }}
            onClick={onShareClick}
          >
            <ReplyIcon
              fontSize={"small"}
              sx={{
                // transform: "rotate(180deg)",
                transform: "scale(-1, 1)",
                width: "24px",
                height: "24px",
              }}
            />
          </IconButton>
        </TooltipWithScrollHide>
      )}
      {!isFree && (
        <TooltipWithScrollHide
          querySelectors={"[data-virtuoso-scroller]"}
          title={"Add to pipeline(s)"}
          tooltipProps={{
            disableInteractive: true,
            disableHoverListener: !!addToPipelinesOpen,
          }}
        >
          <Tooltip
            onClose={handleCloseAddToPipelines}
            open={!!addToPipelinesOpen && addToPipelinesOpen === item.rowId}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            disableInteractive={false}
            PopperProps={{
              disablePortal: false,
              sx: {
                "&[data-popper-reference-hidden]": {
                  // visibility: "hidden",
                  // "pointer-events": "none",
                },
              },
            }}
            title={
              <AddToPipelinesTooltip
                handleCloseAddToPipelines={handleCloseAddToPipelines}
                item={item}
                source={"forecast"}
              />
            }
            slotProps={{
              tooltip: {
                sx: {
                  backgroundColor: "rgb(97,97,97)",
                  minWidth: "350px",
                },
              },
            }}
          >
            <span>
              <IconButton
                sx={{
                  border: "solid 2px",
                  backgroundColor:
                    // !RequestStatus.isDone(fetchStatus)
                    // ? theme.palette.action.disabled
                    // :
                    item.numberIncludedPipelines === 0
                      ? alpha(theme.palette.success.main, 0.1)
                      : theme.palette.success.main,
                  ...(item.numberIncludedPipelines !== 0 && {
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.success.main, 0.85),
                    },
                  }),
                }}
                color={"success"}
                onClick={() => handleOpenAddToPipelines(item)}
              >
                <img
                  src={icons.addToPipeline}
                  alt="map"
                  style={{
                    width: "26px",
                    height: "26px",
                    marginTop: "2px",
                    filter:
                      // !RequestStatus.isDone(fetchStatus)
                      // ? "grayscale(100%)"
                      // :
                      item.numberIncludedPipelines === 0
                        ? "hue-rotate(270deg) brightness(0.8)"
                        : "brightness(0) invert(1)",
                  }}
                />
                {item?.numberIncludedPipelines > 0 && (
                  <Avatar
                    sx={{
                      position: "absolute",
                      height: "16px",
                      width: "16px",
                      top: "-8px",
                      right: "-8px",
                      fontSize: "12px",
                      backgroundColor: theme.palette.secondary.main,
                      // border: `solid 1px ${theme.palette.secondary.main}`,
                    }}
                  >
                    {item?.numberIncludedPipelines}
                  </Avatar>
                )}
                {itemsFor === item.rowId &&
                  RequestStatus.isFetching(fetchStatus) && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "-13px",
                        right: "-10px",
                      }}
                    >
                      <CircularProgress size={20} thickness={5} />
                    </Box>
                  )}
              </IconButton>
            </span>
          </Tooltip>
        </TooltipWithScrollHide>
      )}
      <TooltipWithScrollHide
        querySelectors={"[data-virtuoso-scroller]"}
        title={"usaid.gov link"}
      >
        <IconButton
          sx={{
            border: "solid 2px",
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          }}
          color={"primary"}
          component={"a"}
          href={`https://www.usaid.gov/node/${item.id}`}
          target={"_blank"}
          rel={"noreferrer noopener"}
          disabled={item.status === businessForecastRecordType.FinalRecord}
        >
          <LinkIcon sx={{ width: "24px", height: "24px" }} />
        </IconButton>
      </TooltipWithScrollHide>
      <TooltipWithScrollHide
        title={"Go to opportunity profile page"}
        querySelectors={"[data-virtuoso-scroller]"}
      >
        <IconButton
          color={"primary"}
          sx={{
            border: "solid 2px",
            backgroundColor: alpha(theme.palette.info.main, 0.1),
          }}
          component={"a"}
          href={`/forecast/${item?.id}`}
          target={"_blank"}
          disabled={!item?.id}
        >
          <InfoIcon
            fontSize={"small"}
            sx={{
              width: "24px",
              height: "24px",
            }}
          />
        </IconButton>
      </TooltipWithScrollHide>
    </Box>
  );
};

export default RowActions;
