import {
  alpha,
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getSearchParamValue,
  setUrlParams,
} from "../../utils/Helpers/extractDataFromSearchParams";
import { RootState, useDispatch, useSelector } from "../../store";
import { useHistory } from "react-router-dom";
import UserContext from "../../services/UserContext";
import { useForm } from "react-hook-form";
import { FiltersIcon } from "../../components/Icons/FiltersIcon";
import Button from "@mui/material/Button";
import CustomTable, {
  ColumnsSettingsData,
  DataColumnConfigProps,
  StickyColumnConfigProps,
} from "../../components/MaterialTable/Table";
import { SourceIcon } from "../../components/Icons/SourceIcon";
import { LastUpdatedIcon } from "../../components/Icons/LastUpdatedIcon";
import { format } from "date-fns";
import ReportsFilters from "./ReportsFilters";
import { rowsPerPageOptionsStandard } from "../../utils/constants/gridsOptions";
import {
  getDropdownOptionsForReports,
  getReports,
  ReportsFiltersProps,
  resetReportsItems,
} from "../../slices/reports";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import { getSortableColumnPropertyName } from "../Grants/grantsUtils";
import { getReportsColumnsConfig } from "./reportsColumns.config";
import {
  getTableSettings,
  patchTableSettings,
} from "../../slices/user-settings";
import { generateExcel } from "../../services/exporter";
import {
  getExcelDataReports,
  getExportableDataTableReports,
  getReportsDownloadUrlFromId,
} from "./reportsUtils";
import LinkIcon from "@mui/icons-material/Link";
import TooltipWithScrollHide from "../../components/Widgets/TooltipWithScrollHide";
import { compact, debounce } from "lodash";
import { DropdownOption } from "../../components/Widgets/Inputs/Input";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { constants } from "../../utils/constants/general";

const FILTERS_FORM_ID = "reports-filters-form";
export const REPORTS_COLUMNS_SETTINGS_KEY = "reportsColumnsSettingsKey";

const defaultFilters: Omit<
  ReportsFiltersProps,
  "pageIndex" | "pageSize" | "sortField" | "sortOrder"
> = {
  onlyAttachments: true,
  country: [],
  sector: [],
  authoringOrg: [],
  filter: "",
  fileKeyword: "",
  documentId: "",
  startDate: new Date().addYears(-5),
  endDate: null,
};

export default function Reports() {
  const dispatch = useDispatch();
  const history = useHistory();
  const context = useContext(UserContext);

  const theme = useTheme();

  function getFiltersfromParams(): Omit<
    ReportsFiltersProps,
    "pageIndex" | "pageSize" | "sortField" | "sortOrder"
  > {
    const _startDate = getSearchParamValue<string | null>(
      history,
      "startDate",
      null,
    );
    const startDate = _startDate
      ? new Date(_startDate)
      : new Date().addYears(-5);

    const _endDate = getSearchParamValue<string | null>(
      history,
      "endDate",
      null,
    );
    const endDate = _endDate ? new Date(_endDate) : null;

    return {
      onlyAttachments:
        getSearchParamValue<string>(history, "onlyAttachments", "true") ===
        "true",
      country: getSearchParamValue<string[]>(history, "country", [], {
        multiple: true,
      }).map((v) => ({ value: v, label: v })),
      sector: getSearchParamValue<string[]>(history, "sector", [], {
        multiple: true,
      }).map((v) => ({ value: v, label: v })),
      authoringOrg: getSearchParamValue<string[]>(history, "authoringOrg", [], {
        multiple: true,
      }).map((v) => ({ value: v, label: v })),
      filter: getSearchParamValue<string>(history, "filter", ""),
      fileKeyword: getSearchParamValue<string>(history, "fileKeyword", ""),
      documentId: getSearchParamValue<string>(history, "documentId", ""),
      startDate,
      endDate,
    };
  }

  const { control, handleSubmit, reset, setValue, getValues } = useForm<
    Omit<
      ReportsFiltersProps,
      "pageIndex" | "pageSize" | "sortField" | "sortOrder"
    >
  >({
    defaultValues: getFiltersfromParams(),
  });

  const {
    tableSettings: { columnsOrder, fetchStatus: tableSettingsFetchStatus },
  } = useSelector((state: RootState) => state.userSettings);

  const { fetchStatus, total, items, lastUpdatedUtc } = useSelector(
    (state: RootState) => state.reports,
  );

  const [filtersOpen, setFiltersOpen] = useState<boolean>(true);

  const [columns, setColumns] = useState<Array<any>>([]);

  const [inputValue, setInputValue] = useState<string>(
    getSearchParamValue<string>(history, "filter", ""),
  );

  const [filtersSnapshot, setFiltersSnapshot] = useState<Omit<
    ReportsFiltersProps,
    "pageIndex" | "pageSize" | "sortField" | "sortOrder"
  > | null>(null);

  const allowDataLoadRef = useRef<boolean>(false);

  const page = useMemo(
    () => +getSearchParamValue<string>(history, "pageIndex", "0"),
    [history.location],
  );
  const rowsPerPage = useMemo(
    () =>
      +getSearchParamValue<string>(
        history,
        "pageSize",
        rowsPerPageOptionsStandard[0].toString(),
      ),
    [history.location],
  );

  const sortField = useMemo(
    () => getSearchParamValue<string>(history, "sortField", "publicationDate"),
    [history.location],
  );
  const sortOrder = useMemo(
    () => +getSearchParamValue<string>(history, "sortOrder", "0"),
    [history.location],
  );

  const filtersCount = useMemo(
    () =>
      Object.values(transformFiltersToDispatchParams(getValues())).filter(
        (value) => (Array.isArray(value) ? value.length > 0 : !!value),
      ).length,
    [history.location],
  );

  function onChange(e): void {
    setInputValue(e.target.value);
    debouncedKeywordChange(e.target.value);
  }

  function searchCallback(value: string): void {
    setValue("filter", value);
    setUrlParams(history, { filter: value, pageIndex: "0" });
  }

  const debouncedKeywordChange = useCallback(debounce(searchCallback, 750), []);

  function onPageChange(
    e: React.MouseEvent<HTMLButtonElement>,
    page: number,
  ): void {
    setUrlParams(history, { pageIndex: page.toString() });
  }

  function onRowsPerPageChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    setUrlParams(history, { pageIndex: "0", pageSize: e.target.value });
  }

  function openFilters() {
    setFiltersOpen(true);
    setFiltersSnapshot(getValues());
  }

  function closeFilters(clearSnapshot: boolean = true) {
    setFiltersOpen(false);
    setFiltersSnapshot(null);
  }

  function cancelFilters() {
    closeFilters(false);
    if (filtersSnapshot) {
      setTimeout(() => {
        reset(filtersSnapshot);
        setFiltersSnapshot(null);
      }, 150);
    }
  }

  function clearFilters() {
    reset(defaultFilters);
  }

  function transformFiltersToDispatchParams(
    values: Record<string, any>,
    deleteFalsy: boolean = true,
  ): Record<string, string | string[]> {
    return Object.fromEntries(
      compact(
        Object.entries(values).map(([key, value]) => {
          if (["onlyAttachments"].includes(key)) return [key, value];
          if (
            deleteFalsy &&
            ((!value && value !== 0) ||
              (Array.isArray(value) && value.length === 0) ||
              (value as string).length === 0)
          )
            return null;
          let _v;
          if (Array.isArray(value))
            _v = value.map((v) => (v?.hasOwnProperty("value") ? v.value : v));
          else if (typeof (value as Date)?.getMonth === "function") {
            let _value = value as Date;
            if (_value.getTimezoneOffset() !== 0)
              _value.setHours(-_value.getTimezoneOffset() / 60);
            _v = _value.toISOString().split("T")[0];
          } else _v = value;
          return [key, _v];
        }),
      ),
    );
  }

  function onFiltersSubmit(values: Record<string, any>) {
    setUrlParams(
      history,
      { ...transformFiltersToDispatchParams(values, false), pageIndex: "0" },
      {
        deleteFalsy: true,
      },
    );
    setTimeout(() => closeFilters(), 150);
  }

  function onSort(column: DataColumnConfigProps | StickyColumnConfigProps) {
    const propertyName = getSortableColumnPropertyName(column.propertyName);
    setUrlParams(
      history,
      sortField === propertyName
        ? sortOrder === 0
          ? { pageIndex: "0", sortField: propertyName, sortOrder: "1" }
          : sortOrder === 1
          ? { pageIndex: "0", sortField: propertyName, sortOrder: "-1" }
          : { pageIndex: "0", sortField: "", sortOrder: "0" }
        : { pageIndex: "0", sortField: propertyName, sortOrder: "1" },
    );
  }

  function handleSaveColumnsConfiguration(data: {
    [key: string]: Array<ColumnsSettingsData>;
  }): void {
    dispatch(
      patchTableSettings({
        context,
        key: REPORTS_COLUMNS_SETTINGS_KEY,
        configuration: data,
      }),
    );
  }

  useEffect(() => {
    dispatch(
      getDropdownOptionsForReports({
        context,
      }),
    );
    dispatch(getTableSettings({ context, key: REPORTS_COLUMNS_SETTINGS_KEY }));

    return () => {
      setColumns([]);
      dispatch(resetReportsItems());
    };
  }, []);

  useEffect(() => {
    if (allowDataLoadRef.current) {
      dispatch(
        getReports({
          context,
          params: {
            ...transformFiltersToDispatchParams(getValues()),
            pageIndex: page,
            pageSize: rowsPerPage,
            sortField,
            sortOrder,
          },
        }),
      );
    } else {
      allowDataLoadRef.current = true;
    }
  }, [history.location]);

  useEffect(() => {
    if (
      RequestStatus.isDone(tableSettingsFetchStatus) ||
      RequestStatus.isError(tableSettingsFetchStatus)
    ) {
      setColumns(
        getReportsColumnsConfig(getSortableColumnPropertyName)
          // .filter((column) => (column?.hiddenBasedOnRole ? canEdit : true))
          .map((column, idx) => ({
            ...column,
            order:
              columnsOrder.find(
                (_column) => _column.propertyName === column.propertyName,
              )?.order ?? idx,
            isVisible:
              columnsOrder.find(
                (_column) => _column.propertyName === column.propertyName,
              )?.isVisible ?? column.isVisible,
          }))
          .sort((a, b) => a.order - b.order),
      );
    }
  }, [tableSettingsFetchStatus, getSortableColumnPropertyName]);

  return (
    <Box
      sx={{
        p: 2,
        textAlign: "start",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Drawer
        open={filtersOpen}
        onClose={cancelFilters}
        variant={"temporary"}
        anchor={"right"}
        sx={{
          zIndex: constants.zIndexes.drawer,
        }}
        PaperProps={{
          sx: {
            width: "480px",
            "& > div": {
              px: 2,
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            backgroundColor: theme.palette.secondary.light,
            borderBottom: `solid 3px ${theme.palette.secondary.main}`,
            py: 3,
          }}
        >
          <FiltersIcon />
          <Typography variant={"h5"}>Filters</Typography>
        </Box>
        <Box
          sx={{
            py: 2,
            overflowY: "scroll",
            flex: 1,
          }}
        >
          <form
            onSubmit={handleSubmit(onFiltersSubmit)}
            id={FILTERS_FORM_ID}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <ReportsFilters control={control} />
          </form>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
            py: 2,
          }}
        >
          <Button
            variant={"secondaryContained"}
            color={"secondary"}
            onClick={cancelFilters}
          >
            Cancel
          </Button>
          <Button
            variant={"secondaryContained"}
            color={"secondary"}
            onClick={clearFilters}
          >
            Clear
          </Button>
          <Button
            variant={"contained"}
            color={"secondary"}
            // onClick={handleFilterData}
            type={"submit"}
            form={FILTERS_FORM_ID}
          >
            Save
          </Button>
        </Box>
      </Drawer>
      <Typography variant={"body2"} sx={{ mb: 3 }}>
        Welcome to the streamlined search of USAID reports, evaluations,
        assessments, and other key documents and data sources. This search
        allows you to access more than 140,000 USAID documents, organize based
        on your country, technical sector, and partner preferences, and access
        the PDF documents directly through AidKonekt (when available). Click on
        the PDF icon on the far right to access the document directly, without
        having to go through another search engine. This is a link that you can
        share internally with your team. When you export a search, the link to
        the PDF files will be included in the export, so you and your colleagues
        can access directly without having to even sign back in to AidKonekt.
      </Typography>
      {columns.length > 0 && (
        <CustomTable
          config={{
            data: items.map((item) => ({
              ...item,
              link: (
                <TooltipWithScrollHide
                  querySelectors={"[data-virtuoso-scroller]"}
                  title={"Open attachment in new window"}
                >
                  <span>
                    <IconButton
                      sx={{
                        border: "solid 2px",
                        backgroundColor: alpha(theme.palette.primary.main, 0.1),
                      }}
                      color={"primary"}
                      component={"a"}
                      href={getReportsDownloadUrlFromId(item.uniqueId)}
                      target={"_blank"}
                      rel={"norefferer noopener"}
                      disabled={item.fileSyncStatus !== "Synced"}
                    >
                      <LinkIcon sx={{ width: "24px", height: "24px" }} />
                    </IconButton>
                  </span>
                </TooltipWithScrollHide>
              ),
            })),
            columns,
            header: [
              <Box
                sx={{
                  mt: 1,
                }}
              >
                {/*<Tooltip title={"Filters"}>*/}
                {/*  <span>*/}
                {/*    <Button*/}
                {/*      variant={"outlined"}*/}
                {/*      endIcon={*/}
                {/*        <FiltersIcon sx={{ width: "24px", height: "24px" }} />*/}
                {/*      }*/}
                {/*      size={"small"}*/}
                {/*      onClick={openFilters}*/}
                {/*    >*/}
                {/*      Filters*/}
                {/*      {filtersCount > 0 && (*/}
                {/*        <Avatar*/}
                {/*          sx={{*/}
                {/*            position: "absolute",*/}
                {/*            height: "16px",*/}
                {/*            width: "16px",*/}
                {/*            top: "2px",*/}
                {/*            right: "6px",*/}
                {/*            fontSize: "12px",*/}
                {/*            backgroundColor: theme.palette.secondary.main,*/}
                {/*            // border: `solid 1px ${theme.palette.secondary.main}`,*/}
                {/*            pointerEvents: "none",*/}
                {/*          }}*/}
                {/*        >*/}
                {/*          {filtersCount}*/}
                {/*        </Avatar>*/}
                {/*      )}*/}
                {/*    </Button>*/}
                {/*  </span>*/}
                {/*</Tooltip>*/}
                <TextField
                  value={inputValue}
                  onChange={onChange}
                  sx={{
                    width: "300px",
                  }}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon sx={{ color: theme.palette.action.active }} />
                    ),
                    endAdornment: (
                      <>
                        {inputValue?.length > 0 && (
                          <IconButton
                            onClick={() => onChange({ target: { value: "" } })}
                            sx={{ mr: 0.5 }}
                            disabled={RequestStatus.isFetching(fetchStatus)}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        <Box
                          sx={{
                            borderLeft: `solid 1px ${theme.palette.divider}`,
                            pl: 0.5,
                          }}
                        >
                          <Tooltip title={"Filters"}>
                            <span>
                              <IconButton onClick={openFilters}>
                                <FiltersIcon
                                  sx={{ width: "24px", height: "24px" }}
                                />
                              </IconButton>
                              {filtersCount > 0 && (
                                <Avatar
                                  sx={{
                                    position: "absolute",
                                    height: "16px",
                                    width: "16px",
                                    top: "2px",
                                    right: "2px",
                                    fontSize: "12px",
                                    backgroundColor:
                                      theme.palette.secondary.main,
                                    // border: `solid 1px ${theme.palette.secondary.main}`,
                                    pointerEvents: "none",
                                  }}
                                >
                                  {filtersCount}
                                </Avatar>
                              )}
                            </span>
                          </Tooltip>
                        </Box>
                      </>
                    ),
                    sx: {
                      px: 0.5,
                    },
                  }}
                  size={"small"}
                  placeholder={"Search"}
                />
              </Box>,
              <Box
                sx={{
                  display: "flex",
                  gap: "24px",
                  mb: 1,
                  justifyContent: "flex-end",
                  "& > *": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              >
                <Typography variant={"subtitle2"} color={"textSecondary"}>
                  <SourceIcon
                    color={"secondary"}
                    sx={{
                      height: "16px",
                      width: "16px",
                      mr: 0.5,
                      mt: "-2px",
                    }}
                  />
                  Source: USAID Development Experience Clearinghouse
                </Typography>
                <Typography variant={"subtitle2"} color={"textSecondary"}>
                  <LastUpdatedIcon
                    sx={{
                      height: "16px",
                      width: "16px",
                      mr: 0.5,
                      mt: "-2px",
                    }}
                  />
                  Last updated:{" "}
                  {
                    /*lastUpdated*/ lastUpdatedUtc
                      ? format(new Date(lastUpdatedUtc), "dd MMMM yyyy, HH:mm")
                      : "N/A"
                  }
                </Typography>
              </Box>,
            ],
            columnsConfiguration: {
              allowColumnsConfiguration: true,
              onSaveColumnsConfiguration: handleSaveColumnsConfiguration,
              columnsSettingsKey: REPORTS_COLUMNS_SETTINGS_KEY,
            },
            exportExcelFunc: async () =>
              generateExcel(
                "Data+",
                `Aidkonekt_reports_plus_${new Date().getFullYear()}`,
                getExportableDataTableReports(
                  getExcelDataReports
                    ? await getExcelDataReports(
                        context,
                        transformFiltersToDispatchParams(getValues()),
                        total,
                      )
                    : items,
                ),
              ),
            ...(RequestStatus.isNull(fetchStatus) && {
              noDataMessage: "Select filters to see data",
            }),
          }}
          loading={RequestStatus.isFetching(fetchStatus)}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          pagination={{
            page,
            rowsPerPage,
            total,
          }}
          currentSort={{
            sortField,
            sortOrder,
          }}
          onSortClick={onSort}
        />
      )}
    </Box>
  );
}
