export const Capitalize = (str: string) => {
  // AK1-839 - if null/undefined, don't want to throw error
  if (!str) {
    return '';
  }

  let text: string[] = str.toLowerCase().split(" ");

  for (var i = 0; i < text.length; i++) {
    text[i] = text[i].charAt(0).toUpperCase() + text[i].slice(1);
  }
  return text.join(" ");
};
