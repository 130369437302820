export const pageTitles = [
  {
    pathname: "/dashboard-new-w68f5es4",
    title: "Dashboard (with AI)",
    onlyBreadcrumbs: false,
  },
  {
    pathname: "/",
    title: "Dashboard",
    onlyBreadcrumbs: false,
  },
  {
    pathname: "/forecast",
    title: "Business Forecast Changes Feed",
    onlyBreadcrumbs: false,
  },
  {
    pathname: "/forecast/:recordId",
    title: "Opportunity Details",
    onlyBreadcrumbs: true,
  },
  {
    pathname: "/contracts",
    title: "Contracts+",
    onlyBreadcrumbs: false,
  },
  {
    pathname: "/contracts/:recordId",
    title: "Opportunity Details",
    onlyBreadcrumbs: true,
  },
  {
    pathname: "/grants",
    title: "Grants+",
    onlyBreadcrumbs: false,
  },
  {
    pathname: "/grants/:recordId",
    title: "Opportunity Details",
    onlyBreadcrumbs: true,
  },
  {
    pathname: "/projects",
    title: "Projects",
  },
  {
    pathname: "/reports",
    title: "Reports",
  },
  {
    pathname: "/ecd",
    title: "Environmental Compliance Database (ECD)",
  },
];
