import React, { FC, useContext } from "react";
import Input, { DropdownOption } from "../../components/Widgets/Inputs/Input";
import { RootState, useDispatch, useSelector } from "../../store";
import UserContext from "../../services/UserContext";
import { getCountryDropdownOptions } from "../../slices/grants";

interface GrantsFiltersProps {
  control: any;
}

const GrantsFilters: FC<GrantsFiltersProps> = (props) => {
  const { control } = props;

  const dispatch = useDispatch();
  const context = useContext(UserContext);

  const {
    dropdownOptions: {
      country: { items: countryOptions },
      categories: { items: categoryOptions },
    },
  } = useSelector((state: RootState) => state.grants);

  return (
    <>
      <Input
        type={"text"}
        fullWidth
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"filter"}
        control={control}
        // defaultValue={null}
        label={"KEYWORD SEARCH GRANTS+ PROCUREMENTS DATA"}
      />
      <Input
        type={"text"}
        fullWidth
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"fileKeyword"}
        control={control}
        // defaultValue={null}
        label={"KEYWORD SEARCH IN-DOCUMENT TEXT"}
      />
      <Input
        type={"combo-box"}
        withCheckboxes
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        control={control}
        name={"country"}
        label={"MISSION / BUREAU"}
        options={countryOptions}
        // loading={loading}
        fullWidth
        multiple
      />
      <Input
        type={"combo-box"}
        withCheckboxes
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        control={control}
        // defaultValue={""}
        name={"category"}
        label={"TECHNICAL SECTOR"}
        options={categoryOptions}
        // loading={loading}
        fullWidth
        multiple
      />
      <Input
        type={"text"}
        fullWidth
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"opportunityNumber"}
        control={control}
        label={"OPPORTUNITY NUMBER"}
      />
      <Input
        type={"text"}
        fullWidth
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"fileName"}
        control={control}
        // defaultValue={null}
        label={"FILE NAME"}
      />
      <Input
        type={"text"}
        fullWidth
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"eligibility"}
        control={control}
        // defaultValue={null}
        label={"ELIGIBILITY"}
      />
      <Input
        type={"date"}
        sx={{
          "& .MuiFormLabel-root": {
            fontSize: "0.875em",
            lineHeight: "1.875em",
          },
        }}
        name={"updatedBy"}
        control={control}
        // defaultValue={null}
        label={"SEARCH GRANTS+ UPDATES SINCE"}
      />
    </>
  );
};

export default GrantsFilters;
