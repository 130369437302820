import React from "react";

interface ContactInfoProps {
  data: {
    email?: string;
    fax?: string;
    fullName?: string;
    phone?: string;
  } | null;
}

const ContactInfo = (props: ContactInfoProps) => {
  const { data: _data } = props;

  const data = {
    email: _data?.email ?? "",
    fax: _data?.fax ?? "",
    phone: _data?.phone ?? "",
    fullName: _data?.fullName ?? "",
  };

  return Object.values(data).every((val) => val.length === 0)
    ? null
    : `<div>${data?.email?.length > 0 ? `Email: <b>${data.email}</b><br/>` : ""}
  ${data?.fax?.length > 0 ? `Fax: <b>${data.fax}</b><br/>` : ""}
  ${data?.fullName?.length > 0 ? `Name: <b>${data.fullName}</b><br/>` : ""}
  ${data?.phone?.length > 0 ? `Phone: <b>${data.phone}</b><br/>` : ""}</div>`;
};

export default ContactInfo;
