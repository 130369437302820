import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import {
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import UserContext from "../../services/UserContext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useStyles } from "../../hooks/useStyles";
import { Box, TablePagination, Typography } from "@mui/material";
import { buildQuery } from "../../utils/Helpers/queryBuilder";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  DateFormat,
  formatDate,
  getEpochDateWithoutTimezone,
} from "../../utils/conversion/date-converters";
import { rowsPerPageOptionsStandard } from "../../utils/constants/gridsOptions";
import "./CDCSRepository.css";
import { format } from "date-fns";
import ReactSelect from "react-select";

interface CDCSItem {
  country: string;
  created_on_utc: string;
  description: string;
  is_deleted: boolean;
  name: string;
  pdf_url: string;
  section_title: string;
  url: string;
}

interface CDCSData {
  attributes: {
    [ket: string]: any;
  };
  data: Array<CDCSItem>;
  lastUpdatedUtc: string;
  pageIndex: number;
  pageSize: number;
  totalItems: number;
}

interface DataToDisplay {
  [key: string]: Array<CDCSItem & { children?: Array<CDCSItem> }>;
}

interface CDCSPagination {
  page_index: number;
  rows_per_page: number;
  totalItems: number;
}

type CountryOptions = Array<{
  label: string;
  value: string;
}>;

const initialPagination: CDCSPagination = {
  page_index: 0,
  rows_per_page: rowsPerPageOptionsStandard[0],
  totalItems: 0,
};

const yearRegex = new RegExp("\\b(\\d{4})\\b");

const CDCSRepoList: FC = () => {
  const context = useContext(UserContext);
  const styles = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCountryOptions, setLoadingCountryOptions] =
    useState<boolean>(false);
  const [searchValueCountry, setSearchValueCountry] = useState("");
  const [searchValueKeywords, setSearchValueKeywords] = useState("");
  const [data, setData] = useState<DataToDisplay>({});
  const [countriesDropdown, setCountriesDropdown] = useState<CountryOptions>(
    [],
  );
  const [lastUpdatedUtc, setLastUpdatedUtc] = useState<string | null>(null);

  const [pagination, setPagination] =
    useState<CDCSPagination>(initialPagination);

  const onlyUnique = (
    value: any,
    index: number,
    array: any,
    param: string | null = null,
  ): boolean => {
    if (Object.keys(value)?.length > 0 && param !== null)
      return array.map((item) => item[param]).indexOf(value) === index;
    else return array.indexOf(value) === index;
  };

  const getCountries = async (): Promise<void> => {
    setLoadingCountryOptions(true);
    await axios
      .get(
        getFullUrl(`/api/UsAid/dropdownoptions/aidCountry`, {
          useDedicatedEnvironment: true,
        }),
        createRequestWithAuthHeaders(context),
      )
      .then((response: AxiosResponse<Array<string>>) => {
        setCountriesDropdown(
          response.data.map((country) => ({ value: country, label: country })),
        );
        setLoadingCountryOptions(false);
      })
      .catch((err) => {
        setLoadingCountryOptions(false);
      });
  };

  const getCDCSItems = async (): Promise<void> => {
    setLoading(true);
    const query = buildQuery({
      country: searchValueCountry,
      document_text_keyword: searchValueKeywords,
      ...pagination,
      sort_field: "section_title",
      sort_order: 1,
    });
    await axios
      .get(
        getFullUrl(`/api/utility/cdc${query}`, {
          useDedicatedEnvironment: true,
        }),
        createRequestWithAuthHeaders(context),
      )
      .then((response: AxiosResponse<CDCSData>) => {
        const _data = response?.data?.data ?? [];

        setPagination((prev) => ({
          ...prev,
          totalItems: response?.data?.totalItems,
        }));

        const mostRecentlyCreatedDocument =
          _data.length > 0
            ? _data
                .map((item) => new Date(item.created_on_utc))
                .sort()
                .reverse()[0]
                .toString()
            : null;

        //Currently set a hardcoded date: 07/25/2023

        // const mostRecentlyCreatedDocumentDate = mostRecentlyCreatedDocument
        //   ? new Date(
        //       getEpochDateWithoutTimezone(
        //         new Date(mostRecentlyCreatedDocument),
        //       ) as number,
        //     )
        //   : null;

        const mostRecentlyCreatedDocumentDate = new Date(
          getEpochDateWithoutTimezone(new Date(2023, 7, 25, 0, 0, 0)) as number,
        );

        setLastUpdatedUtc(
          mostRecentlyCreatedDocumentDate
            ? format(mostRecentlyCreatedDocumentDate, "d MMMM yyyy")
            : null,
        );

        if (_data?.length === 0) {
          setData({});
        } else {
          const sections = _data
            .map((item) => item.section_title)
            .filter(onlyUnique);

          let usedNames: Array<string> = [];

          setData(
            sections
              .map((section) => ({
                key: section,
                value: _data
                  .filter((item) => item.section_title === section)
                  .map((item) => item.country)
                  .filter(onlyUnique)
                  .map((country) => ({
                    key: country,
                    value: _data
                      .filter(
                        (item) =>
                          item.country === country &&
                          item.section_title === section,
                      )
                      .map((item) => {
                        const hasYear = yearRegex.test(item.name);
                        if (hasYear) {
                          if (
                            !usedNames.includes(item.name.split(yearRegex)[0])
                          ) {
                            usedNames.push(item.name);
                            const shortenedName = item.name
                              .split(yearRegex)[0]
                              .replaceAll("(", "")
                              .replaceAll(")", "")
                              .replaceAll(",", "")
                              .trim();
                            return {
                              key: shortenedName,
                              value: _data
                                .filter(
                                  (childItem) =>
                                    childItem.country === country &&
                                    childItem.section_title === section &&
                                    childItem.name.includes(
                                      item.name.split(yearRegex)[0],
                                    ),
                                )
                                .map((childItem) => ({
                                  ...childItem,
                                  name:
                                    childItem.name.split(yearRegex)?.length ===
                                    3
                                      ? childItem.name.split(yearRegex)[1]
                                      : `${
                                          childItem.name.split(yearRegex)[1]
                                        } - ${
                                          childItem.name.split(yearRegex)[3]
                                        }`,
                                })),
                            };
                          }
                        } else
                          return {
                            key: item.name,
                            value: item,
                          };
                      })
                      .filter((x) => x)
                      .reduce(
                        // @ts-ignore
                        (obj, item) => ((obj[item.key] = item.value), obj),
                        {},
                      ),
                  }))
                  .reduce(
                    (obj, item) => ((obj[item.key] = item.value), obj),
                    {},
                  ),
              }))
              .reduce((obj, item) => ((obj[item.key] = item.value), obj), {}),
          );
        }

        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCDCSItems();
  }, [pagination.page_index, pagination.rows_per_page]);

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <div className="resources-screen">
      <section className="header">
        <div className="header-title">
          <img
            src={styles.aidkonektIcon}
            style={styles.icon}
            alt="Aidkonekt"
            className="formIcon"
          />
          <span>CDCS Repository</span>
        </div>
        <span className="header-subtitle">
          USAID Country Development Cooperation Strategy (CDCS) Repository
        </span>
        <span className="header-description">
          “A Country Development Cooperation Strategy (CDCS) defines how a
          Mission will further the goal of ending the need for foreign
          assistance in a given country by articulating how and where it will
          build self-reliance with the country partner. Typically covering five
          years, the CDCS is essentially the strategic roadmap for how USAID
          will design and implement its projects and activities in a given
          country. The CDCS development process centers around a Mission's
          dialogue with USAID/Washington and with key local stakeholders,
          resulting in a series of milestones and deliverables. The Mission
          disseminates a public version of the strategy on USAID's public
          website.
          <br />
          <br />
          Listed below, please find the current list of approved CDCSs. Some
          CDCSs have been extended beyond the timeline noted on the cover sheet.
          A fact sheet with all dates is available here. The Agency anticipates
          most CDCSs will be completed on or about December 2020. Answers to
          frequently asked CDCS questions are here.” - USAID
          <br />
          <br />
          Last updated: {lastUpdatedUtc ?? "N/A"}
        </span>
      </section>

      <section className="cdcs-list-section">
        <div className="cdcs-search-block">
          <ReactSelect
            styles={{
              container: (baseStyles) => ({
                ...baseStyles,
                maxWidth: "300px",
                width: "100%",
              }),
              control: (baseStyles) => ({
                ...baseStyles,
                padding: "4px 7px",
                marginRight: "20px",
                border: "3px solid #999999",
                borderRadius: "8px",
                outline: "none",
              }),
              placeholder: (baseStyles) => ({
                ...baseStyles,
                color: "#999999",
                fontSize: "14px",
                fontWeight: 400,
              }),
            }}
            options={countriesDropdown}
            onChange={(e) => {
              if (!e) setSearchValueCountry("");
              else setSearchValueCountry(e.value);
            }}
            isClearable
            isLoading={loadingCountryOptions}
          />
          {/*<input*/}
          {/*  type="text"*/}
          {/*  placeholder="Country"*/}
          {/*  onChange={(e) => setSearchValueCountry(e.currentTarget.value)}*/}
          {/*/>*/}
          <input
            type="text"
            placeholder="Keywords"
            onChange={(e) => setSearchValueKeywords(e.currentTarget.value)}
          />
          <button
            type="button"
            className="card-btn aid-blu-btn"
            onClick={getCDCSItems}
          >
            Search
          </button>
        </div>
      </section>
      <div className="country-list">
        {loading ? (
          <ProgressSpinner />
        ) : Object.entries(data).length > 0 ? (
          Object.entries(data).map(([section, countries]) => {
            return (
              <Fragment key={section}>
                <div className="list-title-block">
                  <h3 className={"list-title"}>{section}</h3>
                </div>
                <ul>
                  {Object.entries(countries).map(([country, documents]) => {
                    return (
                      <Fragment key={country}>
                        <li
                          className={
                            Object.keys(documents)?.length === 1 &&
                            !Array.isArray(Object.values(documents)[0]) &&
                            Object.values(documents)[0].name === country
                              ? "list-item"
                              : "list-item-with-children"
                          }
                        >
                          {/*<span>{country}:</span>*/}
                          {Object.keys(documents)?.length === 1 &&
                          !Array.isArray(Object.values(documents)[0]) &&
                          Object.values(documents)[0].name === country ? (
                            <span
                              onClick={() =>
                                window.open(
                                  Object.values(documents)[0].pdf_url,
                                  "_blank",
                                )
                              }
                            >
                              <DescriptionIcon style={{ height: "24px" }} />
                              {country}
                            </span>
                          ) : (
                            <>
                              <span>{country}:</span>
                              <ul>
                                {Object.entries(documents).map(
                                  ([document, children]) => {
                                    return (
                                      <div
                                        key={document}
                                        // className={"list-item"}
                                      >
                                        {Array.isArray(children) ? (
                                          <p
                                            style={{
                                              margin: "8px 0",
                                              fontWeight: "600",
                                            }}
                                          >
                                            - {document}:
                                          </p>
                                        ) : (
                                          <li className={"list-item"}>
                                            <span
                                              style={{ fontWeight: "normal" }}
                                              onClick={() =>
                                                window.open(
                                                  children?.pdf_url,
                                                  "_blank",
                                                )
                                              }
                                            >
                                              &#8729;{" "}
                                              <DescriptionIcon
                                                style={{ height: "24px" }}
                                              />{" "}
                                              {document}
                                            </span>
                                          </li>
                                        )}
                                        <Box
                                          className={"list-item"}
                                          sx={{
                                            ml: 2,
                                            display: "flex",
                                            flexDirection: "column",
                                            "> span": {
                                              fontWeight: "normal",
                                              // my: 0.5,
                                            },
                                          }}
                                        >
                                          {Array.isArray(children) ? (
                                            children.map(
                                              (child, idx) =>
                                                child.pdf_url && (
                                                  <span
                                                    key={`${child.name}-${idx}`}
                                                    onClick={() =>
                                                      window.open(
                                                        child?.pdf_url,
                                                        "_blank",
                                                      )
                                                    }
                                                  >
                                                    &#8729;{" "}
                                                    <DescriptionIcon
                                                      style={{ height: "24px" }}
                                                    />{" "}
                                                    {child.name}
                                                  </span>
                                                ),
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </div>
                                    );
                                  },
                                )}
                              </ul>
                            </>
                          )}
                        </li>
                      </Fragment>
                    );
                  })}
                </ul>
              </Fragment>
            );
          })
        ) : (
          <div className="list-title-block">
            <h3 className={"list-title"}>No records found</h3>
          </div>
        )}
      </div>
      <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 1 }}>
        <TablePagination
          count={pagination.totalItems}
          page={pagination.page_index}
          rowsPerPage={pagination.rows_per_page}
          rowsPerPageOptions={rowsPerPageOptionsStandard}
          onPageChange={(e, page) =>
            setPagination((prev) => ({
              ...prev,
              page_index: page,
            }))
          }
          onRowsPerPageChange={(e) => {
            setPagination((prev) => ({
              ...prev,
              page_index: 0,
              rows_per_page: +e.target.value,
            }));
          }}
          component={"div"}
        />
      </Box>
    </div>
  );
};

export default CDCSRepoList;
